import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(theme.spacing(12))} 0`,
  },

  iconError: { color: theme.palette.error.main, fontSize: 60 },
  iconInfo: { color: theme.palette.info.main, fontSize: 60 },
  iconSuccess: { color: theme.palette.success.main, fontSize: 60 },
  iconWarning: { color: theme.palette.warning.main, fontSize: 60 },
  iconSearchInfo: { color: theme.palette.info.main, fontSize: 60 },

  divider: {
    height: theme.typography.pxToRem(4),
    margin: `${theme.typography.pxToRem(24)}  0`,
    flexShrink: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    borderBottomWidth: 'thin',
    borderRightWidth: 'thin',
    width: '5%',
  },
}));

export default useStyles;
