import orderBy from 'lodash/orderBy';

const copyExcept = (obj = {}, exception) => {
  const keys = Object.keys(obj);
  if (keys.length === 0) return obj;
  const newObj = {};
  keys.forEach((k) => {
    if (k !== exception) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
};

export const groupTablesByServiceAreas = (tables = []) => {
  if (tables.length === 0) return tables;
  const group = [];
  tables.forEach((t) => {
    const tableObject = {};
    const indexServiceArea = group.findIndex((g) => g.serviceAreaName === t.serviceArea.name);
    if (t?.serviceArea && indexServiceArea < 0) {
      tableObject.serviceAreaName = t.serviceArea.name;
      tableObject.serviceArea = { ...t.serviceArea };
      tableObject.sortPosition = t.serviceArea.sortPosition;
      tableObject.tables = [copyExcept(t, 'serviceArea')];
      group.push(tableObject);
    }
    if (indexServiceArea >= 0) {
      group[indexServiceArea].tables.push(copyExcept(t, 'serviceArea'));
    }
  });
  return sortByPosition(group);
};

export const sortByPosition = (group) => {
  const sortedGroup = group.map((serviceArea) => {
    return {
      ...serviceArea,
      tables: orderBy(serviceArea.tables, ['sortPosition']),
    };
  });

  return orderBy(sortedGroup, ['sortPosition']);
};

export const getDefaultAccordionState = (groups = []) => {
  const defaultState = {};
  groups.forEach((g) => {
    if (g?.serviceArea?.uuid) {
      defaultState[g.serviceArea.uuid] = true;
    }
  });
  return defaultState;
};
