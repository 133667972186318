import queryString from 'query-string';

import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function validatePinRequest({ storeUuid, email, posPin }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    email_address: email,
    pos_pin: posPin,
  });

  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/pos_pin/validate/?${query}`, {
    method: 'GET',
    ...options,
  });
}
export function generatePosPinRequest({ storeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/pos_pin/generate/`, {
    method: 'GET',
    ...options,
  });
}
