import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { deleteRoleRequest } from 'utils/api/baas/workforce/roles';

import { deleteRole } from '../actions';

export function* handleDeleteRoleRequest(action) {
  const jobUuid = action.payload;

  try {
    yield put(deleteRole.request());

    yield call(baasRequest, deleteRoleRequest, jobUuid);

    yield put(deleteRole.success(jobUuid));
  } catch (err) {
    yield put(deleteRole.failure(err));
  }
}
