import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getProduct, editProduct, updateProductPrice } from './actions';
import {
  selectProduct,
  selectProductState,
  selectProductEdit,
  selectProductEditState,
  selectUpdateProductPriceState,
} from './selectors';

export function withProductDetailsHoc(Component) {
  function withProductDetails(props) {
    return <Component {...props} />;
  }
  // Display Name for Easy Debugging
  withProductDetails.displayName = `withProductDetails(${getDisplayName(Component)})`;

  return withProductDetails;
}

const mapStateToProps = createStructuredSelector({
  productDetailsObject: selectProduct,
  productDetailsState: selectProductState,
  productEditObject: selectProductEdit,
  productEditState: selectProductEditState,
  updateProductPriceState: selectUpdateProductPriceState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadGetProduct: (storeUuid, productUuid) => dispatch(getProduct({ storeUuid, productUuid })),
    editProduct: (values) => dispatch(editProduct(values)),
    updateProductPrice: (values) => dispatch(updateProductPrice(values)),
    resetProductDetails: () => dispatch(getProduct.reset()),
    resetEditProduct: () => dispatch(editProduct.reset()),
    resetUpdateProductPrice: () => dispatch(updateProductPrice.reset()),
  };
}

export const withProductDetailsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withProductDetailsState, withProductDetailsHoc);
