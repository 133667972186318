import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const OrderBy = forwardRef(function OrderBy(props, ref) {
  const { color = '#000', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M1 4.5C1 4.22386 1.23452 4 1.52381 4H22.4762C22.7655 4 23 4.22386 23 4.5C23 4.77614 22.7655 5 22.4762 5H1.52381C1.23452 5 1 4.77614 1 4.5ZM1 11.5C1 11.2239 1.1599 11 1.35714 11H15.6429C15.8401 11 16 11.2239 16 11.5C16 11.7761 15.8401 12 15.6429 12H1.35714C1.1599 12 1 11.7761 1 11.5ZM1.16667 18C1.07462 18 1 18.2239 1 18.5C1 18.7761 1.07462 19 1.16667 19H7.83333C7.92538 19 8 18.7761 8 18.5C8 18.2239 7.92538 18 7.83333 18H1.16667Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

OrderBy.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default OrderBy;
