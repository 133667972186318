// @ts-nocheck
import { useQuery } from 'react-query';

import { createHeaders } from 'utils/baasRequest';
import { getCategoriesStore } from 'utils/api/baas/categories';

interface CategoriesProps {
  data: {
    uuid: string;
    name: string;
  }[];
}

export function useGetCategoryCatalog(storeUuid: string) {
  const {
    data: categories,
    isFetched,
    isFetching,
    isError,
    refetch: loadCategoryCatalog,
    remove: resetCategories,
  } = useQuery(
    ['categoryCatalog', storeUuid],
    () => getCategoriesStore({ storeUuid, allCategories: true }, { headers: createHeaders() }),
    {
      initialData: { data: [] },
      select: (response: CategoriesProps) => response.data,
      enabled: false,
    },
  );

  return {
    categories,
    categoriesState: { fetched: isFetched, fetching: isFetching, error: isError },
    loadCategoryCatalog,
    resetCategories,
  };
}
