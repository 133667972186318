import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ArrowBack = forwardRef(function ArrowBack(props, ref) {
  const { color = '#000000', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 32 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M0 12c0-.51.421-.923.941-.923H31.06c.52 0 .941.413.941.923s-.421.923-.941.923H.94A.932.932 0 010 12z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.9.27a.91.91 0 010 1.306L2.273 12l10.629 10.424a.91.91 0 010 1.306.954.954 0 01-1.331 0L.276 12.653a.91.91 0 010-1.306L11.57.27a.954.954 0 011.33 0z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

ArrowBack.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ArrowBack;
