import queryString from 'query-string';
import { api } from 'config';
import { getUserCurrentStore } from 'utils/auth';

import { cleanObject } from 'utils/objects';
import { toUpperAndHyphen } from 'utils/strings';

import { AssignProductTypeFormProps, Response } from '../types';

export async function assignTypeOfProducts(values: AssignProductTypeFormProps) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const body = {
    products: values.products,
    productTypeUuid: values.productTypeUuid !== '' ? values.productTypeUuid : null,
  };

  const params = {
    name: values.name,
    category: values.category,
    type_of_sale: values.type_of_sale,
    brand: values.brand,
    without_brands: values.without_brands,
    without_categories: values.without_categories,
    order_by: values.order_by,
    store_uuid: storeUuid,
  };
  const parseParams = {};

  const keys = Object.keys(cleanObject(params));
  keys.forEach((key) => {
    parseParams[toUpperAndHyphen(key)] = params[key];
  });
  const query = queryString.stringify(parseParams);

  const response = await api.post<Response>(`/v1/menu-maker/product-types/products/?${query}`, {
    ...body,
  });
  return response.data;
}
