import { useContext } from 'react';

import { ContentLayoutContext } from 'providers/root/ContentLayoutProvider';

function useContentLayout() {
  const { value, setValue } = useContext(ContentLayoutContext);

  return { value, setValue };
}

export default useContentLayout;
