/* eslint-disable no-unused-vars */
import produce from 'immer';

import { getCategoryDetail, editCategory, getProductsSortCategory, updateProductsSortCategory } from './actions';

const baseState = {
  error: null,
  fetching: false,
  fetched: false,
};

export const initialState = {
  getCategoryDetail: {
    ...baseState,
    category: {},
  },
  editCategory: {
    ...baseState,
    category: {},
  },
  getProductsSortCategory: {
    ...baseState,
    products: [],
  },
  updateProductsSortCategory: {
    ...baseState,
    products: [],
  },
};

const categoryDetailsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getCategoryDetail.REQUEST:
        draft.getCategoryDetail.fetching = true;
        draft.getCategoryDetail.fetched = false;
        draft.getCategoryDetail.error = false;
        break;

      case getCategoryDetail.SUCCESS:
        draft.getCategoryDetail.fetching = false;
        draft.getCategoryDetail.fetched = true;
        draft.getCategoryDetail.error = false;
        draft.getCategoryDetail.category = payload;
        break;

      case getCategoryDetail.FAILURE:
        draft.getCategoryDetail.error = true;
        draft.getCategoryDetail.fetching = false;
        draft.getCategoryDetail.fetched = false;
        break;

      case getCategoryDetail.RESET:
        draft.getCategoryDetail = { ...initialState.getCategoryDetail };
        break;

      // edit category
      case editCategory.REQUEST:
        draft.editCategory.fetching = true;
        draft.editCategory.fetched = false;
        draft.editCategory.error = false;
        break;

      case editCategory.SUCCESS: {
        draft.editCategory.fetching = false;
        draft.editCategory.fetched = true;
        draft.editCategory.category = payload;
        break;
      }

      case editCategory.FAILURE:
        draft.editCategory.error = payload;
        draft.editCategory.fetched = false;
        draft.editCategory.fetching = false;
        break;

      case editCategory.RESET:
        draft.editCategory = { ...initialState.editCategory };
        break;

      // get products sort category
      case getProductsSortCategory.REQUEST:
        draft.getProductsSortCategory = {
          ...initialState.getProductsSortCategory,
          fetching: true,
        };
        break;

      case getProductsSortCategory.SUCCESS: {
        draft.getProductsSortCategory.fetching = false;
        draft.getProductsSortCategory.fetched = true;
        draft.getProductsSortCategory.products = payload;
        break;
      }

      case getProductsSortCategory.FAILURE:
        draft.getProductsSortCategory.error = payload;
        draft.getProductsSortCategory.fetched = false;
        draft.getProductsSortCategory.fetching = false;
        break;

      case getProductsSortCategory.RESET:
        draft.getProductsSortCategory = { ...initialState.getProductsSortCategory };
        break;

      // update products sort category
      case updateProductsSortCategory.REQUEST:
        draft.updateProductsSortCategory = {
          ...initialState.updateProductsSortCategory,
          fetching: true,
        };
        break;

      case updateProductsSortCategory.SUCCESS: {
        draft.updateProductsSortCategory.fetching = false;
        draft.updateProductsSortCategory.fetched = true;
        draft.updateProductsSortCategory.products = payload;
        draft.getProductsSortCategory.products = payload;
        break;
      }

      case updateProductsSortCategory.FAILURE:
        draft.updateProductsSortCategory.error = payload.error;
        draft.updateProductsSortCategory.fetched = false;
        draft.getProductsSortCategory.fetching = false;
        draft.getProductsSortCategory.products = payload.productsBackup;
        break;

      case updateProductsSortCategory.RESET:
        draft.updateProductsSortCategory = { ...initialState.updateProductsSortCategory };
        break;

      default:
        return draft;
    }
  });

export default categoryDetailsReducer;
