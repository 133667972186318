/* eslint-disable @typescript-eslint/no-explicit-any */
import NumberFormat from 'react-number-format';

import InputBase from './InputBase';

function NumberFieldGroup({ inputRef, thousandSeparator, onChange, ...others }: any) {
  return (
    <NumberFormat
      {...others}
      allowNegative={false}
      customInput={InputBase}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.value || '');
      }}
      thousandSeparator={thousandSeparator}
    />
  );
}

NumberFieldGroup.input = InputBase;

export default NumberFieldGroup;
