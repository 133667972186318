import { takeLatest } from 'redux-saga/effects';

import {
  getModifierGroups,
  getPaginationModifierGroup,
  createModifierGroup,
  duplicateModifierGroup,
  deleteModifierGroup,
} from '../actions';

import { handleGetModifierGroupsRequest, getPaginationModifierGroupSaga } from './getModifierGroupsSaga';
import { handleDuplicateModifierGroupRequest } from './duplicateModifierGroupSaga';
import { handleDeleteModifierGroupRequest } from './deleteModifierGroupSaga';
import { handleCreateModifierGroupRequest } from './createModifierGroupSaga';

export default function* modifierGroupsSaga() {
  yield takeLatest(getModifierGroups.TRIGGER, handleGetModifierGroupsRequest);
  yield takeLatest(getPaginationModifierGroup.TRIGGER, getPaginationModifierGroupSaga);
  yield takeLatest(deleteModifierGroup.TRIGGER, handleDeleteModifierGroupRequest);
  yield takeLatest(duplicateModifierGroup.TRIGGER, handleDuplicateModifierGroupRequest);
  yield takeLatest(createModifierGroup.TRIGGER, handleCreateModifierGroupRequest);
}
