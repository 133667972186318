const STATUS = 'status';
const BRANDS = 'brands';
const PROVIDERS = 'providers';
const ORDER_TYPES = 'orderTypes';
const START_DATE = 'startDate';
const END_DATE = 'endDate';
const PAYMENT_TYPES = 'paymentTypes';
const VOID_TYPE = 'voidType';
const DISCOUNT_TYPES = 'discountTypes';
const USERS = 'users';
const ORDER_REFERENCE = 'orderReference';

export default {
  START_DATE,
  END_DATE,
  STATUS,
  BRANDS,
  PROVIDERS,
  ORDER_TYPES,
  PAYMENT_TYPES,
  VOID_TYPE,
  DISCOUNT_TYPES,
  USERS,
  ORDER_REFERENCE,
};
