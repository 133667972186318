import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getDiningOptions,
  getDiningOptionsPagination,
  getDiningOptionDetail,
  createDiningOption,
  deleteDiningOption,
  editDiningOption,
  setIsEnabledDiningOption,
} from './actions';
import {
  selectDiningOptions,
  selectDiningOptionsFetching,
  selectDiningOptionsFetched,
  selectDiningOptionsError,
  selectCreateDiningOption,
  selectCreateDiningOptionFetching,
  selectCreateDiningOptionFetched,
  selectCreateDiningOptionError,
  selectDeleteDiningOptionFetching,
  selectDeleteDiningOptionFetched,
  selectDeleteDiningOptionError,
  selectEditDiningOption,
  selectEditDiningOptionFetching,
  selectEditDiningOptionFetched,
  selectEditDiningOptionError,
  selectSetIsEnabledDiningOptionFetching,
  selectSetIsEnabledDiningOptionFetched,
  selectSetIsEnabledDiningOptionError,
  selectDiningOptionDetail,
  selectDiningOptionDetailFetching,
  selectDiningOptionDetailFetched,
  selectDiningOptionDetailError,
  selectDiningOptionsPaginationState,
  selectDiningOptionsPaginationValues,
} from './selectors';

export function withDiningOptionsHoc(Component) {
  function withDiningOptions(props) {
    return <Component {...props} />;
  }

  withDiningOptions.displayName = `withDiningOptions(${getDisplayName(Component)})`;

  return withDiningOptions;
}

const mapStateToProps = createStructuredSelector({
  diningOptionsList: selectDiningOptions,
  diningOptionsFetching: selectDiningOptionsFetching,
  diningOptionsFetched: selectDiningOptionsFetched,
  diningOptionsError: selectDiningOptionsError,
  createDiningOptionObject: selectCreateDiningOption,
  createDiningOptionFetching: selectCreateDiningOptionFetching,
  createDiningOptionFetched: selectCreateDiningOptionFetched,
  createDiningOptionError: selectCreateDiningOptionError,
  deleteDiningOptionFetching: selectDeleteDiningOptionFetching,
  deleteDiningOptionFetched: selectDeleteDiningOptionFetched,
  deleteDiningOptionError: selectDeleteDiningOptionError,
  editDiningOptionObject: selectEditDiningOption,
  editDiningOptionFetching: selectEditDiningOptionFetching,
  editDiningOptionFetched: selectEditDiningOptionFetched,
  editDiningOptionError: selectEditDiningOptionError,
  setIsEnabledDiningOptionFetching: selectSetIsEnabledDiningOptionFetching,
  setIsEnabledDiningOptionFetched: selectSetIsEnabledDiningOptionFetched,
  setIsEnabledDiningOptionError: selectSetIsEnabledDiningOptionError,
  diningOptionDetailObject: selectDiningOptionDetail,
  diningOptionDetailFetching: selectDiningOptionDetailFetching,
  diningOptionDetailFetched: selectDiningOptionDetailFetched,
  diningOptionDetailError: selectDiningOptionDetailError,
  diningOptionsPaginationState: selectDiningOptionsPaginationState,
  diningOptionsPaginationValues: selectDiningOptionsPaginationValues,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadDiningOptions: (filters) => dispatch(getDiningOptions(filters)),
    loadDiningOptionsPagination: (filters) => dispatch(getDiningOptionsPagination(filters)),
    loadDiningOptionDetail: ({ storeUuid, diningOptionUuid }) =>
      dispatch(getDiningOptionDetail({ storeUuid, diningOptionUuid })),
    createDiningOption: ({ storeUuid, diningOption }) => dispatch(createDiningOption({ storeUuid, diningOption })),
    deleteDiningOption: ({ storeUuid, diningOptionUuid }) =>
      dispatch(deleteDiningOption({ storeUuid, diningOptionUuid })),
    editDiningOption: ({ storeUuid, diningOptionUuid, diningOption }) =>
      dispatch(editDiningOption({ storeUuid, diningOptionUuid, diningOption })),
    setIsEnabledDiningOption: ({ storeUuid, diningOptionUuid, isEnabled }) =>
      dispatch(setIsEnabledDiningOption({ storeUuid, diningOptionUuid, isEnabled })),
    resetDiningOptions: () => dispatch(getDiningOptions.reset()),
    resetDiningOptionsPagination: () => dispatch(getDiningOptionsPagination.reset()),
    resetDiningOptionDetail: () => dispatch(getDiningOptionDetail.reset()),
    resetCreateDiningOption: () => dispatch(createDiningOption.reset()),
    resetDeleteDiningOption: () => dispatch(deleteDiningOption.reset()),
    resetEditDiningOption: () => dispatch(editDiningOption.reset()),
    resetSetIsEnabledDiningOption: () => dispatch(setIsEnabledDiningOption.reset()),
  };
}

export const withDiningOptionsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withDiningOptionsState, withDiningOptionsHoc);
