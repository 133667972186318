import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getPermissionsRequest } from 'utils/api/baas/workforce/roles';

import { getPermissions } from '../actions';

export function* handleGetPermissionsRequest() {
  try {
    yield put(getPermissions.request());

    const data = yield call(baasRequest, getPermissionsRequest);

    yield put(getPermissions.success(data));
  } catch (err) {
    yield put(getPermissions.failure(err));
  }
}
