import ListMui from '@material-ui/core/List';

import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    minWidth: 300,
  },

  listbox: {
    listStyle: 'none',
    margin: 0,
    padding: '16px 0',
    maxHeight: '40vh',
    overflow: 'auto',
  },
}));

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

function List({ anchorEl, children, onClose, open }: Props) {
  const classes = useStyles();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
      open={open && Boolean(anchorEl)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Paper className={classes.paper}>
        <ListMui component="nav">{children}</ListMui>
      </Paper>
    </Popover>
  );
}

export default List;
