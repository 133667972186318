/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDebounce } from '@open-react-hooks/use-debounce';

import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Box from '@material-ui/core/Box';

import PopupOptions from './PopupOptions';
import useStyles from './styles';

function LiveSearch(props) {
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const [anchorEl, setAnchorRefEl] = useState(null);
  const [focused, setFocused] = useState(false);

  const { debounce } = useDebounce({ delay: 300 });

  const liveSearchRef = useRef(null);

  const {
    id,
    placeholder,
    getOptionLabel = (x) => x,
    getOptionKey = (x) => x,
    renderOption: renderOptionProp,
    isDisabled,
    noOptionsText,
    hasNextPage,
    isNextPageLoading,
    onLoadNextPage,
    endAdornment,
    isFilterLoading,
    onChangeInput,
    options,
    onChange,
  } = props;

  const renderOption = renderOptionProp || getOptionLabel;

  const handleOnClick = () => {
    if (!openPopup) setAnchorRefEl(liveSearchRef.current);
    setOpenPopup(!openPopup);
  };

  const handleOnChange = (e) => {
    setOpenPopup(true);
    setAnchorRefEl(liveSearchRef.current);
    const { value } = e.target;
    if (!isFilterLoading) onChangeInput(value);
  };

  const handleOnBlur = () => {
    setOpenPopup(false);
    setAnchorRefEl(null);
    setFocused(false);
  };

  const handleSelectedOption = (option) => () => {
    setOpenPopup(false);
    setAnchorRefEl(null);
    onChange(option);
  };

  const renderListOption = () => {
    return options.map((option) => (
      <li key={getOptionKey(option)} className={classes.option} onClick={handleSelectedOption(option)}>
        {renderOption(option)}
      </li>
    ));
  };

  return (
    <div>
      <Box
        ref={liveSearchRef}
        className={clsx(classes.inputWrapper, {
          [classes.inputWrapperFocus]: focused,
          [classes.inputWrapperEndAdornment]: Boolean(endAdornment),
        })}
        id={id}
      >
        <input
          className={clsx(classes.inputBase, { [classes.inputEndAdornment]: Boolean(endAdornment) })}
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={debounce(handleOnChange)}
          onClick={handleOnClick}
          onFocus={() => setFocused(true)}
          placeholder={placeholder}
        />
        {endAdornment && <>{endAdornment}</>}
      </Box>

      {openPopup && anchorEl ? (
        <Popper
          anchorEl={anchorEl}
          className={clsx(classes.popper)}
          open
          role="presentation"
          style={{
            width: anchorEl ? anchorEl.clientWidth : null,
          }}
        >
          <Paper className={classes.paper}>
            <PopupOptions
              hasNextPage={hasNextPage}
              isFilterLoading={isFilterLoading}
              isNextPageLoading={isNextPageLoading}
              noOptionsText={noOptionsText}
              onLoadNextPage={onLoadNextPage}
              renderListOption={renderListOption}
            />
          </Paper>
        </Popper>
      ) : null}
    </div>
  );
}

LiveSearch.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.node,
  isDisabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionKey: PropTypes.func,
  noOptionsText: PropTypes.node,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  renderOption: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
  onLoadNextPage: PropTypes.func,
  endAdornment: PropTypes.node,
  isFilterLoading: PropTypes.bool,
  onChangeInput: PropTypes.func,
};

export default LiveSearch;
