import queryString from 'query-string';
import request from 'utils/request';

import { getUserCurrentStore } from 'utils/auth';
import { createHeaders } from 'utils/baasRequest';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

interface PropsSkuList {
  storeUuid?: string;
  searchValue?: string;
  objectType: string;
  isSku?: boolean;
  page?: string | number;
  pageSize?: string | number;
}

export function getSkuListRequest(
  { storeUuid: storeUuidArg, searchValue, objectType, isSku, page = 1, pageSize = 20 }: PropsSkuList,
  options = {},
) {
  const storeUuid = storeUuidArg ?? getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    search_value: searchValue ?? undefined,
    object_type: objectType,
    is_sku: isSku,
    page: page ?? 1,
    page_size: pageSize,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/sku/search/?${query}`, {
    method: 'GET',
    headers: createHeaders(),
    ...options,
  });
}
