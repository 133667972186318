import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import { GET_EMPLOYEES, CREATE_EMPLOYEE, DELETE_EMPLOYEE } from './constants';

export const getEmployees = extendRoutine(createRoutine(GET_EMPLOYEES), [
  'SORTING',
  'RESET_SORTING',
  'PAGINATION',
  'RESET_PAGINATION',
  'RESET',
]);

export const createEmployee = extendRoutine(createRoutine(CREATE_EMPLOYEE), 'RESET');
export const deleteEmployee = extendRoutine(createRoutine(DELETE_EMPLOYEE), 'RESET');
