import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_DINING_OPTIONS,
  GET_DINING_OPTIONS_PAGINATION,
  GET_DINING_OPTION_DETAIL,
  CREATE_DINING_OPTION,
  DELETE_DINING_OPTION,
  EDIT_DINING_OPTION,
  SET_IS_ENABLED_DINING_OPTION,
} from './constants';

export const getDiningOptions = extendRoutine(createRoutine(GET_DINING_OPTIONS), [
  'SORTING',
  'RESET_SORTING',
  'PAGINATION',
  'RESET_PAGINATION',
  'RESET',
]);
export const getDiningOptionsPagination = extendRoutine(createRoutine(GET_DINING_OPTIONS_PAGINATION), 'RESET');
export const getDiningOptionDetail = extendRoutine(createRoutine(GET_DINING_OPTION_DETAIL), 'RESET');

export const createDiningOption = extendRoutine(createRoutine(CREATE_DINING_OPTION), 'RESET');
export const deleteDiningOption = extendRoutine(createRoutine(DELETE_DINING_OPTION), 'RESET');
export const editDiningOption = extendRoutine(createRoutine(EDIT_DINING_OPTION), 'RESET');
export const setIsEnabledDiningOption = extendRoutine(createRoutine(SET_IS_ENABLED_DINING_OPTION), 'RESET');
