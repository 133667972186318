import produce from 'immer';
import mapKeys from 'lodash/mapKeys';

import baseState from 'utils/baseState';
import { DEFAULT_PAGE } from 'utils/menuMaker';

import {
  getMenus,
  getPaginationMenu,
  createMenu,
  publishMenu,
  duplicateMenu,
  deleteMenu,
  copyMenuConfig,
  publishMenus,
  menusDetailsResume,
  syncProductOverride,
} from './actions';

export const initialState = {
  getMenus: {
    error: null,
    fetching: false,
    fetched: false,
    list: [],
    pagination: { page: DEFAULT_PAGE },
  },

  paginationMenu: {
    fetching: false,
    fetched: false,
    error: null,
  },

  createMenu: {
    error: null,
    fetching: false,
    fetched: false,
    menu: {},
  },

  publishMenu: {
    error: null,
    fetching: false,
    fetched: false,
  },

  duplicateMenu: {
    error: null,
    fetching: false,
    fetched: false,
    duplicateMenuObject: {},
  },

  deleteMenu: {
    error: null,
    fetching: false,
    fetched: false,
  },

  copyMenuConfig: {
    error: null,
    fetching: false,
    fetched: false,
    menu: {},
    stores: [],
  },

  publishMenus: {
    error: null,
    fetching: false,
    fetched: false,
  },

  menusDetailsResume: {
    ...baseState,
    menus: {},
  },
};

const menusReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getMenus.REQUEST:
        draft.getMenus.fetching = true;
        draft.getMenus.error = false;
        draft.getMenus.fetched = false;
        draft.getMenus.pagination = { ...initialState.getMenus.pagination };
        break;

      case getMenus.SUCCESS: {
        draft.getMenus.fetching = false;
        draft.getMenus.fetched = true;
        draft.getMenus.list = payload.data;
        draft.getMenus.pagination = { ...draft.getMenus.pagination, ...payload.pagination };
        draft.menusDetailsResume.menus = {};

        if (payload.isResume) {
          payload?.data?.forEach((menu) => {
            draft.menusDetailsResume.menus[menu?.uuid] = {
              isLoading: false,
              isLoaded: false,
              categories: {},
            };
          });
        }
        break;
      }

      case getMenus.FAILURE:
        draft.getMenus.error = payload;
        draft.getMenus.fetching = false;
        draft.getMenus.fetched = false;
        break;

      case getMenus.SORT_POSITION:
        draft.getMenus.list = payload;
        break;

      case getMenus.UPDATE: {
        draft.getMenus.list.forEach((menu, index) => {
          if (menu.uuid === payload.uuid) {
            draft.getMenus.list[index] = payload;
          }
        });
        break;
      }

      case getMenus.RESET:
        draft.getMenus = { ...initialState.getMenus };
        break;

      case getPaginationMenu.REQUEST:
        draft.paginationMenu.fetching = true;
        draft.paginationMenu.fetched = false;
        draft.paginationMenu.error = null;
        break;

      case getPaginationMenu.SUCCESS: {
        const newMenus = [...draft.getMenus.list, ...payload.data];

        draft.paginationMenu.fetching = false;
        draft.paginationMenu.fetched = true;
        draft.paginationMenu.error = null;
        draft.getMenus.list = newMenus;
        draft.getMenus.pagination = { ...draft.getMenus.pagination, ...payload.pagination, page: payload.page };

        if (payload?.isResume) {
          payload?.data?.forEach((menu) => {
            draft.menusDetailsResume.menus[menu?.uuid] = {
              isLoading: false,
              isLoaded: false,
              categories: {},
            };
          });
        }

        break;
      }

      case getPaginationMenu.FAILURE:
        draft.paginationMenu.error = payload;
        draft.paginationMenu.fetching = false;
        draft.paginationMenu.fetched = false;
        break;

      case getPaginationMenu.RESET:
        draft.paginationMenu = { ...initialState.paginationMenu };
        break;

      case createMenu.REQUEST:
        draft.createMenu.fetching = true;
        draft.createMenu.error = false;
        draft.createMenu.fetched = false;
        break;

      case createMenu.SUCCESS: {
        const menusList = [...draft.getMenus.list];
        menusList.unshift(payload);

        draft.createMenu.fetching = false;
        draft.createMenu.fetched = true;
        draft.createMenu.menu = payload;
        draft.getMenus.list = menusList;

        break;
      }

      case createMenu.FAILURE:
        draft.createMenu.error = payload;
        draft.createMenu.fetching = false;
        draft.createMenu.fetched = false;
        break;

      case createMenu.RESET:
        draft.createMenu = { ...initialState.createMenu };
        break;

      case publishMenu.REQUEST:
        draft.publishMenu.fetching = true;
        draft.publishMenu.error = false;
        draft.publishMenu.fetched = false;
        break;

      case publishMenu.SUCCESS: {
        draft.publishMenu.fetching = false;
        draft.publishMenu.fetched = true;
        break;
      }

      case publishMenu.FAILURE:
        draft.publishMenu.error = payload;
        draft.publishMenu.fetching = false;
        draft.publishMenu.fetched = false;
        break;

      case publishMenu.RESET:
        draft.publishMenu = { ...initialState.publishMenu };
        break;

      case duplicateMenu.REQUEST:
        draft.duplicateMenu.fetching = true;
        draft.duplicateMenu.error = false;
        draft.duplicateMenu.fetched = false;
        break;

      case duplicateMenu.SUCCESS:
        draft.duplicateMenu.fetching = false;
        draft.duplicateMenu.fetched = true;
        draft.getMenus.duplicateMenuObject = payload;
        break;

      case duplicateMenu.FAILURE:
        draft.duplicateMenu.error = payload;
        draft.duplicateMenu.fetching = false;
        draft.duplicateMenu.fetched = false;
        break;

      case duplicateMenu.RESET:
        draft.duplicateMenu = { ...initialState.duplicateMenu };
        break;

      case deleteMenu.REQUEST:
        draft.deleteMenu.fetching = true;
        draft.deleteMenu.error = false;
        draft.deleteMenu.fetched = false;
        break;

      case deleteMenu.SUCCESS: {
        draft.deleteMenu.fetching = false;
        draft.deleteMenu.fetched = true;
        break;
      }

      case deleteMenu.FULFILL: {
        if (!draft.deleteMenu.error) {
          const menusList = draft.getMenus.list.filter((menu) => menu.uuid !== payload);
          draft.getMenus.list = menusList;
        }
        break;
      }

      case deleteMenu.FAILURE:
        draft.deleteMenu.error = payload;
        draft.deleteMenu.fetching = false;
        draft.deleteMenu.fetched = false;
        break;

      case deleteMenu.RESET:
        draft.deleteMenu = { ...initialState.deleteMenu };
        break;

      case copyMenuConfig.REQUEST:
        draft.copyMenuConfig.fetching = true;
        break;

      case copyMenuConfig.SUCCESS:
        draft.copyMenuConfig.fetching = false;
        draft.copyMenuConfig.fetched = true;
        draft.copyMenuConfig.menu = payload.menu;
        draft.copyMenuConfig.stores = payload.stores;
        break;

      case copyMenuConfig.FAILURE:
        draft.copyMenuConfig.error = payload;
        draft.copyMenuConfig.fetching = false;
        draft.copyMenuConfig.fetched = false;
        break;

      case copyMenuConfig.RESET:
        draft.copyMenuConfig = { ...initialState.copyMenuConfig };
        break;

      case publishMenus.REQUEST:
        draft.publishMenus.fetching = true;
        break;

      case publishMenus.SUCCESS: {
        draft.publishMenus.fetching = false;
        draft.publishMenus.fetched = true;
        break;
      }

      case publishMenus.FAILURE:
        draft.publishMenus.error = payload;
        draft.publishMenus.fetching = false;
        draft.publishMenus.fetched = false;
        break;

      case publishMenus.RESET:
        draft.publishMenus = { ...initialState.publishMenus };
        break;

      case menusDetailsResume.REQUEST: {
        draft.menusDetailsResume.fetching = true;
        if (payload?.isLoadMenu) {
          draft.menusDetailsResume.menus[payload?.menuUuid].isLoading = true;
        }

        if (payload?.isLoadCategory) {
          draft.menusDetailsResume.menus[payload?.menuUuid].categories[payload?.categoryUuid].isLoading = true;
        }

        break;
      }

      case menusDetailsResume.SUCCESS: {
        draft.menusDetailsResume.fetching = false;
        draft.menusDetailsResume.fetched = true;

        if (payload?.isLoadMenu) {
          if (draft.menusDetailsResume.menus[payload?.menuUuid]) {
            draft.menusDetailsResume.menus[payload?.menuUuid].isLoading = false;
            draft.menusDetailsResume.menus[payload?.menuUuid].isLoaded = true;
            draft.menusDetailsResume.menus[payload?.menuUuid].categories = {};

            payload?.data?.forEach((category, index) => {
              draft.menusDetailsResume.menus[payload?.menuUuid].categories[category?.uuid] = {
                isLoading: false,
                isLoaded: index < 2,
                openDefault: index < 2,
                ...category,
              };
            });
          }
        }

        if (payload?.isLoadCategory) {
          draft.menusDetailsResume.menus[payload?.menuUuid].categories[payload?.categoryUuid] = {
            isLoading: false,
            isLoaded: true,
            ...payload?.data[0],
          };
        }
        break;
      }

      case menusDetailsResume.FAILURE: {
        draft.menusDetailsResume.error = payload?.error;
        draft.menusDetailsResume.fetching = false;
        draft.menusDetailsResume.fetched = false;

        if (payload?.isLoadMenu) {
          if (draft.menusDetailsResume.menus[payload?.menuUuid]) {
            draft.menusDetailsResume.menus[payload?.menuUuid].isLoading = false;
          }
        }

        if (payload?.isLoadCategory) {
          draft.menusDetailsResume.menus[payload?.menuUuid].categories[payload?.categoryUuid].isLoading = false;
        }

        break;
      }

      case menusDetailsResume.INVALIDATE_MENUS: {
        Object.entries(draft.menusDetailsResume.menus).forEach(([key]) => {
          if (draft.menusDetailsResume.menus[key]?.isLoaded) {
            draft.menusDetailsResume.menus[key].isLoaded = false;
          }
        });

        break;
      }

      case menusDetailsResume.RESET:
        draft.menusDetailsResume = { ...initialState.menusDetailsResume };
        break;

      case syncProductOverride.SUCCESS: {
        const { menuUuid, productUuid, modifiers, availability, price, isRestore } = payload;

        mapKeys(state.menusDetailsResume.menus[menuUuid]?.categories, (_, categoryUuid) => {
          state.menusDetailsResume.menus[menuUuid]?.categories[categoryUuid].products.forEach((product, index) => {
            if (product?.uuid === productUuid) {
              const currentProduct = state.menusDetailsResume.menus[menuUuid]?.categories[categoryUuid].products[index];

              if (isRestore) {
                draft.menusDetailsResume.menus[menuUuid].categories[categoryUuid].products[index] = {
                  ...currentProduct,
                  override: null,
                  modifierGroups: modifiers,
                };
              } else {
                draft.menusDetailsResume.menus[menuUuid].categories[categoryUuid].products[index] = {
                  ...currentProduct,
                  override: {
                    ...currentProduct.override,
                    availability,
                    price,
                  },
                };
              }
            }
          });
        });
        break;
      }

      default:
        return draft;
    }
  });

export default menusReducer;
