import produce from 'immer';

import { validatePin, generatePosPin } from './actions';

export const initialState = {
  validatePin: {
    error: null,
    fetching: false,
    fetched: false,
  },

  generatePosPin: {
    error: null,
    fetching: false,
    fetched: false,
    posPin: null,
  },
};

const employeesProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case validatePin.REQUEST:
        draft.validatePin.fetching = true;
        draft.validatePin.error = false;
        draft.validatePin.fetched = false;
        break;

      case validatePin.SUCCESS:
        draft.validatePin.fetching = false;
        draft.validatePin.fetched = true;

        break;

      case validatePin.FAILURE:
        draft.validatePin.error = payload;
        draft.validatePin.fetching = false;
        draft.validatePin.fetched = false;
        break;

      case validatePin.RESET:
        draft.validatePin = { ...initialState.validatePin };
        break;

      case generatePosPin.REQUEST:
        draft.generatePosPin.fetching = true;
        draft.generatePosPin.error = false;
        draft.generatePosPin.fetched = false;
        break;

      case generatePosPin.SUCCESS:
        draft.generatePosPin.fetching = false;
        draft.generatePosPin.fetched = true;
        draft.generatePosPin.posPin = payload;

        break;

      case generatePosPin.FAILURE:
        draft.generatePosPin.error = payload;
        draft.generatePosPin.fetching = false;
        draft.generatePosPin.fetched = false;
        break;

      case generatePosPin.RESET:
        draft.generatePosPin = { ...initialState.generatePosPin };
        break;

      default:
        return draft;
    }
  });

export default employeesProviderReducer;
