const TRUE = 'TRUE';
const FALSE = 'FALSE';

export const booleansTypes = {
  TRUE,
  FALSE,
};

export const booleansLabels = {
  [TRUE]: 'common:buttons.yes',
  [FALSE]: 'common:buttons.no',
};

export const booleansTypesValues = {
  [TRUE]: true,
  [FALSE]: false,
};

export const booleansTypesDefaultValues = {
  true: TRUE,
  false: FALSE,
};
