import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

function Title({ children }) {
  return children;
}

function MenuSkeleton({ children }) {
  return (
    <Grid container data-testid="menuSkeleton" direction="column" spacing={2}>
      <Grid item>{children}</Grid>

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={70} variant="text" width="100%" />
      </Grid>

      <Grid item xs={12}>
        <Grid item>
          <Box mb={3}>
            <Skeleton height={25} varaint="text" width={100} />
            <Skeleton height={15} variant="text" width={150} />
          </Box>
        </Grid>

        <Grid container spacing={3}>
          {[...Array(2).keys()].map((item) => (
            <Grid key={item} item xs={6}>
              <Skeleton height={25} varaint="text" width={100} />
              <Skeleton height={70} variant="text" width="100%" />
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={3}>
          {[...Array(2).keys()].map((item) => (
            <Grid key={item} item xs={6}>
              <Skeleton height={120} variant="rect" width="100%" />
            </Grid>
          ))}
        </Grid>

        <Box mt={3}>
          <Skeleton height={42} variant="rect" width={140} />
        </Box>

        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={15} variant="text" width={150} />
        <Skeleton height={70} variant="text" width="100%" />

        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mb={3}>
          <Skeleton height={25} varaint="text" width={150} />
          <Skeleton height={15} variant="text" width={150} />
        </Box>

        <Grid container spacing={3}>
          {[...Array(5).keys()].map((item) => (
            <Grid key={item} item xs={6}>
              <Skeleton height={45} variant="rect" width="100%" />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item>
        <Box my={3}>
          <Skeleton height={25} varaint="text" width={150} />
          <Skeleton height={15} variant="text" width={150} />
        </Box>

        <Skeleton height={70} variant="rect" width="100%" />
        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>
      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={15} variant="text" width="100%" />
        <Box display="flex" gridColumnGap={8} justifyContent="space-between">
          <Skeleton height={70} variant="text" width="100%" />
          <Skeleton height={70} variant="text" width={170} />
        </Box>
      </Grid>
    </Grid>
  );
}

MenuSkeleton.Title = Title;

MenuSkeleton.propTypes = {
  children: PropTypes.node,
};

export default MenuSkeleton;
