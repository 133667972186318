import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_PAYMENTS,
  GET_PAYMENT_DETAIL,
  CREATE_PAYMENT,
  DELETE_PAYMENT,
  TOGGLE_AVAILABILITY_PAYMENT,
  EDIT_PAYMENT,
  UPDATE_PAYMENT_POSITION,
  GET_PAYMENTS_SCROLL_PAGINATION,
} from './constants';

export const getPayments = extendRoutine(createRoutine(GET_PAYMENTS), [
  'SORTING',
  'RESET_SORTING',
  'PAGINATION',
  'RESET_PAGINATION',
  'RESET',
  'UPDATE_PAYMENT_POSITION',
  'GET_PAYMENTS_SCROLL_PAGINATION',
  'SORT_POSITION',
]);

export const getPaymentDetail = extendRoutine(createRoutine(GET_PAYMENT_DETAIL), 'RESET');
export const createPayment = extendRoutine(createRoutine(CREATE_PAYMENT), 'RESET');
export const deletePayment = extendRoutine(createRoutine(DELETE_PAYMENT), 'RESET');
export const togglePaymentAvailability = extendRoutine(createRoutine(TOGGLE_AVAILABILITY_PAYMENT), 'RESET');
export const editPayment = extendRoutine(createRoutine(EDIT_PAYMENT), 'RESET');
export const updatePaymentPosition = createRoutine(UPDATE_PAYMENT_POSITION);
export const getPaymentsScrollPagination = extendRoutine(createRoutine(GET_PAYMENTS_SCROLL_PAGINATION), 'RESET');
