import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const DeleteOutline = forwardRef(function DeleteOutline(props, ref) {
  const { color = '#DF130E', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M8.5 5L9.5 4H14.5L15.5 5H17.5C17.7761 5 18 5.22386 18 5.5C18 5.77614 17.7761 6 17.5 6H6.5C6.22386 6 6 5.77614 6 5.5C6 5.22386 6.22386 5 6.5 5H8.5ZM6.5 8.5C6.5 8.22386 6.72386 8 7 8H17C17.2761 8 17.5 8.22386 17.5 8.5V20.5C17.5 20.7761 17.2761 21 17 21H7C6.72386 21 6.5 20.7761 6.5 20.5V8.5ZM7.5 9V20H16.5V9H7.5Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

DeleteOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DeleteOutline;
