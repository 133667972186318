/* eslint-disable react/no-array-index-key */
import Skeleton from '@material-ui/lab/Skeleton';

import ReportsTable from '.';

interface Props {
  placeholderRows?: number;
  placeholderCols?: number;
  width?: string | number;
  heightCol?: string | number;
}

function PlaceholdersTable({ placeholderRows = 3, placeholderCols = 3, width = '100%', heightCol = 20 }: Props) {
  return (
    <>
      {[...Array(placeholderRows)].map((row, i) => (
        <ReportsTable.Row key={`tr-${i}`}>
          {[...Array(placeholderCols)].map((col, j) => (
            <ReportsTable.Cell key={`tr-${i}-td-${j}`}>
              <Skeleton height={heightCol} width={width} />
            </ReportsTable.Cell>
          ))}
        </ReportsTable.Row>
      ))}
    </>
  );
}

export default PlaceholdersTable;
