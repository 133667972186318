import { useCallback, useState } from 'react';

import {
  getUserStoresStoreKey,
  setUserCurrentStore,
  getUserCurrentStore,
  getStoreDraftUser,
  setStoreBackupUser,
  getStoreBackupUser,
  getCookieUser,
} from 'utils/auth';

function useUserStore() {
  const currentStore = getUserCurrentStore();

  const storeUuid = currentStore?.uuid;

  const authUser = getCookieUser();

  const [storeState, setStoreState] = useState({
    selected: getUserCurrentStore(),
    stores: getUserStoresStoreKey() || [],
    storeDraft: getStoreDraftUser(),
  });
  const [storeBackup, setStoreBackup] = useState(getStoreBackupUser());

  const handleSetStore = useCallback((store) => {
    setUserCurrentStore(store);
    setStoreState((currentState) => ({ ...currentState, selected: store }));
  }, []);

  const handleSetStoreBackup = (store) => {
    setStoreBackup(store);
    setStoreBackupUser(store);
  };

  return {
    storeId: storeUuid, // @depreciated
    storeUuid, // @depreciated
    storeName: currentStore?.name,
    user: authUser, // @depreciated
    storeState,
    userState: {
      ...authUser,
    },
    setStore: handleSetStore,
    storeBackup,
    setStoreBackup: handleSetStoreBackup,
  };
}

export default useUserStore;
