import produce from 'immer';

import baseState from 'utils/baseState';

import {
  getClientData,
  getClientDetails,
  getPaginationClients,
  exportClientsReport,
  getPaginationDetails,
} from './actions';

export const initialState = {
  clientData: {
    ...baseState,
    data: null,
    pagination: null,
  },

  paginationClients: {
    ...baseState,
  },

  exportClientsReport: {
    ...baseState,
    document: null,
  },

  clientDetails: {
    ...baseState,
    data: null,
    pagination: null,
  },

  paginationDetails: {
    ...baseState,
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getClientData.REQUEST:
        draft.clientData.fetching = true;
        draft.clientData.fetched = false;
        draft.clientData.error = null;
        draft.clientData.data = null;
        draft.clientData.pagination = null;
        break;

      case getClientData.SUCCESS:
        draft.clientData.fetching = false;
        draft.clientData.fetched = true;
        draft.clientData.error = null;
        draft.clientData.data = payload.data;
        draft.clientData.pagination = payload.pagination;
        break;

      case getClientData.FAILURE:
        draft.clientData.fetching = false;
        draft.clientData.fetched = false;
        draft.clientData.error = payload;
        draft.clientData.data = null;
        draft.clientData.pagination = null;
        break;

      case getClientData.RESET:
        draft.clientData = { ...initialState.clientData };
        break;

      case getPaginationClients.REQUEST:
        draft.paginationClients.fetching = true;
        draft.paginationClients.fetched = false;
        draft.paginationClients.error = null;
        break;

      case getPaginationClients.SUCCESS: {
        const newData = { ...draft.clientData.data, rows: [...draft.clientData.data.rows, ...payload.data.rows] };

        draft.paginationClients.fetching = false;
        draft.paginationClients.fetched = true;
        draft.paginationClients.error = null;
        draft.clientData.data = newData;
        draft.clientData.pagination = payload.pagination;
        break;
      }

      case getPaginationClients.FAILURE:
        draft.paginationClients.fetching = false;
        draft.paginationClients.fetched = false;
        draft.paginationClients.error = payload;
        break;

      case getPaginationClients.RESET:
        draft.paginationClients = { ...initialState.paginationClients };
        break;

      case exportClientsReport.REQUEST:
        draft.exportClientsReport.fetching = true;
        draft.exportClientsReport.fetched = false;
        draft.exportClientsReport.error = null;
        draft.exportClientsReport.document = null;
        break;

      case exportClientsReport.SUCCESS:
        draft.exportClientsReport.fetching = false;
        draft.exportClientsReport.fetched = true;
        draft.exportClientsReport.error = null;
        draft.exportClientsReport.document = payload;
        break;

      case exportClientsReport.FAILURE:
        draft.exportClientsReport.fetching = false;
        draft.exportClientsReport.fetched = false;
        draft.exportClientsReport.error = payload;
        draft.exportClientsReport.document = null;
        break;

      case exportClientsReport.RESET:
        draft.exportClientsReport = { ...initialState.exportClientsReport };
        break;

      case getClientDetails.REQUEST:
        draft.clientDetails.fetching = true;
        draft.clientDetails.fetched = false;
        draft.clientDetails.error = null;
        draft.clientDetails.data = null;
        draft.clientDetails.pagination = null;
        break;

      case getClientDetails.SUCCESS:
        draft.clientDetails.fetching = false;
        draft.clientDetails.fetched = true;
        draft.clientDetails.error = null;
        draft.clientDetails.data = payload.data;
        draft.clientDetails.pagination = payload.pagination;
        break;

      case getClientDetails.FAILURE:
        draft.clientDetails.fetching = false;
        draft.clientDetails.fetched = false;
        draft.clientDetails.error = payload;
        draft.clientDetails.data = null;
        draft.clientDetails.pagination = null;
        break;

      case getClientDetails.RESET:
        draft.clientDetails = { ...initialState.clientDetails };
        break;

      case getPaginationDetails.REQUEST:
        draft.paginationDetails.fetching = true;
        draft.paginationDetails.fetched = false;
        draft.paginationDetails.error = null;
        break;

      case getPaginationDetails.SUCCESS: {
        const newDetails = {
          ...draft.clientDetails.data,
          rows: [...draft.clientDetails.data.rows, ...payload.data.rows],
        };

        draft.paginationDetails.fetching = false;
        draft.paginationDetails.fetched = true;
        draft.paginationDetails.error = null;
        draft.clientDetails.data = newDetails;
        draft.clientDetails.pagination = payload.pagination;
        break;
      }

      case getPaginationDetails.FAILURE:
        draft.paginationDetails.fetching = false;
        draft.paginationDetails.fetched = false;
        draft.paginationDetails.error = payload;
        break;

      case getPaginationDetails.RESET:
        draft.paginationDetails = { ...initialState.paginationDetails };
        break;

      default:
        return draft;
    }
  });

export default reducer;
