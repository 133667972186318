/**
 *
 * ParrotSoftware
 * SettingsTablesConfigProvider selectors
 *
 */
import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectSettingsTablesConfigProviderDomain = (state) => state.settingsTablesConfig || initialState;

const selectGetStoreTableSettings = createSelector(
  selectSettingsTablesConfigProviderDomain,
  (subState) => subState.getStoreTableSettings.config,
);

const selectGetStoreTableSettingsState = createSelector(
  selectSettingsTablesConfigProviderDomain,
  ({ getStoreTableSettings: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectUpdateTableConfigState = createSelector(
  selectSettingsTablesConfigProviderDomain,
  ({ updateTableConfig: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

export {
  selectSettingsTablesConfigProviderDomain,
  selectGetStoreTableSettings,
  selectGetStoreTableSettingsState,
  selectUpdateTableConfigState,
};
