import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.darkGrey.main,
  },
}));

export default useStyles;
