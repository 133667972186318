import { createSelector } from 'reselect';

import { getFilteredEmployeesByText } from 'utils/employees';

import { initialState } from './reducer';

const selectEmployeesProviderDomain = (state) => state.employees || initialState;

const selectEmployees = createSelector(selectEmployeesProviderDomain, ({ getEmployees, sorting }) => {
  let employeesList = JSON.parse(JSON.stringify(getEmployees.employees));

  const { filterByText } = sorting;

  if (filterByText && filterByText.length) {
    employeesList = getFilteredEmployeesByText(employeesList, sorting);
  }

  return employeesList;
});

const selectEmployeesPagination = createSelector(
  selectEmployeesProviderDomain,
  (subState) => subState.getEmployees.pagination,
);

const selectEmployeesSorting = createSelector(selectEmployeesProviderDomain, (subState) => subState.sorting);

const selectEmployeesFetching = createSelector(
  selectEmployeesProviderDomain,
  (subState) => subState.getEmployees.fetching,
);

const selectEmployeesFetched = createSelector(
  selectEmployeesProviderDomain,
  (subState) => subState.getEmployees.fetched,
);

const selectEmployeesError = createSelector(selectEmployeesProviderDomain, (subState) => subState.getEmployees.error);

const selectCreateEmployeeFetching = createSelector(
  selectEmployeesProviderDomain,
  (subState) => subState.createEmployee.fetching,
);

const selectCreateEmployeeFetched = createSelector(
  selectEmployeesProviderDomain,
  (subState) => subState.createEmployee.fetched,
);

const selectCreateEmployeeError = createSelector(
  selectEmployeesProviderDomain,
  (subState) => subState.createEmployee.error,
);

const selectDeleteEmployeeFetching = createSelector(
  selectEmployeesProviderDomain,
  (subState) => subState.deleteEmployee.fetching,
);

const selectDeleteEmployeeFetched = createSelector(
  selectEmployeesProviderDomain,
  (subState) => subState.deleteEmployee.fetched,
);

const selectDeleteEmployeeError = createSelector(
  selectEmployeesProviderDomain,
  (subState) => subState.deleteEmployee.error,
);

export {
  selectEmployeesProviderDomain,
  selectEmployees,
  selectEmployeesPagination,
  selectEmployeesSorting,
  selectEmployeesFetching,
  selectEmployeesFetched,
  selectEmployeesError,
  selectCreateEmployeeFetching,
  selectCreateEmployeeFetched,
  selectCreateEmployeeError,
  selectDeleteEmployeeFetching,
  selectDeleteEmployeeFetched,
  selectDeleteEmployeeError,
};
