import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

function Title({ children, ...others }) {
  return (
    <Box component="h4" fontSize={18} fontWeight={500} mb={1.5} ml={0} mr={0} mt={0} {...others}>
      {children}
    </Box>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
