import React, { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

function ConektaProviderV2({ children }: Props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js';
    script.id = 'conektaSDKV2';
    script.type = 'text/javascript';
    script.async = true;
    if (!document.getElementById('conektaSDKV2')) document.body.appendChild(script);
  }, []);

  return React.Children.only(children);
}

export default ConektaProviderV2;
