import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_MODIFIER_GROUPS,
  GET_PAGINATION_MODIFIER_GROUP,
  CREATE_MODIFIER_GROUP,
  DELETE_MODIFIER_GROUP,
  DUPLICATE_MODIFIER_GROUP,
} from './constants';

export const getModifierGroups = extendRoutine(createRoutine(GET_MODIFIER_GROUPS), 'RESET');
export const getPaginationModifierGroup = createRoutine(GET_PAGINATION_MODIFIER_GROUP);
export const createModifierGroup = extendRoutine(createRoutine(CREATE_MODIFIER_GROUP), 'RESET');
export const duplicateModifierGroup = extendRoutine(createRoutine(DUPLICATE_MODIFIER_GROUP), 'RESET');
export const deleteModifierGroup = extendRoutine(createRoutine(DELETE_MODIFIER_GROUP), 'RESET');
