import React from 'react';
import PropTypes from 'prop-types';

import { I18nextProvider } from 'react-i18next';

function LocaleProvider({ i18n, children }) {
  return <I18nextProvider i18n={i18n}>{React.Children.only(children)}</I18nextProvider>;
}

LocaleProvider.propTypes = {
  children: PropTypes.element,
  i18n: PropTypes.object,
};

export default LocaleProvider;
