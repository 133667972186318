import queryString from 'query-string';
import { getUserCurrentStore } from 'utils/auth';
import { reportsApi } from 'utils/api/baas/reports/axiosBase';

export interface RequestProps {
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
}

export async function getMovementsCash(params: RequestProps) {
  const store = getUserCurrentStore()?.uuid;

  const response = await reportsApi.get(`/v1/reports/cash-movements`, {
    params: {
      page: params.page,
      page_size: params.pageSize,
      start_date: `${params.startDate}T${params?.startTime || '00:00:00'}`,
      end_date: `${params.endDate}T${params?.endTime || '00:00:00'}`,
      store_uuid: store,
    },
    paramsSerializer: (p) => queryString.stringify(p, { arrayFormat: 'comma' }),
  });
  return response.data;
}

export async function downloadReport(params: RequestProps): Promise<Blob> {
  const store = getUserCurrentStore()?.uuid;
  const response = await reportsApi.get(`/v1/reports/cash-movements/export`, {
    params: {
      page_size: params.pageSize,
      start_date: `${params.startDate}T${params?.startTime || '00:00:00'}`,
      end_date: `${params.endDate}T${params?.endTime || '00:00:00'}`,
      store_uuid: store,
    },
    paramsSerializer: (p) => queryString.stringify(p, { arrayFormat: 'comma' }),
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    responseType: 'blob',
  });
  return response.data;
}
