import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import { getKitchenTicketDetailsRequest, updateKitchenTicketRequest } from 'utils/api/baas/kitchenTicket/ticket';

import { getKitchenTicket, updateKitchenTicket } from './actions';

export function* handleGetKitchenTicketDetails() {
  try {
    yield put(getKitchenTicket.request());

    const { data } = yield call(baasRequest, getKitchenTicketDetailsRequest);

    yield put(getKitchenTicket.success(data));
  } catch (err) {
    yield put(getKitchenTicket.failure(err));
  }
}

export function* handleUpdateKitchenTicketRequest(action) {
  try {
    yield put(updateKitchenTicket.request());

    const { data } = yield call(baasRequest, updateKitchenTicketRequest, { ...action.payload });

    yield put(updateKitchenTicket.success(data));
  } catch (err) {
    yield put(updateKitchenTicket.failure(err));
  }
}

export default function* kitchenTicketProviderRootSaga() {
  yield takeLatest(getKitchenTicket.TRIGGER, handleGetKitchenTicketDetails);
  yield takeLatest(updateKitchenTicket.TRIGGER, handleUpdateKitchenTicketRequest);
}
