import camelCase from 'lodash/camelCase';

const FORCE_PRODUCT_SELECTION = 'FORCE_PRODUCT_SELECTION';
const NOT_RULE_DEFINED = 'NOT_RULE_DEFINED';

export const selectionType = {
  FORCE_PRODUCT_SELECTION,
  NOT_RULE_DEFINED,
};
const VALUE_IN_RANGE = 'VALUE_IN_RANGE';
const EXACT_VALUE = 'EXACT_VALUE';
const NOT_REQUIRED_VALUE = 'NOT_REQUIRED_VALUE';

export const requiredType = {
  VALUE_IN_RANGE,
  EXACT_VALUE,
  NOT_REQUIRED_VALUE,
};

function constructItemLevel(itemLevel, level, options, finalGroup, isConditionalGroup, conditionalRules) {
  const itemLevelObject = {
    ...itemLevel,
    level,
    options: [],
    isCollapse: true,
    isShowPrice: false,
    isConditionalGroup,
  };

  options?.forEach((option) => {
    let rules = null;

    if (isConditionalGroup) {
      conditionalRules?.forEach((rule) => {
        if (rule?.conditionalOption === option?.conditionalOption) rules = rule;
      });
    }

    itemLevelObject.options.push({
      ...option,
      level: level + 1,
      isCollapse: Boolean(finalGroup),
      finalGroup,
      isShowPrice: true,
      rules,
    });
  });

  return itemLevelObject;
}

export function constructDataForModifiersGroup({ modifiers, finalGroupsMap, conditionalGroupsMap, optionsByModifier }) {
  const modifiersTransform = [];
  modifiers?.forEach((modifier, index) => {
    if (!modifier?.conditionalGroup) {
      modifiersTransform.push({ ...modifier, level: 0, optionsByModifier: [], isCollapse: true });
      optionsByModifier[camelCase(modifier?.uuid)]?.forEach((option) => {
        if (option?.finalGroup) {
          modifiersTransform[index].optionsByModifier.push({
            ...option,
            conditionalGroup: null,
            isCollapse: true,
            level: 1,
            isShowPrice: true,
            finalGroup: constructItemLevel(
              finalGroupsMap[camelCase(option?.finalGroup)],
              2,
              optionsByModifier[camelCase(option?.finalGroup)],
              null,
              false,
            ),
          });
        } else {
          modifiersTransform[index].optionsByModifier.push({
            ...option,
            conditionalGroup: null,
            isCollapse: false,
            level: 1,
            isShowPrice: true,
          });
        }
      });
    }

    if (modifier?.conditionalGroup) {
      modifiersTransform.push({ ...modifier, level: 0, optionsByModifier: [], isCollapse: true });
      optionsByModifier[camelCase(modifier?.uuid)]?.forEach((option) => {
        if (option?.finalGroup) {
          modifiersTransform[index].optionsByModifier.push({
            ...option,
            conditionalGroup: constructItemLevel(
              conditionalGroupsMap[camelCase(modifier?.conditionalGroup)],
              2,
              option?.conditionals,
              constructItemLevel(
                finalGroupsMap[camelCase(option?.finalGroup)],
                4,
                optionsByModifier[camelCase(option?.finalGroup)],
                null,
                false,
              ),
              true,
              modifier?.conditionalRules,
            ),
            isCollapse: true,
            level: 1,
            finalGroup: null,
            isShowPrice: false,
          });
        } else {
          modifiersTransform[index].optionsByModifier.push({
            ...option,
            conditionalGroup: constructItemLevel(
              conditionalGroupsMap[camelCase(modifier?.conditionalGroup)],
              2,
              option?.conditionals,
              null,
              true,
              modifier?.conditionalRules,
            ),
            isCollapse: true,
            level: 1,
            isShowPrice: false,
          });
        }
      });
    }
  });

  return modifiersTransform;
}
