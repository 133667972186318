import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { updateBrands } from './actions';
import { selectUpdateBrandsState } from './selectors';

export function withBrandsHoc(Component) {
  function withBrands(props) {
    return <Component {...props} />;
  }

  withBrands.displayName = `withBrands(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withBrands;
}

const mapStateToProps = createStructuredSelector({
  updateBrandsState: selectUpdateBrandsState,
});

export function mapDispatchToProps(dispatch) {
  return {
    updateBrands: (brands) => dispatch(updateBrands(brands)),
    resetUpdateBrands: () => dispatch(updateBrands.reset()),
  };
}

export const withBrandsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withBrandsState, withBrandsHoc);
