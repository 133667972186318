import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}

const CopyOutline = forwardRef(function CopyOutline(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#000000', size = 32 } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M12.5526 11.2761C8.24336 11.2761 4.75 14.7695 4.75 19.0788C4.75 23.388 8.24336 26.8814 12.5526 26.8814C16.8619 26.8814 20.3553 23.388 20.3553 19.0788C20.3553 14.7695 16.8619 11.2761 12.5526 11.2761ZM3.25 19.0788C3.25 13.9411 7.41493 9.77612 12.5526 9.77612C17.6903 9.77612 21.8553 13.9411 21.8553 19.0788C21.8553 24.2165 17.6903 28.3814 12.5526 28.3814C7.41493 28.3814 3.25 24.2165 3.25 19.0788Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M20.4081 4.69727C17.651 4.69727 15.2316 6.08282 13.8339 8.17226L12.5871 7.33827C14.257 4.84194 17.1392 3.19727 20.4081 3.19727C25.5509 3.19727 29.75 7.27444 29.75 12.3406C29.75 15.8353 27.7481 18.8627 24.8225 20.4006L24.1246 19.0729C26.5884 17.7777 28.25 15.2435 28.25 12.3406C28.25 8.13573 24.7557 4.69727 20.4081 4.69727Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default CopyOutline;
