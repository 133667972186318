import queryString from 'query-string';

import { request2 as request } from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { createHeaders } from 'utils/baasRequest';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

interface GetFiltersResponse {
  datetime: string;
  data: {
    brands: {
      uuid: string;
      name: string;
      logo: string | null;
    }[];
    categories: {
      uuid: string;
      name: string;
    }[];
    orderStates: {
      ACCEPTED: string;
      CANCELED: string;
      CREATED: string;
      DENIED: string;
      FINISHED: string;
      OPEN: string;
      PAID: string;
      VOIDED: string;
    };
    orderTypes: {
      name: string;
      orderType: string;
      uuid: string;
    }[];
    paymentTypes: {
      name: string;
      paymentType: string;
      uuid: string;
    }[];
    providers: string[];
    users: string[];
    voidTypes: {
      ORDER: string;
      ORDER_ITEM: string;
      ORDER_PAYMENT: string;
      SERVICE_CHARGE: string;
    };
  };
}

export function getReportsFilters(): Promise<GetFiltersResponse> {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    group_pay_types: true,
  });

  return request(`${API_BASE_URL}/v1/reports/sales-config/?${query}`, {
    method: 'GET',
    headers: createHeaders(),
  });
}
