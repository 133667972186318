import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getInvoiceCancellationsRequest, getInvoiceTaxRegimeRequest } from 'utils/api/baas/invoicing/catalogs';

import { getInvoiceCancellations, getInvoiceTaxRegime } from './actions';

export function* handleInvoiceCancellationsRequest() {
  try {
    yield put(getInvoiceCancellations.request());

    const { data } = yield call(baasRequest, getInvoiceCancellationsRequest);

    yield put(getInvoiceCancellations.success(data));
  } catch (err) {
    yield put(getInvoiceCancellations.failure(err));
  } finally {
    yield put(getInvoiceCancellations.fulfill());
  }
}

export function* handleInvoiceTaxRegimeRequest() {
  try {
    yield put(getInvoiceTaxRegime.request());

    const { data } = yield call(baasRequest, getInvoiceTaxRegimeRequest);

    yield put(getInvoiceTaxRegime.success(data));
  } catch (err) {
    yield put(getInvoiceTaxRegime.failure(err));
  } finally {
    yield put(getInvoiceTaxRegime.fulfill());
  }
}

export default function* InvoiceCancellationsProviderSaga() {
  yield takeLatest(getInvoiceCancellations.TRIGGER, handleInvoiceCancellationsRequest);
  yield takeLatest(getInvoiceTaxRegime.TRIGGER, handleInvoiceTaxRegimeRequest);
}
