import { takeLatest, put, call } from 'redux-saga/effects';

import { logEventRequest } from 'providers/root/AmplitudeProvider/helpers';

import baasRequest from 'utils/baasRequest';

import {
  getReportsCancellationsRequest,
  exportReportsCancellationsExcelRequest,
} from 'utils/api/baas/reports/cancellations';

import {
  getReportsCancellationsResume,
  getReportsCancellationsDetails,
  getReportsCancellationsDetailsByPagination,
  getReportsCancellationsDetailsBySorting,
  exportReportsCancellations,
} from './actions';

export function* handleGetReportsCancellationsResumeRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsCancellationsResume.request());

    const { data: resume } = yield call(baasRequest, getReportsCancellationsRequest, filters);

    logEventRequest('@event.$reports.viewReport', ['@@type.summary', '@@section.voids']);
    yield put(getReportsCancellationsResume.success({ ...resume, voidType: filters?.voidType }));
  } catch (err) {
    yield put(getReportsCancellationsResume.failure(err));
  }
}

export function* handleGetReportsCancellationsDetailsRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsCancellationsDetails.request());

    const { data: cancellationsDet, pagination } = yield call(baasRequest, getReportsCancellationsRequest, filters);

    logEventRequest('@event.$reports.viewReport', ['@@type.complete', '@@section.voids']);
    yield put(
      getReportsCancellationsDetails.success({
        cancellations: { ...cancellationsDet, voidType: filters?.voidType },
        pagination,
      }),
    );
  } catch (err) {
    yield put(getReportsCancellationsDetails.failure(err));
  }
}

export function* handleGetReportsCancellationsDetailsByPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsCancellationsDetailsByPagination.request());

    const { data: cancellations, pagination } = yield call(baasRequest, getReportsCancellationsRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getReportsCancellationsDetailsByPagination.success({ cancellations, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getReportsCancellationsDetailsByPagination.failure(err));
  }
}

export function* handleGetReportsCancellationsDetailsBySortingRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsCancellationsDetailsBySorting.request());

    const { data: cancellationsDet, pagination } = yield call(baasRequest, getReportsCancellationsRequest, filters);

    yield put(
      getReportsCancellationsDetailsBySorting.success({
        cancellations: { ...cancellationsDet, voidType: filters?.voidType },
        pagination,
      }),
    );
  } catch (err) {
    yield put(getReportsCancellationsDetailsBySorting.failure(err));
  }
}

export function* handleExportReportsCancellationsExcelRequest(action) {
  const filters = action.payload;

  try {
    yield put(exportReportsCancellations.request());

    const documentData = yield call(baasRequest, exportReportsCancellationsExcelRequest, filters);

    logEventRequest('@event.$reports.exportReport', '@@type.voids');
    yield put(exportReportsCancellations.success(documentData));
  } catch (err) {
    yield put(exportReportsCancellations.failure(err));
  }
}

export default function* reportsCancellationsSaga() {
  yield takeLatest(getReportsCancellationsResume.TRIGGER, handleGetReportsCancellationsResumeRequest);
  yield takeLatest(getReportsCancellationsDetails.TRIGGER, handleGetReportsCancellationsDetailsRequest);
  yield takeLatest(
    getReportsCancellationsDetailsByPagination.TRIGGER,
    handleGetReportsCancellationsDetailsByPaginationRequest,
  );
  yield takeLatest(
    getReportsCancellationsDetailsBySorting.TRIGGER,
    handleGetReportsCancellationsDetailsBySortingRequest,
  );
  yield takeLatest(exportReportsCancellations.TRIGGER, handleExportReportsCancellationsExcelRequest);
}
