import { Children } from 'react';
import PropTypes from 'prop-types';

import InvoicingProvider from 'providers/invoicing/InvoicingProvider';
import InvoicesGeneratedProvider from 'providers/invoicing/InvoicesGeneratedProvider';
import InvoicesGlobalProvider from 'providers/invoicing/InvoicesGlobalProvider';
import InvoicesTicketsEditorProvider from 'providers/invoicing/InvoicesTicketsEditorProvider';
import InvoiceCatalogProvider from 'providers/invoicing/InvoiceCatalogProvider';

import ComposeProviders from 'components/ComposeProviders';

function InvoicingRootProvider({ children }) {
  return (
    <ComposeProviders
      providers={[
        <InvoicingProvider />,
        <InvoicesGeneratedProvider />,
        <InvoicesGlobalProvider />,
        <InvoicesTicketsEditorProvider />,
        <InvoiceCatalogProvider />,
      ]}
    >
      {Children.only(children)}
    </ComposeProviders>
  );
}

InvoicingRootProvider.propTypes = {
  children: PropTypes.element,
};

export default InvoicingRootProvider;
