import { Children } from 'react';
import PropTypes from 'prop-types';

import GoogleMapContext from './GoogleMapContext';

function GoogleMapProvider({ children, config }) {
  if (!config || config === null || typeof config !== 'object') {
    throw new TypeError('ParrotGoogleMapProvider: The typeof prop must be an object.');
  }

  return <GoogleMapContext.Provider value={config}>{Children.only(children)}</GoogleMapContext.Provider>;
}

GoogleMapProvider.propTypes = {
  config: PropTypes.object,
  children: PropTypes.element,
};

export default GoogleMapProvider;
