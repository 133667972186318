import { useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-query';

import { dateToString } from 'utils/reports/users/transformers';

import { movementsCashFilterAtom } from './recoil/atoms';
import { FiltersProps } from './types';
import { downloadReport } from './services';

export default function useDownloadMovementsCash() {
  const filtersValue = useRecoilValue<FiltersProps>(movementsCashFilterAtom);
  const currentParams = useRef<FiltersProps>(filtersValue);

  const downloadMutation = useMutation(() =>
    downloadReport({
      startDate: dateToString(currentParams.current.dates?.[0]),
      endDate: dateToString(currentParams.current.dates?.[1]),
      startTime: currentParams.current.startTime,
      endTime: currentParams.current.endTime,
      page: 0,
      pageSize: 0,
    }),
  );

  useEffect(() => {
    currentParams.current = filtersValue;
  }, [filtersValue]);

  return {
    downloadExcel: downloadMutation,
    downloadExcelState: {
      isFetching: downloadMutation.isLoading,
      isSuccess: downloadMutation.isSuccess,
      isError: downloadMutation.isError,
    },
  };
}
