import produce from 'immer';

import { DEFAULT_SIZE_PAGE, DEFAULT_PAGE } from 'utils/payments';

import {
  getMovementReasons,
  getMovementReasonDetail,
  createMovementReason,
  deleteMovementReason,
  editMovementReason,
} from './actions';

const defaultState = {
  error: null,
  fetching: false,
  fetched: false,
};

export const initialState = {
  getMovementReasons: {
    ...defaultState,
    reasons: [],
    pagination: {
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_SIZE_PAGE,
      count: 0,
    },
  },
  getMovementReasonDetail: {
    ...defaultState,
    reason: {},
  },
  createMovementReason: {
    ...defaultState,
    reason: {},
  },
  deleteMovementReason: {
    ...defaultState,
  },
  editMovementReason: {
    ...defaultState,
    reason: {},
  },
};

const payInProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getMovementReasons.REQUEST:
        draft.getMovementReasons.fetching = true;
        draft.getMovementReasons.error = false;
        draft.getMovementReasons.fetched = false;
        draft.getMovementReasons.pagination = { ...initialState.getMovementReasons.pagination };
        break;
      case getMovementReasons.SUCCESS:
        draft.getMovementReasons.fetching = false;
        draft.getMovementReasons.fetched = true;
        draft.getMovementReasons.reasons = payload.reasons;
        draft.getMovementReasons.pagination = {
          ...draft.getMovementReasons.pagination,
          ...payload.pagination,
        };
        break;
      case getMovementReasons.FAILURE:
        draft.getMovementReasons.error = payload;
        draft.getMovementReasons.fetching = false;
        draft.getMovementReasons.fetched = false;
        break;
      case getMovementReasons.RESET:
        draft.getMovementReasons = { ...initialState.getMovementReasons };
        break;

      // detail reasons
      case getMovementReasonDetail.REQUEST:
        draft.getMovementReasonDetail.fetching = true;
        draft.getMovementReasonDetail.fetched = false;
        draft.getMovementReasonDetail.error = false;
        break;

      case getMovementReasonDetail.SUCCESS:
        draft.getMovementReasonDetail.fetching = false;
        draft.getMovementReasonDetail.fetched = true;
        draft.getMovementReasonDetail.error = false;
        draft.getMovementReasonDetail.reason = payload;
        break;

      case getMovementReasonDetail.FAILURE:
        draft.getMovementReasonDetail.error = true;
        draft.getMovementReasonDetail.fetching = false;
        draft.getMovementReasonDetail.fetched = false;
        break;
      case getMovementReasonDetail.RESET:
        draft.getMovementReasonDetail = { ...initialState.getMovementReasonDetail };
        break;

      // create reasons
      case createMovementReason.REQUEST:
        draft.createMovementReason.fetching = true;
        draft.createMovementReason.error = false;
        draft.createMovementReason.fetched = false;
        break;

      case createMovementReason.SUCCESS: {
        const reasonsList = [...draft.getMovementReasons.reasons];
        reasonsList.unshift(payload);

        draft.createMovementReason.fetching = false;
        draft.createMovementReason.fetched = true;
        draft.createMovementReason.reason = payload;
        draft.getMovementReasons.reasons = reasonsList;
        break;
      }

      case createMovementReason.FAILURE:
        draft.createMovementReason.error = payload;
        draft.createMovementReason.fetching = false;
        draft.createMovementReason.fetched = false;
        break;

      case createMovementReason.RESET:
        draft.createMovementReason = { ...initialState.createMovementReason };
        break;

      // edit reason
      case editMovementReason.REQUEST:
        draft.editMovementReason.fetching = true;
        draft.editMovementReason.fetched = false;
        draft.editMovementReason.error = false;
        break;

      case editMovementReason.SUCCESS: {
        const indexReason = draft.getMovementReasons.reasons.findIndex((reason) => {
          return reason.uuid === payload.uuid;
        });

        draft.editMovementReason.fetching = false;
        draft.editMovementReason.fetched = true;
        draft.editMovementReason.reason = payload;
        draft.getMovementReasons.reasons[indexReason] = payload;
        break;
      }

      case editMovementReason.FAILURE:
        draft.editMovementReason.error = payload;
        draft.editMovementReason.fetched = false;
        draft.editMovementReason.fetching = false;
        break;

      case editMovementReason.RESET:
        draft.editMovementReason = { ...initialState.editMovementReason };
        break;

      // delete reason
      case deleteMovementReason.REQUEST:
        draft.deleteMovementReason.fetching = true;
        draft.deleteMovementReason.error = false;
        draft.deleteMovementReason.fetched = false;
        break;

      case deleteMovementReason.SUCCESS: {
        const reasonsList = draft.getMovementReasons.reasons.filter((reasons) => reasons.uuid !== payload);

        draft.deleteMovementReason.fetching = false;
        draft.deleteMovementReason.fetched = true;
        draft.getMovementReasons.reasons = reasonsList;

        break;
      }

      case deleteMovementReason.FAILURE:
        draft.deleteMovementReason.error = payload;
        draft.deleteMovementReason.fetching = false;
        draft.deleteMovementReason.fetched = false;
        break;

      case deleteMovementReason.RESET:
        draft.deleteMovementReason = { ...initialState.deleteMovementReason };
        break;

      default:
        return draft;
    }
  });

export default payInProviderReducer;
