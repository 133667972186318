/* eslint-disable no-plusplus */
import camelCase from 'lodash/camelCase';
import { eventChannel } from 'redux-saga';

import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';

import {
  setAuthToken,
  getAuthToken,
  removeAuthToken,
  setUserCurrentStore,
  getUserCurrentStore,
  removeUserCurrentStore,
  setStoreDraftUser,
  getStoreDraftUser,
  removeStoreDratfKey,
  setStoreBackupUser,
  getStoreBackupUser,
  removeStoreBackupKey,
  setUserStoresStoreKey,
  getUserStoresStoreKey,
  removeUserStoresStoreKey,
  setCookieUser,
  getCookieUser,
  removeCookieUser,
  setOrganizationUser,
  getOrganizationUser,
  removeOrganizationUser,
} from './authTyped';

export const LOGIN_URI = `${window.location.origin}/login`;
export const LOGOUT_URI = `${window.location.origin}/logout`;
export const USER_URI = `${window.location.origin}/user`;

export const SECONDS_TO_CHANEL_CALL = 60000;

export {
  setAuthToken,
  getAuthToken,
  removeAuthToken,
  setUserCurrentStore,
  getUserCurrentStore,
  removeUserCurrentStore,
  setStoreDraftUser,
  getStoreDraftUser,
  removeStoreDratfKey,
  setStoreBackupUser,
  getStoreBackupUser,
  removeStoreBackupKey,
  setUserStoresStoreKey,
  getUserStoresStoreKey,
  removeUserStoresStoreKey,
  setCookieUser,
  getCookieUser,
  removeCookieUser,
  setOrganizationUser,
  getOrganizationUser,
  removeOrganizationUser,
};

const getValuesCamelCase = (values) =>
  values.map((element) => {
    return camelCase(element);
  });

export const getListPermissions = (permissions) => {
  return getValuesCamelCase(permissions);
};

export function channelStateLicenseInterval() {
  let seconds = 0;

  return eventChannel((emiter) => {
    const interval = setInterval(() => {
      seconds++;

      emiter(seconds);
    }, SECONDS_TO_CHANEL_CALL);
    return () => {
      clearInterval(interval);
    };
  });
}

export function isRedirectToSubscription(status, pathname) {
  return (
    (status === subscriptionStatusTypes.UNAVAILABLE || status === subscriptionStatusTypes.PAYMENT_REQUIRED) &&
    pathname !== '/subscription' &&
    pathname !== '/subscription/payment-history' &&
    pathname !== '/subscription/invoice-data'
  );
}

function getSubModule(rootModule, submodules = {}) {
  const subModulesList = [];
  try {
    Object.keys(submodules).forEach((key) => {
      if (typeof submodules[key] === 'boolean' && submodules[key] === true) {
        subModulesList.push(`@module.${rootModule}.${key}`);
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('%cerror auth.js line:99 ', 'color: red; display: block; width: 100%;', err);
  }
  return subModulesList;
}

export function getActivesModules(modules) {
  let modulesList = [];

  Object.keys(modules).forEach((key) => {
    if (typeof modules[key] === 'boolean' && modules[key] === true) {
      modulesList.push(`@module.${key}`);
    }
    if (typeof modules[key] === 'object') {
      modulesList = [...modulesList, ...getSubModule(key, modules[key])];
    }
  });

  return modulesList;
}
