import makeStyles from '@material-ui/core/styles/makeStyles';
import Button, { ButtonProps } from '@material-ui/core/Button';

import IconOrganization from '@experimental-components/IconsComponents/TopNavBar/OrganizationDiagram';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: 16,
    minWidth: 225,
    width: '100%',
    height: '100%',
  },
  startIcon: {
    paddingRight: theme.spacing(1),
  },
}));

interface Props extends ButtonProps {
  customIcon?: React.ReactNode;
}

function ButtonNav({ customIcon, ...others }: Props) {
  const classes = useStyles();
  return (
    <Button
      classes={{ root: classes.root, startIcon: classes.startIcon }}
      {...others}
      startIcon={customIcon || <IconOrganization />}
    />
  );
}

export default ButtonNav;
