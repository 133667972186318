/**
 *
 * ParrotSoftware
 * OnlineOrderingProvider saga
 *
 */
import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getOnlineOrderingRequest,
  getBrandSettingsRequest,
  updateBrandSettingsRequest,
  updateEnableOrderingRequest,
  getBrandContactInformationRequest,
  updateContactInformationRequest,
  getOperationsSettingsRequest,
  updateOperationsSettingsRequest,
  getDeliveryZonesRequest,
  updateDeliveryZonesRequest,
  validateDirectionUrlRequest,
  getResumesMenusRequest,
  saveOnboardingRequest,
  createDeliveryZonesRequest,
  deleteDeliveryZonesRequest,
  publishBrandRequest,
} from 'utils/api/baas/onlineOrdering/onlineOrdering';

import {
  getOnlineOrdering,
  getOnlineOrderingPagination,
  updateCanOrdering,
  getBrandSettings,
  updateBrandSettings,
  getBrandContactInformation,
  updateContactInformation,
  getOperationsSettings,
  updateOperationsSettings,
  getDeliveryZones,
  updateDeliveryZones,
  validateDirectionUrl,
  getResumesMenus,
  saveOnboarding,
  createDeliveryZones,
  deleteDeliveryZones,
  publishBrand,
} from './actions';

export function* handleGetOnlineOrderingRequest() {
  try {
    yield put(getOnlineOrdering.request());

    const { data, pagination } = yield call(baasRequest, getOnlineOrderingRequest);

    yield put(getOnlineOrdering.success({ data, pagination }));
  } catch (err) {
    yield put(getOnlineOrdering.failure(err));
  }
}

export function* handleGetOnlineOrderingPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getOnlineOrderingPagination.request());

    const { data, pagination } = yield call(baasRequest, getOnlineOrderingPagination, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };
    yield put(getOnlineOrderingPagination.success({ data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getOnlineOrderingPagination.failure(err));
  }
}

export function* handleEnableOrdering(action) {
  const fields = action.payload;

  try {
    yield put(updateCanOrdering.request());
    const { data } = yield call(baasRequest, updateEnableOrderingRequest, fields);

    yield put(updateCanOrdering.success(data));
  } catch (err) {
    yield put(updateCanOrdering.failure(err));
  }
}

export function* handleGetBrandSettings(action) {
  const uuidBrand = action.payload;
  try {
    yield put(getBrandSettings.request());
    const { data } = yield call(baasRequest, getBrandSettingsRequest, uuidBrand);

    yield put(getBrandSettings.success({ uuidBrand, ...data }));
  } catch (err) {
    yield put(getBrandSettings.failure(err));
  }
}

export function* handleUpdateBrandSettings(action) {
  const fields = action.payload;

  try {
    yield put(updateBrandSettings.request());
    const { data } = yield call(baasRequest, updateBrandSettingsRequest, fields);

    yield put(updateBrandSettings.success(data));
  } catch (err) {
    yield put(updateBrandSettings.failure(err));
  }
}

export function* getBrandContactInformationSaga(action) {
  const uuidBrand = action.payload;

  try {
    yield put(getBrandContactInformation.request());
    const { data } = yield call(baasRequest, getBrandContactInformationRequest, uuidBrand);
    yield put(
      getBrandContactInformation.success({
        uuid: uuidBrand,
        ...data,
      }),
    );
  } catch (err) {
    yield put(getBrandContactInformation.failure(err));
  }
}

export function* handleUpdateContactInformation(action) {
  const fields = action.payload;
  try {
    yield put(updateContactInformation.request());
    const { data } = yield call(baasRequest, updateContactInformationRequest, fields);
    yield put(updateContactInformation.success(data));
  } catch (err) {
    yield put(updateContactInformation.failure(err));
  }
}

export function* handleGetOperationsSettings(action) {
  const uuidBrand = action.payload;
  try {
    yield put(getOperationsSettings.request());
    const { data } = yield call(baasRequest, getOperationsSettingsRequest, uuidBrand);

    yield put(
      getOperationsSettings.success({
        uuidBrand,
        ...data,
      }),
    );
  } catch (err) {
    yield put(getOperationsSettings.failure(err));
  }
}

export function* handleUpdateOperationsSettings(action) {
  const fields = action.payload;

  try {
    yield put(updateOperationsSettings.request());
    const { data } = yield call(baasRequest, updateOperationsSettingsRequest, fields);

    yield put(updateOperationsSettings.success(data));
  } catch (err) {
    yield put(updateOperationsSettings.failure(err));
  }
}

export function* handleGetDeliveryZones(action) {
  const uuidBrand = action.payload;
  try {
    yield put(getDeliveryZones.request());
    const { data } = yield call(baasRequest, getDeliveryZonesRequest, uuidBrand);

    yield put(getDeliveryZones.success({ list: data, uuidBrand }));
  } catch (err) {
    yield put(getDeliveryZones.failure(err));
  }
}

export function* handleCreateDeliveryZone(action) {
  const fields = action.payload;
  try {
    yield put(createDeliveryZones.request());

    const { data } = yield call(baasRequest, createDeliveryZonesRequest, fields);

    yield put(createDeliveryZones.success({ data }));
  } catch (err) {
    yield put(createDeliveryZones.failure(err));
  }
}

export function* handleDeleteDeliveryZone(action) {
  const fields = action.payload;

  try {
    yield put(deleteDeliveryZones.request());

    yield call(baasRequest, deleteDeliveryZonesRequest, fields);

    yield put(deleteDeliveryZones.success());
  } catch (err) {
    yield put(deleteDeliveryZones.failure(err));
  } finally {
    yield put(deleteDeliveryZones.fulfill(fields.uuid));
  }
}

export function* handleUpdateDeliveryZones(action) {
  const fields = action.payload;

  try {
    yield put(updateDeliveryZones.request());
    const { data } = yield call(baasRequest, updateDeliveryZonesRequest, fields);

    yield put(updateDeliveryZones.success(data));
  } catch (err) {
    yield put(updateDeliveryZones.failure(err));
  }
}

export function* validateDirectionUrlSaga(action) {
  const url = action.payload;

  try {
    yield put(validateDirectionUrl.request());
    const { data } = yield call(baasRequest, validateDirectionUrlRequest, url);

    yield put(validateDirectionUrl.success(data));
  } catch (err) {
    yield put(validateDirectionUrl.failure(err));
  }
}

export function* getResumesMenusSga(action) {
  const menusUuid = action.payload;

  try {
    yield put(getResumesMenus.request());
    const { data } = yield call(baasRequest, getResumesMenusRequest, menusUuid);

    yield put(getResumesMenus.success(data));
  } catch (err) {
    yield put(getResumesMenus.failure(err));
  }
}

export function* saveOnboardingSaga(action) {
  const { brand, onboardingConfig } = action.payload;

  try {
    yield put(saveOnboarding.request());
    const { data } = yield call(baasRequest, saveOnboardingRequest, brand?.uuid, onboardingConfig);
    const brandNew = { ...data };

    brandNew.uuid = brand?.uuid;
    brandNew.name = brand.name;
    brandNew.logo = brand.logo;

    yield put(saveOnboarding.success(brandNew));
  } catch (err) {
    yield put(saveOnboarding.failure(err));
  }
}

export function* publishBrandSaga(action) {
  const { isPublished, brand } = action.payload;

  try {
    yield put(publishBrand.request());
    const { data } = yield call(baasRequest, publishBrandRequest, isPublished, brand);

    yield put(publishBrand.success(data));
  } catch (err) {
    yield put(publishBrand.failure(err));
  }
}

export default function* onlineOrderingRootSga() {
  yield takeLatest(getOnlineOrdering.TRIGGER, handleGetOnlineOrderingRequest);
  yield takeLatest(getOnlineOrderingPagination.TRIGGER, handleGetOnlineOrderingPaginationRequest);
  yield takeLatest(updateCanOrdering.TRIGGER, handleEnableOrdering);
  yield takeLatest(getBrandSettings.TRIGGER, handleGetBrandSettings);
  yield takeLatest(updateBrandSettings.TRIGGER, handleUpdateBrandSettings);
  yield takeLatest(getBrandContactInformation.TRIGGER, getBrandContactInformationSaga);
  yield takeLatest(updateContactInformation.TRIGGER, handleUpdateContactInformation);
  yield takeLatest(getOperationsSettings.TRIGGER, handleGetOperationsSettings);
  yield takeLatest(updateOperationsSettings.TRIGGER, handleUpdateOperationsSettings);
  yield takeLatest(getDeliveryZones.TRIGGER, handleGetDeliveryZones);
  yield takeLatest(createDeliveryZones.TRIGGER, handleCreateDeliveryZone);
  yield takeLatest(deleteDeliveryZones.TRIGGER, handleDeleteDeliveryZone);
  yield takeLatest(updateDeliveryZones.TRIGGER, handleUpdateDeliveryZones);
  yield takeLatest(validateDirectionUrl.TRIGGER, validateDirectionUrlSaga);
  yield takeLatest(getResumesMenus.TRIGGER, getResumesMenusSga);
  yield takeLatest(saveOnboarding.TRIGGER, saveOnboardingSaga);
  yield takeLatest(publishBrand.TRIGGER, publishBrandSaga);
}
