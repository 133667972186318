import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  subtitle: {
    margin: '0 auto',
    fontSize: 24,
    lineHeight: 1.7,
    maxWidth: 522,
    whiteSpace: 'pre',
    textAlign: 'center',
    width: '100%',
  },
}));

interface Props {
  title: string;
  subtitle?: string;
}
function ModalHeader({ title, subtitle }: Props) {
  const classes = useStyles();
  return (
    <>
      <Box fontSize={40} fontWeight="fontWeightMedium" mb={2} textAlign="center">
        {title}
      </Box>

      {subtitle && <Box className={classes.subtitle}>{subtitle}</Box>}
    </>
  );
}

export default ModalHeader;
