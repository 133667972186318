import { Children } from 'react';
import PropTypes from 'prop-types';

import ReportsSummary from 'providers/reports/ReportsSummary';
import ReportsProvider from 'providers/reports/ReportsProvider';
import ReportsFilters from 'providers/reports/ReportsFilters';
import CashRegisterSessionDetail from 'providers/reports/CashRegisterSessionDetail';
import Orders from 'providers/reports/Orders';
import Cancellations from 'providers/reports/Cancellations';
import Discounts from 'providers/reports/Discounts';
import ClientData from 'providers/reports/ClientData';

import ComposeProviders from 'components/ComposeProviders';

function ReportsRootProvider({ children }) {
  return (
    <ComposeProviders
      providers={[
        <ReportsSummary />,
        <ReportsProvider />,
        <ReportsFilters />,
        <CashRegisterSessionDetail />,
        <Orders />,
        <Cancellations />,
        <Discounts />,
        <ClientData />,
      ]}
    >
      {Children.only(children)}
    </ComposeProviders>
  );
}

ReportsRootProvider.propTypes = {
  children: PropTypes.element,
};

export default ReportsRootProvider;
