import { Children, memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

export function InvoiceCatalogProvider({ children }) {
  useInjectSaga({ key: 'InvoiceCatalogProvider', saga });
  useInjectReducer({ key: 'InvoiceCatalogProvider', reducer });

  return Children.only(children);
}

InvoiceCatalogProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const withConnect = connect();

export { default as useInvoiceCatalog } from './useInvoiceCatalog';
export { default as withInvoiceCatalog } from './withInvoiceCatalog';
export default compose(withConnect, memo)(InvoiceCatalogProvider);
