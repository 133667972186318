import produce from 'immer';

import baseState from 'utils/baseState';

import { updateKitchenTicket, getKitchenTicket } from './actions';

export const initialState = {
  getKitchenTicket: {
    ...baseState,
    order: {},
  },
  updateKitchenTicket: {
    ...baseState,
    order: {},
  },
};

const settingsKitchenTicketProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getKitchenTicket.REQUEST:
        draft.getKitchenTicket = {
          ...initialState.getKitchenTicket,
          fetching: true,
        };
        break;

      case getKitchenTicket.SUCCESS:
        draft.getKitchenTicket.fetching = false;
        draft.getKitchenTicket.fetched = true;
        draft.getKitchenTicket.order = payload;
        break;

      case getKitchenTicket.FAILURE:
        draft.getKitchenTicket.error = payload;
        draft.getKitchenTicket.fetching = false;
        draft.getKitchenTicket.fetched = false;
        break;

      case getKitchenTicket.RESET:
        draft.getKitchenTicket = {
          ...initialState.getKitchenTicket,
        };
        break;

      case updateKitchenTicket.REQUEST:
        draft.updateKitchenTicket = {
          ...initialState.updateKitchenTicket,
          fetching: true,
        };
        break;

      case updateKitchenTicket.SUCCESS:
        draft.updateKitchenTicket.fetching = false;
        draft.updateKitchenTicket.fetched = true;
        draft.updateKitchenTicket.order = payload;
        break;

      case updateKitchenTicket.FAILURE:
        draft.updateKitchenTicket.error = payload;
        draft.updateKitchenTicket.fetching = false;
        draft.updateKitchenTicket.fetched = false;
        break;

      case updateKitchenTicket.RESET:
        draft.updateKitchenTicket = {
          ...initialState.updateKitchenTicket,
        };
        break;

      default:
        return draft;
    }
  });

export default settingsKitchenTicketProviderReducer;
