import React, { useState, useEffect, forwardRef, Ref } from 'react';
import clsx, { ClassArray, ClassDictionary } from 'clsx';

import {
  Box,
  withStyles,
  makeStyles,
  FormControl,
  MenuProps,
  Select,
  InputLabel,
  FormHelperText,
  MenuItem as MenuItemMui,
  ListItemText as ListItemTextMui,
} from '@material-ui/core';

import { Checkbox, TextField } from '@design-system';
import IconChevronDown from '@experimental-components/IconsComponents/ChevronBase';
import Divider from '@design-system/Divider';
import { useTranslation } from 'react-i18next';

export const SelectRoot = withStyles((theme) => ({
  select: {
    borderRadius: theme.shape.borderRadius,
    padding: '14px 12px',
    paddingRight: `${theme.typography.pxToRem(36)} !important`,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },

  filled: {
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      borderRadius: theme.shape.borderRadius,
    },

    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },

  disabled: {
    backgroundColor: theme.palette.darkGrey[2],
  },
}))(Select);

const SelectItem = withStyles(() => ({
  root: {
    padding: 8,
    paddingLeft: 12,
  },
  selected: {
    backgroundColor: 'transparent !important',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
}))(MenuItemMui);

const SelectItemText = withStyles(() => ({
  primary: {
    fontSize: 14,
  },
}))(ListItemTextMui);

export const InputLabelRoot = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.common.black,
    position: 'relative',
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

export const FormHelperTextRoot = withStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey[50],
    fontSize: 16,
  },
}))(FormHelperText);

export const useStyles = makeStyles((theme) => ({
  iconBase: {
    display: 'flex',
    position: 'absolute',
    right: 16,
  },

  iconRoot: {
    right: 16,
    position: 'absolute',
    top: 'calc(50% - 6px)',
  },

  menuList: {
    color: '#1E2025',
    fontSize: theme.typography.pxToRem(16),
  },

  menuPaper: {
    marginTop: theme.spacing(2),
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #F2F2F4',
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },

  contentSelected: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  removed: {
    textDecoration: 'line-through',
  },
}));

interface Props {
  id?: string;
  helperText?: string;
  hideLabelHelperText?: boolean;
  label: string;
  options?: {
    value: string;
    label: string;
    isRemoved?: boolean;
  }[];
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  onChange?: (selectedValues: string[]) => void;
  placeholder?: string;
  allItemsSelectedPlaceholder?: string;
  selectAllPlaceholder?: string;
  optionsSelected?: string[];
  icon?: React.ReactNode;
  disableOptionAll?: boolean;
}

const MultipleSelect = forwardRef(function MultipleSelect(
  {
    id,
    helperText,
    hideLabelHelperText,
    label,
    options = [],
    disabled,
    error,
    fullWidth = true,
    required,
    onChange,
    placeholder,
    allItemsSelectedPlaceholder,
    selectAllPlaceholder,
    optionsSelected = [],
    icon,
    disableOptionAll = false,
  }: Props,
  ref: Ref<HTMLSelectElement>,
) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(optionsSelected);

  const CHOOSE_OPTIONS_TEXT = placeholder || t('common:forms.placeholderDefault');
  const SELECT_ALL_TEXT = selectAllPlaceholder || t('common:forms.selectAllPlaceholder');
  const ALL_SELECTED_TEXT = allItemsSelectedPlaceholder || t('common:forms.allItemsSelectedPlaceholder');

  useEffect(() => {
    if (optionsSelected?.length) {
      setSelectedOptions(optionsSelected);
    }
  }, [optionsSelected]);

  const menuProps: Partial<MenuProps> = {
    classes: {
      list: classes.menuList,
      paper: classes.menuPaper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    autoFocus: false,
  };

  const handleOnChange = (values: string[]) => {
    if (values.at(-1) === 'all') {
      const list = options.map((item) => item.value);

      const listTmp = selectedOptions.length === options.length ? [] : list;
      setSelectedOptions(listTmp);
      if (onChange) onChange(listTmp);
      return;
    }

    setSelectedOptions(values);
    if (onChange) onChange(values);
  };

  function handleRenderValue(values: string[]) {
    let renderSelected: string | undefined = CHOOSE_OPTIONS_TEXT;

    if (values.length > 0)
      renderSelected = options
        .filter((item) => values.includes(item.value))
        .map((item) => item.label)
        .join(', ');

    if (selectedOptions.length === options.length) {
      renderSelected = ALL_SELECTED_TEXT;
    }

    return <span className={classes.contentSelected}>{renderSelected}</span>;
  }

  const iconComponent = (iconProps: {
    className: string | number | boolean | ClassArray | ClassDictionary | null | undefined;
  }) =>
    icon ? (
      <div className={classes.iconBase}>{icon}</div>
    ) : (
      <IconChevronDown className={clsx(iconProps.className, classes.iconRoot)} color="#000" />
    );

  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required}>
      <InputLabelRoot htmlFor={id} shrink>
        {label}
      </InputLabelRoot>

      {disableOptionAll && (
        <SelectRoot
          ref={ref}
          disabled={disabled}
          displayEmpty
          IconComponent={iconComponent}
          input={<TextField hideLabelHelperText={hideLabelHelperText} />}
          MenuProps={menuProps}
          multiple
          onChange={(e) => handleOnChange(e.target.value as string[])}
          renderValue={(selected) => handleRenderValue(selected as string[])}
          value={selectedOptions}
        >
          {options.map((option) => (
            <SelectItem key={option.value} disabled={disabled} onClick={() => {}} value={option.value}>
              <Checkbox checked={selectedOptions.includes(option.value)} label="" />
              <SelectItemText className={clsx({ [classes.removed]: option?.isRemoved })} primary={option.label} />
            </SelectItem>
          ))}
        </SelectRoot>
      )}

      {!disableOptionAll && (
        <SelectRoot
          ref={ref}
          disabled={disabled}
          displayEmpty
          IconComponent={iconComponent}
          input={<TextField hideLabelHelperText={hideLabelHelperText} />}
          MenuProps={menuProps}
          multiple
          onChange={(e) => handleOnChange(e.target.value as string[])}
          renderValue={(selected) => handleRenderValue(selected as string[])}
          value={selectedOptions}
        >
          <SelectItem key="all" disabled={disabled} value="all">
            <Box display="flex" pb="5px" pt="9px">
              <Checkbox checked={selectedOptions.length === options.length} label="" />
              <SelectItemText primary={SELECT_ALL_TEXT} />
            </Box>
          </SelectItem>

          <Divider />

          {options.map((option) => (
            <SelectItem key={option.value} disabled={disabled} onClick={() => {}} value={option.value}>
              <Checkbox checked={selectedOptions.includes(option.value)} label="" />
              <SelectItemText className={clsx({ [classes.removed]: option?.isRemoved })} primary={option.label} />
            </SelectItem>
          ))}
        </SelectRoot>
      )}

      {!hideLabelHelperText && <FormHelperTextRoot id={`${id}HelperText`}>{helperText}</FormHelperTextRoot>}
    </FormControl>
  );
});

export default MultipleSelect;
