import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getMenuProductsRequest, changeMenuProductPriceRequest } from 'utils/api/baas/menuMaker/products';

import { getMenusProducts, changeMenuProductPrice } from './actions';

export function* handleGetMenusProductsRequest(action) {
  const { menuUuid, storeUuid } = action.payload;

  try {
    yield put(getMenusProducts.request());

    const { data, pagination } = yield call(baasRequest, getMenuProductsRequest, {
      menuUuid,
      storeUuid,
    });

    yield put(getMenusProducts.success({ data, pagination }));
  } catch (err) {
    yield put(getMenusProducts.failure(err));
  } finally {
    yield put(getMenusProducts.fulfill());
  }
}

export function* handleChangeMenuProductPriceRequest(action) {
  const { menuUuid, storeUuid, productUuid, price, availability } = action.payload;

  try {
    yield put(changeMenuProductPrice.request());

    const { data } = yield call(baasRequest, changeMenuProductPriceRequest, {
      menuUuid,
      storeUuid,
      productUuid,
      price,
      availability,
    });

    yield put(changeMenuProductPrice.success(data));
  } catch (err) {
    yield put(changeMenuProductPrice.failure(err));
  } finally {
    yield put(changeMenuProductPrice.fulfill());
  }
}

export default function* menuProductsSaga() {
  yield takeLatest(getMenusProducts.TRIGGER, handleGetMenusProductsRequest);
  yield takeLatest(changeMenuProductPrice.TRIGGER, handleChangeMenuProductPriceRequest);
}
