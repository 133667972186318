import { useState, Children } from 'react';
import PropTypes from 'prop-types';

import { getCollapsePrincipalMenuKey } from 'utils/principalMenu';

import SidebarLayoutContext from './SidebarLayoutContext';

function SidebarLayoutProvider({ children }) {
  const openMdMenu = getCollapsePrincipalMenuKey();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMdDrawer, setOpenMdDrawer] = useState(openMdMenu);
  const [modeDraft, setModeDraft] = useState(false);

  const contextDefault = {
    openDrawer,
    setOpenDrawer,
    openMdDrawer,
    setOpenMdDrawer,
    modeDraft,
    setModeDraft,
  };

  return (
    <SidebarLayoutContext.Provider value={contextDefault}>{Children.only(children)}</SidebarLayoutContext.Provider>
  );
}

SidebarLayoutProvider.propTypes = {
  children: PropTypes.element,
};

export { default as useSidebarLayout } from './useSidebarLayout';
export default SidebarLayoutProvider;
