import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Warning = forwardRef(function Warning(props, ref) {
  const { color = '#F6BE2C', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M13.3529 3.17647C13.3529 2.52672 12.8262 2 12.1765 2C11.5267 2 11 2.52672 11 3.17647V14.9412C11 15.5909 11.5267 16.1176 12.1765 16.1176C12.8262 16.1176 13.3529 15.5909 13.3529 14.9412V3.17647ZM13.3529 19.6471C13.3529 18.9973 12.8262 18.4706 12.1765 18.4706C11.5267 18.4706 11 18.9973 11 19.6471V20.8235C11 21.4733 11.5267 22 12.1765 22C12.8262 22 13.3529 21.4733 13.3529 20.8235V19.6471Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

Warning.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Warning;
