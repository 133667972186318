import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';

interface Props {
  children: React.ReactNode;
  open: boolean;
}

function ModalBase({ children, open }: Props) {
  return (
    <Dialog data-testid="modalBase" fullScreen open={open}>
      <Box display="grid" height="100%" justifyContent="center" sx={{ overflow: 'auto' }} width="100%">
        {children}
      </Box>
    </Dialog>
  );
}

export default ModalBase;
