import Box from '@material-ui/core/Box';
import ListItemMui from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@design-system/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    color: (prop: { disabled: boolean }) => (prop.disabled ? theme.palette.darkGrey[15] : theme.palette.text.primary),
  },
  secondary: {
    fontSize: 18,
    marginLeft: theme.spacing(1),
    color: (prop: { disabled: boolean }) => (prop.disabled ? theme.palette.darkGrey[15] : theme.palette.text.primary),
  },
  itemIcon: {
    width: 32,
    position: 'relative',
  },
}));

interface Props {
  text: string;
  disabled?: boolean;
  value: string;
  hasCheck?: boolean;
  onClick?: (value: string) => void;
  secondaryText?: string;
}

function ListItem({ disabled = false, hasCheck = false, onClick, text, secondaryText = '', value }: Props) {
  const classes = useStyles({ disabled });
  function handleClick() {
    if (onClick) onClick(value);
  }

  return (
    <ListItemMui button disabled={disabled} id={`item-${value}`} onClick={() => (!hasCheck ? handleClick() : null)}>
      {hasCheck && (
        <Box className={classes.itemIcon}>
          <Checkbox checked disabled={disabled} id={`chk-${value}`} label="" value={value} />
        </Box>
      )}
      <ListItemText
        classes={{ root: classes.root, secondary: classes.secondary }}
        primary={text}
        secondary={secondaryText}
      />
    </ListItemMui>
  );
}

export default ListItem;
