import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { duplicateRoleRequest } from 'utils/api/baas/workforce/roles';

import { duplicateRole } from '../actions';

export function* handleDuplicateRoleRequest(action) {
  const jobUuid = action.payload;

  try {
    yield put(duplicateRole.request());

    const { data } = yield call(baasRequest, duplicateRoleRequest, jobUuid);

    yield put(duplicateRole.success(data));
  } catch (err) {
    yield put(duplicateRole.failure(err));
  }
}
