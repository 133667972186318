import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subtitles: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
  },

  wrapperBox: {
    height: theme.typography.pxToRem(300),
    width: '100%',
  },

  container: {
    width: '100%',
    height: '100%',
  },

  inputSearch: {
    marginBottom: '1rem',
  },

  contentLists: {
    display: 'flex',
    height: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: theme.spacing(3),
    alignItems: 'flex-start',
  },

  labelList: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1),
  },

  boxList: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.darkGrey[15]}`,
    boxSizing: 'border-box',
    width: '100%',
    minWidth: theme.typography.pxToRem(390),
    height: 'calc(100% - 30px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '@media screen and (max-height: 600px)': {
      height: theme.typography.pxToRem(250),
    },
  },

  boxItem: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.darkGrey[15]}`,
    minWidth: '75%',
    maxWidth: 'fit-content',
    padding: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: theme.spacing(1),
  },

  itemText: {
    color: `${theme.palette.itemList.main} !important`,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },

  itemStore: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },

  boxMessages: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  itemRemoved: {
    textDecoration: 'line-through',
  },
}));

export default useStyles;
