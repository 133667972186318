import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getEmployeeRequest, updateEmployeeRequest, updatePassportRequest } from 'utils/api/baas/stores/employees';

import { getEmployeeDetail, editEmployee, updatePassport } from './actions';

export function* handleGetEmployeeDetailsRequest(action) {
  const { storeUuid, employeeUuid } = action.payload;

  try {
    yield put(getEmployeeDetail.request());

    const { data } = yield call(baasRequest, getEmployeeRequest, { storeUuid, employeeUuid });

    yield put(getEmployeeDetail.success(data));
  } catch (err) {
    yield put(getEmployeeDetail.failure(err));
  }
}

export function* handleEditEmployeeRequest(action) {
  const { storeUuid, employeeUuid, employee } = action.payload;

  try {
    yield put(editEmployee.request());

    const { data } = yield call(baasRequest, updateEmployeeRequest, {
      storeUuid,
      employeeUuid,
      ...employee,
    });

    yield put(editEmployee.success(data));
  } catch (err) {
    yield put(editEmployee.failure(err));
  }
}

export function* handleUpdatePassportRequest(action) {
  const { storeUuid, employeeUuid, password } = action.payload;

  try {
    yield put(updatePassport.request());

    yield call(baasRequest, updatePassportRequest, {
      storeUuid,
      employeeUuid,
      password,
    });

    yield put(updatePassport.success());
  } catch (err) {
    yield put(updatePassport.failure(err));
  }
}

export default function* employeesDetailsProviderSaga() {
  yield takeLatest(getEmployeeDetail.TRIGGER, handleGetEmployeeDetailsRequest);
  yield takeLatest(editEmployee.TRIGGER, handleEditEmployeeRequest);
  yield takeLatest(updatePassport.TRIGGER, handleUpdatePassportRequest);
}
