import { useState, Children } from 'react';
import PropTypes from 'prop-types';

import ViewPanelContext from './ViewPanelContext';

function ViewPanelProvider({ children }) {
  const [open, setOpen] = useState(false);

  const contextDefault = {
    open,
    setOpen,
  };

  return <ViewPanelContext.Provider value={contextDefault}>{Children.only(children)}</ViewPanelContext.Provider>;
}

ViewPanelProvider.propTypes = {
  children: PropTypes.element,
};

export { default as ViewPanelContext } from './ViewPanelContext';
export default ViewPanelProvider;
