import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ContentLayout } from 'Layout';

import useUserStore from 'hooks/useUserStore';

import useStyles from './styles';

function MenuMakerTabsNavigation({ t }) {
  const classes = useStyles();

  const {
    storeState: { selected: storeSelected },
  } = useUserStore();

  return (
    <div className={classes.root}>
      <ContentLayout.TabsNavigation>
        <ContentLayout.TabLink label={t('menuMaker:tabs.menusResume')} to="/menus/resume" />
        <ContentLayout.TabLink label={t('menuMaker:tabs.menus')} to="/menus" />
        <ContentLayout.TabLink label={t('menuMaker:tabs.categories')} to="/menus/categories" />
        <ContentLayout.TabLink label={t('menuMaker:tabs.articles')} to="/menus/products" />
        <ContentLayout.TabLink label={t('menuMaker:tabs.customizableGroups')} to="/menus/modifier-groups" />
        {!storeSelected?.isDraft && <ContentLayout.TabLink label={t('menuMaker:tabs.publish')} to="/menus/publish" />}
      </ContentLayout.TabsNavigation>
    </div>
  );
}

MenuMakerTabsNavigation.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('menuMaker')(MenuMakerTabsNavigation);
