/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUserCurrentStore } from 'utils/auth';

export const getValidateItemsAvailabilityFlags = (flags: { [key: string]: any }) => {
  const currentStore = getUserCurrentStore()?.uuid;

  const enableValidateItemsAvailability =
    flags.validateItemsAvailabilityAtPublish ||
    flags.validateItemsAvailabilityByStore?.storesUuidList?.includes(currentStore);

  return { enableValidateItemsAvailability };
};
