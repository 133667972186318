import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getReportsCashRegisterSessionDetailAll } from './actions';

import {
  selectCashRegisterSessionStatus,
  selectCashRegisterSessionState,
  selectCashRegisterSessionSections,
} from './selectors';

export function withReportsCashRegisterSessionDetailHoc(Component) {
  function withReportsCashRegisterSessionDetail(props) {
    return <Component {...props} />;
  }

  withReportsCashRegisterSessionDetail.displayName = `withReportsCashRegisterSessionDetail(${getDisplayName(
    Component,
  )})`;
  return withReportsCashRegisterSessionDetail;
}

const mapStateToProps = createStructuredSelector({
  cashRegisterSessionStatus: selectCashRegisterSessionStatus,
  cashRegisterSessionState: selectCashRegisterSessionState,
  cashRegisterSessionSections: selectCashRegisterSessionSections,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadReportsCashRegisterSessionSections: (filters = {}) => dispatch(getReportsCashRegisterSessionDetailAll(filters)),
    resetReportsCashRegisterSessionSections: () => dispatch(getReportsCashRegisterSessionDetailAll.reset()),
  };
}

export const withReportsCashRegisterSessionDetailState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withReportsCashRegisterSessionDetailState, withReportsCashRegisterSessionDetailHoc);
