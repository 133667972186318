import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getBrandsRequest } from 'utils/api/baas/stores/brands';

import { getBrands } from './actions';

export function* handleGetBrandsRequest() {
  try {
    yield put(getBrands.request());

    const { data } = yield call(baasRequest, getBrandsRequest);

    yield put(getBrands.success(data));
  } catch (err) {
    yield put(getBrands.failure(err));
  } finally {
    yield put(getBrands.fulfill());
  }
}

export default function* brandsProviderSaga() {
  yield takeLatest(getBrands.TRIGGER, handleGetBrandsRequest);
}
