import { takeLatest, put, call } from 'redux-saga/effects';

// import baasRequest from 'utils/baasRequest';
import {
  getStatesRequest,
  getMunicipalityListRequest,
  getNeighborhoodListRequest,
  getInfoZipCodeRequest,
  getInfoZipCodeGeocodingRequest,
} from 'utils/api/baas/address';

import {
  getStatesList,
  getMunicipalityList,
  getNeighborhoodList,
  getInfoZipCode,
  getInfoZipCodeGeocoding,
} from './actions';

export function* handleGetStatesListRequest() {
  try {
    yield put(getStatesList.request());

    const { response } = yield call(getStatesRequest);

    yield put(getStatesList.success({ data: response?.estado }));
  } catch (err) {
    yield put(getStatesList.failure(err));
  }
}

export function* handleGetMunicipalityListRequest(action) {
  const state = action.payload;

  try {
    yield put(getMunicipalityList.request());

    const { response } = yield call(getMunicipalityListRequest, state);

    yield put(getMunicipalityList.success({ data: response?.municipios }));
  } catch (err) {
    yield put(getMunicipalityList.failure(err));
  }
}

export function* handleGetNeighborhoodListRequest(action) {
  const municipality = action.payload;

  try {
    yield put(getNeighborhoodList.request());

    const { response } = yield call(getNeighborhoodListRequest, municipality);

    yield put(getNeighborhoodList.success({ data: response?.colonia }));
  } catch (err) {
    yield put(getNeighborhoodList.failure(err));
  }
}

export function* handleGetInfoZipCodeSaga(action) {
  const zipCode = action.payload;

  try {
    yield put(getInfoZipCode.request());

    const { response } = yield call(getInfoZipCodeRequest, zipCode);

    yield put(getInfoZipCode.success(response));
  } catch (err) {
    yield put(getInfoZipCode.failure(err));
  }
}

export function* handleGetInfoZipCodeGeocodingSaga(action) {
  const { zipCode, street, numberExterior } = action.payload;

  try {
    yield put(getInfoZipCodeGeocoding.request());

    const { response } = yield call(getInfoZipCodeGeocodingRequest, { zipCode, street, numberExterior });

    yield put(getInfoZipCodeGeocoding.success(response));
  } catch (err) {
    yield put(getInfoZipCodeGeocoding.failure(err));
  }
}

export default function* invoicingProviderRootSga() {
  yield takeLatest(getStatesList.TRIGGER, handleGetStatesListRequest);
  yield takeLatest(getMunicipalityList.TRIGGER, handleGetMunicipalityListRequest);
  yield takeLatest(getNeighborhoodList.TRIGGER, handleGetNeighborhoodListRequest);
  yield takeLatest(getInfoZipCode.TRIGGER, handleGetInfoZipCodeSaga);
  yield takeLatest(getInfoZipCodeGeocoding.TRIGGER, handleGetInfoZipCodeGeocodingSaga);
}
