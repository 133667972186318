import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as R from 'ramda';

const EmptyDefault = forwardRef(function EmptyDefault(props, ref) {
  const { size: sizeProp = 107, sizeBase = 75, ...others } = props;

  const sizeWidth = R.add(sizeProp, sizeBase);

  return (
    <svg
      ref={ref}
      fill="none"
      height={sizeProp}
      viewBox="0 0 75 107"
      width={sizeWidth}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M59.4994 22.2858C59.4994 22.2858 66.3114 23.1429 67.2746 16.5057C67.2746 16.5057 66.9494 23.0454 74.3994 25.4419C74.3994 25.4419 67.8138 24.4508 65.8381 32.2334C65.8257 32.2456 67.859 23.0819 59.4994 22.2858Z"
        fill="#F59397"
      />
      <path
        d="M38.3998 3.20232C38.3998 3.20232 37.1888 13.0032 31.6621 15.5389C31.6621 15.5389 40.0159 13.4229 44.2102 21.1554C44.2102 21.1554 43.3509 13.0827 50.2266 9.62144C47.8823 9.60004 45.5784 9.00371 43.5166 7.88463C41.4548 6.76556 39.6979 5.15788 38.3998 3.20232Z"
        fill="#F59397"
      />
      <rect
        fill="#F04E4A"
        height="38.3373"
        transform="rotate(-45 41.9481 79.3143)"
        width="6.97042"
        x="41.9481"
        y="79.3143"
      />
      <circle cx="32.1006" cy="62.9229" fill="#F04E4A" r="31.5" />
      <circle cx="32.1006" cy="62.9229" fill="white" r="25.5" />
    </svg>
  );
});

EmptyDefault.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sizeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EmptyDefault;
