import React from 'react';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';

const HeadRoot = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  'box-shadow': '18px 3px 11px 3px rgba(164, 170, 186, 0.15)',
  position: 'sticky',
  zIndex: 2,
  top: theme.typography.pxToRem(51),
  padding: '.5rem 1rem',
}));

interface Props {
  children: React.ReactNode;
  columnStructure?: string;
  height?: number | string;
}

const Head = ({ children, columnStructure = '2fr repeat(4, 1fr)', height = 'auto', ...others }: Props) => {
  return (
    <HeadRoot aria-label="Head" gridTemplateColumns={columnStructure} height={height} {...others}>
      {children}
    </HeadRoot>
  );
};

export default Head;
