import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  getClientDataRequest,
  getClientDetailsRequest,
  exportClientsReportRequest,
} from 'utils/api/baas/reports/clientData';

import * as actions from './actions';

export function* getClientData({ payload }) {
  try {
    yield put(actions.getClientData.request());

    const result = yield call(baasRequest, getClientDataRequest, payload);

    yield put(actions.getClientData.success({ ...result, pagination: { ...result.pagination, page: payload.page } }));
  } catch (error) {
    yield put(actions.getClientData.failure(error));
  }
}

export function* getPaginationClients({ payload }) {
  try {
    yield put(actions.getPaginationClients.request());

    const result = yield call(baasRequest, getClientDataRequest, payload);

    yield put(
      actions.getPaginationClients.success({ ...result, pagination: { ...result.pagination, page: payload.page } }),
    );
  } catch (error) {
    yield put(actions.getPaginationClients.failure(error));
  }
}

export function* getClientDetails({ payload }) {
  try {
    yield put(actions.getClientDetails.request());

    const result = yield call(baasRequest, getClientDetailsRequest, payload);

    yield put(
      actions.getClientDetails.success({ ...result, pagination: { ...result.pagination, page: payload.page } }),
    );
  } catch (error) {
    yield put(actions.getClientDetails.failure(error));
  }
}

export function* exportClientsReport({ payload }) {
  try {
    yield put(actions.exportClientsReport.request());

    const result = yield call(baasRequest, exportClientsReportRequest, payload);

    yield put(actions.exportClientsReport.success(result));
  } catch (error) {
    yield put(actions.exportClientsReport.failure(error));
  }
}

export function* getPaginationDetails({ payload }) {
  try {
    yield put(actions.getPaginationDetails.request());

    const result = yield call(baasRequest, getClientDetailsRequest, payload);

    yield put(
      actions.getPaginationDetails.success({ ...result, pagination: { ...result.pagination, page: payload.page } }),
    );
  } catch (error) {
    yield put(actions.getPaginationDetails.failure(error));
  }
}

export default function* reportClientDataSaga() {
  yield takeLatest(actions.getClientData.TRIGGER, getClientData);
  yield takeLatest(actions.getPaginationClients.TRIGGER, getPaginationClients);
  yield takeLatest(actions.getClientDetails.TRIGGER, getClientDetails);
  yield takeLatest(actions.exportClientsReport.TRIGGER, exportClientsReport);
  yield takeLatest(actions.getPaginationDetails.TRIGGER, getPaginationDetails);
}
