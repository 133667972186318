import produce from 'immer';

import {
  getStatesList,
  getMunicipalityList,
  getNeighborhoodList,
  getInfoZipCode,
  getInfoZipCodeGeocoding,
} from './actions';

const baseState = {
  error: null,
  fetching: false,
  fetched: false,
};

export const initialState = {
  getStatesList: {
    ...baseState,
    states: [],
  },

  getMunicipalityList: {
    ...baseState,
    municipalityList: [],
  },

  getNeighborhoodList: {
    ...baseState,
    neighborhoodList: [],
  },

  getInfoZipCode: {
    ...baseState,
    infoZipCode: null,
  },

  getInfoZipCodeGeocoding: {
    ...baseState,
    infoZipCodeGeocoding: null,
  },
};

const addressProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getStatesList.REQUEST:
        draft.getStatesList = {
          ...initialState.getStatesList,
          fetching: true,
        };
        break;

      case getStatesList.SUCCESS:
        draft.getStatesList.fetching = false;
        draft.getStatesList.fetched = true;
        draft.getStatesList.states = payload.data;
        break;

      case getStatesList.FAILURE:
        draft.getStatesList.error = payload;
        draft.getStatesList.fetching = false;
        draft.getStatesList.fetched = false;
        break;

      case getStatesList.RESET:
        draft.getStatesList = {
          ...initialState.getStatesList,
        };
        break;

      case getMunicipalityList.REQUEST:
        draft.getMunicipalityList = {
          ...initialState.getMunicipalityList,
          fetching: true,
        };
        break;

      case getMunicipalityList.SUCCESS:
        draft.getMunicipalityList.fetching = false;
        draft.getMunicipalityList.fetched = true;
        draft.getMunicipalityList.municipalityList = payload.data;
        break;

      case getMunicipalityList.FAILURE:
        draft.getMunicipalityList.error = payload;
        draft.getMunicipalityList.fetching = false;
        draft.getMunicipalityList.fetched = false;
        break;

      case getMunicipalityList.RESET:
        draft.getMunicipalityList = {
          ...initialState.getMunicipalityList,
        };
        break;
      case getNeighborhoodList.REQUEST:
        draft.getNeighborhoodList = {
          ...initialState.getNeighborhoodList,
          fetching: true,
        };
        break;

      case getNeighborhoodList.SUCCESS:
        draft.getNeighborhoodList.fetching = false;
        draft.getNeighborhoodList.fetched = true;
        draft.getNeighborhoodList.neighborhoodList = payload.data;
        break;

      case getNeighborhoodList.FAILURE:
        draft.getNeighborhoodList.error = payload;
        draft.getNeighborhoodList.fetching = false;
        draft.getNeighborhoodList.fetched = false;
        break;

      case getNeighborhoodList.RESET:
        draft.getNeighborhoodList = {
          ...initialState.getNeighborhoodList,
        };
        break;

      case getInfoZipCode.REQUEST:
        draft.getInfoZipCode = {
          ...initialState.getInfoZipCode,
          fetching: true,
        };
        break;

      case getInfoZipCode.SUCCESS:
        draft.getInfoZipCode.fetching = false;
        draft.getInfoZipCode.fetched = true;
        draft.getInfoZipCode.infoZipCode = payload;

        break;

      case getInfoZipCode.FAILURE:
        draft.getInfoZipCode.error = payload;
        draft.getInfoZipCode.fetching = false;
        draft.getInfoZipCode.fetched = false;
        break;

      case getInfoZipCode.RESET:
        draft.getInfoZipCode = {
          ...initialState.getInfoZipCode,
        };
        break;

      case getInfoZipCodeGeocoding.REQUEST:
        draft.getInfoZipCodeGeocoding = {
          ...initialState.getInfoZipCodeGeocoding,
          fetching: true,
        };
        break;

      case getInfoZipCodeGeocoding.SUCCESS:
        draft.getInfoZipCodeGeocoding.fetching = false;
        draft.getInfoZipCodeGeocoding.fetched = true;
        draft.getInfoZipCodeGeocoding.infoZipCodeGeocoding = payload;

        break;

      case getInfoZipCodeGeocoding.FAILURE:
        draft.getInfoZipCodeGeocoding.error = payload;
        draft.getInfoZipCodeGeocoding.fetching = false;
        draft.getInfoZipCodeGeocoding.fetched = false;
        break;

      case getInfoZipCodeGeocoding.RESET:
        draft.getInfoZipCodeGeocoding = {
          ...initialState.getInfoZipCodeGeocoding,
        };
        break;

      default:
        return draft;
    }
  });

export default addressProviderReducer;
