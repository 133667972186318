import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_PORTAL_CONFIG_LIST,
  SAVE_PORTAL_CONFIG,
  PORTAL_CONFIG_DETAILS,
  PORTAL_CONFIG_UPDATE,
  INVOICING_ENABLE_AUTO,
  INVOICING_CONFIG_LIST,
  INVOICING_CONFIG_CREATE,
  INVOICING_GET_BRANDS_NOT_CONFIGURED,
  INVOICING_CONFIG_DETAILS,
  INVOICING_CONFIG_UPDATE,
  INVOICING_CONFIG_DELETE,
} from './constants';

export const getPortalConfigList = extendRoutine(createRoutine(GET_PORTAL_CONFIG_LIST), 'RESET');
export const savePortalConfig = extendRoutine(createRoutine(SAVE_PORTAL_CONFIG), 'RESET');
export const portalConfigDetails = extendRoutine(createRoutine(PORTAL_CONFIG_DETAILS), 'RESET');
export const portalConfigUpdate = extendRoutine(createRoutine(PORTAL_CONFIG_UPDATE), 'RESET');
export const invoicingConfigList = extendRoutine(createRoutine(INVOICING_CONFIG_LIST), 'RESET');
export const invoicingConfigCreate = extendRoutine(createRoutine(INVOICING_CONFIG_CREATE), 'RESET');
export const invoicingGetBrandsNotConfigured = extendRoutine(
  createRoutine(INVOICING_GET_BRANDS_NOT_CONFIGURED),
  'RESET',
);
export const getInvoicingConfigDetails = extendRoutine(createRoutine(INVOICING_CONFIG_DETAILS), 'RESET');
export const updateInvoicingConfig = extendRoutine(createRoutine(INVOICING_CONFIG_UPDATE), 'RESET');
export const deleteInvoicingConfig = extendRoutine(createRoutine(INVOICING_CONFIG_DELETE), 'RESET');
export const enableInvoicingAuto = extendRoutine(createRoutine(INVOICING_ENABLE_AUTO), 'RESET');
