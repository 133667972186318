import { useState, useEffect, useRef } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import { useDispatch } from 'react-redux';
import useUserStore from 'hooks/useUserStore';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';

import useNotification from '@design-system/Notification/useNotification';

import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import { deleteProduct as deleteProductAction } from 'providers/menuMaker/Products/actions';

import { getProductAssociations, deleteProduct, DeleteProductRequestProps } from '../services/removeProductService';

const QUERY = 'product';

interface OptionsProps {
  name: string;
  sku: string;
}

interface ProductResponseProps {
  recipe: {
    uuid: string;
    name: string;
  };
  options: OptionsProps[];
}
interface ParamsProps {
  sku: string;
  isSoldAlone: boolean | null;
}
export function useGetProductoAssociations() {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const [params, setParams] = useState<ParamsProps>({ sku: '', isSoldAlone: null });
  const { refetch, data, isError, isFetched, isFetching, remove } = useQuery(
    QUERY,
    () => getProductAssociations(params.sku, params.isSoldAlone || false),
    {
      enabled: false,
      select: (response) => {
        if (!response?.data) {
          return {
            hasAssociation: false,
            associations: [],
          };
        }
        const associations: string[] = [];
        const { recipe, options } = response.data as ProductResponseProps;
        if (options.length > 0) {
          options.forEach((o) => associations.push(o.name));
        }

        return {
          hasAssociation: Boolean(recipe.uuid) || associations.length > 0,
          associations,
        };
      },
      onError: () => {
        setNotification(t('menuMaker:productForm.messages.errorOnGetProductAssociations'), {
          variant: 'error',
        });
      },
    },
  );

  function loadProductAssociations(sku: string, isSoldAlone: boolean) {
    setParams({ sku, isSoldAlone });
  }

  function reset() {
    remove();
    setParams({ sku: '', isSoldAlone: null });
  }

  useEffect(() => {
    if (params.sku) refetch();
  }, [params.sku]);

  return {
    productAssociationsState: {
      isError,
      isFetched,
      isFetching,
    },
    resetLoadProductAssociaions: reset,
    loadProductAssociations,
    productAssociations: data || { hasAssociation: null, associations: [] },
  };
}

export function useDeleteProduct() {
  const { startDebounce } = useDebounce(300);
  const { logEvent } = useAmplitude();
  const dispatch = useDispatch();
  const { t } = useTranslation('menuMaker');
  const { setNotification } = useNotification();
  const uuid = useRef('');
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();

  const mutationDeleteProduct = useMutation((params: DeleteProductRequestProps) => deleteProduct(params), {
    mutationKey: `${QUERY}-delete`,
    onError: () => {
      setNotification(t('menuMaker:productForm.messages.deleteError'), {
        variant: 'error',
      });
    },
    onSuccess: () => {
      setNotification(t('menuMaker:productForm.messages.deleteSuccess'), {
        variant: 'success',
      });
      logEvent('@event.$menuMaker.menuMaker', ['@@section.products', '@@type.delete', '@@item.products'], {
        itemId: uuid.current,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      startDebounce(() => {
        dispatch(deleteProductAction.fulfill({ productUuid: uuid.current }));
        uuid.current = '';
      });
    },
  });

  async function deleteProductMiddleware(params: DeleteProductRequestProps) {
    uuid.current = params.uuid;
    mutationDeleteProduct.mutate(params);
  }

  return {
    deleteProductState: {
      isError: mutationDeleteProduct.isError,
      isSuccess: mutationDeleteProduct.isSuccess,
      isFetching: mutationDeleteProduct.isLoading,
    },
    deleteProduct: deleteProductMiddleware,
    resetDeleteProduct: () => mutationDeleteProduct.reset(),
  };
}
