import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Information from '@experimental-components/IconsComponents/Indicators/Information';

interface propsStyles {
  withBorderColor?: string;
  withBackgroundColor?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    border: ({ withBorderColor }: propsStyles) =>
      withBorderColor ? `1px solid ${withBorderColor}` : '1px solid #44AEC9',
    backgroundColor: ({ withBackgroundColor }: propsStyles) => withBackgroundColor || '#E8F5FF',
    borderRadius: 4,
    width: '100%',
    padding: 16,
    gap: 12,
  },
}));

interface Props {
  text: string;
  withBackgroundColor?: string;
  withBorderColor?: string;
  withTextColor?: string;
  icon?: React.ReactNode;
}

function InformationIndicator({ text, withBackgroundColor, withBorderColor, withTextColor, icon }: Props) {
  const classes = useStyles({ withBorderColor, withBackgroundColor });
  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column" justifyItems="center">
        {icon || <Information />}
      </Box>
      <Box color={withTextColor || 'text.secondary'}>{text}</Box>
    </Box>
  );
}

export default InformationIndicator;
