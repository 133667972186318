import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCellMui from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ChevronBase from '@experimental-components/IconsComponents/ChevronBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const TableCell = withStyles((theme) => ({
  root: {
    padding: '1rem .75rem',
    fontWeight: 500,
    color: theme.palette.darkGrey.main,
    borderBottom: 0,
    lineHeight: 'normal',
    letterSpacing: 0,
  },
  stickyHeader: {
    top: theme.typography.pxToRem(53),
    'box-shadow': '18px 3px 11px 3px rgba(164, 170, 186, 0.15)',
  },
}))(TableCellMui);
const useStyles = makeStyles((theme) => ({
  sortLabelRoot: {
    lineHeight: 'normal !important',
    gap: theme.spacing(0.5),
  },
  sortLabelActive: {
    color: (props) => (props.active ? '#000 !important' : `${theme.palette.text.secondary} !important`),
  },
  sortLabelIcon: {
    opacity: (props) => (props.active ? '1 !important' : '0.5 !important'),
    margin: 0,
  },
}));
function TableHeadCell({
  activeColumn: activeHelperProp,
  disabled,
  isSorting,
  direction,
  children,
  isFetching,
  ...others
}) {
  const classes = useStyles({ active: activeHelperProp });
  const IconSorting = ({ ...props }) => (
    <Box component="span" height={14} width={14}>
      <ChevronBase color="#F04E4A" {...props} />
    </Box>
  );
  const LoadingIcon = () => (
    <Box mb="-4px" ml={1}>
      <CircularProgress size={12} />
    </Box>
  );

  return (
    <TableCell disabled={disabled} {...others}>
      {isSorting && (
        <TableSortLabel
          active={!isFetching}
          classes={{ root: classes.sortLabelRoot, active: classes.sortLabelActive, icon: classes.sortLabelIcon }}
          direction={direction}
          disabled={disabled || isFetching}
          IconComponent={isFetching ? LoadingIcon : IconSorting}
        >
          {children}
        </TableSortLabel>
      )}
      {!isSorting && children}
    </TableCell>
  );
}
TableHeadCell.propTypes = {
  ...TableCellMui.propTypes,
  ...TableSortLabel.propTypes,
};
export default TableHeadCell;
