/**
 *
 * ParrotSoftware
 * SettingsServiceAreasProvider reducer
 *
 */
import produce from 'immer';
import baseState from 'utils/baseState';
import changeIsDefaultServicesAreas from 'utils/tableAreasService/changeIsDefaultServicesAreas';

import {
  createServiceArea,
  getServicesAreas,
  deleteServiceArea,
  getServiceAreaDetails,
  updateServiceArea,
  validateNameArea,
  serviceAreaChangeDefault,
} from './actions';

export const initialState = {
  createServiceArea: {
    ...baseState,
  },

  getServicesAreas: {
    ...baseState,
    areas: [],
  },

  deleteServiceArea: {
    ...baseState,
  },

  updateServiceArea: {
    ...baseState,
  },

  serviceAreaChangeDefault: {
    ...baseState,
  },

  getServiceAreaDetails: {
    ...baseState,
    area: {},
  },

  validateNameArea: {
    ...baseState,
    isNameRegistered: false,
  },
};

const settingsServiceAreasReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case createServiceArea.REQUEST:
        draft.createServiceArea = {
          ...initialState.createServiceArea,
          fetching: true,
        };
        break;

      case createServiceArea.SUCCESS: {
        const serviceAreasLits = [...draft.getServicesAreas.areas];
        serviceAreasLits.unshift(payload);

        draft.createServiceArea.fetching = false;
        draft.createServiceArea.fetched = true;

        draft.getServicesAreas.areas = serviceAreasLits;
        break;
      }

      case createServiceArea.FAILURE:
        draft.createServiceArea.error = payload;
        draft.createServiceArea.fetching = false;
        draft.createServiceArea.fetched = false;
        break;

      case createServiceArea.RESET:
        draft.createServiceArea = {
          ...initialState.createServiceArea,
        };
        break;

      case getServicesAreas.REQUEST:
        draft.getServicesAreas = {
          ...initialState.getServicesAreas,
          fetching: true,
        };
        break;

      case getServicesAreas.SUCCESS:
        draft.getServicesAreas.fetching = false;
        draft.getServicesAreas.fetched = true;
        draft.getServicesAreas.areas = payload;
        break;

      case getServicesAreas.FAILURE:
        draft.getServicesAreas.error = payload;
        draft.getServicesAreas.fetching = false;
        draft.getServicesAreas.fetched = false;
        break;

      case getServicesAreas.RESET:
        draft.getServicesAreas = {
          ...initialState.getServicesAreas,
        };
        break;

      case getServicesAreas.SORT_POSITION: {
        const sortedValues = payload || [];

        const order = sortedValues.map((item, index) => {
          return {
            ...item,
            sortPosition: index,
          };
        });
        draft.getServicesAreas.areas = order;
        break;
      }

      case getServiceAreaDetails.REQUEST:
        draft.getServiceAreaDetails = {
          ...initialState.getServiceAreaDetails,
          fetching: true,
        };
        break;

      case getServiceAreaDetails.SUCCESS:
        draft.getServiceAreaDetails.fetching = false;
        draft.getServiceAreaDetails.fetched = true;
        draft.getServiceAreaDetails.area = payload;
        break;

      case getServiceAreaDetails.FAILURE:
        draft.getServiceAreaDetails.error = payload;
        draft.getServiceAreaDetails.fetching = false;
        draft.getServiceAreaDetails.fetched = false;
        break;

      case getServiceAreaDetails.RESET:
        draft.getServiceAreaDetails = {
          ...initialState.getServiceAreaDetails,
        };
        break;

      case updateServiceArea.REQUEST:
        draft.updateServiceArea = {
          ...initialState.updateServiceArea,
          fetching: true,
        };
        break;

      case updateServiceArea.SUCCESS: {
        const areaUpdate = draft.getServicesAreas.areas.findIndex((area) => {
          return area.uuid === payload.uuid;
        });

        draft.updateServiceArea.fetching = false;
        draft.updateServiceArea.fetched = true;

        draft.getServicesAreas.areas[areaUpdate] = payload;
        break;
      }

      case updateServiceArea.FAILURE:
        draft.updateServiceArea.error = payload;
        draft.updateServiceArea.fetching = false;
        draft.updateServiceArea.fetched = false;
        break;

      case updateServiceArea.RESET:
        draft.updateServiceArea = {
          ...initialState.updateServiceArea,
        };
        break;

      case serviceAreaChangeDefault.REQUEST:
        draft.serviceAreaChangeDefault = {
          ...initialState.serviceAreaChangeDefault,
          fetching: true,
        };
        break;

      case serviceAreaChangeDefault.SUCCESS: {
        const currentAreas = draft.getServicesAreas.areas;
        const areas = changeIsDefaultServicesAreas(currentAreas, payload);

        draft.serviceAreaChangeDefault.fetching = false;
        draft.serviceAreaChangeDefault.fetched = true;
        draft.getServicesAreas.areas = areas;
        break;
      }

      case serviceAreaChangeDefault.FAILURE:
        draft.serviceAreaChangeDefault.error = payload;
        draft.serviceAreaChangeDefault.fetching = false;
        draft.serviceAreaChangeDefault.fetched = false;
        break;

      case serviceAreaChangeDefault.RESET:
        draft.serviceAreaChangeDefault = {
          ...initialState.serviceAreaChangeDefault,
        };
        break;

      case deleteServiceArea.REQUEST:
        draft.deleteServiceArea = {
          ...initialState.deleteServiceArea,
          fetching: true,
        };
        break;

      case deleteServiceArea.SUCCESS:
        draft.deleteServiceArea.fetching = false;
        draft.deleteServiceArea.fetched = true;
        break;

      case deleteServiceArea.FULFILL: {
        const currentAreas = draft.getServicesAreas.areas.filter((area) => {
          return area.uuid !== payload.areaUuid;
        });

        draft.getServicesAreas.areas = currentAreas;
        break;
      }

      case deleteServiceArea.FAILURE:
        draft.deleteServiceArea.error = payload;
        draft.deleteServiceArea.fetching = false;
        draft.deleteServiceArea.fetched = false;
        break;

      case deleteServiceArea.RESET:
        draft.deleteServiceArea = {
          ...initialState.deleteServiceArea,
        };
        break;

      case validateNameArea.REQUEST:
        draft.validateNameArea = {
          ...initialState.validateNameArea,
          fetching: true,
        };
        break;

      case validateNameArea.SUCCESS:
        draft.validateNameArea.fetching = false;
        draft.validateNameArea.fetched = true;
        draft.validateNameArea.isNameRegistered = payload.nameRegistered;
        break;

      case validateNameArea.FAILURE:
        draft.validateNameArea.error = payload;
        draft.validateNameArea.fetching = false;
        draft.validateNameArea.fetched = false;
        break;

      case validateNameArea.RESET:
        draft.validateNameArea = {
          ...initialState.validateNameArea,
        };
        break;

      default:
        return draft;
    }
  });

export default settingsServiceAreasReducer;
