import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import ButtonBase from '@material-ui/core/ButtonBase';
import useTheme from '@material-ui/core/styles/useTheme';

import { makeStyles } from '@material-ui/core/styles';
import { TooltipStyled } from 'containers/Reports/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: ({ disabledAction, isDisbanded }) =>
      disabledAction || isDisbanded ? `1px solid ${theme.palette.darkGrey[5]}` : `1px solid #C8C7CF`,
    borderRadius: theme.typography.pxToRem(8),
    boxShadow: '0px 8px 40px rgba(196, 208, 237, 0.15)',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid black',
    },
    '&:active': {
      transform: 'scale(.9)',
    },
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 150,
    minWidth: 150,
    padding: 12,
    width: '100%',
    height: '100%',
    maxHeight: 80,
    minHeight: 80,
    fontFamily: 'Roboto',
  },
}));

function ButtonCard({
  children,
  isDisbanded,
  disabledAction,
  onClick,
  onClickHelper,
  isLoading = false,
  tooltipTitle,
  ...others
}) {
  const theme = useTheme();
  const classes = useStyles({ disabledAction, isDisbanded });

  function handleClick() {
    if (onClick && !disabledAction) onClick();
    if (disabledAction && onClickHelper) onClickHelper();
  }

  if (isLoading) {
    return <Skeleton height={104} variant="rect" width={211} />;
  }

  if (!isLoading && tooltipTitle) {
    return (
      <TooltipStyled title={tooltipTitle}>
        <ButtonBase
          classes={{ root: classes.root }}
          disabled={isDisbanded}
          disableRipple
          onClick={handleClick}
          {...others}
        >
          <Box
            color={disabledAction || isDisbanded ? theme.palette.darkGrey[15] : theme.palette.text.secondary}
            fontSize={theme.typography.pxToRem(16)}
            fontWeight={400}
            textAlign="center"
          >
            {children}
          </Box>
        </ButtonBase>
      </TooltipStyled>
    );
  }
  return (
    <ButtonBase classes={{ root: classes.root }} disabled={isDisbanded} disableRipple onClick={handleClick} {...others}>
      <Box
        color={disabledAction || isDisbanded ? theme.palette.darkGrey[15] : theme.palette.text.secondary}
        fontSize={theme.typography.pxToRem(16)}
        fontWeight={400}
        textAlign="center"
      >
        {children}
      </Box>
    </ButtonBase>
  );
}

ButtonCard.propTypes = {
  ...ButtonCard.propTypes,
};

export default ButtonCard;
