/**
 *
 * ParrotSoftware
 * PaymentsGatewayProvider constants
 *
 */
export const GET_PAYMENTS_METHODS = 'providers/onlineOrdering/PaymentsGatewayProvider/GET_PAYMENTS_METHODS';
export const CREATE_PAYMENT_METHOD = 'providers/onlineOrdering/PaymentsGatewayProvider/CREATE_PAYMENT_METHOD';
export const RESUME_PAYMENT_METHOD = 'providers/onlineOrdering/PaymentsGatewayProvider/RESUME_PAYMENT_METHOD';
export const UPDATE_PAYMENT_METHOD_STATUS =
  'providers/onlineOrdering/PaymentsGatewayProvider/UPDATE_PAYMENT_METHOD_STATUS';
export const REMOVE_PAYMENT_METHOD = 'providers/onlineOrdering/PaymentsGatewayProvider/REMOVE_PAYMENT_METHOD';
