/**
 *
 * ParrotSoftware
 * SettingsServiceAreasProvider saga
 *
 */
import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  createServicesAreasRequest,
  getServicesAreasRequest,
  deleteServicesAreaRequest,
  getServiceAreaDetailsRequest,
  updateServiceAreaRequest,
  validateNameAreaRequest,
  updateAreaPositionRequest,
} from 'utils/api/baas/tableAreasService/serviceArea';

import {
  createServiceArea,
  getServicesAreas,
  deleteServiceArea,
  getServiceAreaDetails,
  updateServiceArea,
  validateNameArea,
  serviceAreaChangeDefault,
  updateAreaPosition,
} from './actions';

export function* createServiceAreaSaga(action) {
  const areaName = action.payload;

  try {
    yield put(createServiceArea.request());

    const { data } = yield call(baasRequest, createServicesAreasRequest, areaName);

    yield put(createServiceArea.success(data));
  } catch (err) {
    yield put(createServiceArea.failure(err));
  }
}

export function* getServicesAreasSaga() {
  try {
    yield put(getServicesAreas.request());

    const { data } = yield call(baasRequest, getServicesAreasRequest);

    yield put(getServicesAreas.success(data));
  } catch (err) {
    yield put(getServicesAreas.failure(err));
  }
}

export function* getServiceAreaDetailsSaga(action) {
  const areaUuid = action.payload;

  try {
    yield put(getServiceAreaDetails.request());

    const { data } = yield call(baasRequest, getServiceAreaDetailsRequest, areaUuid);

    yield put(getServiceAreaDetails.success(data));
  } catch (err) {
    yield put(getServiceAreaDetails.failure(err));
  }
}

export function* updateServiceAreaSaga(action) {
  const area = action.payload;

  try {
    yield put(updateServiceArea.request());

    const { data } = yield call(baasRequest, updateServiceAreaRequest, area);

    yield put(updateServiceArea.success(data));
  } catch (err) {
    yield put(updateServiceArea.failure(err));
  }
}

export function* serviceAreaChangeDefaultSaga(action) {
  const area = action.payload;

  try {
    yield put(serviceAreaChangeDefault.request());

    const { data } = yield call(baasRequest, updateServiceAreaRequest, area);

    yield put(serviceAreaChangeDefault.success(data));
  } catch (err) {
    yield put(serviceAreaChangeDefault.failure(err));
  }
}

export function* deleteServiceAreaSaga(action) {
  const areaUuid = action.payload;

  try {
    yield put(deleteServiceArea.request());

    yield call(baasRequest, deleteServicesAreaRequest, areaUuid);

    yield put(deleteServiceArea.success());
  } catch (err) {
    yield put(deleteServiceArea.failure(err));
  } finally {
    yield put(deleteServiceArea.fulfill({ areaUuid }));
  }
}
export function* validateNameAreaSaga(action) {
  const values = action.payload;

  try {
    yield put(validateNameArea.request());

    const { data } = yield call(baasRequest, validateNameAreaRequest, values);

    yield put(validateNameArea.success(data));
  } catch (err) {
    yield put(validateNameArea.failure(err));
  }
}

export function* handleUpdateServiceAreaPositionSaga(action) {
  const values = action.payload;
  try {
    yield put(updateAreaPosition.request());

    const { data } = yield call(baasRequest, updateAreaPositionRequest, values);

    yield put(updateAreaPosition.success(data));
  } catch (err) {
    yield put(updateAreaPosition.failure(err));
  }
}

export default function* settingsServiceAreasRootSga() {
  yield takeLatest(createServiceArea.TRIGGER, createServiceAreaSaga);
  yield takeLatest(getServicesAreas.TRIGGER, getServicesAreasSaga);
  yield takeLatest(getServiceAreaDetails.TRIGGER, getServiceAreaDetailsSaga);
  yield takeLatest(updateServiceArea.TRIGGER, updateServiceAreaSaga);
  yield takeLatest(serviceAreaChangeDefault.TRIGGER, serviceAreaChangeDefaultSaga);
  yield takeLatest(deleteServiceArea.TRIGGER, deleteServiceAreaSaga);
  yield takeLatest(validateNameArea.TRIGGER, validateNameAreaSaga);
  yield takeLatest(updateAreaPosition.TRIGGER, handleUpdateServiceAreaPositionSaga);
}
