import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_MENUS,
  GET_PAGINATION_MENU,
  CREATE_MENU,
  PUBLISH_MENU,
  DELETE_MENU,
  DUPLICATE_MENU,
  COPY_MENU_CONFIG,
  PUBLISH_MENUS,
  MENUS_DETAILS_RESUME,
  SYNC_PRODUCT_OVERRIDE,
} from './constants';

export const getMenus = extendRoutine(createRoutine(GET_MENUS), ['UPDATE', 'SORT_POSITION', 'RESET']);
export const getPaginationMenu = extendRoutine(createRoutine(GET_PAGINATION_MENU), 'RESET');
export const createMenu = extendRoutine(createRoutine(CREATE_MENU), 'RESET');
export const publishMenu = extendRoutine(createRoutine(PUBLISH_MENU), 'RESET');
export const duplicateMenu = extendRoutine(createRoutine(DUPLICATE_MENU), 'RESET');
export const deleteMenu = extendRoutine(createRoutine(DELETE_MENU), 'RESET');
export const copyMenuConfig = extendRoutine(createRoutine(COPY_MENU_CONFIG), 'RESET');
export const publishMenus = extendRoutine(createRoutine(PUBLISH_MENUS), 'RESET');
export const menusDetailsResume = extendRoutine(createRoutine(MENUS_DETAILS_RESUME), ['INVALIDATE_MENUS', 'RESET']);
export const syncProductOverride = createRoutine(SYNC_PRODUCT_OVERRIDE);
