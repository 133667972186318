import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getBrandsRequest, updateBrandsRequest } from 'utils/api/baas/stores/brands';

import { getBrands, updateBrands } from './actions';

export function* handleGetBrandsRequest() {
  try {
    yield put(getBrands.request());

    const { data } = yield call(baasRequest, getBrandsRequest);

    yield put(getBrands.success(data));
  } catch (err) {
    yield put(getBrands.failure(err));
  } finally {
    yield put(getBrands.fulfill());
  }
}

export function* handleUpdateBrandsRequest(action) {
  const brands = action.payload;

  try {
    yield put(updateBrands.request());

    const { data } = yield call(baasRequest, updateBrandsRequest, brands);

    yield put(updateBrands.success(data));
  } catch (err) {
    yield put(updateBrands.failure(err));
  } finally {
    yield put(updateBrands.fulfill());
  }
}

export default function* brandsProviderSaga() {
  yield takeLatest(getBrands.TRIGGER, handleGetBrandsRequest);
  yield takeLatest(updateBrands.TRIGGER, handleUpdateBrandsRequest);
}
