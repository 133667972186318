import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';

const InputRoot = withStyles((theme) => {
  const placeholder = {
    color: (props) => (props.disabled ? theme.palette.darkGrey[15] : theme.palette.darkGrey.main),
    opacity: 1,
  };

  const placeholderHidden = {
    opacity: 0.5,
  };

  const placeholderVisible = {
    opacity: 0.5,
  };

  const getBorderClass = (props) => {
    if (props?.disabled) {
      return `${theme.palette.darkGrey[15]}`;
    }

    if (props?.error) {
      return `${theme.palette.error.main}`;
    }

    return `${theme.palette.darkGrey[50]}`;
  };

  return {
    input: {
      position: 'relative',
      backgroundColor: (props) => (props.disabled ? theme.palette.darkGrey[5] : theme.palette.common.white),
      borderRadius: theme.shape.borderRadius,
      borderColor: (props) => getBorderClass(props),
      borderStyle: 'solid',
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      fontWeight: 400,
      padding: 16,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&::-webkit-input-placeholder': placeholder,
      '&::-moz-placeholder': placeholder, // Firefox 19+
      '&:-ms-input-placeholder': placeholder, // IE 11
      '&::-ms-input-placeholder': placeholder, // Edge

      'label[data-shrink=false] + $formControl &': {
        '&::-webkit-input-placeholder': placeholderHidden,
        '&::-moz-placeholder': placeholderHidden, // Firefox 19+
        '&:-ms-input-placeholder': placeholderHidden, // IE 11
        '&::-ms-input-placeholder': placeholderHidden, // Edge
        '&:focus::-webkit-input-placeholder': placeholderVisible,
        '&:focus::-moz-placeholder': placeholderVisible, // Firefox 19+
        '&:focus:-ms-input-placeholder': placeholderVisible, // IE 11
        '&:focus::-ms-input-placeholder': placeholderVisible, // Edge
      },

      '&:hover': {
        borderColor: (props) => (props.disabled ? `${theme.palette.darkGrey[15]}` : `${theme.palette.common.black}`),
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
      },

      '&:focus': {
        borderRadius: theme.shape.borderRadius,
        borderColor: (props) => (props.disabled ? `${theme.palette.darkGrey[15]}` : `${theme.palette.common.black}`),
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
      },
    },

    disabled: {
      color: theme.palette.darkGrey[50],
    },

    error: {
      color: theme.palette.error.main,
    },
  };
})(InputBase);

function Input({ id, error, fullWidth, disabled, required, ...others }) {
  return (
    <FormControl error={error} fullWidth={fullWidth} required={required}>
      <InputRoot disabled={disabled} error={error} id={id} required={required} {...others} />
    </FormControl>
  );
}

Input.propTypes = {
  ...InputBase.propTypes,
};

export default Input;
