import { useState, forwardRef } from 'react';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';

const MenuBase = withStyles(() => ({
  paper: { boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)' },
}))(Menu);

const MenuItemBase = withStyles(() => ({
  root: { padding: '14px 24px' },
}))(MenuItem);

let closeOptions;
let changeOption;

const Option = forwardRef((props, ref) => {
  const { children, value } = props;

  const handleClickOption = () => {
    closeOptions();
    changeOption(value);
  };

  return (
    <MenuItemBase key={value} ref={ref} data-testid={value} name={value} onClick={handleClickOption}>
      {children}
    </MenuItemBase>
  );
});

function CustomDropdown({ children, renderButton, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  closeOptions = () => {
    setAnchorEl(null);
  };

  changeOption = (value) => {
    onChange(value);
  };

  return (
    <Box>
      {renderButton(handleOpenOptions)}

      <MenuBase
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        keepMounted
        onClose={closeOptions}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {children}
      </MenuBase>
    </Box>
  );
}

CustomDropdown.Option = Option;

export default CustomDropdown;
