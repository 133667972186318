import { useUser } from 'containers/Users';
import { getAuthToken, getCookieUser, getUserCurrentStore } from 'utils/authTyped';

export const useAuth = () => {
  const tokens = getAuthToken();
  const cookieUser = getCookieUser();
  const store = getUserCurrentStore();

  const { data: user } = useUser({ userUuid: cookieUser?.uuid, storeUuid: store?.uuid });

  function removeAuthData() {}

  return {
    isAuthenticated: Boolean(tokens?.accessToken && cookieUser && store),
    user,
    store,
    removeAuthData,
  };
};
