import produce from 'immer';

import baseState from 'utils/baseState';

import { sortingPropertyTypes } from 'utils/products';

import {
  getProductsOptions,
  getProductsOptionsByPagination,
  getProductsOptionsByFilter,
  getProductsAutocomplete,
  getProductsAutocompleteByPagination,
  getProductsAutocompleteByFilter,
} from './actions';

export const initialState = {
  getProductsOptions: {
    ...baseState,
    products: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
    loadPaginationStatus: {
      ...baseState,
      lastUpdatedOptions: [],
    },
    loadFilterStatus: {
      ...baseState,
      filterByText: '',
      orderBy: sortingPropertyTypes.NAME,
      soldAlone: true,
      lastFilterOptions: [],
    },
  },
  getProductsAutocomplete: {
    ...baseState,
    products: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
    loadPaginationStatus: {
      ...baseState,
      lastUpdatedOptions: [],
    },
    loadFilterStatus: {
      ...baseState,
      filterByText: '',
      orderBy: sortingPropertyTypes.NAME,
      lastFilterOptions: [],
    },
  },
};

const listsOptionsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getProductsOptions.REQUEST:
        draft.getProductsOptions = {
          ...initialState.getProductsOptions,
          fetching: true,
        };
        break;

      case getProductsOptions.SUCCESS:
        draft.getProductsOptions.fetching = false;
        draft.getProductsOptions.fetched = true;
        draft.getProductsOptions.products = payload.products;
        draft.getProductsOptions.pagination = {
          ...initialState.getProductsOptions.pagination,
          ...payload.pagination,
        };
        break;

      case getProductsOptions.FAILURE:
        draft.getProductsOptions.error = payload;
        draft.getProductsOptions.fetching = false;
        draft.getProductsOptions.fetched = false;
        break;

      case getProductsOptions.RESET:
        draft.getProductsOptions = {
          ...initialState.getProductsOptions,
        };
        break;

      // pagination
      case getProductsOptionsByPagination.REQUEST:
        draft.getProductsOptions.loadPaginationStatus = {
          ...initialState.getProductsOptions.loadPaginationStatus,
          fetching: true,
        };
        break;

      case getProductsOptionsByPagination.SUCCESS: {
        const currentProductsOptionsPaginationState = draft.getProductsOptions;
        let newsRows = [...currentProductsOptionsPaginationState?.products];
        newsRows = [...newsRows, ...payload?.products];

        draft.getProductsOptions = {
          ...currentProductsOptionsPaginationState,
          products: [...newsRows],
          pagination: { ...currentProductsOptionsPaginationState?.pagination, ...payload.pagination },
          loadPaginationStatus: {
            ...initialState.getProductsOptions.loadPaginationStatus,
            fetching: false,
            fetched: true,
            lastUpdatedOptions: [...payload?.products],
          },
        };

        break;
      }

      case getProductsOptionsByPagination.FAILURE:
        draft.getProductsOptions.loadPaginationStatus = {
          ...initialState.getProductsOptions.loadPaginationStatus,
          error: payload,
          fetching: false,
        };
        break;

      case getProductsOptionsByPagination.RESET:
        draft.getProductsOptions.loadPaginationStatus = {
          ...initialState.getProductsOptions.loadPaginationStatus,
        };
        break;

      // filter
      case getProductsOptionsByFilter.REQUEST: {
        const currentProductsOptionsFilterState = draft.getProductsOptions;
        draft.getProductsOptions = {
          ...currentProductsOptionsFilterState,
          pagination: {
            ...initialState.getProductsOptions.pagination,
          },
          loadFilterStatus: {
            ...initialState.getProductsOptions.loadFilterStatus,
            fetching: true,
          },
        };

        break;
      }

      case getProductsOptionsByFilter.SUCCESS: {
        const currentProductsOptionsFilterState = draft.getProductsOptions;
        draft.getProductsOptions = {
          ...currentProductsOptionsFilterState,
          products: payload?.products,
          pagination: { ...currentProductsOptionsFilterState.pagination, ...payload?.pagination },
          loadFilterStatus: {
            ...currentProductsOptionsFilterState.loadFilterStatus,
            fetching: false,
            fetched: true,
            lastFilterOptions: payload?.products,
          },
        };

        break;
      }

      case getProductsOptionsByFilter.FILTER_TEXT:
        draft.getProductsOptions.loadFilterStatus = {
          ...draft.getProductsOptions.loadFilterStatus,
          fetched: false,
          ...payload,
        };
        break;

      case getProductsOptionsByFilter.RESET_FILTER_TEXT:
        draft.getProductsOptions.loadFilterStatus = {
          ...initialState.getProductsOptions.loadFilterStatus,
        };
        break;

      case getProductsOptionsByFilter.FAILURE:
        draft.getProductsOptions.loadFilterStatus = {
          ...draft.getProductsOptions.loadFilterStatus,
          error: payload,
          fetching: false,
          fetched: false,
        };

        break;

      case getProductsOptionsByFilter.RESET:
        draft.getProductsOptions.loadFilterStatus = {
          ...initialState.getProductsOptions.loadFilterStatus,
        };
        break;

      case getProductsAutocomplete.REQUEST:
        draft.getProductsAutocomplete = {
          ...initialState.getProductsAutocomplete,
          fetching: true,
        };
        break;

      case getProductsAutocomplete.SUCCESS:
        draft.getProductsAutocomplete.fetching = false;
        draft.getProductsAutocomplete.fetched = true;
        draft.getProductsAutocomplete.products = payload.products;
        draft.getProductsAutocomplete.pagination = {
          ...initialState.getProductsAutocomplete.pagination,
          ...payload.pagination,
        };
        break;

      case getProductsAutocomplete.FAILURE:
        draft.getProductsAutocomplete.error = payload;
        draft.getProductsAutocomplete.fetching = false;
        draft.getProductsAutocomplete.fetched = false;
        break;

      case getProductsAutocomplete.RESET:
        draft.getProductsAutocomplete = {
          ...initialState.getProductsAutocomplete,
        };
        break;

      // pagination
      case getProductsAutocompleteByPagination.REQUEST:
        draft.getProductsAutocomplete.loadPaginationStatus = {
          ...initialState.getProductsAutocomplete.loadPaginationStatus,
          fetching: true,
        };
        break;

      case getProductsAutocompleteByPagination.SUCCESS: {
        const currentProductsAutocompletePaginationState = draft.getProductsAutocomplete;
        let newsRows = [...currentProductsAutocompletePaginationState?.products];
        newsRows = [...newsRows, ...payload?.products];

        draft.getProductsAutocomplete = {
          ...currentProductsAutocompletePaginationState,
          products: [...newsRows],
          pagination: { ...currentProductsAutocompletePaginationState?.pagination, ...payload.pagination },
          loadPaginationStatus: {
            ...initialState.getProductsAutocomplete.loadPaginationStatus,
            fetching: false,
            fetched: true,
            lastUpdatedOptions: [...payload?.products],
          },
        };

        break;
      }

      case getProductsAutocompleteByPagination.FAILURE:
        draft.getProductsAutocomplete.loadPaginationStatus = {
          ...initialState.getProductsAutocomplete.loadPaginationStatus,
          error: payload,
          fetching: false,
        };
        break;

      case getProductsAutocompleteByPagination.RESET:
        draft.getProductsAutocomplete.loadPaginationStatus = {
          ...initialState.getProductsAutocomplete.loadPaginationStatus,
        };
        break;

      // filter
      case getProductsAutocompleteByFilter.REQUEST: {
        const currentProductsAutocompleteFilterState = draft.getProductsAutocomplete;
        draft.getProductsAutocomplete = {
          ...currentProductsAutocompleteFilterState,
          pagination: {
            ...initialState.getProductsAutocomplete.pagination,
          },
          loadFilterStatus: {
            ...initialState.getProductsAutocomplete.loadFilterStatus,
            fetching: true,
          },
        };

        break;
      }

      case getProductsAutocompleteByFilter.SUCCESS: {
        const currentProductsAutocompleteFilterState = draft.getProductsAutocomplete;
        draft.getProductsAutocomplete = {
          ...currentProductsAutocompleteFilterState,
          products: payload?.products,
          pagination: { ...currentProductsAutocompleteFilterState.pagination, ...payload?.pagination },
          loadFilterStatus: {
            ...currentProductsAutocompleteFilterState.loadFilterStatus,
            fetching: false,
            fetched: true,
            lastFilterOptions: payload?.products,
          },
        };

        break;
      }

      case getProductsAutocompleteByFilter.FILTER_TEXT:
        draft.getProductsAutocomplete.loadFilterStatus = {
          ...draft.getProductsAutocomplete.loadFilterStatus,
          fetched: false,
          ...payload,
        };
        break;

      case getProductsAutocompleteByFilter.RESET_FILTER_TEXT:
        draft.getProductsAutocomplete.loadFilterStatus = {
          ...initialState.getProductsAutocomplete.loadFilterStatus,
        };
        break;

      case getProductsAutocompleteByFilter.FAILURE:
        draft.getProductsAutocomplete.loadFilterStatus = {
          ...draft.getProductsAutocomplete.loadFilterStatus,
          error: payload,
          fetching: false,
          fetched: false,
        };

        break;

      case getProductsAutocompleteByFilter.RESET:
        draft.getProductsAutocomplete.loadFilterStatus = {
          ...initialState.getProductsAutocomplete.loadFilterStatus,
        };
        break;

      default:
        return draft;
    }
  });

export default listsOptionsReducer;
