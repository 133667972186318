import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  selectProducts,
  selectProductsCategories,
  selectProductsFetching,
  selectProductsFetched,
  selectProductsError,
  selectCreateProduct,
  selectCreateProductFetching,
  selectCreateProductFetched,
  selectCreateProductError,
  selectDeleteProductFetching,
  selectDeleteProductFetched,
  selectDeleteProductError,
  selectToggleProductAvailabilityFetching,
  selectToggleProductAvailabilityFetched,
  selectToggleProductAvailabilityError,
  selectUpdateProductImageFetching,
  selectUpdateProductImageFetched,
  selectUpdateProductImageError,
  selectProductsPaginationInfo,
  selectGetProductsPaginationScrollState,
  selectDuplicateProductObject,
  selectDuplicateProductState,
} from './selectors';

import {
  getProducts,
  createProduct,
  deleteProduct,
  duplicateProduct,
  toggleProductAvailability,
  updateProductImage,
  getProductsScrollPagination,
} from './actions';

export function withProductsHoc(Component) {
  function withModifierGroups(props) {
    return <Component {...props} />;
  }
  withModifierGroups.displayName = `withProducts(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withModifierGroups;
}

const mapStateToProps = createStructuredSelector({
  products: selectProducts,
  categories: selectProductsCategories,
  productsFetching: selectProductsFetching,
  productsFetched: selectProductsFetched,
  productsError: selectProductsError,
  createProductObject: selectCreateProduct,
  createProductFetching: selectCreateProductFetching,
  createProductFetched: selectCreateProductFetched,
  createProductError: selectCreateProductError,
  deleteProductFetching: selectDeleteProductFetching,
  deleteProductFetched: selectDeleteProductFetched,
  deleteProductError: selectDeleteProductError,
  toggleProductAvailabilityFetching: selectToggleProductAvailabilityFetching,
  toggleProductAvailabilityFetched: selectToggleProductAvailabilityFetched,
  toggleProductAvailabilityError: selectToggleProductAvailabilityError,
  updateProductImageFetching: selectUpdateProductImageFetching,
  updateProductImageFetched: selectUpdateProductImageFetched,
  updateProductImageError: selectUpdateProductImageError,
  productsPaginationInfo: selectProductsPaginationInfo,
  loadProductsPaginationScrollState: selectGetProductsPaginationScrollState,
  duplicateProductObject: selectDuplicateProductObject,
  duplicateProductState: selectDuplicateProductState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadProducts: (filters = {}) => dispatch(getProducts(filters)),
    createProduct: (values) => dispatch(createProduct(values)),
    handleToggleProductAvailability: ({ productUuid, availability }) =>
      dispatch(toggleProductAvailability({ productUuid, availability })),
    duplicateProduct: ({ storeUuid, productUuid }) => dispatch(duplicateProduct({ storeUuid, productUuid })),
    handleDeleteProduct: (productUuid) => dispatch(deleteProduct(productUuid)),
    updateProductImage: ({ storeUuid, productUuid, image }) =>
      dispatch(updateProductImage({ storeUuid, productUuid, image })),
    resetProducts: () => dispatch(getProducts.reset()),
    resetCreateProduct: () => dispatch(createProduct.reset()),
    resetDeleteProduct: () => dispatch(deleteProduct.reset()),
    resetDuplicateProduct: () => dispatch(duplicateProduct.reset()),
    resetToggleProductAvailability: () => dispatch(toggleProductAvailability.reset()),
    resetUpdateProductImage: () => dispatch(updateProductImage.reset()),
    loadProductsScrollPagination: (filters = {}) => dispatch(getProductsScrollPagination(filters)),
    resetProductsScrollPagination: () => dispatch(getProductsScrollPagination.reset()),
  };
}

export const withProductsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withProductsState, withProductsHoc);
