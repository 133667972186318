/* eslint-disable react/no-array-index-key */
// @ts-nocheck
import Skeleton from '@material-ui/lab/Skeleton';

import Table from '@experimental-components/Table';

import useStyles from './styles';

function SkeletonList() {
  const classes = useStyles();

  return (
    <>
      {[...Array(2)].map((row, i) => (
        <Table.Row key={`tr-${i}`} className={classes.cell}>
          {[...Array(2)].map((col, j) => (
            <Table.Cell key={`tr-${i}-td-${j}`} isBorder={false}>
              <Skeleton height={40} width="100%" />
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </>
  );
}

export default SkeletonList;
