import produce from 'immer';

import baseState from 'utils/baseState';
import {
  sectionsCashRegisterSessionReducer,
  sectionsCashRegisterSessionObject,
} from 'utils/reports/cashRegisterSessionDetailed';

import { getReportsCashRegisterSessionDetailAll } from './actions';

export const initialState = {
  cashRegisterSessionAll: {
    ...baseState,
    session: {},
    sections: {
      ...sectionsCashRegisterSessionReducer,
    },
  },
};

const reportsCashRegisterSessionDetailReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getReportsCashRegisterSessionDetailAll.REQUEST: {
        const cashRegisterSession = draft.cashRegisterSessionAll;
        draft.cashRegisterSessionAll = {
          ...cashRegisterSession,
          fetching: true,
        };
        break;
      }

      case getReportsCashRegisterSessionDetailAll.SUCCESS: {
        const sectionsState = draft.cashRegisterSessionAll.sections;
        draft.cashRegisterSessionAll.fetching = false;
        draft.cashRegisterSessionAll.fetched = true;
        draft.cashRegisterSessionAll.session = payload?.session;
        draft.cashRegisterSessionAll.sections = {
          ...sectionsState,
          [sectionsCashRegisterSessionObject[payload?.sectionSelected]]: { isLoaded: true, ...payload?.section },
        };
        break;
      }

      case getReportsCashRegisterSessionDetailAll.FAILURE:
        draft.cashRegisterSessionAll.error = payload;
        draft.cashRegisterSessionAll.fetching = false;
        draft.cashRegisterSessionAll.fetched = false;
        break;

      case getReportsCashRegisterSessionDetailAll.RESET:
        draft.cashRegisterSessionAll = {
          ...initialState.cashRegisterSessionAll,
        };
        break;

      default:
        return draft;
    }
  });

export default reportsCashRegisterSessionDetailReducer;
