import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_REPORTS_DISCOUNTS_RESUME,
  GET_REPORTS_DISCOUNTS_RESUME_BY_PAGINATION,
  GET_REPORTS_DISCOUNTS_RESUME_BY_SORTING,
  GET_REPORTS_DISCOUNTS_DETAILS,
  GET_REPORTS_DISCOUNTS_DETAILS_BY_PAGINATION,
  GET_REPORTS_DISCOUNTS_DETAILS_BY_SORTING,
  EXPORT_REPORTS_DISCOUNTS,
} from './constants';

export const getReportsDiscountsResume = extendRoutine(createRoutine(GET_REPORTS_DISCOUNTS_RESUME), 'RESET');
export const getReportsDiscountsResumeByPagination = extendRoutine(
  createRoutine(GET_REPORTS_DISCOUNTS_RESUME_BY_PAGINATION),
  'RESET',
);
export const getReportsDiscountsResumeBySorting = extendRoutine(
  createRoutine(GET_REPORTS_DISCOUNTS_RESUME_BY_SORTING),
  ['RESET', 'SORTING', 'RESET_SORTING'],
);

export const getReportsDiscountsDetails = extendRoutine(createRoutine(GET_REPORTS_DISCOUNTS_DETAILS), 'RESET');
export const getReportsDiscountsDetailsByPagination = extendRoutine(
  createRoutine(GET_REPORTS_DISCOUNTS_DETAILS_BY_PAGINATION),
  'RESET',
);
export const getReportsDiscountsDetailsBySorting = extendRoutine(
  createRoutine(GET_REPORTS_DISCOUNTS_DETAILS_BY_SORTING),
  ['RESET', 'SORTING', 'RESET_SORTING'],
);

export const exportReportsDiscounts = extendRoutine(createRoutine(EXPORT_REPORTS_DISCOUNTS), 'RESET');
