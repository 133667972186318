import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_KITCHEN_AREAS,
  GET_KITCHEN_AREAS_PAGINATION,
  CREATE_KITCHEN_AREA,
  GET_KITCHEN_AREA_DETAILS,
  UPDATE_KITCHEN_AREA,
  DISABLE_KITCHEN_AREA,
} from './constants';

export const getKitchenAreas = extendRoutine(createRoutine(GET_KITCHEN_AREAS), 'RESET');
export const getKitchenAreasPagination = extendRoutine(createRoutine(GET_KITCHEN_AREAS_PAGINATION), 'RESET');
export const getKitchenAreaDetails = extendRoutine(createRoutine(GET_KITCHEN_AREA_DETAILS), 'RESET');
export const createKitchenArea = extendRoutine(createRoutine(CREATE_KITCHEN_AREA), 'RESET');
export const updateKitchenArea = extendRoutine(createRoutine(UPDATE_KITCHEN_AREA), 'RESET');
export const disableKitchenArea = extendRoutine(createRoutine(DISABLE_KITCHEN_AREA), 'RESET');
