/**
 *
 * ParrotSoftware
 * SettingsTablesConfigProvider hoc
 *
 */
import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { updateTableConfig, getStoreTableSettings } from './actions';
import {
  selectGetStoreTableSettings,
  selectGetStoreTableSettingsState,
  selectUpdateTableConfigState,
} from './selectors';

export function withSettingsTablesConfigHoc(Component) {
  function withSettingsTablesConfig(props) {
    return <Component {...props} />;
  }

  withSettingsTablesConfig.displayName = `withSettingsTablesConfig(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withSettingsTablesConfig;
}

const mapStateToProps = createStructuredSelector({
  updateTableConfigState: selectUpdateTableConfigState,
  storeTableSettings: selectGetStoreTableSettings,
  loadStoreTableSettingsState: selectGetStoreTableSettingsState,
});

function mapDispatchToProps(dispatch) {
  return {
    loadStoreTableSettings: () => dispatch(getStoreTableSettings()),
    resetStoreTableSettings: () => dispatch(getStoreTableSettings.reset()),
    updateTableConfig: (values = {}) => dispatch(updateTableConfig(values)),
    resetUpdateTableConfig: () => dispatch(updateTableConfig.reset()),
  };
}

export const withSettingsTablesConfigState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withSettingsTablesConfigState, withSettingsTablesConfigHoc);
