import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getCategoryDetail, editCategory, getProductsSortCategory, updateProductsSortCategory } from './actions';

import {
  selectCategoryDetailStore,
  selectCategoryDetailFetching,
  selectCategoryDetailError,
  selectCategoryDetailFetched,
  selectEditCategoryStore,
  selectEditCategoryFetching,
  selectEditCategoryFetched,
  selectEditCategoryError,
  selectProductsSortCategory,
  selectProductsSortCategoryState,
  selectUpdateProductsSortCategory,
  selectUpdateProductsSortCategoryState,
} from './selectors';

export function withCategoryDetailsHoc(Component) {
  function withCategoryDetails(props) {
    return <Component {...props} />;
  }

  withCategoryDetails.displayName = `withCategoryDetails(${getDisplayName(Component)})`;

  return withCategoryDetails;
}

const mapStateToProps = createStructuredSelector({
  categoryDetailObject: selectCategoryDetailStore,
  categoryDetailFetching: selectCategoryDetailFetching,
  categoryDetailError: selectCategoryDetailError,
  categoryDetailFetched: selectCategoryDetailFetched,
  categoryEditObject: selectEditCategoryStore,
  categoryEditFetching: selectEditCategoryFetching,
  categoryEditError: selectEditCategoryError,
  categoryEditFetched: selectEditCategoryFetched,
  productsSortCategory: selectProductsSortCategory,
  productsSortCategoryState: selectProductsSortCategoryState,
  updateProductsSort: selectUpdateProductsSortCategory,
  updateProductsSortCategoryState: selectUpdateProductsSortCategoryState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadCategoryDetail: (categoryUuid) => dispatch(getCategoryDetail(categoryUuid)),
    editCategory: ({ storeUuid, categoryUuid, category }) =>
      dispatch(editCategory({ storeUuid, categoryUuid, category })),
    loadProductsSortCategory: ({ storeUuid, categoryUuid }) =>
      dispatch(getProductsSortCategory({ storeUuid, categoryUuid })),
    updateProductsSortCategory: ({ storeUuid, categoryUuid, productsSort }) =>
      dispatch(updateProductsSortCategory({ storeUuid, categoryUuid, productsSort })),
    resetCategoryDetail: () => dispatch(getCategoryDetail.reset()),
    resetEditCategory: () => dispatch(editCategory.reset()),
    resetProductsSortCategory: () => dispatch(getProductsSortCategory.reset()),
    resetUpdateProductsSortCategory: () => dispatch(updateProductsSortCategory.reset()),
  };
}

export const withCategoryDetailsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withCategoryDetailsState, withCategoryDetailsHoc);
