/**
 *
 * ParrotSoftware
 * SettingsAdminTablesProvider hoc
 *
 */
import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  createTable,
  getTables,
  deleteTable,
  getTableDetails,
  updateTable,
  createTablesBulk,
  validateName,
  getTablesScrollPagination,
  updateTablePosition,
} from './actions';
import {
  selectCreateTableState,
  selectGetTables,
  selectGetTablesState,
  selectDeleteTableState,
  selectGetTableDetails,
  selectGetTableDetailsState,
  selectUpdateTableState,
  selectCreateTablesBulkState,
  selectValidateNameState,
  selectTablesPaginationInfo,
  selectGetTablesPaginationScrollState,
  selectTablesGroupByServiceArea,
} from './selectors';

export function withSettingsAdminTablesHoc(Component) {
  function withSettingsAdminTables(props) {
    return <Component {...props} />;
  }

  withSettingsAdminTables.displayName = `withSettingsAdminTables(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withSettingsAdminTables;
}

const mapStateToProps = createStructuredSelector({
  createTableState: selectCreateTableState,
  tablesList: selectGetTables,
  getTablesState: selectGetTablesState,
  deleteTableState: selectDeleteTableState,
  tableDetails: selectGetTableDetails,
  updateTableState: selectUpdateTableState,
  loadTableDetailsState: selectGetTableDetailsState,
  createTablesBulkState: selectCreateTablesBulkState,
  validateNameState: selectValidateNameState,
  tablesPaginationInfo: selectTablesPaginationInfo,
  getTablesPaginationScrollState: selectGetTablesPaginationScrollState,
  getTablesGroupByServiceArea: selectTablesGroupByServiceArea,
});

function mapDispatchToProps(dispatch) {
  return {
    createTable: (values) => dispatch(createTable(values)),
    deleteTable: (tableUuid) => dispatch(deleteTable(tableUuid)),
    loadTableDetails: (tableUuid) => dispatch(getTableDetails(tableUuid)),
    resetLoadTableDetails: () => dispatch(getTableDetails.reset()),
    resetDeleteTable: () => dispatch(deleteTable.reset()),
    resetCreateTable: () => dispatch(createTable.reset()),
    loadTables: (filters = {}) => dispatch(getTables(filters)),
    resetLoadTables: () => dispatch(getTables.reset()),
    updateTable: ({ tableUuid, table }) => dispatch(updateTable({ tableUuid, table })),
    resetUpdateTable: () => dispatch(updateTable.reset()),
    createTablesBulk: (values) => dispatch(createTablesBulk(values)),
    resetCreateTablesBulk: () => dispatch(createTablesBulk.reset()),
    validateName: (value) => dispatch(validateName(value)),
    resetValidateName: () => dispatch(validateName.reset()),
    loadTablesScrollPagination: (filters = {}) => dispatch(getTablesScrollPagination(filters)),
    resetLoadTablesScrollPagination: () => dispatch(getTablesScrollPagination.reset()),
    sortLocalPosition: (sortedValues) => dispatch(getTables.sortPosition(sortedValues)),
    onUpdateOrder: (values) => dispatch(updateTablePosition(values)),
  };
}

export const withSettingsAdminTablesState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withSettingsAdminTablesState, withSettingsAdminTablesHoc);
