import { useEffect, useState, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setBrandSelectedLocalStorage, getBrandSelectedLocalStorage } from 'utils/menuMaker';

import { selectedBrand, getBrands } from './actions';
import { selectBrands, selectBrandsFetching, selectBrandsFetched, selectSelectedBrand } from './selectors';

function useBrands() {
  const dispatch = useDispatch();

  const [brandsList, setBrandsList] = useState([]);

  const brands = useSelector(selectBrands);
  const loading = useSelector(selectBrandsFetching);
  const isLoaded = useSelector(selectBrandsFetched);
  const brandSelected = useSelector(selectSelectedBrand);
  const brandSelectedLocalStorage = getBrandSelectedLocalStorage();

  useEffect(() => {
    setBrandsList(brands);
  }, [brands]);

  useEffect(() => {
    if (isLoaded) {
      if (brandSelectedLocalStorage) {
        const brand = brands.find((item) => item?.uuid === brandSelectedLocalStorage);
        if (brand) handleSetSelectedBrand(brand);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const handleGetBrands = useCallback(() => {
    dispatch(getBrands());
  }, [dispatch]);

  const handleSetSelectedBrand = useCallback(
    (brand) => {
      setBrandSelectedLocalStorage({ brandUuid: brand?.uuid });
      dispatch(selectedBrand.selected(brand));
    },
    [dispatch],
  );

  const handleResetSelectedBrand = useCallback(() => {
    dispatch(selectedBrand.reset());
  }, [dispatch]);

  const handleBrandsReset = useCallback(() => {
    dispatch(getBrands.reset());
  }, [dispatch]);

  return {
    brands: brandsList,
    brandsState: { isLoading: loading, isLoaded },
    brandSelected,
    getBrands: handleGetBrands,
    loading,
    resetSelectedBrand: handleResetSelectedBrand,
    setSelectedBrand: handleSetSelectedBrand,
    brandsReset: handleBrandsReset,
  };
}

export default useBrands;
