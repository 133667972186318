import { atom } from 'recoil';
import { NavigationType } from '@experimental-components/TopNavigationBar/types';

export const currentNavigationAtom = atom({
  key: 'ATOM/CURRENT_NAVIGATION',
  default: NavigationType.STORE,
});

export const currentReloadStore = atom({
  key: 'ATOM/CURRENT_RELOAD_STORE',
  default: false,
});

export const currentChangeLocation = atom({
  key: 'ATOM/CURRENT_CHANGE_LOCATION',
  default: false,
});

export const currentChangeModeDraft = atom({
  key: 'ATOM/CURRENT_CHANGE_MODE_DRAFT',
  default: false,
});
