import { useState, useEffect } from 'react';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import TimeCustomPicker from '@design-system/Time';

import DateRangePicker from '@experimental-components/MaterialPickersDaterangeV2';
import ContentFiltersReports from 'components/ContentFiltersReports';

import { useQueryParams } from 'hooks';
import { getDateOptionsToReports } from 'utils/dateRanges';

import { FiltersProps } from '../types';
import { transformFilters } from '../utils';

interface Props {
  defaultFilters: FiltersProps;
  onChange: (values: FiltersProps) => void;
}

function Filter({ defaultFilters, onChange }: Props) {
  const { t } = useTranslation('reports');
  const { setManyQueryParams } = useQueryParams<FiltersProps>();

  const [filters, setFilters] = useState<FiltersProps>(defaultFilters);

  function handleChangeRangeDate(dates: Dayjs[]) {
    const newFilters = { ...filters, dates };
    setFilters(newFilters);
    onChange(newFilters);
  }

  function handleChangeTime(key: string, value: string) {
    const newFilters = { ...filters, [key]: value };
    setFilters(newFilters);
    onChange(newFilters);
  }

  useEffect(() => setManyQueryParams(transformFilters(filters)), [filters]);

  return (
    <>
      <Box>
        <DateRangePicker
          isFullWidth
          label={t('common:forms.date')}
          onChange={handleChangeRangeDate}
          ranges={getDateOptionsToReports(t)}
          value={filters.dates || []}
        />
      </Box>

      <ContentFiltersReports.Hours>
        <TimeCustomPicker
          id="startTimeId"
          label={t('reports:labels.startHour')}
          onChange={(value: string) => handleChangeTime('startTime', value)}
          value={filters.startTime}
        />

        <TimeCustomPicker
          id="endTimeId"
          label={t('reports:labels.endHour')}
          onChange={(value: string) => handleChangeTime('endTime', value)}
          value={filters.endTime}
        />
      </ContentFiltersReports.Hours>
    </>
  );
}

export default Filter;
