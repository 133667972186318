import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import { useGetModifiersOptionDetail } from 'containers/ReportModifiers/hooks';

import NestedTable from '@experimental-components/NestedTable';

import { renderSafeValue } from 'utils/formatField';

import PlaceholderTable from './PlaceholderTable';
import { modifiersGroupsByOptionType } from './types';
import useStyles from './styles';

interface Props {
  row: modifiersGroupsByOptionType;
}

function ModifierGroupsByOptionRow({ row }: Props) {
  const { t } = useTranslation('reports');
  const classes = useStyles();
  const { data, dataStatus, loadModifiersOptionDetail, removeModifiersOptionDetail } = useGetModifiersOptionDetail(
    row.sku,
  );
  const [openRow, setOpenRow] = useState(false);

  useEffect(() => {
    if (openRow && !data) {
      loadModifiersOptionDetail();
    }
  }, [openRow]);

  useEffect(() => {
    return () => {
      removeModifiersOptionDetail();
    };
  }, []);

  return (
    <>
      <NestedTable.Row bgColor="inherit" bold="500" divider={!openRow} onClick={() => setOpenRow(!openRow)}>
        <NestedTable.Cell
          align="left"
          className={clsx({ [classes.lineThroughStyle]: row?.textStyle === 'line-through' })}
          openChevron={openRow}
          showChevron
        >
          {renderSafeValue({ value: row?.name, t })}
        </NestedTable.Cell>
        <NestedTable.Cell>{renderSafeValue({ value: row?.sku, t })}</NestedTable.Cell>
        <NestedTable.Cell>{renderSafeValue({ value: row?.quantity, t })}</NestedTable.Cell>
        <NestedTable.Cell>
          {renderSafeValue({ value: row?.averagePrice, renderValue: `$${row?.averagePrice}`, t })}
        </NestedTable.Cell>
        <NestedTable.Cell>
          {renderSafeValue({ value: row?.totalProducts, renderValue: `$${row?.totalProducts}`, t })}
        </NestedTable.Cell>
      </NestedTable.Row>

      <Collapse in={openRow}>
        {dataStatus.fetching && !dataStatus.fetched && <PlaceholderTable.OptionDetail />}
        {dataStatus.fetched &&
          !dataStatus.fetching &&
          data?.modifierOptions?.map((option) => (
            <NestedTable.Row key={option.sku} bgColor="white">
              <NestedTable.Cell
                align="left"
                className={clsx({ [classes.lineThroughStyle]: option?.textStyle === 'line-through' })}
                tab={1}
              >
                {renderSafeValue({ value: option?.name, t })}
              </NestedTable.Cell>
              <NestedTable.Cell>{renderSafeValue({ value: option?.sku, t })}</NestedTable.Cell>
              <NestedTable.Cell>{renderSafeValue({ value: option?.quantity, t })}</NestedTable.Cell>
              <NestedTable.Cell>
                {renderSafeValue({ value: option?.averagePrice, renderValue: `$${option?.averagePrice}`, t })}
              </NestedTable.Cell>
              <NestedTable.Cell>
                {renderSafeValue({ value: option?.totalProducts, renderValue: `$${option?.totalProducts}`, t })}
              </NestedTable.Cell>
            </NestedTable.Row>
          ))}
        <Box borderBottom="1px solid #CECDD1" />
      </Collapse>
    </>
  );
}

export default ModifierGroupsByOptionRow;
