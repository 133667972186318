import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getMenusProducts, changeMenuProductPrice } from './actions';
import {
  selectProducts,
  selectMenuProductsFetching,
  selectMenuProductsFetched,
  selectMenuProductsError,
  selectChangeProductPriceFetching,
  selectChangeProductPriceFetched,
  selectChangeProductPriceError,
} from './selectors';

export function withMenuProductsHoc(Component) {
  function withMenuProducts(props) {
    return <Component {...props} />;
  }

  withMenuProducts.displayName = `withMenuProducts(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withMenuProducts;
}

const mapStateToProps = createStructuredSelector({
  menuProducts: selectProducts,
  menuProductsFetching: selectMenuProductsFetching,
  mnuProductsFetched: selectMenuProductsFetched,
  menuProductsError: selectMenuProductsError,
  changeProductPriceFetching: selectChangeProductPriceFetching,
  changeProductPriceFetched: selectChangeProductPriceFetched,
  changeProductPriceError: selectChangeProductPriceError,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadMenuProducts: ({ menuUuid, storeUuid }) => dispatch(getMenusProducts({ menuUuid, storeUuid })),
    changeMenuProductPrice: ({ menuUuid, storeUuid, productUuid, price, availability }) =>
      dispatch(changeMenuProductPrice({ menuUuid, storeUuid, productUuid, price, availability })),
    resetMenuProducts: () => dispatch(getMenusProducts.reset()),
    resetChangeMenuProductPrice: () => dispatch(changeMenuProductPrice.reset()),
  };
}

export const withMenuProductsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withMenuProductsState, withMenuProductsHoc);
