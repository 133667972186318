import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getSuscriptionHomeRequest,
  deletePaymentMethodRequest,
  savePaymentMethodRequest,
  getSubscriptionPaymentsRequest,
  getSubscriptionInvoiceDataRequest,
  updateSubscriptionInvoiceDataRequest,
} from 'utils/api/baas/subscription';

import {
  getSubscriptionHome,
  deletePaymentMethod,
  savePaymentMethod,
  getSubscriptionPayments,
  getSubscriptionPaymentsByPagination,
  getSubscriptionInvoiceData,
  updateSubscriptionInvoiceData,
} from './actions';

export function* handleGetSuscriptionHomeRequest() {
  try {
    yield put(getSubscriptionHome.request());

    const { data } = yield call(baasRequest, getSuscriptionHomeRequest);

    yield put(getSubscriptionHome.success({ data }));
  } catch (err) {
    yield put(getSubscriptionHome.failure(err));
  }
}

export function* handleDeletePaymentMethodRequest(action) {
  const paymentMethodUuid = action.payload;
  try {
    yield put(deletePaymentMethod.request());

    yield call(baasRequest, deletePaymentMethodRequest, paymentMethodUuid);

    yield put(deletePaymentMethod.success());
  } catch (err) {
    yield put(deletePaymentMethod.failure(err));
  } finally {
    yield put(deletePaymentMethod.fulfill());
  }
}

export function* handleSavePaymentMethodRequest(action) {
  const paymentMethod = action.payload;

  try {
    yield put(savePaymentMethod.request());

    const { data } = yield call(baasRequest, savePaymentMethodRequest, paymentMethod);

    yield put(savePaymentMethod.success({ data }));
  } catch (err) {
    yield put(savePaymentMethod.failure(err));
  } finally {
    yield put(savePaymentMethod.fulfill());
  }
}

export function* handleGetSubscriptionPaymentsRequest(action) {
  const filters = action.payload;
  try {
    yield put(getSubscriptionPayments.request());

    const { data: payments, pagination } = yield call(baasRequest, getSubscriptionPaymentsRequest, filters);

    yield put(getSubscriptionPayments.success({ payments, pagination }));
  } catch (err) {
    yield put(getSubscriptionPayments.failure(err));
  }
}

export function* handleGetSubscriptionPaymentsByPaginationRequest(action) {
  const { page } = action.payload;

  try {
    yield put(getSubscriptionPaymentsByPagination.request({ page }));

    const { data: payments, pagination } = yield call(baasRequest, getSubscriptionPaymentsRequest, { page });

    yield put(getSubscriptionPaymentsByPagination.success({ payments, pagination }));
  } catch (err) {
    yield put(getSubscriptionPaymentsByPagination.failure(err));
  } finally {
    yield put(getSubscriptionPaymentsByPagination.fulfill());
  }
}

export function* handleGetSuscriptionInvoiceDataRequest() {
  try {
    yield put(getSubscriptionInvoiceData.request());

    const { data } = yield call(baasRequest, getSubscriptionInvoiceDataRequest);

    yield put(getSubscriptionInvoiceData.success({ data }));
  } catch (err) {
    yield put(getSubscriptionInvoiceData.failure(err));
  }
}

export function* handleUpdateSuscriptionInvoiceDataRequest(action) {
  const invoiceData = action.payload;

  try {
    yield put(updateSubscriptionInvoiceData.request());

    const { data } = yield call(baasRequest, updateSubscriptionInvoiceDataRequest, invoiceData);

    yield put(updateSubscriptionInvoiceData.success({ data }));
  } catch (err) {
    yield put(updateSubscriptionInvoiceData.failure(err));
  } finally {
    yield put(updateSubscriptionInvoiceData.fulfill());
  }
}

export default function* suscriptionProviderSaga() {
  yield takeLatest(getSubscriptionHome.TRIGGER, handleGetSuscriptionHomeRequest);
  yield takeLatest(deletePaymentMethod.TRIGGER, handleDeletePaymentMethodRequest);
  yield takeLatest(savePaymentMethod.TRIGGER, handleSavePaymentMethodRequest);
  yield takeLatest(getSubscriptionPayments.TRIGGER, handleGetSubscriptionPaymentsRequest);
  yield takeLatest(getSubscriptionPaymentsByPagination.TRIGGER, handleGetSubscriptionPaymentsByPaginationRequest);
  yield takeLatest(getSubscriptionInvoiceData.TRIGGER, handleGetSuscriptionInvoiceDataRequest);
  yield takeLatest(updateSubscriptionInvoiceData.TRIGGER, handleUpdateSuscriptionInvoiceDataRequest);
}
