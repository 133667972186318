/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import Table from '@experimental-components/Table';
import IconMoreVertical from '@experimental-components/IconsComponents/MoreVertical';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { TableCellProps } from '@material-ui/core/TableCell';

import MenuActions from 'components/MenuActions';
import DisplayMessage from 'components/DisplayMessageV2';
import PlaceholdersTable from 'components/PlaceholdersTable';

interface ColumnProps {
  field: string;
  columnName?: string | number;
  isActionField?: boolean;
  isLinkField?: boolean;
  isNavLink?: boolean;
  cellProps?: TableCellProps;
}

interface RowProps {
  uuid: string;
  name: string;
  handler: (uuid: string) => void;
  handlerNavigation: ({ uuid, name }: { uuid: string; name: string }) => void;
  isActionField?: boolean;
  cells: { [key: string]: string | number | undefined };
  isInternal?: boolean;
}

interface Props {
  columns: ColumnProps[];
  confirmDeleteMessage?: string;
  rows: RowProps[];
  isDeleting?: boolean;
  isDeleted?: boolean;
  isLoading: boolean;
  isSortingSearch?: boolean;
  id?: string;
  onDelete: (rowValue: RowProps) => void;
  titleConfirmDelete?: string;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
}

const useStyles = makeStyles((theme) => ({
  cellWith: {
    width: 300,
  },
  linkCellWithoutHover: {
    fontWeight: 500,
  },
  linkCell: {
    cursor: 'pointer',
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  buttonLink: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    textDecoration: 'underline',
  },
}));

function catalogTable({
  columns,
  rows,
  isDeleted = false,
  isLoading,
  isSortingSearch = false,
  id = 'inventoryTable',
  onDelete,
  fetchNextPage,
  hasNextPage,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation('inventoriesSuppliers');
  const [selectedRow, setSelectedRow] = useState<RowProps | null>(null);
  const [anchorElActions, setAnchorElActions] = useState<Element | null>(null);
  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };
  }, [scrollPaginationRef, hasNextPage]);

  const handleOpenActions = (rowValue: RowProps) => (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorElActions(event.currentTarget);
    setSelectedRow(rowValue);
  };

  function handleSetAnchorElClose() {
    setAnchorElActions(null);
  }

  function handleOnConfirmDelete() {
    if (selectedRow) onDelete(selectedRow);
  }

  useEffect(() => {
    if (isDeleted) {
      setAnchorElActions(null);
      // setAnchorElConfirm(null);
      setSelectedRow(null);
    }
  }, [isDeleted]);

  if (!rows.length && isSortingSearch) {
    return (
      <DisplayMessage
        height="90%"
        id={`${id}NoData`}
        maxWidth={340}
        message={t('common:messages.noResultsFoundSearchRetry')}
        sizeIcon={120}
        sizeMessage={16}
        sizeTitle={18}
        title={t('common:messages.titleNoResultsFoundSearch')}
      />
    );
  }

  if (!rows.length && !isLoading) {
    return (
      <DisplayMessage
        id={`${id}DisplayMessageEmpty`}
        maxWidth={340}
        message={t('common:messages.emptyInformation')}
        sizeIcon={120}
      />
    );
  }

  return (
    <Box>
      <Table className="">
        <Table.Head>
          <Table.Row>
            {columns.map((column) => (
              <Table.HeadCell {...column?.cellProps} className={column.isActionField ? classes.cellWith : ''}>
                {column.columnName}
              </Table.HeadCell>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {!isLoading &&
            rows.map((row, index) => (
              <Table.Row key={row.uuid ?? index}>
                {columns.map((column) => (
                  <>
                    {!column.isActionField && column.isLinkField && (
                      <Table.Cell
                        {...column?.cellProps}
                        className={row.isInternal ? classes.linkCellWithoutHover : classes.linkCell}
                        isBorder={index !== 0}
                        onClick={() => {
                          if (row.isInternal) {
                            return;
                          }
                          return row.handler(row.uuid);
                        }}
                      >
                        {row.cells[column.field]}
                      </Table.Cell>
                    )}
                    {!column.isActionField && !column.isLinkField && column.isNavLink && (
                      <Table.Cell
                        {...column?.cellProps}
                        className={classes.buttonLink}
                        isBorder={index !== 0}
                        onClick={() => {
                          row.handlerNavigation({ uuid: row.uuid, name: row.name });
                        }}
                      >
                        {row.cells[column.field]}
                      </Table.Cell>
                    )}
                    {!column.isActionField && !column.isLinkField && !column.isNavLink && (
                      <Table.Cell {...column?.cellProps} isBorder={index !== 0}>
                        {row.cells[column.field]}
                      </Table.Cell>
                    )}
                    {column.isActionField && !row.isInternal && (
                      <Table.Cell align="center" isBorder={index !== 0}>
                        <IconButton data-testid={`actions-button-${row.name}`}>
                          <IconMoreVertical onClick={handleOpenActions(row)} />
                        </IconButton>
                      </Table.Cell>
                    )}
                  </>
                ))}
              </Table.Row>
            ))}
          {isLoading && <PlaceholdersTable placeholderCols={columns.length} />}
        </Table.Body>
      </Table>
      <div ref={scrollPaginationRef} />
      <MenuActions
        anchorEl={anchorElActions}
        onClose={handleSetAnchorElClose}
        onDelete={handleOnConfirmDelete}
        open={Boolean(anchorElActions)}
      />
    </Box>
  );
}

export default catalogTable;
