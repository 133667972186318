import { withStyles, Tab as TabMui, TabProps } from '@material-ui/core';

const TabBase = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 300,
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    paddingBottom: '14px',
  },

  textColorPrimary: {
    color: theme.palette.common.black,
    '&$selected': {
      color: theme.palette.common.black,
    },
  },

  selected: {
    fontWeight: 500,
  },
}))(TabMui);

function Tab(props: TabProps) {
  return <TabBase disableFocusRipple disableRipple {...props} />;
}

export default Tab;
