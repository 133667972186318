import { useAuth } from 'hooks/useAuth';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { getUserCurrentStore } from 'utils/authTyped';
import history from 'utils/history';

import { getActiveLicense } from './services';

const ONE_MINUTE = 60000;

export function useActiveLicense() {
  const { isAuthenticated } = useAuth();
  const storeUuid = getUserCurrentStore()?.uuid;
  const location = useLocation();

  return useQuery({
    queryKey: ['active-license'],
    queryFn: () => getActiveLicense({ storeUuid }),
    refetchInterval: ONE_MINUTE,
    refetchIntervalInBackground: true,
    enabled: isAuthenticated,
    notifyOnChangeProps: ['data'],
    retry: 1,
    onSuccess: (data) => {
      if (!data) return;
      const { status } = data;
      const shouldRedirectToSubscriptionPage =
        (status === 'UNAVAILABLE' || status === 'PAYMENT_REQUIRED') &&
        location.pathname !== '/subscription' &&
        location.pathname !== '/subscription/payment-history' &&
        location.pathname !== '/subscription/invoice-data';

      if (shouldRedirectToSubscriptionPage) {
        history.push({
          pathname: '/subscription',
        });
      }
    },
  });
}
