import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getEmployeesRequest, createEmployeeRequest, deleteEmployeeRequest } from 'utils/api/baas/stores/employees';

import { getEmployees, createEmployee, deleteEmployee } from './actions';

export function* handleGetEmployeesRequest(action) {
  const { storeUuid } = action.payload;

  try {
    yield put(getEmployees.request());

    const { data, pagination } = yield call(baasRequest, getEmployeesRequest, {
      storeUuid,
    });

    yield put(getEmployees.success({ data, pagination }));
  } catch (err) {
    yield put(getEmployees.failure(err));
  } finally {
    yield put(getEmployees.fulfill());
  }
}

export function* handleCreateEmployeeRequest(action) {
  const {
    storeUuid,
    password,
    email,
    firstName,
    lastName,
    phoneNumber,
    posPin,
    permissions,
    jobUuids,
  } = action.payload;

  try {
    yield put(createEmployee.request());

    const { data } = yield call(baasRequest, createEmployeeRequest, {
      storeUuid,
      password,
      email,
      firstName,
      lastName,
      phoneNumber,
      posPin,
      permissions,
      jobUuids,
    });

    yield put(createEmployee.success(data));
  } catch (err) {
    yield put(createEmployee.failure(err));
  } finally {
    yield put(createEmployee.fulfill());
  }
}

export function* handleDeleteEmployeeRequest(action) {
  const { storeUuid, employeeUuid } = action.payload;

  try {
    yield put(deleteEmployee.request());

    yield call(baasRequest, deleteEmployeeRequest, {
      storeUuid,
      employeeUuid,
    });

    yield put(deleteEmployee.success(employeeUuid));
  } catch (err) {
    yield put(deleteEmployee.failure(err));
  } finally {
    yield put(deleteEmployee.fulfill());
  }
}

export default function* employeesProviderSaga() {
  yield takeLatest(getEmployees.TRIGGER, handleGetEmployeesRequest);
  yield takeLatest(createEmployee.TRIGGER, handleCreateEmployeeRequest);
  yield takeLatest(deleteEmployee.TRIGGER, handleDeleteEmployeeRequest);
}
