import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function usersValidateEmailRequest(email, options = {}) {
  return request(`${API_BASE_URL}/v1/users/validate-email/`, {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
    ...options,
  });
}
