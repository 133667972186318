import { Switch } from 'react-router-dom';

import InventoriesManagement from 'containers/InventoriesManagement/Loadable';
import InventoriesSuppliers from 'containers/InventoriesSuppliers/Loadable';
import InventoriesStockCategories from 'containers/InventoriesStockCategories/Loadable';
import InventoriesStorageCenters from 'containers/InventoriesStorageCenters/Loadable';
import InventoriesStorageAreas from 'containers/InventoriesStorageAreas/Loadable';
import InventoriesSupplies from 'containers/InventoriesSupplies/Loadable';
import InventoriesTransfers from 'containers/InventoriesTransfers/Loadable';
import InventoriesTransfersCreate from 'containers/InventoriesTransfers/LoadableCreate';
import InventoriesPurchase from 'containers/InventoriesPurchase/Loadable';
import InventoriesPurchaseCreate from 'containers/InventoriesPurchase/LoadableCreate';
import InventoriesDecrease from 'containers/InventoriesDecrease/Loadable';
import InventoriesDecreaseCreate from 'containers/InventoriesDecrease/LoadableCreate';
import InventoriesCounting from 'containers/InventoriesCounting/Loadable';
import InventoriesRecipes from 'containers/InventoriesRecipes/Loadable';
import InventoriesProductionEvent from 'containers/InventoriesProductionEvent/Loadable';
import InventoriesProductionEventCreate from 'containers/InventoriesProductionEvent/LoadableCreate';
import InventoriesSuppliesTracking from 'containers/InventoriesSuppliesTracking/Loadable';
import InventoriesPurchaseXmlView from 'containers/InventoriesPurchaseXmlView/Loadable';
import InventoriesAdjustment from 'containers/InventoriesAdjustment/Loadable';

import useEnvironment from 'hooks/useEnvironment';

import useUserStore from 'hooks/useUserStore';

import AppRoute from '../PrivateRoute';

export function InventoryRoutes() {
  const { environmentsState } = useEnvironment();
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();
  const flagEnableInventory =
    environmentsState.REACT_APP_FEATURE_INVENTORY_ENABLED === 'true' && !storeSelected?.isDraft;

  return (
    <Switch>
      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesManagement}
        exact
        path={[
          '/inventories',
          '/inventories/suppliers',
          '/inventories/others',
          '/inventories/stock-categories',
          '/inventories/supplies',
          '/inventories/stock',
          '/inventories/recipes',
          '/inventories/recipes/:actionType(create|edit|detail)?/:inventoryUuid?',
          '/inventories/recipes/sub-recipe/:actionType(create|edit|detail)?/:inventoryUuid?',
          '/inventories/recipes/prod-recipe/:actionType(create|edit|detail)?/:inventoryUuid?',
        ]}
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesSuppliers}
        exact
        path="/inventories/suppliers/:actionType(create|edit)?/:inventoryUuid?"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesStockCategories}
        exact
        path="/inventories/stock-categories/:actionType(create|edit)?/:stockCategoryUuidParam?"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesStorageCenters}
        exact
        path="/inventories/storage-centers/:actionType(create|edit)?/:storageCenterUuidParam?"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesStorageAreas}
        exact
        path="/inventories/storage-areas/:actionType(create|edit)?/:storageAreaUuidParam?"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesSupplies}
        exact
        path="/inventories/supplies/:actionType(create|edit|duplicate)?/:supplyUuidParam?"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesTransfers}
        exact
        path="/inventories/transfers"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesTransfersCreate}
        exact
        path="/inventories/transfers/create"
      />

      <AppRoute accessRoute={flagEnableInventory} component={InventoriesDecrease} exact path="/inventories/decrease" />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesDecreaseCreate}
        exact
        path="/inventories/decrease/create"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesCounting}
        exact
        path="/inventories/counting/:actionType(create|upload)?/"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesProductionEvent}
        exact
        path="/inventories/production-event"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesProductionEventCreate}
        exact
        path="/inventories/production-event/create"
      />

      <AppRoute accessRoute={flagEnableInventory} component={InventoriesPurchase} exact path="/inventories/purchase" />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesPurchaseCreate}
        exact
        path="/inventories/purchase/create"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesSuppliesTracking}
        exact
        path="/inventories/supplies-tracking/:actionType(detail)?/:supplyUuid?"
      />
      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesRecipes}
        exact
        path="/inventories/recipes-config/:actionType(config)"
      />
      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesRecipes}
        exact
        path="/inventories/recipes-config-v2/:actionType(products|modifiers)"
      />
      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesPurchaseXmlView}
        exact
        path="/inventories/purchase-xml/"
      />

      <AppRoute
        accessRoute={flagEnableInventory}
        component={InventoriesAdjustment}
        exact
        path="/inventories/adjustment"
      />
    </Switch>
  );
}
