import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getReportsDiscountsResume,
  getReportsDiscountsResumeByPagination,
  getReportsDiscountsResumeBySorting,
  getReportsDiscountsDetails,
  getReportsDiscountsDetailsByPagination,
  getReportsDiscountsDetailsBySorting,
  exportReportsDiscounts,
} from './actions';

import {
  selectReportsDiscountsResume,
  selectReportsDiscountsResumeState,
  selectReportsDiscountsResumePagination,
  selectReportsDiscountsResumePaginationState,
  selectReportsDiscountsResumeSortingState,
  selectReportsDiscountsDetails,
  selectReportsDiscountsDetailsState,
  selectReportsDiscountsDetailsPagination,
  selectReportsDiscountsDetailsPaginationState,
  selectReportsDiscountsDetailsSortingState,
  selectExportReportsDiscountsState,
  selectExportReportsDiscountsDocument,
} from './selectors';

export function withReportsDiscountsHoc(Component) {
  function withReportsDiscounts(props) {
    return <Component {...props} />;
  }

  withReportsDiscounts.displayName = `withReportsDiscounts(${getDisplayName(Component)})`;
  return withReportsDiscounts;
}

const mapStateToProps = createStructuredSelector({
  discountsResume: selectReportsDiscountsResume,
  discountsResumeState: selectReportsDiscountsResumeState,
  discountsResumePaginationValues: selectReportsDiscountsResumePagination,
  discountsResumePaginationState: selectReportsDiscountsResumePaginationState,
  discountsResumeSortingState: selectReportsDiscountsResumeSortingState,
  discountsDetails: selectReportsDiscountsDetails,
  discountsDetailsState: selectReportsDiscountsDetailsState,
  discountsDetailsPaginationValues: selectReportsDiscountsDetailsPagination,
  discountsDetailsPaginationState: selectReportsDiscountsDetailsPaginationState,
  discountsDetailsSortingState: selectReportsDiscountsDetailsSortingState,
  discountsExportState: selectExportReportsDiscountsState,
  discountsExportDocument: selectExportReportsDiscountsDocument,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadReportsDiscountsResume: (filters = {}) => dispatch(getReportsDiscountsResume(filters)),
    loadReportsDiscountsResumePagination: (filters = {}) => dispatch(getReportsDiscountsResumeByPagination(filters)),
    loadReportsDiscountsResumeSorting: (filters = {}) => dispatch(getReportsDiscountsResumeBySorting(filters)),
    loadReportsDiscountsDetails: (filters = {}) => dispatch(getReportsDiscountsDetails(filters)),
    loadReportsDiscountsDetailsPagination: (filters = {}) => dispatch(getReportsDiscountsDetailsByPagination(filters)),
    loadReportsDiscountsDetailsSorting: (filters = {}) => dispatch(getReportsDiscountsDetailsBySorting(filters)),
    exportReportsDiscounts: (filters = {}) => dispatch(exportReportsDiscounts(filters)),
    resetReportsDiscountsResume: () => dispatch(getReportsDiscountsResume.reset()),
    resetReportsDiscountsResumePagination: () => dispatch(getReportsDiscountsResumeByPagination.reset()),
    resetReportsDiscountsResumeSorting: () => dispatch(getReportsDiscountsResumeBySorting.reset()),
    resetReportsDiscountsDetails: () => dispatch(getReportsDiscountsDetails.reset()),
    resetReportsDiscountsDetailsPagination: () => dispatch(getReportsDiscountsDetailsByPagination.reset()),
    resetReportsDiscountsDetailsSorting: () => dispatch(getReportsDiscountsDetailsBySorting.reset()),
    resetExportReportsDiscounts: () => dispatch(exportReportsDiscounts.reset()),
  };
}

export const withReportsDiscountsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withReportsDiscountsState, withReportsDiscountsHoc);
