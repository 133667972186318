import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getRoles, duplicateRole, deleteRole, getPermissionsByRoles } from './actions';
import {
  // Get Roles
  selectRoles,
  selectRolesError,
  selectRolesIsFetching,
  selectRolesDidFetch,
  // Duplicate Role
  selectDuplicateRole,
  selectDuplicateRoleError,
  selectDuplicateRoleIsFetching,
  selectDuplicateRoleDidFetch,
  // Delete Role
  selectDeleteRoleError,
  selectDeleteRoleIsFetching,
  selectDeleteRoleDidFetch,
  selectPermissionsByRoles,
  selectPermissionsByRolesState,
} from './selectors';

export function withRolesHoc(Component) {
  function withRoles(props) {
    return <Component {...props} />;
  }

  withRoles.displayName = `withRoles(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withRoles;
}

const mapStateToProps = createStructuredSelector({
  // Get Roles
  rolesList: selectRoles,
  rolesIsFetching: selectRolesIsFetching,
  rolesError: selectRolesError,
  rolesDidFetch: selectRolesDidFetch,
  // Duplicate role
  duplicatedRole: selectDuplicateRole,
  duplicateRoleError: selectDuplicateRoleError,
  duplicateRoleIsFetching: selectDuplicateRoleIsFetching,
  duplicateRoleDidFetch: selectDuplicateRoleDidFetch,
  // Deleted role
  deleteRoleError: selectDeleteRoleError,
  deleteRoleIsFetching: selectDeleteRoleIsFetching,
  deleteRoleDidFetch: selectDeleteRoleDidFetch,
  permissionByRoles: selectPermissionsByRoles,
  permissionByRolesState: selectPermissionsByRolesState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadRoles: (storeUuid) => dispatch(getRoles(storeUuid)),
    resetLoadRoles: () => dispatch(getRoles.reset()),
    duplicateRole: (jobUuid) => dispatch(duplicateRole(jobUuid)),
    resetDuplicateRole: () => dispatch(duplicateRole.reset()),
    deleteRole: (jobUuid) => dispatch(deleteRole(jobUuid)),
    resetDeleteRole: () => dispatch(deleteRole.reset()),
    loadPermissionsByRoles: (jobsUuid) => dispatch(getPermissionsByRoles(jobsUuid)),
    resetPermissionsByRoles: () => dispatch(getPermissionsByRoles.reset()),
  };
}

export const withRolesState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRolesState, withRolesHoc);
