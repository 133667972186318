import { Children, memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

export function BrandsProvider({ children }) {
  useInjectSaga({ key: 'brandsProviderV2', saga });
  useInjectReducer({ key: 'brandsProviderV2', reducer });

  return Children.only(children);
}

const withConnect = connect();

BrandsProvider.propTypes = {
  children: PropTypes.element,
};

export { default as useBrandsV2 } from './useBrands';
export { default as withBrands } from './withBrands';
export default compose(withConnect, memo)(BrandsProvider);
