import { Switch } from 'react-router-dom';

import { useFeature } from 'FeatureProvider';

import Invoicing from 'containers/Invoicing/Loadable';
import InvoicingConfig from 'containers/InvoicingConfig/Loadable';
import InvoicingPortalConfig from 'containers/InvoicingPortalConfig/Loadable';
import InvoicesGenerated from 'containers/InvoicesGenerated/Loadable';
import InvoicingGlobal from 'containers/InvoicingGlobal/Loadable';
import InvoicingTicketsEditor from 'containers/InvoicingTicketsEditor/Loadable';
import InvoicingGeneral from 'containers/InvoicingGeneral/Loadable';

import useUserStore from 'hooks/useUserStore';

import AppRoute from '../PrivateRoute';

export function InvoicingRoutes() {
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();
  const superUserAccess = useFeature('adminStore');
  const invoicingModuleAccess = useFeature('backofficeModuleInvoicing');
  const accessTicketsEditorPermission = useFeature('invoicingTicketEditor');
  const permissionInvoicingModule = (superUserAccess || invoicingModuleAccess) && !storeSelected?.isDraft;

  return (
    <Switch>
      <AppRoute accessRoute={permissionInvoicingModule} component={Invoicing} exact path="/invoicing" />
      <AppRoute
        accessRoute={permissionInvoicingModule}
        component={InvoicesGenerated}
        exact
        path="/invoicing/generated/:type(global|customer)"
      />
      <AppRoute
        accessRoute={permissionInvoicingModule}
        component={InvoicingPortalConfig}
        exact
        path="/invoicing/portal-config/:actionType(create|edit)?/:uuid?"
      />
      <AppRoute
        accessRoute={permissionInvoicingModule}
        component={InvoicingConfig}
        exact
        path="/invoicing/config/:actionType(create|edit)?/:uuid?"
      />
      <AppRoute accessRoute={permissionInvoicingModule} component={InvoicingGlobal} exact path="/invoicing/global" />
      <AppRoute
        accessRoute={accessTicketsEditorPermission}
        component={InvoicingTicketsEditor}
        exact
        path="/invoicing/tickets-editor"
      />
      <AppRoute accessRoute={permissionInvoicingModule} component={InvoicingGeneral} exact path="/invoicing/general" />
    </Switch>
  );
}
