import useFeatures from './useFeatures';

export default function useFeature(name) {
  const { permissions } = useFeatures();

  if (Array.isArray(permissions)) return permissions.includes(name);

  if (typeof permissions[name] === 'boolean') return permissions[name];

  return name.split('/').reduce((featureGroup, featureName) => {
    if (typeof featureGroup === 'boolean') return featureGroup;

    if (featureGroup[featureName] === undefined) return false;

    return featureGroup[featureName];
  }, permissions);
}
