import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import camelCase from 'lodash/camelCase';

import { useFeatures } from 'FeatureProvider';
import { withMenus } from 'providers/menuMaker/Menus';
import { useBrands } from 'providers/root/BrandsProvider';
import { withRequiredLicense } from 'providers/root/AuthProvider';
import { useGetMenusCatalog, useGetPublishKeepItemsAvailability } from 'providers/menuMaker/Menus/hooks';
import useUserStore from 'hooks/useUserStore';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';
import { useActiveLicense } from 'containers/Subscription/hooks';
import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import Layout, { ContentLayout } from 'Layout';
import MenuMakerTabsNavigation from 'components/MenuMakerTabsNavigation';
import MenusForBrand from 'components/MenusForBrand';
import PageError from 'components/PageError';
import DialogPublishMenu, { ModalPublishWithErrors } from 'components/DialogPublishMenu';
import DialogMessage from 'components/DialogMessage';
import DetailsPublishMenusErrors from 'components/DetailsPublishMenusErrors';

import { statusFullMessage } from 'utils/typeStatus';
import { providersTypes } from 'utils/menuMaker';
import { getValidateItemsAvailabilityFlags } from 'utils/featureFlags/menus';

import MenuMakerPublishPlaceholder from './MenuMakerPublishPlaceholder';

export function PublishMenuMaker({
  publishMenusFetching,
  publishMenusFetched,
  publishMenusError,
  publishMenus,
  resetPublishMenus,
  t,
}) {
  const { getBrands, brandsState } = useBrands();
  const { storeId } = useUserStore();
  const [openDialog, setOpenDialog] = useState(false);
  const [brandSelected, setBrandSelected] = useState({});
  const [providerSelected, setProviderSelected] = useState({});
  const [openDialogFull, setOpenDialogFull] = useState(false);
  const [typeMessage, setTypeMessage] = useState(null);
  const [titleFullMessage, setTitleFullMessage] = useState(null);
  const [descriptionFullMessage, setDescriptionFullMessage] = useState(null);
  const [resetPublishProvider, setResetPublishProvider] = useState(false);
  const [brandPublishMenus, setBrandPublishMenus] = useState(null);
  const [openDialogPublishError, setOpenDialogPublishError] = useState(false);
  const { isPublishMenuError } = useSidebarLayout();

  const { logEvent } = useAmplitude();
  const { flags } = useFeatures();
  const { enableValidateItemsAvailability } = getValidateItemsAvailabilityFlags(flags);

  const {
    getAllMenus,
    allMenusForBrand,
    getAllMenusFetching,
    getAllMenusFetched,
    getAllMenusError,
    resetGetAllMenus,
  } = useGetMenusCatalog({ storeUuid: storeId });
  const {
    loadKeepItemsAvailability,
    removeKeepItemsAvalability,
    dataStatus,
    keepItemsAvailability,
    paginateKeepItemsState,
  } = useGetPublishKeepItemsAvailability(providerSelected, brandSelected?.uuid);
  const { refetch: loadLicenseState } = useActiveLicense();

  useEffect(() => {
    logEvent('@event.$menuMaker.viewMenuMaker', '@@section.publication');

    setTimeout(() => {
      getBrands();
      getAllMenus();
    }, 0);

    return function cleanUp() {
      resetPublishMenus();
      resetGetAllMenus();
    };
  }, []);

  useEffect(() => {
    if (
      isPublishMenuError &&
      getAllMenusFetched &&
      brandsState?.isLoaded &&
      !getAllMenusFetching &&
      !brandsState?.isLoading
    ) {
      getBrands();
      getAllMenus();
    }
  }, [isPublishMenuError]);

  useEffect(() => {
    if (publishMenusFetching) {
      setBrandPublishMenus(null);
      setOpenDialog(false);
      setOpenDialogPublishError(false);
      setOpenDialogFull(true);
      setTypeMessage(statusFullMessage.LOADING);
      setTitleFullMessage(t('menuMaker:publishMenu.fullMessage.loading.title'));
      setDescriptionFullMessage(t('menuMaker:publishMenu.fullMessage.loading.description'));
    }
  }, [publishMenusFetching, t]);

  useEffect(() => {
    if (publishMenusFetched) {
      logEvent(
        '@event.$menuMaker.menuMakerPublishing',
        [`@@type.${camelCase(providerSelected?.value)}`, '@@status.success'],
        {
          brand: brandSelected?.uuid,
          message: '',
        },
      );

      setTypeMessage(statusFullMessage.SUCCESS);
      if (providerSelected?.value === providersTypes.PARROT || providerSelected?.value === providersTypes.ONLINE) {
        setTitleFullMessage(t('menuMaker:publishMenu.fullMessage.success.pos.title'));
        setDescriptionFullMessage(t('menuMaker:publishMenu.fullMessage.success.pos.description'));
      } else {
        setTitleFullMessage(t('menuMaker:publishMenu.fullMessage.success.title'));
        setDescriptionFullMessage(t('menuMaker:publishMenu.fullMessage.success.description'));
      }
      removeKeepItemsAvalability();
      setBrandPublishMenus(brandSelected?.uuid);

      loadLicenseState();
      getBrands();
      getAllMenus();
    }
  }, [publishMenusFetched]);

  useEffect(() => {
    if (publishMenusError) {
      logEvent(
        '@event.$menuMaker.menuMakerPublishing',
        [`@@type.${camelCase(providerSelected?.value)}`, '@@status.error'],
        {
          brand: brandSelected?.uuid,
          message: publishMenusError?.messages?.map((error) => error?.message).join(', '),
        },
      );
      removeKeepItemsAvalability();
      setTypeMessage(statusFullMessage.ERROR);
      setTitleFullMessage(t('menuMaker:publishMenu.fullMessage.error.title'));
      setDescriptionFullMessage(t('menuMaker:publishMenu.fullMessage.error.description'));
    }
  }, [publishMenusError, t]);

  useEffect(() => {
    if (openDialog && !dataStatus.fetching && dataStatus.fetched && !keepItemsAvailability?.pages[0].data?.length) {
      setOpenDialogFull(true);
      handlePublishMenus();
    }
  }, [dataStatus.fetching, dataStatus.fetched, keepItemsAvailability]);

  function handleLoadMenus() {
    resetGetAllMenus();
    getAllMenus();
  }

  function handleOpenDialog(brand, provider) {
    if (brand && provider) {
      setBrandSelected(brand);
      setProviderSelected(provider);
      setOpenDialog(true);
    }
  }

  function handleCloseDialog() {
    setOpenDialog(false);
    setBrandSelected({});
    setProviderSelected({});
    setResetPublishProvider(true);
    removeKeepItemsAvalability();
  }

  function handleOnValidateKeepItemsAvailability() {
    if (!enableValidateItemsAvailability) {
      return handlePublishMenus();
    }

    if (enableValidateItemsAvailability && dataStatus.fetched && keepItemsAvailability?.pages[0].data?.length) {
      return handlePublishMenus();
    }

    return loadKeepItemsAvailability();
  }

  function handlePublishMenus() {
    setOpenDialogFull(true);
    publishMenus({
      storeUuid: storeId,
      brandUuid: brandSelected?.uuid,
      provider: providerSelected?.value,
    });
  }

  function handleCloseFullMessage() {
    resetPublishMenus();
    setOpenDialogFull(false);
    setBrandSelected({});
    setProviderSelected({});
    setResetPublishProvider(true);
    setTypeMessage(null);
    setTitleFullMessage(null);
    setDescriptionFullMessage(null);
    removeKeepItemsAvalability();
  }

  function handleOnOpenDetailsPublishError(brand, provider) {
    if (brand && provider) {
      setProviderSelected({ ...provider, value: provider?.providerName });
      setBrandSelected(brand);
      setOpenDialogPublishError(true);
    }
  }

  function handleOnCloseDetailsPublishError() {
    setOpenDialogPublishError(false);
    setBrandSelected({});
    setProviderSelected({});
  }

  const handleReloadStatusPublish = () => {
    loadLicenseState();
    getBrands();
    getAllMenus();
  };

  return (
    <Layout>
      <ContentLayout>
        <ContentLayout.Header sticky>
          <MenuMakerTabsNavigation />
        </ContentLayout.Header>

        <ContentLayout.Container>
          {getAllMenusError && (
            <PageError
              data-testid="publishMenusLoadError"
              id="publishMenusLoadError"
              labelAction={t('common:buttons.retry')}
              message={t('menuMaker:errors.loadedError')}
              onAction={handleLoadMenus}
            />
          )}

          {(getAllMenusFetching || brandsState?.isLoading) && <MenuMakerPublishPlaceholder width="100%" />}

          {!getAllMenusFetching && !getAllMenusError && (
            <MenusForBrand
              brandPublished={brandPublishMenus}
              brands={allMenusForBrand}
              confirmPublishMenu={handleOpenDialog}
              isLoaded={getAllMenusFetched}
              isLoading={getAllMenusFetching}
              onOpenErrorsDetails={handleOnOpenDetailsPublishError}
              onResetOptions={setResetPublishProvider}
              resetOptions={resetPublishProvider}
            />
          )}

          {openDialog && (
            <DialogPublishMenu
              brand={brandSelected}
              isLoadingValidation={dataStatus.fetching}
              keepItemsAvailabilityPages={keepItemsAvailability?.pages}
              onClose={handleCloseDialog}
              onPublishMenus={handleOnValidateKeepItemsAvailability}
              open={openDialog}
              paginateKeepItemsState={paginateKeepItemsState}
              provider={providerSelected}
            />
          )}

          {openDialogPublishError && (
            <ModalPublishWithErrors
              brandUuid={brandSelected?.uuid}
              onClose={handleOnCloseDetailsPublishError}
              onPublish={handlePublishMenus}
              onReloadMenus={handleReloadStatusPublish}
              open={openDialogPublishError}
              provider={providerSelected}
            />
          )}

          <DialogMessage
            onClose={handleCloseFullMessage}
            open={openDialogFull}
            textDescription={descriptionFullMessage}
            textTitle={titleFullMessage}
            type={typeMessage}
          >
            {publishMenusError && (
              <DetailsPublishMenusErrors data={publishMenusError} provider={providerSelected.value} />
            )}
          </DialogMessage>
        </ContentLayout.Container>
      </ContentLayout>
    </Layout>
  );
}

PublishMenuMaker.propTypes = {
  publishMenusFetching: PropTypes.bool,
  publishMenusFetched: PropTypes.bool,
  publishMenusError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  publishMenus: PropTypes.func,
  resetPublishMenus: PropTypes.func,
  t: PropTypes.func,
};

export default compose(memo, withRequiredLicense(), withMenus, withTranslation('menuMaker'))(PublishMenuMaker);
