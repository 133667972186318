import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getEmployees, createEmployee, deleteEmployee } from './actions';
import {
  selectEmployees,
  selectEmployeesFetching,
  selectEmployeesFetched,
  selectEmployeesError,
  selectCreateEmployeeFetching,
  selectCreateEmployeeFetched,
  selectCreateEmployeeError,
  selectDeleteEmployeeFetching,
  selectDeleteEmployeeFetched,
  selectDeleteEmployeeError,
} from './selectors';

export function withEmployeesHoc(Component) {
  function withEmployees(props) {
    return <Component {...props} />;
  }

  withEmployees.displayName = `withEmployees(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withEmployees;
}

const mapStateToProps = createStructuredSelector({
  employeesList: selectEmployees,
  employeesFetching: selectEmployeesFetching,
  employeesFetched: selectEmployeesFetched,
  employeesError: selectEmployeesError,
  createEmployeeFetching: selectCreateEmployeeFetching,
  createEmployeeFetched: selectCreateEmployeeFetched,
  createEmployeeError: selectCreateEmployeeError,
  deleteEmployeeFetching: selectDeleteEmployeeFetching,
  deleteEmployeeFetched: selectDeleteEmployeeFetched,
  deleteEmployeeError: selectDeleteEmployeeError,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadEmployees: ({ storeUuid }) => dispatch(getEmployees({ storeUuid })),
    createEmployee: ({ storeUuid, password, email, firstName, lastName, phoneNumber, posPin, permissions, jobUuids }) =>
      dispatch(
        createEmployee({ storeUuid, password, email, firstName, lastName, phoneNumber, posPin, permissions, jobUuids }),
      ),
    deleteEmployee: ({ storeUuid, employeeUuid }) => dispatch(deleteEmployee({ storeUuid, employeeUuid })),
    resetEmployees: () => dispatch(getEmployees.reset()),
    resetCreateEmployee: () => dispatch(createEmployee.reset()),
    resetDeleteEmployee: () => dispatch(deleteEmployee.reset()),
  };
}

export const withEmployeesState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withEmployeesState, withEmployeesHoc);
