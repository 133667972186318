import queryString from 'query-string';

import request, { requestParseErrors } from 'utils/request';

import { getUserCurrentStore } from 'utils/auth';
import { createHeaders } from 'utils/baasRequest';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getMenuMakerMenusRequest(
  { storeUuid: storeUuidArg, brand, name, page = 1, pageSize = 20, version = 1 },
  options = {},
) {
  const storeUuid = storeUuidArg ?? getUserCurrentStore()?.uuid;

  if (version === 1) {
    const query = queryString.stringify({ store_uuid: storeUuid });
    return request(`${API_BASE_URL}/v1/menu-maker/menus/?${query}`, { method: 'GET', ...options });
  }

  if (version === 2) {
    const query = queryString.stringify({ store_uuid: storeUuid, brand, name, page, page_size: pageSize });
    return request(`${API_BASE_URL}/v1/menu-maker/menus/search/?${query}`, { method: 'GET', ...options });
  }
}

export function createMenuRequest(
  { availability, name, sortingPosition, store, operationTimes, providers, brand, categories, sku },
  options = {},
) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/`, {
    method: 'POST',
    body: JSON.stringify({
      availability,
      name,
      sortingPosition,
      store,
      operationTimes,
      providers,
      brand,
      categories,
      sku,
    }),
    ...options,
  });
}

export function editMenuRequest(
  {
    menuUuid,
    availability,
    name,
    hasProductOverrides,
    sortingPosition,
    store,
    operationTimes,
    providers,
    categories,
    sku,
  },
  options = {},
) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/`, {
    method: 'PUT',
    body: JSON.stringify({
      availability,
      name,
      hasProductOverrides,
      sortingPosition,
      store,
      operationTimes,
      providers,
      categories,
      sku,
    }),
    ...options,
  });
}

export function duplicateMenuRequest({ menuUuid, storeUuid }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/duplicate/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      uuid: menuUuid,
      objectType: 'MENU',
    }),
    ...options,
  });
}

export function publishMenuRequest({ menuUuid, storeUuid }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/publish/?${query}`, {
    method: 'PUT',
    ...options,
  });
}

export function deleteMenuRequest(menuUuid, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}`, {
    method: 'DELETE',
    ...options,
  });
}

export function getMenuDetailsRequest({ menuUuid, storeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function getCopyMenuConfigRequest({ menuUuid, storeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/copy/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function menuCopyRequest({ storeOriginUuid, menuOriginUuid, brandsDestination }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/admin/menus/copy/?store_uuid=${storeOriginUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      brands: brandsDestination,
      menu: menuOriginUuid,
    }),
    headers: createHeaders(),
    ...options,
  });
}

export function menuSyncRequest({ storeOriginUuid, menuOriginUuid, menusDestination }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/admin/menus/sync/?store_uuid=${storeOriginUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      menus: menusDestination,
      menu: menuOriginUuid,
    }),
    headers: createHeaders(),
    ...options,
  });
}

export function getProductsForStoreRequest(
  { filterByText, filterByCategories, typeOfSale, filterByBrands, withoutBrands, withoutCategories, orderBy, page = 1 },
  options = {},
) {
  const query = queryString.stringify({
    store_uuid: getUserCurrentStore()?.uuid,
    page_size: 20,
    page,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/products/search/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      name: filterByText,
      category: filterByCategories,
      type_of_sale: typeOfSale,
      brand: filterByBrands,
      without_brands: withoutBrands,
      without_categories: withoutCategories,
      order_by: orderBy,
    }),
    ...options,
  });
}

export function getCategoriesForStoreRequest(options = {}) {
  const query = queryString.stringify({
    store_uuid: getUserCurrentStore()?.uuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/categories/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function productsAvailabilityForStoreRequest({ productUuid, availability }, options = {}) {
  const query = queryString.stringify({
    store_uuid: getUserCurrentStore()?.uuid,
  });
  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/availability/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      availability,
    }),
    ...options,
  });
}

export function duplicateProductRequest({ storeUuid, productUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/duplicate/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      uuid: productUuid,
      objectType: 'PRODUCT',
    }),
    ...options,
  });
}

export function deleteProductRequest(productUuid, options = {}) {
  const query = queryString.stringify({
    store_uuid: getUserCurrentStore()?.uuid,
  });
  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function getProductRequest({ storeUuid, productUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function updateProductRequest({ storeUuid, originMenuUuid, product }, options = {}) {
  const productUuid = product.uuid;
  const formData = createFormDataForProductSave(product);

  const headers = { ...options.headers };
  delete headers['Content-Type'];

  const query = queryString.stringify({ store_uuid: storeUuid, origin_menu_id: originMenuUuid });

  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/?${query}`, {
    method: 'PUT',
    body: formData,
    ...options,
    headers,
  });
}

export function createProductRequest({ storeUuid, originMenuUuid, product }, options = {}) {
  const formData = createFormDataForProductSave(product, storeUuid);
  const headers = { ...options.headers };
  delete headers['Content-Type'];

  const query = queryString.stringify({ origin_menu_id: originMenuUuid });

  return request(`${API_BASE_URL}/v1/menu-maker/products/?${query}`, {
    method: 'POST',
    body: formData,
    ...options,
    headers,
  });
}

export function createFormDataForProductSave(product, storeUuid) {
  const formData = new FormData();
  Object.keys(product).forEach((key) => {
    let value = product[key];
    if (value === null) {
      return;
    }

    if (typeof value === 'object' && key !== 'image') {
      value = JSON.stringify(value);
    }

    formData.append(key, value);
  });

  if (storeUuid) {
    formData.append('store', storeUuid);
  }

  return formData;
}

export function updateProductImageRequest({ storeUuid, productUuid, image }, options = {}) {
  const headers = { ...options.headers };
  delete headers['Content-Type'];

  const formData = new FormData();
  formData.append('image', image);

  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/update-image/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: formData,
    ...options,
    headers,
  });
}

export function publishMenusRequest({ storeUuid, brandUuid, provider }, options = {}) {
  const bodyRequest = {
    store: storeUuid,
    brand: brandUuid,
    provider,
  };

  return requestParseErrors(`${API_BASE_URL}/v1/menu-maker/menus/publish/`, {
    method: 'POST',
    body: JSON.stringify(bodyRequest),
    ...options,
  });
}

export function getMenusDetailsResumen({ menuUuid, categoryUuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    category: categoryUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/detail/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function sortingPositionMenusRequest({ menuUuid, newPosition }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/sorting-position/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: JSON.stringify({ newPosition }),
    headers: createHeaders(),
    ...options,
  });
}

export function getOrganizationStoreBrandsRequest({ includeMenus, includeBrands }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    include_menus: includeMenus,
    include_brands: includeBrands,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/admin/stores/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getValidationCopyMenuRequest({ menuUuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/admin/menus/validate-copy/?${query}`, {
    method: 'POST',
    body: JSON.stringify({ menu: menuUuid }),
    ...options,
  });
}

export function getPublishKeepItemsAvailabilityRequest(
  { provider, storeProviderLogUuid, brandUuid, page = 1 },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    provider,
    page,
    page_size: 10,
    store_provider_log_uuid: storeProviderLogUuid,
    brand_uuid: brandUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/products/availability/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function changeStatusStoreProviderLog({ provider, storeProviderLogUuid, status }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    provider,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/store-provider-log/${storeProviderLogUuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({ status }),
    headers: createHeaders(),
    ...options,
  });
}
