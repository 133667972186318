import { Value } from 'firebase/remote-config';

export const getFlagsFormatted = (remoteFlags: Record<string, Value>) => {
  const newFlags = {};

  Object.entries(remoteFlags).forEach(([key, value]) => {
    let typeValue;
    let originValue;

    try {
      typeValue = typeof JSON.parse(value.asString());
    } catch (e) {
      typeValue = 'string';
    }

    switch (typeValue) {
      case 'number':
        originValue = value.asNumber();
        break;

      case 'boolean':
        originValue = value.asBoolean();
        break;

      case 'object':
        originValue = JSON.parse(value.asString());
        break;

      default:
        originValue = value.asString();
        break;
    }

    newFlags[key] = originValue;
  });

  return newFlags;
};
