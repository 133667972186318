import { takeLatest, put, call } from 'redux-saga/effects';

import { logEventRequest } from 'providers/root/AmplitudeProvider/helpers';

import baasRequest from 'utils/baasRequest';

import { getReportsDiscountsRequest, exportReportsDiscountsExcelRequest } from 'utils/api/baas/reports/discounts';

import {
  getReportsDiscountsResume,
  getReportsDiscountsResumeByPagination,
  getReportsDiscountsResumeBySorting,
  getReportsDiscountsDetails,
  getReportsDiscountsDetailsByPagination,
  getReportsDiscountsDetailsBySorting,
  exportReportsDiscounts,
} from './actions';

export function* handleGetReportsDiscountsResumeRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsDiscountsResume.request());

    const { data: resume, pagination } = yield call(baasRequest, getReportsDiscountsRequest, filters);

    logEventRequest('@event.$reports.viewReport', ['@@type.summary', '@@section.discounts']);
    yield put(
      getReportsDiscountsResume.success({ resume: { ...resume, discountTypes: filters?.discountTypes }, pagination }),
    );
  } catch (err) {
    yield put(getReportsDiscountsResume.failure(err));
  }
}

export function* handleGetReportsDiscountsResumeByPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsDiscountsResumeByPagination.request());

    const { data: resume, pagination } = yield call(baasRequest, getReportsDiscountsRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getReportsDiscountsResumeByPagination.success({ resume, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getReportsDiscountsResumeByPagination.failure(err));
  }
}

export function* handleGetReportsDiscountsResumeBySortingRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsDiscountsResumeBySorting.request());

    const { data: resume, pagination } = yield call(baasRequest, getReportsDiscountsRequest, filters);

    yield put(
      getReportsDiscountsResumeBySorting.success({
        resume: { ...resume, discountTypes: filters?.discountTypes },
        pagination,
      }),
    );
  } catch (err) {
    yield put(getReportsDiscountsResumeBySorting.failure(err));
  }
}

export function* handleGetReportsDiscountsDetailsRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsDiscountsDetails.request());

    const { data: discountsDet, pagination } = yield call(baasRequest, getReportsDiscountsRequest, filters);

    logEventRequest('@event.$reports.viewReport', ['@@type.complete', '@@section.discounts']);
    yield put(
      getReportsDiscountsDetails.success({
        discounts: { ...discountsDet, discountTypes: filters?.discountTypes },
        pagination,
      }),
    );
  } catch (err) {
    yield put(getReportsDiscountsDetails.failure(err));
  }
}

export function* handleGetReportsDiscountsDetailsByPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsDiscountsDetailsByPagination.request());

    const { data: discounts, pagination } = yield call(baasRequest, getReportsDiscountsRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getReportsDiscountsDetailsByPagination.success({ discounts, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getReportsDiscountsDetailsByPagination.failure(err));
  }
}

export function* handleGetReportsDiscountsDetailsBySortingRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsDiscountsDetailsBySorting.request());

    const { data: discountsDet, pagination } = yield call(baasRequest, getReportsDiscountsRequest, filters);

    yield put(
      getReportsDiscountsDetailsBySorting.success({
        discounts: { ...discountsDet, discountTypes: filters?.discountTypes },
        pagination,
      }),
    );
  } catch (err) {
    yield put(getReportsDiscountsDetailsBySorting.failure(err));
  }
}

export function* handleExportReportsDiscountsExcelRequest(action) {
  const filters = action.payload;

  try {
    yield put(exportReportsDiscounts.request());

    const documentData = yield call(baasRequest, exportReportsDiscountsExcelRequest, filters);

    logEventRequest('@event.$reports.exportReport', '@@type.discounts');
    yield put(exportReportsDiscounts.success(documentData));
  } catch (err) {
    yield put(exportReportsDiscounts.failure(err));
  }
}

export default function* reportsDiscountsSaga() {
  yield takeLatest(getReportsDiscountsResume.TRIGGER, handleGetReportsDiscountsResumeRequest);
  yield takeLatest(getReportsDiscountsResumeByPagination.TRIGGER, handleGetReportsDiscountsResumeByPaginationRequest);
  yield takeLatest(getReportsDiscountsResumeBySorting.TRIGGER, handleGetReportsDiscountsResumeBySortingRequest);
  yield takeLatest(getReportsDiscountsDetails.TRIGGER, handleGetReportsDiscountsDetailsRequest);
  yield takeLatest(getReportsDiscountsDetailsByPagination.TRIGGER, handleGetReportsDiscountsDetailsByPaginationRequest);
  yield takeLatest(getReportsDiscountsDetailsBySorting.TRIGGER, handleGetReportsDiscountsDetailsBySortingRequest);
  yield takeLatest(exportReportsDiscounts.TRIGGER, handleExportReportsDiscountsExcelRequest);
}
