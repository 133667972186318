/**
 *
 * ParrotSoftware
 * PaymentsGatewayProvider hoc
 *
 */
import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getPaymentsMethods, createPaymentMethod, resumePaymentMethod, updatePaymentMethodStatus } from './actions';

import {
  selectPaymentMethods,
  selectPaymentMethodsState,
  selectCreatePaymentMethod,
  selectCreatePaymentMethodState,
  selectResumeMethod,
  selectResumeMethodState,
  selectUpdatePaymentMethodStatusState,
} from './selectors';

export function withPaymentsGatewayHoc(Component) {
  function withPaymentsGateway(props) {
    return <Component {...props} />;
  }

  withPaymentsGateway.displayName = `withPaymentsGateway(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withPaymentsGateway;
}

const mapStateToProps = createStructuredSelector({
  paymentsGatewayList: selectPaymentMethods,
  paymentsGatewayState: selectPaymentMethodsState,
  createPaymentMethodState: selectCreatePaymentMethodState,
  createPaymentMethodResult: selectCreatePaymentMethod,
  resumePaymentMethod: selectResumeMethod,
  resumePaymentMethodState: selectResumeMethodState,
  updatePaymentMethodStatusState: selectUpdatePaymentMethodStatusState,
});

function mapDispatchToProps(dispatch) {
  return {
    loadPaymentMethods: () => dispatch(getPaymentsMethods()),
    resetPaymentMethods: () => dispatch(getPaymentsMethods.reset()),
    createPaymentMethod: (values) => dispatch(createPaymentMethod(values)),
    resetCreatePaymentMethod: () => dispatch(createPaymentMethod.reset()),
    loadResumePaymentMethod: (values) => dispatch(resumePaymentMethod(values)),
    resetResumePaymentMethod: () => dispatch(resumePaymentMethod.reset()),
    updatePaymentMethodStatus: (values) => dispatch(updatePaymentMethodStatus(values)),
  };
}

export const withPaymentsGatewayState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withPaymentsGatewayState, withPaymentsGatewayHoc);
