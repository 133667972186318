import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const SuccessCircle = forwardRef(function SuccessCircle(props, ref) {
  const { color = '#27AE60', colorInherit = '#DFF3E7', size = 72, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 72 72"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <circle cx="36" cy="36" fill={colorInherit} r="36" />
      <path
        clipRule="evenodd"
        d="M48.7664 26.185C49.1952 26.6244 49.1997 27.3414 48.7764 27.7864L32.2764 45.1362C32.0714 45.3517 31.7918 45.4731 31.5001 45.4731C31.2083 45.4731 30.9287 45.3517 30.7237 45.1362L23.2237 37.2499C22.8005 36.8049 22.8049 36.0879 23.2337 35.6485C23.6625 35.2092 24.3532 35.2138 24.7764 35.6589L31.5001 42.7288L47.2237 26.1954C47.647 25.7503 48.3377 25.7457 48.7664 26.185Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

SuccessCircle.propTypes = {
  color: PropTypes.string,
  colorInherit: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SuccessCircle;
