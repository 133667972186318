export interface MenuProps {
  uuid: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface MenusProductOverrideProps {
  uuid: string;
  availability: string;
  price: string;
  menu: MenuProps;
  createdAt: string;
  updatedAt: string;
}

export interface StatusProps {
  STATUS_AVAILABLE: string;
  STATUS_UNAVAILABLE: string;
}

export const SalesTypes = {
  WITH_MODIFIER: 0,
  ALONE: 1,
  BY_GRAMMAGE: 2,
};

export const GrammageTypes = [
  {
    id: 'KILOGRAM',
    name: 'kg',
  },
  {
    id: 'GRAMS',
    name: 'gr',
  },
];

export interface GrammageTypesProps {
  id: string;
  name: string;
}

export const TypeOfSaleFilterValues = {
  ALL: 'ALL',
  WITH_INDEPENDENT_SALE: 'WITH_INDEPENDENT_SALE',
  WITHOUT_INDEPENDENT_SALE: 'WITHOUT_INDEPENDENT_SALE',
  SALE_BY_WEIGHT: 'SALE_BY_WEIGHT',
};
