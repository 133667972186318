import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getSettingsStore, updateSettingsStore, getSettingsCustomizePrints } from './actions';

import {
  selectSettingStoreData,
  selectSettingStoreState,
  selectUpdateSettingStore,
  selectUpdateSettingStoreState,
  selectSettingStoreCustomizePrintsData,
  selectSettingStoreCustomizePrintsState,
} from './selectors';

export function withSettingsStoreHoc(Component) {
  function withSettingsStore(props) {
    return <Component {...props} />;
  }

  withSettingsStore.displayName = `withSettingsStore(${getDisplayName(Component)})`;
  return withSettingsStore;
}

const mapStateToProps = createStructuredSelector({
  settingsStoreData: selectSettingStoreData,
  settingsStoreState: selectSettingStoreState,
  updateSettingsStoreData: selectUpdateSettingStore,
  updateSettingsStoreState: selectUpdateSettingStoreState,
  settingCustomizePrintsList: selectSettingStoreCustomizePrintsData,
  settingCustomizePrintsState: selectSettingStoreCustomizePrintsState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadSettingsStore: () => dispatch(getSettingsStore()),
    updateSettingsStore: (data = {}) => dispatch(updateSettingsStore(data)),
    loadSettingsCustomizePrints: (filters = {}) => dispatch(getSettingsCustomizePrints(filters)),
    resetSettingsStore: () => dispatch(getSettingsStore.reset()),
    resetUpdateSettingsStore: () => dispatch(updateSettingsStore.reset()),
    resetSettingsCustomizePrints: () => dispatch(getSettingsCustomizePrints.reset()),
  };
}

export const withSettingsStoreState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withSettingsStoreState, withSettingsStoreHoc);
