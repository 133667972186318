import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getInvoiceCancellations, getInvoiceTaxRegime } from './actions';

import {
  selectInvoiceCancellations,
  selectInvoiceCancellationsState,
  selectInvoiceTaxRegime,
  selectInvoiceTaxRegimeState,
} from './selectors';

export function withInvoiceCatalogHoc(Component) {
  function withInvoiceCatalog(props) {
    return <Component {...props} />;
  }

  withInvoiceCatalog.displayName = `withInvoiceCatalog(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withInvoiceCatalog;
}

const mapStateToProps = createStructuredSelector({
  InvoiceCancellations: selectInvoiceCancellations,
  InvoiceCancellationsState: selectInvoiceCancellationsState,
  InvoiceTaxRegime: selectInvoiceTaxRegime,
  InvoiceTaxRegimeState: selectInvoiceTaxRegimeState,
});

function mapDispatchToProps(dispatch) {
  return {
    loadInvoiceCancellations: () => dispatch(getInvoiceCancellations()),
    loadInvoiceTaxRegime: () => dispatch(getInvoiceTaxRegime()),
  };
}

export const withInvoiceCatalogState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withInvoiceCatalogState, withInvoiceCatalogHoc);
