import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MenuOrdering = forwardRef(function MenuOrdering(props, ref) {
  const { size = 32, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M16 0C7.16328 0 0 7.16328 0 16C0 24.8367 7.16328 32 16 32C24.8367 32 32 24.8367 32 16C32 7.16328 24.8367 0 16 0ZM24.1836 25.5822C24.1836 25.9834 24.0242 26.3682 23.7405 26.652C23.4568 26.9357 23.0719 27.0951 22.6707 27.0951H9.32931C8.92806 27.0951 8.54324 26.9357 8.25952 26.652C7.97579 26.3682 7.81639 25.9834 7.81639 25.5822V6.41784C7.81639 6.01658 7.97579 5.63177 8.25952 5.34804C8.54324 5.06431 8.92806 4.90492 9.32931 4.90492H12.3546C12.7504 4.29876 13.291 3.80084 13.9276 3.4562C14.5642 3.11155 15.2766 2.93107 16.0005 2.93107C16.7244 2.93107 17.4369 3.11155 18.0735 3.4562C18.7101 3.80084 19.2507 4.29876 19.6464 4.90492H22.6717C23.0728 4.9052 23.4574 5.06472 23.7409 5.34841C24.0244 5.63211 24.1836 6.01677 24.1836 6.41784V25.5822Z"
        fill="#F7F7F9"
      />
      <path
        d="M22.6707 4.90479H19.6454C20.0439 5.51326 20.2824 6.21243 20.3389 6.93757H22.2164V24.013H9.78362V6.93757H11.6611C11.7176 6.21243 11.9562 5.51326 12.3546 4.90479H9.32932C8.92807 4.90479 8.54326 5.06418 8.25953 5.34791C7.9758 5.63164 7.81641 6.01645 7.81641 6.4177V25.582C7.81641 25.9833 7.9758 26.3681 8.25953 26.6518C8.54326 26.9356 8.92807 27.095 9.32932 27.095H22.6707C23.072 27.095 23.4568 26.9356 23.7405 26.6518C24.0242 26.3681 24.1836 25.9833 24.1836 25.582V6.4177C24.1836 6.01645 24.0242 5.63164 23.7405 5.34791C23.4568 5.06418 23.072 4.90479 22.6707 4.90479ZM17.3115 26.0452H14.6885V24.7338H17.3115V26.0452Z"
        fill="black"
      />
      <path
        d="M15.9996 14.4591C15.9996 14.4591 11.6455 9.6895 11.6455 7.28478C11.6455 7.16779 11.6513 7.05239 11.6607 6.9375H9.7832V24.0129H22.216V6.9375H20.3385C20.3479 7.05239 20.3537 7.16779 20.3537 7.28478C20.3537 9.6895 15.9996 14.4591 15.9996 14.4591ZM16.0426 15.2911C18.4505 15.2911 19.6004 16.3114 19.6424 17.9324C19.6245 17.9324 19.6062 17.9324 19.5899 17.9324H12.403C12.4434 16.1519 13.7061 15.2911 16.0426 15.2911ZM16.0253 23.3834C13.8614 23.3834 12.6606 22.9039 12.4224 21.2735H19.5715C19.2521 22.8242 18.0943 23.3834 16.0253 23.3834ZM20.2672 20.1745C20.2665 20.3549 20.1943 20.5277 20.0665 20.655C19.9387 20.7823 19.7656 20.8538 19.5852 20.8538H12.4114C12.2314 20.8531 12.0591 20.7813 11.9318 20.6541C11.8045 20.5268 11.7327 20.3544 11.7321 20.1745V19.0288C11.7327 18.8488 11.8045 18.6764 11.9318 18.5492C12.0591 18.4219 12.2314 18.3501 12.4114 18.3494H19.5878C19.7682 18.3494 19.9413 18.4209 20.0691 18.5483C20.1969 18.6756 20.2691 18.8484 20.2698 19.0288L20.2672 20.1745Z"
        fill="white"
      />
      <path
        d="M16.0008 9.53598C16.4123 9.53639 16.8148 9.4147 17.1571 9.18631C17.4995 8.95792 17.7664 8.63308 17.9241 8.25293C18.0818 7.87277 18.1232 7.45438 18.043 7.0507C17.9628 6.64703 17.7647 6.27622 17.4736 5.9852C17.1826 5.69418 16.8118 5.49603 16.4081 5.41584C16.0045 5.33565 15.5861 5.37702 15.2059 5.53471C14.8257 5.6924 14.5009 5.95932 14.2725 6.3017C14.0441 6.64408 13.9224 7.04651 13.9229 7.45808C13.9228 7.73097 13.9765 8.0012 14.0809 8.25334C14.1853 8.50547 14.3383 8.73456 14.5313 8.92753C14.7243 9.12049 14.9534 9.27355 15.2055 9.37795C15.4576 9.48235 15.7279 9.53605 16.0008 9.53598Z"
        fill="#F7F7F9"
      />
      <path
        d="M11.6455 7.28498C11.6455 9.6897 15.9996 14.4593 15.9996 14.4593C15.9996 14.4593 20.3537 9.6897 20.3537 7.28498C20.3537 7.168 20.3479 7.05259 20.3385 6.9377C20.2473 5.84969 19.7507 4.83565 18.947 4.09656C18.1434 3.35746 17.0914 2.94727 15.9996 2.94727C14.9078 2.94727 13.8558 3.35746 13.0522 4.09656C12.2485 4.83565 11.7519 5.84969 11.6607 6.9377C11.6513 7.05259 11.6455 7.168 11.6455 7.28498ZM13.9873 6.9377C14.119 6.42547 14.441 5.98267 14.8876 5.69938C15.3343 5.41609 15.872 5.31359 16.3916 5.41273C16.9111 5.51187 17.3734 5.8052 17.6843 6.23306C17.9952 6.66091 18.1315 7.19115 18.0654 7.7159C17.9992 8.24066 17.7357 8.72052 17.3283 9.05786C16.921 9.3952 16.4004 9.56469 15.8725 9.53185C15.3446 9.499 14.8491 9.26629 14.4867 8.88106C14.1243 8.49582 13.9223 7.987 13.9217 7.4581C13.9215 7.28256 13.9435 7.1077 13.9873 6.9377Z"
        fill="#F04E4A"
      />
      <path
        d="M19.5882 18.3496H12.4118C12.0366 18.3496 11.7324 18.6538 11.7324 19.029V20.1747C11.7324 20.5498 12.0366 20.854 12.4118 20.854H19.5882C19.9634 20.854 20.2675 20.5498 20.2675 20.1747V19.029C20.2675 18.6538 19.9634 18.3496 19.5882 18.3496Z"
        fill="#F04E4A"
      />
      <path
        d="M19.5883 17.9299C19.6066 17.9299 19.625 17.9299 19.6407 17.9299C19.5988 16.3089 18.4489 15.2886 16.041 15.2886C13.7045 15.2886 12.4418 16.1494 12.4014 17.9278H19.5883V17.9299Z"
        fill="#F79494"
      />
      <path
        d="M16.0257 23.3838C18.0947 23.3838 19.252 22.8246 19.572 21.2739H12.4229C12.661 22.9044 13.8618 23.3838 16.0257 23.3838Z"
        fill="#F79494"
      />
      <path d="M17.3092 24.7358H14.6904V26.0452H17.3092V24.7358Z" fill="#F7F7F9" />
    </svg>
  );
});

MenuOrdering.propTypes = {
  size: PropTypes.number,
};

export default MenuOrdering;
