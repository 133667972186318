import { createSelector } from 'reselect';

import { getFilteredDiningOptionsByText } from 'utils/diningOptions';

import { initialState } from './reducer';

const selectDiningOptionsProviderDomain = (state) => state.settingsDiningOptions || initialState;

const selectDiningOptions = createSelector(selectDiningOptionsProviderDomain, ({ getDiningOptions, sorting }) => {
  let diningOptionsList = [...getDiningOptions.diningOptions];

  const { filterByText } = sorting;

  if (filterByText && filterByText.length >= 3) {
    diningOptionsList = getFilteredDiningOptionsByText(getDiningOptions.diningOptions, sorting);
  }

  return diningOptionsList;
});

const selectDiningOptionsPagination = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.getDiningOptions.pagination,
);

const selectDiningOptionsPaginationState = createSelector(
  selectDiningOptionsProviderDomain,
  ({ getDiningOptionsPagination }) => getDiningOptionsPagination,
);

const selectDiningOptionsPaginationValues = createSelector(
  selectDiningOptionsProviderDomain,
  ({ getDiningOptions }) => getDiningOptions.pagination,
);

const selectDiningOptionsSorting = createSelector(selectDiningOptionsProviderDomain, (subState) => subState.sorting);

const selectDiningOptionsFetching = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.getDiningOptions.fetching,
);

const selectDiningOptionsFetched = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.getDiningOptions.fetched,
);

const selectDiningOptionsError = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.getDiningOptions.error,
);

const selectCreateDiningOption = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.createDiningOption.diningOption,
);

const selectCreateDiningOptionFetching = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.createDiningOption.fetching,
);

const selectCreateDiningOptionFetched = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.createDiningOption.fetched,
);

const selectCreateDiningOptionError = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.createDiningOption.error,
);

const selectDeleteDiningOptionFetching = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.deleteDiningOption.fetching,
);

const selectDeleteDiningOptionFetched = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.deleteDiningOption.fetched,
);

const selectDeleteDiningOptionError = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.deleteDiningOption.error,
);

const selectEditDiningOption = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.editDiningOption.diningOption,
);

const selectEditDiningOptionFetching = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.editDiningOption.fetching,
);

const selectEditDiningOptionFetched = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.editDiningOption.fetched,
);

const selectEditDiningOptionError = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.editDiningOption.error,
);

const selectSetIsEnabledDiningOptionFetching = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.setIsEnabledDiningOption.fetching,
);

const selectSetIsEnabledDiningOptionFetched = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.setIsEnabledDiningOption.fetched,
);

const selectSetIsEnabledDiningOptionError = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.setIsEnabledDiningOption.error,
);

const selectDiningOptionDetail = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.getDiningOptionDetail.diningOption,
);

const selectDiningOptionDetailFetching = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.getDiningOptionDetail.fetching,
);

const selectDiningOptionDetailFetched = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.getDiningOptionDetail.fetched,
);

const selectDiningOptionDetailError = createSelector(
  selectDiningOptionsProviderDomain,
  (subState) => subState.getDiningOptionDetail.error,
);

export {
  selectDiningOptionsProviderDomain,
  selectDiningOptions,
  selectDiningOptionsPagination,
  selectDiningOptionsSorting,
  selectDiningOptionsFetching,
  selectDiningOptionsFetched,
  selectDiningOptionsError,
  selectCreateDiningOption,
  selectCreateDiningOptionFetching,
  selectCreateDiningOptionFetched,
  selectCreateDiningOptionError,
  selectDeleteDiningOptionFetching,
  selectDeleteDiningOptionFetched,
  selectDeleteDiningOptionError,
  selectEditDiningOption,
  selectEditDiningOptionFetching,
  selectEditDiningOptionFetched,
  selectEditDiningOptionError,
  selectSetIsEnabledDiningOptionFetching,
  selectSetIsEnabledDiningOptionFetched,
  selectSetIsEnabledDiningOptionError,
  selectDiningOptionDetail,
  selectDiningOptionDetailFetching,
  selectDiningOptionDetailFetched,
  selectDiningOptionDetailError,
  selectDiningOptionsPaginationState,
  selectDiningOptionsPaginationValues,
};
