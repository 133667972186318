import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectReportsCashRegisterSessionDetailProviderDomain = (state) =>
  state.reportsCashRegisterSessionDetail || initialState;

const selectCashRegisterSessionStatus = createSelector(
  selectReportsCashRegisterSessionDetailProviderDomain,
  (subState) => subState.cashRegisterSessionAll.session,
);

const selectCashRegisterSessionState = createSelector(
  selectReportsCashRegisterSessionDetailProviderDomain,
  ({ cashRegisterSessionAll: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectCashRegisterSessionSections = createSelector(
  selectReportsCashRegisterSessionDetailProviderDomain,
  (subState) => subState.cashRegisterSessionAll.sections,
);

export {
  selectReportsCashRegisterSessionDetailProviderDomain,
  selectCashRegisterSessionStatus,
  selectCashRegisterSessionState,
  selectCashRegisterSessionSections,
};
