import { createSelector } from 'reselect';

import { convertTaxesToList, withNoApplyOption } from 'utils/settingsTaxes/transformer';

import { initialState } from './reducer';

const selecMultiTaxesProviderDomain = (state) => state.multiTaxesProvider || initialState;

const selectMultiTaxes = createSelector(selecMultiTaxesProviderDomain, ({ multiTaxes }) => multiTaxes.taxes);

const selectMultiTaxesAtList = createSelector(selecMultiTaxesProviderDomain, ({ multiTaxes }) =>
  convertTaxesToList(multiTaxes.taxes),
);

const selectMultiTaxesWithNoOption = createSelector(selecMultiTaxesProviderDomain, ({ multiTaxes }) =>
  withNoApplyOption([...multiTaxes.taxes]),
);

const selectMultiTaxesState = createSelector(
  selecMultiTaxesProviderDomain,
  ({ multiTaxes: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateDefaultState = createSelector(
  selecMultiTaxesProviderDomain,
  ({ updateDefaultTax: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

export {
  selectMultiTaxes,
  selectMultiTaxesAtList,
  selectMultiTaxesState,
  selectUpdateDefaultState,
  selectMultiTaxesWithNoOption,
};
