/* eslint-disable @typescript-eslint/no-explicit-any */
export default {
  error: null,
  fetching: false,
  fetched: false,
};

export interface BaseStateInterface {
  error: any;
  fetching: boolean;
  fetched: boolean;
}
