import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectReceiptCustomizationProviderDomain = (state) => state.settingsReceiptCustomization || initialState;

const selectBrandsReceiptCustomization = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.getBrandsReceiptCustomization.brands,
);

const selectBrandsReceiptCustomizationFetching = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.getBrandsReceiptCustomization.fetching,
);

const selectBrandsReceiptCustomizationFetched = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.getBrandsReceiptCustomization.fetched,
);

const selectBrandsReceiptCustomizationError = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.getBrandsReceiptCustomization.error,
);

const selectBrandReceiptCustomization = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.getBrandReceiptCustomization.brand,
);

const selectBrandReceiptCustomizationFetching = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.getBrandReceiptCustomization.fetching,
);

const selectBrandReceiptCustomizationFetched = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.getBrandReceiptCustomization.fetched,
);

const selectBrandReceiptCustomizationError = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.getBrandReceiptCustomization.error,
);

const selectUpdateBrandReceiptCustomization = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.updateBrandReceiptCustomization.brand,
);

const selectUpdateBrandReceiptCustomizationFetching = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.updateBrandReceiptCustomization.fetching,
);

const selectUpdateBrandReceiptCustomizationFetched = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.updateBrandReceiptCustomization.fetched,
);

const selectUpdateBrandReceiptCustomizationError = createSelector(
  selectReceiptCustomizationProviderDomain,
  (subState) => subState.updateBrandReceiptCustomization.error,
);

export {
  selectReceiptCustomizationProviderDomain,
  selectBrandsReceiptCustomization,
  selectBrandsReceiptCustomizationFetching,
  selectBrandsReceiptCustomizationFetched,
  selectBrandsReceiptCustomizationError,
  selectBrandReceiptCustomization,
  selectBrandReceiptCustomizationFetching,
  selectBrandReceiptCustomizationFetched,
  selectBrandReceiptCustomizationError,
  selectUpdateBrandReceiptCustomization,
  selectUpdateBrandReceiptCustomizationFetching,
  selectUpdateBrandReceiptCustomizationFetched,
  selectUpdateBrandReceiptCustomizationError,
};
