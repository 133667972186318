import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getMovementReasons,
  getMovementReasonDetail,
  createMovementReason,
  deleteMovementReason,
  editMovementReason,
} from './actions';

import {
  selectMovementReasons,
  selectMovementReasonsPagination,
  selectMovementReasonsState,
  selectMovementReasonDetail,
  selectMovementReasonDetailState,
  selectCreateMovementReasonState,
  selectUpdateMovementReasonState,
  selectDeleteMovementReasonState,
} from './selectors';

export function withSettingsPayInHoc(Component) {
  function withSettingsPay(props) {
    return <Component {...props} />;
  }

  withSettingsPay.displayName = `withSettingsPay(${getDisplayName(Component)})`;

  return withSettingsPay;
}

const mapStateToProps = createStructuredSelector({
  movementReasonsList: selectMovementReasons,
  movementReasonState: selectMovementReasonsState,
  movementPagination: selectMovementReasonsPagination,
  movementReasonDetail: selectMovementReasonDetail,
  movementReasonDetailState: selectMovementReasonDetailState,
  createMovementReasonState: selectCreateMovementReasonState,
  deleteMovementReasonState: selectDeleteMovementReasonState,
  updateMovementReasonState: selectUpdateMovementReasonState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadMovementReasons: () => dispatch(getMovementReasons()),
    loadMovementsReasonsDetail: (reasonUuid) => dispatch(getMovementReasonDetail({ reasonUuid })),
    createMovementReason: (movementType, name) => dispatch(createMovementReason({ movementType, name })),
    deleteMovementReason: (reasonUuid) => dispatch(deleteMovementReason({ reasonUuid })),
    resetCreateReasonState: () => dispatch(createMovementReason.reset()),
    resetDeleteReasonState: () => dispatch(deleteMovementReason.reset()),
    resetUpdateReasonState: () => dispatch(editMovementReason.reset()),
    updateMovementReason: (movementType, name, reasonUuid) =>
      dispatch(editMovementReason({ movementType, name, reasonUuid })),
  };
}

export const withSettingsPayState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withSettingsPayState, withSettingsPayInHoc);
