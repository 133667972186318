// @ts-nocheck
import { useInfiniteQuery } from 'react-query';
import snakeCase from 'lodash/snakeCase';

import { getSkuListRequest } from 'utils/api/baas/menuMaker/sku';

export function useGetSkuList(type: string, filter: null | string, isSku?: boolean) {
  const {
    data,
    isFetched,
    isFetching,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    ['listSku', type],
    ({ pageParam = 1 }) =>
      getSkuListRequest({ searchValue: filter, objectType: snakeCase(type)?.toUpperCase(), isSku, page: pageParam }),
    {
      keepPreviousData: false,
      initialData: { data: [], pagination: { next: null } },
      getNextPageParam: (lastPage, pages) => (!lastPage.pagination.next ? null : pages.length + 1),
      enabled: false,
    },
  );

  return {
    skuList: data?.pages,
    loadSkuList: refetch,
    paginateSkuList: fetchNextPage,
    skuListState: {
      fetched: isFetched,
      fetching: isFetching,
      error: isError,
      isFetchingNextPage,
      hasNextPage,
    },
    resetSkuList: remove,
  };
}
