import { stringify } from 'query-string';
import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getModifierGroupsRequest({ storeUuid, name, type, page = 1, allModifiers, searchType }, options = {}) {
  const query = stringify({
    store_uuid: storeUuid,
    name: name ?? undefined,
    modifier_types: type ?? undefined,
    page,
    page_size: allModifiers ? undefined : 50,
    search_type: searchType,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/modifiers/?${query}`, { method: 'GET', ...options });
}

export function deleteModifierGroupRequest({ storeUuid, modifierGroupUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/modifier-groups/${modifierGroupUuid}/?store_uuid=${storeUuid}`, {
    method: 'DELETE',
    ...options,
  });
}

export function duplicateModifierGroupRequest({ storeUuid, modifierGroupUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/duplicate/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      uuid: modifierGroupUuid,
      objectType: 'MODIFIER_GROUP',
    }),
    ...options,
  });
}

export function getModifierGroupRequest({ storeUuid, modifierGroupUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/modifiers/${modifierGroupUuid}/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function createModifierGroupRequest({ storeUuid, modifierGroup }, options = {}) {
  const body = { ...modifierGroup };
  return request(`${API_BASE_URL}/v1/menu-maker/modifiers/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify(body),
    ...options,
  });
}

export function updateModifierGroupRequest({ storeUuid, originMenuUuid, modifierGroup }, options = {}) {
  const { uuid, ...toUpdate } = modifierGroup;
  const query = stringify({ store_uuid: storeUuid, origin_menu_id: originMenuUuid });

  return request(`${API_BASE_URL}/v1/menu-maker/modifiers/${uuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify(toUpdate),
    ...options,
  });
}
