import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getDiscounts,
  getDiscountsPagination,
  getDiscountDetail,
  createDiscount,
  deleteDiscount,
  setIsEnabledDiscount,
} from './actions';
import {
  selectDiscounts,
  selectDiscountsFetching,
  selectDiscountsFetched,
  selectDiscountsError,
  selectCreateDiscount,
  selectCreateDiscountFetching,
  selectCreateDiscountFetched,
  selectCreateDiscountError,
  selectDeleteDiscountFetching,
  selectDeleteDiscountFetched,
  selectDeleteDiscountError,
  selectSetIsEnabledDiscountFetching,
  selectSetIsEnabledDiscountFetched,
  selectSetIsEnabledDiscountError,
  selectDiscountDetail,
  selectDiscountDetailFetching,
  selectDiscountDetailFetched,
  selectDiscountDetailError,
  selectDiscountsPaginationState,
  selectDiscountsPaginationInfo,
} from './selectors';

export function withDiscountsHoc(Component) {
  function withDiscounts(props) {
    return <Component {...props} />;
  }

  withDiscounts.displayName = `withDiscounts(${getDisplayName(Component)})`;

  return withDiscounts;
}

const mapStateToProps = createStructuredSelector({
  discountsList: selectDiscounts,
  discountsFetching: selectDiscountsFetching,
  discountsFetched: selectDiscountsFetched,
  discountsError: selectDiscountsError,
  createDiscountObject: selectCreateDiscount,
  createDiscountFetching: selectCreateDiscountFetching,
  createDiscountFetched: selectCreateDiscountFetched,
  createDiscountError: selectCreateDiscountError,
  deleteDiscountFetching: selectDeleteDiscountFetching,
  deleteDiscountFetched: selectDeleteDiscountFetched,
  deleteDiscountError: selectDeleteDiscountError,
  setIsEnabledDiscountFetching: selectSetIsEnabledDiscountFetching,
  setIsEnabledDiscountFetched: selectSetIsEnabledDiscountFetched,
  setIsEnabledDiscountError: selectSetIsEnabledDiscountError,
  discountDetailObject: selectDiscountDetail,
  discountDetailFetching: selectDiscountDetailFetching,
  discountDetailFetched: selectDiscountDetailFetched,
  discountDetailError: selectDiscountDetailError,
  discountsPaginationState: selectDiscountsPaginationState,
  discountsPaginationInfo: selectDiscountsPaginationInfo,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadDiscounts: (storeUuid) => dispatch(getDiscounts(storeUuid)),
    loadDiscountsPagination: (filters = {}) => dispatch(getDiscountsPagination(filters)),
    loadDiscountDetail: ({ storeUuid, discountUuid }) => dispatch(getDiscountDetail({ storeUuid, discountUuid })),
    createDiscount: ({ storeUuid, title, discountType, amountType, description, amount, isRestricted, scope }) =>
      dispatch(
        createDiscount({ storeUuid, title, discountType, amountType, description, amount, isRestricted, scope }),
      ),
    deleteDiscount: ({ storeUuid, discountUuid }) => dispatch(deleteDiscount({ storeUuid, discountUuid })),
    setIsEnabledDiscount: ({ storeUuid, discountUuid, isEnabled }) =>
      dispatch(setIsEnabledDiscount({ storeUuid, discountUuid, isEnabled })),
    resetDiscounts: () => dispatch(getDiscounts.reset()),
    resetDiscountsPagination: () => dispatch(getDiscountsPagination.reset()),
    resetDiscountDetail: () => dispatch(getDiscountDetail.reset()),
    resetCreateDiscount: () => dispatch(createDiscount.reset()),
    resetDeleteDiscount: () => dispatch(deleteDiscount.reset()),
    resetSetIsEnabledDiscount: () => dispatch(setIsEnabledDiscount.reset()),
  };
}

export const withDiscountsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withDiscountsState, withDiscountsHoc);
