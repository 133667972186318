const defaultFlags = {
  reportModifiersByStore: '{"storesUuidList":[]}',
  activeReportModifiersByStore: false,
  validateItemsAvailabilityAtPublish: false,
  activeGeneralReceiptSetting: false,
  activeReportSalesPerTime: false,
  activeConciliationBanner: false,
  validateItemsAvailabilityByStore: '{"storesUuidList":[]}',
};

export default defaultFlags;
