import React from 'react';
import DividerMUI from '@material-ui/core/Divider';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  divider: {
    background: theme.palette.darkGrey[15],
  },
}));

function Divider({ ...others }) {
  const classes = useStyles();

  return <DividerMUI classes={{ root: classes.divider }} {...others} />;
}

export default Divider;
