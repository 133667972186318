import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  REFRESH_TOKEN,
  GET_USER,
  SET_STORE,
  GET_LICENSE_STATE_CHANNEL,
  CLEAR_LICENSE_STATE_CHANNEL,
} from './constants';

export const refreshToken = extendRoutine(createRoutine(REFRESH_TOKEN), 'RESET');
export const getUser = extendRoutine(createRoutine(GET_USER), 'RESET');
export const setStore = extendRoutine(createRoutine(SET_STORE), 'SET_STORE');
export const getLicenseStateChannel = createRoutine(GET_LICENSE_STATE_CHANNEL);
export const clearLicenseStateChannel = createRoutine(CLEAR_LICENSE_STATE_CHANNEL);
