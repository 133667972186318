import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectBrandsProviderDomain = (state) => state.brandsProviderV2 || initialState;

const selectBrands = createSelector(selectBrandsProviderDomain, (subState) => subState.getBrands.brands);

const selectBrandsState = createSelector(selectBrandsProviderDomain, ({ getBrands: { error, fetching, fetched } }) => ({
  error,
  fetching,
  fetched,
}));

const selectUpdateBrandsState = createSelector(
  selectBrandsProviderDomain,
  ({ updateBrands: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

export { selectBrandsProviderDomain, selectBrands, selectBrandsState, selectUpdateBrandsState };
