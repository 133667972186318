/**
 *
 * ParrotSoftware
 * SettingsServiceAreasProvider actions
 *
 */
import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/extendRoutines';

import {
  CREATE_SERVICE_AREA,
  GET_SERVICES_AREAS,
  DELETE_SERVICE_AREA,
  GET_SERVICE_AREA_DETAILS,
  UPDATE_SERVICE_AREA,
  VALIDATE_NAME_AREA,
  SERVICE_AREA_CHANGE_DEFAULT,
  UPDATE_AREA_POSITION,
} from './constants';

export const createServiceArea = extendRoutine(createRoutine(CREATE_SERVICE_AREA), 'RESET');
export const getServicesAreas = extendRoutine(createRoutine(GET_SERVICES_AREAS), ['RESET', 'SORT_POSITION']);
export const getServiceAreaDetails = extendRoutine(createRoutine(GET_SERVICE_AREA_DETAILS), 'RESET');
export const deleteServiceArea = extendRoutine(createRoutine(DELETE_SERVICE_AREA), 'RESET');
export const updateServiceArea = extendRoutine(createRoutine(UPDATE_SERVICE_AREA), 'RESET');
export const validateNameArea = extendRoutine(createRoutine(VALIDATE_NAME_AREA), 'RESET');
export const serviceAreaChangeDefault = extendRoutine(createRoutine(SERVICE_AREA_CHANGE_DEFAULT), 'RESET');
export const updateAreaPosition = extendRoutine(createRoutine(UPDATE_AREA_POSITION), 'RESET');
