/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';
import Box from '@material-ui/core/Box';

import useUserStore from 'hooks/useUserStore';

import { withRequiredLicense } from 'providers/root/AuthProvider';
import { withModifierGroups } from 'providers/menuMaker/ModifierGroups';
import { withModifierGroupDetails } from 'providers/menuMaker/ModifierGroupDetails';
import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import Layout, { ContentLayout } from 'Layout';
import useNotification from '@design-system/Notification/useNotification';
import MenuMakerTabsNavigation from 'components/MenuMakerTabsNavigation';
import ModifierGroupsTable from 'components/ModifierGroupsTable';
import ModifierGroupDrawer from 'components/ModifierGroupDrawer';

import PageError from 'components/PageError';
import HeaderForm from 'components/HeaderForm';

import {
  drawerActions,
  drawerEntities,
  drawerActionList,
  drawerEntityList,
  getQueryParamsMenuMaker,
} from 'utils/drawerMenuMaker';

import useStyles from './styles';

export function ModifierGroups({
  // Get Modifier Groups
  loadModifierGroup,
  modifierGroupObject,
  modifierGroupFetching,
  modifierGroupFetched,
  resetModifierGroupState,
  loadModifierGroups,
  modifierGroupsList,
  modifierGroupsFetching,
  modifierGroupsFetched,
  modifierGroupsError,
  modifierGroupPagination,
  paginationModifierGroupState,
  paginateModifierGroups,
  // Delete Modifier Group
  deleteModifierGroup,
  resetDeleteModifierGroup,
  deleteModifierGroupFetching,
  deleteModifierGroupFetched,
  deleteModifierGroupError,
  // Duplicate Modifier Group
  duplicateModifierGroup,
  resetDuplicateModifierGroup,
  duplicateModifierGroupFetched,
  duplicateModifierGroupError,
  // Modifier Group create
  createModifierGroup,
  createModifierGroupError,
  createModifierGroupFetching,
  createModifierGroupFetched,
  createModifierGroupObject,
  resetCreateModifierGroup,
  // edit
  editModifierGroupError,
  editModifierGroupFetching,
  editModifierGroupFetched,
  editModifierGroupObject,
  editModifierGroup,
  resetEditModifierGroup,
  t,
}) {
  const { modeDraft, isPublishMenuError } = useSidebarLayout();
  const {
    storeId,
    storeState: { selected: storeSelected },
  } = useUserStore();
  const history = useHistory();
  const location = useLocation();
  const { closeAllSnackbar, setNotification } = useNotification();
  const [selectedModifierGroupUuid, setSelectedModifierGroupUuid] = useState(null);
  const [filtersSelected, setFiltersSelected] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles({ openDrawer, isModeDraft: modeDraft || isPublishMenuError });

  const { logEvent } = useAmplitude();

  const { action, entity, uuid } = getQueryParamsMenuMaker(location);
  const isEdition = action === drawerActions.EDIT;

  useEffect(() => {
    logEvent('@event.$menuMaker.viewMenuMaker', '@@section.modifiers');
    setTimeout(() => {
      loadModifierGroups({ storeUuid: storeId });
    }, 0);
  }, [storeId]);

  useEffect(() => {
    const isValid = drawerActionList.includes(action) && drawerEntityList.includes(entity);

    setOpenDrawer(Boolean(isEdition ? isValid && uuid : isValid));
  }, [action, entity, uuid, isEdition]);

  useEffect(() => {
    if (createModifierGroupFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.modifiers', '@@type.new', '@@item.modifiers'], {
        itemId: createModifierGroupObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadModifierGroups({ storeUuid: storeId, ...filtersSelected });
      setNotification(
        t('menuMaker:modifierGroupForm.messages.saveSuccess', { modifierGroupName: createModifierGroupObject?.name }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetCreateModifierGroup();
    }

    if (createModifierGroupError) {
      setNotification(t('menuMaker:modifierGroupForm.messages.saveError'), { variant: 'error' });
      resetCreateModifierGroup();
    }
  }, [createModifierGroupFetched, createModifierGroupError]);

  useEffect(() => {
    if (deleteModifierGroupFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.modifiers', '@@type.delete', '@@item.modifiers'], {
        itemId: selectedModifierGroupUuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadModifierGroups({ storeUuid: storeId, ...filtersSelected });
      setNotification(t('menuMaker:modifierGroupsTable.snacks.deletedModifierGroup.success'), { variant: 'success' });
      setSelectedModifierGroupUuid(null);
      resetDeleteModifierGroup();
    }

    if (deleteModifierGroupError) {
      setNotification(t('menuMaker:modifierGroupsTable.snacks.deletedModifierGroup.error'), { variant: 'error' });
      resetDeleteModifierGroup();
    }
  }, [deleteModifierGroupFetched]);

  useEffect(() => {
    if (duplicateModifierGroupFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.modifiers', '@@type.duplicate', '@@item.modifiers'], {
        itemId: selectedModifierGroupUuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadModifierGroups({ storeUuid: storeId, ...filtersSelected });
      setNotification(t('menuMaker:modifierGroupsTable.snacks.duplicatedModifierGroup.success'), {
        variant: 'success',
      });
      setSelectedModifierGroupUuid(null);
      resetDuplicateModifierGroup();
    }

    if (duplicateModifierGroupError) {
      setNotification(t('menuMaker:modifierGroupsTable.snacks.duplicatedModifierGroup.error'), {
        variant: 'error',
      });
      resetDuplicateModifierGroup();
    }
  }, [duplicateModifierGroupFetched, duplicateModifierGroupError]);

  useEffect(() => {
    if (editModifierGroupFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.modifiers', '@@type.edit', '@@item.modifiers'], {
        itemId: editModifierGroupObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadModifierGroups({ storeUuid: storeId, ...filtersSelected });
      setNotification(
        t('menuMaker:modifierGroupForm.messages.editSuccess', { modifierGroupName: editModifierGroupObject?.name }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetEditModifierGroup();
    }

    if (editModifierGroupError) {
      setNotification(t('menuMaker:modifierGroupForm.messages.editError'), { variant: 'error' });
      resetEditModifierGroup();
    }
  }, [editModifierGroupFetched, editModifierGroupError]);

  useEffect(() => {
    return () => {
      resetCreateModifierGroup();
      resetEditModifierGroup();
      resetDuplicateModifierGroup();
      resetDeleteModifierGroup();
      closeAllSnackbar();
    };
  }, [
    resetCreateModifierGroup,
    resetEditModifierGroup,
    resetDuplicateModifierGroup,
    resetDeleteModifierGroup,
    closeAllSnackbar,
  ]);

  const handleSearchByTextModifierGroups = () => (value) => {
    const { filterByText, searchType } = value;

    if (filterByText?.length >= 3) {
      logEvent('@event.$menuMaker.menuMakerFilters', '@@section.modifiers', { search: filterByText });
      setFiltersSelected({ ...filtersSelected, name: filterByText, searchType });
      loadModifierGroups({ ...filtersSelected, storeUuid: storeId, name: filterByText, searchType });
    }

    if (!filterByText) {
      setFiltersSelected({ ...filtersSelected, name: null });
      loadModifierGroups({ ...filtersSelected, storeUuid: storeId, name: null, searchType });
    }
  };

  const handleSearchByModifierType = (value) => {
    const { filterByType } = value;
    setFiltersSelected({ ...filtersSelected, type: filterByType });
    loadModifierGroups({ ...filtersSelected, storeUuid: storeId, type: filterByType });
  };

  const handleBySeachTypeModifier = (value) => {
    const { searchType, filterByText } = value;
    setFiltersSelected({ ...filtersSelected, searchType });
    if (filterByText?.length >= 3) {
      loadModifierGroups({ ...filtersSelected, storeUuid: storeId, searchType });
    }
  };

  const handleDeleteModifierGroup = (storeUuid, modifierGroup) => {
    setSelectedModifierGroupUuid(modifierGroup.uuid);
    deleteModifierGroup({ storeUuid, modifierGroupUuid: modifierGroup.uuid });
  };

  const handleDuplicateModifierGroup = (modifierGroupUuid) => {
    setSelectedModifierGroupUuid(modifierGroupUuid);
    duplicateModifierGroup({ storeUuid: storeId, modifierGroupUuid });
  };

  const handleOpenNewModifierGroup = () => {
    const query = { action: drawerActions.CREATE, entity: drawerEntities.MODIFIER_GROUP };

    history.replace({ search: stringify(query) });
  };

  const handlePaginateModifierGroups = () => {
    paginateModifierGroups({
      ...filtersSelected,
      storeUuid: storeId,
      page: modifierGroupPagination?.page + 1,
    });
  };

  const handleSubmitModifierGroup = (values) => {
    if (isEdition) {
      editModifierGroup(storeId, { uuid, ...values });
    } else {
      createModifierGroup(storeId, values);
    }
  };

  const handleLoadModifierGroupDetails = () => {
    loadModifierGroup(storeId, uuid);
  };

  return (
    <Layout>
      <ContentLayout>
        <ContentLayout.Header className={classes.headerLayout} sticky>
          <MenuMakerTabsNavigation />
        </ContentLayout.Header>

        <ContentLayout.Container px="0">
          <Box className={classes.root} pt={4}>
            <HeaderForm
              addButtonLabel={t('menuMaker:buttons.modifierGroup')}
              isShowModifierTypeFilter
              isShowSearchTypeModifierFilter
              onAddButtonClick={handleOpenNewModifierGroup}
              onModifierSeacrhTypeChange={handleBySeachTypeModifier}
              onModifierTypeChange={handleSearchByModifierType}
              onSearchBoxChange={handleSearchByTextModifierGroups()}
              openDrawer={openDrawer}
              px={4}
              searchLabel={t('menuMaker:modifierGroupsTable.formHeader.searchModifierGroup')}
            />

            {modifierGroupsError && (
              <PageError
                data-testid="modifierGroupsError"
                id="modifierGroupsError"
                labelAction={t('common:buttons.retry')}
                message={t('common:errors.loaded.modifierGroups')}
                onAction={() => loadModifierGroups({ storeUuid: storeId, ...filtersSelected })}
              />
            )}

            {!modifierGroupsError && (
              <ModifierGroupsTable
                deleteModifierGroupState={{
                  fetching: deleteModifierGroupFetching,
                  fetched: deleteModifierGroupFetched,
                  error: deleteModifierGroupError,
                }}
                isSearchFilter={Boolean(filtersSelected?.name || filtersSelected?.type)}
                loading={modifierGroupsFetching || (!modifierGroupsList.length && !modifierGroupsFetched)}
                modifierGroupPagination={modifierGroupPagination}
                modifierGroups={modifierGroupsList}
                modifierGroupsFetched={modifierGroupsFetched}
                onDeleteModifierGroup={handleDeleteModifierGroup}
                onDuplicateModifierGroup={handleDuplicateModifierGroup}
                onPaginate={handlePaginateModifierGroups}
                openDrawer={openDrawer}
                paginationModifierGroupState={paginationModifierGroupState}
              />
            )}
          </Box>

          <ModifierGroupDrawer
            actionState={
              isEdition
                ? { fetched: editModifierGroupFetched, fetching: editModifierGroupFetching }
                : { fetched: createModifierGroupFetched, fetching: createModifierGroupFetching }
            }
            isEdition={isEdition}
            modifierGroupState={{
              data: modifierGroupObject,
              fetching: modifierGroupFetching,
              fetched: modifierGroupFetched,
            }}
            onLoadModifierGroupDetails={handleLoadModifierGroupDetails}
            onResetModifierGroupDetails={resetModifierGroupState}
            onSubmit={handleSubmitModifierGroup}
            openDrawer={openDrawer}
            uuidSelected={uuid}
          />
        </ContentLayout.Container>
      </ContentLayout>
    </Layout>
  );
}

ModifierGroups.propTypes = {
  // Get Modifier Groups
  loadModifierGroup: PropTypes.func,
  modifierGroupObject: PropTypes.object,
  modifierGroupFetching: PropTypes.bool,
  modifierGroupFetched: PropTypes.bool,
  resetModifierGroupState: PropTypes.func,
  loadModifierGroups: PropTypes.func,
  modifierGroupsList: PropTypes.array,
  modifierGroupsFetching: PropTypes.bool,
  modifierGroupsFetched: PropTypes.bool,
  modifierGroupsError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  modifierGroupPagination: PropTypes.object,
  paginationModifierGroupState: PropTypes.object,
  paginateModifierGroups: PropTypes.func,
  // Delete Modifier Group
  deleteModifierGroup: PropTypes.func,
  resetDeleteModifierGroup: PropTypes.func,
  deleteModifierGroupFetching: PropTypes.bool,
  deleteModifierGroupFetched: PropTypes.bool,
  deleteModifierGroupError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  // Duplicate Modifier Group
  duplicateModifierGroup: PropTypes.func,
  resetDuplicateModifierGroup: PropTypes.func,
  duplicateModifierGroupFetched: PropTypes.bool,
  duplicateModifierGroupError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  // Modifier Group Save
  createModifierGroup: PropTypes.func,
  createModifierGroupError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  createModifierGroupFetching: PropTypes.bool,
  createModifierGroupFetched: PropTypes.bool,
  createModifierGroupObject: PropTypes.object,
  resetCreateModifierGroup: PropTypes.func,
  // edit
  editModifierGroupError: PropTypes.bool,
  editModifierGroupFetching: PropTypes.bool,
  editModifierGroupFetched: PropTypes.bool,
  editModifierGroupObject: PropTypes.object,
  editModifierGroup: PropTypes.func,
  resetEditModifierGroup: PropTypes.func,
  t: PropTypes.func,
};

export default compose(
  memo,
  withRequiredLicense(),
  withModifierGroups,
  withModifierGroupDetails,
  withTranslation('menuMaker'),
)(ModifierGroups);
