const CASH = 'CASH';
const CREDIT_CARD = 'CREDIT_CARD';
const DEBIT_CARD = 'DEBIT_CARD';
const OXXO = 'OXXO';
const SPEI = 'SPEI';
const THIRD_PARTY = 'THIRD_PARTY';
const CONFIG = 'CONFIG';
const CARD = 'CARD';

const PENDING = 'PENDING';
const SUCCESS = 'SUCCESS';
const FAILED = 'FAILED';

export const typePaymentMethods = {
  CASH,
  CREDIT_CARD,
  DEBIT_CARD,
  CARD,
  OXXO,
  SPEI,
  THIRD_PARTY,
};

const MASTERCARD = 'MASTERCARD';
const VISA = 'VISA';
const AMEX = 'AMERICAN_EXPRESS';

export const cardsBrand = {
  MASTERCARD,
  VISA,
  AMEX,
};

export const stepsTypes = {
  CONFIG,
  CARD,
  OXXO,
  SPEI,
};

export const lastPaymentStatus = {
  PENDING,
  SUCCESS,
  FAILED,
};

export const stepsLabels = {
  [CONFIG]: {
    1: {
      title: 'subscription:stepper.steps.config.title',
      text: 'subscription:stepper.steps.config.text',
      labelButtonNext: 'subscription:stepper.steps.config.labelButtonNext',
      titleEnd: 'subscription:stepper.steps.config.title',
    },
  },

  [CARD]: {
    2: {
      title: 'subscription:stepper.steps.card.second.title',
      text: 'subscription:stepper.steps.card.second.text',
      labelButtonNext: 'subscription:stepper.steps.card.second.labelButtonNext',
    },
    3: {
      title: 'subscription:stepper.steps.card.final.title',
      text: 'subscription:stepper.steps.card.final.text',
    },
  },

  [OXXO]: {
    title: 'subscription:stepper.steps.oxxo.title',
    text: 'subscription:stepper.steps.oxxo.text',
    labelButtonNext: 'subscription:stepper.steps.oxxo.labelButtonNext',
    titleEnd: 'subscription:stepper.steps.oxxo.title',
  },

  [SPEI]: {
    title: 'subscription:stepper.steps.spei.title',
    text: 'subscription:stepper.steps.spei.text',
    labelButtonNext: 'subscription:stepper.steps.spei.labelButtonNext',
    titleEnd: 'subscription:stepper.steps.spei.titleEnd',
  },
};

function limitMonth(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = `0 ${val}`;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

export function cardExpiry(val) {
  const month = limitMonth(val.substring(0, 2), '12');
  const year = val.substring(2, 6);

  return month + (year.length ? `/${year}` : '');
}

export const formatRetryPaymentMsg = (paymentError, t) => {
  let message = t('subscription:notifications.retryPayment.genericError');

  if (paymentError) {
    message = paymentError;
  }

  const messageSplit = message.split('. ');

  return messageSplit.map((item, index) => (
    <p key={item}>{`${item}${index !== messageSplit.length - 1 ? '.' : ''}`}</p>
  ));
};
