import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectInvoicesTickestEditorProviderDomain = (state) => state.invoicesTicketsEditorProvider || initialState;

const selectInvoicesEdit = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  (subState) => subState.getInvoicesOrdersEdit.invoices,
);

const selectInvoicesEditState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  ({ getInvoicesOrdersEdit: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

const selectInvoicesEditPagination = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  (subState) => subState.getInvoicesOrdersEdit.pagination,
);

const selectInvoicesEditPaginationState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  (subState) => subState.loadPaginationStatus,
);

const selectInvoicesPreEditState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  ({ getInvoicesOrdersPreEdit: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

const selectInvoicesPreEditPreview = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  (subState) => subState.getInvoicesOrdersPreEdit.previewData,
);

const selectUpdateInvoicesOrdersState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  ({ updateInvoicesOrders: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

const selectResetEditedInvoicesOrdersState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  ({ resetEditedInvoicesOrders: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

const selectGenerateInvoicingState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  (subState) => subState.generateInvoicing,
);

const selectGenerateInvoicingInfo = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  (subState) => subState.generateInvoicing.data,
);

const selectInvoicesOrderDetails = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  (subState) => subState.getInvoicesOrderDetailsEdit.order,
);

const selectInvoicesOrderDetailsState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  ({ getInvoicesOrderDetailsEdit: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

const selectUpdateOrderRemoveItem = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  (subState) => subState.updateOrderRemoveItem.orderUpdated,
);

const selectUpdateOrderRemoveItemState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  ({ updateOrderRemoveItem: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

const selectUpdateOrderDiscount = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  (subState) => subState.updateOrderDiscount.orderUpdated,
);

const selectUpdateOrderDiscountState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  ({ updateOrderDiscount: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

const selectRevertChangesOrderEditedState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  ({ revertChangesOrderEdited: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

const selectSaveChangesOrderEditedState = createSelector(
  selectInvoicesTickestEditorProviderDomain,
  ({ saveChangesOrderEdited: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

export {
  selectInvoicesTickestEditorProviderDomain,
  selectInvoicesEdit,
  selectInvoicesEditState,
  selectInvoicesEditPagination,
  selectInvoicesEditPaginationState,
  selectInvoicesPreEditState,
  selectInvoicesPreEditPreview,
  selectUpdateInvoicesOrdersState,
  selectResetEditedInvoicesOrdersState,
  selectGenerateInvoicingState,
  selectGenerateInvoicingInfo,
  selectInvoicesOrderDetails,
  selectInvoicesOrderDetailsState,
  selectUpdateOrderRemoveItem,
  selectUpdateOrderRemoveItemState,
  selectUpdateOrderDiscount,
  selectUpdateOrderDiscountState,
  selectRevertChangesOrderEditedState,
  selectSaveChangesOrderEditedState,
};
