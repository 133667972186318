import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_SUBSCRIPTION_HOME,
  DELETE_PAYMENT_METHOD,
  SAVE_PAYMENT_METHOD,
  GET_SUBSCRIPTION_PYMENTS,
  GET_SUBSCRIPTION_PYMENTS_BY_PAGINATION,
  GET_SUBSCRIPTION_INVOICE_DATA,
  UPDATE_SUBSCRIPTION_INVOICE_DATA,
} from './constants';

export const getSubscriptionHome = extendRoutine(createRoutine(GET_SUBSCRIPTION_HOME), 'RESET');
export const deletePaymentMethod = extendRoutine(createRoutine(DELETE_PAYMENT_METHOD), 'RESET');
export const savePaymentMethod = extendRoutine(createRoutine(SAVE_PAYMENT_METHOD), 'RESET');
export const getSubscriptionPayments = extendRoutine(createRoutine(GET_SUBSCRIPTION_PYMENTS), 'RESET');
export const getSubscriptionPaymentsByPagination = extendRoutine(
  createRoutine(GET_SUBSCRIPTION_PYMENTS_BY_PAGINATION),
  'RESET',
);
export const getSubscriptionInvoiceData = extendRoutine(createRoutine(GET_SUBSCRIPTION_INVOICE_DATA), 'RESET');
export const updateSubscriptionInvoiceData = extendRoutine(createRoutine(UPDATE_SUBSCRIPTION_INVOICE_DATA), 'RESET');
