import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectKitchenAreasProviderDomain = (state) => state.settingsKitchenAreas || initialState;

const selectKitchenAreas = createSelector(
  selectKitchenAreasProviderDomain,
  (subState) => subState.kitchenAreas.kitchenAreas,
);

const selectKitchenAreasState = createSelector(
  selectKitchenAreasProviderDomain,
  ({ kitchenAreas: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectKitchenArea = createSelector(
  selectKitchenAreasProviderDomain,
  (subState) => subState.kitchenAreaDetails.kitchenArea,
);

const selectKitchenAreaState = createSelector(
  selectKitchenAreasProviderDomain,
  ({ kitchenAreaDetails: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectCreateKitchenAreaState = createSelector(
  selectKitchenAreasProviderDomain,
  ({ createKitchenArea: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateKitchenAreaState = createSelector(
  selectKitchenAreasProviderDomain,
  ({ updateKitchenArea: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectDisabledKitchenAreaState = createSelector(
  selectKitchenAreasProviderDomain,
  ({ disableKitchenArea: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectKitchenAreasPaginationState = createSelector(
  selectKitchenAreasProviderDomain,
  ({ kitchenAreasPagination }) => kitchenAreasPagination,
);

const selectKitchenAreasPaginationInfo = createSelector(
  selectKitchenAreasProviderDomain,
  ({ kitchenAreas }) => kitchenAreas.pagination,
);

export {
  selectKitchenAreasProviderDomain,
  selectKitchenAreas,
  selectKitchenAreasState,
  selectKitchenAreasPaginationState,
  selectKitchenArea,
  selectKitchenAreaState,
  selectCreateKitchenAreaState,
  selectUpdateKitchenAreaState,
  selectDisabledKitchenAreaState,
  selectKitchenAreasPaginationInfo,
};
