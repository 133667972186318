import React from 'react';
import PropTypes from 'prop-types';

import Layout, { ContentLayout } from 'Layout';
import ReportsTabsNavigation from 'components/ReportsTabsNavigation';

import { pathNeedsTab } from '../../utils/reports/paths';

function LayoutReport({ children, path }) {
  const hasTab = pathNeedsTab(path);
  if (hasTab) {
    return (
      <Layout>
        <ContentLayout>
          <ReportsTabsNavigation />
          <ContentLayout.Container pb="0" pl="0" pr="0">
            {children}
          </ContentLayout.Container>
        </ContentLayout>
      </Layout>
    );
  }
  return children;
}

LayoutReport.propTypes = {
  path: PropTypes.string,
  children: PropTypes.node,
};

export default LayoutReport;
