import { Children } from 'react';
import PropTypes from 'prop-types';

import OnlineOrderingProvider from 'providers/onlineOrdering/OnlineOrderingProvider';
import GoogleMapsProvider from 'providers/onlineOrdering/GoogleMapsProvider';
import PaymentsGatewayProvider from 'providers/onlineOrdering/PaymentsGatewayProvider';

import ComposeProviders from 'components/ComposeProviders';

function OnlineOrderingRootProvider({ children }) {
  return (
    <ComposeProviders providers={[<OnlineOrderingProvider />, <GoogleMapsProvider />, <PaymentsGatewayProvider />]}>
      {Children.only(children)}
    </ComposeProviders>
  );
}

OnlineOrderingRootProvider.propTypes = {
  children: PropTypes.element,
};

export default OnlineOrderingRootProvider;
