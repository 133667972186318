import capitalize from 'utils/capitalize';

import { ReactComponent as IconEdit } from 'images/svg/brand/icons/icn-edit.svg';
import { ReactComponent as IconExit } from 'images/svg/brand/icons/icn-exit.svg';
import { ReactComponent as IconSearch } from 'images/svg/brand/icons/icn-search.svg';
import { ReactComponent as IconRestaurant } from 'images/svg/brand/icons/icn-restaurant-select.svg';
import { ReactComponent as IconChevronDown } from 'images/svg/brand/icons/icn-chevron-down.svg';
import { ReactComponent as IconAddCircle } from 'images/svg/brand/icons/icn-add-circle.svg';
import { ReactComponent as IconBack } from 'images/svg/brand/icons/icn-back.svg';
import { ReactComponent as IconUser } from 'images/svg/brand/icons/icn-user.svg';
import { ReactComponent as IconDeleteOutline } from 'images/svg/brand/icons/icn-delete-outline.svg';
import { ReactComponent as IconArrowDownTable } from 'images/svg/brand/icons/icn-arrowDownTable.svg';
import { ReactComponent as IconArrowUpTable } from 'images/svg/brand/icons/icn-arrowUpTable.svg';
import { ReactComponent as IconExcel } from 'images/svg/brand/icons/icn-excel.svg';
import { ReactComponent as IconExcelDisabled } from 'images/svg/brand/icons/icn-excel-disabled.svg';
import { ReactComponent as IconExcelRed } from 'images/svg/brand/icons/icn-excel-red.svg';
import { ReactComponent as IconReportEmpty } from 'images/svg/brand/icons/icn-report-empty.svg';
import { ReactComponent as IconExternal } from 'images/svg/brand/icons/icn-external.svg';
import { ReactComponent as IconProductDetailsExternal } from 'images/svg/brand/icons/icn-product-details-external.svg';
import { ReactComponent as IconClose } from 'images/svg/brand/icons/icn-close.svg';
import { ReactComponent as IconDuplicateOutline } from 'images/svg/brand/icons/icn-duplicate-outline.svg';
import { ReactComponent as IconCopyOutline } from 'images/svg/brand/icons/icn-copy-outline.svg';
import { ReactComponent as IconMoreHorizontal } from 'images/svg/brand/icons/icn-more-horizontal.svg';
import { ReactComponent as IconDeleteRed } from 'images/svg/brand/icons/icn-delete-red.svg';
import { ReactComponent as IconSuccess } from 'images/svg/brand/icons/icn-success.svg';
import { ReactComponent as IconUpload } from 'images/svg/brand/icons/icn-upload.svg';
import { ReactComponent as IconCloudUpload } from 'images/svg/brand/icons/icn-cloud-upload.svg';
import { ReactComponent as IconDotRed } from 'images/svg/brand/icons/icn-dot-red.svg';
import { ReactComponent as IconPreviewOutline } from 'images/svg/brand/icons/icn-preview-outline.svg';
import { ReactComponent as IconPay } from 'images/svg/brand/icons/icn-pay.svg';
import { ReactComponent as IconInvoicing } from 'images/svg/brand/icons/icn-invoicing.svg';
import { ReactComponent as IconPaymentMethod } from 'images/svg/brand/icons/icn-paymentMethod.svg';
import { ReactComponent as IconPaymentsHistory } from 'images/svg/brand/icons/icn-paymentsHistory.svg';
import { ReactComponent as IconFolios } from 'images/svg/brand/icons/icn-folios.svg';
import { ReactComponent as IconMastercard } from 'images/svg/subscription/icons/icn-mastercard.svg';
import { ReactComponent as IconOxxo } from 'images/svg/subscription/icons/icn-oxxo.svg';
import { ReactComponent as IconSpei } from 'images/svg/subscription/icons/icn-spei.svg';
import { ReactComponent as IconVisa } from 'images/svg/subscription/icons/icn-visa.svg';
import { ReactComponent as IconAmex } from 'images/svg/subscription/icons/icn-amex.svg';
import { ReactComponent as IconDownload } from 'images/svg/brand/icons/icn-download.svg';
import { ReactComponent as IconPhone } from 'images/svg/brand/icons/icn-phone.svg';
import { ReactComponent as IconEmail } from 'images/svg/brand/icons/icn-email.svg';
import { ReactComponent as IconDiscover } from 'images/svg/subscription/icons/icn-discover.svg';
import { ReactComponent as IconDefaultCard } from 'images/svg/subscription/icons/icn-default-card.svg';
import { ReactComponent as IconDownloadCloud } from 'images/svg/brand/icons/icn-download-cloud.svg';
import { ReactComponent as IconConversionHelp1 } from 'images/svg/brand/icons/icn-conversion-help-1.svg';
import { ReactComponent as IconConversionHelp2 } from 'images/svg/brand/icons/icn-conversion-help-2.svg';
import { ReactComponent as IconConversionHelp3 } from 'images/svg/brand/icons/icn-conversion-help-3.svg';
import { ReactComponent as IconConversionHelp4 } from 'images/svg/brand/icons/icn-conversion-help-4.svg';
import { ReactComponent as IconConversionHelp5 } from 'images/svg/brand/icons/icn-conversion-help-5.svg';
import { ReactComponent as IconConversionHelp6 } from 'images/svg/brand/icons/icn-conversion-help-6.svg';
import { ReactComponent as IconEditV2 } from 'images/svg/actions/icons/icn-edit.svg';
import { ReactComponent as IconListV2 } from 'images/svg/actions/icons/icn-list.svg';
import { ReactComponent as IconDeleteV2 } from 'images/svg/actions/icons/icn-delete.svg';

const iconComponents = {
  addCircle: IconAddCircle,
  amex: IconAmex,
  arrowDownTable: IconArrowDownTable,
  arrowUpTable: IconArrowUpTable,
  back: IconBack,
  chevronDown: IconChevronDown,
  close: IconClose,
  cloudUpload: IconCloudUpload,
  copyOutline: IconCopyOutline,
  deleteOutline: IconDeleteOutline,
  deleteRed: IconDeleteRed,
  dotRed: IconDotRed,
  download: IconDownload,
  duplicateOutline: IconDuplicateOutline,
  email: IconEmail,
  excel: IconExcel,
  excelDisabled: IconExcelDisabled,
  excelRed: IconExcelRed,
  exit: IconExit,
  external: IconExternal,
  folios: IconFolios,
  invoicing: IconInvoicing,
  mastercard: IconMastercard,
  moreHorizontal: IconMoreHorizontal,
  oxxo: IconOxxo,
  pay: IconPay,
  paymentMethod: IconPaymentMethod,
  paymentsHistory: IconPaymentsHistory,
  phone: IconPhone,
  previewOutline: IconPreviewOutline,
  productDetailsExternal: IconProductDetailsExternal,
  reportEmpty: IconReportEmpty,
  restaurant: IconRestaurant,
  search: IconSearch,
  spei: IconSpei,
  success: IconSuccess,
  upload: IconUpload,
  user: IconUser,
  visa: IconVisa,
  discover: IconDiscover,
  defaultCard: IconDefaultCard,
  downloadCloud: IconDownloadCloud,
  conversionHelp1: IconConversionHelp1,
  conversionHelp2: IconConversionHelp2,
  conversionHelp3: IconConversionHelp3,
  conversionHelp4: IconConversionHelp4,
  conversionHelp5: IconConversionHelp5,
  conversionHelp6: IconConversionHelp6,
  edit: IconEdit,
  editV2: IconEditV2,
  listV2: IconListV2,
  deleteV2: IconDeleteV2,
};

function useIconParrot({ icon: iconName = '', icons: iconsNames = [] }) {
  let iconsNamesList = [];
  const IconComponent = iconComponents[iconName];

  iconsNamesList = iconsNames.reduce((obj, nameIcon) => {
    return {
      ...obj,
      [`Icon${capitalize(nameIcon)}`]: iconComponents[nameIcon],
    };
  }, {});

  return { [`Icon${capitalize(iconName)}`]: IconComponent, ...iconsNamesList };
}

export default useIconParrot;
