import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectReportsFiltersProviderDomain = (state) => state.reportsFiltersProvider || initialState;

const selectReportsFiltersFetching = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.fetching,
);

const selectReportsFiltersFetched = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.fetched,
);

const selectReportsFiltersError = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.error,
);
const selectReportsFiltersBrands = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.brands,
);

const selectReportsFiltersProviders = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.providers,
);

const selectReportsFiltersOrderTypes = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.orderTypes,
);

const selectReportsFiltersCategories = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.categories,
);

const selectReportsFiltersOrderStates = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.orderStates,
);

const selectReportsFiltersPaymentTypes = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.paymentTypes,
);

const selectReportsFiltersUsers = createSelector(
  selectReportsFiltersProviderDomain,
  (subState) => subState.getFilters.users,
);

export {
  selectReportsFiltersProviderDomain,
  selectReportsFiltersFetching,
  selectReportsFiltersFetched,
  selectReportsFiltersError,
  selectReportsFiltersBrands,
  selectReportsFiltersProviders,
  selectReportsFiltersOrderTypes,
  selectReportsFiltersCategories,
  selectReportsFiltersOrderStates,
  selectReportsFiltersPaymentTypes,
  selectReportsFiltersUsers,
};
