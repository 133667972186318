/**
 *
 * ParrotSoftware
 * SettingsAdminTablesProvider actions
 *
 */
import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/extendRoutines';

import {
  CREATE_TABLE,
  GET_TABLES,
  GET_TABLE_DETAILS,
  DELETE_TABLE,
  UPDATE_TABLE,
  CREATE_TABLES_BULK,
  VALIDATE_NAME,
  GET_TABLES_SCROLL_PAGINATION,
  UPDATE_TABLE_POSITION,
} from './constants';

export const createTable = extendRoutine(createRoutine(CREATE_TABLE), 'RESET');
export const createTablesBulk = extendRoutine(createRoutine(CREATE_TABLES_BULK), 'RESET');
export const getTables = extendRoutine(createRoutine(GET_TABLES), ['RESET', 'SORT_POSITION']);
export const deleteTable = extendRoutine(createRoutine(DELETE_TABLE), 'RESET');
export const getTableDetails = extendRoutine(createRoutine(GET_TABLE_DETAILS), 'RESET');
export const updateTable = extendRoutine(createRoutine(UPDATE_TABLE), 'RESET');
export const validateName = extendRoutine(createRoutine(VALIDATE_NAME), 'RESET');
export const getTablesScrollPagination = extendRoutine(createRoutine(GET_TABLES_SCROLL_PAGINATION), 'RESET');
export const updateTablePosition = extendRoutine(createRoutine(UPDATE_TABLE_POSITION), 'RESET');
