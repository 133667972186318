import React from 'react';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';

function PriceFormatOnlyNumbersField(props) {
  const { inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowLeadingZeros
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      getInputRef={inputRef}
    />
  );
}

PriceFormatOnlyNumbersField.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node]),
};

export default PriceFormatOnlyNumbersField;
