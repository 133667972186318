import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import { getProductsOptionsRequest } from 'utils/api/baas/listsOptions';

import {
  getProductsOptions,
  getProductsOptionsByPagination,
  getProductsOptionsByFilter,
  getProductsAutocomplete,
  getProductsAutocompleteByPagination,
  getProductsAutocompleteByFilter,
} from './actions';

export function* handleGetProductsOptionsRequest(action) {
  const filters = action.payload;

  try {
    yield put(getProductsOptions.request());

    const { data: products, pagination } = yield call(baasRequest, getProductsOptionsRequest, filters);

    yield put(getProductsOptions.success({ products, pagination }));
  } catch (err) {
    yield put(getProductsOptions.failure(err));
  }
}

export function* handleGetProductsOptionsByPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getProductsOptionsByPagination.request());

    const { data: products, pagination } = yield call(baasRequest, getProductsOptionsRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getProductsOptionsByPagination.success({ products, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getProductsOptionsByPagination.failure(err));
  }
}

export function* handleGetProductsOptionsByFilterRequest(action) {
  const filters = action.payload;

  try {
    yield put(getProductsOptionsByFilter.request());

    const { data: products, pagination } = yield call(baasRequest, getProductsOptionsRequest, filters);

    yield put(getProductsOptionsByFilter.success({ products, pagination }));
  } catch (err) {
    yield put(getProductsOptionsByFilter.failure(err));
  }
}

// autocomplete
export function* handleGetProductsAutocompleteRequest(action) {
  const filters = action.payload;

  try {
    yield put(getProductsAutocomplete.request());

    const { data: products, pagination } = yield call(baasRequest, getProductsOptionsRequest, filters);

    yield put(getProductsAutocomplete.success({ products, pagination }));
  } catch (err) {
    yield put(getProductsAutocomplete.failure(err));
  }
}

export function* handleGetProductsAutocompleteByPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getProductsAutocompleteByPagination.request());

    const { data: products, pagination } = yield call(baasRequest, getProductsOptionsRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getProductsAutocompleteByPagination.success({ products, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getProductsAutocompleteByPagination.failure(err));
  }
}

export function* handleGetProductsAutocompleteByFilterRequest(action) {
  const filters = action.payload;

  try {
    yield put(getProductsAutocompleteByFilter.request());

    const { data: products, pagination } = yield call(baasRequest, getProductsOptionsRequest, filters);

    yield put(getProductsAutocompleteByFilter.success({ products, pagination }));
  } catch (err) {
    yield put(getProductsAutocompleteByFilter.failure(err));
  }
}

export default function* listsOptionsSaga() {
  yield takeLatest(getProductsOptions.TRIGGER, handleGetProductsOptionsRequest);
  yield takeLatest(getProductsOptionsByPagination.TRIGGER, handleGetProductsOptionsByPaginationRequest);
  yield takeLatest(getProductsOptionsByFilter.TRIGGER, handleGetProductsOptionsByFilterRequest);
  yield takeLatest(getProductsAutocomplete.TRIGGER, handleGetProductsAutocompleteRequest);
  yield takeLatest(getProductsAutocompleteByPagination.TRIGGER, handleGetProductsAutocompleteByPaginationRequest);
  yield takeLatest(getProductsAutocompleteByFilter.TRIGGER, handleGetProductsAutocompleteByFilterRequest);
}
