import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';

function HelperSkeleton({ dragActive }) {
  const classes = useStyles();

  return (
    <>
      {[...Array(4).keys()].map((row) => (
        <Box key={`skeleton-table-resume-row-${row}`} className={classes.rootRow}>
          <Box className={classes.headerRow}>
            <Box className={classes.contentLeftRow}>
              {dragActive && <Skeleton height={24} variant="rect" width={24} />}
              <Box>
                <Box mb={1}>
                  <Skeleton height={40} variant="text" width={200} />
                </Box>
                <Box className={classes.wrapperTimesRow}>
                  {[...Array(2).keys()].map((time) => (
                    <Skeleton key={`skeleton-table-resume-row-time-${time}`} height={20} variant="text" width={120} />
                  ))}
                </Box>
              </Box>
            </Box>

            <Box className={classes.contentRightRow}>
              <Box className={classes.providersRow}>
                {[...Array(4).keys()].map((provider) => (
                  <Skeleton
                    key={`skeleton-table-resume-row-provider-${provider}`}
                    height={30}
                    variant="circle"
                    width={30}
                  />
                ))}
              </Box>
              <Skeleton height={24} variant="rect" width={6} />
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
}

HelperSkeleton.propTypes = {
  dragActive: PropTypes.bool,
};

export default HelperSkeleton;
