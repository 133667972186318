const ASC = 'asc';
const DESC = 'desc';

export const sortingTypes = {
  ASC,
  DESC,
};

export const getOrderBy = (index, columnSortingSelected, currentSortDirection) => {
  const isCurrentColumn = columnSortingSelected === index + 1;
  let selected = index + 1;
  let direction = null;
  if (!isCurrentColumn) direction = ASC;
  else if (isCurrentColumn && !currentSortDirection) {
    direction = ASC;
  } else if (isCurrentColumn && currentSortDirection === ASC) {
    direction = DESC;
    selected *= -1;
  } else if (isCurrentColumn && currentSortDirection === DESC) {
    direction = '';
    selected = null;
  }

  return {
    orderBy: selected,
    direction,
  };
};

export function setSortingPosition(items) {
  return items?.map((item, index) => ({
    ...item,
    sortingPosition: index,
  }));
}
