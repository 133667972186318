import cookies from 'js-cookie';

const ONE_DAY = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
const DEFAULT_EXPIRE_COOKIE = ONE_DAY;

export function setCookie<T>(
  name: string,
  data: T,
  options?: {
    expires: number | Date;
  },
) {
  cookies.set(name, JSON.stringify(data), {
    expires: options?.expires || DEFAULT_EXPIRE_COOKIE,
  });
}

export function getCookie<T>(name: string): T | null {
  try {
    const cookie = cookies.get(name);
    if (!cookie) {
      return null;
    }

    return JSON.parse(cookie) as T;
  } catch (error) {
    return null;
  }
}

export function removeCookie(name: string) {
  return cookies.remove(name);
}
