import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import Checkbox from '@design-system/Checkbox';
import SelectKitchenAreas from '@experimental-components/SelectKitchenAreas';
import Find from '@experimental-components/IconsComponents/Find';
import ButtonPlus from '@experimental-components/ButtonPlus';

import ProductsSort from 'components/ProductsSort';
import SkuMultiStore from 'components/SkuMultiStore';
import SelectColor from 'components/SelectColor';

import useEnvironment from 'hooks/useEnvironment';

import arrayToObjectUuid from 'utils/arrayToObjectUuid';
import { regexTypes, formatTextAllowedValues } from 'utils/formatField';
import { validateUppercase } from 'utils/menuMaker';

import CheckboxLabel from './CheckboxLabel';
import CategorySkeleton from './CategorySkeleton';

import useStyles from './styles';

function CategoryForm({
  focusInProducts,
  isEdition,
  isLoaded,
  isLoading,
  menuOfCategory,
  menusState,
  kitchenAreasState,
  onChangeProductsOrder,
  onSetStep,
  optionsSelected,
}) {
  const productsRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { register, errors, control, setValue, trigger, watch } = useFormContext();
  const watchFields = watch();
  const { environmentsState } = useEnvironment();
  const flagMenuColor = environmentsState.REACT_APP_FEATURE_MENU_COLOR === 'true';

  useEffect(() => {
    if (focusInProducts) {
      productsRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [productsRef.current, isLoaded]);

  const renderKitchenAreasSelectItem = (selectedState) => {
    if (selectedState?.length > 0) {
      return selectedState
        ?.filter((uuid) => arrayToObjectUuid(kitchenAreasState.list)[uuid]?.name)
        .map((uuid) => arrayToObjectUuid(kitchenAreasState.list)[uuid]?.name)
        .join(', ');
    }

    return (
      <Box color={theme.palette.darkGrey[50]} component="span">
        {t('categories:formCategory.selectKitchenArea')}
      </Box>
    );
  };

  const handleOpenSelectProducts = () => {
    onSetStep(2);
  };

  const handleChangeProductsOrder = (options) => {
    onChangeProductsOrder(options);
  };

  const handleValidateAllowedValues = (target, field) => {
    target.value = formatTextAllowedValues(target.value, regexTypes.REGEX_PRODUCT_NAME_ALLOWED);
    setValue(field, target.value);
    return target.value;
  };

  const handleOpenSelectSku = () => {
    onSetStep(3);
  };

  const renderTitle = () => {
    return (
      <Typography className={classes.title}>
        {t(`menuMaker:sections.${isEdition ? 'editCategory' : 'addCategory'}`)}
      </Typography>
    );
  };

  if (isLoading) {
    return (
      <CategorySkeleton isEdition={isEdition}>
        <CategorySkeleton.Title>{renderTitle()}</CategorySkeleton.Title>
      </CategorySkeleton>
    );
  }

  if (isLoaded) {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>{renderTitle()}</Grid>

        {isEdition && (
          <Grid item>
            <Typography className={classes.grayBox}>{t('categories:messages.editionInfo')}</Typography>
          </Grid>
        )}

        <Grid item>
          <InputAdornmentFiled
            autoComplete="off"
            autoFocus
            data-testid="inputNameCategory"
            endAdornment={
              <InputAdornment position="end">
                <Box color="#CECDD1" fontSize="14px">
                  {watch('nameCategoryField')?.length ?? 0}/30
                </Box>
              </InputAdornment>
            }
            error={Boolean(errors?.nameCategoryField)}
            errorMsg={errors?.nameCategoryField?.message}
            fullWidth
            id="nameCategoryField"
            inputProps={{ maxLength: 30, minLength: 1, 'data-testid': 'nameCategory' }}
            inputRef={register({
              required: t('categories:formCategory.fieldsErrors.requireCategory'),
              maxLength: { value: 30, message: t('common:errors.maxLengthField', { chars: 30 }) },
              minLength: 1,
              validate: {
                controlUppercase: (value) => validateUppercase(value) || t('common:errors.controlUppercase'),
              },
            })}
            label={t('categories:formCategory.inputCategory')}
            name="nameCategoryField"
            onChange={({ target }) => handleValidateAllowedValues(target, 'inputNameCategory')}
          />
        </Grid>

        <Grid item>
          <Typography className={classes.subtitles}>{t('categories:formCategory.titleSelectMenus')}</Typography>

          <Box>
            <Typography color="textSecondary" variant="body2">
              {t('categories:formCategory.labelIncludeCategoryMenus')}
            </Typography>
          </Box>

          <Box mt={3}>
            {menusState.list.length > 0 ? (
              <FormControl component="fieldset" fullWidth>
                <FormGroup className={classes.formMenus}>
                  {menusState.list?.map((menu) => (
                    <CheckboxLabel
                      key={menu?.uuid}
                      data-testid={menu?.uuid}
                      defaultChecked={Boolean(watchFields.menusField && watchFields.menusField[menu?.uuid])}
                      disabled={menuOfCategory === menu?.uuid}
                      inputRef={register()}
                      label={menu?.name}
                      name={`menusField[${menu?.uuid}]`}
                      value={menu?.uuid}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : (
              <Box className={classes.label} width="100%">
                {t('common:messages.emptyInformation')}
              </Box>
            )}
          </Box>

          <Box mb={1} mt={2}>
            <Divider />
          </Box>
        </Grid>

        <Grid item>
          <Typography className={classes.subtitles}>{t('categories:formCategory.kitchenAreasTitle')}</Typography>

          <Box mb={3}>
            <Typography color="textSecondary" variant="body2">
              {t('categories:formCategory.addKitchenAreaToCategory')}
            </Typography>
          </Box>

          {kitchenAreasState.list?.length > 0 ? (
            <Controller
              control={control}
              name="kitchenAreasFields"
              render={({ onChange, value, name, ref }) => (
                <SelectKitchenAreas
                  displayEmpty
                  inputRef={ref}
                  isFullWidth
                  multiple
                  name={name}
                  onChange={onChange}
                  renderValue={(selected) => renderKitchenAreasSelectItem(selected)}
                  value={value}
                >
                  {kitchenAreasState.list?.map((kitchen) => (
                    <MenuItem key={kitchen?.uuid} value={kitchen?.uuid}>
                      <Checkbox checked={value?.indexOf(kitchen?.uuid) > -1} />
                      <ListItemText primary={kitchen?.name} />
                    </MenuItem>
                  ))}
                </SelectKitchenAreas>
              )}
              rules={{ required: true }}
            />
          ) : (
            <Box alignItems="left" display="flex" flexDirection="column" mb={5} mt={5}>
              <Box textAlign="center" width="100%">
                <Find />
                <Box className={classes.label} mt={2} width="100%">
                  {t('common:messages.emptyInformation')}
                </Box>
              </Box>
            </Box>
          )}

          <Box mb={1} mt={4}>
            <Divider />
          </Box>
        </Grid>

        <Grid item>
          <Typography className={classes.subtitles}>{t('categories:formCategory.articles')}</Typography>

          <Box mb={3}>
            <Typography color="textSecondary" variant="body2">
              {t('categories:formCategory.describeSortArticles')}
            </Typography>
          </Box>

          {optionsSelected?.length > 0 && (
            <Box mb={3}>
              <ProductsSort
                isValidateSku
                messageError={t('menuMaker:skuLabels.category.errorDuplicateProductsSku')}
                onChange={handleChangeProductsOrder}
                products={optionsSelected}
              />
            </Box>
          )}
          <Box ref={productsRef}>
            <ButtonPlus data-testid="btnAddArticles" onClick={handleOpenSelectProducts}>
              {t('menuMaker:buttons.addProduct')}
            </ButtonPlus>
          </Box>
          <Box mt={5}>
            <Divider />
          </Box>
        </Grid>

        <Grid className={clsx({ [classes.disableSection]: !flagMenuColor })} item>
          <Controller
            control={control}
            name="colorField"
            render={({ onChange, value }) => <SelectColor entity="category" onChange={onChange} value={value} />}
            rules={{ required: true }}
          />
          <Box mt={5}>
            <Divider />
          </Box>
        </Grid>

        <Grid item>
          <Typography className={classes.subtitles}>{t('menuMaker:skuLabels.key')}</Typography>

          <Box mb={4} mt={1}>
            <Typography color="textSecondary" variant="body2">
              {t('menuMaker:skuLabels.category.info')}
            </Typography>
          </Box>

          <SkuMultiStore
            errors={errors}
            onClickButton={handleOpenSelectSku}
            prefix="CA-"
            register={register}
            trigger={trigger}
            watch={watch}
          />
        </Grid>
      </Grid>
    );
  }

  return null;
}

CategoryForm.propTypes = {
  focusInProducts: PropTypes.bool,
  isEdition: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  menuOfCategory: PropTypes.string,
  menusState: PropTypes.object,
  kitchenAreasState: PropTypes.object,
  onSetStep: PropTypes.func,
};

export default CategoryForm;
