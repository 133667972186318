export const paginationConfigDefaults = {
  pageSize: 50,
  page: 1,
};

const PROPERTY_NAME = 'reason';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  TITLE: PROPERTY_NAME,
};

const FILTER_PROPERTIES = [PROPERTY_NAME];

export function getFilteredVoidReasonsByText(voidReasons, sorting) {
  return voidReasons.filter(
    (voidReason) =>
      FILTER_PROPERTIES.filter((property) =>
        voidReason[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}

const ORDER = 'ORDER';
const ORDER_ITEM = 'ORDER_ITEM';
const ORDER_PAYMENT = 'ORDER_PAYMENT';
const SERVICE_CHARGE = 'SERVICE_CHARGE';

export const voidReasonsTypes = [
  {
    value: ORDER,
    label: 'restaurant:voidReasons.types.order',
  },
  {
    value: ORDER_ITEM,
    label: 'restaurant:voidReasons.types.orderItem',
  },
  {
    value: ORDER_PAYMENT,
    label: 'restaurant:voidReasons.types.orderPayment',
  },
  {
    value: SERVICE_CHARGE,
    label: 'restaurant:voidReasons.types.serviceCharge',
  },
];
