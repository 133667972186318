const MONDAY = 'MONDAY';
const TUESDAY = 'TUESDAY';
const WEDNESDAY = 'WEDNESDAY';
const THURSDAY = 'THURSDAY';
const FRIDAY = 'FRIDAY';
const SATURDAY = 'SATURDAY';
const SUNDAY = 'SUNDAY';

const HOUR_FORMAT_AM = 'AM';
const HOUR_FORMAT_PM = 'PM';

export const formatHourTypes = {
  HOUR_FORMAT_AM,
  HOUR_FORMAT_PM,
};

export const daysTypes = {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
};

export const daysIndex = {
  0: [MONDAY],
  1: [TUESDAY],
  2: [WEDNESDAY],
  3: [THURSDAY],
  4: [FRIDAY],
  5: [SATURDAY],
  6: [SUNDAY],
};

export const daysLabels = {
  [MONDAY]: 'common:daysLabels.MONDAY',
  [TUESDAY]: 'common:daysLabels.TUESDAY',
  [WEDNESDAY]: 'common:daysLabels.WEDNESDAY',
  [THURSDAY]: 'common:daysLabels.THURSDAY',
  [FRIDAY]: 'common:daysLabels.FRIDAY',
  [SATURDAY]: 'common:daysLabels.SATURDAY',
  [SUNDAY]: 'common:daysLabels.SUNDAY',
};

export const daysOptions = [
  { value: MONDAY, label: daysLabels[MONDAY] },
  { value: TUESDAY, label: daysLabels[TUESDAY] },
  { value: WEDNESDAY, label: daysLabels[WEDNESDAY] },
  { value: THURSDAY, label: daysLabels[THURSDAY] },
  { value: FRIDAY, label: daysLabels[FRIDAY] },
  { value: SATURDAY, label: daysLabels[SATURDAY] },
  { value: SUNDAY, label: daysLabels[SUNDAY] },
];

export const indexOfDay = {
  [MONDAY]: 0,
  [TUESDAY]: 1,
  [WEDNESDAY]: 2,
  [THURSDAY]: 3,
  [FRIDAY]: 4,
  [SATURDAY]: 5,
  [SUNDAY]: 6,
};

export const shortDaysLabels = {
  0: 'common:daysShort.monday',
  1: 'common:daysShort.tuesday',
  2: 'common:daysShort.wednesday',
  3: 'common:daysShort.thursday',
  4: 'common:daysShort.friday',
  5: 'common:daysShort.saturday',
  6: 'common:daysShort.sunday',
};
