import PropTypes from 'prop-types';

import Header from './Header';
import TabLink from './TabLink';
import Container from './Container';
import TabsNavigation from './TabsNavigation';

function ContentLayout({ children }) {
  return <>{children}</>;
}

ContentLayout.Header = Header;
ContentLayout.TabsNavigation = TabsNavigation;
ContentLayout.TabLink = TabLink;
ContentLayout.Container = Container;

ContentLayout.propTypes = {
  children: PropTypes.node,
};

export default ContentLayout;
