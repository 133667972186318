import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

function StepperStartText({ children }) {
  return (
    <Box color="#FFFFFF" fontSize="1.5rem" fontWeight={400} maxWidth="20rem">
      {children}
    </Box>
  );
}

StepperStartText.propTypes = {
  children: PropTypes.node,
};

export default StepperStartText;
