import { useMemo } from 'react';

import { Redirect } from 'react-router-dom';
import getDisplayName from 'utils/getDisplayName';

import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';
import { useActiveLicense } from 'containers/Subscription/hooks';

export default function withRequiredLicense() {
  return (Component) => (props) => {
    const { data: license } = useActiveLicense();

    const isRedirectToSubscriptionPath = useMemo(() => {
      return (
        license?.status === subscriptionStatusTypes.UNAVAILABLE ||
        license?.status === subscriptionStatusTypes.PAYMENT_REQUIRED
      );
    }, [license?.status]);

    if (isRedirectToSubscriptionPath) {
      return <Redirect to="/subscription" />;
    }

    Component.displayName = `withRequiredLicense(${getDisplayName(Component)})`;
    return <Component {...props} />;
  };
}
