/**
 *
 * ParrotSoftware
 * OnlineOrderingProvider actions
 *
 */
import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/extendRoutines';

import {
  GET_ONLINE_ORDERING,
  GET_ONLINE_ORDERING_PAGINATION,
  UPDATE_CAN_ORDERING,
  GET_BRAND_SETTINGS,
  UPDATE_BRAND_SETTINGS,
  GET_BRAND_CONTACT_INFORMATION,
  UPDATE_CONTACT_INFORMATION,
  GET_OPERATIONS_SETTINGS,
  UPDATE_OPERATIONS_SETTINGS,
  GET_DELIVERY_ZONES,
  UPDATE_DELIVERY_ZONES,
  VALIDATE_DIRECTION_URL,
  GET_RESUMES_MENUS,
  SAVE_ONBOARDING,
  CREATE_DELIVERY_ZONES,
  DELETE_DELIVERY_ZONES,
  PUBLISH_BRAND,
} from './constants';

export const getOnlineOrdering = extendRoutine(createRoutine(GET_ONLINE_ORDERING), 'RESET');
export const getOnlineOrderingPagination = extendRoutine(createRoutine(GET_ONLINE_ORDERING_PAGINATION), 'RESET');
export const updateCanOrdering = extendRoutine(createRoutine(UPDATE_CAN_ORDERING), 'RESET');
export const getBrandSettings = extendRoutine(createRoutine(GET_BRAND_SETTINGS), 'RESET');
export const updateBrandSettings = extendRoutine(createRoutine(UPDATE_BRAND_SETTINGS), 'RESET');
export const getBrandContactInformation = extendRoutine(createRoutine(GET_BRAND_CONTACT_INFORMATION), 'RESET');
export const updateContactInformation = extendRoutine(createRoutine(UPDATE_CONTACT_INFORMATION), 'RESET');
export const getOperationsSettings = extendRoutine(createRoutine(GET_OPERATIONS_SETTINGS), 'RESET');
export const updateOperationsSettings = extendRoutine(createRoutine(UPDATE_OPERATIONS_SETTINGS), 'RESET');
export const getDeliveryZones = extendRoutine(createRoutine(GET_DELIVERY_ZONES), 'RESET');
export const updateDeliveryZones = extendRoutine(createRoutine(UPDATE_DELIVERY_ZONES), 'RESET');
export const validateDirectionUrl = extendRoutine(createRoutine(VALIDATE_DIRECTION_URL), 'RESET');
export const getResumesMenus = extendRoutine(createRoutine(GET_RESUMES_MENUS), 'RESET');
export const saveOnboarding = extendRoutine(createRoutine(SAVE_ONBOARDING), 'RESET');
export const createDeliveryZones = extendRoutine(createRoutine(CREATE_DELIVERY_ZONES), 'RESET');
export const deleteDeliveryZones = extendRoutine(createRoutine(DELETE_DELIVERY_ZONES), 'RESET');
export const publishBrand = extendRoutine(createRoutine(PUBLISH_BRAND), 'RESET');
