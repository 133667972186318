import React from 'react';
import PropTypes from 'prop-types';

function ComposeProviders({ providers = [], children }) {
  return providers.reduceRight((child, provider) => React.cloneElement(provider, {}, child), children);
}

ComposeProviders.propTypes = {
  providers: PropTypes.array,
  children: PropTypes.node,
};

export default ComposeProviders;
