import produce from 'immer';

import baseState from 'utils/baseState';
import { updateDefault } from 'utils/settingsTaxes/utils';

import { getMultiTaxes, updateDefaultTax } from './actions';

export const initialState = {
  multiTaxes: {
    ...baseState,
    taxes: [],
  },
  updateDefaultTax: {
    ...baseState,
  },
};

const multiTaxesProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getMultiTaxes.REQUEST:
        draft.multiTaxes = {
          ...initialState.multiTaxes,
          fetching: true,
        };
        break;
      case getMultiTaxes.SUCCESS:
        draft.multiTaxes.fetching = false;
        draft.multiTaxes.fetched = true;
        draft.multiTaxes.taxes = payload;
        break;

      case getMultiTaxes.FAILURE:
        draft.multiTaxes.error = payload;
        draft.multiTaxes.fetching = false;
        draft.multiTaxes.fetched = false;
        break;

      case getMultiTaxes.RESET:
        draft.multiTaxes = {
          ...initialState.multiTaxes,
        };
        break;

      case updateDefaultTax.REQUEST:
        draft.updateDefaultTax = {
          ...initialState.updateDefaultTax,
          fetching: true,
        };
        break;
      case updateDefaultTax.SUCCESS:
        draft.updateDefaultTax.fetching = false;
        draft.updateDefaultTax.fetched = true;
        draft.multiTaxes.taxes = updateDefault(payload?.tax?.uuid, draft.multiTaxes.taxes);
        break;

      case updateDefaultTax.FAILURE:
        draft.updateDefaultTax.error = payload;
        draft.updateDefaultTax.fetching = false;
        draft.updateDefaultTax.fetched = false;
        break;

      case updateDefaultTax.RESET:
        draft.updateDefaultTax = {
          ...initialState.updateDefaultTax,
        };
        break;

      default:
        return draft;
    }
  });

export default multiTaxesProviderReducer;
