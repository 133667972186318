import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_BRANDS_RECEIPT_CUSTOMIZATION,
  GET_BRAND_RECEIPT_CUSTOMIZATION,
  UPDATE_BRAND_RECEIPT_CUSTOMIZATION,
} from './constants';

export const getBrandsReceiptCustomization = extendRoutine(createRoutine(GET_BRANDS_RECEIPT_CUSTOMIZATION), 'RESET');

export const getBrandReceiptCustomization = extendRoutine(createRoutine(GET_BRAND_RECEIPT_CUSTOMIZATION), 'RESET');

export const updateBrandReceiptCustomization = extendRoutine(
  createRoutine(UPDATE_BRAND_RECEIPT_CUSTOMIZATION),
  'RESET',
);
