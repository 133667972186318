import { Switch } from 'react-router-dom';

import { useFeature } from 'FeatureProvider';

import useEnvironment from 'hooks/useEnvironment';
import useUserStore from 'hooks/useUserStore';

import OnlineOrdering from 'containers/OnlineOrdering/Loadable';

import AppRoute from '../PrivateRoute';

export function OnlineOrderingRoutes() {
  const { environmentsState } = useEnvironment();
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();
  const isActiveModuleOnlineOrdering = useFeature('@module.onlineOrdering');
  const flagEnableOnlineOrdering =
    environmentsState.REACT_APP_FEATURE_ONLINE_ORDERING_ENABLED === 'true' && !storeSelected?.isDraft;

  return (
    <Switch>
      <AppRoute
        accessRoute={isActiveModuleOnlineOrdering && flagEnableOnlineOrdering}
        component={OnlineOrdering}
        exact
        path="/online-ordering"
      />

      <AppRoute
        accessRoute={isActiveModuleOnlineOrdering && flagEnableOnlineOrdering}
        component={OnlineOrdering}
        exact
        path="/online-ordering/:type(brand-settings|data-settings|operation-settings|zone-settings|payments-gateway)?/:key?"
      />
    </Switch>
  );
}
