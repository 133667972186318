import produce from 'immer';

import {
  getPortalConfigList,
  savePortalConfig,
  portalConfigDetails,
  portalConfigUpdate,
  invoicingConfigList,
  invoicingConfigCreate,
  invoicingGetBrandsNotConfigured,
  getInvoicingConfigDetails,
  updateInvoicingConfig,
  deleteInvoicingConfig,
  enableInvoicingAuto,
} from './actions';

const baseState = {
  error: null,
  fetching: false,
  fetched: false,
};

export const initialState = {
  getPortalConfigList: {
    ...baseState,
    list: [],
  },
  savePortalConfig: {
    ...baseState,
  },

  portalConfigDetails: {
    ...baseState,
    portal: {},
  },

  portalConfigUpdate: {
    ...baseState,
    portal: {},
  },

  invoicingConfigList: {
    ...baseState,
    list: [],
  },

  invoicingConfigCreate: {
    ...baseState,
    invoicing: {},
  },

  getInvoicingConfigDetails: {
    ...baseState,
    invoicing: {},
  },

  invoicingGetBrandsNotConfigured: {
    ...baseState,
    brands: [],
  },

  updateInvoicingConfig: {
    ...baseState,
    invoicing: {},
  },

  deleteInvoicingConfig: {
    ...baseState,
  },
  enableInvoicingAuto: {
    ...baseState,
  },
};

const invoicingProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getPortalConfigList.REQUEST:
        draft.getPortalConfigList = {
          ...initialState.getPortalConfigList,
          fetching: true,
        };
        break;

      case getPortalConfigList.SUCCESS:
        draft.getPortalConfigList.fetching = false;
        draft.getPortalConfigList.fetched = true;
        draft.getPortalConfigList.list = payload.data;
        break;

      case getPortalConfigList.FAILURE:
        draft.getPortalConfigList.error = payload;
        draft.getPortalConfigList.fetching = false;
        draft.getPortalConfigList.fetched = false;
        break;

      case getPortalConfigList.RESET:
        draft.getPortalConfigList = {
          ...initialState.getPortalConfigList,
        };
        break;

      case savePortalConfig.REQUEST:
        draft.savePortalConfig = {
          ...initialState.savePortalConfig,
          fetching: true,
        };
        break;

      case savePortalConfig.SUCCESS:
        draft.savePortalConfig.fetching = false;
        draft.savePortalConfig.fetched = true;
        draft.savePortalConfig.list = payload.data;
        break;

      case savePortalConfig.FAILURE:
        draft.savePortalConfig.error = payload;
        draft.savePortalConfig.fetching = false;
        draft.savePortalConfig.fetched = false;
        break;

      case savePortalConfig.RESET:
        draft.savePortalConfig = {
          ...initialState.savePortalConfig,
        };
        break;

      case portalConfigDetails.REQUEST:
        draft.portalConfigDetails = {
          ...initialState.portalConfigDetails,
          fetching: true,
        };
        break;

      case portalConfigDetails.SUCCESS:
        draft.portalConfigDetails.fetching = false;
        draft.portalConfigDetails.fetched = true;
        draft.portalConfigDetails.portal = payload.data;
        break;

      case portalConfigDetails.FAILURE:
        draft.portalConfigDetails.error = payload;
        draft.portalConfigDetails.fetching = false;
        draft.portalConfigDetails.fetched = false;
        break;

      case portalConfigDetails.RESET:
        draft.portalConfigDetails = {
          ...initialState.portalConfigDetails,
        };
        break;

      case portalConfigUpdate.REQUEST:
        draft.portalConfigUpdate = {
          ...initialState.portalConfigUpdate,
          fetching: true,
        };
        break;

      case portalConfigUpdate.SUCCESS:
        draft.portalConfigUpdate.fetching = false;
        draft.portalConfigUpdate.fetched = true;
        draft.portalConfigUpdate.portal = payload.data;
        break;

      case portalConfigUpdate.FAILURE:
        draft.portalConfigUpdate.error = payload;
        draft.portalConfigUpdate.fetching = false;
        draft.portalConfigUpdate.fetched = false;
        break;

      case portalConfigUpdate.RESET:
        draft.portalConfigUpdate = {
          ...initialState.portalConfigUpdate,
        };
        break;

      case invoicingConfigList.REQUEST:
        draft.invoicingConfigList = {
          ...initialState.invoicingConfigList,
          fetching: true,
        };
        break;

      case invoicingConfigList.SUCCESS:
        draft.invoicingConfigList.fetching = false;
        draft.invoicingConfigList.fetched = true;
        draft.invoicingConfigList.list = payload.data;
        break;

      case invoicingConfigList.FAILURE:
        draft.invoicingConfigList.error = payload;
        draft.invoicingConfigList.fetching = false;
        draft.invoicingConfigList.fetched = false;
        break;

      case invoicingConfigList.RESET:
        draft.invoicingConfigList = {
          ...initialState.invoicingConfigList,
        };
        break;

      case invoicingConfigCreate.REQUEST:
        draft.invoicingConfigCreate = {
          ...initialState.invoicingConfigCreate,
          fetching: true,
        };
        break;

      case invoicingConfigCreate.SUCCESS:
        draft.invoicingConfigCreate.fetching = false;
        draft.invoicingConfigCreate.fetched = true;
        draft.invoicingConfigCreate.invoicing = payload.data;
        break;

      case invoicingConfigCreate.FAILURE:
        draft.invoicingConfigCreate.error = payload;
        draft.invoicingConfigCreate.fetching = false;
        draft.invoicingConfigCreate.fetched = false;
        break;

      case invoicingConfigCreate.RESET:
        draft.invoicingConfigCreate = {
          ...initialState.invoicingConfigCreate,
        };
        break;

      case invoicingGetBrandsNotConfigured.REQUEST:
        draft.invoicingGetBrandsNotConfigured = {
          ...initialState.invoicingGetBrandsNotConfigured,
          fetching: true,
        };
        break;

      case invoicingGetBrandsNotConfigured.SUCCESS:
        draft.invoicingGetBrandsNotConfigured.fetching = false;
        draft.invoicingGetBrandsNotConfigured.fetched = true;
        draft.invoicingGetBrandsNotConfigured.brands = payload.data;
        break;

      case invoicingGetBrandsNotConfigured.FAILURE:
        draft.invoicingGetBrandsNotConfigured.error = payload;
        draft.invoicingGetBrandsNotConfigured.fetching = false;
        draft.invoicingGetBrandsNotConfigured.fetched = false;
        break;

      case invoicingGetBrandsNotConfigured.RESET:
        draft.invoicingGetBrandsNotConfigured = {
          ...initialState.invoicingGetBrandsNotConfigured,
        };
        break;

      case getInvoicingConfigDetails.REQUEST:
        draft.getInvoicingConfigDetails = {
          ...initialState.getInvoicingConfigDetails,
          fetching: true,
        };
        break;

      case getInvoicingConfigDetails.SUCCESS:
        draft.getInvoicingConfigDetails.fetching = false;
        draft.getInvoicingConfigDetails.fetched = true;
        draft.getInvoicingConfigDetails.invoicing = payload.data;
        break;

      case getInvoicingConfigDetails.FAILURE:
        draft.getInvoicingConfigDetails.error = payload;
        draft.getInvoicingConfigDetails.fetching = false;
        draft.getInvoicingConfigDetails.fetched = false;
        break;

      case getInvoicingConfigDetails.RESET:
        draft.getInvoicingConfigDetails = {
          ...initialState.getInvoicingConfigDetails,
        };
        break;

      case updateInvoicingConfig.REQUEST:
        draft.updateInvoicingConfig = {
          ...initialState.updateInvoicingConfig,
          fetching: true,
        };
        break;

      case updateInvoicingConfig.SUCCESS:
        draft.updateInvoicingConfig.fetching = false;
        draft.updateInvoicingConfig.fetched = true;
        draft.updateInvoicingConfig.invoicing = payload.data;
        break;

      case updateInvoicingConfig.FAILURE:
        draft.updateInvoicingConfig.error = payload;
        draft.updateInvoicingConfig.fetching = false;
        draft.updateInvoicingConfig.fetched = false;
        break;

      case updateInvoicingConfig.RESET:
        draft.updateInvoicingConfig = {
          ...initialState.updateInvoicingConfig,
        };
        break;

      case deleteInvoicingConfig.REQUEST:
        draft.deleteInvoicingConfig = {
          ...initialState.deleteInvoicingConfig,
          fetching: true,
        };
        break;

      case deleteInvoicingConfig.SUCCESS: {
        const invoicingConfigListCurrent = draft.invoicingConfigList.list.filter((item) => item.uuid !== payload?.uuid);

        draft.deleteInvoicingConfig.fetching = false;
        draft.deleteInvoicingConfig.fetched = true;
        draft.invoicingConfigList.list = invoicingConfigListCurrent;
        break;
      }

      case deleteInvoicingConfig.FAILURE:
        draft.deleteInvoicingConfig.error = payload;
        draft.deleteInvoicingConfig.fetching = false;
        draft.deleteInvoicingConfig.fetched = false;
        break;

      case deleteInvoicingConfig.RESET:
        draft.deleteInvoicingConfig = {
          ...initialState.deleteInvoicingConfig,
        };
        break;

      // Enable/Disabled auto invoicing
      case enableInvoicingAuto.REQUEST:
        draft.enableInvoicingAuto = {
          ...initialState.enableInvoicingAuto,
          fetching: true,
        };
        break;
      case enableInvoicingAuto.SUCCESS: {
        const indexPortal = draft.getPortalConfigList.list.findIndex((portal) => {
          return portal.uuid === payload.uuid;
        });
        draft.enableInvoicingAuto.fetching = false;
        draft.enableInvoicingAuto.fetched = true;
        draft.getPortalConfigList.list[indexPortal].isEnabled = payload.isEnabled;
        break;
      }

      case enableInvoicingAuto.FAILURE:
        draft.enableInvoicingAuto.error = payload;
        draft.enableInvoicingAuto.fetching = false;
        draft.enableInvoicingAuto.fetched = false;
        break;

      case enableInvoicingAuto.RESET:
        draft.enableInvoicingAuto = {
          ...initialState.enableInvoicingAuto,
        };
        break;

      default:
        return draft;
    }
  });

export default invoicingProviderReducer;
