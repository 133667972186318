const CONFIG = 'CONFIG';
const STORES = 'STORES';
const MENUS = 'MENUS';
const WARNING = 'WARNING';
const RESUME = 'RESUME';

export const stepsTypes = {
  CONFIG,
  STORES,
  MENUS,
  WARNING,
  RESUME,
};

export const stepsLabels = {
  [CONFIG]: {
    title: 'menuMaker:copyMenu.steps.config.title',
    text: 'menuMaker:copyMenu.steps.config.description',
    labelButtonNext: 'common:buttons.next',
  },

  [STORES]: {
    title: 'menuMaker:copyMenu.steps.stores.title',
    text: 'menuMaker:copyMenu.steps.stores.description',
    labelButtonNext: 'common:buttons.next',
  },

  [MENUS]: {
    title: 'menuMaker:copyMenu.steps.menus.title',
    text: 'menuMaker:copyMenu.steps.menus.description',
    labelButtonNext: 'common:buttons.next',
  },

  [WARNING]: {
    title: 'menuMaker:copyMenu.steps.warning.title',
    text: 'menuMaker:copyMenu.steps.warning.description',
    labelButtonNext: 'common:buttons.continue',
  },

  [RESUME]: {
    title: 'menuMaker:copyMenu.steps.resume.title',
    text: 'menuMaker:copyMenu.steps.resume.description',
    textSync: 'menuMaker:copyMenu.steps.resume.descriptionSync',
    labelButtonNext: 'common:buttons.copy',
    labelButtonNextSync: 'common:buttons.sync',
  },
};

export const warningsListLabels = [
  'menuMaker:copyMenu.steps.warning.items.firstItem',
  'menuMaker:copyMenu.steps.warning.items.secondItem',
  'menuMaker:copyMenu.steps.warning.items.thirdItem',
  'menuMaker:copyMenu.steps.warning.items.fourthItem',
];

const COPY_MENU = 'COPY_MENU';
const SYNC_COPY = 'SYNC_COPY';

export const copyModes = {
  COPY_MENU,
  SYNC_COPY,
};

export function filterDataSelection(elementsSelected, stores, copyMode) {
  const storesSelected = [];

  if (copyMode === copyModes.SYNC_COPY) {
    stores.forEach((store) => {
      const brandsSelected = [];

      store?.brands?.forEach((brand) => {
        const menusSelected = brand?.menus?.filter((menu) => elementsSelected.includes(menu.uuid));
        if (menusSelected.length) brandsSelected.push({ ...brand, menus: menusSelected });
      });

      if (brandsSelected?.length) storesSelected.push({ ...store, brands: brandsSelected });
    });
  } else {
    stores.forEach((store) => {
      const brandsSelected = store?.brands?.filter((brand) => elementsSelected.includes(brand.uuid));
      if (brandsSelected?.length) storesSelected.push({ ...store, brands: brandsSelected });
    });
  }

  return storesSelected;
}

export function excludeStoreSelected(storeUuidSelected, stores) {
  return stores?.filter((store) => store?.uuid !== storeUuidSelected);
}

export function filterMenusWithSameSku(menuSkuOrigin, stores) {
  const storesFiltered = [];

  stores?.forEach((store) => {
    const brandsFiltered = [];

    store?.brands?.forEach((brand) => {
      const menusFiltered = brand?.menus?.filter((menu) => menu.sku === menuSkuOrigin);
      if (menusFiltered?.length) brandsFiltered.push({ ...brand, menus: menusFiltered });
    });

    if (brandsFiltered?.length) storesFiltered.push({ ...store, brands: brandsFiltered });
  });

  return storesFiltered;
}
