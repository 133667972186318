/**
 *
 * ParrotSoftware
 * GoogleMapsProvider selectors
 *
 */
import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectGoogleMapsProviderDomain = (state) => state.googleMapsProvider || initialState;

const selectGeocodingLookupLatitudeLongitude = createSelector(
  selectGoogleMapsProviderDomain,
  ({ geocodingLookupLatitudeLongitude: { geometry } }) => geometry,
);

const selectGeocodingLookupLatitudeLongitudeState = createSelector(
  selectGoogleMapsProviderDomain,
  ({ geocodingLookupLatitudeLongitude: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

export {
  selectGoogleMapsProviderDomain,
  selectGeocodingLookupLatitudeLongitude,
  selectGeocodingLookupLatitudeLongitudeState,
};
