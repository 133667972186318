import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}

const Check = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => {
  const { color = '#FFF', size = 24 } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 16 12"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M15.8017 0.199761C16.0637 0.468559 16.0664 0.907196 15.8078 1.17948L5.72444 11.7939C5.59917 11.9258 5.4283 12 5.25 12C5.0717 12 4.90083 11.9258 4.77556 11.7939L0.192231 6.96918C-0.0664337 6.69689 -0.0637104 6.25825 0.198313 5.98945C0.460337 5.72065 0.882438 5.72348 1.1411 5.99577L5.25 10.3211L14.8589 0.206082C15.1176 -0.0662063 15.5397 -0.0690363 15.8017 0.199761Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default Check;
