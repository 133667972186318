import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  CANCEL_INVOICING,
  DOWNLOAD_INVOICES,
  GET_REPORTS_GENERATED,
  GET_REPORTS_GENERATED_PAGINATION,
} from './constants';

export const cancelInvoicing = extendRoutine(createRoutine(CANCEL_INVOICING), 'RESET');
export const downloadInvoices = extendRoutine(createRoutine(DOWNLOAD_INVOICES), 'RESET');
export const getReportsGenerated = extendRoutine(createRoutine(GET_REPORTS_GENERATED), 'RESET');
export const getReportsGeneratedPagination = extendRoutine(createRoutine(GET_REPORTS_GENERATED_PAGINATION), 'RESET');
