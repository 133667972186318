import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import { validateDuplicateSkuItems } from 'utils/menuMaker';

export const DEFAULT_SIZE_PAGE = 10;
export const DEFAULT_PAGE = 1;

const PROPERTY_NAME = 'name';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  NAME: PROPERTY_NAME,
};

const CUSTOMIZABLE = 'CUSTOMIZABLE';
const PRESENTATION = 'PRESENTATION';
const QUESTION = 'QUESTION';

export const modifierTypes = { CUSTOMIZABLE, PRESENTATION, QUESTION };
export const modifierTypesList = [CUSTOMIZABLE, PRESENTATION, QUESTION];

export const defaultModifierGroup = {
  modifierTypeField: '',
  nameModifierGroupField: '',
  noteModifierGroupField: '',
  forceSelectionField: true,
  ruleOptionMin: 1,
  ruleOptionMax: 1,
  ruleOptionRepeated: 1,
  skuFieldMultiStore: '',
  modifierOptionsListField: [],
};

export const modifierGroupIsValid = (formState, modifierOptions = [], optionsFields = []) => {
  let optionsIsValid = false;

  if (formState.isValid) {
    optionsIsValid = modifierOptions.length >= 1 && !validateDuplicateSkuItems(optionsFields)?.length;
  }

  return formState.isValid && optionsIsValid;
};

export const modifierFormIsDirty = (
  initModifierGroup,
  modifierGroupForm,
  optionsFields,
  excludeTypeModifier = false,
) => {
  if (modifierGroupForm.modifierTypeField === '') return false;

  const initModifierGroupTmp = {
    ...initModifierGroup,
    modifierOptionsListField: initModifierGroup.modifierOptionsListField?.map(({ name, additionalPrice }) => ({
      name,
      additionalPrice: parseFloat(additionalPrice),
    })),
    products: sortBy(
      initModifierGroup.products?.map(({ name, uuid }) => ({ name, uuid })),
      'name',
    ),
  };

  const modifierOptionsFields = optionsFields.map((field, index) => ({
    ...field,
    ...modifierGroupForm?.modifierOptionsListField?.at(index),
  }));

  const modifierGroupFormTmp = {
    ...modifierGroupForm,
    modifierOptionsListField: modifierOptionsFields?.map(({ name, additionalPrice }) => ({
      name,
      additionalPrice: parseFloat(additionalPrice),
    })),
    products: sortBy(
      modifierGroupForm.products?.map(({ name, uuid }) => ({ name, uuid })),
      'name',
    ),
  };

  modifierGroupFormTmp.ruleOptionMin = parseInt(modifierGroupForm.ruleOptionMin, 10);
  modifierGroupFormTmp.ruleOptionMax = parseInt(modifierGroupForm.ruleOptionMax, 10);
  modifierGroupFormTmp.ruleOptionRepeated = parseInt(modifierGroupForm.ruleOptionRepeated, 10);

  if (excludeTypeModifier) {
    delete initModifierGroupTmp?.modifierTypeField;
    delete modifierGroupFormTmp?.modifierTypeField;
  }

  const isDirty = !isEqual(initModifierGroupTmp, modifierGroupFormTmp);

  return isDirty;
};

export const defaultOptions = {
  [CUSTOMIZABLE]: [],
  [PRESENTATION]: [
    { name: 'Chico', additionalPrice: 0, error: '', sku: '' },
    { name: 'Mediano', additionalPrice: 0, error: '', sku: '' },
  ],
  [QUESTION]: [
    { name: 'Si, por favor', additionalPrice: 0, error: '', sku: '' },
    { name: 'No, gracias', additionalPrice: 0, error: '', sku: '' },
  ],
};
