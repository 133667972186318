import { all, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getProductsForStoreRequest, getCategoriesForStoreRequest } from 'utils/api/baas/menuMaker';

import { getProducts, getProductsScrollPagination } from '../actions';

export function* handleLoadProductsRequest({ payload }) {
  try {
    yield put(getProducts.request());
    const filters = payload;

    const result = yield all([
      call(baasRequest, getProductsForStoreRequest, filters),
      call(baasRequest, getCategoriesForStoreRequest),
    ]);

    const [productsResponse, categoriesResponse] = result;

    const paginationCurrent = { ...productsResponse?.pagination };

    yield put(
      getProducts.success({
        products: productsResponse.data,
        pagination: paginationCurrent,
        categories: categoriesResponse.data,
        isSorting: filters?.isSorting,
      }),
    );
  } catch (err) {
    yield put(getProducts.failure(err));
  }
}

export function* getProductsScrollPaginationSaga({ payload }) {
  const filters = payload;

  try {
    yield put(getProductsScrollPagination.request());

    const { data, pagination } = yield call(baasRequest, getProductsForStoreRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(
      getProductsScrollPagination.success({
        products: data,
        pagination: paginationCurrent,
      }),
    );
  } catch (err) {
    yield put(getProductsScrollPagination.failure(err));
  }
}
