import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getBrands } from './actions';
import { selectBrands, selectBrandsState } from './selectors';

function useBrands() {
  const dispatch = useDispatch();

  const brands = useSelector(selectBrands);
  const brandsState = useSelector(selectBrandsState);

  useEffect(() => {
    function init() {
      dispatch(getBrands());
    }
    init();
  }, [dispatch]);

  const handleResetBrands = useCallback(() => {
    dispatch(getBrands.reset());
  }, [dispatch]);

  return {
    brandsState,
    brands,
    resetBrands: handleResetBrands,
  };
}

export default useBrands;
