import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  getDiscountsRequest,
  getDiscountDetailRequest,
  createDiscountRequest,
  deleteDiscountRequest,
  setIsEnabledDiscountRequest,
} from 'utils/api/baas/restaurant/discounts';

import {
  getDiscounts,
  getDiscountsPagination,
  getDiscountDetail,
  createDiscount,
  deleteDiscount,
  setIsEnabledDiscount,
} from './actions';

export function* handleGetDiscountsRequest(action) {
  const storeUuid = action.payload;

  try {
    yield put(getDiscounts.request());

    const { data: discounts, pagination } = yield call(baasRequest, getDiscountsRequest, storeUuid);

    yield put(getDiscounts.success({ discounts, pagination }));
  } catch (err) {
    yield put(getDiscounts.failure(err));
  } finally {
    yield put(getDiscounts.fulfill());
  }
}

export function* handleGetDiscountsPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getDiscountsPagination.request());

    const { data, pagination } = yield call(baasRequest, getDiscountsRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };
    yield put(getDiscountsPagination.success({ data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getDiscountsPagination.failure(err));
  }
}

export function* handleGetDiscountDetailRequest(action) {
  const { storeUuid, discountUuid } = action.payload;

  try {
    yield put(getDiscountDetail.request());

    const { data } = yield call(baasRequest, getDiscountDetailRequest, { storeUuid, discountUuid });

    yield put(getDiscountDetail.success(data));
  } catch (err) {
    yield put(getDiscountDetail.failure(err));
  } finally {
    yield put(getDiscountDetail.fulfill());
  }
}

export function* handleCreateDiscountRequest(action) {
  const { storeUuid, title, discountType, amountType, description, amount, isRestricted, scope } = action.payload;

  try {
    yield put(createDiscount.request());

    const { data } = yield call(baasRequest, createDiscountRequest, {
      storeUuid,
      title,
      discountType,
      amountType,
      description,
      amount,
      isRestricted,
      scope,
    });

    yield put(createDiscount.success(data));
  } catch (err) {
    yield put(createDiscount.failure(err));
  } finally {
    yield put(createDiscount.fulfill());
  }
}

export function* handleDeleteDiscountsRequest(action) {
  const { storeUuid, discountUuid } = action.payload;

  try {
    yield put(deleteDiscount.request());

    yield call(baasRequest, deleteDiscountRequest, {
      storeUuid,
      discountUuid,
    });

    yield put(deleteDiscount.success(discountUuid));
  } catch (err) {
    yield put(deleteDiscount.failure(err));
  } finally {
    yield put(deleteDiscount.fulfill());
  }
}

export function* handleSetIsEnabledDiscountRequest(action) {
  const { storeUuid, discountUuid, isEnabled } = action.payload;

  try {
    yield put(setIsEnabledDiscount.request());

    const { data } = yield call(baasRequest, setIsEnabledDiscountRequest, {
      storeUuid,
      discountUuid,
      isEnabled,
    });

    yield put(setIsEnabledDiscount.success(data));
  } catch (err) {
    yield put(setIsEnabledDiscount.failure(err));
  } finally {
    yield put(setIsEnabledDiscount.fulfill());
  }
}

export default function* restaurantDiscountsProviderSaga() {
  yield takeLatest(getDiscounts.TRIGGER, handleGetDiscountsRequest);
  yield takeLatest(getDiscountsPagination.TRIGGER, handleGetDiscountsPaginationRequest);
  yield takeLatest(getDiscountDetail.TRIGGER, handleGetDiscountDetailRequest);
  yield takeLatest(createDiscount.TRIGGER, handleCreateDiscountRequest);
  yield takeLatest(deleteDiscount.TRIGGER, handleDeleteDiscountsRequest);
  yield takeLatest(setIsEnabledDiscount.TRIGGER, handleSetIsEnabledDiscountRequest);
}
