export const GET_PAYMENTS = 'providers/settings/Payments/GET_PAYMENTS';

export const GET_PAYMENT_DETAIL = 'providers/settings/Payments/GET_PAYMENT_DETAIL';

export const CREATE_PAYMENT = 'providers/settings/Payments/CREATE_PAYMENT';

export const DELETE_PAYMENT = 'providers/settings/Payments/DELETE_PAYMENT';

export const TOGGLE_AVAILABILITY_PAYMENT = 'providers/settings/Payments/TOGGLE_AVAILABILITY_PAYMENT';

export const EDIT_PAYMENT = 'providers/settings/Payments/EDIT_PAYMENT';

export const UPDATE_PAYMENT_POSITION = 'providers/settings/Payments/UPDATE_PAYMENT_POSITION';

export const GET_PAYMENTS_SCROLL_PAGINATION = 'providers/settings/Payments/GET_PAYMENTS_SCROLL_PAGINATION';
