import { useQuery, useQueryClient, useMutation } from 'react-query';

import useNotification from '@design-system/Notification/useNotification';
import { useTranslation } from 'react-i18next';

import { getSettings, updateSetting, UpdateSettingsProps } from './services';

interface ErrorsProps {
  code: string;
  message: string;
}

interface DataErrorProps {
  data: {
    errors: ErrorsProps[];
  };
}

interface ResponseErrorProps {
  response: DataErrorProps;
}

export function useSettingTimeClock() {
  const queryClient = useQueryClient();
  const { setNotification } = useNotification();
  const { t } = useTranslation('settingsClockTime');
  const { data, isError, isFetched, isFetching } = useQuery('settings-tinme-clock', () => getSettings());
  const mutationUpdate = useMutation((params: UpdateSettingsProps) => updateSetting(params));

  async function updateSettings(params: UpdateSettingsProps) {
    mutationUpdate.mutate(params, {
      onSuccess: () => {
        setNotification(t('settingsClockTime:messages.success', { variant: 'success' }));
      },
      onError: (resp: unknown) => {
        const { response } = resp as ResponseErrorProps;
        let errorMsg = t('settingsClockTime:messages.error');
        const { errors } = response.data;
        if (errors.length === 1) {
          const [error] = errors;
          errorMsg = error?.message || errorMsg;
        }
        setNotification(errorMsg, { variant: 'error' });
      },
    });
  }

  return {
    settingsState: {
      error: isError,
      fetched: isFetched,
      fetching: isFetching,
    },
    updateSettingState: {
      error: mutationUpdate.isError,
      fetched: !mutationUpdate.isLoading && (mutationUpdate.isSuccess || mutationUpdate.isError),
      fetching: mutationUpdate.isLoading,
    },
    resetSettings: () => queryClient.invalidateQueries(['settings-tinme-clock']),
    updateSettings,
    settings: data?.data?.timeClock || { isEnabled: false, isEnabledAuthPin: false },
  };
}
