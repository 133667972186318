import produce from 'immer';

import baseState from 'utils/baseState';
import { pageSizeTypes } from 'utils/pagination';

import {
  getInvoicesOrdersEdit,
  getInvoicesOrdersEditByPagination,
  getInvoicesOrdersPreEdit,
  updateInvoicesOrders,
  resetEditedInvoicesOrders,
  generateInvoicing,
  getInvoicesOrderDetailsEdit,
  updateOrderRemoveItem,
  updateOrderDiscount,
  revertChangesOrderEdited,
  saveChangesOrderEdited,
} from './actions';

export const initialState = {
  getInvoicesOrdersEdit: {
    ...baseState,
    invoices: {},
    pagination: {
      page: 1,
      page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
      count: 0,
      next: null,
      previous: null,
    },
  },

  loadPaginationStatus: {
    ...baseState,
    lastUpdatedRows: {},
  },

  getInvoicesOrdersPreEdit: {
    ...baseState,
    previewData: {},
  },

  updateInvoicesOrders: {
    ...baseState,
  },

  resetEditedInvoicesOrders: {
    ...baseState,
  },

  generateInvoicing: {
    ...baseState,
    data: null,
  },

  getInvoicesOrderDetailsEdit: {
    ...baseState,
    order: {},
  },

  updateOrderRemoveItem: {
    ...baseState,
    orderUpdated: {},
  },
  updateOrderDiscount: {
    ...baseState,
    orderUpdated: {},
  },

  revertChangesOrderEdited: {
    ...baseState,
  },

  saveChangesOrderEdited: {
    ...baseState,
  },
};

const invoicesTicketsEditorProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getInvoicesOrdersEdit.REQUEST:
        draft.getInvoicesOrdersEdit = {
          ...initialState.getInvoicesOrdersEdit,
          fetching: true,
        };
        break;

      case getInvoicesOrdersEdit.SUCCESS:
        draft.getInvoicesOrdersEdit.fetching = false;
        draft.getInvoicesOrdersEdit.fetched = true;
        draft.getInvoicesOrdersEdit.invoices = payload.data;
        draft.getInvoicesOrdersEdit.pagination = {
          ...initialState.getInvoicesOrdersEdit.pagination,
          ...payload.pagination,
        };
        break;

      case getInvoicesOrdersEdit.FAILURE:
        draft.getInvoicesOrdersEdit.error = payload;
        draft.getInvoicesOrdersEdit.fetching = false;
        draft.getInvoicesOrdersEdit.fetched = false;
        break;

      case getInvoicesOrdersEdit.RESET:
        draft.getInvoicesOrdersEdit = {
          ...initialState.getInvoicesOrdersEdit,
        };
        break;

      case getInvoicesOrdersEditByPagination.REQUEST:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          fetching: true,
        };
        break;

      case getInvoicesOrdersEditByPagination.SUCCESS: {
        const currentInvoicesState = state.getInvoicesOrdersEdit.invoices?.rows;
        const newRowsPayload = payload?.data?.rows;

        let newsRows = [...currentInvoicesState];
        newsRows = [...newsRows, ...newRowsPayload];

        draft.loadPaginationStatus.fetching = false;
        draft.loadPaginationStatus.fetched = true;
        draft.loadPaginationStatus.error = null;
        draft.loadPaginationStatus.lastUpdatedRows = newRowsPayload;

        draft.getInvoicesOrdersEdit.invoices.rows = [...newsRows];
        draft.getInvoicesOrdersEdit.pagination = {
          ...initialState.getInvoicesOrdersEdit.pagination,
          ...payload?.pagination,
          page: payload?.page,
        };

        break;
      }

      case getInvoicesOrdersEditByPagination.FAILURE:
        draft.loadPaginationStatus.error = payload;
        draft.loadPaginationStatus.fetching = false;
        draft.loadPaginationStatus.fetched = false;
        break;

      case getInvoicesOrdersEditByPagination.RESET:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
        };
        break;

      case getInvoicesOrdersPreEdit.REQUEST:
        draft.getInvoicesOrdersPreEdit = {
          ...initialState.getInvoicesOrdersPreEdit,
          fetching: true,
        };
        break;

      case getInvoicesOrdersPreEdit.SUCCESS:
        draft.getInvoicesOrdersPreEdit.fetching = false;
        draft.getInvoicesOrdersPreEdit.fetched = true;
        draft.getInvoicesOrdersPreEdit.previewData = payload;
        break;
      case getInvoicesOrdersPreEdit.FAILURE:
        draft.getInvoicesOrdersPreEdit.error = payload;
        draft.getInvoicesOrdersPreEdit.fetching = false;
        draft.getInvoicesOrdersPreEdit.fetched = false;
        break;

      case getInvoicesOrdersPreEdit.RESET:
        draft.getInvoicesOrdersPreEdit = {
          ...initialState.getInvoicesOrdersPreEdit,
        };
        break;

      case updateInvoicesOrders.REQUEST:
        draft.updateInvoicesOrders = {
          ...initialState.updateInvoicesOrders,
          fetching: true,
        };
        break;

      case updateInvoicesOrders.SUCCESS:
        draft.updateInvoicesOrders.fetching = false;
        draft.updateInvoicesOrders.fetched = true;
        break;
      case updateInvoicesOrders.FAILURE:
        draft.updateInvoicesOrders.error = payload;
        draft.updateInvoicesOrders.fetching = false;
        draft.updateInvoicesOrders.fetched = false;
        break;

      case updateInvoicesOrders.RESET:
        draft.updateInvoicesOrders = {
          ...initialState.updateInvoicesOrders,
        };
        break;

      case resetEditedInvoicesOrders.REQUEST:
        draft.resetEditedInvoicesOrders = {
          ...initialState.resetEditedInvoicesOrders,
          fetching: true,
        };
        break;

      case resetEditedInvoicesOrders.SUCCESS:
        draft.resetEditedInvoicesOrders.fetching = false;
        draft.resetEditedInvoicesOrders.fetched = true;
        break;
      case resetEditedInvoicesOrders.FAILURE:
        draft.resetEditedInvoicesOrders.error = payload;
        draft.resetEditedInvoicesOrders.fetching = false;
        draft.resetEditedInvoicesOrders.fetched = false;
        break;

      case resetEditedInvoicesOrders.RESET:
        draft.resetEditedInvoicesOrders = {
          ...initialState.resetEditedInvoicesOrders,
        };
        break;

      case generateInvoicing.REQUEST:
        draft.generateInvoicing = {
          ...initialState.generateInvoicing,
          fetching: true,
        };
        break;

      case generateInvoicing.SUCCESS: {
        draft.generateInvoicing.fetching = false;
        draft.generateInvoicing.fetched = true;
        draft.generateInvoicing.data = payload?.data;

        break;
      }

      case generateInvoicing.FAILURE:
        draft.generateInvoicing.error = payload;
        draft.generateInvoicing.fetching = false;
        draft.generateInvoicing.fetched = false;
        break;

      case generateInvoicing.RESET:
        draft.generateInvoicing = {
          ...initialState.generateInvoicing,
        };
        break;

      case getInvoicesOrderDetailsEdit.REQUEST:
        draft.getInvoicesOrderDetailsEdit = {
          ...initialState.getInvoicesOrderDetailsEdit,
          fetching: true,
        };
        break;

      case getInvoicesOrderDetailsEdit.SUCCESS: {
        draft.getInvoicesOrderDetailsEdit.fetching = false;
        draft.getInvoicesOrderDetailsEdit.fetched = true;
        draft.getInvoicesOrderDetailsEdit.order = payload?.order;

        break;
      }

      case getInvoicesOrderDetailsEdit.FAILURE:
        draft.getInvoicesOrderDetailsEdit.error = payload;
        draft.getInvoicesOrderDetailsEdit.fetching = false;
        draft.getInvoicesOrderDetailsEdit.fetched = false;
        break;

      case getInvoicesOrderDetailsEdit.RESET:
        draft.getInvoicesOrderDetailsEdit = {
          ...initialState.getInvoicesOrderDetailsEdit,
        };
        break;

      case updateOrderRemoveItem.REQUEST:
        draft.updateOrderRemoveItem = {
          ...initialState.updateOrderRemoveItem,
          fetching: true,
        };
        break;

      case updateOrderRemoveItem.SUCCESS: {
        const currentOrderEditState = state.getInvoicesOrderDetailsEdit.order;
        const updatesOrderEdit = payload?.orderUpdated;

        let changesOrderEdit = { ...currentOrderEditState };
        changesOrderEdit = { ...changesOrderEdit, ...updatesOrderEdit };

        draft.updateOrderRemoveItem.fetching = false;
        draft.updateOrderRemoveItem.fetched = true;
        draft.updateOrderRemoveItem.orderUpdated = payload?.orderUpdated;
        draft.getInvoicesOrderDetailsEdit.order = changesOrderEdit;

        break;
      }

      case updateOrderRemoveItem.FAILURE:
        draft.updateOrderRemoveItem.error = payload;
        draft.updateOrderRemoveItem.fetching = false;
        draft.updateOrderRemoveItem.fetched = false;
        break;

      case updateOrderRemoveItem.RESET:
        draft.updateOrderRemoveItem = {
          ...initialState.updateOrderRemoveItem,
        };
        break;

      case updateOrderDiscount.REQUEST:
        draft.updateOrderDiscount = {
          ...initialState.updateOrderDiscount,
          fetching: true,
        };
        break;

      case updateOrderDiscount.SUCCESS: {
        const currentOrderEditState = state.getInvoicesOrderDetailsEdit.order;
        const updatesOrderEdit = payload?.orderUpdated;

        let changesOrderEdit = { ...currentOrderEditState };
        changesOrderEdit = { ...changesOrderEdit, ...updatesOrderEdit };

        draft.updateOrderDiscount.fetching = false;
        draft.updateOrderDiscount.fetched = true;
        draft.updateOrderDiscount.orderUpdated = payload?.orderUpdated;
        draft.getInvoicesOrderDetailsEdit.order = changesOrderEdit;

        break;
      }

      case updateOrderDiscount.FAILURE:
        draft.updateOrderDiscount.error = payload;
        draft.updateOrderDiscount.fetching = false;
        draft.updateOrderDiscount.fetched = false;
        break;

      case updateOrderDiscount.RESET:
        draft.updateOrderDiscount = {
          ...initialState.updateOrderDiscount,
        };
        break;

      case revertChangesOrderEdited.REQUEST:
        draft.revertChangesOrderEdited = {
          ...initialState.revertChangesOrderEdited,
          fetching: true,
        };
        break;

      case revertChangesOrderEdited.SUCCESS:
        draft.revertChangesOrderEdited.fetching = false;
        draft.revertChangesOrderEdited.fetched = true;

        break;

      case revertChangesOrderEdited.FAILURE:
        draft.revertChangesOrderEdited.error = payload;
        draft.revertChangesOrderEdited.fetching = false;
        draft.revertChangesOrderEdited.fetched = false;
        break;

      case revertChangesOrderEdited.RESET:
        draft.revertChangesOrderEdited = {
          ...initialState.revertChangesOrderEdited,
        };
        break;

      case saveChangesOrderEdited.REQUEST:
        draft.saveChangesOrderEdited = {
          ...initialState.saveChangesOrderEdited,
          fetching: true,
        };
        break;

      case saveChangesOrderEdited.SUCCESS:
        draft.saveChangesOrderEdited.fetching = false;
        draft.saveChangesOrderEdited.fetched = true;
        break;

      case saveChangesOrderEdited.FAILURE:
        draft.saveChangesOrderEdited.error = payload;
        draft.saveChangesOrderEdited.fetching = false;
        draft.saveChangesOrderEdited.fetched = false;
        break;

      case saveChangesOrderEdited.RESET:
        draft.saveChangesOrderEdited = {
          ...initialState.saveChangesOrderEdited,
        };
        break;

      default:
        return draft;
    }
  });

export default invoicesTicketsEditorProviderReducer;
