import { useRef } from 'react';

export function useDebounce(delay: number) {
  const timeRef = useRef<ReturnType<typeof setTimeout>>();

  const startDebounce = (callBack: () => void, customDelay?: number) => {
    if (timeRef.current) clearTimeout(timeRef.current);
    timeRef.current = setTimeout(callBack, customDelay || delay);
  };

  return {
    startDebounce,
  };
}
