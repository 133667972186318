import { useSelector, useDispatch } from 'react-redux';

import {
  selectClientData,
  selectClientDataState,
  selectClientDetails,
  selectClientDetailsState,
  selectPaginationClientsState,
  selectExportClientsReportDocument,
  selectExportClientsReportState,
  selectPaginationDetailsState,
} from './selectors';
import * as actions from './actions';

function useClientData() {
  const dispatch = useDispatch();
  const clientData = useSelector(selectClientData);
  const clientDataState = useSelector(selectClientDataState);
  const clientDetails = useSelector(selectClientDetails);
  const clientDetailsState = useSelector(selectClientDetailsState);
  const paginationClientsState = useSelector(selectPaginationClientsState);
  const exportClientsReportDocument = useSelector(selectExportClientsReportDocument);
  const exportClientsReportState = useSelector(selectExportClientsReportState);
  const paginationDetailsState = useSelector(selectPaginationDetailsState);

  return {
    getClientData: (values) => dispatch(actions.getClientData(values)),
    getClientDetails: (values) => dispatch(actions.getClientDetails(values)),
    paginateClients: (values) => dispatch(actions.getPaginationClients(values)),
    paginateDetails: (values) => dispatch(actions.getPaginationDetails(values)),
    exportClientsReport: (values) => dispatch(actions.exportClientsReport(values)),
    resetGetClientData: () => dispatch(actions.getClientData.reset()),
    resetGetClientDetails: () => dispatch(actions.getClientDetails.reset()),
    resetExportClientsReport: () => dispatch(actions.exportClientsReport.reset()),
    clientData,
    clientDataState,
    clientDetails,
    clientDetailsState,
    paginationClientsState,
    exportClientsReportDocument,
    exportClientsReportState,
    paginationDetailsState,
  };
}

export default useClientData;
