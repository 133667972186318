import { getUserCurrentStore } from 'utils/auth';

import { settingsApi } from 'utils/api/baas/settings/axiosBase';

export async function getSettings() {
  const store = getUserCurrentStore()?.uuid;
  const response = await settingsApi.get(`/v1/stores/settings/?store_uuid=${store}`);
  return response.data;
}

export interface UpdateSettingsProps {
  isEnabledAuthPin: boolean;
}

export async function updateSetting({ isEnabledAuthPin }: UpdateSettingsProps) {
  const store = getUserCurrentStore()?.uuid;
  const oParams = {
    namespace: 'TIME_CLOCK',
    settings: {
      isEnabledAuthPin,
    },
  };

  const response = await settingsApi.put(`/v1/stores/settings/?store_uuid=${store}`, [oParams]);
  return response.data;
}
