import React from 'react';

import Box, { BoxProps } from '@material-ui/core/Box';

import useStyles from './styles';

interface Props extends BoxProps {
  children: React.ReactNode;
}

function ContentHours({ children, ...others }: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.wrapperHours} {...others}>
      {children}
    </Box>
  );
}

export default ContentHours;
