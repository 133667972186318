import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const LogOutOutline = forwardRef(function LogOutOutline(props, ref) {
  const { color = '#000', size = 18, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 18 18"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M17.9641 9.18132C17.9409 9.23719 17.9067 9.28953 17.8613 9.33495L13.1244 14.0718C12.9394 14.2568 12.6395 14.2568 12.4545 14.0718C12.2695 13.8868 12.2695 13.5869 12.4545 13.4019L16.3827 9.47368L6.15789 9.47368C5.89629 9.47368 5.68421 9.26161 5.68421 9C5.68421 8.73839 5.89629 8.52632 6.15789 8.52632L16.3827 8.52632L12.4545 4.5981C12.2695 4.41312 12.2695 4.1132 12.4545 3.92821C12.6395 3.74323 12.9394 3.74323 13.1244 3.92821L17.8607 8.66452M17.9641 9.18132C17.9872 9.12547 18 9.06423 18 9C18 8.87234 17.9495 8.75648 17.8674 8.67129M6.63158 0.473684C6.63158 0.735292 6.4195 0.947368 6.1579 0.947368L2.36842 0.947368C1.99154 0.947368 1.63008 1.09709 1.36359 1.36358C1.09709 1.63008 0.947369 1.99153 0.947369 2.36842L0.947368 15.6316C0.947368 16.0085 1.09709 16.3699 1.36359 16.6364C1.63008 16.9029 1.99153 17.0526 2.36842 17.0526L6.15789 17.0526C6.4195 17.0526 6.63158 17.2647 6.63158 17.5263C6.63158 17.7879 6.4195 18 6.15789 18L2.36842 18C1.74028 18 1.13786 17.7505 0.693694 17.3063C0.24953 16.8621 -7.10735e-07 16.2597 -6.83278e-07 15.6316L-1.03527e-07 2.36842C-7.60699e-08 1.74028 0.249531 1.13786 0.693695 0.693694C1.13786 0.249529 1.74028 -7.10735e-07 2.36842 -6.83278e-07L6.1579 -5.17635e-07C6.4195 -5.062e-07 6.63158 0.212075 6.63158 0.473684Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

LogOutOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LogOutOutline;
