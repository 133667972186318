import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  twoCols: {
    display: 'flex',
    columnGap: theme.spacing(1),
    justifyContent: 'space-between',
  },
  buttonRoot: {
    width: theme.typography.pxToRem(170),
    paddingInline: theme.spacing(1),
  },
}));

export default useStyles;
