import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const UploadOutline = forwardRef(function UploadOutline(props, ref) {
  const { size = 32, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <rect fill="#F2F2F4" height={size} rx="4" width={size} y="6.10352e-05" />
      <path
        clipRule="evenodd"
        d="M6 24.2501L26 24.2501L26 25.7501L6 25.7501L6 24.2501Z"
        fill="#47465F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.803 8.35236L16.803 21.258L15.303 21.258L15.303 8.35236L16.803 8.35236Z"
        fill="#47465F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.8972 8.70844L11.0304 13.5752L9.96978 12.5146L15.8972 6.58712L21.8246 12.5146L20.764 13.5752L15.8972 8.70844Z"
        fill="#47465F"
        fillRule="evenodd"
      />
    </svg>
  );
});

UploadOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UploadOutline;
