import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectReportsOrdersProviderDomain = (state) => state.reportsOrders || initialState;

const selectReportsOrdersSummaries = createSelector(
  selectReportsOrdersProviderDomain,
  (subState) => subState.getListReportsOrders.summaries,
);

const selectReportsOrdersHeaders = createSelector(
  selectReportsOrdersProviderDomain,
  (subState) => subState.getListReportsOrders.headers,
);

const selectReportsOrdersOrders = createSelector(
  selectReportsOrdersProviderDomain,
  (subState) => subState.getListReportsOrders.orders,
);

const selectReportsOrdersPaginationInfo = createSelector(
  selectReportsOrdersProviderDomain,
  (subState) => subState.getListReportsOrders.pagination,
);

const selectReportsOrdersPaginationScrollState = createSelector(
  selectReportsOrdersProviderDomain,
  (subState) => subState.paginationReportsOrders,
);

const selectReportsOrdersState = createSelector(
  selectReportsOrdersProviderDomain,
  ({ getListReportsOrders: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectDetailsReportsOrdersState = createSelector(
  selectReportsOrdersProviderDomain,
  ({ detailsReportsOrders: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectDetailsReportsOrder = createSelector(
  selectReportsOrdersProviderDomain,
  (subState) => subState.detailsReportsOrders.order,
);

const selectExportReportsOrdersState = createSelector(
  selectReportsOrdersProviderDomain,
  ({ exportReportsOrders: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectExportReportsOrdersDocument = createSelector(
  selectReportsOrdersProviderDomain,
  (subState) => subState.exportReportsOrders.document,
);

export {
  selectReportsOrdersProviderDomain,
  selectReportsOrdersSummaries,
  selectReportsOrdersHeaders,
  selectReportsOrdersOrders,
  selectReportsOrdersState,
  selectExportReportsOrdersDocument,
  selectExportReportsOrdersState,
  selectReportsOrdersPaginationInfo,
  selectReportsOrdersPaginationScrollState,
  selectDetailsReportsOrder,
  selectDetailsReportsOrdersState,
};
