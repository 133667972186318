import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import permissionsSaga from './sagas';
import reducer from './reducer';

export function Permissions({ children }) {
  useInjectSaga({ key: 'permissions', saga: permissionsSaga });
  useInjectReducer({ key: 'permissions', reducer });

  return React.Children.only(children);
}

Permissions.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withPermissions } from './withPermissions';
export default compose(withConnect, memo)(Permissions);
