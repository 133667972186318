import { api } from 'config';
import { Response } from 'types';

import { PostValidateEmailResponse, User } from './types';

interface PostValidateEmail {
  email: string;
}
export async function postValidateEmail({ email }: PostValidateEmail) {
  const response = await api.post<PostValidateEmailResponse>('/v1/users/validate-email/', {
    email,
  });
  return response.data;
}

interface GetUser {
  userUuid?: string;
  storeUuid?: string;
}

export async function getUser({ userUuid, storeUuid }: GetUser) {
  if (!userUuid || !storeUuid) return null;
  const response = await api.get<Response<User>>(`v1/stores/${storeUuid}/users/${userUuid}`);
  return response.data.data;
}
