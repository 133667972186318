import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';

function Title({ children }) {
  return children;
}

function ProductSkeleton({ children }) {
  const classes = useStyles();

  return (
    <Grid container data-testid="productSkeleton" direction="column" spacing={2}>
      <Grid item>{children}</Grid>

      <Grid item>
        <Box className={classes.skeletonToggle}>
          <Skeleton height={48} variant="rect" width={80} />
          <Skeleton height={48} variant="rect" width={80} />
        </Box>
      </Grid>

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={70} variant="text" width="100%" />
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.skeletonCols}>
          <Box>
            <Skeleton height={20} variant="text" width={150} />
            <Skeleton height={170} variant="rect" width="100%" />
          </Box>
          <Box>
            <Skeleton height={20} variant="text" width={150} />
            <Skeleton height={170} variant="rect" width="100%" />
          </Box>
        </Box>
      </Grid>

      <Grid item>
        <Skeleton height={25} varaint="text" width={350} />
        <Box className={classes.skeletonToggle} mt={1}>
          <Skeleton height={48} variant="rect" width={90} />
          <Skeleton height={48} variant="rect" width={90} />
          <Skeleton height={48} variant="rect" width={90} />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid item>
          <Skeleton height={25} varaint="text" width={150} />
          <Skeleton height={70} variant="text" width="100%" />
        </Grid>

        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={15} variant="text" width={150} />
        <Skeleton height={70} variant="text" width="100%" />
      </Grid>

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={15} variant="text" width={150} />
        <Skeleton height={70} variant="text" width="100%" />
        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>

      <Grid item>
        <Box mb={3}>
          <Skeleton height={25} varaint="text" width={150} />
          <Skeleton height={15} variant="text" width={150} />
        </Box>

        <Box display="grid" gridAutoFlow="column" justifyContent="space-between">
          {[...Array(7).keys()].map((value) => (
            <Skeleton key={value} height={40} variant="circle" width={40} />
          ))}
        </Box>

        <Box mt={3}>
          <Divider />
        </Box>
      </Grid>

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={15} variant="text" width="100%" />
        <Box display="flex" gridColumnGap={8} justifyContent="space-between">
          <Skeleton height={70} variant="text" width="100%" />
          <Skeleton height={70} variant="text" width={170} />
        </Box>
      </Grid>
    </Grid>
  );
}

ProductSkeleton.Title = Title;

ProductSkeleton.propTypes = {
  children: PropTypes.node,
};

export default ProductSkeleton;
