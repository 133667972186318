import { indexOfDay, shortDaysLabels } from 'utils/dates';

export function getScheduleFormatted(schedule = {}, t) {
  let scheduleFormatted = '';
  const daysArray = schedule?.days.map((day) => indexOfDay[day]);

  const rangeOfDays = daysArray?.reduce((rangesMatrix, day) => {
    if (rangesMatrix.length === 0) {
      rangesMatrix.push([day]);
      return rangesMatrix;
    }

    const lastRange = rangesMatrix[rangesMatrix.length - 1];

    if (Math.abs(day - lastRange[lastRange.length - 1]) === 1) {
      lastRange.push(day);
    } else {
      rangesMatrix.push([day]);
    }

    return rangesMatrix;
  }, []);

  rangeOfDays?.forEach((range) => {
    let result;

    if (range.length >= 3) {
      result = `${t(shortDaysLabels[range[0]])}-${t(shortDaysLabels[range[range.length - 1]])}`;
    } else {
      result = range.map((day) => t(shortDaysLabels[day])).join(',');
    }

    const scheduleAux = scheduleFormatted ? `${scheduleFormatted},` : '';
    scheduleFormatted = `${scheduleAux}${result}`;
  });

  return scheduleFormatted;
}
