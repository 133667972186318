// @ts-nocheck
import { useQuery } from 'react-query';

import { createHeaders } from 'utils/baasRequest';
import { getModifierGroupsRequest } from 'utils/api/baas/menuMaker/modifierGroups';

interface ModifierGroupsProps {
  data: { uuid: string; name: string }[];
}

export function useGetModifierGroupsCatalog(storeUuid: string) {
  const { data, isFetched, isFetching, isError, refetch, remove } = useQuery(
    ['modifierGroupsCatalog', storeUuid],
    () => getModifierGroupsRequest({ storeUuid, allModifiers: true }, { headers: createHeaders() }),
    {
      initialData: { data: [] },
      select: (response: ModifierGroupsProps) => response.data,
      enabled: false,
    },
  );

  return {
    modifierGroups: data,
    modifierGroupsState: { fetched: isFetched, fetching: isFetching, error: isError },
    loadModifierGroups: refetch,
    resetModifierGroups: remove,
  };
}
