import dayjs, { Dayjs } from 'dayjs';
import { formatTypesDates } from 'utils/times';
import { rangesTypes } from 'utils/dateRanges';
import { Response, Filters, FiltersParams } from 'containers/ReportUsers/types';

export const dateToString = (date: Dayjs | undefined) => {
  if (!date) return '';
  return dayjs(date).format(formatTypesDates.SEND_FORM);
};

export const transformPagesToList = (pages: Response[]) => {
  const rows = pages?.map((page) => page.data.rows);

  if (rows.length > 0) {
    return rows.reduce((prev, current) => {
      return [...prev, ...current];
    });
  }
  return [];
};

export const transformFilters = (filers: Filters) => {
  const stringDates: Array<string> = [];
  if (filers.dates?.length === 2) {
    const [startDate, endDate] = filers.dates;
    stringDates.push(dateToString(startDate));
    stringDates.push(dateToString(endDate));
  }
  return {
    startDate: stringDates[0] || '',
    endDate: stringDates[1] || '',
    startTime: filers.startTime,
    endTime: filers.endTime,
    rangeType: filers.rangeType,
  };
};

export const transformParamsToFilters = (filers: FiltersParams) => {
  const dates: Array<Dayjs> = [];
  if (filers.startDate && filers.endDate) {
    dates.push(dayjs(filers.startDate));
    dates.push(dayjs(filers.endDate));
  }
  const params = {
    dates,
    startTime: filers.startTime || '',
    endTime: filers.endTime || '',
    rangeType: filers.rangeType,
  };
  if (
    (filers.rangeType === rangesTypes.TODAY || (!filers.rangeType && typeof filers.rangeType === 'string')) &&
    !filers.startTime &&
    !filers.endTime
  ) {
    params.startTime = '00:00';
    params.endTime = '00:00';
  }
  return params;
};

export const isEmptyReport = (pages: Array<Response> = []) => {
  if (pages.length === 0 || pages[0] === null) {
    return true;
  }
  if (pages.length === 1) {
    const [row] = pages;
    if (row.data.rows.length === 0) return true;
  }
  return false;
};
