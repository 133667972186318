import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getKitchenAreas,
  getKitchenAreasPagination,
  getKitchenAreaDetails,
  createKitchenArea,
  updateKitchenArea,
  disableKitchenArea,
} from './actions';
import {
  selectKitchenAreas,
  selectKitchenAreasPaginationState,
  selectKitchenAreasPaginationInfo,
  selectKitchenAreasState,
  selectKitchenArea,
  selectKitchenAreaState,
  selectCreateKitchenAreaState,
  selectUpdateKitchenAreaState,
  selectDisabledKitchenAreaState,
} from './selectors';

export function withKitchenAreasHoc(Component) {
  function withKitchenAreas(props) {
    return <Component {...props} />;
  }

  withKitchenAreas.displayName = `withKitchenAreas(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withKitchenAreas;
}

const mapStateToProps = createStructuredSelector({
  kitchenAreas: selectKitchenAreas,
  kitchenAreasState: selectKitchenAreasState,
  kitchenAreasPaginationState: selectKitchenAreasPaginationState,
  kitchenAreasPaginationInfo: selectKitchenAreasPaginationInfo,
  kitchenArea: selectKitchenArea,
  kitchenAreaState: selectKitchenAreaState,
  createKitchenAreaState: selectCreateKitchenAreaState,
  updateKitchenAreaState: selectUpdateKitchenAreaState,
  disableKitchenAreasState: selectDisabledKitchenAreaState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadKitchenAreas: (filters = {}) => dispatch(getKitchenAreas(filters)),
    loadKitchenAreasPagination: (filters = {}) => dispatch(getKitchenAreasPagination(filters)),
    resetKitchenAreas: () => dispatch(getKitchenAreas.reset()),
    loadKitchenAreaDetails: (kitchenAreaUuid) => dispatch(getKitchenAreaDetails(kitchenAreaUuid)),
    resetGetKitchenAreaDetails: () => dispatch(getKitchenAreaDetails.reset()),
    createKitchenArea: (fields = {}) => dispatch(createKitchenArea(fields)),
    resetCreateKitchenArea: () => dispatch(createKitchenArea.reset()),
    updateKitchenArea: (fields = {}) => dispatch(updateKitchenArea(fields)),
    resetUpdateKitchenArea: () => dispatch(updateKitchenArea.reset()),
    disableKitchenArea: (fields = {}) => dispatch(disableKitchenArea(fields)),
    resetDisableKitchenArea: () => dispatch(disableKitchenArea.reset()),
  };
}

export const withKitchenAreasState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withKitchenAreasState, withKitchenAreasHoc);
