/* eslint-disable no-unused-vars */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ParrotConnect = forwardRef(function ParrotConnect(props, ref) {
  const { size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <circle cx="12" cy="12" fill="#F7F7F9" r="12" />
      <path
        d="M14.7404 19.244C15.9292 18.3214 16.6107 16.9269 16.6107 15.4195C16.6107 12.7561 14.4548 10.5889 11.8054 10.5889C11.7991 10.5889 11.7917 10.5889 11.7854 10.5889C11.1501 10.5847 10.5557 10.3693 10.0643 9.96397C9.42578 9.43721 9.05929 8.65922 9.05929 7.82949C9.05929 6.30728 10.2911 5.07008 11.8043 5.07008C13.3186 5.07008 14.5493 6.30833 14.5493 7.82949C14.5493 8.19157 14.4811 8.54204 14.3467 8.87351C14.3235 8.92946 14.2994 8.9854 14.2721 9.03924L16.1224 9.94919C16.1697 9.85313 16.2127 9.7539 16.2526 9.65678C16.4889 9.07619 16.6086 8.46076 16.6086 7.82949C16.6107 5.1672 14.4548 3 11.8054 3C9.1559 3 7 5.1672 7 7.83055C7 9.28204 7.64057 10.6427 8.75685 11.5654C8.78206 11.5865 8.81041 11.6044 8.83666 11.6255C7.71933 12.5101 7 13.8814 7 15.4195C7 16.8709 7.64057 18.2316 8.75685 19.1543C9.6127 19.8615 10.6954 20.25 11.8054 20.25C12.8775 20.25 13.893 19.9027 14.7404 19.244ZM10.0643 17.5539C9.42578 17.0272 9.05929 16.2492 9.05929 15.4195C9.05929 13.9025 10.2837 12.6685 11.7907 12.66C11.7959 12.66 11.8001 12.6611 11.8043 12.6611V12.66C13.3186 12.66 14.5493 13.8983 14.5493 15.4195C14.5493 16.2808 14.1597 17.0778 13.4803 17.6057C12.9972 17.9804 12.4176 18.1789 11.8043 18.1789C11.1627 18.1789 10.5599 17.9625 10.0643 17.5539Z"
        fill="#F04E4B"
      />
      <path
        d="M12.7273 7.82949C12.7273 8.34147 12.3146 8.75633 11.8053 8.75633C11.296 8.75633 10.8833 8.34147 10.8833 7.82949C10.8833 7.31751 11.296 6.90265 11.8053 6.90265C12.3146 6.90265 12.7273 7.31751 12.7273 7.82949Z"
        fill="#F04E4B"
      />
    </svg>
  );
});

ParrotConnect.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ParrotConnect;
