import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { validatePin, generatePosPin } from './actions';
import {
  selectValidatePinFetching,
  selectValidatePinFetched,
  selectValidatePinError,
  selectGeneratePosPin,
  selectGeneratePosPinFetching,
  selectGeneratePosPinFetched,
  selectGeneratePosPinError,
} from './selectors';

export function withEmployeesHoc(Component) {
  function withEmployees(props) {
    return <Component {...props} />;
  }

  withEmployees.displayName = `withEmployees(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withEmployees;
}

const mapStateToProps = createStructuredSelector({
  validatePinFetching: selectValidatePinFetching,
  validatePinFetched: selectValidatePinFetched,
  validatePinError: selectValidatePinError,
  posPinData: selectGeneratePosPin,
  generatePosPinFetching: selectGeneratePosPinFetching,
  generatePosPinFetched: selectGeneratePosPinFetched,
  generatePosPinError: selectGeneratePosPinError,
});

export function mapDispatchToProps(dispatch) {
  return {
    validatePin: ({ storeUuid, email, posPin }) => dispatch(validatePin({ storeUuid, email, posPin })),
    generatePosPin: ({ storeUuid }) => dispatch(generatePosPin({ storeUuid })),
    resetValidatePin: () => dispatch(validatePin.reset()),
    resetGeneratePosPin: () => dispatch(generatePosPin.reset()),
  };
}

export const withEmployeesState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withEmployeesState, withEmployeesHoc);
