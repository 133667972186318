// @ts-nocheck
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.darkGrey[2],
  },
}));

interface Props {
  storeSelected: { name: string };
  menu: { name: string; sku: string; brand: { uuid: string; name: string } };
  t: (value: string) => void;
}

function StepperStartInfo({ storeSelected, menu, t }: Props) {
  const classes = useStyles();

  return (
    <Box color="#FFFFFF" fontSize="1rem" mb="1rem">
      <Box my={9}>
        <Divider className={classes.divider} />
      </Box>

      <Grid container spacing={6}>
        <Grid container item spacing={1} xs={12}>
          <Grid item xs={6}>
            <Typography variant="h6">{t('menuMaker:copyMenu.info.originMenu')}</Typography>
            <Typography>{menu?.name}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6">{t('menuMaker:copyMenu.info.key')}</Typography>
            <Typography>{menu?.sku}</Typography>
          </Grid>
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid item xs={6}>
            <Typography variant="h6">{t('menuMaker:copyMenu.info.store')}</Typography>
            <Typography>{storeSelected?.name}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6">{t('menuMaker:copyMenu.info.brand')}</Typography>
            <Typography>{menu?.brand?.name}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StepperStartInfo;
