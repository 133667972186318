/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TransferList from '@experimental-components/TransferList';
import HelperSkeleton from '@experimental-components/TransferList/HelperSkeleton';

import { useListsOptions } from 'providers/root/ListsOptionsProvider';
import useSortingListsOptions from 'hooks/useSortingListsOptions';

import { resetProductsOptionsList } from 'utils/listsOptions';

import useStyles from './styles';

function SelectProducts({ description, onSelectProducts, optionsSelected, t }) {
  const classes = useStyles();
  const scrollPaginationRef = useRef(null);
  const {
    productsOptionsFilterValues,
    setProductsOptionsFilterByText,
    productsOptionsFilterState,
  } = useSortingListsOptions();
  const {
    productsOptions,
    productsOptionsState,
    productsOptionsPaginationValues,
    productsOptionsPaginationState,
    getListProductsOptions,
    getListProductsOptionsReset,
    getListProductsOptionsPagination,
  } = useListsOptions();

  useEffect(() => {
    getListProductsOptions({ ...productsOptionsFilterValues });

    return () => {
      onSelectProducts(null);
      getListProductsOptionsReset();
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && productsOptionsPaginationValues?.next) {
          const nextpage = productsOptionsPaginationValues?.page + 1;

          if (!productsOptionsPaginationState.fetching) {
            getListProductsOptionsPagination({ page: nextpage, ...productsOptionsFilterValues });
          }
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };
  }, [scrollPaginationRef, productsOptionsPaginationValues?.next]);

  const handleSearchProducts = (value) => {
    setProductsOptionsFilterByText({ ...productsOptionsFilterValues, filterByText: value });
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography className={classes.subtitles}>{t('common:addProducts.title')}</Typography>

        <Box mb={4}>
          <Typography variant="body2">{description}</Typography>
        </Box>

        {productsOptionsState.fetching ? (
          <HelperSkeleton isDrawer />
        ) : (
          <TransferList
            isDrawer
            isError={productsOptionsState.error || productsOptionsFilterState.error}
            isLoadingOptions={productsOptionsPaginationState.fetching}
            isLoadingOptionsByFilter={productsOptionsFilterState.fetching}
            isSearchFilter={productsOptionsFilterValues?.filterByText?.length > 0}
            labelOptionsAvailable={t('common:addProducts.labelProductsAvailable')}
            labelOptionsSelected={t('common:addProducts.labelProductsSelected')}
            onChange={onSelectProducts}
            onSearch={handleSearchProducts}
            options={resetProductsOptionsList(productsOptions)}
            optionsSelected={resetProductsOptionsList(optionsSelected)}
            placeholderLabel={t('common:addProducts.placeHolderSearch')}
            scrollPaginationRef={scrollPaginationRef}
          />
        )}
      </Grid>
    </Grid>
  );
}

SelectProducts.propTypes = {
  description: PropTypes.string,
  onSelectProducts: PropTypes.func,
  optionsSelected: PropTypes.array,
  t: PropTypes.func,
};

export default SelectProducts;
