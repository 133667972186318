import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const UploadImage = forwardRef(function UploadImage(props, ref) {
  const { width = 73, height = 56, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={height}
      viewBox="0 0 73 56"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...others}
    >
      <g clipPath="url(#clip0_202_47432)">
        <path d="M9.64062 55.8413H56.7472L64.6958 20.6895H17.5892L9.64062 55.8413Z" fill="#F8B8B7" />
        <path
          d="M72.4998 43.5181L70.8703 43.4419V1.66149H15.2683L15.1141 0L14.0156 1.66149V44.5474H70.8703V44.5459L70.8719 44.5474L72.4998 43.5181Z"
          fill="black"
        />
        <path d="M72.3428 0.160156H15.2734V43.3638H72.3428V0.160156Z" fill="white" />
        <path
          d="M72.5029 43.5233H15.1172V0.00195312H72.5045V43.5233H72.5029ZM15.4351 43.2056H72.1865V0.319639H15.4351V43.2056Z"
          fill="black"
        />
        <path d="M70.0389 1.9043H17.5781V41.6181H70.0389V1.9043Z" fill="#F2F2F2" />
        <path
          d="M70.1928 41.7792H17.4141V1.74609H70.1928V41.7792ZM17.732 41.4615H69.8748V2.06378H17.732V41.4615Z"
          fill="black"
        />
        <path
          d="M56.9468 24.6777H32.8482C31.4429 24.6777 30.3047 25.815 30.3047 27.2192V27.8451C30.3047 29.2492 31.4429 30.3865 32.8482 30.3865H56.9468C58.3521 30.3865 59.4903 29.2492 59.4903 27.8451V27.2192C59.4887 25.8166 58.3505 24.6777 56.9468 24.6777Z"
          fill="#F8B8B7"
        />
        <path
          d="M56.9495 30.546H32.851C31.3614 30.546 30.1484 29.3341 30.1484 27.8457V27.2199C30.1484 25.7315 31.3614 24.5195 32.851 24.5195H56.9495C58.4391 24.5195 59.652 25.7315 59.652 27.2199V27.8457C59.6504 29.3341 58.4391 30.546 56.9495 30.546ZM32.851 24.8372C31.5363 24.8372 30.4664 25.9062 30.4664 27.2199V27.8457C30.4664 29.1593 31.5363 30.2283 32.851 30.2283H56.9495C58.2642 30.2283 59.3341 29.1593 59.3341 27.8457V27.2199C59.3341 25.9062 58.2642 24.8372 56.9495 24.8372H32.851Z"
          fill="black"
        />
        <path d="M58.5414 20.6426H31.2109V22.2135H58.5414V20.6426Z" fill="white" />
        <path
          d="M58.7031 22.373H31.0547V20.4844H58.7031V22.373ZM31.3726 22.0553H58.3852V20.8021H31.3726V22.0553Z"
          fill="black"
        />
        <path
          d="M30.7583 24.9262C30.806 25.0818 30.868 25.2248 30.9491 25.3487C31.5023 26.1874 32.4673 26.6782 33.4115 26.6607C33.9616 26.6512 34.4989 26.4844 35.0013 26.2493C35.3303 26.0952 35.6451 25.9046 35.9487 25.6981C36.645 25.2216 36.9852 24.7085 37.9136 24.9643C38.8388 25.22 39.3937 26.1683 40.2235 26.6432C41.9658 27.6424 43.3489 26.7338 44.5316 25.3519C45.7351 23.9461 47.2691 25.9237 48.2436 26.6575C48.7635 27.0499 49.2992 27.2262 49.9351 27.1102C51.1242 26.8926 51.8555 25.6552 52.9603 25.2105C53.4166 25.0278 53.9158 24.9023 54.407 24.9563C54.9364 25.0135 55.2766 25.4329 55.7137 25.7172C56.3941 26.1604 57.4179 26.1429 58.1237 25.7553C59.0585 25.2407 59.1316 24.1701 59.1412 23.163C59.1475 22.4339 58.6007 21.8398 57.9234 21.8398C53.2226 21.8398 36.4304 21.8398 31.8663 21.8398C31.2225 21.8398 30.6899 22.3799 30.6502 23.0709C30.6105 23.7158 30.5993 24.3988 30.7583 24.9262Z"
          fill="#F04E4A"
        />
        <path
          d="M48.2961 7.93164H41.0184C35.0315 7.93164 30.1797 12.7811 30.1797 18.7615C30.1797 19.8019 31.0238 20.6438 32.0635 20.6438H57.2494C58.2907 20.6438 59.1333 19.8004 59.1333 18.7615C59.1348 12.7795 54.2814 7.93164 48.2961 7.93164Z"
          fill="white"
        />
        <path
          d="M57.2537 20.8033H32.0662C30.9391 20.8033 30.0234 19.8868 30.0234 18.7622C30.0234 12.7023 34.9563 7.77344 41.0211 7.77344H48.2989C54.3636 7.77344 59.2965 12.7023 59.2965 18.7622C59.2965 19.8868 58.3793 20.8033 57.2537 20.8033ZM41.0211 8.09112C35.1328 8.09112 30.3414 12.8786 30.3414 18.7622C30.3414 19.712 31.1156 20.4856 32.0662 20.4856H57.2522C58.2028 20.4856 58.977 19.712 58.977 18.7622C58.977 12.8786 54.1856 8.09112 48.2973 8.09112H41.0211Z"
          fill="black"
        />
        <path
          d="M30.5368 30.3848C30.3842 31.1822 30.3047 32.0081 30.3047 32.8532C30.3047 34.0207 31.179 34.9674 32.2585 34.9674H57.535C58.6144 34.9674 59.4888 34.0207 59.4888 32.8532C59.4888 32.0065 59.4093 31.1822 59.2567 30.3848H30.5368Z"
          fill="white"
        />
        <path
          d="M57.5377 35.1269H32.2612C31.0959 35.1269 30.1484 34.1071 30.1484 32.8538C30.1484 32.0135 30.2279 31.1717 30.3837 30.3552L30.4076 30.2266H59.3913L59.4152 30.3552C59.571 31.1733 59.6505 32.0135 59.6505 32.8538C59.6505 34.1071 58.703 35.1269 57.5377 35.1269ZM30.6715 30.5442C30.5347 31.3019 30.4664 32.0771 30.4664 32.8538C30.4664 33.9324 31.2708 34.8092 32.2612 34.8092H57.5377C58.5281 34.8092 59.3325 33.9324 59.3325 32.8538C59.3325 32.0771 59.2642 31.3003 59.1275 30.5442H30.6715Z"
          fill="black"
        />
        <path
          d="M49.9341 13.5046C50.2429 13.5046 50.4933 13.4043 50.4933 13.2806C50.4933 13.1569 50.2429 13.0566 49.9341 13.0566C49.6253 13.0566 49.375 13.1569 49.375 13.2806C49.375 13.4043 49.6253 13.5046 49.9341 13.5046Z"
          fill="black"
        />
        <path
          d="M47.4341 10.9616C47.7429 10.9616 47.9933 10.8613 47.9933 10.7376C47.9933 10.6139 47.7429 10.5137 47.4341 10.5137C47.1253 10.5137 46.875 10.6139 46.875 10.7376C46.875 10.8613 47.1253 10.9616 47.4341 10.9616Z"
          fill="black"
        />
        <path
          d="M44.5201 13.5046C44.8289 13.5046 45.0792 13.4043 45.0792 13.2806C45.0792 13.1569 44.8289 13.0566 44.5201 13.0566C44.2113 13.0566 43.9609 13.1569 43.9609 13.2806C43.9609 13.4043 44.2113 13.5046 44.5201 13.5046Z"
          fill="black"
        />
        <path
          d="M41.0669 10.9616C41.3757 10.9616 41.626 10.8613 41.626 10.7376C41.626 10.6139 41.3757 10.5137 41.0669 10.5137C40.7581 10.5137 40.5078 10.6139 40.5078 10.7376C40.5078 10.8613 40.7581 10.9616 41.0669 10.9616Z"
          fill="black"
        />
        <path
          d="M39.1841 13.5046C39.4929 13.5046 39.7432 13.4043 39.7432 13.2806C39.7432 13.1569 39.4929 13.0566 39.1841 13.0566C38.8753 13.0566 38.625 13.1569 38.625 13.2806C38.625 13.4043 38.8753 13.5046 39.1841 13.5046Z"
          fill="black"
        />
        <path
          d="M48.4361 24.0137H10.1922L9.77089 22.0234C9.69776 21.6787 9.35279 21.4277 8.949 21.4277H0.703125L1.32947 24.0137L9.27807 55.841H56.3847L48.4361 24.0137Z"
          fill="white"
        />
        <path
          d="M56.5869 55.9989H9.15285L1.17404 24.0525L0.5 21.2695H8.94777C9.42628 21.2695 9.83642 21.5729 9.92545 21.9907L10.3197 23.8555H48.5588L56.5869 55.9989ZM9.40084 55.6812H56.1799L48.3108 24.1716H10.0622L9.61545 22.0558C9.55822 21.7842 9.27684 21.5856 8.94936 21.5856H0.903789L1.48245 23.9746L9.40084 55.6812Z"
          fill="black"
        />
        <path
          d="M28.3285 34.7065L26.5798 32.6797L24.8295 34.7065L20.3672 39.8785L22.6405 41.2985L25.1697 38.3679V47.1026H27.9899V38.3679L30.5191 41.2985L32.7909 39.8785L28.3285 34.7065Z"
          fill="#F04E4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_202_47432">
          <rect fill="white" height={height} transform="translate(0.5)" width={width} />
        </clipPath>
      </defs>
    </svg>
  );
});

UploadImage.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UploadImage;
