import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import AppFrame from 'components/AppFrame';
import { useAuth } from 'hooks/useAuth';

import AppProviders from './Providers';

interface Props {
  accessRoute: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
}

export function PrivateRoute({
  accessRoute = true,
  component: Component,
  ...routeProps
}: Omit<RouteProps, 'component'> & Props) {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { pathname } = location;

  function renderComponent(routerProps: RouteProps) {
    if (accessRoute && isAuthenticated) {
      return <Component {...routerProps} />;
    }

    return <Redirect to="/" />;
  }

  if (!isAuthenticated) {
    window.location.replace(`/signin?continue=${pathname}`);
    return <></>;
  }

  return (
    <AppProviders>
      <AppFrame>
        <Route {...routeProps} render={renderComponent} />
      </AppFrame>
    </AppProviders>
  );
}

export default PrivateRoute;
