import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Find = forwardRef(function Find(props, ref) {
  const { color = '#A3A3AF', size = 32, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M4.87207 13.9552C4.87207 14.1904 5.06062 14.3813 5.29295 14.3813C5.52527 14.3813 5.71382 14.1904 5.71382 13.9552C5.71382 9.44902 9.33335 5.78477 13.7845 5.78477C14.0169 5.78477 14.2054 5.59389 14.2054 5.35869C14.2054 5.1235 14.0169 4.93262 13.7845 4.93262C8.8687 4.93262 4.87207 8.97863 4.87207 13.9552Z"
        fill={color}
      />
      <path
        d="M31.8822 31.2808L24.1414 23.1513C26.2727 20.6937 27.569 17.4759 27.569 13.9548C27.569 13.7196 27.3805 13.5288 27.1481 13.5288C26.9158 13.5288 26.7273 13.7196 26.7273 13.9548C26.7273 21.1811 20.9192 27.0575 13.7845 27.0575C6.64983 27.0575 0.841751 21.1811 0.841751 13.9548C0.841751 6.72859 6.64646 0.852152 13.7845 0.852152C16.6094 0.852152 19.2963 1.75884 21.5488 3.46996C22.165 3.93694 22.7407 4.46528 23.266 5.03451C23.4242 5.20835 23.6902 5.21517 23.862 5.05496C24.0337 4.89476 24.0404 4.62548 23.8822 4.45164C23.3232 3.84491 22.7104 3.28249 22.0539 2.78483C19.6532 0.964636 16.7946 0 13.7845 0C6.18518 0 0 6.26161 0 13.9548C0 21.6515 6.18518 27.9097 13.7845 27.9097C17.6061 27.9097 21.0673 26.3281 23.569 23.775L31.2761 31.8705C31.3603 31.9557 31.468 32 31.5791 32C31.6835 32 31.7879 31.9591 31.8721 31.8807C32.037 31.7171 32.0438 31.4478 31.8822 31.2808Z"
        fill={color}
      />
    </svg>
  );
});

Find.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Find;
