/**
 *
 * ParrotSoftware
 * SettingsAdminTablesProvider reducer
 *
 */
import produce from 'immer';
import baseState from 'utils/baseState';

import {
  createTable,
  getTables,
  getTableDetails,
  deleteTable,
  updateTable,
  createTablesBulk,
  validateName,
  getTablesScrollPagination,
} from './actions';

export const initialState = {
  getTables: {
    ...baseState,
    tables: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  paginationGetTables: {
    ...baseState,
    lastUpdatedRows: [],
  },

  getTableDetails: {
    ...baseState,
    table: {},
  },

  createTable: {
    ...baseState,
  },

  updateTable: {
    ...baseState,
  },

  deleteTable: {
    ...baseState,
  },

  createTablesBulk: {
    ...baseState,
  },

  validateName: {
    ...baseState,
    isNameRegistered: false,
  },
};

const settingsAdminTablesReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case createTable.REQUEST:
        draft.createTable = {
          ...initialState.createTable,
          fetching: true,
        };
        break;

      case createTable.SUCCESS: {
        const tablesList = [...draft.getTables.tables];
        tablesList.unshift(payload);

        draft.createTable.fetching = false;
        draft.createTable.fetched = true;

        draft.getTables.tables = tablesList;
        break;
      }

      case createTable.FAILURE:
        draft.createTable.error = payload;
        draft.createTable.fetching = false;
        draft.createTable.fetched = false;
        break;

      case createTable.RESET:
        draft.createTable = {
          ...initialState.createTable,
        };
        break;

      case createTablesBulk.REQUEST:
        draft.createTablesBulk = {
          ...initialState.createTablesBulk,
          fetching: true,
        };
        break;

      case createTablesBulk.SUCCESS: {
        const tablesList = [...draft.getTables.tables, ...payload];

        draft.createTablesBulk.fetching = false;
        draft.createTablesBulk.fetched = true;

        draft.getTables.tables = tablesList;
        break;
      }

      case createTablesBulk.FAILURE:
        draft.createTablesBulk.error = payload;
        draft.createTablesBulk.fetching = false;
        draft.createTablesBulk.fetched = false;
        break;

      case createTablesBulk.RESET:
        draft.createTablesBulk = {
          ...initialState.createTablesBulk,
        };
        break;

      case getTables.REQUEST:
        draft.getTables = {
          ...initialState.getTables,
          fetching: true,
        };
        break;

      case getTables.SUCCESS:
        draft.getTables.fetching = false;
        draft.getTables.fetched = true;
        draft.getTables.tables = payload?.tables;

        draft.getTables.pagination = {
          ...initialState.getTables.pagination,
          ...payload.pagination,
        };
        break;

      case getTables.FAILURE:
        draft.getTables.error = payload;
        draft.getTables.fetching = false;
        draft.getTables.fetched = false;
        break;

      case getTables.RESET:
        draft.getTables = {
          ...initialState.getTables,
        };
        break;

      case getTables.SORT_POSITION: {
        const sortedValues = payload || {};
        const newTables = [...draft.getTables.tables];

        const order = sortedValues.sorted.map((item, index) => {
          return {
            ...item,
            sortPosition: sortedValues.current[index].sortPosition,
          };
        });
        order.forEach((item) => {
          const indexToChange = draft.getTables.tables.findIndex((t) => t.uuid === item.uuid);

          if (indexToChange >= 0) {
            newTables[indexToChange].sortPosition = item.sortPosition;
          }
        });

        draft.getTables.tables = newTables;
        break;
      }

      case getTablesScrollPagination.REQUEST:
        draft.paginationReportsOrders = {
          ...initialState.paginationReportsOrders,
          fetching: true,
        };
        break;

      case getTablesScrollPagination.SUCCESS: {
        const currentTablesState = state.getTables.tables;

        let newsRows = [...currentTablesState];
        newsRows = [...newsRows, ...payload.tables];

        draft.paginationGetTables.fetching = false;
        draft.paginationGetTables.fetched = true;
        draft.paginationGetTables.lastUpdatedRows = payload.data;

        draft.getTables.tables = [...newsRows];
        draft.getTables.pagination = {
          ...initialState.getTables.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getTablesScrollPagination.FAILURE:
        draft.paginationGetTables.error = payload;
        draft.paginationGetTables.fetching = false;
        draft.paginationGetTables.fetched = false;
        break;

      case getTablesScrollPagination.RESET:
        draft.paginationGetTables = {
          ...initialState.paginationGetTables,
        };
        break;

      case getTableDetails.REQUEST:
        draft.getTableDetails = {
          ...initialState.getTableDetails,
          fetching: true,
        };
        break;

      case getTableDetails.SUCCESS:
        draft.getTableDetails.fetching = false;
        draft.getTableDetails.fetched = true;
        draft.getTableDetails.table = payload;
        break;

      case getTableDetails.FAILURE:
        draft.getTableDetails.error = payload;
        draft.getTableDetails.fetching = false;
        draft.getTableDetails.fetched = false;
        break;

      case getTableDetails.RESET:
        draft.getTableDetails = {
          ...initialState.getTableDetails,
        };
        break;

      case updateTable.REQUEST:
        draft.updateTable = {
          ...initialState.updateTable,
          fetching: true,
        };
        break;

      case updateTable.SUCCESS: {
        const tableUpdate = draft.getTables.tables.findIndex((table) => {
          return table.uuid === payload.uuid;
        });

        draft.updateTable.fetching = false;
        draft.updateTable.fetched = true;

        draft.getTables.tables[tableUpdate] = payload;
        break;
      }

      case updateTable.FAILURE:
        draft.updateTable.error = payload;
        draft.updateTable.fetching = false;
        draft.updateTable.fetched = false;
        break;

      case updateTable.RESET:
        draft.updateTable = {
          ...initialState.updateTable,
        };
        break;

      case deleteTable.REQUEST:
        draft.deleteTable = {
          ...initialState.deleteTable,
          fetching: true,
        };
        break;

      case deleteTable.SUCCESS:
        draft.deleteTable.fetching = false;
        draft.deleteTable.fetched = true;
        break;

      case deleteTable.FULFILL: {
        const currentTables = draft.getTables.tables.filter((table) => {
          return table.uuid !== payload.tableUuid;
        });

        draft.getTables.tables = currentTables;
        break;
      }

      case deleteTable.FAILURE:
        draft.deleteTable.error = payload;
        draft.deleteTable.fetching = false;
        draft.deleteTable.fetched = false;
        break;

      case deleteTable.RESET:
        draft.deleteTable = {
          ...initialState.deleteTable,
        };
        break;

      case validateName.SUCCESS:
        draft.validateName.isNameRegistered = payload.nameRegistered;
        break;

      case validateName.RESET:
        draft.validateName = {
          ...initialState.validateName,
        };
        break;

      default:
        return draft;
    }
  });

export default settingsAdminTablesReducer;
