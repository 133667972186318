import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectInvoicesGlobalProviderDomain = (state) => state.invoicesGlobalProvider || initialState;

const selectAllUninvoicedOrders = createSelector(
  selectInvoicesGlobalProviderDomain,
  (subState) => subState.getAllInvoicesUninvoicedOrders.invoices,
);

const selectAllUninvoicedOrdersState = createSelector(
  selectInvoicesGlobalProviderDomain,
  (subState) => subState.getAllInvoicesUninvoicedOrders,
);

const selectAllUninvoicedOrdersPaginationInfo = createSelector(
  selectInvoicesGlobalProviderDomain,
  (subState) => subState.getAllInvoicesUninvoicedOrders.pagination,
);

const selectInvoicedOrdersSortingState = createSelector(
  selectInvoicesGlobalProviderDomain,
  (subState) => subState.sorting,
);

const selectPaginationScrollInvoicesStateState = createSelector(
  selectInvoicesGlobalProviderDomain,
  (subState) => subState.paginationScrollInvoicesState,
);

const selectGenerateInvoicingState = createSelector(
  selectInvoicesGlobalProviderDomain,
  (subState) => subState.generateInvoicing,
);

const selectGenerateInvoicingInfo = createSelector(
  selectInvoicesGlobalProviderDomain,
  (subState) => subState.generateInvoicing.data,
);

export {
  selectInvoicesGlobalProviderDomain,
  selectAllUninvoicedOrders,
  selectAllUninvoicedOrdersState,
  selectPaginationScrollInvoicesStateState,
  selectAllUninvoicedOrdersPaginationInfo,
  selectGenerateInvoicingState,
  selectGenerateInvoicingInfo,
  selectInvoicedOrdersSortingState,
};
