import { put, call, takeLatest } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getProductRequest, updateProductRequest } from 'utils/api/baas/menuMaker';
import { changeMenuProductPriceRequest } from 'utils/api/baas/menuMaker/products';

import { getProduct, editProduct, updateProductPrice } from './actions';

export function* handleGetProductRequest(action) {
  const { storeUuid, productUuid } = action.payload;
  try {
    yield put(getProduct.request());

    const { data } = yield call(baasRequest, getProductRequest, {
      storeUuid,
      productUuid,
    });

    yield put(getProduct.success(data));
  } catch (err) {
    yield put(getProduct.failure({ error: err, productUuid }));
  }
}

export function* handleEditProductRequest(action) {
  const { storeUuid, originMenuUuid, product } = action.payload;
  try {
    yield put(editProduct.request());

    const { data } = yield call(baasRequest, updateProductRequest, {
      storeUuid,
      originMenuUuid,
      product,
    });

    yield put(editProduct.success(data));
  } catch (err) {
    yield put(editProduct.failure({ error: err }));
  }
}

export function* handleSetNewProductPrice(action) {
  const { menuUuid, productUuid, price, availability } = action.payload;

  try {
    yield put(updateProductPrice.request());

    const { data } = yield call(baasRequest, changeMenuProductPriceRequest, {
      menuUuid,
      productUuid,
      price,
      availability,
    });

    yield put(updateProductPrice.success(data));
  } catch (err) {
    yield put(updateProductPrice.failure(err));
  }
}

export default function* productDetailsSaga() {
  yield takeLatest(getProduct.TRIGGER, handleGetProductRequest);
  yield takeLatest(editProduct.TRIGGER, handleEditProductRequest);
  yield takeLatest(updateProductPrice.TRIGGER, handleSetNewProductPrice);
}
