import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getPermissions } from './actions';
import {
  // Get Permissions
  selectPermissions,
  selectPermissionsError,
  selectPermissionsIsFetching,
  selectPermissionsDidFetch,
} from './selectors';

export function withPermissionsHoc(Component) {
  function withPermissions(props) {
    return <Component {...props} />;
  }

  withPermissions.displayName = `withPermissions(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withPermissions;
}

const mapStateToProps = createStructuredSelector({
  // Get Permissions
  permissionsList: selectPermissions,
  permissionsError: selectPermissionsError,
  permissionsIsFetching: selectPermissionsIsFetching,
  permissionsDidFetch: selectPermissionsDidFetch,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadPermissions: () => dispatch(getPermissions()),
    resetLoadPermissions: () => dispatch(getPermissions.reset()),
  };
}

export const withPermissionsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withPermissionsState, withPermissionsHoc);
