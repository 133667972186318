import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getProductsOptions, getProductsOptionsByPagination, getProductsOptionsByFilter } from './actions';

import {
  selectGetProductsOptions,
  selectGetProductsOptionsState,
  selectGetProductsOptionsPagination,
  selectGetProductsOptionsPaginationState,
  selectGetProductsOptionsFilterState,
} from './selectors';

export function withListsOptionsHoc(Component) {
  function withListsOptions(props) {
    return <Component {...props} />;
  }

  withListsOptions.displayName = `withListsOptions(${getDisplayName(Component)})`;
  return withListsOptions;
}

const mapStateToProps = createStructuredSelector({
  productsOptions: selectGetProductsOptions,
  productsOptionsState: selectGetProductsOptionsState,
  productsOptionsPaginationValues: selectGetProductsOptionsPagination,
  productsOptionsPaginationState: selectGetProductsOptionsPaginationState,
  productsOptionsFilterState: selectGetProductsOptionsFilterState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadProductsOptions: (filters = {}) => dispatch(getProductsOptions(filters)),
    loadProductsOptionsPagination: (filters = {}) => dispatch(getProductsOptionsByPagination(filters)),
    loadProductsOptionsFilter: (filters = {}) => dispatch(getProductsOptionsByFilter(filters)),
    resetProductsOptions: () => dispatch(getProductsOptions.reset()),
    resetProductsPaginationPagination: () => dispatch(getProductsOptionsByPagination.reset()),
    resetProductsOptionsFilter: () => dispatch(getProductsOptionsByFilter.reset()),
  };
}

export const withListsOptionsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withListsOptionsState, withListsOptionsHoc);
