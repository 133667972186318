import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Divider from '@design-system/Divider';

import NestedTable from '@experimental-components/NestedTable';
import Tooltip from '@design-system/Tooltip';

import { useFilters, useReportFilters, useGetModifiersOptionInfinity } from 'containers/ReportModifiers/hooks';

import DisplayMessage from 'components/DisplayMessageV2';
import PageError from 'components/PageError';

import PlaceholderTable from './PlaceholderTable';
import { GetModifiersOptionsResponse } from './types';
import ModifierGroupsByOptionRow from './ModifierGroupsByOptionRow';
import useStyle from './styles';

function ModifierGroupsByOption() {
  const classes = useStyle();
  const { t } = useTranslation('reports');

  const { filters } = useFilters();
  const {
    refetch,
    isFetching,
    isFetchingNextPage,
    isFetched,
    isError,
    data,
    fetchNextPage,
    hasNextPage,
    remove,
  } = useGetModifiersOptionInfinity();
  const reportsFilters = useReportFilters();
  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    if (reportsFilters.data) {
      remove();
      refetch();
    }
  }, [filters]);

  useEffect(() => {
    return () => {
      remove();
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        if (isIntersecting && hasNextPage) {
          if (!isFetchingNextPage) {
            fetchNextPage();
          }
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };
  }, [scrollPaginationRef, hasNextPage, fetchNextPage, data]);

  if (isError) {
    return (
      <Box mt={6}>
        <PageError
          id="modifiersByOptionsError"
          labelAction={t('common:buttons.retry')}
          message={t('reports:messages.reportError')}
          onAction={refetch}
          title=""
        />
      </Box>
    );
  }

  if (!isFetching && !data?.pages[0].data?.length && isFetched) {
    return (
      <Box>
        <Box mt="5%">
          <DisplayMessage
            height="auto"
            id="modifiersGroupsOptionsNoData"
            message={t('reports:reportsEmptyMessage')}
            sizeIcon={120}
          />
        </Box>
      </Box>
    );
  }

  const renderBodyTable = () => {
    if (isFetching && !isFetchingNextPage) {
      return <PlaceholderTable.Option />;
    }

    return data?.pages.map((group: GetModifiersOptionsResponse | null) => {
      if (!group) return null;
      return group?.data?.map((item) => {
        return <ModifierGroupsByOptionRow key={item?.sku} row={item} />;
      });
    });
  };

  return (
    <Box mx={-4}>
      <NestedTable>
        <NestedTable.Head>
          <NestedTable.HeadCell align="left">{t('modifiersReport.tableByProduct.name')}</NestedTable.HeadCell>

          <NestedTable.HeadCell>
            {t('modifiersReport.tableByProduct.sku')}
            <Tooltip
              classes={{ tooltip: classes.tooltipMaxWidth }}
              sizeIcon={16}
              title={t('modifiersReport.tooltips.byOptions.sku')}
            >
              <Tooltip.Help size={16} />
            </Tooltip>
          </NestedTable.HeadCell>

          <NestedTable.HeadCell>{t('modifiersReport.tableByProduct.quantity')}</NestedTable.HeadCell>

          <NestedTable.HeadCell>
            {t('modifiersReport.tableByProduct.averagePrice')}
            <Tooltip sizeIcon={16} title={t('modifiersReport.tooltips.byOptions.averagePrice')}>
              <Tooltip.Help size={16} />
            </Tooltip>
          </NestedTable.HeadCell>

          <NestedTable.HeadCell>
            {t('modifiersReport.tableByProduct.totalSale')}
            <Tooltip sizeIcon={16} title={t('modifiersReport.tooltips.byOptions.totalSale')}>
              <Tooltip.Help size={16} />
            </Tooltip>
          </NestedTable.HeadCell>
        </NestedTable.Head>

        <NestedTable.Body>
          {renderBodyTable()}

          <Divider />

          {isFetchingNextPage && <PlaceholderTable.Option />}
        </NestedTable.Body>
      </NestedTable>

      <div ref={scrollPaginationRef} />
    </Box>
  );
}

export default ModifierGroupsByOption;
