import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';

import IconButton from '@design-system/IconButton';
import IconArrowBack from '@experimental-components/IconsComponents/ArrowBack';

import { stepsTypes } from 'utils/menus/menuCopy';

import useStyles from './styles';

function StepperStartContentDialog({ children, onBack, step }) {
  const classes = useStyles();
  const isWarningStep = step === stepsTypes.WARNING;

  return (
    <Box bgcolor={isWarningStep ? '#F2F2F4' : '#000'} component="aside">
      <Box
        bottom="0"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        left="0"
        padding="3rem"
        position="fixed"
        top="0"
        width="50%"
      >
        <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
          <Box>
            {step !== stepsTypes.CONFIG && (
              <IconButton
                disabled={step === stepsTypes.CONFIG}
                onClick={onBack}
                startIcon={<IconArrowBack color={isWarningStep ? '#000' : '#FFF'} />}
              />
            )}
          </Box>

          <Box flex="1 1 auto" />

          <Box className={clsx({ [classes.warningStep]: isWarningStep })}>{children}</Box>

          <Box flex="1 1 auto" />
        </Box>
      </Box>
    </Box>
  );
}

StepperStartContentDialog.propTypes = {
  children: PropTypes.node,
  onBack: PropTypes.func,
  step: PropTypes.string,
};

export default StepperStartContentDialog;
