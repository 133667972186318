import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@experimental-components/Divider';

import useStyles from './styles';

import Sidebar from './Sidebar';
import Section from './Section';
import Item from './Item';
import ItemMenu from './ItemMenu';
import Spacer from './Spacer';
import Content from './Content';
import ButtonCard from './ButtonCard';

function SidebarLayout({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

SidebarLayout.Sidebar = Sidebar;
SidebarLayout.Section = Section;
SidebarLayout.Item = Item;
SidebarLayout.ItemMenu = ItemMenu;
SidebarLayout.Divider = Divider;
SidebarLayout.Spacer = Spacer;
SidebarLayout.Content = Content;
SidebarLayout.ButtonCard = ButtonCard;

SidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default SidebarLayout;
