import { useRecoilState } from 'recoil';
import { NavigationType } from '@experimental-components/TopNavigationBar/types';

import {
  currentNavigationAtom,
  currentReloadStore,
  currentChangeLocation,
  currentChangeModeDraft,
} from './navigationAtom';

export default function useNavigation() {
  const [currentNavigation, setCurrentNavigation] = useRecoilState<NavigationType>(currentNavigationAtom);
  const [reloadStoreOnClose, setReloadStoreOnClose] = useRecoilState<boolean>(currentReloadStore);
  const [changeLocationState, setChangeLocationState] = useRecoilState<boolean>(currentChangeLocation);
  const [goToMenuDraft, setGoToMenuDraft] = useRecoilState<boolean>(currentChangeModeDraft);

  function setNavigation(nav: NavigationType) {
    setCurrentNavigation(nav);
  }
  return {
    currentNavigation,
    setNavigation,
    reloadStoreOnClose,
    setReloadStoreOnClose,
    changeLocationState,
    setChangeLocationState,
    goToMenuDraft,
    setGoToMenuDraft,
  };
}
