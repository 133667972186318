import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getPortalConfigListRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/invoicing/portal-configs/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getPortalConfigDetailsRequest(portalUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/invoicing/portal-configs/${portalUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function savePortalConfigRequest(
  {
    brandUuid,
    email,
    phone,
    mainColor,
    logo,
    expirationType,
    expirationDays,
    activeConfigSingleConcept,
    taxProductKey,
  },
  options = {},
) {
  const headers = { ...options.headers };
  delete headers['Content-Type'];

  options = {
    ...options,
    headers: {
      ...headers,
    },
  };

  const storeUuid = getUserCurrentStore()?.uuid;
  const formData = new FormData();

  const configValues = {
    brand: brandUuid,
    contact_email: email,
    contact_phone: phone,
    main_color: mainColor,
    expiration_type: expirationType,
    expiration_days: expirationDays,
    logo,
    active_config_single_concept: activeConfigSingleConcept,
    tax_product_key: taxProductKey,
  };

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  Object.keys(configValues).forEach((key) => {
    const value = configValues[key];

    if (value) {
      formData.append(key, value);
    }
  });

  return request(`${API_BASE_URL}/v1/invoicing/portal-configs/?${query}`, {
    method: 'POST',
    body: formData,
    ...options,
  });
}

export function updatePortalConfigRequest(
  {
    portalUuid,
    brandUuid,
    email,
    phone,
    mainColor,
    logo,
    expirationType,
    expirationDays,
    activeConfigSingleConcept,
    taxProductKey,
  },
  options = {},
) {
  const headers = { ...options.headers };
  delete headers['Content-Type'];

  options = {
    ...options,
    headers: {
      ...headers,
    },
  };

  const storeUuid = getUserCurrentStore()?.uuid;
  const formData = new FormData();

  const configValues = {
    brand: brandUuid,
    contact_email: email,
    contact_phone: phone,
    main_color: mainColor,
    expiration_type: expirationType,
    expiration_days: expirationDays,
    logo,
    active_config_single_concept: activeConfigSingleConcept,
    tax_product_key: taxProductKey,
  };

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  Object.keys(configValues).forEach((key) => {
    const value = configValues[key];

    if (value) {
      formData.append(key, value);
    }
  });

  return request(`${API_BASE_URL}/v1/invoicing/portal-configs/${portalUuid}/?${query}`, {
    method: 'PUT',
    body: formData,
    ...options,
  });
}

export function setEnableAutoInvoicingRequest({ portalUuid, isEnabled }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/invoicing/portal-configs/${portalUuid}/status/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: JSON.stringify({
      is_enabled: isEnabled,
    }),
    ...options,
  });
}
