import queryString from 'query-string';
import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { pageSizeTypes } from 'utils/pagination';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getDiningOptionsRequest({ page = 1 }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    page,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
  });
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/order-types/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getDiningOptionDetailRequest({ storeUuid, diningOptionUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/order-types/${diningOptionUuid}/`, {
    method: 'GET',
    ...options,
  });
}

export function createDiningOptionRequest({ storeUuid, diningOption }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/order-types/`, {
    method: 'POST',
    body: JSON.stringify(diningOption),
    ...options,
  });
}

export function deleteDiningOptionRequest({ storeUuid, diningOptionUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/order-types/${diningOptionUuid}/`, {
    method: 'DELETE',
    ...options,
  });
}

export function setIsEnabledDiningOptionRequest({ storeUuid, diningOptionUuid, isEnabled }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/order-types/${diningOptionUuid}/set-is-enabled/`, {
    method: 'PUT',
    body: JSON.stringify({
      is_enabled: isEnabled,
    }),
    ...options,
  });
}

export function editDiningOptionRequest({ storeUuid, diningOptionUuid, diningOption }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/order-types/${diningOptionUuid}/`, {
    method: 'PUT',
    body: JSON.stringify(diningOption),
    ...options,
  });
}
