import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}
const Users = forwardRef(function Users(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FFF', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M3 21.4608C3.83333 18.4335 6.66667 16.1426 10.0833 16.1426H12H13.9167C17.25 16.1426 20.0833 18.3517 21 21.4608"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <circle cx="12" cy="7.14286" r="4.39286" stroke={color} strokeWidth="1.5" />
    </svg>
  );
});

export default Users;
