import dayjs, { Dayjs } from 'dayjs';
import { formatTypesDates } from 'utils/times';

import { FiltersProps, FiltersQueryParamsProps } from './types';

export const dateToString = (date: Dayjs | undefined) => {
  if (!date) return '';
  return dayjs(date).format(formatTypesDates.SEND_FORM);
};

export const transformFilters = (filers: FiltersProps) => {
  const stringDates: Array<string> = [];
  if (filers.dates?.length === 2) {
    const [startDate, endDate] = filers.dates;
    stringDates.push(dateToString(startDate));
    stringDates.push(dateToString(endDate));
  }
  return {
    startDate: stringDates[0] || '',
    endDate: stringDates[1] || '',
    startTime: filers.startTime,
    endTime: filers.endTime,
  };
};

export const transformParamsToFilters = (filers: FiltersQueryParamsProps) => {
  const dates: Array<Dayjs> = [];
  if (filers.startDate && filers.endDate) {
    dates.push(dayjs(filers.startDate));
    dates.push(dayjs(filers.endDate));
  }
  return {
    dates,
    startTime: filers.startTime || '00:00',
    endTime: filers.endTime || '00:00',
  };
};
