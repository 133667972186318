// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import IconAlert from '@experimental-components/IconsComponents/Alert';

import theme from 'utils/theme';

import useStyles from './styles';

interface Props {
  t: (value: string) => void;
}

function BannerErrorPublishMenus({ t }: Props) {
  const classes = useStyles();

  const history = useHistory();
  const isSmallSize = useMediaQuery(theme.breakpoints.down('sm'));

  const handleGoToPublish = () => {
    history.push('/menus/publish');
  };

  return (
    <Box className={classes.errorPublishMenu}>
      <Box className={classes.descriptionErrorPublish}>
        <IconAlert colorInherit="#FFF" size={20} />

        <Box component="p" lineHeight="14px" m={0}>
          {isSmallSize ? t('menuMaker:messages.isErrorPublishMenuShort') : t('menuMaker:messages.isErrorPublishMenu')}
        </Box>
      </Box>

      {history.location?.pathname !== '/menus/publish' ? (
        <Link
          className={classes.publishLink}
          color="secondary"
          component="button"
          onClick={handleGoToPublish}
          underline="none"
          variant="button"
        >
          {t('common:buttons.goToPublish')}
        </Link>
      ) : null}
    </Box>
  );
}

export default BannerErrorPublishMenus;
