import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}

const HelpPrincipalMenu = forwardRef(function HelpPrincipalMenu(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FFF', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        fill={color}
        fillRule="evenodd"
      />
      <path clipRule="evenodd" d="M11.7256 17.9999V16.4268H13.2256V17.9999H11.7256Z" fill={color} fillRule="evenodd" />
      <path
        clipRule="evenodd"
        d="M12.4756 6.75C10.9395 6.75 9.75 7.93477 9.75 9.32892H8.25C8.25 7.04604 10.1727 5.25 12.4756 5.25C14.7785 5.25 16.7012 7.04604 16.7012 9.32892C16.7012 11.3586 15.1813 13.0035 13.2256 13.3434V15.2683H11.7256V11.9078H12.4756C14.0117 11.9078 15.2012 10.7231 15.2012 9.32892C15.2012 7.93477 14.0117 6.75 12.4756 6.75Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default HelpPrincipalMenu;
