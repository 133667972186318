import PropTypes from 'prop-types';

import { Trans } from 'react-i18next';

import useTheme from '@material-ui/core/styles/useTheme';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: `0px 8px 40px ${theme.palette.darkGrey[5]}`,
    marginLeft: theme.spacing(2),
  },
}));

function ToolTip({ anchorEl, message, onClose, id }) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{ paper: classes.paper }}
      data-testid={id}
      id={id}
      onClose={onClose}
      open={Boolean(anchorEl)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box maxWidth={250} p={2} width="100%">
        <Box
          color="text.secondary"
          component="p"
          fontSize={16}
          fontWeight={theme.typography.fontWeightRegular}
          m={0}
          sx={{
            '& > strong': {
              fontSize: 14,
              fontWeight: theme.typography.fontWeightMedium,
            },
          }}
        >
          <Trans i18nKey={message} />
        </Box>
      </Box>
    </Popover>
  );
}

ToolTip.propTypes = {
  anchorEl: PropTypes.object,
  message: PropTypes.string,
  onClose: PropTypes.func,
  id: PropTypes.string,
};

export default ToolTip;
