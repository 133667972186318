import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.typography.pxToRem(0),
  },

  listContainer: {
    width: (props) => (props.fullWidth ? '100%' : 'fit-content'),
    marginTop: theme.typography.pxToRem(14),
    padding: 0,
    '&  > :first-child > li': {
      borderTop: '1px solid #0000003b',
      borderTopLeftRadius: theme.typography.pxToRem(5),
      borderTopRightRadius: theme.typography.pxToRem(5),
    },
    '&  > :last-child > li': {
      borderBottomLeftRadius: theme.typography.pxToRem(5),
      borderBottomRightRadius: theme.typography.pxToRem(5),
    },

    '& li': {
      width: (props) => (props.hasItemOverride ? 'calc(100% - 33px)' : '100%'),
      borderBottom: '1px solid #0000003b',
      borderLeft: '1px solid #0000003b',
      borderRight: '1px solid #0000003b',
      marginRight: theme.spacing(1),
      '& button': {
        padding: 0,
        paddingLeft: theme.typography.pxToRem(14),
      },
    },
  },

  contentItem: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    zIndex: '1300',
  },

  listItem: {
    zIndex: '1300',
    padding: theme.typography.pxToRem(14),
  },

  itemProduct: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '& > :first-child': {
      cursor: (props) => (props.isSorteable ? 'grab' : 'auto'),
    },
    '& > :not(:first-child)': {
      margin: 0,
      marginLeft: theme.spacing(2),
    },
    '& > div:first-child': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
  },

  listItemTextContainer: {
    margin: 0,
  },

  listItemText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },

  listItemError: {
    color: theme.palette.secondary.main,
  },

  disableHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
