import Grid from '@material-ui/core/Grid';

function Row({ ...others }) {
  return <Grid container spacing={3} {...others} />;
}

Row.propTypes = {
  ...Grid.propTypes,
};

export default Row;
