import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const HelpChat = forwardRef(function HelpChat(props, ref) {
  const { color = '#fff', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 26 23"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M14.6916 18.6646H15.6813C17.0421 18.6646 18.1555 17.5122 18.1555 16.1037V8.03665C18.1555 6.62811 17.0421 5.47567 15.6813 5.47567H3.55756C2.19674 5.47567 1.08333 6.62811 1.08333 8.03665V16.1037C1.08333 17.5122 2.19674 18.6646 3.55756 18.6646H9.86684L15.1864 22.25L14.6916 18.6646Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.9"
      />
      <path
        d="M5.66053 13.1585C6.20712 13.1585 6.65022 12.6999 6.65022 12.1341C6.65022 11.5683 6.20712 11.1098 5.66053 11.1098C5.11394 11.1098 4.67084 11.5683 4.67084 12.1341C4.67084 12.6999 5.11394 13.1585 5.66053 13.1585Z"
        fill={color}
      />
      <path
        d="M9.61954 13.1585C10.1661 13.1585 10.6092 12.6999 10.6092 12.1341C10.6092 11.5683 10.1661 11.1098 9.61954 11.1098C9.07295 11.1098 8.62985 11.5683 8.62985 12.1341C8.62985 12.6999 9.07295 13.1585 9.61954 13.1585Z"
        fill={color}
      />
      <path
        d="M13.7018 13.1585C14.2483 13.1585 14.6915 12.6999 14.6915 12.1341C14.6915 11.5683 14.2483 11.1098 13.7018 11.1098C13.1552 11.1098 12.7121 11.5683 12.7121 12.1341C12.7121 12.6999 13.1552 13.1585 13.7018 13.1585Z"
        fill={color}
      />
      <path
        d="M18.1555 15.4634L21.9906 18.0244L21.6194 14.439H22.6091C23.9699 14.439 25.0833 13.2866 25.0833 11.878V3.81098C25.0833 2.40244 23.9699 1.25 22.6091 1.25H10.3617C9.00086 1.25 7.88746 2.40244 7.88746 3.81098V5.34756"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.9"
      />
    </svg>
  );
});

HelpChat.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default HelpChat;
