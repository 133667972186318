import { takeLatest } from 'redux-saga/effects';

import { getRoles, duplicateRole, deleteRole, getPermissionsByRoles } from '../actions';

import { handleGetRolesRequest, handleGetPermissionsByRolesRequest } from './getRolesSaga';
import { handleDuplicateRoleRequest } from './duplicateRoleSaga';
import { handleDeleteRoleRequest } from './deleteRoleSaga';

export default function* rolesSaga() {
  yield takeLatest(getRoles.TRIGGER, handleGetRolesRequest);
  yield takeLatest(duplicateRole.TRIGGER, handleDuplicateRoleRequest);
  yield takeLatest(deleteRole.TRIGGER, handleDeleteRoleRequest);
  yield takeLatest(getPermissionsByRoles.TRIGGER, handleGetPermissionsByRolesRequest);
}
