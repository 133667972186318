import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: theme.typography.pxToRem(152),
    height: theme.typography.pxToRem(152),
    padding: theme.typography.pxToRem(4),
    border: `1px dashed ${theme.palette.darkGrey[15]}`,
    borderRadius: theme.shape.borderRadius,
  },

  wrapperPhoto: {
    position: 'relative',
    maxWidth: '100%',
    height: '100%',
  },

  photoContainer: {
    cursor: 'pointer',
    maxWidth: '100%',
    height: '100%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
    },
  },

  withoutImage: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  addPhoto: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(2),
  },

  removeImage: {
    position: 'absolute',
    top: '-5px',
    right: '-24px',
  },

  loadDisabled: {
    borderColor: theme.palette.error.main,
    '& > div': {
      opacity: 0.5,
      pointerEvents: 'none',
      cursor: 'none',
    },
  },
}));

export default useStyles;
