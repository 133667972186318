import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  linkCell: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left',
    width: theme.spacing(30),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
