import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

export const DEFAULT_SIZE_PAGE = 10;
export const DEFAULT_PAGE = 1;

const STATUS_AVAILABLE = 'AVAILABLE';
const STATUS_UNAVAILABLE = 'UNAVAILABLE';

export const statusType = {
  STATUS_AVAILABLE,
  STATUS_UNAVAILABLE,
};

export function getFilteredProductsByText(products, sorting) {
  const productSearchValue = {};
  products?.forEach((product) => {
    const searchValues = new Set();
    // Add product name
    searchValues.add(product.name.toLowerCase());

    // add modifier names
    product.modifierGroups.forEach((modifierGroupContainer) => {
      searchValues.add(modifierGroupContainer.modifierGroup.name.toLowerCase());
    });

    productSearchValue[product.uuid] = searchValues;
  });

  const productsList = [];
  products?.forEach((product) => {
    const finalSearchValue = sorting?.filterByText.toLowerCase();
    const productSearchTermSet = productSearchValue[product.uuid];

    if (!finalSearchValue) {
      productsList.push(product);
      return;
    }
    const searchTerms = Array.from(productSearchTermSet);
    for (let i = 0; i < productSearchTermSet.size; i += 1) {
      const term = searchTerms[i];
      if (term.includes(finalSearchValue)) {
        productsList.push(product);
        return;
      }
    }
  });
  return productsList;
}

export function getFilteredProductsByCategories(products, categoriesByUuid) {
  const productList = [];
  products?.forEach((product) => {
    product?.categories?.forEach((categoryContainer) => {
      if (categoriesByUuid?.includes(categoryContainer?.category?.uuid)) {
        productList.push(product);
      }
    });
  });
  return uniqBy(productList, 'uuid');
}

export const pieceUnits = 'PIECES';
export const gramUnits = 'GRAMS';
export const kilogramUnits = 'KILOGRAM';
export const litreUnits = 'LITRE';
export const millilitreUnits = 'MILLILITRE';
export const units = [pieceUnits, gramUnits, kilogramUnits, litreUnits, millilitreUnits];

const PROPERTY_NAME = 'name';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  NAME: PROPERTY_NAME,
};

const FILTER_PROPERTIES = [PROPERTY_NAME];

export function getFilteredMenuProductsByText(products, sorting) {
  return products.filter(
    (product) =>
      FILTER_PROPERTIES.filter((property) =>
        product[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}

export const filtersTableProductsTypes = {
  all: 'all',
  articles: 'articles',
  soldAlone: 'soldAlone',
};

export const optionsFilter = [
  { label: 'Ver todo', value: filtersTableProductsTypes.all },
  { label: 'Artículos', value: filtersTableProductsTypes.articles },
  { label: 'Personalizables', value: filtersTableProductsTypes.soldAlone },
];

const GENERAL = 'GENERAL';
const ADVANCED = 'ADVANCED';

export const tabsFormOptions = {
  GENERAL,
  ADVANCED,
};

export const tabsFormProduct = [
  {
    value: GENERAL,
    label: 'menuMaker:productForm.tabs.general',
    testId: 'product-form-tab-general',
  },
  {
    value: ADVANCED,
    label: 'menuMaker:productForm.tabs.advanced',
    testId: 'product-form-tab-advanced',
  },
];

export const sortByToLower = (option) => {
  return option?.name?.toLowerCase();
};

export function getInitialOptionsAutocomplete(options, optionsSelected) {
  const initialOptions = [...options];

  optionsSelected?.forEach((optionSelected) => {
    options?.forEach((option, index) => {
      if (option?.uuid === optionSelected?.uuid) {
        initialOptions[index] = optionSelected;
      }
    });
  });

  return sortBy(initialOptions, [sortByToLower]);
}

export function formatAmount(amount) {
  return amount?.replace('$', '');
}

export const defaultProduct = {
  nameProductField: '',
  descriptionProductField: '',
  imageProductField: '',
  soldAloneField: 1,
  priceProductField: '',
  selectedCategories: [],
  selectedModifierGroups: [],
  skuFieldMultiStore: '',
  posNameField: '',
  taxServingUnitField: '',
  taxProductKeyField: '',
  kitchenAreasFields: [],
  exemptField: false,
  taxGroupFieldIVA: '',
  taxGroupFieldIEPS: '',
  canSelectOtherAreaField: false,
  kitchenAreasFieldsCanPrint: false,
  colorField: 'FFFFFF',
  barcodeField: '',
};

export const defaultProductObject = {
  serving_unit: 'PIECES',
  pos_name: '',
  sku: '',
  kitchen_areas: {},
  is_exempt: false,
  taxes: [],
};

export function getInitialAutocompleteProduct(optionsSelectedProduct, availableOptions, type, includeOverride = false) {
  const initialOptions = [];
  optionsSelectedProduct?.forEach((group) => {
    availableOptions?.forEach((option) => {
      const groupUuid = group[type]?.uuid ? group[type]?.uuid : group[type];

      if (option.uuid === groupUuid) {
        const itemOption = { ...option };

        if (includeOverride) {
          itemOption.hasOverride = group[type]?.hasOverride ?? group?.hasOverride;
        }
        initialOptions.push(itemOption);
      }
    });
  });

  return initialOptions;
}

export function productFormIsDirty(initProduct, productForm) {
  const selectedCategoriesTmp = productForm?.selectedCategories ?? [];
  const selectedModifierGroupsTmp = productForm?.selectedModifierGroups ?? [];
  const initProductsTmp = { ...initProduct, kitchenAreasFields: initProduct?.kitchenAreasFields?.sort() };
  let productFormTmp = {
    ...productForm,
    selectedCategories: selectedCategoriesTmp,
    selectedModifierGroups: selectedModifierGroupsTmp,
    priceProductField: formatAmount(productForm?.priceProductField),
    kitchenAreasFields: productForm?.kitchenAreasFields?.sort(),
  };

  if (productFormTmp?.exemptField) {
    delete productFormTmp?.taxGroupFieldIVA;
    delete productFormTmp?.taxGroupFieldIEPS;
    delete initProductsTmp?.taxGroupFieldIVA;
    delete initProductsTmp?.taxGroupFieldIEPS;
  }

  if (productFormTmp?.soldAloneField !== 2) {
    delete initProduct?.serving;
    delete initProduct?.servingUnit;
  } else {
    productFormTmp = { ...productFormTmp, serving: Number(productFormTmp?.serving) };
  }

  const isDirty = !isEqual(initProductsTmp, productFormTmp);

  return isDirty;
}

export function productFormIsFormValid(formState, productForm) {
  const soldAlone =
    productForm?.soldAloneField === 1 || productForm?.soldAloneField === 0 || productForm?.soldAloneField === 2;
  const name = productForm?.nameProductField !== '';
  const price = productForm?.priceProductField !== '';
  const sku = productForm?.skuFieldMultiStore !== '';
  const serving = productForm?.soldAloneField !== 2 ? true : productForm?.serving && productForm?.servingUnit;

  return formState?.isValid && soldAlone && name && price && sku && serving;
}

export const typesAddingProducts = { EXISTING: 'existing', NEW: 'new' };

export const productOverrideFormIsDirty = (initProduct, productForm, isSoldByWeight) => {
  const initProductTmp = {
    ...initProduct,
    modifierGroupsField: isSoldByWeight ? undefined : initProduct?.modifierGroupsField?.map((item) => item?.uuid),
  };

  const productFormTmp = {
    ...productForm,
    priceOverrideField: formatAmount(productForm.priceOverrideField),
    modifierGroupsField: isSoldByWeight ? undefined : productForm?.modifierGroupsField?.map((item) => item?.uuid),
  };

  return !isEqual(initProductTmp, productFormTmp);
};

export const getBaseItemsOverride = (itemsBase) => {
  let base = [];
  base = itemsBase?.map((item) => item?.modifierGroup);
  return base;
};

export const getNameProductDuplicateBarcode = (message) => {
  const tmpArray = message.split(':');
  const name = tmpArray[1].substring(0, tmpArray[1].length - 38);

  return name.trim();
};
