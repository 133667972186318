import clsx from 'clsx';

import { makeStyles, ButtonBase, ButtonProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    width: '100%', // Ensure the correct width for iOS Safari
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    color: theme.palette.common.black,

    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },

  startIcon: {
    display: 'inherit',
    marginRight: 16,
    marginLeft: -4,
  },

  endIcon: {
    display: 'inherit',
    marginRight: -4,
    marginLeft: 16,
  },

  disabledLabel: {
    color: theme.palette.darkGrey[15],
  },
}));

function IconButton(props: ButtonProps) {
  const { children, startIcon, endIcon, disabled } = props;
  const classes = useStyles();

  return (
    <ButtonBase disabled={disabled} disableRipple disableTouchRipple {...props}>
      <span
        className={clsx(classes.label, {
          [classes.disabledLabel]: disabled,
        })}
      >
        {startIcon && <span className={classes.startIcon}>{startIcon}</span>}

        {children}

        {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
      </span>
    </ButtonBase>
  );
}

export default IconButton;
