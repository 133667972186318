import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { stringify } from 'query-string';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import Box from '@material-ui/core/Box';

import HeaderForm from 'components/HeaderForm';
import DisplayMessage from 'components/DisplayMessageV2';
import PageError from 'components/PageError';
import MenuMakerTable from 'components/MenuMakerTable';
import HelperSkeleton from 'components/MenuMakerTable/HelperSkeleton';

import { drawerActions, drawerEntities } from 'utils/drawerMenuMaker';

import useStyles from './styles';

function MenusContent({
  loading,
  menusError,
  menus,
  isSearchFilter,
  onReloadMenus,
  onPaginate,
  openDrawer,
  menusPagination,
  paginationState,
  onSearchMenusByText,
  onCopyMenu,
  onDeleteMenu,
  onDuplicate,
  deleteMenuState,
}) {
  const { modeDraft, isPublishMenuError } = useSidebarLayout();
  const classes = useStyles({ openDrawer, isModeDraft: modeDraft || isPublishMenuError });
  const { t } = useTranslation('menuMaker');
  const history = useHistory();

  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        if (isIntersecting && menusPagination?.next) {
          if (!paginationState.fetching) {
            onPaginate();
          }
        }
      },
      { threshold: 0 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPaginationRef, menusPagination?.next]);

  const handleOpenNewMenu = () => {
    const query = { action: drawerActions.CREATE, entity: drawerEntities.MENU };

    history.replace({ search: stringify(query) });
  };

  function renderContent() {
    if (loading) {
      return <HelperSkeleton dragActive={!isSearchFilter} />;
    }

    if (menusError) {
      return (
        <Box className={classes.containerMessageError}>
          <PageError
            data-testid="menusLoadError"
            id="menusLoadError"
            labelAction={t('common:buttons.retry')}
            maxWidth={300}
            message={t('common:errors.loaded.errorConnection')}
            onAction={onReloadMenus}
            title={t('common:errors.sorry')}
          />
        </Box>
      );
    }

    if (!menus?.length && !loading && isSearchFilter) {
      return (
        <DisplayMessage
          data-testid="menusSearchNoData"
          id="menusSearchNoData"
          maxWidth={450}
          message={t('common:messages.searchMessageNoResults')}
          sizeIcon={120}
          title={t('common:messages.searchTitleNoResults')}
        />
      );
    }

    if (!menus?.length && !loading && !isSearchFilter) {
      return (
        <DisplayMessage
          data-testid="menusNoData"
          id="menusNoData"
          message={t('menuMaker:messages.noMenus')}
          sizeIcon={120}
        />
      );
    }

    return (
      <MenuMakerTable
        deleteMenuState={deleteMenuState}
        isSearchFilter={isSearchFilter}
        loadingPagination={paginationState.fetching}
        menus={menus}
        onCopyMenu={onCopyMenu}
        onDeleteMenu={onDeleteMenu}
        onDuplicate={onDuplicate}
        scrollPaginationRef={scrollPaginationRef}
      />
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.contentMenus}>
        <HeaderForm
          addButtonLabel={t('menuMaker:buttons.menu')}
          isShowFilterBrands
          onAddButtonClick={handleOpenNewMenu}
          onSearchBoxChange={onSearchMenusByText}
          openDrawer={openDrawer}
          px={5}
          searchLabel={t('menuMaker:headerForm.searchMenu')}
        />

        {renderContent()}
      </Box>
    </Box>
  );
}

MenusContent.propTypes = {
  loading: PropTypes.bool,
  menusError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  menus: PropTypes.array,
  isSearchFilter: PropTypes.bool,
  onReloadMenus: PropTypes.func,
  onPaginate: PropTypes.func,
  openDrawer: PropTypes.bool,
  menusPagination: PropTypes.object,
  paginationState: PropTypes.object,
  onSearchMenusByText: PropTypes.func,
  onCopyMenu: PropTypes.func,
  onDeleteMenu: PropTypes.func,
  onDuplicate: PropTypes.func,
  deleteMenuState: PropTypes.object,
};

export default MenusContent;
