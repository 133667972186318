import React from 'react';

import { FeatureContext } from '../FeatureContext';

export default function useFeatures() {
  const features = React.useContext(FeatureContext);
  if (features === null) {
    throw new Error('You must wrap your components in a FeatureProvider.');
  }
  return features;
}
