/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Skeleton } from '@material-ui/lab';

import ReportsTable from 'components/ReportsTable';
import DisplayMessage from 'components/DisplayMessageV2';
import PlaceholdersTable from 'components/PlaceholdersTable';
import TableCustomContainer from 'components/GeneralTable/TableContainer';

import { ContentProps, RowsProps } from '../types';

interface HeadersProps {
  name: string;
  sorting: number;
  tooltip?: string | null;
}

interface Props {
  isLoading?: boolean;
  isLoadingNextPage?: boolean;
  isFetched?: boolean;
  headers: HeadersProps[];
  rows: RowsProps[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
}

interface ContentItemProps {
  content: ContentProps[];
}

interface ValueProps {
  symbol?: string | null;
  symbolPosition?: string | null;
  value?: string | number | null;
}

function Value({ symbol, symbolPosition, value }: ValueProps) {
  let valueToShow = value || '';
  if (symbol && symbolPosition === 'left') {
    valueToShow = `${symbol}${valueToShow}`;
  }
  if (symbol && symbolPosition === 'right') {
    valueToShow = `${valueToShow}${symbol}`;
  }
  return <Box>{valueToShow}</Box>;
}

function Content({ content }: ContentItemProps) {
  return (
    <>
      {content.map((itemContent, index) => (
        <ReportsTable.Cell
          key={`${itemContent.value}-${index}`}
          align={itemContent?.alignment}
          component="th"
          scope="row"
        >
          <Value symbol={itemContent.symbol} symbolPosition={itemContent.symbolPosition} value={itemContent.value} />
        </ReportsTable.Cell>
      ))}
    </>
  );
}

function List({ isLoading, isLoadingNextPage, headers, rows, hasNextPage, fetchNextPage, isFetched }: Props) {
  const { t } = useTranslation('reports');

  function handleOnIntersecting() {
    if (!isLoadingNextPage) fetchNextPage();
  }

  if (isLoading) {
    return (
      <ReportsTable>
        <ReportsTable.Head>
          <ReportsTable.Row>
            {Array.from(Array(7).keys()).map((item) => (
              <ReportsTable.HeadCell key={item} align="left">
                <Skeleton height={20} variant="text" width={100} />
              </ReportsTable.HeadCell>
            ))}
          </ReportsTable.Row>
        </ReportsTable.Head>
        <ReportsTable.Body>
          <PlaceholdersTable placeholderCols={7} />
        </ReportsTable.Body>
      </ReportsTable>
    );
  }

  if (rows.length === 0 && !isLoading && isFetched) {
    return (
      <Box mt={24}>
        <DisplayMessage
          height="90%"
          id="DisplayMessageEmptyNoData"
          maxWidth={384}
          message={t('reports:messages.noInformationTryWithOtherDate')}
          sizeIcon={120}
          sizeMessage={16}
          sizeTitle={18}
          title=""
        />
      </Box>
    );
  }

  return (
    <TableCustomContainer hasNextPage={hasNextPage} onIntersecting={handleOnIntersecting} stickyHeader>
      <ReportsTable.Head>
        <ReportsTable.Row>
          {headers.map((head) => (
            <ReportsTable.HeadCell key={head.name} align="left">
              {head?.name}
            </ReportsTable.HeadCell>
          ))}
        </ReportsTable.Row>
      </ReportsTable.Head>

      <ReportsTable.Body>
        {rows?.map((row, index) => (
          <ReportsTable.Row key={`${row.uuid}-${index}`}>
            <Content content={row.content} />
          </ReportsTable.Row>
        ))}

        {isLoadingNextPage && <PlaceholdersTable placeholderCols={7} />}
      </ReportsTable.Body>
    </TableCustomContainer>
  );
}

export default List;
