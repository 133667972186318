import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const EmptyBrands = forwardRef(function EmptyBrands(props, ref) {
  const { color = '#A3A3AF', size = 56, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 56 56"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M50.4091 16.1856C51.0206 17.8301 51.4574 19.6477 51.7195 21.4654C53.4668 34.6215 44.2063 46.6524 30.927 48.3835"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          d="M7.86267 11.0786C11.5319 5.62573 17.4727 1.81738 24.5491 0.865298C34.0717 -0.433002 43.0702 3.98122 47.9625 11.4248"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          d="M25.3357 48.47C14.4152 47.3448 5.32943 39.0357 3.75688 27.7838C3.2327 23.7158 3.75688 19.7343 5.1547 16.0991"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          d="M28.0442 55.3945V31.0731"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          d="M20.8804 37.3046L28.0442 30.4669L35.208 37.1315"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          d="M55.3884 10.3864L33.6349 21.7249"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          d="M42.5459 25.1005L33.198 21.9846L35.7315 12.6368"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          d="M0.698853 10.3864L22.365 21.7249"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          d="M13.4534 25.1005L22.8886 21.9846L20.2677 12.6368"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="56" width="56" />
        </clipPath>
      </defs>
    </svg>
  );
});

EmptyBrands.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EmptyBrands;
