import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_DISCOUNTS,
  GET_DISCOUNTS_PAGINATION,
  GET_DISCOUNT_DETAIL,
  CREATE_DISCOUNT,
  DELETE_DISCOUNT,
  SET_IS_ENABLED_DISCOUNT,
} from './constants';

export const getDiscounts = extendRoutine(createRoutine(GET_DISCOUNTS), ['SORTING', 'RESET_SORTING', 'RESET']);
export const getDiscountsPagination = extendRoutine(createRoutine(GET_DISCOUNTS_PAGINATION), ['RESET']);

export const getDiscountDetail = extendRoutine(createRoutine(GET_DISCOUNT_DETAIL), 'RESET');
export const createDiscount = extendRoutine(createRoutine(CREATE_DISCOUNT), 'RESET');
export const deleteDiscount = extendRoutine(createRoutine(DELETE_DISCOUNT), 'RESET');
export const setIsEnabledDiscount = extendRoutine(createRoutine(SET_IS_ENABLED_DISCOUNT), 'RESET');
