import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { createModifierGroupRequest } from 'utils/api/baas/menuMaker/modifierGroups';

import { createModifierGroup } from '../actions';

export function* handleCreateModifierGroupRequest(action) {
  const { storeUuid, modifierGroup } = action.payload;
  try {
    yield put(createModifierGroup.request());

    const { data } = yield call(baasRequest, createModifierGroupRequest, {
      storeUuid,
      modifierGroup,
    });

    yield put(createModifierGroup.success(data));
  } catch (err) {
    yield put(createModifierGroup.failure({ error: err }));
  }
}
