import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getModifierGroup, editModifierGroup } from './actions';
import {
  // Get Modifier Groups
  selectModifierGroupError,
  selectModifierGroupFetched,
  selectModifierGroupInstance,
  selectModifierGroupFetching,
  // edit Modifier Group
  selectEditModifierGroupError,
  selectEditModifierGroupFetching,
  selectEditModifierGroupFetched,
  selectEditModifierGroupInstance,
} from './selectors';

export function withModifierGroupDetailsHoc(Component) {
  function withModifierGroupDetails(props) {
    return <Component {...props} />;
  }

  withModifierGroupDetails.displayName = `withModifierGroupDetails(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withModifierGroupDetails;
}

const mapStateToProps = createStructuredSelector({
  // Get Modifier Groups
  modifierGroupObject: selectModifierGroupInstance,
  modifierGroupFetched: selectModifierGroupFetched,
  modifierGroupError: selectModifierGroupError,
  modifierGroupFetching: selectModifierGroupFetching,
  // edit Modifier Group
  editModifierGroupError: selectEditModifierGroupError,
  editModifierGroupFetching: selectEditModifierGroupFetching,
  editModifierGroupFetched: selectEditModifierGroupFetched,
  editModifierGroupObject: selectEditModifierGroupInstance,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadModifierGroup: (storeUuid, modifierGroupUuid) => dispatch(getModifierGroup({ storeUuid, modifierGroupUuid })),
    resetModifierGroupState: () => dispatch(getModifierGroup.reset()),
    editModifierGroup: (storeUuid, modifierGroup, originMenuUuid) =>
      dispatch(editModifierGroup({ storeUuid, originMenuUuid, modifierGroup })),
    resetEditModifierGroup: () => dispatch(editModifierGroup.reset()),
  };
}

export const withModifierGroupDetailsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withModifierGroupDetailsState, withModifierGroupDetailsHoc);
