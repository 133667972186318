import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getRole, saveRole } from './actions';
import {
  // Get Role
  selectRole,
  selectRoleError,
  selectRoleIsFetching,
  selectRoleDidFetch,
  // Save Role
  selectSaveRoleError,
  selectSaveRoleIsFetching,
  selectSaveRoleDidFetch,
} from './selectors';

export function withRoleHoc(Component) {
  function withRoles(props) {
    return <Component {...props} />;
  }

  withRoles.displayName = `withRole(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withRoles;
}

const mapStateToProps = createStructuredSelector({
  // Get Role
  selectRole,
  selectRoleError,
  selectRoleIsFetching,
  selectRoleDidFetch,
  // Save Role
  selectSaveRoleError,
  selectSaveRoleIsFetching,
  selectSaveRoleDidFetch,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadRole: (roleUuid) => dispatch(getRole(roleUuid)),
    resetLoadRole: () => dispatch(getRole.reset()),
    saveRole: (role) => dispatch(saveRole(role)),
    resetSaveRole: () => dispatch(saveRole.reset()),
  };
}

export const withRoleState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRoleState, withRoleHoc);
