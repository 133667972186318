export function cleanObject(object) {
  const newObject = { ...object };
  const keys = Object.keys(newObject);
  if (keys.length > 0) {
    keys.forEach((key) => {
      if (!newObject[key] && typeof newObject[key] !== 'boolean' && typeof newObject[key] !== 'number') {
        delete newObject[key];
      }
    });
    return newObject;
  }
  return object;
}

export function cloneObject(object = {}) {
  return JSON.parse(JSON.stringify(object));
}
