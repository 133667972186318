import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';

import defaultFlags from 'utils/featureFlags/defaultFlags';

const firebaseConfig = {
  apiKey: 'AIzaSyCzeN2UU8JEp_H5zPmZ3zM8KEsRoIh6VWk',
  authDomain: 'featureflags-8034b.firebaseapp.com',
  projectId: 'featureflags-8034b',
  storageBucket: 'featureflags-8034b.appspot.com',
  messagingSenderId: '89160173915',
  appId: '1:89160173915:web:c690481b7c5b723ded727e',
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyCzeN2UU8JEp_H5zPmZ3zM8KEsRoIh6VWk',
  authDomain: 'featureflags-8034b.firebaseapp.com',
  projectId: 'featureflags-8034b',
  storageBucket: 'featureflags-8034b.appspot.com',
  messagingSenderId: '89160173915',
  appId: '1:89160173915:web:f2cfc250c60419c4ed727e',
};

const app = initializeApp(process.env.REACT_APP_VERCEL_ENV === 'production' ? firebaseConfig : firebaseConfigDev);
export const analytics = getAnalytics(app);

export const getRemoteConfigFlag = () => {
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings.minimumFetchIntervalMillis = process.env.REACT_APP_VERCEL_ENV === 'production' ? 450 : 0;
  remoteConfig.defaultConfig = defaultFlags;
  return remoteConfig;
};
