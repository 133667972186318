import produce from 'immer';
import baseState from 'utils/baseState';

import {
  getPayments,
  getPaymentDetail,
  createPayment,
  deletePayment,
  togglePaymentAvailability,
  editPayment,
  getPaymentsScrollPagination,
} from './actions';

export const initialState = {
  getPayments: {
    ...baseState,
    payments: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  paginationGetPayments: {
    ...baseState,
    lastUpdatedRows: [],
  },

  getPaymentDetail: {
    ...baseState,
    payment: {},
  },

  sorting: {
    filterByText: '',
  },

  createPayment: {
    ...baseState,
    payment: {},
  },

  deletePayment: {
    ...baseState,
  },

  togglePaymentAvailability: {
    ...baseState,
  },

  editPayment: {
    ...baseState,
    payment: {},
  },
};

const restaurantPaymentsProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getPayments.REQUEST:
        draft.getPayments.fetching = true;
        draft.getPayments.error = false;
        draft.getPayments.fetched = false;
        draft.getPayments.pagination = { ...initialState.getPayments.pagination };
        draft.sorting = {
          ...initialState.sorting,
        };
        break;

      case getPayments.SUCCESS:
        draft.getPayments.fetching = false;
        draft.getPayments.fetched = true;
        draft.getPayments.payments = payload?.payments;
        draft.getPayments.pagination = {
          ...draft.getPayments.pagination,
          ...payload.pagination,
        };
        break;

      case getPayments.FAILURE:
        draft.getPayments.error = payload;
        draft.getPayments.fetching = false;
        draft.getPayments.fetched = false;
        break;

      case getPayments.RESET:
        draft.getPayments = { ...initialState.getPayments };
        break;

      case getPayments.SORTING:
        draft.getPayments.pagination = {
          ...draft.getPayments.pagination,
          ...initialState.getPayments.pagination,
        };

        draft.sorting = {
          ...draft.sorting,
          ...payload,
        };
        break;

      case getPayments.RESET_SORTING:
        draft.sorting = {
          ...initialState.sorting,
        };
        break;

      case getPayments.SORT_POSITION: {
        draft.getPayments.payments = payload;
        break;
      }

      case getPaymentsScrollPagination.REQUEST:
        draft.paginationGetPayments = {
          ...initialState.paginationGetPayments,
          fetching: true,
        };
        break;

      case getPaymentsScrollPagination.SUCCESS: {
        const currentPaymentsState = state.getPayments.payments;

        let newRows = [...currentPaymentsState];
        newRows = [...newRows, ...payload.payments];

        draft.paginationGetPayments.fetching = false;
        draft.paginationGetPayments.fetched = true;
        draft.paginationGetPayments.lastUpdatedRows = payload.data;

        draft.getPayments.payments = [...newRows];
        draft.getPayments.pagination = {
          ...initialState.getPayments.pagination,
          ...payload.pagination,
        };
        break;
      }

      case getPaymentsScrollPagination.FAILURE:
        draft.paginationGetPayments.error = payload;
        draft.paginationGetPayments.fetching = false;
        draft.paginationGetPayments.fetched = false;
        break;

      case getPaymentsScrollPagination.RESET:
        draft.paginationGetPayments = {
          ...initialState.paginationGetPayments,
        };
        break;

      // detail payment
      case getPaymentDetail.REQUEST:
        draft.getPaymentDetail.fetching = true;
        draft.getPaymentDetail.fetched = false;
        draft.getPaymentDetail.error = false;
        break;

      case getPaymentDetail.SUCCESS:
        draft.getPaymentDetail.fetching = false;
        draft.getPaymentDetail.fetched = true;
        draft.getPaymentDetail.error = false;
        draft.getPaymentDetail.payment = payload;
        break;

      case getPaymentDetail.FAILURE:
        draft.getPaymentDetail.error = true;
        draft.getPaymentDetail.fetching = false;
        draft.getPaymentDetail.fetched = false;
        break;

      case getPaymentDetail.RESET:
        draft.getPaymentDetail = { ...initialState.getPaymentDetail };
        break;

      // create payment
      case createPayment.REQUEST:
        draft.createPayment.fetching = true;
        draft.createPayment.error = false;
        draft.createPayment.fetched = false;
        break;

      case createPayment.SUCCESS: {
        const paymentsList = [...draft.getPayments.payments];
        paymentsList.push(payload);

        draft.createPayment.fetching = false;
        draft.createPayment.fetched = true;
        draft.createPayment.payment = payload;
        draft.getPayments.payments = paymentsList;
        break;
      }

      case createPayment.FAILURE:
        draft.createPayment.error = payload;
        draft.createPayment.fetching = false;
        draft.createPayment.fetched = false;
        break;

      case createPayment.RESET:
        draft.createPayment = { ...initialState.createPayment };
        break;

      // delete payment
      case deletePayment.REQUEST:
        draft.deletePayment.fetching = true;
        draft.deletePayment.error = false;
        draft.deletePayment.fetched = false;
        break;

      case deletePayment.SUCCESS: {
        const paymentsList = draft.getPayments.payments.filter((payment) => payment.uuid !== payload);

        draft.deletePayment.fetching = false;
        draft.deletePayment.fetched = true;
        draft.getPayments.payments = paymentsList;

        break;
      }

      case deletePayment.FAILURE:
        draft.deletePayment.error = payload;
        draft.deletePayment.fetching = false;
        draft.deletePayment.fetched = false;
        break;

      case deletePayment.RESET:
        draft.deletePayment = { ...initialState.deletePayment };
        break;

      // toggle Payment Availability
      case togglePaymentAvailability.TRIGGER:
        draft.getPayments.payments.forEach((payment, index) => {
          if (payment.uuid === payload.paymentUuid) {
            draft.getPayments.payments[index].isChangingAvailability = true;
          }
        });
        break;
      case togglePaymentAvailability.REQUEST:
        draft.togglePaymentAvailability.fetching = true;
        draft.togglePaymentAvailability.error = false;
        draft.togglePaymentAvailability.fetched = false;
        break;

      case togglePaymentAvailability.SUCCESS:
        draft.getPayments.payments.forEach((payment, index) => {
          if (payment.uuid === payload.paymentUuid) {
            draft.getPayments.payments[index].isChangingAvailability = false;
            draft.getPayments.payments[index] = payload.data;
          }
        });

        draft.togglePaymentAvailability.fetching = false;
        draft.togglePaymentAvailability.fetched = true;
        break;

      case togglePaymentAvailability.FAILURE:
        draft.togglePaymentAvailability.error = payload;
        draft.togglePaymentAvailability.fetching = false;
        draft.togglePaymentAvailability.fetched = false;
        draft.getPayments.payments.forEach((payment, index) => {
          if (payment.uuid === payload.paymentUuid) {
            draft.getPayments.payments[index].isChangingAvailability = false;
          }
        });
        break;

      case togglePaymentAvailability.RESET:
        draft.togglePaymentAvailability = { ...initialState.togglePaymentAvailability };
        break;

      // edit payment
      case editPayment.REQUEST:
        draft.editPayment.fetching = true;
        draft.editPayment.fetched = false;
        draft.editPayment.error = false;
        break;

      case editPayment.SUCCESS: {
        const paymentsList = draft.getPayments.payments.map((payment) => {
          return { ...payment };
        });

        paymentsList.forEach((payment, index) => {
          if (payment?.uuid === payload?.uuid) {
            paymentsList[index] = payload;
          }
        });

        draft.editPayment.fetching = false;
        draft.editPayment.fetched = true;
        draft.editPayment.payment = payload;
        draft.getPayments.payments = paymentsList;
        break;
      }

      case editPayment.FAILURE:
        draft.editPayment.error = payload;
        draft.editPayment.fetched = false;
        draft.editPayment.fetching = false;
        break;

      case editPayment.RESET:
        draft.editPayment = { ...initialState.editPayment };
        break;

      default:
        return draft;
    }
  });

export default restaurantPaymentsProviderReducer;
