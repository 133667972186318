import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { useListsOptions } from 'providers/root/ListsOptionsProvider';
import useSortingListsOptions from 'hooks/useSortingListsOptions';

import LiveSearch from '@experimental-components/LiveSearch';

import { sortingPropertyTypes } from 'utils/products';

function OptionSelect({ selectedOptions, onChange, t }) {
  const {
    productsAutocomplete: options,
    productsAutocompletePaginationValues,
    productsAutocompletePaginationState,
    getListProductsAutocompletePagination,
  } = useListsOptions();
  const {
    getProductsAutocompleteFilter: {
      productsAutocompleteFilterValues,
      productsAutocompleteFilterState,
      setProductsAutocompleteFilterByText,
    },
  } = useSortingListsOptions();

  function getFinalOptions() {
    const selectOptionByUuid = [...selectedOptions].map((option) => option?.uuid);
    const optionsTmp = [...options]?.filter((option) => !selectOptionByUuid.includes(option?.uuid));

    return optionsTmp.map((item) => ({ ...item, additionalPrice: item?.price || 0 }));
  }

  const handlePaginationProductsOptions = useCallback(() => {
    const currentPage = productsAutocompletePaginationValues.page + 1;

    if (!productsAutocompletePaginationState.fetching) {
      getListProductsAutocompletePagination({ page: currentPage, ...productsAutocompleteFilterValues });
    }
  }, [
    getListProductsAutocompletePagination,
    productsAutocompletePaginationState.fetching,
    productsAutocompletePaginationValues.page,
    productsAutocompleteFilterValues,
  ]);

  const handleInputChange = (value) => {
    if (!productsAutocompleteFilterState.fetching)
      setProductsAutocompleteFilterByText({ filterByText: value, orderBy: sortingPropertyTypes.NAME });
  };

  return (
    <LiveSearch
      getOptionKey={(item) => item?.uuid}
      getOptionLabel={(item) => item?.name}
      hasNextPage={Boolean(productsAutocompletePaginationValues?.next)}
      id="filled-adornment-autocomplete-modifiers"
      isFilterLoading={productsAutocompleteFilterState.fetching}
      isNextPageLoading={productsAutocompletePaginationState.fetching}
      noOptionsText={
        productsAutocompleteFilterValues?.filterByText
          ? t('menuMaker:modifierGroupForm.labels.emptyModifierGroups')
          : t('menuMaker:modifierGroupForm.labels.emptyProducts')
      }
      onChange={onChange}
      onChangeInput={handleInputChange}
      onLoadNextPage={handlePaginationProductsOptions}
      options={getFinalOptions()}
      placeholder={t('menuMaker:modifierGroupForm.labels.optionsPlaceholder')}
    />
  );
}

OptionSelect.propTypes = {
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  t: PropTypes.func,
};

export default compose(withTranslation('menuMaker'), memo)(OptionSelect);
