import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paragraph: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));

function KitchenAreasBox({ kitchenAreasNames }) {
  const classes = useStyles();
  return (
    <Box className={classes.paragraph} mb={6} mt={6}>
      {kitchenAreasNames?.map((name) => (
        <Box key={`${name}`} component="span" fontWeight="fontWeightMedium" mr={4}>
          {name}
        </Box>
      ))}
    </Box>
  );
}

KitchenAreasBox.propTypes = {
  kitchenAreasNames: PropTypes.array,
};

export default KitchenAreasBox;
