import { makeStyles, Divider as DividerMui, DividerProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.darkGrey[5],
  },
}));

function Divider(props: DividerProps) {
  const classes = useStyles();

  return <DividerMui classes={classes} {...props} />;
}

export default Divider;
