import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getServiceCharges,
  getServiceChargesByPagination,
  getServiceCharge,
  createServiceCharge,
  updateServiceCharge,
  deleteServiceCharge,
  setIsEnabledServiceCharge,
} from './actions';

import {
  selectServiceCharges,
  selectServiceChargesState,
  selectServiceChargesPagination,
  selectServiceChargesPaginationState,
  selectServiceChargeDetails,
  selectServiceChargeDetailsState,
  selectCreateServiceCharge,
  selectCreateServiceChargeState,
  selectUpateServiceCharge,
  selectUpateServiceChargeState,
  selectDeleteServiceChargeState,
  selectSetIsEnabledServiceChargeState,
} from './selectors';

export function withSettingsServiceChargesHoc(Component) {
  function withSettingsServiceCharges(props) {
    return <Component {...props} />;
  }

  withSettingsServiceCharges.displayName = `withSettingsServiceCharges(${getDisplayName(Component)})`;

  return withSettingsServiceCharges;
}

const mapStateToProps = createStructuredSelector({
  serviceCharges: selectServiceCharges,
  serviceChargesState: selectServiceChargesState,
  serviceChargesPaginationValues: selectServiceChargesPagination,
  serviceChargesPaginationState: selectServiceChargesPaginationState,
  serviceChargeDetails: selectServiceChargeDetails,
  serviceChargeDetailsState: selectServiceChargeDetailsState,
  createServiceChargeObjec: selectCreateServiceCharge,
  createServiceChargeState: selectCreateServiceChargeState,
  updateServiceChargeObjec: selectUpateServiceCharge,
  updateServiceChargeState: selectUpateServiceChargeState,
  deleteServiceChargeState: selectDeleteServiceChargeState,
  setIsEnabledServiceChargeState: selectSetIsEnabledServiceChargeState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadServiceCharges: (filters) => dispatch(getServiceCharges(filters)),
    loadServiceChargesByPagination: ({ page }) => dispatch(getServiceChargesByPagination({ page })),
    loadServiceChargeDetail: (serviceChargeUuid) => dispatch(getServiceCharge(serviceChargeUuid)),
    createServiceCharge: (serviceCharge) => dispatch(createServiceCharge(serviceCharge)),
    updateServiceCharge: ({ serviceChargeUuid, serviceCharge }) =>
      dispatch(updateServiceCharge({ serviceChargeUuid, serviceCharge })),
    deleteServiceCharge: (serviceChargeUuid) => dispatch(deleteServiceCharge(serviceChargeUuid)),
    setIsEnabledServiceCharge: ({ serviceChargeUuid, isEnabled }) =>
      dispatch(setIsEnabledServiceCharge({ serviceChargeUuid, isEnabled })),
    resetServicesCharge: () => dispatch(getServiceCharges.reset()),
    resetServicesChargeByPagination: () => dispatch(getServiceChargesByPagination.reset()),
    resetServiceChargeDetail: () => dispatch(getServiceCharge.reset()),
    resetCreateServiceCharge: () => dispatch(createServiceCharge.reset()),
    resetUpdateServiceCharge: () => dispatch(updateServiceCharge.reset()),
    resetDeleteServiceCharge: () => dispatch(deleteServiceCharge.reset()),
    resetSetIsEnabledServiceCharg: () => dispatch(setIsEnabledServiceCharge.reset()),
  };
}

export const withSettingsServiceChagesState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withSettingsServiceChagesState, withSettingsServiceChargesHoc);
