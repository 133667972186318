/* eslint-disable no-unused-vars */
import produce from 'immer';

import { getRole, saveRole } from './actions';

export const initialState = {
  getRole: {
    error: null,
    isFetching: false,
    didFetch: false,
    instance: null,
  },
  saveRole: {
    error: null,
    isFetching: false,
    didFetch: false,
  },
};

const roleProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      /*
        Get
      */
      case getRole.REQUEST:
        draft.getRole.error = null;
        draft.getRole.isFetching = true;
        draft.getRole.didFetch = false;
        draft.getRole.instance = null;
        break;

      case getRole.SUCCESS:
        draft.getRole.isFetching = false;
        draft.getRole.didFetch = true;
        draft.getRole.instance = payload;
        break;

      case getRole.FAILURE:
        draft.getRole.error = payload;
        draft.getRole.isFetching = false;
        draft.getRole.didFetch = false;
        draft.getRole.instance = null;
        break;

      case getRole.RESET:
        draft.getRole = { ...initialState.getRole };
        break;

      /*
        Save
      */
      case saveRole.REQUEST:
        draft.saveRole.error = null;
        draft.saveRole.isFetching = true;
        draft.saveRole.didFetch = false;
        break;

      case saveRole.SUCCESS:
        draft.saveRole.isFetching = false;
        draft.saveRole.didFetch = true;
        // Update current role
        draft.getRole.instance = payload;
        break;

      case saveRole.FAILURE:
        draft.saveRole.error = payload;
        draft.saveRole.isFetching = false;
        draft.saveRole.didFetch = false;
        break;

      case saveRole.RESET:
        draft.saveRole = { ...initialState.saveRole };
        break;

      default:
        return draft;
    }
  });

export default roleProviderReducer;
