import request from 'utils/request';
import queryString from 'query-string';

import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

// Roles
export function getRolesRequest(storeUUID, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUUID}/jobs/`, {
    method: 'GET',
    ...options,
  });
}

export function duplicateRoleRequest(jobUUID, options = {}) {
  return request(`${API_BASE_URL}/v1/jobs/${jobUUID}/duplicate/`, {
    method: 'POST',
    ...options,
  });
}

export function deleteRoleRequest(jobUUID, options = {}) {
  return request(`${API_BASE_URL}/v1/jobs/${jobUUID}/`, {
    method: 'DELETE',
    ...options,
  });
}

// Role
export function getRoleRequest(jobUUID, options = {}) {
  return request(`${API_BASE_URL}/v1/jobs/${jobUUID}/`, {
    method: 'GET',
    ...options,
  });
}

export function createRoleRequest(body, options = {}) {
  return request(`${API_BASE_URL}/v1/jobs/`, {
    method: 'POST',
    body: JSON.stringify(body),
    ...options,
  });
}

export function updateRoleRequest(jobUUID, body, options = {}) {
  return request(`${API_BASE_URL}/v1/jobs/${jobUUID}/`, {
    method: 'PUT',
    body: JSON.stringify(body),
    ...options,
  });
}

// Permissions
export function getPermissionsRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/permissions/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getPermissionsByRolesRequest(jobsUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    job_uuid: jobsUuid,
  });

  return request(`${API_BASE_URL}/v1/jobs/permissions/?${query}`, {
    method: 'GET',
    ...options,
  });
}
