import Radio, { RadioProps } from '@material-ui/core/Radio';
import clsx from 'clsx';

import useStyles from './styles';

interface Props extends RadioProps {
  colorIcon: string;
  checked: boolean;
}

function RadioColor({ colorIcon = 'FFFFFF', ...others }: Props) {
  const classes = useStyles({ colorIcon });

  return (
    <Radio
      checkedIcon={<span className={clsx(classes.radioIcon, classes.radioChecked)} />}
      className={classes.radioRoot}
      color="default"
      disableRipple
      icon={<span className={classes.radioIcon} />}
      name="color"
      {...others}
    />
  );
}

export default RadioColor;
