import PropTypes from 'prop-types';

import clsx from 'clsx';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonNavigationBack from '@design-system/ButtonNavigationBack';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import history from 'utils/history';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  appBar: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    marginBottom: theme.spacing(2),
  },

  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: 'auto !important',
  },

  main: {
    flexGrow: 1,
    '& main': {
      marginTop: 0,
    },
  },
}));

const Title = ({ children, style = { paddingLeft: '2rem' }, ...props }) => (
  <Typography
    component="h1"
    style={{
      fontWeight: 500,
      fontSize: '20px',
      marginBottom: '16px',
      lineHeight: '1',
      color: 'black',
      ...style,
    }}
    variant="h5"
    {...props}
  >
    {children}
  </Typography>
);

const TopBar = ({ children }) => {
  const { openMdDrawer } = useSidebarLayout();
  const classes = useStyles({ open: openMdDrawer });
  return (
    <AppBar className={clsx(classes.appBar)} elevation={0} position="sticky">
      <Toolbar className={clsx(classes.toolbar)}>{children}</Toolbar>
    </AppBar>
  );
};

function ViewPanelLayout({
  actionComponent: actionComponentProp,
  children,
  disabledGoBack,
  labelAction,
  labelGoBack,
  onAction,
  onGoBack,
  title,
  componentHeader,
  defaultGoBack,
  goBackPath,
  t,
  titleProps = {},
  ...others
}) {
  const { openMdDrawer } = useSidebarLayout();
  const theme = useTheme();
  const classes = useStyles({ open: openMdDrawer });

  if (componentHeader) {
    return (
      <>
        <Box mb={6}>{componentHeader}</Box>

        <Box component="main" pb={4} pl={4} pr={4} role="main" {...others}>
          {children}
        </Box>
      </>
    );
  }

  function handleGoBack() {
    if (goBackPath) {
      return history.replace(goBackPath);
    }
    return history.goBack();
  }

  return (
    <Box className={classes.main} component="main" pb={theme.typography.pxToRem(32)} role="main" {...others}>
      {/* TODO: Refactor those conditions */}
      {!title && (
        <TopBar>
          {onGoBack && (
            <ButtonNavigationBack data-testid="btnGoBack" disabled={disabledGoBack} onClick={onGoBack}>
              {labelGoBack}
            </ButtonNavigationBack>
          )}
          {defaultGoBack && (
            <ButtonNavigationBack disabled={disabledGoBack} onClick={handleGoBack}>
              {t('common:buttons.toReturn')}
            </ButtonNavigationBack>
          )}

          {actionComponentProp && <>{actionComponentProp}</>}
          {onAction && <ButtonPrimary onClick={onAction}>{labelAction}</ButtonPrimary>}
        </TopBar>
      )}
      {title && !actionComponentProp && !defaultGoBack && !goBackPath && (
        <TopBar>
          <Title {...titleProps}>{title}</Title>
        </TopBar>
      )}
      {title && actionComponentProp && (
        <TopBar>
          <Title {...titleProps}>{title}</Title>
          {actionComponentProp}
          {onAction && <ButtonPrimary onClick={onAction}>{labelAction}</ButtonPrimary>}
        </TopBar>
      )}
      {(defaultGoBack || goBackPath) && (
        <>
          <TopBar>
            <ButtonNavigationBack data-testid="btnGoBack" onClick={handleGoBack}>
              {t('common:buttons.toReturn')}
            </ButtonNavigationBack>
          </TopBar>
          {title && <Title {...titleProps}>{title}</Title>}
        </>
      )}
      <Box pl={theme.typography.pxToRem(32)} pr={theme.typography.pxToRem(32)} {...others}>
        {children}
      </Box>
    </Box>
  );
}

ViewPanelLayout.propTypes = {
  disabledGoBack: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  labelGoBack: PropTypes.string,
  onGoBack: PropTypes.func,
  labelAction: PropTypes.string,
  onAction: PropTypes.func,
  actionComponent: PropTypes.element,
  componentHeader: PropTypes.element,
  titleProps: PropTypes.object,
};

export default withTranslation()(ViewPanelLayout);
