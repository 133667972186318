/**
 *
 * ParrotSoftware
 * PaymentsGatewayProvider saga
 *
 */
import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getPaymentsMethodsRequest,
  getResumePaymentMethodRequest,
  createPaymentMethodRequest,
  updatePaymentMethodRequest,
} from 'utils/api/baas/paymentsGateway/paymentsGateway';

import { getPaymentsMethods, createPaymentMethod, resumePaymentMethod, updatePaymentMethodStatus } from './actions';

export function* handleGetPaymentsMethods() {
  try {
    yield put(getPaymentsMethods.request());

    const { data } = yield call(baasRequest, getPaymentsMethodsRequest);

    yield put(getPaymentsMethods.success(data));
  } catch (err) {
    yield put(getPaymentsMethods.failure(err));
  }
}

export function* handleCreatePaymentMethod(action) {
  const provider = action.payload;
  try {
    yield put(createPaymentMethod.request());

    const { data } = yield call(baasRequest, createPaymentMethodRequest, provider);

    yield put(createPaymentMethod.success(data));
  } catch (err) {
    yield put(createPaymentMethod.failure(err));
  }
}

export function* handleGetResumePaymentMethod(action) {
  const { provider, uuid } = action.payload;
  try {
    yield put(resumePaymentMethod.request());

    const { data } = yield call(baasRequest, getResumePaymentMethodRequest, { provider, paymentProviderUuid: uuid });

    yield put(resumePaymentMethod.success(data));
  } catch (err) {
    yield put(resumePaymentMethod.failure(err));
  }
}

export function* handleUpdatePaymentMethodStatus(action) {
  const { provider, status } = action.payload;
  try {
    yield put(updatePaymentMethodStatus.request());

    const { data } = yield call(baasRequest, updatePaymentMethodRequest, { provider, status });

    yield put(updatePaymentMethodStatus.success({ data }));
  } catch (err) {
    yield put(updatePaymentMethodStatus.failure(err));
  }
}

export default function* paymentsGatewayRootSga() {
  yield takeLatest(getPaymentsMethods.TRIGGER, handleGetPaymentsMethods);
  yield takeLatest(createPaymentMethod.TRIGGER, handleCreatePaymentMethod);
  yield takeLatest(resumePaymentMethod.TRIGGER, handleGetResumePaymentMethod);
  yield takeLatest(updatePaymentMethodStatus.TRIGGER, handleUpdatePaymentMethodStatus);
}
