import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  getPaymentsRequest,
  getPaymentDetailRequest,
  createPaymentRequest,
  deletePaymentRequest,
  paymentAvailabilityRequest,
  editPaymentRequest,
  updatePaymentPositionRequest,
} from 'utils/api/baas/restaurant/payments';

import {
  getPayments,
  getPaymentDetail,
  createPayment,
  deletePayment,
  togglePaymentAvailability,
  editPayment,
  updatePaymentPosition,
  getPaymentsScrollPagination,
} from './actions';

export function* handleGetPaymentsRequest(action) {
  const storeUuid = action.payload;

  try {
    yield put(getPayments.request());

    const { data: payments, pagination } = yield call(baasRequest, getPaymentsRequest, storeUuid);

    yield put(getPayments.success({ payments, pagination }));
  } catch (err) {
    yield put(getPayments.failure(err));
  } finally {
    yield put(getPayments.fulfill());
  }
}

export function* handleGetPaymentsScrollPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getPaymentsScrollPagination.request());

    const { data, pagination } = yield call(baasRequest, getPaymentsRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getPaymentsScrollPagination.success({ payments: data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getPaymentsScrollPagination.failure(err));
  }
}

export function* handleGetPaymentDetailsRequest(action) {
  const { storeUuid, paymentUuid } = action.payload;

  try {
    yield put(getPaymentDetail.request());

    const { data } = yield call(baasRequest, getPaymentDetailRequest, { storeUuid, paymentUuid });

    yield put(getPaymentDetail.success(data));
  } catch (err) {
    yield put(getPaymentDetail.failure(err));
  }
}

export function* handleCreatePaymentRequest(action) {
  const { storeUuid, payment } = action.payload;

  try {
    yield put(createPayment.request());

    const { data } = yield call(baasRequest, createPaymentRequest, {
      storeUuid,
      payment,
    });

    yield put(createPayment.success(data));
  } catch (err) {
    yield put(createPayment.failure(err));
  } finally {
    yield put(createPayment.fulfill());
  }
}

export function* handleDeletePaymentRequest(action) {
  const { storeUuid, paymentUuid } = action.payload;

  try {
    yield put(deletePayment.request());

    yield call(baasRequest, deletePaymentRequest, {
      storeUuid,
      paymentUuid,
    });

    yield put(deletePayment.success(paymentUuid));
  } catch (err) {
    yield put(deletePayment.failure(err));
  } finally {
    yield put(deletePayment.fulfill());
  }
}

export function* handlePaymentAvailabilityRequest(action) {
  const { storeUuid, paymentUuid, availability } = action.payload;

  try {
    yield put(togglePaymentAvailability.request());

    const { data } = yield call(baasRequest, paymentAvailabilityRequest, {
      storeUuid,
      paymentUuid,
      availability,
    });

    yield put(togglePaymentAvailability.success({ data, paymentUuid }));
  } catch (err) {
    yield put(togglePaymentAvailability.failure(err));
  } finally {
    yield put(togglePaymentAvailability.fulfill());
  }
}

export function* handleEditPaymentRequest(action) {
  const { storeUuid, paymentUuid, payment } = action.payload;

  try {
    yield put(editPayment.request());

    const { data } = yield call(baasRequest, editPaymentRequest, {
      storeUuid,
      paymentUuid,
      payment,
    });

    yield put(editPayment.success(data));
  } catch (err) {
    yield put(editPayment.failure(err));
  }
}

export function* handleUpdatePaymentPositionRequest(action) {
  const values = action.payload;

  try {
    yield put(updatePaymentPosition.request());

    const { data } = yield call(baasRequest, updatePaymentPositionRequest, values);

    yield put(updatePaymentPosition.success(data));
  } catch (err) {
    yield put(updatePaymentPosition.failure(err));
  }
}

export default function* restaurantPaymentsProviderSaga() {
  yield takeLatest(getPayments.TRIGGER, handleGetPaymentsRequest);
  yield takeLatest(getPaymentDetail.TRIGGER, handleGetPaymentDetailsRequest);
  yield takeLatest(createPayment.TRIGGER, handleCreatePaymentRequest);
  yield takeLatest(deletePayment.TRIGGER, handleDeletePaymentRequest);
  yield takeLatest(togglePaymentAvailability.TRIGGER, handlePaymentAvailabilityRequest);
  yield takeLatest(editPayment.TRIGGER, handleEditPaymentRequest);
  yield takeLatest(updatePaymentPosition.TRIGGER, handleUpdatePaymentPositionRequest);
  yield takeLatest(getPaymentsScrollPagination.TRIGGER, handleGetPaymentDetailsRequest);
}
