import { useContext } from 'react';

import { DrawerStateContext } from 'providers/menuMaker/DrawerState';

function useMenuMakerDrawerState() {
  const { keepOpen, setKeepOpen, drawerIsDirty, setDrawerIsDirty } = useContext(DrawerStateContext);

  return { keepOpen, setKeepOpen, drawerIsDirty, setDrawerIsDirty };
}

export default useMenuMakerDrawerState;
