import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleResume: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
  },

  boxChart: {
    width: '100%',
    border: `1px solid ${theme.palette.darkGrey[15]}`,
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
    boxShadow: '0px 8px 40px rgba(196, 208, 237, 0.15)',
    height: '100%',
    minHeight: theme.typography.pxToRem(420),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },

  staticHeightChart: {
    height: theme.typography.pxToRem(420),
  },

  wrapperTittleChart: {
    display: 'flex',
    alignItems: 'center',
    gap: '13px',
    paddingBottom: theme.spacing(1),
  },

  titleBoxChart: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },

  textHidden: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    maxWidth: theme.typography.pxToRem(200),
    [theme.breakpoints.between('md', 'md')]: {
      maxWidth: theme.typography.pxToRem(160),
    },
  },

  textLegendHidden: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  boxLegendsPeriod: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    marginTop: theme.spacing(2.5),
  },

  contentSelected: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  optionAll: {
    paddingBlock: theme.spacing(1.5),
  },

  rootLegend: {
    width: theme.typography.pxToRem(275),
  },

  productDeleted: {
    color: theme.palette.darkGrey[15],
    textDecorationLine: 'line-through',
  },

  baseTootlip: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.darkGrey[15]}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(11),
    maxWidth: 250,
    padding: '1.1rem 1rem',
    '& > span': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  textOptionItemFilters: {
    fontSize: 14,
  },

  wrapperSummaries: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    alignItems: 'end',
    gap: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },

  storeSalesGraphLayout: {
    width: '100%',
    height: 'calc(100% - 29px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },

  storeSalesGraph: {
    display: 'flex',
    width: '70%',
    height: '108%',
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
  },

  containerTableLegends: {
    overflowX: 'auto',
  },

  legendName: {
    lineHeight: 1.2,
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'left',
    alignItems: 'center',
    gap: theme.spacing(1),

    '& > span': {
      height: '8px',
      width: '8px',
      borderRadius: '50%',
    },
  },

  tableLegends: {
    height: '100%',
    '& th,td': {
      fontSize: theme.typography.pxToRem(12),
      paddingBlock: theme.spacing(0.5),
      paddingInline: theme.spacing(1),
    },
  },

  summaryCardTable: {
    height: 'calc(100% - 29px)',
    '& th,td': {
      fontSize: theme.typography.pxToRem(12),
      paddingBlock: theme.spacing(1.75),
      paddingInline: theme.spacing(2),
    },
    '& th': {
      borderBottom: `1px solid ${theme.palette.darkGrey[15]}`,
    },
    '& td': {
      borderTopStyle: 'dashed',
      borderTopColor: theme.palette.darkGrey[15],
    },
    '& tr': {
      '& > :first-child': {
        paddingLeft: 0,
      },
      '& > :last-child': {
        paddingRight: 0,
      },
    },
  },
}));

export default useStyles;
