/**
 *
 * ParrotSoftware
 * PaymentsGatewayProvider actions
 *
 */
import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/extendRoutines';

import {
  GET_PAYMENTS_METHODS,
  CREATE_PAYMENT_METHOD,
  RESUME_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_STATUS,
  REMOVE_PAYMENT_METHOD,
} from './constants';

export const getPaymentsMethods = extendRoutine(createRoutine(GET_PAYMENTS_METHODS), 'RESET');
export const createPaymentMethod = extendRoutine(createRoutine(CREATE_PAYMENT_METHOD), 'RESET');
export const resumePaymentMethod = extendRoutine(createRoutine(RESUME_PAYMENT_METHOD), 'RESET');
export const updatePaymentMethodStatus = extendRoutine(createRoutine(UPDATE_PAYMENT_METHOD_STATUS), 'RESET');
export const removePaymentMethod = extendRoutine(createRoutine(REMOVE_PAYMENT_METHOD), 'RESET');
