import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { invoicingGenerateDownload } from 'utils/invoicing/invoicing';

import { downloadInvoices } from '../actions';

export function* handleDownloadInvoicesRequest(action) {
  const { type, filters, invoicesUuids } = action.payload;
  const params = {
    invoicesUuids,
    ...filters,
    action: 'download',
  };
  try {
    yield put(downloadInvoices.request());

    const data = yield call(baasRequest, invoicingGenerateDownload, type, params);

    yield put(downloadInvoices.success(data));
  } catch (err) {
    yield put(downloadInvoices.failure(err));
  }
}
