import produce from 'immer';

import { getPermissions } from './actions';

export const initialState = {
  permissions: {
    error: null,
    isFetching: false,
    didFetch: false,
    list: [],
  },
};

const permissionsProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      /*
        Get
      */
      case getPermissions.REQUEST:
        draft.permissions.error = null;
        draft.permissions.isFetching = true;
        draft.permissions.didFetch = false;
        draft.permissions.list = [];
        break;

      case getPermissions.SUCCESS:
        draft.permissions.isFetching = false;
        draft.permissions.didFetch = true;
        draft.permissions.list = payload;
        break;

      case getPermissions.FAILURE:
        draft.permissions.error = payload;
        draft.permissions.isFetching = false;
        draft.permissions.didFetch = false;
        draft.permissions.list = null;
        break;

      case getPermissions.RESET:
        draft.permissions = { ...initialState.permissions };
        break;

      default:
        return draft;
    }
  });

export default permissionsProviderReducer;
