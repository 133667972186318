import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getInvoicesUninvoicedOrdersRequest, generateInvoicingRequest } from 'utils/api/baas/invoicing/global';

import { getAllInvoicesUninvoicedOrders, getAllInvoicesUninvoicedOrdersPagination, generateInvoicing } from './actions';

export function* handleGetAllInvoicesUninvoicedOrdersRequest(action) {
  const filters = action.payload;

  try {
    yield put(getAllInvoicesUninvoicedOrders.request());

    const { data, pagination } = yield call(baasRequest, getInvoicesUninvoicedOrdersRequest, { ...filters });

    yield put(getAllInvoicesUninvoicedOrders.success({ data, pagination }));
  } catch (err) {
    yield put(getAllInvoicesUninvoicedOrders.failure(err));
  }
}

export function* handleGetAllInvoicesUninvoicedOrdersPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getAllInvoicesUninvoicedOrdersPagination.request());

    const { data, pagination } = yield call(baasRequest, getInvoicesUninvoicedOrdersRequest, { ...filters });

    yield put(getAllInvoicesUninvoicedOrdersPagination.success({ data, pagination, page: filters?.page }));
  } catch (err) {
    yield put(getAllInvoicesUninvoicedOrdersPagination.failure(err));
  }
}

export function* handleGenerateInvoicingRequest(action) {
  const filters = action.payload;

  try {
    yield put(generateInvoicing.request());

    const { data } = yield call(baasRequest, generateInvoicingRequest, { ...filters });

    yield put(generateInvoicing.success({ data, subtractOrders: filters?.includedOrders }));
  } catch (err) {
    yield put(generateInvoicing.failure(err));
  }
}

export default function* InvoicesGlobalProviderRootSga() {
  yield takeLatest(getAllInvoicesUninvoicedOrders.TRIGGER, handleGetAllInvoicesUninvoicedOrdersRequest);
  yield takeLatest(
    getAllInvoicesUninvoicedOrdersPagination.TRIGGER,
    handleGetAllInvoicesUninvoicedOrdersPaginationRequest,
  );
  yield takeLatest(generateInvoicing.TRIGGER, handleGenerateInvoicingRequest);
}
