import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const HelpPhone = forwardRef(function HelpPhone(props, ref) {
  const { color = '#fff', size = 13, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M14.843 21c-.201 0-.503 0-.704-.1-.1 0-.804-.198-1.005-.298-6.431-1.89-10.25-8.453-8.843-14.917C4.592 4.193 5.999 3 7.507 3c.904 0 2.21.497 2.512.696.1.1.1.298.1.398l-.301 5.072c0 .199-.1.298-.302.398-.402.198-.904.298-1.406.397.402 2.288 1.808 4.376 3.718 5.669.2-.1.2-.2.301-.299.201-.298.402-.497.603-.795.1-.1.302-.199.503-.1l4.823 1.89c.201.1.302.199.302.398.1.298-.1 1.989-.704 2.784-.804.995-1.708 1.492-2.813 1.492zM7.507 3.895c-1.005 0-2.01.895-2.312 2.088-1.407 5.967 2.211 12.034 8.24 13.824.202.1.805.199.905.199 1.407.298 2.211-.498 2.613-1.094.402-.498.502-1.492.603-1.99l-4.22-1.69c-.202.298-.302.497-.503.696-.201.2-.302.398-.503.597-.1.199-.402.199-.603.1-2.512-1.492-4.22-4.078-4.622-7.061 0-.1 0-.2.1-.299.1-.1.201-.199.302-.199.502 0 1.005-.1 1.406-.199l.302-4.475c-.402-.199-1.206-.497-1.708-.497z"
        fill={color}
      />
      <path
        d="M15.044 13.343c-.1 0-.1 0 0 0-.302 0-.503-.2-.402-.498.1-3.083-2.312-3.878-2.513-3.878-.2-.1-.301-.298-.301-.597.1-.199.301-.398.603-.298 0 0 3.316.994 3.015 4.773 0 .299-.201.498-.402.498z"
        fill={color}
      />
      <path
        d="M17.254 13.342c-.301 0-.502-.198-.402-.497.302-4.475-3.316-5.569-3.416-5.668-.302 0-.503-.299-.402-.497.1-.2.301-.398.603-.299 0 0 4.522 1.293 4.12 6.564-.1.199-.302.397-.503.397z"
        fill={color}
      />
      <path
        d="M19.465 13.144s-.1 0 0 0c-.301 0-.502-.2-.402-.498.503-5.867-4.32-7.359-4.522-7.359-.2-.1-.402-.298-.301-.596.1-.2.301-.398.603-.299.1 0 5.627 1.69 5.125 8.354-.1.199-.302.398-.503.398z"
        fill={color}
      />
    </svg>
  );
});

HelpPhone.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default HelpPhone;
