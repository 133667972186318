import produce from 'immer';

import { paginationConfigDefaults, sortingOrderTypes, sortingPropertyTypes } from 'utils/voidReasons';

import { getVoidReasons, getVoidReasonsByPagination, createVoidReason, deleteVoidReason } from './actions';

export const initialState = {
  getVoidReasons: {
    error: null,
    fetching: false,
    fetched: false,
    voidReasons: [],
    pagination: {
      page: paginationConfigDefaults.page,
      page_size: paginationConfigDefaults.pageSize,
      count: 0,
      next: null,
      previous: null,
    },
  },

  loadPaginationStatus: {
    error: null,
    fetching: false,
    fetched: false,
    lastUpdatedRows: {},
  },

  sorting: {
    property: sortingPropertyTypes.NAME,
    filterByText: '',
    direction: sortingOrderTypes.ASC,
  },

  createVoidReason: {
    error: null,
    fetching: false,
    fetched: false,
    voidReason: {},
  },

  deleteVoidReason: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const restaurantVoidReasonsProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getVoidReasons.REQUEST:
        draft.getVoidReasons = { ...draft.getVoidReasons, fetching: true };
        break;

      case getVoidReasons.SUCCESS:
        draft.getVoidReasons.fetching = false;
        draft.getVoidReasons.fetched = true;
        draft.getVoidReasons.voidReasons = payload.voidReasons;
        draft.getVoidReasons.pagination = {
          ...draft.getVoidReasons.pagination,
          ...payload.pagination,
        };
        break;

      case getVoidReasons.FAILURE:
        draft.getVoidReasons.error = payload;
        draft.getVoidReasons.fetching = false;
        draft.getVoidReasons.fetched = false;
        break;

      case getVoidReasons.SORTING:
        draft.sorting = {
          ...draft.sorting,
          ...payload,
        };
        break;

      case getVoidReasons.RESET_SORTING:
        draft.sorting = {
          ...initialState.sorting,
        };
        break;

      case getVoidReasons.RESET:
        draft.getVoidReasons = { ...initialState.getVoidReasons };
        break;

      // pagination
      case getVoidReasonsByPagination.REQUEST: {
        const currentVoidReasonsPaginationState = draft.getVoidReasons;
        draft.getVoidReasons.pagination = { ...currentVoidReasonsPaginationState?.pagination, page: payload.page };
        draft.loadPaginationStatus = { ...initialState.loadPaginationStatus, fetching: true };

        break;
      }

      case getVoidReasonsByPagination.SUCCESS: {
        const currentVoidReasonsState = draft.getVoidReasons;
        let newsRows = [...currentVoidReasonsState?.voidReasons];
        newsRows = [...newsRows, ...payload?.voidReasons];

        draft.getVoidReasons = {
          ...currentVoidReasonsState,
          voidReasons: [...newsRows],
          pagination: { ...currentVoidReasonsState?.pagination, ...payload.pagination },
        };

        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          fetching: false,
          fetched: true,
          lastUpdatedRows: [...payload?.voidReasons],
        };
        break;
      }

      case getVoidReasonsByPagination.FAILURE:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          error: payload,
          fetching: false,
        };
        break;

      case getVoidReasonsByPagination.RESET:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
        };
        break;

      // create void reasons
      case createVoidReason.REQUEST:
        draft.createVoidReason = { ...initialState.createVoidReason, fetching: true };
        break;

      case createVoidReason.SUCCESS: {
        const voidReasonsList = [...draft.getVoidReasons.voidReasons];
        voidReasonsList.unshift(payload);

        draft.createVoidReason.fetching = false;
        draft.createVoidReason.fetched = true;
        draft.createVoidReason.voidReason = payload;
        draft.getVoidReasons.voidReasons = voidReasonsList;

        break;
      }

      case createVoidReason.FAILURE:
        draft.createVoidReason.error = payload;
        draft.createVoidReason.fetching = false;
        draft.createVoidReason.fetched = false;
        break;

      case createVoidReason.RESET:
        draft.createVoidReason = { ...initialState.createVoidReason };
        break;

      // detail void reason
      case deleteVoidReason.REQUEST:
        draft.deleteVoidReason = { ...initialState.deleteVoidReason, fetching: true };
        break;

      case deleteVoidReason.SUCCESS: {
        draft.deleteVoidReason.fetching = false;
        draft.deleteVoidReason.fetched = true;
        break;
      }

      case deleteVoidReason.FULFILL: {
        if (!draft.deleteVoidReason.error) {
          const voidReasonsList = draft.getVoidReasons.voidReasons.filter((voidReason) => voidReason.uuid !== payload);
          draft.getVoidReasons.voidReasons = voidReasonsList;
        }
        break;
      }

      case deleteVoidReason.FAILURE:
        draft.deleteVoidReason.error = payload;
        draft.deleteVoidReason.fetching = false;
        draft.deleteVoidReason.fetched = false;
        break;

      case deleteVoidReason.RESET:
        draft.deleteVoidReason = { ...initialState.deleteVoidReason };
        break;

      default:
        return draft;
    }
  });

export default restaurantVoidReasonsProviderReducer;
