/**
 *
 * ParrotSoftware
 * GoogleMapsProvider actions
 *
 */
import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/extendRoutines';

import { GEOCODING_LOOKUP_LATITUDE_LONGITUDE } from './constants';

export const geocodingLookupLatitudeLongitude = extendRoutine(
  createRoutine(GEOCODING_LOOKUP_LATITUDE_LONGITUDE),
  'RESET',
);
