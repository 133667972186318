import { useCallback, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { validateEmail } from './actions';
import { selectValidateEmailFetching, selectValidateEmailFetched, selectValidateEmailError } from './selectors';

function useEmail() {
  const dispatch = useDispatch();
  const validateEmailFetching = useSelector(selectValidateEmailFetching);
  const validateEmailFetched = useSelector(selectValidateEmailFetched);
  const validateEmailError = useSelector(selectValidateEmailError);

  const [validateEmailState, setValidateEmailState] = useState({
    fetching: false,
    fetched: false,
    error: null,
  });

  useEffect(() => {
    setValidateEmailState({
      fetching: validateEmailFetching,
      fetched: validateEmailFetched,
      error: validateEmailError,
    });
  }, [validateEmailError, validateEmailFetched, validateEmailFetching]);

  const handleValidateEmail = useCallback(
    (email) => {
      dispatch(validateEmail(email));
    },
    [dispatch],
  );

  const handleResetValidateEmail = useCallback(() => {
    dispatch(validateEmail.reset());
  }, [dispatch]);

  return {
    validateEmailState,
    setValidateEmail: handleValidateEmail,
    resetValidateEmail: handleResetValidateEmail,
  };
}

export default useEmail;
