import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_STATES_LIST,
  GET_MUNICIPALITY_LIST,
  GET_NEIGHBORHOOD_LIST,
  GET_INFO_ZIP_CODE,
  GET_INFO_ZIP_CODE_GEOCODING,
} from './constants';

export const getStatesList = extendRoutine(createRoutine(GET_STATES_LIST), 'RESET');
export const getMunicipalityList = extendRoutine(createRoutine(GET_MUNICIPALITY_LIST), 'RESET');
export const getNeighborhoodList = extendRoutine(createRoutine(GET_NEIGHBORHOOD_LIST), 'RESET');
export const getInfoZipCode = extendRoutine(createRoutine(GET_INFO_ZIP_CODE), 'RESET');
export const getInfoZipCodeGeocoding = extendRoutine(createRoutine(GET_INFO_ZIP_CODE_GEOCODING), 'RESET');
