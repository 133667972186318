import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getMenuDetails, editMenu } from './actions';
import {
  selectMenu,
  selectMenuFetching,
  selectMenuFetched,
  selectMenuError,
  selectEditMenuFetching,
  selectEditMenuFetched,
  selectEditMenuError,
  selectEditMenuObject,
} from './selectors';

export function withMenuDetailsHoc(Component) {
  function withMenuDetails(props) {
    return <Component {...props} />;
  }

  withMenuDetails.displayName = `withMenuDetails(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withMenuDetails;
}

const mapStateToProps = createStructuredSelector({
  menu: selectMenu,
  menuFetching: selectMenuFetching,
  menuFetched: selectMenuFetched,
  menuError: selectMenuError,
  editMenuFetching: selectEditMenuFetching,
  editMenuFetched: selectEditMenuFetched,
  editMenuError: selectEditMenuError,
  editMenuObject: selectEditMenuObject,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadMenu: ({ menuUuid, storeUuid }) => dispatch(getMenuDetails({ menuUuid, storeUuid })),
    editMenu: (values) => dispatch(editMenu(values)),
    resetGetMenu: () => dispatch(getMenuDetails.reset()),
    resetEditMenu: () => dispatch(editMenu.reset()),
  };
}

export const withMenuDetailsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withMenuDetailsState, withMenuDetailsHoc);
