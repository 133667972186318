import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';

import IconButton from '@design-system/IconButton';

import MenuOutline from '@experimental-components/IconsComponents/MenuOutline';
import CloseOutline from '@experimental-components/IconsComponents/CloseOutline';
import ChevronBase from '@experimental-components/IconsComponents/ChevronBase';
import LogoConnect from '@experimental-components/IconsComponents/LogoConnect';
import LogoConnectSmall from '@experimental-components/IconsComponents/LogoConnectSmall';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import useEnvironment from 'hooks/useEnvironment';

import { setCollapsePrincipalMenuKey } from 'utils/principalMenu';

import { useHistory } from 'react-router-dom';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: (props) => (props?.open ? drawerWidth : `64px`),
      flexShrink: 0,
    },
  },

  appBar: {
    background: (props) => (props.isTestEnv ? theme.palette.testEnv.main : theme.palette.common.black),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  drawerPaperMdMobile: {
    zIndex: '1300',
    width: (props) => (props?.open ? drawerWidth : `64px`),
    backgroundColor: (props) => (props.isTestEnv ? theme.palette.testEnv.main : theme.palette.common.black),
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 200,
    }),
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.darkGrey[50]} ${theme.palette.common.black}`,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'auto',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.darkGrey[50],
    },
  },
  headerMdMobile: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    minHeight: theme.typography.pxToRem(120),
    maxHeight: theme.typography.pxToRem(120),
    padding: (props) => (props?.open ? '1.5rem 1.125rem' : '2rem 1rem'),
  },
  contentMenuMdMobile: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  drawerPaperMobile: {
    width: '100%',
    backgroundColor: (props) => (props.isTestEnv ? theme.palette.testEnv.main : theme.palette.common.black),
  },

  toolbarMobile: {
    justifyContent: 'space-between',
  },

  logSmall: {
    width: 35,
    height: 35,
  },
  chevronLeft: {
    rotate: '90deg',
  },
}));

function SidebarLayoutSidebar({ children, window }) {
  const { isTestEnv } = useEnvironment();
  const history = useHistory();
  const { openDrawer, setOpenDrawer, openMdDrawer, setOpenMdDrawer } = useSidebarLayout();

  const classes = useStyles({ isTestEnv, open: openMdDrawer });
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleDrawerMdToggle = () => {
    setOpenMdDrawer((state) => !state);
    setCollapsePrincipalMenuKey(!openMdDrawer);
  };

  function handleLogoClick() {
    history.push('/');
  }

  const container = window !== undefined ? () => window().document.body : undefined;

  function renderHeaderMdMenu() {
    if (!openMdDrawer) {
      return (
        <>
          <IconButton aria-label="open drawer" color="inherit" edge="start" onClick={handleDrawerMdToggle}>
            <MenuOutline color={theme.palette.common.white} size={24} />
          </IconButton>
        </>
      );
    }

    return (
      <>
        <IconButton onClick={handleLogoClick}>
          <LogoConnect height={30} width={180} />
        </IconButton>
        <IconButton aria-label="open_drawer_menu" color="inherit" edge="start" onClick={handleDrawerMdToggle}>
          <ChevronBase className={classes.chevronLeft} color={theme.palette.common.white} size={24} />
        </IconButton>
      </>
    );
  }

  return (
    <>
      <Hidden lgUp smUp>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar className={classes.toolbarMobile}>
            <IconButton
              aria-label="open drawer"
              className={classes.menuButton}
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuOutline color={theme.palette.common.white} size={24} />
            </IconButton>

            <Box>
              <LogoConnectSmall className={classes.logSmall} />
            </Box>
          </Toolbar>
        </AppBar>
      </Hidden>

      <nav aria-label="mailbox folders" className={classes.drawer}>
        <Hidden implementation="css" smUp>
          <Drawer
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            container={container}
            ModalProps={{
              keepMounted: true,
            }}
            onClose={handleDrawerToggle}
            open={openDrawer}
            variant="temporary"
          >
            <Box
              height="100%"
              maxHeight={theme.typography.pxToRem(100)}
              minHeight={theme.typography.pxToRem(100)}
              p={2}
            >
              {openDrawer && (
                <ButtonBase disableRipple onClick={handleDrawerToggle}>
                  <CloseOutline color={theme.palette.common.white} />
                </ButtonBase>
              )}
            </Box>
            {children}
          </Drawer>
        </Hidden>

        <Hidden implementation="css" xsDown>
          <Drawer
            classes={{
              paper: classes.drawerPaperMdMobile,
            }}
            open
            variant="permanent"
          >
            <Box className={classes.headerMdMobile}>{renderHeaderMdMenu()}</Box>
            <Box className={classes.contentMenuMdMobile}>{children}</Box>
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}

SidebarLayoutSidebar.propTypes = {
  children: PropTypes.node,
  window: PropTypes.func,
};

export default SidebarLayoutSidebar;
