import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { cleanObject } from 'utils/objects';
import { pageSizeTypes } from 'utils/pagination';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getKitchenAreasRequest(filters, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    ...filters,
    store_uuid: storeUuid,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
  };

  const query = queryString.stringify(cleanObject(params));

  return request(`${API_BASE_URL}/v1/kitchen-areas/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getKitchenAreaDetailsRequest(kitchenAreaUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/kitchen-areas/${kitchenAreaUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function createKitchenAreaRequest({ name }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/kitchen-areas/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    }),
    ...options,
  });
}

export function disableKitchenAreaRequest(kitchenAreaUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/kitchen-areas/${kitchenAreaUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function updateKitchenAreaRequest({ uuid, name, isDefault, isEnabled }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    name,
    isDefault,
    isEnabled,
  };
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/kitchen-areas/${uuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify(cleanObject(params)),
    ...options,
  });
}
