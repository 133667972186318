import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_SERVICE_CHARGES,
  GET_SERVICE_CHARGES_BY_PAGINATION,
  CREATE_SERVICE_CHARGE,
  GET_SERVICE_CHARGE,
  UPDATE_SERVICE_CHARGE,
  DELETE_SERVICE_CHARGE,
  SET_IS_ENABLED_SERVICE_CHARGE,
} from './constants';

export const getServiceCharges = extendRoutine(createRoutine(GET_SERVICE_CHARGES), 'RESET');
export const getServiceChargesByPagination = extendRoutine(createRoutine(GET_SERVICE_CHARGES_BY_PAGINATION), 'RESET');
export const getServiceCharge = extendRoutine(createRoutine(GET_SERVICE_CHARGE), 'RESET');
export const createServiceCharge = extendRoutine(createRoutine(CREATE_SERVICE_CHARGE), 'RESET');
export const updateServiceCharge = extendRoutine(createRoutine(UPDATE_SERVICE_CHARGE), 'RESET');
export const deleteServiceCharge = extendRoutine(createRoutine(DELETE_SERVICE_CHARGE), 'RESET');
export const setIsEnabledServiceCharge = extendRoutine(createRoutine(SET_IS_ENABLED_SERVICE_CHARGE), 'RESET');
