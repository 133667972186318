import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectCategoriesProviderDomain = (state) => state.categories || initialState;

const selectCategoriesStore = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.getCategories.categories,
);

const selectCategoriesPagination = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.getCategories.pagination,
);

const selectPaginationCategoryState = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.paginationCategory,
);

const selectCategoriesFetching = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.getCategories.fetching,
);

const selectCategoriesError = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.getCategories.error,
);

const selectCategoriesFetched = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.getCategories.fetched,
);

const selectCreateCategory = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.createCategory.category,
);

const selectCreateCategoryFetching = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.createCategory.fetching,
);

const selectCreateCategoryFetched = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.createCategory.fetched,
);

const selectCreateCategoryError = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.createCategory.error,
);

const selectDeleteCategoryFetching = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.deleteCategory.fetching,
);

const selectDeleteCategoryFetched = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.deleteCategory.fetched,
);

const selectDeleteCategoryError = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.deleteCategory.error,
);

const selectDuplicateCategory = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.duplicateCategory.category,
);

const selectDuplicateCategoryFetching = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.duplicateCategory.fetching,
);

const selectDuplicateCategoryFetched = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.duplicateCategory.fetched,
);

const selectDuplicateCategoryError = createSelector(
  selectCategoriesProviderDomain,
  (subState) => subState.duplicateCategory.error,
);

export {
  selectCategoriesProviderDomain,
  selectCategoriesStore,
  selectCategoriesPagination,
  selectCategoriesFetching,
  selectCategoriesError,
  selectCategoriesFetched,
  selectCreateCategory,
  selectCreateCategoryFetching,
  selectCreateCategoryFetched,
  selectCreateCategoryError,
  selectDeleteCategoryFetching,
  selectDeleteCategoryFetched,
  selectDeleteCategoryError,
  selectDuplicateCategory,
  selectDuplicateCategoryFetching,
  selectDuplicateCategoryFetched,
  selectDuplicateCategoryError,
  selectPaginationCategoryState,
};
