import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_PRODUCTS_OPTIONS,
  GET_PRODUCTS_OPTIONS_BY_PAGINATION,
  GET_PRODUCTS_OPTIONS_BY_FILTER,
  GET_PRODUCTS_AUTOCOMPLETE,
  GET_PRODUCTS_AUTOCOMPLETE_BY_PAGINATION,
  GET_PRODUCTS_AUTOCOMPLETE_BY_FILTER,
} from './constants';

export const getProductsOptions = extendRoutine(createRoutine(GET_PRODUCTS_OPTIONS), 'RESET');
export const getProductsOptionsByPagination = extendRoutine(createRoutine(GET_PRODUCTS_OPTIONS_BY_PAGINATION), 'RESET');
export const getProductsOptionsByFilter = extendRoutine(createRoutine(GET_PRODUCTS_OPTIONS_BY_FILTER), [
  'RESET',
  'FILTER_TEXT',
  'RESET_FILTER_TEXT',
]);
// autocomplete
export const getProductsAutocomplete = extendRoutine(createRoutine(GET_PRODUCTS_AUTOCOMPLETE), 'RESET');
export const getProductsAutocompleteByPagination = extendRoutine(
  createRoutine(GET_PRODUCTS_AUTOCOMPLETE_BY_PAGINATION),
  'RESET',
);
export const getProductsAutocompleteByFilter = extendRoutine(createRoutine(GET_PRODUCTS_AUTOCOMPLETE_BY_FILTER), [
  'RESET',
  'FILTER_TEXT',
  'RESET_FILTER_TEXT',
]);
