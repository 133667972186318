/**
 *
 * ParrotSoftware
 * SettingsAdminTablesProvider constants
 *
 */
export const CREATE_TABLE = 'providers/settings/AdminTables/CREATE_TABLE';
export const VALIDATE_NAME = 'providers/settings/AdminTables/VALIDATE_NAME';
export const CREATE_TABLES_BULK = 'providers/settings/AdminTables/CREATE_TABLES_BULK';
export const GET_TABLES = 'providers/settings/AdminTables/GET_TABLES';
export const DELETE_TABLE = 'providers/settings/AdminTables/DELETE_TABLE';
export const GET_TABLE_DETAILS = 'providers/settings/AdminTables/GET_TABLE_DETAILS';
export const UPDATE_TABLE = 'providers/settings/AdminTables/UPDATE_TABLE';
export const GET_TABLES_SCROLL_PAGINATION = 'providers/settings/AdminTables/GET_TABLES_SCROLL_PAGINATION';
export const UPDATE_TABLE_POSITION = 'providers/settings/AdminTables/UPDATE_TABLE_POSITION';
