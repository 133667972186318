export const GET_MENUS = 'providers/menuMaker/Menus/GET_MENUS';
export const GET_PAGINATION_MENU = 'providers/menuMaker/Menus/GET_PAGINATION_MENU';
export const CREATE_MENU = 'providers/menuMaker/Menus/CREATE_MENU';
export const PUBLISH_MENU = 'providers/menuMaker/Menus/PUBLISH_MENU';
export const DUPLICATE_MENU = 'providers/menuMaker/Menus/DUPLICATE_MENU';
export const DELETE_MENU = 'providers/menuMaker/Menus/DELETE_MENU';
export const COPY_MENU_CONFIG = 'providers/menuMaker/Menus/COPY_MENU_CONFIG';
export const PUBLISH_MENUS = 'providers/menuMaker/Menus/PUBLISH_MENUS';
export const MENUS_DETAILS_RESUME = 'providers/menuMaker/Menus/MENUS_DETAILS_RESUME';
export const SYNC_PRODUCT_OVERRIDE = 'providers/menuMaker/Menus/SYNC_PRODUCT_OVERRIDE';
