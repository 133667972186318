import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectClientDataProviderDomain = (state) => state.reportClientData || initialState;

const selectClientData = createSelector(selectClientDataProviderDomain, ({ clientData: { data, pagination } }) => ({
  data,
  pagination,
}));
const selectClientDataState = createSelector(
  selectClientDataProviderDomain,
  ({ clientData: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectPaginationClientsState = createSelector(
  selectClientDataProviderDomain,
  ({ paginationClients: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectClientDetails = createSelector(
  selectClientDataProviderDomain,
  ({ clientDetails: { data, pagination } }) => ({
    data,
    pagination,
  }),
);
const selectClientDetailsState = createSelector(
  selectClientDataProviderDomain,
  ({ clientDetails: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectExportClientsReportDocument = createSelector(
  selectClientDataProviderDomain,
  ({ exportClientsReport: { document } }) => document,
);
const selectExportClientsReportState = createSelector(
  selectClientDataProviderDomain,
  ({ exportClientsReport: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectPaginationDetailsState = createSelector(
  selectClientDataProviderDomain,
  ({ paginationDetails: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

export {
  selectClientDataProviderDomain,
  selectClientData,
  selectClientDataState,
  selectClientDetails,
  selectClientDetailsState,
  selectPaginationClientsState,
  selectExportClientsReportDocument,
  selectExportClientsReportState,
  selectPaginationDetailsState,
};
