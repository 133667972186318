import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectProductsSorting } from 'providers/menuMaker/Products/selectors';
import { getProducts } from 'providers/menuMaker/Products/actions';

import { sortingOrderTypes, sortingPropertyTypes } from 'utils/products';

export function useSortingProducts() {
  const dispatch = useDispatch();
  const [cacheFilters, setCacheFilters] = useState({});
  const {
    property,
    filterByText,
    filterByCategories,
    typeOfSale,
    filterByBrands,
    withoutBrands,
    withoutCategories,
    direction,
    orderBy,
    isSearchFilter,
  } = useSelector(selectProductsSorting);

  function handleSetSortingByFilters(filtersArgs) {
    const filters = filtersArgs;

    if (filters?.filterByText === null || filters?.filterByText === undefined) {
      delete filters.filterByText;
    }
    if (filters?.typeOfSale === null || filters?.typeOfSale === undefined) {
      delete filters.typeOfSale;
    }
    if (filters?.filterByCategories?.length === 0 && !filters?.withoutCategories) {
      delete filters.withoutCategories;
    }
    if (filters?.filterByBrands?.length === 0 && !filters?.withoutBrands) {
      delete filters.withoutBrands;
    }

    setCacheFilters(filters);
    dispatch(getProducts({ ...filters, isSorting: true }));
    dispatch(getProducts.sorting({ ...filters, isSearchFilter: true }));
  }

  function handleResetSorting() {
    dispatch(getProducts.resetSorting());
  }

  return {
    sortingProperty: property,
    sortingFilterByText: filterByText,
    sortingDirection: direction,
    setSortingByFilters: useCallback(handleSetSortingByFilters, [dispatch]),
    sortingOrderTypes,
    sortingPropertyTypes,
    sortingFilterByCategories: filterByCategories,
    resetSorting: useCallback(handleResetSorting, [dispatch]),
    filtersSorting: {
      filterByCategories,
      [filterByText.length && 'filterByText']: filterByText,
      typeOfSale,
      filterByBrands,
      withoutBrands,
      withoutCategories,
      isSearchFilter,
      orderBy,
    },
    cacheFilters,
  };
}

export default useSortingProducts;
