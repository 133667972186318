/**
 *
 * ParrotSoftware
 * SettingsAdminTablesProvider saga
 *
 */
import { takeLatest, put, call, delay } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  createTableRequest,
  createTableBulkRequest,
  getTablesRequest,
  deleteTableRequest,
  getTableDetailsRequest,
  updateTableRequest,
  validateNameRequest,
  updateTablePositionRequest,
} from 'utils/api/baas/tableAreasService/serviceTables';

import {
  createTable,
  getTables,
  deleteTable,
  getTableDetails,
  updateTable,
  createTablesBulk,
  validateName,
  getTablesScrollPagination,
  updateTablePosition,
} from './actions';

export function* createTableSaga(action) {
  const table = action.payload;

  try {
    yield put(createTable.request());

    const { data } = yield call(baasRequest, createTableRequest, table);

    yield put(createTable.success(data));
  } catch (err) {
    yield put(createTable.failure(err));
  }
}

export function* createTablesBulkSaga(action) {
  const values = action.payload;

  try {
    yield put(createTablesBulk.request());

    const { data } = yield call(baasRequest, createTableBulkRequest, values);

    yield put(createTablesBulk.success(data));
  } catch (err) {
    yield put(createTablesBulk.failure(err));
  }
}

export function* getTablesSaga(action) {
  const filters = action.payload;

  try {
    yield put(getTables.request());

    const { data, pagination } = yield call(baasRequest, getTablesRequest);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getTables.success({ tables: data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getTables.failure(err));
  }
}

export function* getTablesScrollPaginationSaga(action) {
  const filters = action.payload;

  try {
    yield put(getTablesScrollPagination.request());

    const { data, pagination } = yield call(baasRequest, getTablesRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getTablesScrollPagination.success({ tables: data, pagination: paginationCurrent })); // { summaries, headers, rows } = data
  } catch (err) {
    yield put(getTablesScrollPagination.failure(err));
  }
}

export function* getTableDetailsSaga(action) {
  const tableUuid = action.payload;

  try {
    yield put(getTableDetails.request());

    const { data } = yield call(baasRequest, getTableDetailsRequest, tableUuid);

    yield put(getTableDetails.success(data));
  } catch (err) {
    yield put(getTableDetails.failure(err));
  }
}

export function* updateTableSaga(action) {
  const { tableUuid, table } = action.payload;

  try {
    yield put(updateTable.request());

    const { data } = yield call(baasRequest, updateTableRequest, tableUuid, table);

    yield put(updateTable.success(data));
  } catch (err) {
    yield put(updateTable.failure(err));
  }
}

export function* deleteTableSaga(action) {
  const tableUuid = action.payload;

  try {
    yield put(deleteTable.request());
    yield call(baasRequest, deleteTableRequest, tableUuid);
    yield put(deleteTable.success());
  } catch (err) {
    yield put(deleteTable.failure(err));
  } finally {
    yield delay(100);
    yield put(deleteTable.fulfill({ tableUuid }));
  }
}

export function* validateNameSaga(action) {
  const values = action.payload;

  try {
    yield put(validateName.request());

    const { data } = yield call(baasRequest, validateNameRequest, values);

    yield put(validateName.success(data));
  } catch (err) {
    yield put(validateName.failure(err));
  }
}

export function* handleUpdateTablePositionSaga(action) {
  const values = action.payload;
  try {
    yield put(updateTablePosition.request());

    const { data } = yield call(baasRequest, updateTablePositionRequest, values);

    yield put(updateTablePosition.success(data));
  } catch (err) {
    yield put(updateTablePosition.failure(err));
  }
}

export default function* settingsAdminTablesRootSga() {
  yield takeLatest(createTable.TRIGGER, createTableSaga);
  yield takeLatest(createTablesBulk.TRIGGER, createTablesBulkSaga);
  yield takeLatest(getTables.TRIGGER, getTablesSaga);
  yield takeLatest(getTablesScrollPagination.TRIGGER, getTablesScrollPaginationSaga);
  yield takeLatest(getTableDetails.TRIGGER, getTableDetailsSaga);
  yield takeLatest(updateTable.TRIGGER, updateTableSaga);
  yield takeLatest(deleteTable.TRIGGER, deleteTableSaga);
  yield takeLatest(validateName.TRIGGER, validateNameSaga);
  yield takeLatest(updateTablePosition.TRIGGER, handleUpdateTablePositionSaga);
}
