import Box, { BoxProps as BoxPropsBase } from '@material-ui/core/Box';

import useStyles from './styles';

interface Props extends BoxPropsBase {
  title: string;
  description: string;
  selected?: boolean;
}

function ConfigCard({ title, description, selected, ...others }: Props) {
  const classes = useStyles({ selectedCard: selected });

  return (
    <Box className={classes.configCard} {...others}>
      <Box className={classes.contentConfigCard}>
        <Box component="h3" fontSize="1.125rem" fontWeight={500}>
          {title}
        </Box>

        <Box component="p">{description}</Box>
      </Box>
    </Box>
  );
}

export default ConfigCard;
