import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { productsAvailabilityForStoreRequest } from 'utils/api/baas/menuMaker';

import { toggleProductAvailability } from '../actions';

export function* handleToggleProductAvailabilityRequest(action) {
  const { productUuid, availability } = action.payload;
  try {
    yield put(toggleProductAvailability.request());

    const { data: product } = yield call(baasRequest, productsAvailabilityForStoreRequest, {
      productUuid,
      availability,
    });

    yield put(toggleProductAvailability.success({ product }));
  } catch (err) {
    yield put(toggleProductAvailability.failure({ error: err, productUuid }));
  }
}
