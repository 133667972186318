import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import Select from '@design-system/Select';
import Checkbox from '@design-system/Checkbox';

import { modifierTypesList } from 'utils/modifierGroups';

import { menuProps } from './utils';
import useStyles from './styles';

function SelectModifierType({ onSelectModifierType }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');
  const [typesSelected, setTypesSelected] = useState([]);

  const handleRenderNamesSelected = (itemsSelected) => {
    let namesSelected = t('menuMaker:modifierGroupForm.modifierType.filterSearchPlaceholder');

    if (itemsSelected.length) {
      namesSelected =
        itemsSelected.length === modifierTypesList.length
          ? t('common:forms.allViews')
          : itemsSelected
              .map((item) => t(`menuMaker:modifierGroupForm.modifierType.options.${item?.toLowerCase()}.name`))
              .join(', ');
    }

    return <span className={classes.contentSelected}>{namesSelected}</span>;
  };

  const handleSelectAllTypes = () => {
    const selection = typesSelected.length === modifierTypesList.length ? [] : modifierTypesList;
    setTypesSelected(selection);
    onSelectModifierType(selection);
  };

  const handleSelectedType = (type) => {
    let selection;

    if (typesSelected.includes(type)) {
      selection = typesSelected.filter((item) => item !== type);
    } else {
      selection = [...typesSelected, type];
    }

    setTypesSelected(selection);
    onSelectModifierType(selection);
  };

  return (
    <Select
      data-testid="selectModifierType"
      displayEmpty
      fullWidth
      hideLabelHelperText
      label={t('menuMaker:headerForm.modifierType')}
      MenuProps={{ ...menuProps }}
      multiple
      name="modifierTypeFilter"
      renderValue={(selected) => handleRenderNamesSelected(selected)}
      value={typesSelected}
    >
      <MenuItem
        key="seeAllTypes"
        className={classes.optionAll}
        data-testid="seeAllTypes"
        onClick={handleSelectAllTypes}
      >
        <Checkbox checked={typesSelected.length === modifierTypesList.length} />
        <ListItemText primary={t('common:forms.allViews')} />
      </MenuItem>
      <Divider />
      {modifierTypesList.map((type) => (
        <MenuItem key={type} data-testid={type} onClick={() => handleSelectedType(type)} value={type}>
          <Checkbox checked={typesSelected.includes(type)} />
          <ListItemText primary={t(`menuMaker:modifierGroupForm.modifierType.options.${type?.toLowerCase()}.name`)} />
        </MenuItem>
      ))}
    </Select>
  );
}

export default SelectModifierType;
