import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}

const CloudUpload = forwardRef(function CloudUpload(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#000', size = 24 } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5539 9.74932L12.447 13.169C12.447 13.9832 12.2333 14.7974 11.8593 15.5031C11.4853 16.2087 10.8976 16.8058 10.203 17.2401C9.50844 17.6743 8.76044 17.9457 7.95902 18H7.47816C6.24931 18 7.58502 18 6.30274 18C5.71503 18 5.12731 17.9457 4.5396 17.7286C3.25732 17.2401 2.34904 16.3173 2.0819 14.9603C1.86818 13.9289 2.0819 12.9519 2.61618 12.0834C3.09704 11.3235 3.73818 10.7807 4.59303 10.5093C4.69989 10.5093 4.69989 10.455 4.69989 10.3464C4.80674 9.36936 5.18074 8.55514 5.92874 7.90378C6.56988 7.36097 7.31788 7.14384 8.17273 7.19813C8.43987 7.19813 8.70702 7.30669 8.97416 7.36097C9.08102 7.36097 9.08102 7.36097 9.13444 7.30669C9.56187 6.60104 10.1496 6.05823 10.8442 5.62398C11.3784 5.2983 11.9661 5.13546 12.6073 5.02689C13.7293 4.91833 14.8513 5.13546 15.813 5.73254C17.2021 6.65532 17.9501 7.95806 18.1104 9.64076C18.1104 9.74932 18.1638 9.74932 18.2173 9.74932C19.2324 10.1293 19.9804 10.7807 20.4613 11.7034C21.049 12.789 21.1558 13.8747 20.7818 15.0146C20.3544 16.3716 19.3927 17.2943 18.057 17.7829C17.6296 17.9457 17.1487 18 16.6678 18C15.5459 18 14.4239 18 13.3553 18"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.4"
      />
      <path d="M15 12L12.5 10L10 12" stroke={color} strokeWidth="1.5" />
    </svg>
  );
});

export default CloudUpload;
