import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import { GET_CATEGORY, EDIT_CATEGORY, GET_PRODUCTS_SORT_CATEGORY, UPDATE_PRODUCTS_SORT_CATEGORY } from './constants';

export const getCategoryDetail = extendRoutine(createRoutine(GET_CATEGORY), 'RESET');

export const editCategory = extendRoutine(createRoutine(EDIT_CATEGORY), 'RESET');
export const getProductsSortCategory = extendRoutine(createRoutine(GET_PRODUCTS_SORT_CATEGORY), 'RESET');
export const updateProductsSortCategory = extendRoutine(createRoutine(UPDATE_PRODUCTS_SORT_CATEGORY), 'RESET');
