import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useFormContext } from 'react-hook-form';

import InputTextField from '@design-system/TextField';

import { regexTypes } from 'utils/formatField';

import useStyles from './styles';

function OptionRule({ disabled, label, name, t }) {
  const classes = useStyles();
  const { errors, register, watch, clearErrors, trigger } = useFormContext();
  const minField = Number(watch('ruleOptionMin'));
  const maxField = Number(watch('ruleOptionMax'));
  const repField = Number(watch('ruleOptionRepeated'));

  const validateRules = {
    ruleOptionMin: {
      validateValue: (value) => value <= maxField || t('menuMaker:modifierGroupForm.errors.maxShouldBeGreatherThanMin'),
    },
    ruleOptionMax: {
      greaterThanZero: (value) => value > 0 || t('menuMaker:modifierGroupForm.errors.greaterThanZero'),
      validateValue: (value) => value >= minField || t('menuMaker:modifierGroupForm.errors.maxShouldBeGreatherThanMin'),
    },
    ruleOptionRepeated: {
      maxValueAllowed: (value) => value <= maxField || t('menuMaker:modifierGroupForm.errors.repLessThanMax'),
      greaterThanZero: (value) => value > 0 || t('menuMaker:modifierGroupForm.errors.repGreaterThanZero'),
    },
  };

  const handleChangeOption = () => {
    if (minField && minField >= 0 && maxField > 0 && minField <= 20 && maxField <= 20 && minField <= maxField) {
      clearErrors(['ruleOptionMin', 'ruleOptionMax']);
      trigger();
    }

    if (repField && maxField && repField <= maxField) {
      clearErrors('ruleOptionRepeated');
      trigger();
    }
  };

  return (
    <Box className={classes.optionRule}>
      <Typography variant="body2">{label}</Typography>

      <InputTextField
        className={classes.hideArrows}
        data-testid={name}
        disabled={disabled}
        error={Boolean(errors[name])}
        errorMsg={errors[name]?.message}
        hideLabel
        id={name}
        inputProps={{ type: 'number', min: 0, max: 20 }}
        inputRef={register({
          required: t('menuMaker:modifierGroupForm.errors.required'),
          pattern: {
            value: regexTypes.REGEX_ONLY_NUMBERS_NO_NEGATIVES,
            message: t('menuMaker:modifierGroupForm.errors.noNegatives'),
          },
          validate: {
            maxValueAllowed: (value) => value <= 20 || t('menuMaker:modifierGroupForm.errors.maxValueAllowed'),
            ...validateRules[name],
          },
        })}
        name={name}
        onChange={handleChangeOption}
      />
    </Box>
  );
}

OptionRule.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  t: PropTypes.func,
};

export default OptionRule;
