import { useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import clsx from 'clsx';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Table from '@experimental-components/Table';

import PriceFormatField from 'components/PriceFormatField';

import { selectionType, requiredType } from 'utils/menuPreview';

import useStyles from './styles';

function TableRowsModifiersGroups({
  option,
  children,
  isHeader,
  isCollapse,
  level,
  isShowPrice,
  isConditionalGroup,
  t,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  function renderOptionCollapse() {
    return (
      <Box className={classes.iconExpand} display="block" width={4}>
        <IconButton aria-label="expand row" onClick={() => setOpen(!open)} size="small">
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
    );
  }

  function renderMinMax(requiredTypeValue, min, max) {
    let requiredTypeText = '';
    if (min || max) {
      switch (requiredTypeValue) {
        case requiredType.EXACT_VALUE:
          requiredTypeText = ` (Exacto ${max})`;
          break;
        case requiredType.VALUE_IN_RANGE:
          requiredTypeText = ' (';
          requiredTypeText += min ? `${t('menuMaker:previewMenu.min')} ${min}` : '';
          requiredTypeText += min && max ? ', ' : '';
          requiredTypeText += max ? `${t('menuMaker:previewMenu.max')} ${max}` : '';
          requiredTypeText += ')';
          break;

        default:
          requiredTypeText = '';
          break;
      }
    }

    return requiredTypeText;
  }

  function renderNameOption() {
    if (isHeader) {
      return (
        <Box>
          <Box fontWeight="fontWeightMedium">
            {option?.name}
            {!isConditionalGroup && renderMinMax(option?.requiredType, option?.min, option?.max)}
          </Box>
          <Box color="darkGrey.main" component="p" fontSize={theme.typography.pxToRem(14)} m={0}>
            {option?.selectionType === selectionType.FORCE_PRODUCT_SELECTION
              ? t('menuMaker:previewMenu.required')
              : t('menuMaker:previewMenu.optional')}
          </Box>
        </Box>
      );
    }

    return (
      <Box color="darkGrey.main" component="p" m={0}>
        {option?.name ?? option?.conditionalOptionName}
        {option?.rules && renderMinMax(option?.rules?.requiredType, option?.rules?.min, option?.rules?.max)}
      </Box>
    );
  }

  return (
    <>
      <Table.Row>
        <Table.Cell className={clsx({ [classes.rowSecondaryCell]: isCollapse })} isBorder={false}></Table.Cell>
        <Table.Cell className={clsx({ [classes.rowSecondaryCell]: isCollapse })} isBorder={false}></Table.Cell>
        <Table.Cell
          className={clsx({ [classes.rowSecondaryCell]: isCollapse })}
          isBorder={false}
          style={{ paddingLeft: theme.typography.pxToRem(32 * level) }}
        >
          <Box alignItems="center" display="inline-flex">
            {isCollapse && renderOptionCollapse()}
            {renderNameOption()}
          </Box>
        </Table.Cell>
        <Table.Cell className={clsx({ [classes.rowSecondaryCell]: isCollapse })} isBorder={false}>
          {isShowPrice && (
            <Box color="darkGrey.main" component="span" fontSize="1rem" fontWeight="fontWeightMedium">
              <PriceFormatField displayType="text" thousandSeparator value={option?.additionalPrice} />
            </Box>
          )}
        </Table.Cell>
      </Table.Row>

      {open && children}
    </>
  );
}

TableRowsModifiersGroups.defaultProps = {
  level: 0,
  isShowPrice: false,
  isConditionalGroup: false,
};

TableRowsModifiersGroups.propTypes = {
  option: PropTypes.object,
  children: PropTypes.node,
  isHeader: PropTypes.bool,
  isCollapse: PropTypes.bool,
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isShowPrice: PropTypes.bool,
  isConditionalGroup: PropTypes.bool,
  t: PropTypes.func,
};

export default compose(withTranslation('menuMaker'))(TableRowsModifiersGroups);
