import produce from 'immer';

import { pageSizeTypes } from 'utils/pagination';

import {
  getServiceCharges,
  getServiceChargesByPagination,
  createServiceCharge,
  getServiceCharge,
  updateServiceCharge,
  deleteServiceCharge,
  setIsEnabledServiceCharge,
} from './actions';

const baseState = {
  error: null,
  fetching: false,
  fetched: false,
};

export const initialState = {
  getServiceCharges: {
    ...baseState,
    serviceCharges: [],
    pagination: {
      page: 1,
      page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
      count: 0,
      next: null,
      previous: null,
    },
  },
  loadPaginationStatus: {
    ...baseState,
    lastUpdatedRows: {},
  },
  createServiceCharge: {
    ...baseState,
    serviceCharge: {},
  },
  getServiceCharge: {
    ...baseState,
    serviceCharge: {},
  },
  updateServiceCharge: {
    ...baseState,
    serviceCharge: {},
  },
  deleteServiceCharge: {
    ...baseState,
  },
  setIsEnabledServiceCharge: {
    ...baseState,
  },
};

const settingsServiceChargesProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getServiceCharges.REQUEST:
        draft.getServiceCharges = {
          ...initialState.getServiceCharges,
          fetching: true,
        };
        break;
      case getServiceCharges.SUCCESS:
        draft.getServiceCharges.fetching = false;
        draft.getServiceCharges.fetched = true;
        draft.getServiceCharges.serviceCharges = payload.serviceCharges;
        draft.getServiceCharges.pagination = {
          ...draft.getServiceCharges.pagination,
          ...payload.pagination,
        };
        break;

      case getServiceCharges.FAILURE:
        draft.getServiceCharges.error = payload;
        draft.getServiceCharges.fetching = false;
        draft.getServiceCharges.fetched = false;
        break;

      case getServiceCharges.RESET:
        draft.getServiceCharges = { ...initialState.getServiceCharges };
        break;

      // pagination
      case getServiceChargesByPagination.REQUEST: {
        const currentServiceChargesPaginationState = draft.getServiceCharges;
        draft.getServiceCharges.pagination = {
          ...currentServiceChargesPaginationState?.pagination,
          page: payload.page,
        };
        draft.loadPaginationStatus = { ...initialState.loadPaginationStatus, fetching: true };

        break;
      }

      case getServiceChargesByPagination.SUCCESS: {
        const currentServiceChargesPaginationState = draft.getServiceCharges;
        let newsRows = [...currentServiceChargesPaginationState?.serviceCharges];
        newsRows = [...newsRows, ...payload?.serviceCharges];

        draft.getServiceCharges = {
          ...currentServiceChargesPaginationState,
          serviceCharges: [...newsRows],
          pagination: { ...currentServiceChargesPaginationState?.pagination, ...payload.pagination },
        };

        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          fetching: false,
          fetched: true,
          lastUpdatedRows: [...payload?.serviceCharges],
        };
        break;
      }

      case getServiceChargesByPagination.FAILURE:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          error: payload,
          fetching: false,
        };
        break;

      case getServiceChargesByPagination.RESET:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
        };
        break;

      // create service charge
      case createServiceCharge.REQUEST:
        draft.createServiceCharge = {
          ...initialState.createServiceCharge,
          fetching: true,
        };
        break;

      case createServiceCharge.SUCCESS: {
        const serviceChargesList = [...draft.getServiceCharges.serviceCharges];
        serviceChargesList.unshift(payload);

        draft.createServiceCharge.fetching = false;
        draft.createServiceCharge.fetched = true;
        draft.createServiceCharge.serviceCharge = payload;
        draft.getServiceCharges.serviceCharges = serviceChargesList;
        break;
      }

      case createServiceCharge.FAILURE:
        draft.createServiceCharge.error = payload;
        draft.createServiceCharge.fetching = false;
        draft.createServiceCharge.fetched = false;
        break;

      case createServiceCharge.RESET:
        draft.createServiceCharge = {
          ...initialState.createServiceCharge,
        };
        break;

      // get service charge
      case getServiceCharge.REQUEST:
        draft.getServiceCharge = {
          ...initialState.getServiceCharge,
          fetching: true,
        };
        break;

      case getServiceCharge.SUCCESS:
        draft.getServiceCharge.fetching = false;
        draft.getServiceCharge.fetched = true;
        draft.getServiceCharge.serviceCharge = payload;
        break;

      case getServiceCharge.FAILURE:
        draft.getServiceCharge.error = payload;
        draft.getServiceCharge.fetching = false;
        draft.getServiceCharge.fetched = false;
        break;

      case getServiceCharge.RESET:
        draft.getServiceCharge = {
          ...initialState.getServiceCharge,
        };
        break;

      // update service charge
      case updateServiceCharge.REQUEST:
        draft.updateServiceCharge = {
          ...initialState.updateServiceCharge,
          fetching: true,
        };
        break;

      case updateServiceCharge.SUCCESS: {
        const serviceChargesList = [...draft.getServiceCharges.serviceCharges];
        const indexServiceCharge = serviceChargesList.findIndex((serviceCharge) => {
          return serviceCharge?.uuid === payload?.uuid;
        });

        draft.updateServiceCharge.fetching = false;
        draft.updateServiceCharge.fetched = true;
        draft.updateServiceCharge.serviceCharge = payload;
        draft.getServiceCharges.serviceCharges[indexServiceCharge] = payload;
        break;
      }

      case updateServiceCharge.FAILURE:
        draft.updateServiceCharge.error = payload;
        draft.updateServiceCharge.fetching = false;
        draft.updateServiceCharge.fetched = false;
        break;

      case updateServiceCharge.RESET:
        draft.updateServiceCharge = {
          ...initialState.updateServiceCharge,
        };
        break;

      // delete service charge
      case deleteServiceCharge.REQUEST:
        draft.deleteServiceCharge = {
          ...initialState.deleteServiceCharge,
          fetching: true,
        };
        break;

      case deleteServiceCharge.SUCCESS: {
        const serviceChargesList = draft.getServiceCharges.serviceCharges.filter(
          (serviceCharge) => serviceCharge?.uuid !== payload,
        );
        draft.deleteServiceCharge.fetching = false;
        draft.deleteServiceCharge.fetched = true;
        draft.getServiceCharges.serviceCharges = serviceChargesList;
        break;
      }

      case deleteServiceCharge.FAILURE:
        draft.deleteServicesCharge.error = payload;
        draft.deleteServicesCharge.fetching = false;
        draft.deleteServicesCharge.fetched = false;
        break;

      case deleteServiceCharge.RESET:
        draft.deleteServicesCharge = {
          ...initialState.deleteServicesCharge,
        };
        break;

      // setIsEnabled
      case setIsEnabledServiceCharge.REQUEST:
        draft.setIsEnabledServiceCharge = { ...initialState.setIsEnabledServiceCharge, fetching: true };
        break;

      case setIsEnabledServiceCharge.SUCCESS: {
        const serviceChargesList = [...draft.getServiceCharges.serviceCharges];

        serviceChargesList.find((serviceCharge) => serviceCharge?.uuid === payload?.uuid).isEnabled =
          payload?.isEnabled;

        draft.setIsEnabledServiceCharge.fetching = false;
        draft.setIsEnabledServiceCharge.fetched = true;
        draft.getServiceCharges.serviceCharges = serviceChargesList;
        break;
      }

      case setIsEnabledServiceCharge.FAILURE:
        draft.setIsEnabledServiceCharge.error = payload;
        draft.setIsEnabledServiceCharge.fetching = false;
        draft.setIsEnabledServiceCharge.fetched = false;
        break;

      case setIsEnabledServiceCharge.RESET:
        draft.setIsEnabledServiceCharge = { ...initialState.setIsEnabledServiceCharge };
        break;

      default:
        return draft;
    }
  });

export default settingsServiceChargesProviderReducer;
