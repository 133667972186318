import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectBrandsProviderDomain = (state) => state.brandsProvider || initialState;

const selectBrands = createSelector(selectBrandsProviderDomain, (subState) => subState.getBrands.brands);
const selectBrandsFetching = createSelector(selectBrandsProviderDomain, (subState) => subState.getBrands.fetching);
const selectBrandsFetched = createSelector(selectBrandsProviderDomain, (subState) => subState.getBrands.fetched);
const selectBrandsError = createSelector(selectBrandsProviderDomain, (subState) => subState.getBrands.error);
const selectSelectedBrand = createSelector(selectBrandsProviderDomain, (subState) => subState.selectedBrand.brand);

export {
  selectBrandsProviderDomain,
  selectBrands,
  selectBrandsFetching,
  selectBrandsFetched,
  selectBrandsError,
  selectSelectedBrand,
};
