import dayjs from 'dayjs';

export function getReportFileName(options: { name?: string; extension?: string; useWithoutDate?: boolean }) {
  const name = options.name || 'Report';
  const extension = options.extension || 'xlsx';

  if (options.useWithoutDate) {
    return `${name}.${extension}`;
  }
  return `${name} ${dayjs().format('DD-MM-YYYY')}.${extension}`;
}

export function getReportFileNameByRangeDate(prefix: string, startDate: string, endDate: string) {
  const startFormat = dayjs(startDate).format('DD-MM-YYYY');
  const endFormat = dayjs(endDate).format('DD-MM-YYYY');

  return `${prefix} ${startFormat} - ${endFormat}.xlsx`;
}
