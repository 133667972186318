import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import productsSaga from './sagas';
import productsReducer from './reducer';

export function Products({ children }) {
  useInjectSaga({ key: 'products', saga: productsSaga });
  useInjectReducer({ key: 'products', reducer: productsReducer });

  return React.Children.only(children);
}

Products.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withProducts } from './withProducts';
export default compose(withConnect, memo)(Products);
