/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import withStyles from '@material-ui/core/styles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import ButtonBaseMui from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';

import useBrands from 'providers/root/BrandsProvider/useBrands';
import useIconParrot from 'hooks/useIconParrot';
import useMenuMakerDrawerState from 'hooks/useMenuMakerDrawerState';

import { CATEGORIES_PATH } from 'utils/categories';

import { getCloseDrawerFunction } from 'components/GeneralDrawer/MainView';

const ButtonBase = withStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(350),
    fontFamily: 'Roboto',
    color: theme.palette.common.black,
    height: theme.typography.pxToRem(42),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.darkGrey[2],
    paddingInline: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,

    '& > :not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
}))(ButtonBaseMui);

const MenuBase = withStyles(() => ({
  paper: {
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },
}))(Menu);

const MenuItemBase = withStyles((theme) => ({
  root: {
    padding: '12px 16px',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
    '&$focusVisible': {
      backgroundColor: theme.palette.darkGrey[2],
    },
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.darkGrey[2],
    },

    '&:hover': {
      backgroundColor: theme.palette.darkGrey[2],
    },
  },
}))(MenuItem);

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
  },
  text: {
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
  },
  minContent: {
    minWidth: 'min-content',
  },
  iconOpenMenu: {
    transform: 'rotate(180deg)',
  },
}));

function MenuBrands() {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation('menuMaker');

  const [anchorEl, setAnchorEl] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const {
    brands,
    brandsState,
    getBrands,
    loading: loadingBrands,
    setSelectedBrand,
    brandSelected,
    brandsReset,
    resetSelectedBrand,
  } = useBrands();
  const { IconRestaurant, IconChevronDown } = useIconParrot({ icons: ['restaurant', 'chevronDown'] });
  const { keepOpen, setKeepOpen } = useMenuMakerDrawerState();

  const isCategoriePath = location?.pathname === CATEGORIES_PATH;

  useEffect(() => {
    setTimeout(() => {
      getBrands();
    }, 0);

    return () => {
      brandsReset();
      resetSelectedBrand();
    };
  }, []);

  useEffect(() => {
    if (brandsState.isLoaded && brands.length > 0 && isEmpty(brandSelected)) {
      setSelectedBrand(isCategoriePath ? null : brands[0]);
    }
  }, [brandsState.isLoaded, brandSelected, brands]);

  useEffect(() => {
    if (!keepOpen && itemSelected) {
      setSelectedBrand(itemSelected === -1 ? null : itemSelected);
    }

    setKeepOpen(undefined);
    setItemSelected(null);
  }, [keepOpen]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectedItem = (value) => () => {
    const closeMenuMakerDrawer = getCloseDrawerFunction();
    setAnchorEl(null);

    if (closeMenuMakerDrawer) {
      setItemSelected(value);
      closeMenuMakerDrawer();
    } else {
      setSelectedBrand(value === -1 ? null : value);
    }
  };

  if (loadingBrands || (!brands.length && !brandsState.isLoaded)) {
    return <Skeleton height={40} variant="rect" width={200} />;
  }

  return (
    <Box className={classes.root}>
      {brands?.length > 0 && (
        <ButtonBase disableRipple onClick={handleOpenMenu}>
          <IconRestaurant className={classes.minContent} />

          <Box className={classes.text} component="span">
            {brandSelected ? brandSelected?.name : t('menuMaker:headerForm.filterProducts.withoutBrand')}
          </Box>

          <IconChevronDown
            className={clsx(classes.minContent, { [classes.iconOpenMenu]: Boolean(anchorEl) })}
            fontSize="small"
          />
        </ButtonBase>
      )}

      <MenuBase
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleCloseMenu}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {isCategoriePath ? (
          <MenuItemBase
            key="withoutBrand"
            disableRipple
            name="withoutBrand"
            onClick={handleSelectedItem(-1)}
            selected={!brandSelected}
          >
            {t('menuMaker:headerForm.filterProducts.withoutBrand')}
          </MenuItemBase>
        ) : null}

        {brands?.map((brand) => (
          <MenuItemBase
            key={brand.uuid}
            disableRipple
            name={brand.name}
            onClick={handleSelectedItem(brand)}
            selected={brandSelected?.uuid === brand.uuid}
          >
            {brand.name}
          </MenuItemBase>
        ))}
      </MenuBase>
    </Box>
  );
}

export default MenuBrands;
