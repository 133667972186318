import produce from 'immer';

import baseState from 'utils/baseState';
import { sortingDirection } from 'utils/reports/tables';

import {
  getReportsCancellationsResume,
  getReportsCancellationsDetails,
  getReportsCancellationsDetailsByPagination,
  getReportsCancellationsDetailsBySorting,
  exportReportsCancellations,
} from './actions';

export const initialState = {
  getReportsCancellationsResume: {
    ...baseState,
    resume: {},
  },
  getReportsCancellationsDetails: {
    ...baseState,
    cancellations: {},
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },
  loadPaginationStatus: {
    ...baseState,
    lastUpdatedRows: {},
  },

  sortingStatus: {
    ...baseState,
    columnSelected: 0,
    columnDirection: sortingDirection.ASC,
    lastSortingRows: {},
  },

  exportReportsCancellations: {
    ...baseState,
    document: null,
  },
};

const reportsCancellationsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getReportsCancellationsResume.REQUEST:
        draft.getReportsCancellationsResume = {
          ...initialState.getReportsCancellationsResume,
          fetching: true,
        };
        break;

      case getReportsCancellationsResume.SUCCESS:
        draft.getReportsCancellationsResume.fetching = false;
        draft.getReportsCancellationsResume.fetched = true;
        draft.getReportsCancellationsResume.resume = payload;
        break;

      case getReportsCancellationsResume.FAILURE:
        draft.getReportsCancellationsResume.error = payload;
        draft.getReportsCancellationsResume.fetching = false;
        draft.getReportsCancellationsResume.fetched = false;
        break;

      case getReportsCancellationsResume.RESET:
        draft.getReportsCancellationsResume = {
          ...initialState.getReportsCancellationsResume,
        };
        break;

      case getReportsCancellationsDetails.REQUEST:
        draft.getReportsCancellationsDetails = {
          ...initialState.getReportsCancellationsDetails,
          fetching: true,
        };
        break;

      case getReportsCancellationsDetails.SUCCESS:
        draft.getReportsCancellationsDetails.fetching = false;
        draft.getReportsCancellationsDetails.fetched = true;
        draft.getReportsCancellationsDetails.cancellations = payload.cancellations;
        draft.getReportsCancellationsDetails.pagination = {
          ...initialState.getReportsCancellationsDetails.pagination,
          ...payload.pagination,
        };
        break;

      case getReportsCancellationsDetails.FAILURE:
        draft.getReportsCancellationsDetails.error = payload;
        draft.getReportsCancellationsDetails.fetching = false;
        draft.getReportsCancellationsDetails.fetched = false;
        break;

      case getReportsCancellationsDetails.RESET:
        draft.getReportsCancellationsDetails = {
          ...initialState.getReportsCancellationsDetails,
        };
        break;

      // pagination
      case getReportsCancellationsDetailsByPagination.REQUEST:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          fetching: true,
        };
        break;

      case getReportsCancellationsDetailsByPagination.SUCCESS: {
        const currentReportsCancellationsPaginationState = draft.getReportsCancellationsDetails;
        let newsRows = [...currentReportsCancellationsPaginationState.cancellations?.rows];
        newsRows = [...newsRows, ...payload?.cancellations?.rows];

        draft.getReportsCancellationsDetails = {
          ...currentReportsCancellationsPaginationState,
          cancellations: { ...draft.getReportsCancellationsDetails.cancellations, rows: [...newsRows] },
          pagination: { ...currentReportsCancellationsPaginationState?.pagination, ...payload.pagination },
        };

        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          fetching: false,
          fetched: true,
          lastUpdatedRows: [...payload?.cancellations?.rows],
        };
        break;
      }

      case getReportsCancellationsDetailsByPagination.FAILURE:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          error: payload,
          fetching: false,
        };
        break;

      case getReportsCancellationsDetailsByPagination.RESET:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
        };
        break;

      // sorting
      case getReportsCancellationsDetailsBySorting.REQUEST: {
        const currentReportsCancellationsSortingState = draft.getReportsCancellationsDetails;
        draft.getReportsCancellationsDetails = {
          ...currentReportsCancellationsSortingState,
          pagination: {
            ...initialState.getReportsCancellationsDetails.pagination,
          },
        };

        draft.sortingStatus = {
          ...draft.sortingStatus,
          fetching: true,
          fetched: false,
          error: null,
        };

        break;
      }

      case getReportsCancellationsDetailsBySorting.SUCCESS: {
        const currentReportsCancellationsSortingState = draft.getReportsCancellationsDetails;
        draft.getReportsCancellationsDetails = {
          ...currentReportsCancellationsSortingState,
          cancellations: payload?.cancellations,
          pagination: { ...currentReportsCancellationsSortingState.pagination, ...payload?.pagination },
        };

        draft.sortingStatus = {
          ...draft.sortingStatus,
          fetching: false,
          fetched: true,
          lastSortingRows: payload?.cancellations?.rows,
        };
        break;
      }

      case getReportsCancellationsDetailsBySorting.SORTING:
        draft.sortingStatus = {
          ...draft.sortingStatus,
          fetched: false,
          columnSelected: payload.selected,
          columnDirection: payload.direction,
        };
        break;

      case getReportsCancellationsDetailsBySorting.RESET_SORTING:
        draft.sortingStatus = {
          ...initialState.sortingStatus,
        };
        break;

      case getReportsCancellationsDetailsBySorting.FAILURE:
        draft.sortingStatus = {
          ...draft.sortingStatus,
          error: payload,
          fetching: false,
          fetched: false,
        };

        break;

      case getReportsCancellationsDetailsBySorting.RESET:
        draft.sortingStatus = {
          ...initialState.sortingStatus,
        };
        break;

      // export file excel
      case exportReportsCancellations.REQUEST:
        draft.exportReportsCancellations = {
          ...initialState.exportReportsCancellations,
          fetching: true,
        };
        break;

      case exportReportsCancellations.SUCCESS:
        draft.exportReportsCancellations.fetching = false;
        draft.exportReportsCancellations.fetched = true;
        draft.exportReportsCancellations.document = payload;

        break;

      case exportReportsCancellations.FAILURE:
        draft.exportReportsCancellations.error = payload;
        draft.exportReportsCancellations.fetching = false;
        draft.exportReportsCancellations.fetched = false;
        break;

      case exportReportsCancellations.RESET:
        draft.exportReportsCancellations = {
          ...initialState.exportReportsCancellations,
        };
        break;

      default:
        return draft;
    }
  });

export default reportsCancellationsReducer;
