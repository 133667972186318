import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootTable: {
    paddingBottom: theme.spacing(1),
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.darkGrey[15]}`,
      width: '100%',
    },
  },
  rootRow: {
    padding: '1rem 0',
  },
  headerRow: {
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelRow: {
    textAlign: 'start',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  iconInitialState: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  iconRotate: {
    transform: 'rotate(180deg)',
  },
  wrapperTimesRow: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    '& > div': {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  chipTimesRow: {
    backgroundColor: theme.palette.darkGrey[5],
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(5),
  },
  contentLeftRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: theme.spacing(1.2),
    '& > :last-child': {
      marginLeft: theme.spacing(3.5),
    },
  },
  providersRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  labelCategoryRow: {
    textAlign: 'start',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  rowBorderBottom: {
    borderBottom: `1px solid ${theme.palette.darkGrey[5]}`,
  },
  columnProduct: {
    width: theme.typography.pxToRem(100),
    textAlign: 'center',
    marginLeft: theme.spacing(2),
  },
  columnPrice: {
    paddingLeft: theme.typography.pxToRem(20),
    width: theme.typography.pxToRem(128),
    textAlign: 'left',
    display: 'inline-flex',
  },
  columnPriceInMenu: {
    display: 'flex',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    width: theme.typography.pxToRem(140),
    textAlign: 'center',
  },
  labelHeaderColumns: {
    fontSize: '1rem',
    lineHeight: 'normal',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.darkGrey.main,
  },
  contentLeftRowProduct: {
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: theme.spacing(3),
    },
  },
  contentRightRowProduct: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: theme.spacing(1.2),
    '& > :last-child': {
      marginLeft: theme.spacing(3.5),
    },
  },
  imageContainer: {
    width: '2rem',
    height: '2rem',
    borderRadius: theme.shape.borderRadius,
    '& img': {
      width: '2rem',
      height: '2rem',
      borderRadius: theme.shape.borderRadius,
    },
  },
  labelProductRow: {
    textAlign: 'start',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  boxModifiers: {
    color: theme.palette.darkGrey.main,
  },
  labelModifiers: {
    textAlign: 'start',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.darkGrey.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  disabledRow: {
    pointerEvents: 'none',
    opacity: 0.3,
  },
  styleCursorPoint: {
    cursor: 'pointer',
  },
  styleCursorNormal: {
    cursor: 'default',
  },
}));

export default useStyles;
