import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

export function withInvoicingHoc(Component) {
  function withInvoicing(props) {
    return <Component {...props} />;
  }

  withInvoicing.displayName = `withInvoicing(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withInvoicing;
}

const mapStateToProps = createStructuredSelector({});

export function mapDispatchToProps() {
  return {};
}

export const withInvoicingState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withInvoicingState, withInvoicingHoc);
