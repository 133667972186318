/**
 *
 * ParrotSoftware
 * GoogleMapsProvider reducer
 *
 */
import produce from 'immer';
import baseState from 'utils/baseState';

import { geocodingLookupLatitudeLongitude } from './actions';

export const initialState = {
  geocodingLookupLatitudeLongitude: {
    ...baseState,
    geometry: {
      location: {},
      locationType: {},
      viewport: {},
    },
  },
};

const googleMapsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case geocodingLookupLatitudeLongitude.REQUEST:
        draft.geocodingLookupLatitudeLongitude = {
          ...initialState.geocodingLookupLatitudeLongitude,
          fetching: true,
        };
        break;

      case geocodingLookupLatitudeLongitude.SUCCESS:
        draft.geocodingLookupLatitudeLongitude.fetching = false;
        draft.geocodingLookupLatitudeLongitude.fetched = true;
        draft.geocodingLookupLatitudeLongitude.geometry = payload;
        break;

      case geocodingLookupLatitudeLongitude.FAILURE:
        draft.geocodingLookupLatitudeLongitude.error = payload;
        draft.geocodingLookupLatitudeLongitude.fetching = false;
        draft.geocodingLookupLatitudeLongitude.fetched = false;
        break;

      case geocodingLookupLatitudeLongitude.RESET:
        draft.geocodingLookupLatitudeLongitude = {
          ...initialState.geocodingLookupLatitudeLongitude,
        };
        break;

      default:
        return draft;
    }
  });

export default googleMapsReducer;
