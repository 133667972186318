/**
 *
 * ParrotSoftware
 * SettingsTablesConfigProvider saga
 *
 */
import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  updateTableConfigRequest,
  getStoreTableSettingsRequest,
} from 'utils/api/baas/tableAreasService/serviceTablesConfig';

import { updateTableConfig, getStoreTableSettings } from './actions';

export function* getStoreTableSettingsSaga() {
  try {
    yield put(getStoreTableSettings.request());

    const { data } = yield call(baasRequest, getStoreTableSettingsRequest);

    yield put(getStoreTableSettings.success(data));
  } catch (err) {
    yield put(getStoreTableSettings.failure(err));
  }
}

export function* updateTableConfigSaga({ payload }) {
  const values = payload;

  try {
    yield put(updateTableConfig.request());

    yield call(baasRequest, updateTableConfigRequest, values);

    yield put(updateTableConfig.success());
  } catch (err) {
    yield put(updateTableConfig.failure(err));
  }
}

export default function* settingsTablesConfigRootSga() {
  yield takeLatest(getStoreTableSettings.TRIGGER, getStoreTableSettingsSaga);
  yield takeLatest(updateTableConfig.TRIGGER, updateTableConfigSaga);
}
