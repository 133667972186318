import axios from 'axios';
import { getAuthToken } from 'utils/auth';

import { env } from 'utils/api/baas/env';

const authTokens = getAuthToken();

const authorization = authTokens?.accessToken ? { authorization: `Bearer ${authTokens.accessToken}` } : undefined;
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Language': 'es-MX',
  ...authorization,
};

export const reportsApi = axios.create({
  baseURL: env.BASE_URL_API,
  headers,
});
