/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import camelCase from 'lodash/camelCase';

import { useFeatures } from 'FeatureProvider';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import Table from '@experimental-components/Table';
import ChanelItem from 'components/ChanelItem';

import { getValidateItemsAvailabilityFlags } from 'utils/featureFlags/menus';

import useStyles from './styles';

function TableMenusForBrand({ menus, onOpenErrorsDetails }) {
  const { t } = useTranslation('menuMaker');
  const classes = useStyles();
  const { flags } = useFeatures();
  const { enableValidateItemsAvailability } = getValidateItemsAvailabilityFlags(flags);

  const renderDatePublish = (storeProviderLog, provider) => {
    if (
      storeProviderLog?.status === 'PENDING' ||
      storeProviderLog?.status === 'ACCEPTED' ||
      storeProviderLog?.status === 'APPROVED_IN_VALIDATION'
    ) {
      return t('menuMaker:publishMenu.penddingApprovePublish', { date: storeProviderLog?.updatedAt });
    }

    if (storeProviderLog?.status === 'APPROVED_SYNCED' && provider === 'PARROT') {
      return t('menuMaker:publishMenu.updatePublishDatePos', { date: storeProviderLog?.updatedAt });
    }

    return storeProviderLog?.updatedAt;
  };

  function renderBody() {
    const menusNotDraf = menus?.filter((menu) => menu?.providers?.length > 0);

    if (!menus?.length || !menusNotDraf?.length) {
      return (
        <Table.Row>
          <Table.Cell colSpan="4">Sin Registros</Table.Cell>
        </Table.Row>
      );
    }

    return menus?.map((menu, index) => (
      <Table.Row key={menu?.uuid}>
        <Table.Cell className={classes.tdName} isBorder={index !== 0}>
          {menu?.name}
        </Table.Cell>

        <Table.BigCell align="left" className={classes.td} component="td" isBorder={index !== 0} scope="row">
          {menu?.categories?.length > 0 ? (
            <Table.BigCell.ExpandableList
              defaultItems={6}
              list={menu?.categories?.map((category) => (
                <Table.BigCell.Option key={category?.uuid}>{category?.category?.name}</Table.BigCell.Option>
              ))}
            />
          ) : (
            t('common:notAvailableDefault')
          )}
        </Table.BigCell>

        <Table.BigCell align="left" className={classes.td} component="td" isBorder={index !== 0} scope="row">
          {menu?.providers?.length > 0
            ? menu?.providers.map((provider) => {
                if (
                  provider?.storeProviderLog !== null &&
                  (provider?.storeProviderLog?.status === 'REJECTED' ||
                    provider?.storeProviderLog?.status === 'APPROVED_WITH_ERRORS')
                ) {
                  return (
                    <Box key={provider?.uuid} className={classes.publishErrorProvider}>
                      <ChanelItem
                        key={camelCase(`${menu?.uuid}-${provider?.providerName || provider}`)}
                        idRef={`-${menu?.uuid}-${provider?.providerName || provider}`}
                        size={24}
                        value={provider?.providerName || provider}
                      />
                      <Box component="p" m={0}>
                        {t('menuMaker:publishMenu.publishWithErrors')}
                      </Box>
                      {enableValidateItemsAvailability && (
                        <Link
                          className={classes.viewDetails}
                          color="secondary"
                          onClick={() => onOpenErrorsDetails(provider)}
                          underline="always"
                          variant="button"
                        >
                          {t('common:buttons.viewDetail')}
                        </Link>
                      )}
                    </Box>
                  );
                }

                return (
                  <Box key={provider?.uuid} className={classes.publishProvider}>
                    <ChanelItem
                      key={camelCase(`${menu?.uuid}-${provider?.providerName || provider}`)}
                      idRef={`-${menu?.uuid}-${provider?.providerName || provider}`}
                      size={24}
                      value={provider?.providerName || provider}
                    />
                    <Typography>
                      {provider?.storeProviderLog
                        ? renderDatePublish(provider?.storeProviderLog, provider?.providerName || provider)
                        : provider?.lastPublishDate}
                    </Typography>
                  </Box>
                );
              })
            : t('common:notAvailableDefault')}
        </Table.BigCell>
      </Table.Row>
    ));
  }

  return (
    <Box mb={2}>
      <Table aria-label="menu maker table for brand">
        <Table.Head>
          <Table.Row>
            <Table.HeadCell align="left">{t('menuMaker:menuMakerTable.columns.name')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('menuMaker:menuMakerTable.columns.categories')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('menuMaker:menuMakerTable.columns.lastPublish')}</Table.HeadCell>
          </Table.Row>
        </Table.Head>

        <Table.Body>{renderBody()}</Table.Body>
      </Table>
    </Box>
  );
}

TableMenusForBrand.propTypes = {
  menus: PropTypes.array,
};

export default TableMenusForBrand;
