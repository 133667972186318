import PropTypes from 'prop-types';

import Table from '@experimental-components/Table';

import PlaceholdersTable from 'components/PlaceholdersTable';

import TableRowsBody from './TableRowsBody';

function MenuMakerPreviewTable({
  isLoaded,
  loading,
  products,
  productsDetails,
  onLoadProductModifiersGroups,
  loadingProductsDetails,
  t,
}) {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell align="left" colSpan="3">
            {t('menuMaker:previewMenu.menuPreviewTable.columns.articles')}
          </Table.HeadCell>
          <Table.HeadCell align="left">{t('menuMaker:previewMenu.menuPreviewTable.columns.price')}</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {loading && !isLoaded && <PlaceholdersTable placeholderCols={4} />}
        {isLoaded && products.length === 0 && (
          <Table.Row>
            <Table.Cell align="center" colSpan="2">
              Sin Registros
            </Table.Cell>
          </Table.Row>
        )}
        {isLoaded &&
          products?.map((product) => (
            <TableRowsBody
              key={product?.uuid}
              loadingModifiersGroups={loadingProductsDetails}
              modifiersGroups={productsDetails[product?.uuid]}
              onLoadModfiersGroups={onLoadProductModifiersGroups}
              product={product}
            />
          ))}
      </Table.Body>
    </Table>
  );
}

MenuMakerPreviewTable.propTypes = {
  isLoaded: PropTypes.bool,
  loading: PropTypes.bool,
  products: PropTypes.array,
  productsDetails: PropTypes.object,
  onLoadProductModifiersGroups: PropTypes.func,
  loadingProductsDetails: PropTypes.bool,
  t: PropTypes.func,
};

export default MenuMakerPreviewTable;
