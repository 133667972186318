export const DEFAULT_SIZE_PAGE = 6;
export const DEFAULT_PAGE = 1;

const PROPERTY_TITLE = 'title';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  TITLE: PROPERTY_TITLE,
};

const FILTER_PROPERTIES = [PROPERTY_TITLE];

export function getFilteredDiscountsByText(discounts, sorting) {
  return discounts.filter(
    (discount) =>
      FILTER_PROPERTIES.filter((property) =>
        discount[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}

const DISCOUNT_FIXED = 'FIXED';
const DISCOUNT_OPEN = 'OPEN';

const PRICE = 'PRICE';
const PERCENTAGE = 'PERCENTAGE';

const AMOUNT_OPEN = 'AMOUNT_OPEN';
const AMOUNT_FIXED = 'AMOUNT_FIXED';

const ORDER = 'ORDER';
const ORDER_ITEM = 'ORDER_ITEM';

export const discountTypes = {
  DISCOUNT_FIXED,
  DISCOUNT_OPEN,
};

export const priceTypes = {
  PRICE,
  PERCENTAGE,
};

export const amountTypes = {
  AMOUNT_OPEN,
  AMOUNT_FIXED,
};

export const selectedDiscountTypes = {
  AMOUNT_OPEN,
  [AMOUNT_FIXED]: null,
  [DISCOUNT_FIXED]: {
    PRICE,
    PERCENTAGE,
  },
  [DISCOUNT_OPEN]: {
    PRICE,
    PERCENTAGE,
  },
};

export const discountTypesLabels = {
  [AMOUNT_OPEN]: 'restaurant:discounts.discountsTable.rows.quantityOpen',
  [AMOUNT_FIXED]: null,
  [DISCOUNT_FIXED]: {
    [PRICE]: 'restaurant:discounts.discountsTable.rows.discountTypeFixedPrice',
    [PERCENTAGE]: 'restaurant:discounts.discountsTable.rows.discountTypeFixedPercentage',
  },
  [DISCOUNT_OPEN]: {
    [PRICE]: 'restaurant:discounts.discountsTable.rows.discountTypeOpenPrice',
    [PERCENTAGE]: 'restaurant:discounts.discountsTable.rows.discountTypeOpenPercentage',
  },
};

export const discountTypesScopeLabels = {
  [ORDER]: 'restaurant:discounts.discountsTable.rows.discountTypeScopeOrder',
  [ORDER_ITEM]: 'restaurant:discounts.discountsTable.rows.discountTypeScopeOrderItem',
};

export const discountTypeScopes = [
  {
    label: 'restaurantDiscounts:discountTypes.completeOrder',
    value: ORDER,
  },
  {
    label: 'restaurantDiscounts:discountTypes.itemInOrder',
    value: ORDER_ITEM,
  },
];

export function formatAmount(amount) {
  return amount?.replace('$', '');
}

export function formatPercentage(amount) {
  return amount?.replace('%', '');
}
