import { Dayjs } from 'dayjs';

const FILTERS_REPORTS_STORAGE_KEY = 'parr-filters-reports';

export interface filtersReportsProps {
  [key: string]: string | string[] | Dayjs | undefined;
}

export function setFiltersReportsKey(filters: filtersReportsProps) {
  localStorage.setItem(FILTERS_REPORTS_STORAGE_KEY, JSON.stringify(filters));
}

export function getFiltersReportsKey() {
  const filters = localStorage.getItem(FILTERS_REPORTS_STORAGE_KEY);

  if (!filters) {
    return;
  }

  return JSON.parse(filters);
}

export function removeFiltersReportsKey() {
  localStorage.removeItem(FILTERS_REPORTS_STORAGE_KEY);
}
