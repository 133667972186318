import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

function SignInLayout({ children }) {
  const classes = useStyles();

  return <div className={classes.row}>{children}</div>;
}

SignInLayout.propTypes = { children: PropTypes.node };

export default SignInLayout;
