import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getInvoicesOrdersEdit,
  getInvoicesOrdersEditByPagination,
  getInvoicesOrdersPreEdit,
  updateInvoicesOrders,
  resetEditedInvoicesOrders,
  generateInvoicing,
  getInvoicesOrderDetailsEdit,
  updateOrderRemoveItem,
  updateOrderDiscount,
  revertChangesOrderEdited,
  saveChangesOrderEdited,
} from './actions';

import {
  selectInvoicesEdit,
  selectInvoicesEditState,
  selectInvoicesEditPagination,
  selectInvoicesEditPaginationState,
  selectInvoicesPreEditState,
  selectInvoicesPreEditPreview,
  selectUpdateInvoicesOrdersState,
  selectResetEditedInvoicesOrdersState,
  selectGenerateInvoicingState,
  selectGenerateInvoicingInfo,
  selectInvoicesOrderDetails,
  selectInvoicesOrderDetailsState,
  selectUpdateOrderRemoveItem,
  selectUpdateOrderRemoveItemState,
  selectUpdateOrderDiscount,
  selectUpdateOrderDiscountState,
  selectRevertChangesOrderEditedState,
  selectSaveChangesOrderEditedState,
} from './selectors';

export function withInvoicesTicketsEditorHoc(Component) {
  function withInvoicesTicketsEditor(props) {
    return <Component {...props} />;
  }

  withInvoicesTicketsEditor.displayName = `withInvoicesTicketsEditor(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withInvoicesTicketsEditor;
}

const mapStateToProps = createStructuredSelector({
  invoicesEditObject: selectInvoicesEdit,
  invoicesEditState: selectInvoicesEditState,
  invoicesEditPaginationValues: selectInvoicesEditPagination,
  invoicesEditPaginationState: selectInvoicesEditPaginationState,
  invoicesPreEditPreview: selectInvoicesPreEditPreview,
  invoicesPreEditState: selectInvoicesPreEditState,
  updateInvoicesOrdersState: selectUpdateInvoicesOrdersState,
  resetEditedInvoicesOrdersState: selectResetEditedInvoicesOrdersState,
  generateInvoicingState: selectGenerateInvoicingState,
  generateInvoicingInfo: selectGenerateInvoicingInfo,
  invoicesOrderDetails: selectInvoicesOrderDetails,
  invoicesOrderDetailsState: selectInvoicesOrderDetailsState,
  updateOrderRemoveItemObject: selectUpdateOrderRemoveItem,
  updateOrderRemoveItemState: selectUpdateOrderRemoveItemState,
  updateOrderDiscountObject: selectUpdateOrderDiscount,
  updateOrderDiscountState: selectUpdateOrderDiscountState,
  revertChangesOrderEditedState: selectRevertChangesOrderEditedState,
  saveChangesOrderEditedState: selectSaveChangesOrderEditedState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadInvoicesEdit: (filters = {}) => dispatch(getInvoicesOrdersEdit(filters)),
    loadInvoicesEditByPagination: (filters = {}) => dispatch(getInvoicesOrdersEditByPagination(filters)),
    loadInvoicesPreEdit: (filters = {}) => dispatch(getInvoicesOrdersPreEdit(filters)),
    updateInvoicesOrders: (filters = {}) => dispatch(updateInvoicesOrders(filters)),
    resetEditedInvoicesOrders: (filters = {}) => dispatch(resetEditedInvoicesOrders(filters)),
    generateInvoicing: (filters = {}) => dispatch(generateInvoicing(filters)),
    loadInvoicesOrderDetails: (filters = {}) => dispatch(getInvoicesOrderDetailsEdit(filters)),
    updateOrderRemoveItem: (filters = {}) => dispatch(updateOrderRemoveItem(filters)),
    updateOrderDiscount: (filters = {}) => dispatch(updateOrderDiscount(filters)),
    revertChangesOrderEdited: (filters = {}) => dispatch(revertChangesOrderEdited(filters)),
    saveChangesOrderEdited: (filters = {}) => dispatch(saveChangesOrderEdited(filters)),
    resetInvoicesEdit: () => dispatch(getInvoicesOrdersEdit.reset()),
    resetInvoicesEditPagination: () => dispatch(getInvoicesOrdersEditByPagination.reset()),
    resetInvoicesPreEdit: () => dispatch(getInvoicesOrdersPreEdit.reset()),
    resetUpdateInvoicesOrders: () => dispatch(updateInvoicesOrders.reset()),
    resetEditedInvoicesOrdersInitial: () => dispatch(resetEditedInvoicesOrders.reset()),
    resetGenerateInvoicing: () => dispatch(generateInvoicing.reset()),
    resetInvoicesOrderDetails: () => dispatch(getInvoicesOrderDetailsEdit.reset()),
    resetUpdateOrderRemoveItem: () => dispatch(updateOrderRemoveItem.reset()),
    resetUpdateOrderDiscount: () => dispatch(updateOrderDiscount.reset()),
    resetChangesOrderEdited: () => dispatch(revertChangesOrderEdited.reset()),
    resetSaveChangesOrderEdited: () => dispatch(revertChangesOrderEdited.reset()),
  };
}

export const withInvoicesTicketsEditorState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withInvoicesTicketsEditorState, withInvoicesTicketsEditorHoc);
