/**
 *
 * ParrotSoftware
 * SettingsServiceAreasProvider root
 *
 */
import { Children, memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

export function ServiceAreas({ children }) {
  useInjectSaga({ key: 'settingsServiceAreas', saga });
  useInjectReducer({ key: 'settingsServiceAreas', reducer });

  return Children.only(children);
}

ServiceAreas.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withServiceAreas } from './withServiceAreas';
export default compose(withConnect, memo)(ServiceAreas);
