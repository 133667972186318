import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  colorCircle?: string;
  colorCircleInherit?: string;
  size?: string | number;
}

const Help = forwardRef(function Help(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#47465F', colorCircle = '#F7F7F9', colorCircleInherit = '#CECDD1', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <circle cx="12" cy="12" fill={colorCircle} r="11.5" stroke={colorCircleInherit} />
      <path
        clipRule="evenodd"
        d="M10.25 7.46627C10.805 7.14007 11.4576 7.02084 12.0921 7.12967C12.7266 7.23851 13.3021 7.5684 13.7167 8.06091C14.1313 8.55342 14.3583 9.17677 14.3573 9.82056L14.3573 9.82137C14.3573 10.6224 13.7493 11.2979 12.9644 11.8212C12.5886 12.0718 12.2089 12.262 11.9209 12.39C11.7778 12.4536 11.6592 12.501 11.5777 12.532C11.537 12.5475 11.5057 12.5589 11.4853 12.5662L11.4632 12.5739L11.4588 12.5754L11.6312 13.0926C11.4588 12.5754 11.4587 12.5755 11.4586 12.5755L11.4583 12.5756C11.236 12.6499 11.086 12.8581 11.086 13.0926V15.2644C11.086 15.5655 11.3301 15.8096 11.6312 15.8096C11.9323 15.8096 12.1764 15.5655 12.1764 15.2644V13.467C12.2338 13.4432 12.2966 13.4163 12.3638 13.3864C12.6893 13.2418 13.1273 13.0231 13.5693 12.7285C14.42 12.1614 15.4475 11.2015 15.4477 9.82185L15.4477 9.82218L14.9025 9.82137H15.4477V9.82185C15.449 8.92067 15.1313 8.04811 14.5509 7.35868C13.9705 6.66916 13.1648 6.20731 12.2764 6.05494C11.3881 5.90257 10.4745 6.0695 9.69749 6.52618C8.92045 6.98285 8.3301 7.69979 8.03101 8.55002C7.93109 8.83407 8.08036 9.14534 8.36441 9.24526C8.64846 9.34519 8.95973 9.19592 9.05965 8.91187C9.27329 8.30457 9.69496 7.79246 10.25 7.46627ZM11.7183 16.9093C11.4172 16.9093 11.1731 17.1534 11.1731 17.4545C11.1731 17.7556 11.4172 17.9997 11.7183 17.9997H11.7292C12.0303 17.9997 12.2744 17.7556 12.2744 17.4545C12.2744 17.1534 12.0303 16.9093 11.7292 16.9093H11.7183Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default Help;
