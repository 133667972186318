export const DEFAULT_SIZE_PAGE = 6;
export const DEFAULT_PAGE = 1;

const PROPERTY_NAME = 'name';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  NAME: PROPERTY_NAME,
};

const FILTER_PROPERTIES = [PROPERTY_NAME];

export function getFilteredDiningOptionsByText(diningOptions, sorting) {
  return diningOptions.filter(
    (diningOption) =>
      FILTER_PROPERTIES.filter((property) =>
        diningOption[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}

const DINING_OPTION_TYPE_PICK_UP = 'PICK_UP';

const DINING_OPTION_TYPE_DINE_IN = 'DINE_IN';

const DINING_OPTION_TYPE_DELIVERY = 'DELIVERY';

const DINING_OPTION_TYPE_TAKE_OUT = 'TAKE_OUT';

export const diningOptionsTypes = [
  {
    label: 'restaurant:diningOptions.formCreateDiningOption.diningOptionsTypesLabels.pickUp',
    value: DINING_OPTION_TYPE_PICK_UP,
  },

  {
    label: 'restaurant:diningOptions.formCreateDiningOption.diningOptionsTypesLabels.dineIn',
    value: DINING_OPTION_TYPE_DINE_IN,
  },

  {
    label: 'restaurant:diningOptions.formCreateDiningOption.diningOptionsTypesLabels.delivery',
    value: DINING_OPTION_TYPE_DELIVERY,
  },

  {
    label: 'restaurant:diningOptions.formCreateDiningOption.diningOptionsTypesLabels.takeOut',
    value: DINING_OPTION_TYPE_TAKE_OUT,
  },
];
