/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import useBrands from 'providers/root/BrandsProvider/useBrands';
import useMenuMakerDrawerState from 'hooks/useMenuMakerDrawerState';
import useNavigation from 'hooks/navigation/useNavigation';

import { useElementEdited } from 'containers/MenuMakerResume/ProviderEdition';

import DrawerMenuMaker from 'components/GeneralDrawer';
import MenuForm from 'components/MenuForm';
import ModalConfirm from 'components/ModalConfirm';
import SelectSKU from 'components/SelectSKU';
import ModalValidateUsedSku from 'components/ModalValidateUsedSku';

import {
  getOperationTimes,
  getProvidersValues,
  getCategoriesValues,
  getInitialCategoriesSelected,
  getValuesProvidersFields,
  getValuesTimeFieldsFields,
  defaultMenu,
  menuFormIsDirty,
  menuFormIsValid,
  generateSkuValue,
  handleFormatValidateItems,
} from 'utils/menuMaker';

function MenuDrawer({
  actionState,
  categoriesState,
  focusInCategories,
  isEdition,
  menuState,
  onLoadCategories,
  onLoadMenu,
  onResetCategories,
  onResetGetMenu,
  onSubmit,
  openDrawer,
  uuidSelected,
}) {
  const [initMenu, setInitMenu] = useState(defaultMenu);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [saveWithoutOverride, setSaveWithoutOverride] = useState(false);
  const [step, setStep] = useState(1);
  const [skuSelectedTmp, setSkuSelectedTmp] = useState(null);
  const [openValidateSku, setOpenValidateSku] = useState(false);
  const { setKeepOpen, setDrawerIsDirty } = useMenuMakerDrawerState();
  const { setReloadStoreOnClose, setChangeLocationState, setGoToMenuDraft } = useNavigation();
  const { setElementEdited } = useElementEdited();

  const unblockRef = useRef();
  const { brandSelected } = useBrands();
  const { t } = useTranslation();
  const history = useHistory();
  const methods = useForm({ mode: 'onChange', defaultValues: { timeFields: [{}], selectedCategories: [] } });

  const watchFields = methods.watch();
  const isFormDirty = menuFormIsDirty(isEdition, initMenu, watchFields);
  const isFormValid = menuFormIsValid(methods.formState, watchFields?.timeFields);

  useEffect(() => {
    if (openDrawer) onLoadCategories();

    return () => {
      onResetCategories();
      onResetGetMenu();
      setSaveWithoutOverride(false);
    };
  }, [openDrawer]);

  useEffect(() => {
    if (isEdition) {
      onLoadMenu();
    } else {
      onResetGetMenu();
    }
  }, [uuidSelected]);

  useEffect(() => {
    let menuValues;
    setStep(1);

    if (isEdition && menuState.data && categoriesState.fetched) {
      const { data } = menuState;
      const skuValue = data?.sku?.slice(0, 3) === 'ME-' ? data?.sku.slice(3) : data?.sku;

      menuValues = {
        nameMenuField: data?.name,
        posFiled: getValuesProvidersFields(data?.providers, 'posFiled'),
        uberEatsFiled: getValuesProvidersFields(data?.providers, 'uberEatsFiled'),
        didiFoodFiled: getValuesProvidersFields(data?.providers, 'didiFoodFiled'),
        rappiFiled: getValuesProvidersFields(data?.providers, 'rappiFiled'),
        onlineField: getValuesProvidersFields(data?.providers, 'onlineField'),
        changePricesField: data?.hasProductOverrides ? 1 : 0,
        timeFields: getValuesTimeFieldsFields(data?.operationTimes),
        selectedCategories: handleFormatValidateItems(
          getInitialCategoriesSelected(categoriesState.list, data?.categories),
        ),
        skuFieldMultiStore: skuValue ?? '',
      };

      setSkuSelectedTmp(skuValue ?? '');
    } else {
      const menuSKU = generateSkuValue();

      menuValues = { ...defaultMenu, skuFieldMultiStore: menuSKU };
      setSkuSelectedTmp(menuSKU);
    }
    setInitMenu(menuValues);

    const fillForm = async () => {
      if (openDrawer) {
        try {
          await methods.reset(menuValues);
          if (isEdition) methods.trigger();
        } catch (e) {
          return null;
        }
      }
    };

    fillForm();
  }, [isEdition, menuState.data, categoriesState.fetched]);

  useEffect(() => {
    setSkuSelectedTmp(watchFields?.skuFieldMultiStore);
  }, [watchFields?.skuFieldMultiStore]);

  useEffect(() => {
    setDrawerIsDirty(isFormDirty);
    if (!openDrawer) {
      setKeepOpen(false);
      setDrawerIsDirty(false);
      setReloadStoreOnClose(false);
    }

    if (openDrawer && isFormDirty) {
      const unblock = history.block((destination) => {
        setNextPage(destination);

        setOpenConfirmationModal(isFormDirty);
        if (!isFormDirty) setKeepOpen(false);

        return !isFormDirty;
      });
      unblockRef.current = unblock;

      return () => {
        unblock();
      };
    }
  }, [isFormDirty, openDrawer]);

  useEffect(() => {
    if (actionState.fetched) {
      if (setElementEdited) setElementEdited(true);
      unblockNavigation();
    }
  }, [actionState.fetched]);

  const handleCloseDrawer = () => {
    history.replace({ search: '' });
  };

  const handleValidateSku = () => {
    setOpenValidateSku(true);
  };

  const handleSaveMenu = () => {
    let sendValues = {};

    setNextPage(null);
    const data = watchFields;

    sendValues = {
      name: data?.nameMenuField,
      operationTimes: getOperationTimes(data.timeFields),
      providers: getProvidersValues(data),
      categories: getCategoriesValues(data.selectedCategories),
      hasProductOverrides: !!data?.changePricesField,
      sku: `ME-${data?.skuFieldMultiStore}`,
      [brandSelected && 'brand']: brandSelected?.uuid,
    };

    return onSubmit(sendValues);
  };

  const unblockNavigation = () => {
    setKeepOpen(false);
    if (unblockRef.current) unblockRef.current();

    unblockRef.current = null;
    setOpenConfirmationModal(false);

    if (nextPage?.pathname === history.location?.pathname && nextPage?.search === history.location?.search) {
      setStep(1);
      methods.reset(initMenu);
      setSkuSelectedTmp(initMenu?.skuFieldMultiStore);
    }

    history.replace({ pathname: nextPage?.pathname, search: nextPage?.search });
    setReloadStoreOnClose(true);
  };

  const handleCancel = () => {
    setChangeLocationState(false);
    setReloadStoreOnClose(false);
    setGoToMenuDraft(false);
    setKeepOpen(true);
    setNextPage(null);
    setOpenConfirmationModal(false);
  };

  const handleValiteWithoutOverrides = () => {
    const { data } = menuState;
    if (data?.hasProductOverrides && watchFields?.changePricesField === 0) {
      return setSaveWithoutOverride(true);
    }
    return handleValidateSku();
  };

  const handleConfirmationWithoutOverrides = () => {
    handleValidateSku();
    setSaveWithoutOverride(false);
  };

  const handleCloseValidateWithoutOverrides = () => {
    setSaveWithoutOverride(false);
  };

  const handleAcceptSKU = () => {
    methods.setValue('skuFieldMultiStore', skuSelectedTmp, { shouldValidate: true });
    setStep(1);
  };

  const handleGoBack = () => {
    setSkuSelectedTmp(watchFields?.skuFieldMultiStore);
    setStep(1);
  };

  const handleSelectSku = (value) => {
    setSkuSelectedTmp(value ?? '');
  };

  const handleCloseValidateSku = () => {
    setOpenValidateSku(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <DrawerMenuMaker currentStep={step} open={openDrawer}>
          <DrawerMenuMaker.MainView
            disabled={
              !(isFormDirty && isFormValid) ||
              categoriesState.fetching ||
              menuState.fetching ||
              actionState.fetching ||
              openValidateSku ||
              saveWithoutOverride
            }
            labelAction={t('common:buttons.save')}
            loading={actionState.fetching || openValidateSku || saveWithoutOverride}
            onAction={isEdition ? handleValiteWithoutOverrides : handleValidateSku}
            onClose={openDrawer ? handleCloseDrawer : undefined}
            step={1}
          >
            <MenuForm
              categoriesState={categoriesState}
              focusInCategories={focusInCategories}
              isEdition={isEdition}
              menuState={menuState}
              onSetStep={setStep}
            />
          </DrawerMenuMaker.MainView>

          <DrawerMenuMaker.Step
            disabled={watchFields?.skuFieldMultiStore === skuSelectedTmp}
            labelAction={t('common:buttons.accept')}
            onAction={handleAcceptSKU}
            onGoBack={handleGoBack}
            step={2}
            t={t}
          >
            <SelectSKU
              onSelectOption={handleSelectSku}
              optionSelected={watchFields?.skuFieldMultiStore}
              t={t}
              type="menu"
            />
          </DrawerMenuMaker.Step>
        </DrawerMenuMaker>
      </FormProvider>

      {saveWithoutOverride && (
        <ModalConfirm
          data-testid="modalConfirmSaveWithoutOverrides"
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          loading={actionState.fetching}
          message={t('menuMaker:createMenu.modalConfirmWithoutOverride.message')}
          onAccept={handleConfirmationWithoutOverrides}
          onCancel={handleCloseValidateWithoutOverrides}
          open={saveWithoutOverride}
          title={t('menuMaker:createMenu.modalConfirmWithoutOverride.title')}
        />
      )}

      {openConfirmationModal && (
        <ModalConfirm
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          message={t('menuMaker:messages.exitNoSaveMessage')}
          onAccept={unblockNavigation}
          onCancel={handleCancel}
          open={openConfirmationModal}
          title={t('menuMaker:messages.exitNoSaveTitle')}
        />
      )}

      {openValidateSku && (
        <ModalValidateUsedSku
          actionState={actionState}
          itemUuid={uuidSelected}
          onAccept={handleSaveMenu}
          onClose={handleCloseValidateSku}
          sku={`ME-${watchFields?.skuFieldMultiStore}`}
          t={t}
          type="menu"
        />
      )}
    </>
  );
}

MenuDrawer.propTypes = {
  actionState: PropTypes.object,
  categoriesState: PropTypes.object,
  focusInCategories: PropTypes.bool,
  isEdition: PropTypes.bool,
  menuState: PropTypes.object,
  onLoadCategories: PropTypes.func,
  onLoadMenu: PropTypes.func,
  onResetGetMenu: PropTypes.func,
  onSubmit: PropTypes.func,
  openDrawer: PropTypes.bool,
  uuidSelected: PropTypes.string,
};

export default MenuDrawer;
