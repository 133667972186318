import { forwardRef, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import camelCase from 'lodash/camelCase';

import { useTranslation, Trans } from 'react-i18next';
import { useCurrentSizePage } from '@open-react-hooks/core';

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  useGetOrganizationStoresBrands,
  useMenuCopyMutation,
  useMenuSyncMutation,
} from 'providers/menuMaker/Menus/hooks';
import useUserStore from 'hooks/useUserStore';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import WarningCircle from '@experimental-components/IconsComponents/WarningCircle';
import DialogMessage from 'components/DialogMessage';
import ModalConfirm from 'components/ModalConfirm';

import {
  stepsTypes,
  stepsLabels,
  copyModes,
  excludeStoreSelected,
  filterDataSelection,
  filterMenusWithSameSku,
} from 'utils/menus/menuCopy';

import StepperStart from './StepperStart';
import StepperStartText from './StepperStartText';
import StepperStartTitle from './StepperStartTitle';
import StepperStartInfo from './StepperStartInfo.tsx';
import StepperStartContentDialog from './StepperStartContentDialog';
import StepperEndContentDialog from './StepperEndContentDialog';
import ConfigCard from './ConfigCard';
import SelectDestinationElements from './SelectDestinationElements';
import SelectionSummary from './SelectionSummary';
import MenuCopyNotification from './MenuCopyNotification';
import WarningsList from './WarningsList';

import useStyles from './styles';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} direction="up" {...props} />;
});

function StepperDialog({ menuSelected, open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation(['menuMaker']);
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();

  const { logEvent } = useAmplitude();

  const maxStepsRef = useRef(3);
  const [pageHeight] = useCurrentSizePage();

  const [activeStep, setActiveStep] = useState(1);
  const [selectedConfigInitial, setSelectedConfigInitial] = useState(copyModes.COPY_MENU);
  const [step, setStep] = useState(stepsTypes.CONFIG);
  const [disabledNextButton, setDisabledNextButton] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [elementsSelected, setElementsSelected] = useState([]);
  const [selectionList, setSelectionList] = useState([]);
  const [storesAvailableToSync, setStoresAvailableToSync] = useState([]);
  const [storesWithoutDraft, setStoresWithoutDraft] = useState([]);
  const [storesToShow, setStoreToShow] = useState([]);

  const menuCopyMutation = useMenuCopyMutation();
  const menuSyncMutation = useMenuSyncMutation();
  const {
    organizationStoreBrands,
    organizationStoreBrandsState,
    resetGetOrganizationStoreBrands,
  } = useGetOrganizationStoresBrands({ includeMenus: true, includeBrands: true, enabledQuery: true });

  useEffect(() => {
    if (organizationStoreBrandsState.fetched) {
      const storesFiltered = excludeStoreSelected(storeSelected?.uuid, organizationStoreBrands);
      const storesWithSameSku = filterMenusWithSameSku(menuSelected?.sku, storesFiltered);

      setStoreToShow(storesFiltered);
      setStoresWithoutDraft(storesFiltered);
      setStoresAvailableToSync(storesWithSameSku);

      if (storesWithSameSku?.length) {
        setDisabledNextButton(true);
        setSelectedConfigInitial(null);
      } else {
        logEvent('@event.$menuMaker.viewCopyMenu', [`@@type.${camelCase(copyModes.COPY_MENU)}`, '@@status.start']);
      }
    }
  }, [organizationStoreBrandsState.fetched]);

  useEffect(() => {
    if (menuCopyMutation?.isSuccess && !menuCopyMutation?.isLoading) {
      const isFullCopySuccess = menuCopyMutation?.data?.data.every((item) => item.isSuccess);
      if (isFullCopySuccess) {
        logEvent('@event.$menuMaker.copyMenu', [`@@type.${camelCase(selectedConfigInitial)}`, '@@status.success'], {
          menuId: menuSelected?.uuid,
        });
      } else {
        logEvent('@event.$menuMaker.copyMenu', [`@@type.${camelCase(selectedConfigInitial)}`, '@@status.error'], {
          menuId: menuSelected?.uuid,
        });
      }
    }

    if (menuCopyMutation?.isError && !menuCopyMutation?.isLoading) {
      logEvent('@event.$menuMaker.copyMenu', [`@@type.${camelCase(selectedConfigInitial)}`, '@@status.error'], {
        menuId: menuSelected?.uuid,
        message: menuCopyMutation?.data?.errors?.map((error) => error?.message).join(', '),
      });
    }
  }, [menuCopyMutation?.isSuccess, menuCopyMutation?.isError]);

  useEffect(() => {
    if (menuSyncMutation?.isSuccess && !menuSyncMutation?.isLoading) {
      const isFullSyncSuccess = menuSyncMutation?.data?.data.every((item) => item.isSuccess);
      if (isFullSyncSuccess) {
        logEvent('@event.$menuMaker.copyMenu', [`@@type.${camelCase(selectedConfigInitial)}`, '@@status.success'], {
          menuId: menuSelected?.uuid,
        });
      } else {
        logEvent('@event.$menuMaker.copyMenu', [`@@type.${camelCase(selectedConfigInitial)}`, '@@status.error'], {
          menuId: menuSelected?.uuid,
        });
      }
    }

    if (menuSyncMutation?.isError && !menuSyncMutation?.isLoading) {
      logEvent('@event.$menuMaker.copyMenu', [`@@type.${camelCase(selectedConfigInitial)}`, '@@status.error'], {
        menuId: menuSelected?.uuid,
        message: menuSyncMutation?.data?.errors?.map((error) => error?.message).join(', '),
      });
    }
  }, [menuSyncMutation?.isSuccess, menuSyncMutation?.isError]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    switch (activeStep) {
      case 1:
        setStep(selectedConfigInitial === copyModes.SYNC_COPY ? stepsTypes.MENUS : stepsTypes.STORES);
        setDisabledNextButton(elementsSelected.length === 0);

        break;

      case 2: {
        const listSelected = filterDataSelection(elementsSelected, storesToShow, selectedConfigInitial);
        setSelectionList(listSelected);

        if (selectedConfigInitial === copyModes.SYNC_COPY) {
          setStep(stepsTypes.WARNING);
        } else {
          logEvent('@event.$menuMaker.viewCopyMenu', [`@@type.${camelCase(selectedConfigInitial)}`, '@@status.finish']);
          setStep(stepsTypes.RESUME);
        }

        break;
      }

      case 3: {
        logEvent('@event.$menuMaker.viewCopyMenu', [`@@type.${camelCase(selectedConfigInitial)}`, '@@status.finish']);
        setStep(stepsTypes.RESUME);
        break;
      }

      default:
        break;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    switch (activeStep) {
      case 2:
        setStep(stepsTypes.CONFIG);
        setDisabledNextButton(false);
        break;
      case 3:
        setStep(selectedConfigInitial === copyModes.SYNC_COPY ? stepsTypes.MENUS : stepsTypes.STORES);
        break;
      case 4:
        setStep(stepsTypes.WARNING);
        break;
      default:
        break;
    }
  };

  function handleSelectedConfigInitial(config) {
    if (selectedConfigInitial === config) {
      setSelectedConfigInitial(null);
      setDisabledNextButton(true);
      return false;
    }

    setElementsSelected([]);
    maxStepsRef.current = config === copyModes.SYNC_COPY ? 4 : 3;
    setStoreToShow(config === copyModes.SYNC_COPY ? storesAvailableToSync : storesWithoutDraft);
    setDisabledNextButton(false);
    logEvent('@event.$menuMaker.viewCopyMenu', [`@@type.${camelCase(config)}`, '@@status.start']);
    setSelectedConfigInitial(config);
  }

  const handleSelectElements = (values) => {
    setElementsSelected(values);
    setDisabledNextButton(values?.length === 0);
  };

  const handleSubmit = ({ destinationList = elementsSelected }) => {
    if (selectedConfigInitial === copyModes.SYNC_COPY) {
      menuSyncMutation.mutate({
        storeOriginUuid: storeSelected?.uuid,
        menuOriginUuid: menuSelected?.uuid,
        menusDestination: destinationList,
      });
    } else {
      menuCopyMutation.mutate({
        storeOriginUuid: storeSelected?.uuid,
        menuOriginUuid: menuSelected?.uuid,
        brandsDestination: destinationList,
      });
    }
  };

  const handleCloseStepper = () => {
    setOpenConfirmationModal(true);
  };

  const handleAcceptExitConfirmation = () => {
    resetGetOrganizationStoreBrands();
    onClose();
  };

  const handleCancelExitConfirmation = () => {
    setOpenConfirmationModal(false);
  };

  const handleCloseMessage = () => {
    resetGetOrganizationStoreBrands();
    menuCopyMutation.reset();
    menuSyncMutation.reset();
    onClose();
  };

  return (
    <>
      <Dialog fullScreen open={open} style={{ zIndex: 1400 }} TransitionComponent={Transition}>
        <Box display="grid" gridTemplateColumns="repeat(2,1fr)" height="100%">
          <StepperStartContentDialog loading onBack={handleBack} step={step}>
            <StepperStart activeStep={activeStep} steps={maxStepsRef.current}>
              {step === stepsTypes.WARNING && (
                <Box pb={3}>
                  <WarningCircle size={72} />
                </Box>
              )}

              <StepperStartTitle>{t(stepsLabels[step].title)}</StepperStartTitle>

              <StepperStartText>
                {selectedConfigInitial === copyModes.SYNC_COPY && stepsTypes.RESUME === step
                  ? t(stepsLabels[step].textSync)
                  : t(stepsLabels[step].text)}
              </StepperStartText>

              {step !== stepsTypes.WARNING && (
                <StepperStartInfo menu={menuSelected} storeSelected={storeSelected} t={t} />
              )}
            </StepperStart>
          </StepperStartContentDialog>

          <StepperEndContentDialog
            activeScroll={pageHeight <= 530}
            activeStep={activeStep}
            disabledNextButton={disabledNextButton || organizationStoreBrandsState.fetching}
            labelButtonNext={
              selectedConfigInitial === copyModes.SYNC_COPY && stepsTypes.RESUME === step
                ? t(stepsLabels[step].labelButtonNextSync)
                : t(stepsLabels[step].labelButtonNext)
            }
            onClose={handleCloseStepper}
            onNext={step === stepsTypes.RESUME ? handleSubmit : handleNext}
            steps={maxStepsRef.current}
          >
            {step === stepsTypes.CONFIG && (
              <Box className={classes.contentStepConfig}>
                {organizationStoreBrandsState.fetching && (
                  <>
                    <Skeleton height={151} variant="rect" width="100%" />
                    <Skeleton height={151} variant="rect" width="100%" />
                  </>
                )}

                {!organizationStoreBrandsState.fetching && organizationStoreBrandsState.fetched && (
                  <>
                    <ConfigCard
                      description={t('menuMaker:copyMenu.steps.config.cards.copy.description')}
                      onClick={() => handleSelectedConfigInitial(copyModes.COPY_MENU)}
                      selected={selectedConfigInitial === copyModes.COPY_MENU}
                      title={t('menuMaker:copyMenu.steps.config.cards.copy.title')}
                    />

                    {!!storesAvailableToSync.length && (
                      <ConfigCard
                        description={<Trans i18nKey="menuMaker:copyMenu.steps.config.cards.sync.description" />}
                        onClick={() => handleSelectedConfigInitial(copyModes.SYNC_COPY)}
                        selected={selectedConfigInitial === copyModes.SYNC_COPY}
                        title={t('menuMaker:copyMenu.steps.config.cards.sync.title')}
                      />
                    )}
                  </>
                )}
              </Box>
            )}

            {(step === stepsTypes.STORES || step === stepsTypes.MENUS) && (
              <Box className={classes.contentStepStores}>
                <SelectDestinationElements
                  configType={selectedConfigInitial}
                  elementsSelected={elementsSelected}
                  isLoading={organizationStoreBrandsState?.fetching}
                  onSelectElements={handleSelectElements}
                  stores={storesToShow}
                />
              </Box>
            )}

            {step === stepsTypes.WARNING && <WarningsList t={t} />}

            {step === stepsTypes.RESUME && (
              <SelectionSummary
                isSync={selectedConfigInitial === copyModes.SYNC_COPY}
                selectionList={selectionList}
                t={t}
              />
            )}
          </StepperEndContentDialog>
        </Box>
      </Dialog>

      <DialogMessage
        open={menuCopyMutation.isLoading || menuSyncMutation.isLoading}
        textDescription={t(
          `menuMaker:copyMenu.status.loading.${
            selectedConfigInitial === copyModes.SYNC_COPY ? 'descriptionSync' : 'description'
          }`,
        )}
        textTitle={t('menuMaker:copyMenu.status.loading.title')}
        type="loading"
      />

      <MenuCopyNotification
        isSync={selectedConfigInitial === copyModes.SYNC_COPY}
        menuMutation={selectedConfigInitial === copyModes.SYNC_COPY ? menuSyncMutation : menuCopyMutation}
        onClose={handleCloseMessage}
        onRetry={handleSubmit}
        open={selectedConfigInitial === copyModes.SYNC_COPY ? menuSyncMutation.isSuccess : menuCopyMutation.isSuccess}
        t={t}
      />

      <DialogMessage
        onClose={handleCloseMessage}
        open={menuCopyMutation.isError || menuSyncMutation.isError}
        textDescription={t(
          `menuMaker:copyMenu.status.error.${
            selectedConfigInitial === copyModes.SYNC_COPY ? 'generalDescriptionSync' : 'generalDescription'
          }`,
        )}
        textTitle={t(`menuMaker:copyMenu.status.error.generalTitle`)}
        type="error"
      />

      {openConfirmationModal && (
        <ModalConfirm
          className={classes.riseUp}
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          message={t('menuMaker:messages.exitNoSaveMessage')}
          onAccept={handleAcceptExitConfirmation}
          onCancel={handleCancelExitConfirmation}
          open={openConfirmationModal}
          title={t('menuMaker:messages.exitNoSaveTitle')}
        />
      )}
    </>
  );
}

StepperDialog.propTypes = {
  menuSelected: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StepperDialog;
