import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import { getCashRegisterSessionsDetailedAllRequest } from 'utils/api/baas/reports/cashRegister';

import { getReportsCashRegisterSessionDetailAll } from './actions';

export function* handleGetReportsCashRegisterSessionDetailedAllRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsCashRegisterSessionDetailAll.request());

    const { data } = yield call(baasRequest, getCashRegisterSessionsDetailedAllRequest, filters);
    const { contextDetail: section } = data;
    delete data.contextDetail;
    const session = data;
    const sectionSelected = filters.context;

    yield put(getReportsCashRegisterSessionDetailAll.success({ session, section, sectionSelected }));
  } catch (err) {
    yield put(getReportsCashRegisterSessionDetailAll.failure(err));
  }
}

export default function* reportsCashRegisterSessionDetailSaga() {
  yield takeLatest(
    getReportsCashRegisterSessionDetailAll.TRIGGER,
    handleGetReportsCashRegisterSessionDetailedAllRequest,
  );
}
