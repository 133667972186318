import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { validatePinRequest, generatePosPinRequest } from 'utils/api/baas/stores/posPin';

import { validatePin, generatePosPin } from './actions';

export function* handleValidatePinRequest(action) {
  const { storeUuid, email, posPin } = action.payload;

  try {
    yield put(validatePin.request());

    const { data } = yield call(baasRequest, validatePinRequest, {
      storeUuid,
      email,
      posPin,
    });

    yield put(validatePin.success(data));
  } catch (err) {
    yield put(validatePin.failure(err));
  } finally {
    yield put(validatePin.fulfill());
  }
}

export function* handleGeneratePosPinRequest(action) {
  const { storeUuid } = action.payload;

  try {
    yield put(generatePosPin.request());

    const { data } = yield call(baasRequest, generatePosPinRequest, {
      storeUuid,
    });

    yield put(generatePosPin.success(data?.posPin));
  } catch (err) {
    yield put(generatePosPin.failure(err));
  } finally {
    yield put(generatePosPin.fulfill());
  }
}

export default function* employeesProviderSaga() {
  yield takeLatest(validatePin.TRIGGER, handleValidatePinRequest);
  yield takeLatest(generatePosPin.TRIGGER, handleGeneratePosPinRequest);
}
