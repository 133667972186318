export default function changeIsDefaultServicesAreas(areas, areasValidate) {
  if (areas?.length) {
    return areas.map((area) => {
      if (area?.uuid === areasValidate?.uuid) {
        return areasValidate;
      }

      if (area?.isDefault && areasValidate?.isDefault) {
        area.isDefault = false;
        return area;
      }

      return area;
    });
  }

  return [];
}
