import { getGlobalInvoicesRequest, getCustomerInvoicesRequest } from 'utils/api/baas/invoicing/invoicesGenerated';
import isEqual from 'lodash/isEqual';

const GLOBAL = 'global';
const CUSTOMER = 'customer';

export const invoicingGeneratedCalls = {
  [GLOBAL]: getGlobalInvoicesRequest,
  [CUSTOMER]: getCustomerInvoicesRequest,
};

export const invoicingGeneratedPaginationCalls = {
  [GLOBAL]: getGlobalInvoicesRequest,
  [CUSTOMER]: getCustomerInvoicesRequest,
};

export const invoicingGeneratedDownloadCalls = {
  [GLOBAL]: getGlobalInvoicesRequest,
  [CUSTOMER]: getCustomerInvoicesRequest,
};

export function invoicingGeneratedRequest(type, ...args) {
  return invoicingGeneratedCalls[type](...args);
}

export function invoicingGeneratedPaginationRequest(type, ...args) {
  return invoicingGeneratedPaginationCalls[type](...args);
}

export function invoicingGenerateDownload(type, ...args) {
  return invoicingGeneratedDownloadCalls[type](...args);
}

export function isInvoicingFormValid(initialForm, watchValues) {
  let isValid = false;

  if (initialForm) {
    const initialFormTmp = { ...initialForm };

    Object.keys(initialFormTmp).forEach((field) => {
      if (!initialFormTmp[field]) {
        delete initialFormTmp[field];
      }
    });

    isValid = !isEqual(initialFormTmp, watchValues);
  }

  return isValid;
}

export function parseErrors(errors = []) {
  return errors.map((error) => {
    return {
      errorKey: error.code,
      message: error.message,
    };
  });
}
