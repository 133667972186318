import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';

import useStyles from './styles';

function CheckboxLabel({ value, defaultChecked, name, ...rest }) {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.checkboxPaper, { [classes.checkboxActive]: defaultChecked })} variant="outlined">
      <FormControlLabel
        classes={{ root: classes.checkboxLabel }}
        control={<Checkbox defaultChecked={defaultChecked} icon={<div />} name={name} />}
        value={value}
        {...rest}
      />
    </Paper>
  );
}

CheckboxLabel.propTypes = {
  defaultChecked: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default CheckboxLabel;
