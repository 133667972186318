import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getBrandsReceiptCustomizationRequest,
  getBrandReceiptCustomizationRequest,
  saveBrandReceiptCustomizationRequest,
} from 'utils/api/baas/restaurant/receiptCustomizations';

import {
  getBrandsReceiptCustomization,
  getBrandReceiptCustomization,
  updateBrandReceiptCustomization,
} from './actions';

export function* handleGetBrandsReceiptCustomizationRequest(action) {
  const storeUuid = action.payload;

  try {
    yield put(getBrandsReceiptCustomization.request());

    const { data: brands } = yield call(baasRequest, getBrandsReceiptCustomizationRequest, storeUuid);

    yield put(getBrandsReceiptCustomization.success({ brands }));
  } catch (err) {
    yield put(getBrandsReceiptCustomization.failure(err));
  } finally {
    yield put(getBrandsReceiptCustomization.fulfill());
  }
}

export function* handleGetBrandReceiptCustomizationRequest(action) {
  const { storeUuid, brandUuid } = action.payload;

  try {
    yield put(getBrandReceiptCustomization.request());

    const { data } = yield call(baasRequest, getBrandReceiptCustomizationRequest, { storeUuid, brandUuid });

    yield put(getBrandReceiptCustomization.success(data));
  } catch (err) {
    yield put(getBrandReceiptCustomization.failure(err));
  } finally {
    yield put(getBrandReceiptCustomization.fulfill());
  }
}

export function* handleSaveBrandReceiptCustomizationRequest(action) {
  const { storeUuid, brandUuid, footerText, headerText, printerLogo, sections } = action.payload;

  try {
    yield put(updateBrandReceiptCustomization.request());

    const { data } = yield call(baasRequest, saveBrandReceiptCustomizationRequest, {
      storeUuid,
      brandUuid,
      footerText,
      headerText,
      printerLogo,
      sections,
    });

    yield put(updateBrandReceiptCustomization.success(data));
  } catch (err) {
    yield put(updateBrandReceiptCustomization.failure(err));
  } finally {
    yield put(updateBrandReceiptCustomization.fulfill());
  }
}

export default function* restaurantReceiptCustomizationProviderSaga() {
  yield takeLatest(getBrandsReceiptCustomization.TRIGGER, handleGetBrandsReceiptCustomizationRequest);
  yield takeLatest(getBrandReceiptCustomization.TRIGGER, handleGetBrandReceiptCustomizationRequest);
  yield takeLatest(updateBrandReceiptCustomization.TRIGGER, handleSaveBrandReceiptCustomizationRequest);
}
