// @ts-nocheck
import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import IconNotImage from '@experimental-components/IconsComponents/NotImage';
import TextField from '@design-system/TextField';
import ButtonOutlined from '@design-system/ButtonOutlined';

import ImageDropzone from 'components/ImageDropzone';
import PriceFormatField from 'components/PriceFormatField';
import MultiSelect from 'components/MultiSelect';
import PageError from 'components/PageError';
import ModalConfirm from 'components/ModalConfirm';

import { getInitialOptionsAutocomplete, getBaseItemsOverride } from 'utils/products';
import { maxValueAllowed } from 'utils/currency';

import { handleFormatValidateItems, validateDuplicateSkuItems } from 'utils/menuMaker';

import ProductOverrideSkeleton from './ProductOverrideSkeleton';
import useStyles from './styles';

interface Props {
  t: (value: string) => string;
  productState: {
    data: {
      uuid: string;
      name: string;
      price: string;
      description: string;
      image: string;
      override: { price: string; modifiersOverride: boolean; image: string };
      overrideModifiers: string[];
      overrideImageCount: number;
    };
    fetching: boolean;
    error: boolean;
  };
  modifierGroupsState: {
    list: { uuid: string; name: string; description: string }[];
    fetched: boolean;
    fetching: boolean;
    error: boolean;
  };
  onLoadProduct: () => void;
  restoreProductState: {
    fetching: boolean;
    fetched: boolean;
    error: boolean;
  };
  restoreProduct: () => void;
}

function ProductOverrideForm({
  t,
  productState,
  modifierGroupsState,
  onLoadProduct,
  restoreProduct,
  restoreProductState,
  isSoldByWeight = false,
}: Props) {
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const classes = useStyles();
  const { control, errors } = useFormContext();

  useEffect(() => {
    if (restoreProductState.fetched || restoreProductState.error) setOpenRestoreModal(false);
  }, [restoreProductState.fetched, restoreProductState.error]);

  const handleConfirmRestoreProduct = () => {
    setOpenRestoreModal(true);
  };

  const handleCloseRestoreModal = () => {
    setOpenRestoreModal(false);
  };

  const handleRestoreProduct = () => {
    restoreProduct();
  };

  const renderTitle = () => (
    <Typography className={classes.title}>{t('menuMaker:sections.editProductOverride')}</Typography>
  );

  if (modifierGroupsState.fetching || productState.fetching) {
    return (
      <ProductOverrideSkeleton>
        <ProductOverrideSkeleton.Title>{renderTitle()}</ProductOverrideSkeleton.Title>
      </ProductOverrideSkeleton>
    );
  }

  if (productState.error || modifierGroupsState.error) {
    return (
      <PageError
        id="productOverrideError"
        labelAction={t('common:buttons.retry')}
        message={t('common:errors.loaded.errorConnection')}
        onAction={onLoadProduct}
        title={t('common:errors.sorry')}
      />
    );
  }

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item>{renderTitle()}</Grid>

        <Grid item>
          <Typography className={clsx(classes.grayBox, classes.bold)}>
            <Trans i18nKey="menuMaker:productForm.productOverrideInfo" />
          </Typography>
        </Grid>

        <Grid item>
          <Box className={classes.wrapperInfo}>
            {productState?.data?.image ? (
              <img alt="Product" className={classes.image} height={80} src={productState?.data?.image} width={80} />
            ) : (
              <IconNotImage size={80} />
            )}

            <Box>
              <Typography className={classes.subtitle}>{productState?.data?.name}</Typography>

              <Typography className={classes.productInfo}>{productState?.data?.description}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Box mb={0.5}>
            <Typography>Precio</Typography>
          </Box>

          <Typography color="textSecondary">{`$${productState?.data?.price}`}</Typography>
        </Grid>

        <Grid item>
          <Controller
            control={control}
            name="priceOverrideField"
            render={({ onChange, value, name }) => (
              <PriceFormatField
                autoComplete="off"
                customInput={TextField}
                data-testid={`priceOverrideField_${productState?.data?.uuid}`}
                fullWidth
                isAllowed={maxValueAllowed(9999999.99)}
                label={t('menuMaker:contentResume.labels.priceInMenu')}
                name={name}
                onChange={onChange}
                placeholder="$0.00"
                useNegative={false}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item>
          <Box display="grid" gridGap={35} gridTemplateColumns="1.5fr 1fr">
            <Box display="flex" flexDirection="column" gridRowGap={16}>
              <Typography className={classes.subtitle}>{t('menuMaker:productForm.image.override.title')}</Typography>

              <Typography color="textSecondary" variant="body2">
                <Trans i18nKey="menuMaker:productForm.image.override.description" />
              </Typography>
            </Box>

            <Controller
              control={control}
              name="imageOverrideField"
              render={({ onChange, value, name }) => (
                <ImageDropzone
                  initialImageUrl={value}
                  isEdition
                  loadDisabled={productState?.data?.overrideImageCount === 6 && !productState?.data?.override?.image}
                  name={name}
                  onImageChange={onChange}
                  value={value}
                />
              )}
            />
          </Box>
        </Grid>

        <Box my={2} px={2}>
          <Divider />
        </Box>

        {isSoldByWeight === false && (
          <Grid item>
            <Typography className={classes.subtitle}>
              {t('menuMaker:productForm.modifierGroupsPicker.override.title')}
            </Typography>
            <Box mb={2}>
              <Typography color="textSecondary" variant="body2">
                {t('menuMaker:productForm.modifierGroupsPicker.override.description')}
              </Typography>
            </Box>
            <Controller
              control={control}
              name="modifierGroupsField"
              render={({ onChange, value, name }) => (
                <MultiSelect
                  baseItemsOverride={getBaseItemsOverride(productState?.data?.modifiers)}
                  getOptionDescription={(option) => option.description}
                  getOptionLabel={(option) => option.name}
                  getOptionUniqueKey={(option) => option.uuid}
                  hasItemOverride={value?.some((item) => item?.hasOverride)}
                  id="product-autocomplete-modifiers"
                  inputPlaceholderText={t('menuMaker:productForm.modifierGroupsPicker.inputPlaceholder')}
                  messageItemsOverride={t('menuMaker:productForm.modifierGroupsPicker.override.tooltip')}
                  name={name}
                  noOptionsText={t('menuMaker:productForm.modifierGroupsPicker.noModifiers')}
                  onChange={(selectedOptions) => onChange(handleFormatValidateItems(selectedOptions))}
                  options={getInitialOptionsAutocomplete(modifierGroupsState.list, value)}
                  renderOption={(option, props) => {
                    return (
                      <li {...props} data-testid={`option-modifier-${option.uuid}`}>
                        <Box>{option.name}</Box>
                        {option?.description && (
                          <Box className={classes.descriptionModifiers}>{option?.description}</Box>
                        )}
                      </li>
                    );
                  }}
                  selectedOptions={value || []}
                  useItemsOverride
                  value={value}
                />
              )}
              rules={{
                validate: (items) => {
                  const itemsDuplicateSku = validateDuplicateSkuItems(items);

                  return (
                    itemsDuplicateSku?.length === 0 || t('menuMaker:skuLabels.product.errorDuplicateModifierGroupsSku')
                  );
                },
              }}
            />
            {Boolean(errors?.modifierGroupsField) && (
              <Box className={classes.errorDuplicateSku}>{errors?.modifierGroupsField?.message}</Box>
            )}
          </Grid>
        )}

        <Grid item>
          <Box mt={3}>
            <ButtonOutlined
              disabled={!productState?.data?.override?.price && !productState?.data?.override?.modifiersOverride}
              fullWidth
              onClick={handleConfirmRestoreProduct}
            >
              {t('menuMaker:productForm.resetToOriginalProduct')}
            </ButtonOutlined>
          </Box>
        </Grid>
      </Grid>

      {openRestoreModal && (
        <ModalConfirm
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          loading={restoreProductState.fetching}
          message={t('menuMaker:productForm.modalRestore.message')}
          onAccept={handleRestoreProduct}
          onCancel={handleCloseRestoreModal}
          open={openRestoreModal}
          title={t('menuMaker:productForm.modalRestore.title')}
        />
      )}
    </>
  );
}

export default ProductOverrideForm;
