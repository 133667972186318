import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    marginTop: ({ isModeDraft, fullDrawer }) =>
      fullDrawer ? 'auto' : theme.typography.pxToRem(isModeDraft ? 193 : 137),
    width: theme.typography.pxToRem(496.8),
    [theme.breakpoints.down('xs')]: {
      marginTop: ({ isModeDraft, fullDrawer }) =>
        fullDrawer ? 'auto' : theme.typography.pxToRem(isModeDraft ? 168 : 112),
      width: '100%',
    },
  },
  drawerContainer: ({ isActive, isModeDraft, fullDrawer }) => ({
    display: `${isActive ? 'flex' : 'none'}`,
    height: fullDrawer ? 'auto' : `calc(100vh - ${theme.typography.pxToRem(isModeDraft ? 179.2 : 123.2)})`,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: fullDrawer ? 'auto' : `calc(100vh - ${theme.typography.pxToRem(isModeDraft ? 168 : 112)})`,
    },
  }),
  drawerHeader: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    background: theme.palette.background.default,
    position: 'sticky',
    top: '0',
    zIndex: '1350',
  },
  drawerContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    paddingInline: theme.spacing(3),
  },
}));

export default useStyles;
