import { Route, useHistory } from 'react-router';

import Layout from 'Layout';

import { settingsPathRoot } from 'utils/settings/paths';

import { useState } from 'react';
import { cleanObject } from 'utils/objects';

import ProductTypesList from './components/ProductTypesList';
import CreateEditTypeOfProduct from './components/CreateEditTypeOfProduct';
import useTypesOfProducts from './hooks/useProductTypes';
import { ProductTypeFormProps } from './types';

export function SettingsTypeOfProducts() {
  const history = useHistory();
  const [isSearching, setIsSearching] = useState(false);
  const [typeOfProductSelected, setTypeOfProductSelected] = useState<ProductTypeFormProps>({ name: '', uuid: '' });

  const {
    typesOfProducts,
    typesOfProductsState,
    setSearch,
    createTypeOfProduct,
    createState,
    deleteTypeOfProduct,
    deleteState,
    updateTypeOfProduct,
    updateState,
  } = useTypesOfProducts({ enabled: true });

  function handleCreate(values: ProductTypeFormProps) {
    const newValues = cleanObject(values);
    createTypeOfProduct(newValues, history, '/settings/product-type');
  }

  function handleGoSettings() {
    history.push({
      pathname: settingsPathRoot,
    });
  }
  function handleGoHome() {
    setTypeOfProductSelected({ name: '', uuid: '' });
    history.push({
      pathname: '/settings/product-type',
    });
  }

  function handleGoCreate() {
    setSearch('');
    history.push({
      pathname: '/settings/product-type/create',
    });
  }

  function handleChangeSearch(event: string) {
    if (event.length > 0) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    if (event.length >= 3 || event.length === 0) {
      setSearch(event);
    }
  }

  function handleGoUpdate(productTypeUuid: string) {
    setSearch('');
    const selectedType = typesOfProducts.find((type) => type.uuid === productTypeUuid);
    if (selectedType) {
      setTypeOfProductSelected(selectedType);
      history.push({
        pathname: `/settings/product-type/edit/${productTypeUuid}`,
      });
    }
  }

  function handleUpdate(values: unknown) {
    const newValues = cleanObject(values);
    updateTypeOfProduct(newValues, history, '/settings/product-type');
  }

  function handleDelete(values: { uuid: string }) {
    deleteTypeOfProduct(values.uuid);
  }

  return (
    <Layout>
      <Route exact path="/settings/product-type">
        <ProductTypesList
          isDeleted={deleteState.isSuccess}
          isDeleting={deleteState.isFetching}
          isFetching={typesOfProductsState.isFetching}
          isSearching={isSearching}
          onDelete={handleDelete}
          onGoBack={handleGoSettings}
          onGoCreate={handleGoCreate}
          onGoUpdate={handleGoUpdate}
          onSearch={handleChangeSearch}
          productTypes={typesOfProducts}
        />
      </Route>
      <Route exact path="/settings/product-type/create">
        <CreateEditTypeOfProduct isLoading={createState.isFetching} onGoBack={handleGoHome} onSubmit={handleCreate} />
      </Route>
      <Route exact path="/settings/product-type/edit/:inventorySupplierUuidParam?">
        <CreateEditTypeOfProduct
          isLoading={updateState.isFetching}
          onGoBack={handleGoHome}
          onSubmit={handleUpdate}
          productTypeDetail={typeOfProductSelected}
        />
      </Route>
    </Layout>
  );
}

export default SettingsTypeOfProducts;
