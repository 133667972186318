import Box, { BoxProps } from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ExportToExcelButton from 'components/ExportToExcelButton';

import ModalBase from './Modal/ModalBase';
import ModalLoader from './Modal/ModalLoader';

import useStyles from './styles';

interface Props extends BoxProps {
  isLoadingByModal: boolean;
  isLoading: boolean;
  children?: React.ReactNode;
  nameFile: string;
  name: string;
  showDownloadButton: boolean;
  mutateAsync: () => Promise<Blob>;
  messageLoader?: string;
  alignButton?: string;
  disabled?: boolean;
  useDivider?: boolean;
}

const ExportFileBar = ({
  children,
  isLoadingByModal,
  isLoading,
  showDownloadButton,
  nameFile,
  name,
  mutateAsync,
  messageLoader,
  alignButton = 'center',
  disabled = false,
  useDivider = false,
  ...others
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root} {...others}>
        {children}

        <Box alignItems={alignButton} className={classes.buttonSection}>
          <ExportToExcelButton
            disabled={disabled || !showDownloadButton}
            fileName={nameFile}
            isLoading={isLoading}
            isVisible
            mutateAsync={mutateAsync}
            name={name}
          />
        </Box>
      </Box>

      {useDivider && <Divider className={classes.divider} />}
      <ModalBase open={isLoading && isLoadingByModal}>
        <ModalLoader message={messageLoader} />
      </ModalBase>
    </>
  );
};

export default ExportFileBar;
