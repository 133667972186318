/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import history from 'utils/history';

import { withRequiredLicense } from 'providers/root/AuthProvider';
import { withMenus } from 'providers/menuMaker/Menus';
import { withMenuDetails } from 'providers/menuMaker/MenuDetails';
import useBrands from 'providers/root/BrandsProvider/useBrands';
import { useGetCategoryCatalog } from 'providers/menuMaker/Categories/hooks';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';
import { useGetValidateCopyMeny } from 'providers/menuMaker/Menus/hooks';

import Layout, { ContentLayout } from 'Layout';

import MenuMakerTabsNavigation from 'components/MenuMakerTabsNavigation';
import StepperDialog from 'components/StepperDialog';
import MenuDrawer from 'components/MenuDrawer';
import DialogMessage from 'components/DialogMessage';
import DetailsErrosOnBeforeCopyMenu from 'components/DetailsErrosOnBeforeCopyMenu';

import useNotification from '@design-system/Notification/useNotification';

import useUserStore from 'hooks/useUserStore';

import { drawerActions, drawerActionList, drawerEntityList, getQueryParamsMenuMaker } from 'utils/drawerMenuMaker';
import { statusType } from 'utils/products';
import { getErrorCode, errorsCodeTypes } from 'utils/parrotErrorCode';

import MenusContent from './MenusContent';
import useStyles from './styles';

export function MenuMaker({
  loadGetMenus,
  menus,
  menusError,
  menusFetching,
  menusPagination,
  paginateMenu,
  paginationMenuState,
  resetGetMenus,
  deleteMenu,
  deleteMenuError,
  deleteMenuFetched,
  deleteMenuFetching,
  resetDeleteMenu,
  duplicateMenu,
  duplicateMenuError,
  duplicateMenuFetched,
  resetDuplicateMenu,
  createMenuError,
  createMenuFetched,
  createMenuFetching,
  createMenuObject,
  createNewMenu,
  resetCreateMenu,
  editMenu,
  editMenuObject,
  editMenuFetched,
  editMenuFetching,
  editMenuError,
  resetEditMenu,
  loadMenu,
  menu,
  menuFetching,
  resetGetMenu,
  t,
}) {
  const classes = useStyles();
  const {
    storeId,
    storeState: { selected: storeSelected },
  } = useUserStore();
  const { brandSelected, brandsState } = useBrands();
  const location = useLocation();
  const { closeAllSnackbar, setNotification } = useNotification();
  const { loadCategoryCatalog, categories, categoriesState, resetCategories } = useGetCategoryCatalog(storeId);
  const { logEvent } = useAmplitude();

  const { action, entity, uuid } = getQueryParamsMenuMaker(location);
  const isEdition = action === drawerActions.EDIT;

  const [menuNameSearched, setMenuNameSearched] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCopyMenu, setOpenCopyMenu] = useState(false);
  const [selectedCopyMenu, setSelectedCopyMenu] = useState(null);
  const [selectedMenuUuid, setSelectedMenuUuid] = useState(null);
  const [openDialogFull, setOpenDialogFull] = useState(false);
  const [typeMessage, setTypeMessage] = useState(null);
  const [titleFullMessage, setTitleFullMessage] = useState(null);
  const [descriptionFullMessage, setDescriptionFullMessage] = useState(null);

  const { validateCopyMenuState, dataError, getValidateCopyMenu, resetValidateCopyMenu } = useGetValidateCopyMeny(
    selectedCopyMenu,
  );

  useEffect(() => {
    logEvent('@event.$menuMaker.viewMenuMaker', '@@section.menu');
  }, []);

  useEffect(() => {
    if (brandSelected?.uuid) {
      loadGetMenus({ brand: brandSelected?.uuid, name: menuNameSearched, version: 2 });
    }
  }, [brandSelected]);

  useEffect(() => {
    const isValid = drawerActionList.includes(action) && drawerEntityList.includes(entity);

    setOpenDrawer(Boolean(isEdition ? isValid && uuid : isValid));
  }, [action, entity, uuid, isEdition]);

  useEffect(() => {
    if (createMenuFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.menu', '@@type.new', '@@item.menu'], {
        itemId: createMenuObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadGetMenus({ brand: brandSelected?.uuid, name: menuNameSearched, version: 2 });
      setNotification(t('menuMaker:createMenu.createNewMenuSuccess', { menuName: createMenuObject?.name }), {
        variant: 'success',
      });
      history.replace({ search: '' });
      resetCreateMenu();
    }

    if (createMenuError && !getErrorCode(createMenuError, errorsCodeTypes.MENU_NAME_EXISTING)) {
      setNotification(t('menuMaker:createMenu.createNewMenuError'), { variant: 'error' });
      resetCreateMenu();
    }

    if (getErrorCode(createMenuError, errorsCodeTypes.MENU_NAME_EXISTING)) {
      setNotification(t('menuMaker:createMenu.creatMenuErrorDuplicateName'), { variant: 'warning' });
      resetCreateMenu();
    }
  }, [createMenuFetched, createMenuError]);

  useEffect(() => {
    if (editMenuFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.menu', '@@type.edit', '@@item.menu'], {
        itemId: editMenuObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadGetMenus({ brand: brandSelected?.uuid, name: menuNameSearched, version: 2 });
      setNotification(t('menuMaker:messages.editMenuSuccess', { menuName: editMenuObject?.name }), {
        variant: 'success',
      });
      history.replace({ search: '' });
      resetEditMenu();
    }

    if (editMenuError && !getErrorCode(editMenuError, errorsCodeTypes.MENU_NAME_EXISTING)) {
      setNotification(t('menuMaker:messages.editError'), { variant: 'error' });
      resetEditMenu();
    }

    if (getErrorCode(editMenuError, errorsCodeTypes.MENU_NAME_EXISTING)) {
      setNotification(t('menuMaker:createMenu.creatMenuErrorDuplicateName'), { variant: 'warning' });
      resetEditMenu();
    }
  }, [editMenuFetched, editMenuError]);

  useEffect(() => {
    if (duplicateMenuFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.menu', '@@type.duplicate', '@@item.menu'], {
        itemId: selectedMenuUuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadGetMenus({ brand: brandSelected?.uuid, name: menuNameSearched, version: 2 });
      setNotification(t('menuMaker:messages.duplicateSuccess'), { variant: 'success' });
      setSelectedMenuUuid(null);
      resetDuplicateMenu();
    }
    if (duplicateMenuError) {
      setNotification(t('menuMaker:messages.duplicateError'), { variant: 'error' });
      resetDuplicateMenu();
    }
  }, [duplicateMenuFetched, duplicateMenuError, setNotification, t]);

  useEffect(() => {
    if (deleteMenuFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.menu', '@@type.delete', '@@item.menu'], {
        itemId: selectedMenuUuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      setNotification(t('menuMaker:messages.deleteSuccess'), { variant: 'success' });
      setSelectedMenuUuid(null);
      resetDeleteMenu();
    }
    if (deleteMenuError) {
      setNotification(t('menuMaker:messages.deleteError'), { variant: 'error' });
      resetDeleteMenu();
    }
  }, [deleteMenuFetched, deleteMenuError]);

  useEffect(() => {
    if (selectedCopyMenu) {
      getValidateCopyMenu();
    }
  }, [selectedCopyMenu]);

  useEffect(() => {
    if (validateCopyMenuState?.fetching) {
      setTypeMessage('loading');
      setTitleFullMessage(t('menuMaker:validateCopyMenu.loading.title'));
      setDescriptionFullMessage(t(`menuMaker:validateCopyMenu.loading.message`));
    }

    if (!validateCopyMenuState?.fetching && !validateCopyMenuState?.error && validateCopyMenuState?.fetched) {
      setOpenDialogFull(false);
      setOpenCopyMenu(true);
      resetValidateCopyMenu();
    }

    if (!validateCopyMenuState?.fetching && validateCopyMenuState?.error) {
      setTypeMessage('error');
      setTitleFullMessage(t(`menuMaker:validateCopyMenu.error.title`));
      setDescriptionFullMessage(t(`menuMaker:validateCopyMenu.error.message`));
    }
  }, [validateCopyMenuState?.fetching, validateCopyMenuState?.fetched, validateCopyMenuState?.error]);

  useEffect(() => {
    return function cleanUp() {
      resetGetMenus();
      resetCategories();
      resetDeleteMenu();
      resetDuplicateMenu();
      closeAllSnackbar();
    };
  }, []);

  function handleLoadMenus() {
    loadGetMenus({ brand: brandSelected?.uuid, name: menuNameSearched, version: 2 });
  }

  const handlePaginate = () => {
    paginateMenu({
      brand: brandSelected?.uuid,
      name: menuNameSearched,
      page: menusPagination?.page + 1,
      version: 2,
    });
  };

  const handleSearchByTextMenus = () => (value) => {
    const { filterByText } = value;

    if (filterByText?.length >= 3) {
      logEvent('@event.$menuMaker.menuMakerFilters', '@@section.menu', { search: filterByText });
      setMenuNameSearched(filterByText);
      loadGetMenus({ brand: brandSelected?.uuid, name: filterByText, version: 2 });
    }

    if (!filterByText) {
      setMenuNameSearched(null);
      loadGetMenus({ brand: brandSelected?.uuid, name: null, version: 2 });
    }
  };

  const handleLoadCategories = () => {
    loadCategoryCatalog();
  };

  const handleLoadMenu = () => {
    loadMenu({ menuUuid: uuid, storeUuid: storeId });
  };

  const handleSubmitMenu = (data) => {
    if (isEdition) {
      editMenu({ menuUuid: menu?.uuid, ...menu, ...data });
    } else {
      createNewMenu({ ...data, availability: statusType.STATUS_AVAILABLE, sortingPosition: 0, store: storeId });
    }
  };

  function handleCloseCopyMenu() {
    setOpenCopyMenu(false);
    setSelectedCopyMenu(null);
  }

  function handleGetCopyMenuConfig(menuSelected) {
    setSelectedCopyMenu(menuSelected);
    setOpenDialogFull(true);
  }

  function handleCloseErrorCopy() {
    setSelectedCopyMenu(null);
    setOpenDialogFull(false);
    setTypeMessage(null);
    setTitleFullMessage(null);
    setDescriptionFullMessage(null);
    resetValidateCopyMenu();
  }

  function handleDuplicateMenu(menuUuid) {
    setSelectedMenuUuid(menuUuid);
    duplicateMenu({ menuUuid, storeUuid: storeId });
  }

  function handleDeleteMenu(menuUuid) {
    setSelectedMenuUuid(menuUuid);
    deleteMenu(menuUuid);
  }

  return (
    <Layout>
      <ContentLayout>
        <ContentLayout.Header className={classes.headerLayout} sticky>
          <MenuMakerTabsNavigation />
        </ContentLayout.Header>

        <ContentLayout.Container pb="0" pl="0" pr="0">
          <MenusContent
            deleteMenuState={{ fetching: deleteMenuFetching, fetched: deleteMenuFetched, error: deleteMenuError }}
            isSearchFilter={menuNameSearched?.length > 0}
            loading={menusFetching || brandsState.isLoading}
            menus={menus}
            menusError={menusError}
            menusPagination={menusPagination}
            onCopyMenu={handleGetCopyMenuConfig}
            onDeleteMenu={handleDeleteMenu}
            onDuplicate={handleDuplicateMenu}
            onPaginate={handlePaginate}
            onReloadMenus={handleLoadMenus}
            onSearchMenusByText={handleSearchByTextMenus()}
            openDrawer={openDrawer}
            paginationState={paginationMenuState}
          />

          <MenuDrawer
            actionState={
              isEdition
                ? { fetched: editMenuFetched, fetching: editMenuFetching }
                : { fetched: createMenuFetched, fetching: createMenuFetching }
            }
            categoriesState={{ list: categories, fetching: categoriesState.fetching, fetched: categoriesState.fetched }}
            isEdition={isEdition}
            menuState={{ data: menu, fetching: menuFetching }}
            onLoadCategories={handleLoadCategories}
            onLoadMenu={handleLoadMenu}
            onResetCategories={resetCategories}
            onResetGetMenu={resetGetMenu}
            onSubmit={handleSubmitMenu}
            openDrawer={openDrawer}
            uuidSelected={uuid}
          />

          {openCopyMenu && (
            <StepperDialog menuSelected={selectedCopyMenu} onClose={handleCloseCopyMenu} open={openCopyMenu} />
          )}

          {openDialogFull && (
            <DialogMessage
              onClose={handleCloseErrorCopy}
              open={openDialogFull}
              textDescription={descriptionFullMessage}
              textTitle={titleFullMessage}
              type={typeMessage}
            >
              {validateCopyMenuState.error && <DetailsErrosOnBeforeCopyMenu data={dataError} />}
            </DialogMessage>
          )}
        </ContentLayout.Container>
      </ContentLayout>
    </Layout>
  );
}

MenuMaker.propTypes = {
  loadGetMenus: PropTypes.func,
  menus: PropTypes.array,
  menusError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  menusFetching: PropTypes.bool,
  menusPagination: PropTypes.object,
  paginateMenu: PropTypes.func,
  paginationMenuState: PropTypes.object,
  resetGetMenus: PropTypes.func,
  deleteMenu: PropTypes.func,
  deleteMenuError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  deleteMenuFetched: PropTypes.bool,
  deleteMenuFetching: PropTypes.bool,
  resetDeleteMenu: PropTypes.func,
  duplicateMenu: PropTypes.func,
  duplicateMenuError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  duplicateMenuFetched: PropTypes.bool,
  resetDuplicateMenu: PropTypes.func,
  createMenuError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  createMenuFetched: PropTypes.bool,
  createMenuFetching: PropTypes.bool,
  createMenuObject: PropTypes.object,
  createNewMenu: PropTypes.func,
  resetCreateMenu: PropTypes.func,
  editMenu: PropTypes.func,
  editMenuObject: PropTypes.object,
  editMenuFetched: PropTypes.bool,
  editMenuFetching: PropTypes.bool,
  editMenuError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetEditMenu: PropTypes.func,
  loadMenu: PropTypes.func,
  menu: PropTypes.object,
  menuFetching: PropTypes.bool,
  resetGetMenu: PropTypes.func,
  t: PropTypes.func,
};

export default compose(
  memo,
  withMenus,
  withMenuDetails,
  withRequiredLicense(),
  withTranslation('menuMaker'),
)(MenuMaker);
