import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

const CalendarIllustration = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => {
  const { size = 118 } = props;

  return (
    <svg ref={ref} fill="none" height={size} viewBox="0 0 118 103" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_100_19709)">
        <path
          d="M77.7533 99.2115L77.7075 98.616C94.5403 97.2878 97.503 76.3437 97.5358 76.1343L98.1323 76.2128C98.1061 76.4287 95.0777 97.844 77.7599 99.2115H77.7533Z"
          fill="black"
        />
        <path
          d="M76.4162 102.941L76.4817 102.346C76.6784 102.365 96.2314 104.283 99.594 83.731L100.184 83.8291C96.7296 104.943 76.6194 102.961 76.4162 102.941Z"
          fill="black"
        />
        <path
          d="M5.99111 20.7743C5.93867 20.4602 4.68669 12.9947 9.04565 7.89765C11.6807 4.80935 15.8037 3.29137 21.2901 3.37643V3.97185C15.9807 3.89333 12.0215 5.33933 9.50449 8.28369C5.32907 13.1713 6.56793 20.6042 6.58104 20.6761L5.99111 20.7808V20.7743Z"
          fill="black"
        />
        <path
          d="M2.97589 15.592C2.96278 15.4546 1.83535 1.81896 18.4912 0L18.5567 0.595414C2.49739 2.34894 3.51994 14.9966 3.57238 15.5331L2.97589 15.592Z"
          fill="black"
        />
        <path
          d="M5.55194 45.2972C5.55194 45.2972 4.98167 42.9614 2.53672 43.3147C0.0917703 43.668 0.203202 46.0628 0.465395 46.8087C0.727589 47.5546 2.52361 49.1642 3.57238 48.8566C4.62116 48.5491 6.12877 46.5404 5.5585 45.2972H5.55194Z"
          stroke="black"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        <path
          d="M18.5567 83.3773C18.5567 83.3773 15.6529 89.1744 17.757 92.5703C17.757 92.5703 13.9552 88.3762 8.84901 91.0719C8.84901 91.0719 12.251 86.976 9.74703 82.5791C9.74703 82.5791 13.4505 85.3795 18.5567 83.3773Z"
          fill="#F8B8B7"
        />
        <path
          d="M101.121 50.0015C101.121 50.0015 103.239 53.7114 106.621 53.4889C106.621 53.4889 102.878 54.6143 103.055 58.5859C103.055 58.5859 101.167 55.1574 97.8177 55.2424C97.8177 55.2424 100.866 53.7637 101.128 50.0015H101.121Z"
          fill="#F8B8B7"
        />
        <path
          d="M9.60938 70.7234C10.2649 71.7833 11.3399 72.5489 12.5525 72.8368L13.1293 72.9742L15.5874 73.55L77.6485 88.1016C78.8611 88.3829 80.1655 88.1736 81.2274 87.5193C82.2827 86.865 83.0562 85.7919 83.3446 84.5815L92.2985 46.5339L17.9799 29.1099L9.026 67.1574C8.74415 68.3679 8.9539 69.67 9.60938 70.7299V70.7234Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path d="M18.2325 26.87L17.5909 29.5962L93.3092 47.3516L93.9508 44.6254L18.2325 26.87Z" fill="black" />
        <path
          d="M29.0969 15.6054L26.5929 15.0165L26.003 14.8791C24.7641 14.5913 23.4401 14.7941 22.3651 15.4484C21.2901 16.1027 20.5101 17.1692 20.2216 18.3797L18.9631 23.7253L94.6779 41.483L95.9365 36.1374C96.2183 34.9269 95.9954 33.6249 95.3269 32.5649C94.6517 31.5049 93.5571 30.7263 92.3247 30.4384L75.8262 26.5715L33.9671 16.757L29.0903 15.612L29.0969 15.6054Z"
          fill="black"
        />
        <path d="M18.9696 23.7294L18.2291 26.876L93.9474 44.6314L94.6879 41.4848L18.9696 23.7294Z" fill="white" />
        <path
          d="M34.6816 22.9923L38.9816 23.9999L40.3974 17.9803L41.6428 12.6805L37.3429 11.6729L36.0974 16.9727L34.6816 22.9923Z"
          fill="#B0B0B0"
        />
        <path
          d="M75.7607 32.6299L80.0606 33.6376L81.4765 27.6115L82.7219 22.3182L78.4285 21.3105L77.1765 26.6038L75.7607 32.6299Z"
          fill="#B0B0B0"
        />
        <path d="M35.3272 36.4313L33.1042 45.8774L43.7415 48.3718L45.9646 38.9257L35.3272 36.4313Z" fill="#D9D9D9" />
        <path d="M49.0841 39.6593L46.861 49.1055L57.4983 51.5998L59.7214 42.1537L49.0841 39.6593Z" fill="#D9D9D9" />
        <path d="M62.8424 42.8805L60.6193 52.3267L71.2566 54.821L73.4797 45.3749L62.8424 42.8805Z" fill="#D9D9D9" />
        <path d="M76.599 46.11L74.3759 55.5562L85.0132 58.0505L87.2363 48.6044L76.599 46.11Z" fill="#D9D9D9" />
        <path d="M18.7382 45.2682L16.5151 54.7144L27.1524 57.2087L29.3755 47.7626L18.7382 45.2682Z" fill="#D9D9D9" />
        <path d="M32.4965 48.4899L30.2734 57.936L40.9107 60.4304L43.1338 50.9843L32.4965 48.4899Z" fill="#D9D9D9" />
        <path d="M46.2467 51.7179L44.0237 61.1641L54.661 63.6584L56.884 54.2123L46.2467 51.7179Z" fill="#D9D9D9" />
        <path d="M60.005 54.9396L57.782 64.3857L68.4193 66.8801L70.6423 57.434L60.005 54.9396Z" fill="#FD4D48" />
        <path d="M73.7553 58.1676L71.5322 67.6138L82.1695 70.1081L84.3926 60.662L73.7553 58.1676Z" fill="#D9D9D9" />
        <path d="M15.9008 57.3205L13.6777 66.7666L24.3151 69.261L26.5381 59.8148L15.9008 57.3205Z" fill="#D9D9D9" />
        <path d="M29.6591 60.5485L27.4361 69.9946L38.0734 72.489L40.2964 63.0429L29.6591 60.5485Z" fill="#FD4D48" />
        <path d="M43.4093 63.7765L41.1863 73.2227L51.8236 75.717L54.0467 66.2709L43.4093 63.7765Z" fill="#D9D9D9" />
        <path d="M57.1676 66.9982L54.9446 76.4443L65.5819 78.9387L67.805 69.4926L57.1676 66.9982Z" fill="#D9D9D9" />
        <path
          d="M117.843 30.5758C119.029 22.2662 113.241 14.5716 104.917 13.3873C96.5919 12.2031 88.8835 17.9805 87.697 26.2836C87.153 30.0851 88.0641 33.7426 90.0109 36.7197L85.6323 43.7076C86.1239 42.929 93.2621 43.6815 94.3764 43.6945C104.412 43.8974 116.106 42.7196 117.843 30.5693V30.5758Z"
          fill="#F1F1F1"
        />
        <path
          d="M102.983 37.5635C108.089 37.5635 112.232 33.4283 112.232 28.3313C112.232 23.2343 108.089 19.0991 102.983 19.0991C97.8767 19.0991 93.734 23.2343 93.734 28.3313C93.734 33.4283 97.8767 37.5635 102.983 37.5635Z"
          fill="#F04E4A"
        />
        <path
          d="M102.491 34.0304C102.439 33.6771 102.504 33.3696 102.688 33.0948C102.871 32.8265 103.166 32.663 103.592 32.6041C104.012 32.5452 104.346 32.6237 104.602 32.8265C104.851 33.0359 105.002 33.3173 105.054 33.6706C105.1 34.0239 105.035 34.3314 104.851 34.5931C104.667 34.8549 104.366 35.0184 103.94 35.0773C103.52 35.1362 103.186 35.0642 102.937 34.8614C102.688 34.6586 102.544 34.3838 102.491 34.0304ZM104.294 31.0207L102.347 31.2955L100.21 22.698L103.737 22.2007L104.294 31.0207Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_100_19709">
          <rect fill="white" height={size} width={size} />
        </clipPath>
      </defs>
    </svg>
  );
});

export default CalendarIllustration;
