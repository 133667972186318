import produce from 'immer';

import baseState from 'utils/baseState';

import { getInvoiceCancellations, getInvoiceTaxRegime } from './actions';

export const initialState = {
  CancellationReasons: {
    ...baseState,
    list: [],
  },
  TaxRegime: {
    ...baseState,
    data: {
      taxRegimeCompany: [],
      taxRegimePersons: [],
    },
  },
};

const InvoiceCatalogProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getInvoiceCancellations.REQUEST:
        draft.CancellationReasons = {
          ...initialState.CancellationReasons,
          fetching: true,
        };
        break;
      case getInvoiceCancellations.SUCCESS:
        draft.CancellationReasons.fetching = false;
        draft.CancellationReasons.fetched = true;
        draft.CancellationReasons.list = payload;
        break;

      case getInvoiceCancellations.FAILURE:
        draft.CancellationReasons.error = payload;
        draft.CancellationReasons.fetching = false;
        draft.CancellationReasons.fetched = false;
        break;

      case getInvoiceCancellations.RESET:
        draft.CancellationReasons = {
          ...initialState.CancellationReasons,
        };
        break;

      case getInvoiceTaxRegime.REQUEST:
        draft.TaxRegime = {
          ...initialState.TaxRegime,
          fetching: true,
        };
        break;
      case getInvoiceTaxRegime.SUCCESS:
        draft.TaxRegime.fetching = false;
        draft.TaxRegime.fetched = true;
        draft.TaxRegime.data = payload;
        break;

      case getInvoiceTaxRegime.FAILURE:
        draft.TaxRegime.error = payload;
        draft.TaxRegime.fetching = false;
        draft.TaxRegime.fetched = false;
        break;

      case getInvoiceTaxRegime.RESET:
        draft.TaxRegime = {
          ...initialState.TaxRegime,
        };
        break;

      default:
        return draft;
    }
  });

export default InvoiceCatalogProviderReducer;
