import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import { GET_ROLES, DUPLICATE_ROLE, DELETE_ROLE, GET_PERMISSIONS_BY_ROLES } from './constants';

export const getRoles = extendRoutine(createRoutine(GET_ROLES), 'RESET');

export const duplicateRole = extendRoutine(createRoutine(DUPLICATE_ROLE), 'RESET');

export const deleteRole = extendRoutine(createRoutine(DELETE_ROLE), 'RESET');

export const getPermissionsByRoles = extendRoutine(createRoutine(GET_PERMISSIONS_BY_ROLES), 'RESET');
