const AVAILABLE = 'AVAILABLE';
const UNAVAILABLE = 'UNAVAILABLE';
const PAYMENT_REQUIRED = 'PAYMENT_REQUIRED';
const PAYMENT_DECLINED = 'PAYMENT_DECLINED';

export const subscriptionStatusTypes = {
  AVAILABLE,
  UNAVAILABLE,
  PAYMENT_REQUIRED,
  PAYMENT_DECLINED,
};

export const statusPayment = {
  PAID: 'PAID',
  EXPIRED: 'EXPIRED',
  PENDING: 'PENDING',
};
