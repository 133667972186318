/**
 *
 * ParrotSoftware
 * GoogleMapsProvider hoc
 *
 */
import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { selectGeocodingLookupLatitudeLongitude, selectGeocodingLookupLatitudeLongitudeState } from './selectors';
import { geocodingLookupLatitudeLongitude } from './actions';

export function withGoogleMapsHoc(Component) {
  function withGoogleMaps(props) {
    return <Component {...props} />;
  }

  withGoogleMaps.displayName = `withGoogleMaps(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withGoogleMaps;
}

const mapStateToProps = createStructuredSelector({
  geocodingLookupLatitudeLongitude: selectGeocodingLookupLatitudeLongitude,
  geocodingLookupLatitudeLongitudeState: selectGeocodingLookupLatitudeLongitudeState,
});

function mapDispatchToProps(dispatch) {
  return {
    loadGeocodingLookupLatitudeLongitude: ({ address }) => dispatch(geocodingLookupLatitudeLongitude({ address })),
  };
}

export const withGoogleMapsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withGoogleMapsState, withGoogleMapsHoc);
