import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { usersValidateEmailRequest } from 'utils/api/baas/users/email';

import { validateEmail } from './actions';

export function* handleValidateEmailRequest(action) {
  const email = action.payload;

  try {
    yield put(validateEmail.request());

    const { data } = yield call(baasRequest, usersValidateEmailRequest, email);

    yield put(validateEmail.success(data));
  } catch (err) {
    yield put(validateEmail.failure(err));
  } finally {
    yield put(validateEmail.fulfill());
  }
}

export default function* emailProviderSaga() {
  yield takeLatest(validateEmail.TRIGGER, handleValidateEmailRequest);
}
