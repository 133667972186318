import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  colorInherit?: string;
  size?: string | number;
}

const ErrorCircle = forwardRef(function ErrorCircle(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FADCDB', colorInherit = '#DF130E', size = 72, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 72 72"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <circle cx="36" cy="36" fill={color} r="36" />
      <path
        clipRule="evenodd"
        d="M44.6697 28.9249C45.1101 28.4845 45.1101 27.7706 44.6697 27.3303C44.2294 26.8899 43.5155 26.8899 43.0751 27.3303L36 34.4054L28.9249 27.3303C28.4845 26.8899 27.7706 26.8899 27.3303 27.3303C26.8899 27.7706 26.8899 28.4845 27.3303 28.9249L34.4054 36L27.3303 43.0751C26.8899 43.5155 26.8899 44.2294 27.3303 44.6697C27.7706 45.1101 28.4845 45.1101 28.9249 44.6697L36 37.5946L43.0751 44.6697C43.5155 45.1101 44.2294 45.1101 44.6697 44.6697C45.1101 44.2294 45.1101 43.5155 44.6697 43.0751L37.5946 36L44.6697 28.9249Z"
        fill={colorInherit}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default ErrorCircle;
