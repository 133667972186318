import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getModifierGroupRequest } from 'utils/api/baas/menuMaker/modifierGroups';

import { getModifierGroup } from '../actions';

export function* handleGetModifierGroupRequest(action) {
  const { storeUuid, modifierGroupUuid } = action.payload;
  try {
    yield put(getModifierGroup.request());

    const { data } = yield call(baasRequest, getModifierGroupRequest, {
      storeUuid,
      modifierGroupUuid,
    });

    yield put(getModifierGroup.success(data));
  } catch (err) {
    yield put(getModifierGroup.failure({ error: err, modifierGroupUuid }));
  }
}
