import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_PRODUCTS,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  DUPLICATE_PRODUCT,
  TOGGLE_PRODUCT_AVAILABILITY,
  UPDATE_PRODUCT_IMAGE,
  GET_PRODUCTS_SCROLL_PAGINATION,
} from './constants';

export const getProducts = extendRoutine(createRoutine(GET_PRODUCTS), [
  'SORTING',
  'RESET_SORTING',
  'PAGINATION',
  'RESET_PAGINATION',
  'RESET',
]);

export const createProduct = extendRoutine(createRoutine(CREATE_PRODUCT), 'RESET');

export const toggleProductAvailability = extendRoutine(createRoutine(TOGGLE_PRODUCT_AVAILABILITY), 'RESET');

export const duplicateProduct = extendRoutine(createRoutine(DUPLICATE_PRODUCT), 'RESET');

export const deleteProduct = extendRoutine(createRoutine(DELETE_PRODUCT), 'RESET');

export const updateProductImage = extendRoutine(createRoutine(UPDATE_PRODUCT_IMAGE), 'RESET');
export const getProductsScrollPagination = extendRoutine(createRoutine(GET_PRODUCTS_SCROLL_PAGINATION), 'RESET');
