import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  backgroundColor?: string;
  colorInherit?: string;
  size?: string | number;
}
const Alert = forwardRef(function Alert(props: Props, ref: Ref<SVGSVGElement>) {
  const { backgroundColor = '#F04E4A', colorInherit = '#000', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        fill={backgroundColor}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.25 15L11.25 5L12.75 5L12.75 15L11.25 15Z"
        fill={colorInherit}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.25 19L11.25 17L12.75 17L12.75 19L11.25 19Z"
        fill={colorInherit}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default Alert;
