import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getPaymentsMethodsRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/payment-providers/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getResumePaymentMethodRequest({ provider, paymentProviderUuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/online-ordering/payment-providers/${paymentProviderUuid}/resume?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      provider,
    }),
    ...options,
  });
}

export function createPaymentMethodRequest(provider, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/payment-providers/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      store: storeUuid,
      provider,
    }),
    ...options,
  });
}

export function updatePaymentMethodRequest({ provider, status }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/payment/method/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      provider,
      status,
    }),
    ...options,
  });
}
