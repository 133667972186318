import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerLayout: {
    marginBottom: '0px',
  },
  root: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    width: ({ openDrawer }) => `calc(100% - ${openDrawer ? '496px' : '0px'})`,
    height: ({ isModeDraft }) => `calc(100vh - ${theme.typography.pxToRem(isModeDraft ? 193 : 137)})`,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: ({ openDrawer }) => `${openDrawer ? '0px' : '100%'}`,
    },
  },
  contentResume: {
    width: '100%',
  },
  headerResume: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 2rem',
    width: '100%',
  },
  rootTable: {
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.darkGrey[15]}`,
      width: '100%',
    },
  },
  rootRow: {
    padding: '1rem 0',
  },
  headerRow: {
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapperTimesRow: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    '& > div': {
      fontSize: '1rem',
    },
  },
  contentLeftRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :first-child': {
      marginRight: theme.spacing(5),
    },
  },
  providersRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  containerMessageError: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    maxHeight: 500,
  },
}));

export default useStyles;
