import { Children, memo } from 'react';
import PropTypes from 'prop-types';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

export function ClientData({ children }) {
  useInjectSaga({ key: 'reportClientData', saga });
  useInjectReducer({ key: 'reportClientData', reducer });

  return Children.only(children);
}

ClientData.propTypes = {
  children: PropTypes.element,
};

export { default as useClientData } from './useClientData';
export default memo(ClientData);
