const PARROT = 'PARROT';
const UBER_EATS = 'UBER_EATS';
const RAPPI = 'RAPPI';
const DIDI_FOOD = 'DIDI_FOOD';
const ONLINE = 'ONLINE';

export const providersTypes = {
  PARROT,
  DIDI_FOOD,
  UBER_EATS,
  RAPPI,
  ONLINE,
};

const POS_FILED = 'posFiled';
const UBER_EATS_FILED = 'uberEatsFiled';
const DIDI_FOOD_FILED = 'didiFoodFiled';
const RAPPI_FILED = 'rappiFiled';
const ONLINE_FIELD = 'onlineField';

export const providersFields = {
  [POS_FILED]: PARROT,
  [DIDI_FOOD_FILED]: DIDI_FOOD,
  [UBER_EATS_FILED]: UBER_EATS,
  [RAPPI_FILED]: RAPPI,
  [ONLINE_FIELD]: ONLINE,
};

const posField = {
  name: POS_FILED,
  id: POS_FILED,
  label: 'reports:products.labels.channels.posField',
  value: PARROT,
  register: {},
};

const uberEatsFiled = {
  name: UBER_EATS_FILED,
  id: UBER_EATS_FILED,
  label: 'reports:products.labels.channels.uberEatsFiled',
  value: UBER_EATS,
  register: {},
};

const didiFoodFiled = {
  name: DIDI_FOOD_FILED,
  id: DIDI_FOOD_FILED,
  label: 'reports:products.labels.channels.didiFoodFiled',
  value: DIDI_FOOD,
  register: {},
};

const rappiFiled = {
  name: RAPPI_FILED,
  id: RAPPI_FILED,
  label: 'reports:products.labels.channels.rappiFiled',
  value: RAPPI,
  register: {},
};

const onlineField = {
  name: ONLINE_FIELD,
  id: ONLINE_FIELD,
  label: 'reports:products.labels.channels.onlineOrderingField',
  value: ONLINE,
  register: {},
};

export const providersOptions = [posField, uberEatsFiled, rappiFiled, didiFoodFiled, onlineField];

const PICK_UP = 'PICK_UP';
const DINE_IN = 'DINE_IN';
const DELIVERY = 'DELIVERY';
const TAKE_OUT = 'TAKE_OUT';
const EXTERNAL = 'EXTERNAL';

export const typeOrders = {
  PICK_UP,
  DINE_IN,
  DELIVERY,
  TAKE_OUT,
  EXTERNAL,
};

export const filters = {
  dates: { startDate: null, endDate: null },
  brands: [],
  providers: [],
  orderTypes: [],
  categories: [],
};
