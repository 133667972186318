import produce from 'immer';

import {
  getBrandsReceiptCustomization,
  getBrandReceiptCustomization,
  updateBrandReceiptCustomization,
} from './actions';

export const initialState = {
  getBrandsReceiptCustomization: {
    error: null,
    fetching: false,
    fetched: false,
    brands: [],
  },

  getBrandReceiptCustomization: {
    error: null,
    fetching: false,
    fetched: false,
    brand: {},
  },

  updateBrandReceiptCustomization: {
    error: null,
    fetching: false,
    fetched: false,
    brand: {},
  },
};

const restaurantReceiptCustomizationProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      // get all brands
      case getBrandsReceiptCustomization.REQUEST:
        draft.getBrandsReceiptCustomization = { ...initialState.getBrandsReceiptCustomization, fetching: true };
        break;

      case getBrandsReceiptCustomization.SUCCESS:
        draft.getBrandsReceiptCustomization.fetching = false;
        draft.getBrandsReceiptCustomization.fetched = true;
        draft.getBrandsReceiptCustomization.brands = payload.brands;
        break;

      case getBrandsReceiptCustomization.FAILURE:
        draft.getBrandsReceiptCustomization.error = payload;
        draft.getBrandsReceiptCustomization.fetching = false;
        draft.getBrandsReceiptCustomization.fetched = false;
        break;

      case getBrandsReceiptCustomization.RESET:
        draft.getBrandsReceiptCustomization = { ...initialState.getBrandsReceiptCustomization };
        break;

      // get brand
      case getBrandReceiptCustomization.REQUEST:
        draft.getBrandReceiptCustomization = { ...initialState.getBrandReceiptCustomization, fetching: true };
        break;

      case getBrandReceiptCustomization.SUCCESS:
        draft.getBrandReceiptCustomization.fetching = false;
        draft.getBrandReceiptCustomization.fetched = true;
        draft.getBrandReceiptCustomization.brand = payload;
        break;

      case getBrandReceiptCustomization.FAILURE:
        draft.getBrandReceiptCustomization.error = payload;
        draft.getBrandReceiptCustomization.fetching = false;
        draft.getBrandReceiptCustomization.fetched = false;
        break;

      case getBrandReceiptCustomization.RESET:
        draft.getBrandReceiptCustomization = { ...initialState.getBrandReceiptCustomization };
        break;

      // update brand
      case updateBrandReceiptCustomization.REQUEST:
        draft.updateBrandReceiptCustomization = { ...initialState.updateBrandReceiptCustomization, fetching: true };
        break;

      case updateBrandReceiptCustomization.SUCCESS:
        draft.updateBrandReceiptCustomization.fetching = false;
        draft.updateBrandReceiptCustomization.fetched = true;
        draft.updateBrandReceiptCustomization.brand = payload;
        break;

      case updateBrandReceiptCustomization.FAILURE:
        draft.updateBrandReceiptCustomization.error = payload;
        draft.updateBrandReceiptCustomization.fetching = false;
        draft.updateBrandReceiptCustomization.fetched = false;
        break;

      case updateBrandReceiptCustomization.RESET:
        draft.updateBrandReceiptCustomization = { ...initialState.updateBrandReceiptCustomization };
        break;

      default:
        return draft;
    }
  });

export default restaurantReceiptCustomizationProviderReducer;
