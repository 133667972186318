import { ResponsiveLine, CustomLayerProps } from '@nivo/line';

import Box from '@material-ui/core/Box';

import { DataResponseChart } from 'containers/ReportSalesPerTime/types';

import TooltipChart from 'components/ReportsResume/TooltipChart';

import { themeChartBars, kFormatter, getDataGraph } from 'utils/reports/resume';

import { PropsTooltip } from './types';

const DashedLine = ({ series, lineGenerator, xScale, yScale, points }: CustomLayerProps) => {
  return (
    <>
      {series.map((datum, index) => (
        <path
          key={datum.id}
          d={lineGenerator(
            datum.data.map((d) => ({
              x: xScale(d.data.x),
              y: d.data.y !== null ? yScale(d.data.y) : null,
            })),
          )}
          fill="none"
          stroke={datum.color}
          style={
            index === 0
              ? {
                  strokeDasharray: '2, 2',
                  strokeWidth: 2,
                }
              : {
                  strokeWidth: 4,
                }
          }
        />
      ))}
      {points.map((data) => (
        <g key={data.id}>
          <circle cx={data?.x} cy={data?.y} fill={data?.color} r={3.5} stroke={data?.borderColor} strokeWidth={2} />
        </g>
      ))}
    </>
  );
};

interface Props {
  dataChart?: DataResponseChart;
}
const ChartLinesPerTime = ({ dataChart }: Props) => {
  function renderTooltip(dataE: PropsTooltip) {
    const tooltip = dataE?.point?.data?.tooltip;
    return (
      <TooltipChart>
        {tooltip?.tickValue && <span>{tooltip?.tickValue}: </span>}
        {`${tooltip?.symbol}${tooltip?.value}`}
      </TooltipChart>
    );
  }

  return (
    <Box alignItems="center" display="flex" height={350} justifyContent="center">
      <ResponsiveLine
        axisBottom={{
          tickSize: 8,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          format: (v) => kFormatter(v),
          tickValues: 5,
          tickSize: 0,
          tickPadding: 5,
        }}
        colors={{
          datum: 'color',
        }}
        data={getDataGraph(dataChart?.layers ?? [])}
        enableGridX={false}
        enablePoints
        layers={['grid', 'markers', 'areas', 'slices', 'axes', 'legends', 'crosshair', DashedLine, 'mesh']}
        margin={{ top: 30, right: 30, bottom: 40, left: 40 }}
        pointBorderColor={{ from: 'serieColor' }}
        pointBorderWidth={2}
        pointColor="#ffffff"
        pointSize={7}
        theme={themeChartBars}
        tooltip={renderTooltip}
        useMesh
      />
    </Box>
  );
};

export default ChartLinesPerTime;
