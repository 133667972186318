import PropTypes from 'prop-types';

import useMenus from 'providers/menuMaker/Menus/useMenus';

import Box from '@material-ui/core/Box';

import RowMenu from './RowMenu';
import HelperSkeleton from './HelperSkeleton';
import useStyles from './styles';

function MenuMakerResumeTable({ menus, loadingPagination, openDrawer, scrollPaginationRef }) {
  const classes = useStyles();
  const { getMenusDetails, menusDetails } = useMenus();

  function handleLoadMenuDetails(menuUuid, categoryUuid) {
    getMenusDetails({ menuUuid, categoryUuid });
  }

  return (
    <Box className={classes.rootTable}>
      {menus?.map((menu, index) => (
        <RowMenu
          key={menu?.uuid}
          details={menusDetails[menu?.uuid]}
          menu={menu}
          onLoadDetails={handleLoadMenuDetails}
          openDefault={index === 0}
          openDrawer={openDrawer}
        />
      ))}
      {loadingPagination && <HelperSkeleton />}

      <Box ref={scrollPaginationRef} id="actionTableResumeScroll" mb={4} name="actionTableResumeScroll" />
    </Box>
  );
}

MenuMakerResumeTable.propTypes = {
  menus: PropTypes.array,
  loadingPagination: PropTypes.bool,
  openDrawer: PropTypes.bool,
  scrollPaginationRef: PropTypes.object,
};

export default MenuMakerResumeTable;
