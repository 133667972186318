import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/extendRoutines';

import {
  GET_CLIENT_DATA,
  GET_CLIENT_DETAILS,
  GET_PAGINATION_CLIENTS,
  EXPORT_CLIENTS_REPORT,
  GET_PAGINATION_DETAILS,
} from './constants';

export const getClientData = extendRoutine(createRoutine(GET_CLIENT_DATA), 'RESET');
export const getPaginationClients = extendRoutine(createRoutine(GET_PAGINATION_CLIENTS), 'RESET');
export const getClientDetails = extendRoutine(createRoutine(GET_CLIENT_DETAILS), 'RESET');
export const exportClientsReport = extendRoutine(createRoutine(EXPORT_CLIENTS_REPORT), 'RESET');
export const getPaginationDetails = extendRoutine(createRoutine(GET_PAGINATION_DETAILS), 'RESET');
