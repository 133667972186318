import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

function StepperStartTitle({ children }) {
  return (
    <Box color="#FFFFFF" fontSize="2.5rem" fontWeight={500} mb="1rem">
      {children}
    </Box>
  );
}

StepperStartTitle.propTypes = {
  children: PropTypes.node,
};

export default StepperStartTitle;
