import withStyles from '@material-ui/core/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Button from '@material-ui/core/Button';
import IconPlus from '@experimental-components/IconsComponents/Plus';

const ButtonPlusBase = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    padding: theme.typography.pxToRem(12),
    border: 'none',
    lineHeight: theme.typography.pxToRem(18),
    height: theme.typography.pxToRem(42),
    minWidth: 'min-content',
    backgroundColor: theme.palette.darkGrey[2],
    color: theme.palette.common.black,

    '&:hover': {
      backgroundColor: theme.palette.darkGrey[5],
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.darkGrey[2],
    },

    '&:focus': {
      border: 'none',
    },

    '&$disabled': {
      color: theme.palette.darkGrey[50],
    },
  },

  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.darkGrey[50],
  },

  sizeSmall: {
    padding: 16,
  },
  startIcon: {
    margin: 0,
    paddingRight: (props) => (props.children ? 8 : 0),
  },
}))(Button);

function ButtonPlus({ disabled = false, isDisabled = false, ...others }) {
  const theme = useTheme();
  return (
    <ButtonPlusBase
      disabled={disabled || isDisabled}
      disableElevation
      disableRipple
      startIcon={
        <IconPlus color={disabled || isDisabled ? theme.palette.darkGrey[50] : theme.palette.common.black} size={16} />
      }
      {...others}
    />
  );
}

ButtonPlus.propTypes = {
  ...Button.propTypes,
};

export default ButtonPlus;
