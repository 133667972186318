import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

const Filter = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => {
  const { size = 16, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M8.99546 4.65039H2V3.15039H8.99546V4.65039ZM14 4.65039H12.6227V3.15039H14V4.65039Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.7 13.0508H2V11.5508H7.7V13.0508ZM14 13.0508H10.8091V11.5508H14V13.0508Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.5 8.85059H2V7.35059H3.5V8.85059ZM14 8.85059H6.5V7.35059H14V8.85059Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.5991 3.15039C10.1849 3.15039 9.84912 3.48618 9.84912 3.90039C9.84912 4.3146 10.1849 4.65039 10.5991 4.65039C11.0133 4.65039 11.3491 4.3146 11.3491 3.90039C11.3491 3.48618 11.0133 3.15039 10.5991 3.15039ZM8.34912 3.90039C8.34912 2.65775 9.35648 1.65039 10.5991 1.65039C11.8418 1.65039 12.8491 2.65775 12.8491 3.90039C12.8491 5.14303 11.8418 6.15039 10.5991 6.15039C9.35648 6.15039 8.34912 5.14303 8.34912 3.90039Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.57275 7.40039C5.15854 7.40039 4.82275 7.73618 4.82275 8.15039C4.82275 8.5646 5.15854 8.90039 5.57275 8.90039C5.98697 8.90039 6.32275 8.5646 6.32275 8.15039C6.32275 7.73618 5.98697 7.40039 5.57275 7.40039ZM3.32275 8.15039C3.32275 6.90775 4.33011 5.90039 5.57275 5.90039C6.81539 5.90039 7.82275 6.90775 7.82275 8.15039C7.82275 9.39303 6.81539 10.4004 5.57275 10.4004C4.33011 10.4004 3.32275 9.39303 3.32275 8.15039Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.71814 11.4414C9.30393 11.4414 8.96814 11.7772 8.96814 12.1914C8.96814 12.6056 9.30393 12.9414 9.71814 12.9414C10.1324 12.9414 10.4681 12.6056 10.4681 12.1914C10.4681 11.7772 10.1324 11.4414 9.71814 11.4414ZM7.46814 12.1914C7.46814 10.9488 8.4755 9.94141 9.71814 9.94141C10.9608 9.94141 11.9681 10.9488 11.9681 12.1914C11.9681 13.434 10.9608 14.4414 9.71814 14.4414C8.4755 14.4414 7.46814 13.434 7.46814 12.1914Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
});

export default Filter;
