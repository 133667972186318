import React, { useEffect, memo } from 'react';

import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withMenuPreview } from 'providers/menuMaker/MenuPreview';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import Layout, { ViewPanelLayout } from 'Layout';

import useTheme from '@material-ui/core/styles/useTheme';

import { withRequiredLicense } from 'providers/root/AuthProvider';

import PreviewMenuDetails from 'components/PreviewMenuDetails';

import useUserStore from 'hooks/useUserStore';

function MenuMakerPreview({
  categoryProductsFetching,
  exportMenu,
  exportMenuState,
  history,
  loadCategoryProducts,
  loadMenu,
  loadProductsDetails,
  match,
  menu,
  menuCategories,
  menuFetched,
  menuFetching,
  menuProductsDetails,
  productsDetailsFetching,
  resetExportMenu,
  resetMenuPreview,
  t,
}) {
  const { menuUuid } = match?.params;
  const theme = useTheme();
  const {
    storeState: { selected: store },
  } = useUserStore();

  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('@event.$menuMaker.viewMenuMakerDetail', '@@section.menu');
    setTimeout(() => {
      loadMenu({ menuUuid, storeUuid: store?.uuid });
    }, 50);
  }, [loadMenu, menuUuid, store]);

  useEffect(() => {
    if (menuFetched) {
      const firstCategoryUuid = Object.keys(menuCategories)[0];
      if (!menuCategories[firstCategoryUuid]?.isLoaded) {
        loadCategoryProducts({ menuUuid, storeUuid: store?.uuid, categoryUuid: firstCategoryUuid });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuFetched]);

  useEffect(() => {
    return () => {
      resetExportMenu();
      resetMenuPreview();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleGoBack() {
    history.push({ pathname: '/menus' });
  }

  function handleLoadCategoryProducts(categoryUuid) {
    loadCategoryProducts({ menuUuid, storeUuid: store?.uuid, categoryUuid });
  }

  function handleLoadProductDetails(productUuid) {
    loadProductsDetails({ menuUuid, storeUuid: store?.uuid, productUuid });
  }

  const handleExportMenu = () => {
    exportMenu({ menuUuid: menu?.uuid, storeUuid: store?.uuid });
  };

  return (
    <Layout>
      <ViewPanelLayout
        disabledGoBack={menuFetching}
        labelGoBack={t('common:buttons.toReturn')}
        onGoBack={handleGoBack}
        pb={theme.typography.pxToRem(32)}
        pl={0}
        pr={0}
      >
        <PreviewMenuDetails
          categories={menuCategories}
          exportMenu={handleExportMenu}
          exportMenuState={exportMenuState}
          loading={menuFetching}
          loadingCategoryProducts={categoryProductsFetching}
          loadingMenuProductsDetails={productsDetailsFetching}
          menu={menu}
          onLoadCategoryProducts={handleLoadCategoryProducts}
          onLoadCategoryProductsDetails={handleLoadProductDetails}
          productsDetails={menuProductsDetails}
          store={store}
        />
      </ViewPanelLayout>
    </Layout>
  );
}

MenuMakerPreview.propTypes = {
  categoryProductsFetching: PropTypes.bool,
  exportMenu: PropTypes.func,
  exportMenuState: PropTypes.object,
  history: PropTypes.object,
  loadCategoryProducts: PropTypes.func,
  loadMenu: PropTypes.func,
  loadProductsDetails: PropTypes.func,
  match: PropTypes.object,
  menu: PropTypes.object,
  menuCategories: PropTypes.object,
  menuFetched: PropTypes.bool,
  menuFetching: PropTypes.bool,
  menuProductsDetails: PropTypes.object,
  productsDetailsFetching: PropTypes.bool,
  resetExportMenu: PropTypes.func,
  resetMenuPreview: PropTypes.func,
  t: PropTypes.func,
};

export default compose(memo, withRequiredLicense(), withMenuPreview, withTranslation(''))(MenuMakerPreview);
