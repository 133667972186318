import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

import Table from '@experimental-components/Table';

import PriceFormatField from 'components/PriceFormatField';
import PlaceholdersTable from 'components/PlaceholdersTable';

import { getUnitWeight } from 'utils/products/soldByGrammage';

import TableRowsModifiersGroups from './TableRowsModifiersGroups';

import useStyles from './styles';

function TableRowsBody({ product, modifiersGroups, onLoadModfiersGroups, loadingModifiersGroups, t }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const unitWeight = useMemo(() => {
    return getUnitWeight({ serving: product?.serving, servingUnit: product?.servingUnit });
  }, [product?.serving, product?.servingUnit]);

  function handleLoadModifiersGroups() {
    if (!open && !modifiersGroups?.isLoaded) onLoadModfiersGroups(product?.uuid);
    setOpen(!open);
  }

  function getPreviewImage() {
    if (product?.productImage) {
      return <img alt="Preview" src={product?.productImage} />;
    }

    return <InsertPhotoIcon className={classes.placeholderIcon} color="disabled" />;
  }

  function renderModifierGroupsText() {
    if (product?.modifierCounter > 0 && product?.modifierRequired) {
      return `${product?.modifierCounter} ${t('menuMaker:previewMenu.modifierGroups')} ${t(
        'menuMaker:previewMenu.modifierGroupsRequired',
      )}`;
    }
    if (product?.modifierCounter > 0 && !product?.modifierRequired) {
      return `${product?.modifierCounter} ${t('menuMaker:previewMenu.modifierGroups')}`;
    }
  }

  function renderSubRows(optionsModifier) {
    const subRows = optionsModifier?.map((optionModifier) => (
      <TableRowsModifiersGroups
        key={optionModifier?.uuid}
        isCollapse={optionModifier?.isCollapse}
        isShowPrice={optionModifier?.isShowPrice}
        level={optionModifier?.level}
        option={optionModifier}
      >
        {!optionModifier?.conditionalGroup &&
          !optionModifier?.finalGroup &&
          renderSubRows(optionModifier?.conditionals)}

        {optionModifier?.conditionalGroup && (
          <TableRowsModifiersGroups
            key={optionModifier?.conditionalGroup?.uuid}
            isCollapse={optionModifier?.conditionalGroup?.isCollapse}
            isConditionalGroup={optionModifier?.conditionalGroup?.isConditionalGroup}
            isHeader
            isShowPrice={optionModifier?.isShowPrice}
            level={optionModifier?.conditionalGroup?.level}
            option={optionModifier?.conditionalGroup}
          >
            {renderSubRows(optionModifier?.conditionalGroup?.options)}
          </TableRowsModifiersGroups>
        )}
        {optionModifier?.finalGroup && (
          <TableRowsModifiersGroups
            key={optionModifier?.finalGroup?.uuid}
            isCollapse={optionModifier?.finalGroup?.isCollapse}
            isHeader
            isShowPrice={optionModifier?.isShowPrice}
            level={optionModifier?.finalGroup?.level}
            option={optionModifier?.finalGroup}
          >
            {renderSubRows(optionModifier?.finalGroup?.options)}
          </TableRowsModifiersGroups>
        )}
      </TableRowsModifiersGroups>
    ));

    return subRows;
  }

  return (
    <>
      <Table.Row>
        <Table.Cell className={classes.rowCell} component="th" isBorder={false}>
          <Box className={classes.iconExpand} display="block" width={4}>
            {product?.modifierCounter > 0 && (
              <IconButton aria-label="expand row" onClick={() => handleLoadModifiersGroups()} size="small">
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </Box>
        </Table.Cell>
        <Table.Cell className={classes.rowCell} component="th" isBorder={false}>
          <Box className={classes.photoContainer}>{getPreviewImage()}</Box>
        </Table.Cell>
        <Table.Cell className={classes.rowCell} component="th" isBorder={false} scope="row">
          <Box display="inline-grid">
            <Box fontSize="1rem" fontWeight="fontWeightMedium">
              {product?.name}
            </Box>
            <Box component="p" m={0}>
              {renderModifierGroupsText()}
            </Box>
            <Box color="darkGrey.main" component="p" m={0}>
              {product?.description}
            </Box>
          </Box>
        </Table.Cell>
        <Table.Cell className={classes.rowCell} isBorder={false}>
          <Box component="span" display="inline-flex" fontSize="1rem" fontWeight="fontWeightMedium">
            <PriceFormatField displayType="text" thousandSeparator value={product?.price} />
            {unitWeight && (
              <Box>
                /{Number(product?.serving)} {unitWeight}
              </Box>
            )}
          </Box>
        </Table.Cell>
      </Table.Row>
      {loadingModifiersGroups && open && !modifiersGroups?.isLoaded && <PlaceholdersTable placeholderCols={4} />}
      {modifiersGroups?.isLoaded &&
        product?.modifierCounter > 0 &&
        open &&
        modifiersGroups?.modifiers?.map((modifier) => (
          <TableRowsModifiersGroups key={modifier?.uuid} isCollapse isHeader option={modifier}>
            {renderSubRows(modifier?.optionsByModifier)}
          </TableRowsModifiersGroups>
        ))}
    </>
  );
}

TableRowsBody.propTypes = {
  product: PropTypes.object,
  modifiersGroups: PropTypes.object,
  onLoadModfiersGroups: PropTypes.func,
  loadingModifiersGroups: PropTypes.bool,
  t: PropTypes.func,
};

export default compose(withTranslation('menuMaker'))(TableRowsBody);
