import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';

import Layout, { ViewPanelLayout } from 'Layout';

import { useQueryParams } from 'hooks';

import { FiltersProps } from '../types';
import { transformParamsToFilters } from '../utils';
import { movementsCashFilterAtom, hasBeenSetQueryParamsAtom, initialState } from '../recoil/atoms';

interface Props {
  title: string;
  subtile?: string;
  description?: string;
  children: React.ReactNode;
  onGoBack: () => void;
}

function LayoutContainer({ children, onGoBack, title, subtile, description }: Props) {
  const { t } = useTranslation('');
  const { queryParams } = useQueryParams<FiltersProps>();
  const [initalParams, setInitalParams] = useState(false);
  const setInitialQueryParams = useSetRecoilState(hasBeenSetQueryParamsAtom);
  const setFiltersValue = useSetRecoilState(movementsCashFilterAtom);

  useEffect(() => {
    if (!initalParams && Object.keys(queryParams)?.length > 0) {
      setInitalParams(true);
      setFiltersValue(transformParamsToFilters(queryParams));
    }
    if (!initalParams && Object.keys(queryParams)?.length === 0) {
      setInitalParams(true);
      setFiltersValue(initialState);
    }
  }, [initalParams, queryParams]);

  useEffect(() => {
    if (initalParams) {
      setInitialQueryParams(true);
    }
  }, [initalParams]);

  return (
    <Layout>
      <ViewPanelLayout labelGoBack={t('common:buttons.toReturn')} onGoBack={() => onGoBack()} pb={0}>
        <Box fontSize={20} fontWeight="fontWeightMedium" mb={2}>
          {title}
        </Box>

        {subtile && (
          <Box color="text.secondary" fontSize={14}>
            {subtile}
          </Box>
        )}

        {description && (
          <Box color="text.secondary" fontSize={14}>
            {description}
          </Box>
        )}

        <Box id="wrapperId" mt={3}>
          {children}
        </Box>
      </ViewPanelLayout>
    </Layout>
  );
}

export default LayoutContainer;
