/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { useElementEdited } from 'containers/MenuMakerResume/ProviderEdition';

import DrawerMenuMaker from 'components/GeneralDrawer';
import ProductForm from 'components/ProductForm';
import ModalConfirm from 'components/ModalConfirm';
import SelectSKU from 'components/SelectSKU';
import ModalValidateUsedSku from 'components/ModalValidateUsedSku';

import {
  defaultProduct,
  defaultProductObject,
  formatAmount,
  getInitialAutocompleteProduct,
  productFormIsDirty,
  productFormIsFormValid,
} from 'utils/products';
import { getKitchenAreasFromCategories } from 'utils/kitchenAreas/makeKitchenAreasFields';
import { covertToSaveArray, convertToFieldFormat, mergeAssignTaxesToField } from 'utils/settingsTaxes/transformer';
import useMenuMakerDrawerState from 'hooks/useMenuMakerDrawerState';
import useNavigation from 'hooks/navigation/useNavigation';
import { generateSkuValue, handleFormatValidateItems } from 'utils/menuMaker';
import { isSaleByWeight } from 'utils/products/soldByGrammage';

import { SalesTypes } from 'components/ProductForm/types';

function ProductsDrawer({
  actionState,
  isEdition,
  categoriesState,
  modifierGroupsState,
  kitchenAreasState,
  multiTaxesState,
  productState,
  onLoadCategories,
  onLoadModifierGroups,
  onLoadKitchenAreas,
  onLoadMultiTaxes,
  onLoadProductDetails,
  onResetCategories,
  onResetModifierGroups,
  onResetGetKitchenAreas,
  onResetMultitaxes,
  onResetProductDetails,
  onSubmit,
  openDrawer,
  uuidSelected,
  categoryOfProduct,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [initProduct, setInitProduct] = useState(defaultProduct);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [defaultTaxes, setDefaultTaxes] = useState({});
  const [canSelectOtherAreas, setCanSelectOtherAreas] = useState(false);
  const [cantPrintKA, setCantPrintKA] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [step, setStep] = useState(1);
  const [skuSelectedTmp, setSkuSelectedTmp] = useState(null);
  const [openValidateSku, setOpenValidateSku] = useState(false);
  const { setKeepOpen, setDrawerIsDirty } = useMenuMakerDrawerState();
  const { setReloadStoreOnClose, setChangeLocationState, setGoToMenuDraft } = useNavigation();
  const { setElementEdited } = useElementEdited();
  const [shouldShowUnitSelector, setShouldShowUnitSelector] = useState(!isEdition);

  const unblockRef = useRef();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      soldAloneField: SalesTypes.ALONE,
      selectedCategories: [],
      selectedModifierGroups: [],
      kitchenAreasFields: [],
    },
  });

  const watchFields = methods.watch();
  const selectedCategoriesFields = useWatch({
    control: methods.control,
    name: 'selectedCategories',
  });
  const selectedModifierGroupsFields = useWatch({
    control: methods.control,
    name: 'selectedModifierGroups',
  });
  const kitchenAreasFields = useWatch({
    control: methods.control,
    name: 'kitchenAreasFields',
  });
  const kitchenAreasFieldsCanPrint = useWatch({
    control: methods.control,
    name: 'kitchenAreasFieldsCanPrint',
  });
  const canSelectOtherAreaField = useWatch({
    control: methods.control,
    name: 'canSelectOtherAreaField',
  });
  const isFormDirty = productFormIsDirty(initProduct, {
    ...watchFields,
    selectedCategories: selectedCategoriesFields,
    selectedModifierGroups: selectedModifierGroupsFields,
    kitchenAreasFields,
    canSelectOtherAreaField,
    kitchenAreasFieldsCanPrint,
  });

  const isFormValid = productFormIsFormValid(methods.formState, { ...watchFields });

  useEffect(() => {
    if (openDrawer) {
      onLoadKitchenAreas();
      onLoadCategories();
      onLoadModifierGroups();
      onLoadMultiTaxes();
    }

    return () => {
      onResetCategories();
      onResetModifierGroups();
      onResetGetKitchenAreas();
      onResetMultitaxes();
    };
  }, [openDrawer]);

  useEffect(() => {
    onResetProductDetails();

    if (isEdition) {
      setTimeout(() => onLoadProductDetails(), 0);
    }
  }, [uuidSelected]);

  useEffect(() => {
    let productValues = { ...defaultProduct };
    setStep(1);

    if (
      categoriesState.fetched &&
      modifierGroupsState.fetched &&
      kitchenAreasState.fetched &&
      multiTaxesState.fetched
    ) {
      const taxesField = convertToFieldFormat(multiTaxesState.list);
      if (isEdition) {
        if (!isEmpty(productState.data) && productState.fetched) {
          const { data } = productState;

          const initCategories = handleFormatValidateItems(
            getInitialAutocompleteProduct(data?.categories, categoriesState.list, 'category'),
          );

          const overridesKitchenAreas = data?.kitchenAreasAssignation.length > 0;
          const kitchenAreasFieldsInit = data?.kitchenAreasAssignation.map((ka) => ka.uuid);
          let newKitchenAreas = [];
          let kitchenAreaCanPrint = true;

          if (data?.categories.length > 0 && kitchenAreasFieldsInit.length === 0) {
            newKitchenAreas = getKitchenAreasFromCategories(categoriesState.list, data?.categories);
          } else if (kitchenAreasFieldsInit.length > 0) {
            kitchenAreaCanPrint = data?.kitchenAreasAssignation.every((ka) => ka.canPrint);
          }

          const newTaxesField = mergeAssignTaxesToField(taxesField, data.taxes, multiTaxesState.list);
          const saleByWeight = isSaleByWeight({ serving: data?.serving, servingUnit: data?.servingUnit });
          const soldAloneValue = data?.soldAlone ? SalesTypes.ALONE : SalesTypes.WITH_MODIFIER;
          const soldAloneField = saleByWeight ? SalesTypes.BY_GRAMMAGE : soldAloneValue;
          productValues = {
            nameProductField: data?.name,
            descriptionProductField: data?.description ?? '',
            imageProductField: data?.image ?? '',
            soldAloneField,
            priceProductField: data?.price,
            selectedCategories: initCategories,
            selectedModifierGroups: handleFormatValidateItems(
              getInitialAutocompleteProduct(data?.modifierGroups, modifierGroupsState.list, 'modifierGroup'),
            ),
            skuFieldMultiStore: data?.sku ?? '',
            posNameField: data?.posName ?? '',
            taxServingUnitField: data?.taxServingUnit,
            taxProductKeyField: data?.taxProductKey,
            exemptField: data?.isExempt,
            taxGroupFieldIVA: newTaxesField?.taxGroupFieldIVA,
            taxGroupFieldIEPS: newTaxesField?.taxGroupFieldIEPS,
            kitchenAreasFields: kitchenAreasFieldsInit.length > 0 ? kitchenAreasFieldsInit : newKitchenAreas,
            canSelectOtherAreaField: overridesKitchenAreas,
            kitchenAreasFieldsCanPrint: !kitchenAreaCanPrint,
            colorField: data?.color || 'FFFFFF',
            servingUnit: data?.servingUnit,
            serving: data?.serving ? Number(data?.serving) : 0,
            barcodeField: data?.barcode,
          };
          setShouldShowUnitSelector(!saleByWeight);
          setInitProduct({ ...productValues });
          setSelectedCategories(initCategories);
          setCanSelectOtherAreas(overridesKitchenAreas);
          setCantPrintKA(!kitchenAreaCanPrint);
          setDefaultTaxes(newTaxesField);
          setSkuSelectedTmp(data?.sku ?? '');
        }
      } else {
        setShouldShowUnitSelector(true);
        const selectedCategoriesTmp = [];
        let kitchenAreasTmp = [];
        const productSKU = generateSkuValue('AR');

        if (categoryOfProduct) {
          categoriesState.list.forEach((category) => {
            if (category?.uuid === categoryOfProduct) {
              selectedCategoriesTmp.push(category);
              kitchenAreasTmp = category.kitchenAreasAssignation.map((ka) => ka?.uuid);
            }
          });

          setSelectedCategories(selectedCategoriesTmp);
        }

        productValues = {
          ...productValues,
          soldAloneField: SalesTypes.ALONE,
          skuFieldMultiStore: productSKU,
          selectedCategories: selectedCategoriesTmp,
          kitchenAreasFields: kitchenAreasTmp,
          taxGroupFieldIVA: taxesField?.taxGroupFieldIVA,
          taxGroupFieldIEPS: taxesField?.taxGroupFieldIEPS,
        };

        setInitProduct({ ...productValues });
        setDefaultTaxes(taxesField);
        setSkuSelectedTmp(productSKU);
      }
    }

    const fillForm = async () => {
      await methods.reset(productValues);
      if (isEdition) methods.trigger();
    };

    fillForm();
  }, [
    isEdition,
    categoriesState.fetched,
    modifierGroupsState.fetched,
    kitchenAreasState.fetched,
    multiTaxesState.fetched,
    productState.fetched,
    productState.data,
    categoryOfProduct,
  ]);

  useEffect(() => {
    setSkuSelectedTmp(watchFields?.skuFieldMultiStore);
  }, [watchFields?.skuFieldMultiStore]);

  useEffect(() => {
    setDrawerIsDirty(isFormDirty);
    if (!openDrawer) {
      setKeepOpen(false);
      setDrawerIsDirty(false);
      setReloadStoreOnClose(false);
    }

    if (openDrawer && isFormDirty) {
      const unblock = history.block((destination) => {
        setNextPage(destination);

        setOpenConfirmationModal(isFormDirty);
        if (!isFormDirty) setKeepOpen(false);

        return !isFormDirty;
      });
      unblockRef.current = unblock;

      return () => {
        unblock();
      };
    }
  }, [isFormDirty, openDrawer]);

  useEffect(() => {
    if (actionState.fetched) {
      if (setElementEdited) setElementEdited(true);
      unblockNavigation();
    }
  }, [actionState.fetched]);

  const handleCloseDrawer = () => {
    history.replace({ search: '' });
  };

  const handleSaveProduct = () => {
    const data = watchFields;
    let productObject = {};
    let categoriesValuesField = [];
    let modifierGroupsValuesField = [];
    let kitchenAreasValues = {};
    let isExemptValue = false;
    let taxesValues = [];

    if (data.soldAloneField) {
      categoriesValuesField = selectedCategoriesFields?.map((category, index) => ({
        category: category.uuid,
        sorting_position: index,
      }));

      modifierGroupsValuesField = selectedModifierGroupsFields?.map((modifier, index) => ({
        modifier_group: modifier.uuid,
        sorting_position: index,
      }));

      if (data?.canSelectOtherAreaField) {
        kitchenAreasValues = {
          uuids: data?.kitchenAreasFields,
          can_print: !data.kitchenAreasFieldsCanPrint,
        };
      }

      isExemptValue = data.exemptField;
      taxesValues = !data.exemptField ? covertToSaveArray(data, multiTaxesState.list) : [];
    }

    const soldAloneValue = data.soldAloneField === SalesTypes.BY_GRAMMAGE ? true : Boolean(data.soldAloneField);

    productObject = {
      ...defaultProductObject,
      name: data.nameProductField,
      description: data.descriptionProductField,
      sold_alone: soldAloneValue,
      price: formatAmount(data.priceProductField),
      categories: categoriesValuesField,
      modifier_groups: modifierGroupsValuesField,
      sku: data.skuFieldMultiStore,
      pos_name: data.posNameField,
      is_exempt: isExemptValue,
      taxes: taxesValues,
      kitchen_areas: kitchenAreasValues,
      color: data?.colorField || 'FFFFFF',
      serving: data?.serving || 0,
      serving_unit: data?.servingUnit || defaultProductObject.serving_unit,
      barcode: data?.barcodeField,
    };

    if (typeof data.imageProductField === 'object' || isEmpty(data.imageProductField)) {
      productObject.image = data.imageProductField;
    }

    if (!isEmpty(data.taxServingUnitField)) {
      productObject.tax_serving_unit = data.taxServingUnitField;
    }
    if (!isEmpty(data.taxProductKeyField)) {
      productObject.tax_product_key = data.taxProductKeyField;
    }

    onSubmit({ ...productObject });
  };

  const unblockNavigation = () => {
    setKeepOpen(false);
    if (unblockRef.current) unblockRef.current();

    unblockRef.current = null;
    setOpenConfirmationModal(false);

    if (nextPage?.pathname === history.location?.pathname && nextPage?.search === history.location?.search) {
      setStep(1);
      methods.reset(initProduct);
      setSkuSelectedTmp(initProduct?.skuFieldMultiStore);
    }

    history.replace({ pathname: nextPage?.pathname, search: nextPage?.search });
    setReloadStoreOnClose(true);
  };

  const handleCancel = () => {
    setChangeLocationState(false);
    setReloadStoreOnClose(false);
    setGoToMenuDraft(false);
    setKeepOpen(true);
    setNextPage(null);
    setOpenConfirmationModal(false);
  };

  const handleAcceptSKU = () => {
    methods.setValue('skuFieldMultiStore', skuSelectedTmp, { shouldValidate: true });
    setStep(1);
  };

  const handleGoBack = () => {
    setSkuSelectedTmp(watchFields?.skuFieldMultiStore);
    setStep(1);
  };

  const handleSelectSku = (value) => {
    setSkuSelectedTmp(value ?? '');
  };

  const handleValidateSku = () => {
    setOpenValidateSku(true);
  };

  const handleCloseValidateSku = () => {
    setOpenValidateSku(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <DrawerMenuMaker currentStep={step} open={openDrawer}>
          <DrawerMenuMaker.MainView
            disabled={
              !(isFormDirty && isFormValid) ||
              categoriesState.fetching ||
              modifierGroupsState.fetching ||
              kitchenAreasState.fetching ||
              actionState.fetching ||
              productState.fetching ||
              openValidateSku
            }
            labelAction={t('common:buttons.save')}
            loading={actionState.fetching || openValidateSku}
            onAction={handleValidateSku}
            onClose={openDrawer ? handleCloseDrawer : undefined}
            step={1}
          >
            <ProductForm
              actionState={actionState}
              canSelectOtherAreas={canSelectOtherAreas}
              cantPrintKA={cantPrintKA}
              categoriesState={categoriesState}
              categoryOfProduct={categoryOfProduct}
              defaultTaxes={defaultTaxes}
              isEdition={isEdition}
              isLoaded={
                categoriesState.fetched &&
                modifierGroupsState.fetched &&
                kitchenAreasState.fetched &&
                multiTaxesState.fetched &&
                (isEdition ? productState.fetched : true)
              }
              isLoading={
                categoriesState.fetching ||
                modifierGroupsState.fetching ||
                kitchenAreasState.fetching ||
                multiTaxesState.fetching ||
                productState.fetching
              }
              kitchenAreasState={kitchenAreasState}
              menusProductOverride={productState?.data?.menus}
              modifierGroupsState={modifierGroupsState}
              multiTaxes={multiTaxesState.list}
              onSetStep={setStep}
              openDrawer={openDrawer}
              selectedCategories={selectedCategories}
              setCanSelectOtherAreas={setCanSelectOtherAreas}
              setCantPrintKA={setCantPrintKA}
              setSelectedCategories={setSelectedCategories}
              shouldShowUnitSelector={shouldShowUnitSelector}
              unitValue
              uuidSelected={uuidSelected}
            />
          </DrawerMenuMaker.MainView>

          <DrawerMenuMaker.Step
            disabled={watchFields?.skuFieldMultiStore === skuSelectedTmp}
            labelAction={t('common:buttons.accept')}
            onAction={handleAcceptSKU}
            onGoBack={handleGoBack}
            step={2}
            t={t}
          >
            <SelectSKU
              onSelectOption={handleSelectSku}
              optionSelected={watchFields?.skuFieldMultiStore}
              t={t}
              type="product"
            />
          </DrawerMenuMaker.Step>
        </DrawerMenuMaker>
      </FormProvider>

      {openConfirmationModal && (
        <ModalConfirm
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          message={t('menuMaker:messages.exitNoSaveMessage')}
          onAccept={unblockNavigation}
          onCancel={handleCancel}
          open={openConfirmationModal}
          title={t('menuMaker:messages.exitNoSaveTitle')}
        />
      )}
      {openValidateSku && (
        <ModalValidateUsedSku
          actionState={actionState}
          itemUuid={uuidSelected}
          onAccept={handleSaveProduct}
          onClose={handleCloseValidateSku}
          sku={watchFields?.skuFieldMultiStore}
          t={t}
          type="product"
        />
      )}
    </>
  );
}

ProductsDrawer.propTypes = {
  actionState: PropTypes.object,
  isEdition: PropTypes.bool,
  categoriesState: PropTypes.object,
  modifierGroupsState: PropTypes.object,
  kitchenAreasState: PropTypes.object,
  multiTaxesState: PropTypes.object,
  productState: PropTypes.object,
  onLoadCategories: PropTypes.func,
  onLoadModifierGroups: PropTypes.func,
  onLoadKitchenAreas: PropTypes.func,
  onLoadMultiTaxes: PropTypes.func,
  onLoadProductDetails: PropTypes.func,
  onResetCategories: PropTypes.func,
  onResetModifierGroups: PropTypes.func,
  onResetGetKitchenAreas: PropTypes.func,
  onResetMultitaxes: PropTypes.func,
  onResetProductDetails: PropTypes.func,
  onSubmit: PropTypes.func,
  openDrawer: PropTypes.bool,
  uuidSelected: PropTypes.string,
  categoryOfProduct: PropTypes.string,
};

export default ProductsDrawer;
