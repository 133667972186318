import { api } from 'config';

import { Response } from 'types';

import { getUserCurrentStore } from 'utils/auth';

import { DataResponseSummary, DataResponseChart } from './types';

export async function getSalesPerTimeSummary(options: { startDate: string; endDate: string }) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const response = await api.get<Response<Array<DataResponseSummary | DataResponseChart>>>(`/v1/reports/sales/hour/`, {
    params: {
      store_uuid: storeUuid,
      report_format: 'table',
      start_date: options.startDate,
      end_date: options.endDate,
    },
  });

  return response.data.data;
}

export async function getSalesPerTimeLines(options: { startDate: string; endDate: string }) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const response = await api.get<Response<DataResponseChart>>(`/v1/reports/sales/hour/`, {
    params: {
      store_uuid: storeUuid,
      report_format: 'lines',
      start_date: options.startDate,
      end_date: options.endDate,
    },
  });

  return response.data.data;
}
