import { useState, useRef } from 'react';

import {
  setFiltersReportsKey,
  getFiltersReportsKey,
  removeFiltersReportsKey,
  filtersReportsProps,
} from 'utils/reports/filtersStorage';

function useFiltersReports() {
  const filtersStorageRef = useRef<filtersReportsProps>(getFiltersReportsKey());
  const [filtersState, setFilterState] = useState<filtersReportsProps | null>(filtersStorageRef?.current);

  const handleSetFilters = (filters: filtersReportsProps) => {
    setFilterState(filters);
    setFiltersReportsKey(filters);
  };

  const removeFiltersValues = () => {
    setFilterState(null);
    removeFiltersReportsKey();
  };

  return {
    filtersReports: filtersState,
    setFilterReports: handleSetFilters,
    removeFilters: removeFiltersValues,
  };
}

export default useFiltersReports;
