import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectEmployeesDetailsProviderDomain = (state) => state.employeeDetails || initialState;

const selectEmployeeDetails = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.getEmployeeDetail.employee,
);

const selectEmployeeDetailsFetching = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.getEmployeeDetail.fetching,
);

const selectEmployeeDetailsError = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.getEmployeeDetail.error,
);

const selectEmployeeDetailsFetched = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.getEmployeeDetail.fetched,
);

const selectEditEmployee = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.editEmployee.employee,
);

const selectEditEmployeeFetching = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.editEmployee.fetching,
);

const selectEditEmployeeFetched = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.editEmployee.fetched,
);

const selectEditEmployeeError = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.editEmployee.error,
);

const selectUpdatePasswordFetching = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.updatePassport.fetching,
);

const selectUpdatePasswordFetched = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.updatePassport.fetched,
);

const selectUpdatePasswordError = createSelector(
  selectEmployeesDetailsProviderDomain,
  (subState) => subState.updatePassport.error,
);

export {
  selectEmployeesDetailsProviderDomain,
  selectEmployeeDetails,
  selectEmployeeDetailsFetching,
  selectEmployeeDetailsError,
  selectEmployeeDetailsFetched,
  selectEditEmployee,
  selectEditEmployeeFetching,
  selectEditEmployeeFetched,
  selectEditEmployeeError,
  selectUpdatePasswordFetching,
  selectUpdatePasswordFetched,
  selectUpdatePasswordError,
};
