import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const DragHandleVertical = forwardRef(function MoreVertical(props, ref) {
  const { color = '#000000', size = 16, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 5 11"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path clipRule="evenodd" d="M0 0V11H1V0H0ZM3.5 0V11H4.5V0H3.5Z" fill={color} fillRule="evenodd" />
    </svg>
  );
});

DragHandleVertical.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DragHandleVertical;
