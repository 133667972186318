import { GrammageTypes } from 'components/ProductForm/types';

interface IsSalesByWeightProps {
  serving: string | number;
  servingUnit: string;
}

export const isSaleByWeight = ({ serving, servingUnit }: IsSalesByWeightProps) => {
  const servingValue = typeof serving === 'string' ? Number(serving) : serving;
  return servingValue > 0 && servingUnit !== 'PIECES';
};

interface ServingProps {
  serving?: string | number;
  servingUnit?: string;
}

export const getUnitWeight = ({ serving, servingUnit }: ServingProps) => {
  if (!serving || !servingUnit) return '';
  const isByWeight = isSaleByWeight({ serving, servingUnit });
  if (isByWeight) {
    return GrammageTypes.find((type) => type.id === servingUnit)?.name || '';
  }
  return '';
};

export const concatServingUnit = (quantity: string | number, servingUnit: unknown) => {
  const qty = typeof quantity === 'string' ? Number(quantity) : quantity;
  if (!servingUnit) {
    return qty;
  }
  const unitName = servingUnit !== 'PIECES' ? GrammageTypes.find((type) => type.id === servingUnit)?.name || '' : '';
  const qtyTransform = !Number.isInteger(qty) ? qty.toFixed(2) : qty;
  if (unitName) return `${qtyTransform} ${unitName}`;
  return qtyTransform;
};
