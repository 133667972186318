import { useRef, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectGetStatesList,
  selectGetMunicipalityList,
  selectGetNeighborhoodList,
  selectGetInfoZipCode,
  selectGetInfoZipCodeGeocoding,
} from './selectors';
import {
  getStatesList,
  getMunicipalityList,
  getNeighborhoodList,
  getInfoZipCode,
  getInfoZipCodeGeocoding,
} from './actions';

function useAddress() {
  const dispatch = useDispatch();

  const selectGetStatesListRef = useRef();
  const selectGetMunicipalityListRef = useRef();
  const selectGetNeighborhoodListRef = useRef();
  const selectGetInfoZipCodeRef = useRef();
  const selectGetInfoZipCodeGeocodingRef = useRef();

  selectGetStatesListRef.current = useSelector(selectGetStatesList);
  selectGetMunicipalityListRef.current = useSelector(selectGetMunicipalityList);
  selectGetNeighborhoodListRef.current = useSelector(selectGetNeighborhoodList);
  selectGetInfoZipCodeRef.current = useSelector(selectGetInfoZipCode);
  selectGetInfoZipCodeGeocodingRef.current = useSelector(selectGetInfoZipCodeGeocoding);

  const handleGetStates = useCallback(() => {
    dispatch(getStatesList());
  }, [dispatch]);

  const handleRestStates = useCallback(() => {
    dispatch(getStatesList.reset());
  }, [dispatch]);

  const handleGetMunicipalityList = useCallback(
    (state) => {
      dispatch(getMunicipalityList(state));
    },
    [dispatch],
  );

  const handleResetMunicipalityList = useCallback(() => {
    dispatch(getMunicipalityList.reset());
  }, [dispatch]);

  const handleGetNeighborhoodList = useCallback(
    (municipality) => {
      dispatch(getNeighborhoodList(municipality));
    },
    [dispatch],
  );

  const handleResetNeighborhoodList = useCallback(() => {
    dispatch(getNeighborhoodList.reset());
  }, [dispatch]);

  const handleGetInfoZipCode = useCallback(
    (zipCode) => {
      dispatch(getInfoZipCode(zipCode));
    },
    [dispatch],
  );

  const handleResetInfoZipCode = useCallback(() => {
    dispatch(getInfoZipCode.reset());
  }, [dispatch]);

  const handleGetInfoZipCodeGeocoding = useCallback(
    (address) => {
      dispatch(getInfoZipCodeGeocoding(address));
    },
    [dispatch],
  );

  const handleResetInfoZipCodeGeocoding = useCallback(() => {
    dispatch(getInfoZipCodeGeocoding.reset());
  }, [dispatch]);

  const getStatesListValues = selectGetStatesListRef.current;
  const getMunicipalityListValues = selectGetMunicipalityListRef.current;
  const getNeighborhoodListValues = selectGetNeighborhoodListRef.current;
  const getInfoZipCodeValues = selectGetInfoZipCodeRef.current;
  const getInfoZipCodeGeocodingValues = selectGetInfoZipCodeGeocodingRef.current;

  return {
    loadStates: handleGetStates,
    resetStates: handleRestStates,
    getStatesListState: {
      ...getStatesListValues,
    },

    loadMunicipalityList: handleGetMunicipalityList,
    resetMunicipalityList: handleResetMunicipalityList,
    getMunicipalityListState: {
      ...getMunicipalityListValues,
    },

    loadNeighborhoodList: handleGetNeighborhoodList,
    resetNeighborhoodList: handleResetNeighborhoodList,
    getNeighborhoodListState: {
      ...getNeighborhoodListValues,
    },

    loadInfoZipCode: handleGetInfoZipCode,
    resetInfoZipCode: handleResetInfoZipCode,
    getInfoZipCodeValuesState: {
      ...getInfoZipCodeValues,
    },

    loadInfoZipCodeGeocoding: handleGetInfoZipCodeGeocoding,
    resetInfoZipCodeGeocoding: handleResetInfoZipCodeGeocoding,
    getInfoZipCodeGeocodingState: {
      ...getInfoZipCodeGeocodingValues,
    },
  };
}

export default useAddress;
