/* eslint-disable no-unused-vars */
import produce from 'immer';

import { getRoles, duplicateRole, deleteRole, getPermissionsByRoles } from './actions';

export const initialState = {
  getRoles: {
    error: null,
    isFetching: false,
    didFetch: false,
    list: [],
  },
  duplicateRole: {
    error: null,
    isFetching: false,
    didFetch: false,
    newInstance: null,
  },
  deleteRole: {
    error: null,
    isFetching: false,
    didFetch: false,
  },
  getPermissionsByRoles: {
    error: null,
    fetching: false,
    fetched: false,
    permissions: [],
  },
};

const rolesProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      /*
        List
      */
      case getRoles.REQUEST:
        draft.getRoles.error = null;
        draft.getRoles.isFetching = true;
        draft.getRoles.didFetch = false;
        draft.getRoles.list = [];
        break;

      case getRoles.SUCCESS:
        draft.getRoles.isFetching = false;
        draft.getRoles.didFetch = true;
        draft.getRoles.list = payload;
        break;

      case getRoles.FAILURE:
        draft.getRoles.error = payload;
        draft.getRoles.isFetching = false;
        draft.getRoles.didFetch = false;
        draft.getRoles.list = [];
        break;

      case getRoles.RESET:
        draft.getRoles = { ...initialState.getRoles };
        break;

      /*
        Duplicate
      */
      case duplicateRole.REQUEST:
        draft.duplicateRole.error = null;
        draft.duplicateRole.isFetching = true;
        draft.duplicateRole.didFetch = false;
        draft.duplicateRole.newInstance = null;
        break;

      case duplicateRole.SUCCESS:
        draft.duplicateRole.isFetching = false;
        draft.duplicateRole.didFetch = true;
        draft.duplicateRole.newInstance = payload;
        // Add duplicated role to existing list of roles
        draft.getRoles.list = draft.getRoles.list.concat([payload]);
        break;

      case duplicateRole.FAILURE:
        draft.duplicateRole.error = payload;
        draft.duplicateRole.isFetching = false;
        draft.duplicateRole.didFetch = false;
        draft.duplicateRole.newInstance = null;
        break;

      case duplicateRole.RESET:
        draft.duplicateRole = { ...initialState.duplicateRole };
        break;

      /*
        Delete
      */
      case deleteRole.REQUEST:
        draft.deleteRole.error = null;
        draft.deleteRole.isFetching = true;
        draft.deleteRole.didFetch = false;
        break;

      case deleteRole.SUCCESS:
        draft.deleteRole.error = null;
        draft.deleteRole.isFetching = false;
        draft.deleteRole.didFetch = true;
        // Remove deleted role from existing list of roles
        draft.getRoles.list = draft.getRoles.list.filter((role) => role.uuid !== payload);
        break;

      case deleteRole.FAILURE:
        draft.deleteRole.error = payload;
        draft.deleteRole.isFetching = false;
        draft.deleteRole.didFetch = false;
        break;

      case deleteRole.RESET:
        draft.deleteRole = { ...initialState.deleteRole };
        break;

      // permission in roles
      case getPermissionsByRoles.REQUEST:
        draft.getPermissionsByRoles = { ...initialState.getPermissionsByRoles, fetching: true };
        break;

      case getPermissionsByRoles.SUCCESS:
        draft.getPermissionsByRoles.fetching = false;
        draft.getPermissionsByRoles.fetched = true;
        draft.getPermissionsByRoles.permissions = payload;
        break;

      case getPermissionsByRoles.FAILURE:
        draft.getPermissionsByRoles.error = payload;
        draft.getPermissionsByRoles.fetching = false;
        draft.getPermissionsByRoles.fetched = false;
        break;

      case getPermissionsByRoles.RESET:
        draft.getPermissionsByRoles = { ...initialState.getPermissionsByRoles };
        break;

      default:
        return draft;
    }
  });

export default rolesProviderReducer;
