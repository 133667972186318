import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

import useStyles from './styles';

function TooltipChart({ children }) {
  const theme = useTheme();
  const clasess = useStyles();

  return (
    <>
      <Box className={clasess.baseTootlip}>{children}</Box>
      <Box
        bgcolor="background.paper"
        borderBottom={`1px solid ${theme.palette.darkGrey[15]}`}
        borderRadius="1px"
        borderRight={`1px solid ${theme.palette.darkGrey[15]}`}
        height="12px"
        margin="auto"
        mt="-7px"
        sx={{ transform: 'rotate(45deg)', zIndex: '-1' }}
        width="12px"
      />
    </>
  );
}

TooltipChart.propTypes = {
  children: PropTypes.node,
};

export default TooltipChart;
