// @ts-nocheck
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import useNotification from '@design-system/Notification/useNotification';
import { useGetOverrideProductDetail, useRestoreOverrideProduct } from 'providers/menuMaker/MenuProducts/hooks';
import useMenuMakerDrawerState from 'hooks/useMenuMakerDrawerState';
import useNavigation from 'hooks/navigation/useNavigation';

import { useElementEdited } from 'containers/MenuMakerResume/ProviderEdition';

import DrawerMenuMaker from 'components/GeneralDrawer';
import ProductOverrideForm from 'components/ProductOverrideForm';
import ModalConfirm from 'components/ModalConfirm';

import { getInitialAutocompleteProduct, productOverrideFormIsDirty } from 'utils/products';
import { removePriceSymbol } from 'utils/currency';
import relativePercentageDiff from 'utils/products/priceVariation';

import { handleFormatValidateItems } from 'utils/menuMaker';

interface PropsSyncProduct {
  productUuid: string;
  menuUuid: string;
  modifiers: string[];
  isRestore: boolean;
}

interface Props {
  actionState: { fetching: boolean; fetched: boolean };
  menuOfProduct: string;
  openDrawer: boolean;
  uuidSelected: string;
  onLoadMenus: () => void;
  onSubmit: (values: {
    menuUuid: string;
    productUuid: string;
    price: string | null;
    modifierGroups: string[];
    image: File;
  }) => void;
  onLoadModifierGroups: () => void;
  onResetModifierGroups: () => void;
  onSyncProductOverride: (values: PropsSyncProduct) => void;
  modifierGroupsState: {
    list: { uuid: string; name: string; description: string }[];
    fetched: boolean;
    fetching: boolean;
    error: boolean;
  };
}

interface PropInitProduct {
  priceOverrideField: string;
  modifierGroupsField: string[];
}

function ProductOverrideDrawer({
  actionState,
  menuOfProduct,
  modifierGroupsState,
  onLoadModifierGroups,
  onResetModifierGroups,
  onSubmit,
  onSyncProductOverride,
  openDrawer,
  uuidSelected,
  isSoldByWeight = false,
}: Props) {
  const [initProduct, setInitProduct] = useState<null | PropInitProduct>(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [nextPage, setNextPage] = useState<null | string>(null);
  const [priceBaseValue, setPriceBaseValue] = useState<null | string>(null);
  const [openPriceOverRange, setOpenPriceOverRange] = useState(false);

  const history = useHistory();
  const unblockRef = useRef();
  const { t } = useTranslation('menuMaker');
  const methods = useForm({ mode: 'onChange' });
  const { setKeepOpen, setDrawerIsDirty } = useMenuMakerDrawerState();
  const { setReloadStoreOnClose, setChangeLocationState, setGoToMenuDraft } = useNavigation();
  const { refetch, isLoading, isError, data, isRefetching } = useGetOverrideProductDetail(uuidSelected, menuOfProduct);
  const restoreProductMutation = useRestoreOverrideProduct('restore_product');
  const { setNotification } = useNotification();
  const { setElementEdited } = useElementEdited();

  const watchFields = methods.watch();
  const isFormDirty = productOverrideFormIsDirty(initProduct, watchFields, isSoldByWeight);

  useEffect(() => {
    if (openDrawer) {
      refetch();
      onLoadModifierGroups();
    } else {
      setInitProduct(null);
      setPriceBaseValue(null);
    }

    return () => {
      onResetModifierGroups();
    };
  }, [openDrawer]);

  useEffect(() => {
    if (uuidSelected) refetch();
  }, [uuidSelected]);

  useEffect(() => {
    if (data && modifierGroupsState.list.length) {
      const { data: dataProduct } = data;

      const currentModifers = dataProduct?.override?.modifiersOverride
        ? dataProduct?.overrideModifiers
        : dataProduct?.modifiers;

      const productValues = {
        priceOverrideField: dataProduct?.override?.price || '',
        modifierGroupsField: handleFormatValidateItems(
          getInitialAutocompleteProduct(currentModifers, modifierGroupsState.list, 'modifierGroup', true),
        ),
        imageOverrideField: dataProduct?.override?.image ?? '',
      };

      setPriceBaseValue(dataProduct?.override?.price || null);
      setInitProduct(productValues);

      const fillForm = async () => {
        await methods.reset(productValues);
        methods.trigger();
      };

      fillForm();
    }
  }, [data, modifierGroupsState.list]);

  useEffect(() => {
    if (restoreProductMutation.isSuccess) {
      const { data: product } = data;

      onSyncProductOverride({
        productUuid: uuidSelected,
        menuUuid: menuOfProduct,
        modifiers: product.modifiers,
        isRestore: true,
      });
      refetch();
    }

    if (restoreProductMutation.isError) {
      setNotification(t('menuMaker:productForm.messages.restoreProductOverride'), { variant: 'error' });
      restoreProductMutation.reset();
    }
  }, [restoreProductMutation.isSuccess, restoreProductMutation.isError]);

  useEffect(() => {
    setDrawerIsDirty(isFormDirty);
    if (!openDrawer) {
      setKeepOpen(false);
      setDrawerIsDirty(false);
      setReloadStoreOnClose(false);
    }

    if (openDrawer) {
      const unblock = history.block((destination) => {
        setNextPage(destination);

        setOpenConfirmationModal(isFormDirty);
        if (!isFormDirty) setKeepOpen(false);

        return !isFormDirty;
      });
      unblockRef.current = unblock;

      return () => {
        unblock();
      };
    }
  }, [isFormDirty]);

  useEffect(() => {
    if (actionState.fetched) {
      if (setElementEdited) setElementEdited(true);
      unblockNavigation();
    }
  }, [actionState.fetched]);

  const handleCloseDrawer = () => {
    history.replace({ search: '' });
  };

  const handleSaveProduct = () => {
    setNextPage(null);
    const priceOverrideValue = removePriceSymbol(watchFields.priceOverrideField) || null;

    if (priceBaseValue !== priceOverrideValue) {
      const priceVariation = relativePercentageDiff(priceBaseValue ?? data?.data?.price, priceOverrideValue);
      if (priceVariation > 30) {
        return setOpenPriceOverRange(true);
      }
    }
    const sendValues = {
      menuUuid: menuOfProduct,
      productUuid: uuidSelected,
      price: priceOverrideValue,
      modifierGroups: watchFields.modifierGroupsField?.map((item) => item.uuid) || [],
    };

    if (typeof watchFields?.imageOverrideField === 'object' || isEmpty(watchFields?.imageOverrideField)) {
      sendValues.image = watchFields.imageOverrideField;
    }

    return onSubmit(sendValues);
  };

  const handleConfirmProductPriceChange = () => {
    const priceOverrideValue = removePriceSymbol(watchFields.priceOverrideField) || null;

    const sendValues = {
      menuUuid: menuOfProduct,
      productUuid: uuidSelected,
      price: priceOverrideValue,
      modifierGroups: watchFields.modifierGroupsField?.map((item) => item.uuid),
    };

    setOpenPriceOverRange(false);
    onSubmit(sendValues);
  };

  const handleCancelProductPriceChange = () => {
    setOpenPriceOverRange(false);
  };

  const handleRestoreProduct = () => {
    restoreProductMutation.mutate({ productUuid: uuidSelected, menuUuid: menuOfProduct });
  };

  const unblockNavigation = () => {
    setKeepOpen(false);
    if (unblockRef.current) unblockRef.current();

    unblockRef.current = undefined;
    setOpenConfirmationModal(false);

    if (nextPage?.pathname === history.location?.pathname && nextPage?.search === history.location?.search) {
      methods.reset(initProduct);
    }

    history.replace({ pathname: nextPage?.pathname, search: nextPage?.search });
    setReloadStoreOnClose(true);
  };

  const handleCancel = () => {
    setChangeLocationState(false);
    setReloadStoreOnClose(false);
    setGoToMenuDraft(false);
    setKeepOpen(true);
    setNextPage(null);
    setOpenConfirmationModal(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <DrawerMenuMaker open={openDrawer}>
          <DrawerMenuMaker.MainView
            disabled={
              !isFormDirty ||
              !methods.formState?.isValid ||
              isLoading ||
              isRefetching ||
              modifierGroupsState.fetching ||
              isError ||
              actionState.fetching
            }
            labelAction={t('common:buttons.save')}
            loading={actionState.fetching}
            onAction={handleSaveProduct}
            onClose={openDrawer ? handleCloseDrawer : undefined}
          >
            <ProductOverrideForm
              isSoldByWeight={isSoldByWeight}
              modifierGroupsState={modifierGroupsState}
              onLoadProduct={refetch}
              productState={{ data: data?.data, fetching: isLoading || isRefetching, error: isError }}
              restoreProduct={handleRestoreProduct}
              restoreProductState={{
                fetching: restoreProductMutation.isLoading,
                fetched: restoreProductMutation.isSuccess,
                error: restoreProductMutation.isError,
              }}
              t={t}
            />
          </DrawerMenuMaker.MainView>
        </DrawerMenuMaker>
      </FormProvider>

      {openConfirmationModal && (
        <ModalConfirm
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          message={t('menuMaker:messages.exitNoSaveMessage')}
          onAccept={unblockNavigation}
          onCancel={handleCancel}
          open={openConfirmationModal}
          title={t('menuMaker:messages.exitNoSaveTitle')}
        />
      )}

      {openPriceOverRange && (
        <ModalConfirm
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          message={t('menuMaker:dialogConfirmPriceVariation.helperText')}
          onAccept={handleConfirmProductPriceChange}
          onCancel={handleCancelProductPriceChange}
          open={openPriceOverRange}
          title={t('menuMaker:dialogConfirmPriceVariation.title', { currentMenuName: 'este menú' })}
        />
      )}
    </>
  );
}

export default ProductOverrideDrawer;
