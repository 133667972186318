import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MoreVertical = forwardRef(function MoreVertical(props, ref) {
  const { color = '#000000', size = 20, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 4 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M-8.74228e-08 18C-3.93402e-08 19.1 0.9 20 2 20C3.1 20 4 19.1 4 18C4 16.9 3.1 16 2 16C0.9 16 -1.35505e-07 16.9 -8.74228e-08 18ZM-6.11959e-07 2C-5.63877e-07 3.1 0.899999 4 2 4C3.1 4 4 3.1 4 2C4 0.899999 3.1 -1.35505e-07 2 -8.74228e-08C0.899999 -3.93403e-08 -6.60042e-07 0.9 -6.11959e-07 2ZM-3.49691e-07 10C-3.01609e-07 11.1 0.9 12 2 12C3.1 12 4 11.1 4 10C4 8.9 3.1 8 2 8C0.899999 8 -3.97774e-07 8.9 -3.49691e-07 10Z"
        fill={color}
      />
    </svg>
  );
});

MoreVertical.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MoreVertical;
