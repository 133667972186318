import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

import { createFormDataForProductSave } from '../menuMaker';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getMenuProductsRequest({ menuUuid, storeUuid }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/products/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function changeMenuProductPriceRequest(productOverride, options = {}) {
  const formData = createFormDataForProductSave({ ...productOverride, menu: productOverride?.menuUuid });
  if (productOverride?.hasOverridePrice && !formData.has('price')) {
    formData.append('price', productOverride.price);
  }
  formData.delete('hasOverridePrice');
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/products/${productOverride.productUuid}/override/?${query}`, {
    method: 'PUT',
    body: formData,
    ...options,
  });
}

export function getOverrideProductRequest({ productUuid, menuUuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    menu_uuid: menuUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/override/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function restoreOverrideProductRequest({ productUuid, menuUuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/restore/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({ menu: menuUuid }),
    ...options,
  });
}
