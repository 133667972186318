import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface Context {
  elementEdited: boolean;
  setElementEdited: Dispatch<SetStateAction<boolean>>;
}

interface Provider {
  children: ReactNode;
}

const ElementEditedContext = createContext<Context | boolean>(false);

export function ElementEditedProvider({ children }: Provider) {
  const [elementEdited, setElementEdited] = useState(false);

  return (
    <ElementEditedContext.Provider value={{ elementEdited, setElementEdited }}>
      {children}
    </ElementEditedContext.Provider>
  );
}

export function useElementEdited() {
  const context = useContext(ElementEditedContext);

  return context;
}
