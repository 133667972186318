import React, { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const SearchOutline = forwardRef(function SearchOutline(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#000', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <g id="Search">
        <path
          clipRule="evenodd"
          d="M18.1717 10.6144C18.1717 14.4771 15.0277 17.6084 11.1493 17.6084C7.27096 17.6084 4.12693 14.4771 4.12693 10.6144C4.12693 6.75168 7.27096 3.62035 11.1493 3.62035C15.0277 3.62035 18.1717 6.75168 18.1717 10.6144ZM16.0475 17.7153C14.6549 18.6699 12.9677 19.2287 11.1493 19.2287C6.37243 19.2287 2.5 15.3719 2.5 10.6144C2.5 5.85678 6.37243 2 11.1493 2C15.9262 2 19.7986 5.85678 19.7986 10.6144C19.7986 12.9756 18.8448 15.115 17.2998 16.6711L21.5 20.8542L20.3496 22L16.0475 17.7153Z"
          fill={color}
          fillRule="evenodd"
          id="Union"
        />
      </g>
    </svg>
  );
});

export default SearchOutline;
