import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { createProductRequest } from 'utils/api/baas/menuMaker';

import { createProduct } from '../actions';

export function* handleCreateProductRequest(action) {
  const { storeUuid, originMenuUuid, product } = action.payload;
  try {
    yield put(createProduct.request());

    const { data } = yield call(baasRequest, createProductRequest, {
      storeUuid,
      originMenuUuid,
      product,
    });

    yield put(createProduct.success(data));
  } catch (err) {
    yield put(createProduct.failure(err));
  }
}
