import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getMultiTaxes, updateDefaultTax } from './actions';

import {
  selectMultiTaxes,
  selectMultiTaxesAtList,
  selectMultiTaxesState,
  selectUpdateDefaultState,
  selectMultiTaxesWithNoOption,
} from './selectors';

export function withMultiTaxesHoc(Component) {
  function withMultiTaxes(props) {
    return <Component {...props} />;
  }

  withMultiTaxes.displayName = `withMultiTaxes(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withMultiTaxes;
}

const mapStateToProps = createStructuredSelector({
  multiTaxes: selectMultiTaxes,
  multiTaxesAtList: selectMultiTaxesAtList,
  multiTaxesWithNoOption: selectMultiTaxesWithNoOption,
  multiTaxesState: selectMultiTaxesState,
  updateDafultTaxState: selectUpdateDefaultState,
});

function mapDispatchToProps(dispatch) {
  return {
    loadMultiTaxes: () => dispatch(getMultiTaxes()),
    resetMultiTaxes: () => dispatch(getMultiTaxes.reset()),
    updateDefaultTax: (taxUuid) => dispatch(updateDefaultTax(taxUuid)),
    resetUpdateDefaultTax: () => dispatch(updateDefaultTax.reset()),
  };
}

export const withMultiTaxesState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withMultiTaxesState, withMultiTaxesHoc);
