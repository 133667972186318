import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectAddressProviderDomain = (state) => state.addressProvider || initialState;

const selectGetStatesList = createSelector(selectAddressProviderDomain, (subState) => subState.getStatesList);

const selectGetMunicipalityList = createSelector(
  selectAddressProviderDomain,
  (subState) => subState.getMunicipalityList,
);

const selectGetNeighborhoodList = createSelector(
  selectAddressProviderDomain,
  (subState) => subState.getNeighborhoodList,
);

const selectGetInfoZipCode = createSelector(selectAddressProviderDomain, (subState) => subState.getInfoZipCode);

const selectGetInfoZipCodeGeocoding = createSelector(
  selectAddressProviderDomain,
  (subState) => subState.getInfoZipCodeGeocoding,
);

export {
  selectAddressProviderDomain,
  selectGetStatesList,
  selectGetMunicipalityList,
  selectGetNeighborhoodList,
  selectGetInfoZipCode,
  selectGetInfoZipCodeGeocoding,
};
