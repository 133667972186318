export function getUuidProducts(products) {
  if (products) return products?.map((product) => product?.uuid);

  return [];
}

export function resetProductsOptionsList(options) {
  if (options)
    return options?.map((option) => ({
      uuid: option?.uuid,
      name: option?.name,
      value: option?.uuid,
      sku: option?.sku,
    }));

  return [];
}
