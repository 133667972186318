import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const CloseOutline = forwardRef(function CloseOutline(props, ref) {
  const { color = '#000000', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M1 4.5C1 4.22386 1.23452 4 1.52381 4H22.4762C22.7655 4 23 4.22386 23 4.5C23 4.77614 22.7655 5 22.4762 5H1.52381C1.23452 5 1 4.77614 1 4.5ZM1 11.5C1 11.2239 1.23452 11 1.52381 11H22.4762C22.7655 11 23 11.2239 23 11.5C23 11.7761 22.7655 12 22.4762 12H1.52381C1.23452 12 1 11.7761 1 11.5ZM1.52381 18C1.23452 18 1 18.2239 1 18.5C1 18.7761 1.23452 19 1.52381 19H22.4762C22.7655 19 23 18.7761 23 18.5C23 18.2239 22.7655 18 22.4762 18H1.52381Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

CloseOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CloseOutline;
