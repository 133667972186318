import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapperFilters: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      gridRow: '2/3',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  wrapperMultiSelect: {
    overflow: 'hidden',
  },
  titleNote: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
  },
  describeNote: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    '& > strong': {
      fontWeight: 500,
    },
  },
  tooltipMaxWidth: {
    maxWidth: 310,
  },
  lineThroughStyle: {
    textDecoration: 'line-through',
  },
}));

export default useStyles;
