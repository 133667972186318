import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  getCategoriesStore,
  saveCategoryStore,
  deleteCategoryStore,
  duplicateCategoryStore,
} from 'utils/api/baas/categories';

import { getCategories, getPaginationCategory, createCategory, deleteCategory, duplicateCategory } from './actions';

export function* handleInitCategoriesRequest({ payload }) {
  try {
    yield put(getCategories.request());

    const { data: categories, pagination } = yield call(baasRequest, getCategoriesStore, payload);

    yield put(getCategories.success({ categories, pagination, page: payload?.page }));
  } catch (err) {
    yield put(getCategories.failure(err));
  }
}

export function* getPaginationCategorySaga({ payload }) {
  try {
    yield put(getPaginationCategory.request());

    const { data, pagination } = yield call(baasRequest, getCategoriesStore, payload);

    yield put(getPaginationCategory.success({ data, pagination, page: payload?.page }));
  } catch (error) {
    yield put(getPaginationCategory.failure(error));
  }
}

export function* handleSaveCategoryRequest(action) {
  try {
    yield put(createCategory.request());

    const { data } = yield call(baasRequest, saveCategoryStore, action.payload);

    yield put(createCategory.success(data));
  } catch (err) {
    yield put(createCategory.failure(err));
  }
}

export function* handleDeleteCategoryRequest(action) {
  const { storeUuid, categoryUuid } = action.payload;
  try {
    yield put(deleteCategory.request());

    yield call(baasRequest, deleteCategoryStore, {
      storeUuid,
      categoryUuid,
    });
    yield put(deleteCategory.success());
  } catch (err) {
    yield put(deleteCategory.failure(err));
  } finally {
    yield put(deleteCategory.fulfill(categoryUuid));
  }
}

export function* handleDuplicateCategoryRequest(action) {
  const { storeUuid, categoryUuid } = action.payload;
  try {
    yield put(duplicateCategory.request());

    const { data } = yield call(baasRequest, duplicateCategoryStore, {
      storeUuid,
      categoryUuid,
    });

    yield put(duplicateCategory.success(data.objectDetail));
  } catch (err) {
    yield put(duplicateCategory.failure({ error: err, categoryUuid }));
  }
}

export default function* categoriesSaga() {
  yield takeLatest(getCategories.TRIGGER, handleInitCategoriesRequest);
  yield takeLatest(getPaginationCategory.TRIGGER, getPaginationCategorySaga);
  yield takeLatest(createCategory.TRIGGER, handleSaveCategoryRequest);
  yield takeLatest(deleteCategory.TRIGGER, handleDeleteCategoryRequest);
  yield takeLatest(duplicateCategory.TRIGGER, handleDuplicateCategoryRequest);
}
