import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  createKitchenAreaRequest,
  getKitchenAreasRequest,
  getKitchenAreaDetailsRequest,
  updateKitchenAreaRequest,
} from 'utils/api/baas/kitchenAreas/kitchenAreas';

import {
  getKitchenAreas,
  getKitchenAreaDetails,
  createKitchenArea,
  updateKitchenArea,
  disableKitchenArea,
  getKitchenAreasPagination,
} from './actions';

export function* handleGetKitchenAreasRequest(action) {
  const filters = action.payload;

  try {
    yield put(getKitchenAreas.request());

    const { data, pagination } = yield call(baasRequest, getKitchenAreasRequest, filters);

    yield put(getKitchenAreas.success({ data, pagination }));
  } catch (err) {
    yield put(getKitchenAreas.failure(err));
  }
}

export function* handleGetKitchenAreasPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getKitchenAreasPagination.request());

    const { data, pagination } = yield call(baasRequest, getKitchenAreasRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };
    yield put(getKitchenAreasPagination.success({ data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getKitchenAreasPagination.failure(err));
  }
}

export function* handleGetKitchenAreaDetailsRequest(action) {
  const kitchenAreaUuid = action.payload;

  try {
    yield put(getKitchenAreaDetails.request());

    const { data } = yield call(baasRequest, getKitchenAreaDetailsRequest, kitchenAreaUuid);

    yield put(getKitchenAreaDetails.success({ data }));
  } catch (err) {
    yield put(getKitchenAreaDetails.failure(err));
  }
}

export function* handleCreateKitchenAreaRequest(action) {
  const fields = action.payload;

  try {
    yield put(createKitchenArea.request());

    const { data } = yield call(baasRequest, createKitchenAreaRequest, fields);

    yield put(createKitchenArea.success({ data }));
  } catch (err) {
    yield put(createKitchenArea.failure(err));
  }
}

export function* handleUpdateKitchenAreaRequest(action) {
  const fields = action.payload;

  try {
    yield put(updateKitchenArea.request());
    const { data } = yield call(baasRequest, updateKitchenAreaRequest, fields);

    yield put(updateKitchenArea.success(data));
  } catch (err) {
    yield put(updateKitchenArea.failure(err));
  }
}

export function* handleDisableKitchenAreaRequest(action) {
  const fields = action.payload;

  try {
    yield put(disableKitchenArea.request());

    const { data } = yield call(baasRequest, updateKitchenAreaRequest, fields);

    yield put(disableKitchenArea.success(data));
  } catch (err) {
    yield put(disableKitchenArea.failure(err));
  }
}

export default function* reportsOrdersProviderRootSga() {
  yield takeLatest(getKitchenAreas.TRIGGER, handleGetKitchenAreasRequest);
  yield takeLatest(getKitchenAreasPagination.TRIGGER, handleGetKitchenAreasPaginationRequest);
  yield takeLatest(getKitchenAreaDetails.TRIGGER, handleGetKitchenAreaDetailsRequest);
  yield takeLatest(createKitchenArea.TRIGGER, handleCreateKitchenAreaRequest);
  yield takeLatest(updateKitchenArea.TRIGGER, handleUpdateKitchenAreaRequest);
  yield takeLatest(disableKitchenArea.TRIGGER, handleDisableKitchenAreaRequest);
}
