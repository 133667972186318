import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';
import { useActiveLicense } from 'containers/Subscription/hooks';

function useLicenseState() {
  const { data: license } = useActiveLicense();

  return {
    licenseStatus: license?.status,
    licenseState: license,
    licenseValid: license?.status === subscriptionStatusTypes.AVAILABLE,
    licenseInvalid: license?.status === subscriptionStatusTypes.UNAVAILABLE,
    paymentInvalid: license?.status === subscriptionStatusTypes.PAYMENT_REQUIRED,
    paymentStatus: {
      lastPaymentStatus: license?.lastPaymentStatus,
      paymentIntentsDetail: license?.paymentIntentsDetail,
    },
    serverDateValidate: license?.serverDate,
  };
}
export default useLicenseState;
