import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getEmployeeDetail, editEmployee, updatePassport } from './actions';

import {
  selectEmployeeDetails,
  selectEmployeeDetailsFetching,
  selectEmployeeDetailsError,
  selectEmployeeDetailsFetched,
  selectEditEmployee,
  selectEditEmployeeFetching,
  selectEditEmployeeFetched,
  selectEditEmployeeError,
  selectUpdatePasswordFetching,
  selectUpdatePasswordFetched,
  selectUpdatePasswordError,
} from './selectors';

export function withEmployeesDetailsHoc(Component) {
  function withEmployeesDetails(props) {
    return <Component {...props} />;
  }

  withEmployeesDetails.displayName = `withEmployeesDetails(${getDisplayName(Component)})`;

  return withEmployeesDetails;
}

const mapStateToProps = createStructuredSelector({
  employee: selectEmployeeDetails,
  employeeDetailFetching: selectEmployeeDetailsFetching,
  employeeDetailError: selectEmployeeDetailsError,
  employeeDetailFetched: selectEmployeeDetailsFetched,
  employeeEditObject: selectEditEmployee,
  employeeEditFetching: selectEditEmployeeFetching,
  employeeEditFetched: selectEditEmployeeFetched,
  employeeEditError: selectEditEmployeeError,
  updatePasswordFetching: selectUpdatePasswordFetching,
  updatePasswordFetched: selectUpdatePasswordFetched,
  updatePasswordError: selectUpdatePasswordError,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadEmployeeDetail: ({ storeUuid, employeeUuid }) => dispatch(getEmployeeDetail({ storeUuid, employeeUuid })),
    editEmployee: ({ storeUuid, employeeUuid, employee }) =>
      dispatch(editEmployee({ storeUuid, employeeUuid, employee })),
    updatePassport: ({ storeUuid, employeeUuid, password }) =>
      dispatch(updatePassport({ storeUuid, employeeUuid, password })),
    resetEmployeeDetail: () => dispatch(getEmployeeDetail.reset()),
    resetEditEmployee: () => dispatch(editEmployee.reset()),
    resetUpdatePassport: () => dispatch(updatePassport.reset()),
  };
}

export const withEmployeesDetailsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withEmployeesDetailsState, withEmployeesDetailsHoc);
