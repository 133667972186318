import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Clock = forwardRef(function Clock(props, ref) {
  const { color = '#000', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <circle cx="12" cy="12" r="8.5" stroke={color} />
      <path d="M12 6V12.5L15.5 15.5" stroke={color} />
    </svg>
  );
});

Clock.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Clock;
