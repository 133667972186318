import produce from 'immer';

import baseState from 'utils/baseState';

import { getBrands, updateBrands } from './actions';

export const initialState = {
  getBrands: {
    ...baseState,
    fetching: true,
    brands: [],
  },

  updateBrands: {
    ...baseState,
  },
};

const brandsProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getBrands.REQUEST:
        draft.getBrands = { ...initialState.getBrands, fetching: true };
        break;

      case getBrands.SUCCESS: {
        draft.getBrands.fetching = false;
        draft.getBrands.fetched = true;
        draft.getBrands.brands = payload;
        break;
      }

      case getBrands.FAILURE:
        draft.getBrands.error = payload;
        draft.getBrands.fetching = false;
        draft.getBrands.fetched = false;
        break;

      case getBrands.RESET:
        draft.getBrands = { ...initialState.getBrands };
        break;

      case updateBrands.REQUEST:
        draft.updateBrands = { ...initialState.updateBrands, fetching: true };
        break;

      case updateBrands.SUCCESS: {
        draft.updateBrands.fetching = false;
        draft.updateBrands.fetched = true;
        break;
      }

      case updateBrands.FAILURE:
        draft.updateBrands.error = payload;
        draft.updateBrands.fetching = false;
        draft.updateBrands.fetched = false;
        break;

      case updateBrands.RESET:
        draft.updateBrands = { ...initialState.updateBrands };
        break;

      default:
        return draft;
    }
  });

export default brandsProviderReducer;
