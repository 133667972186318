import { useMutation, useQuery } from 'react-query';
import { getCookieUser, getUserCurrentStore } from 'utils/auth';

import { getUser, postValidateEmail } from './services';

export function useValidateEmail() {
  return useMutation((email: string) => postValidateEmail({ email }));
}

export function useUser(options?: { storeUuid?: string; userUuid?: string }) {
  const storeUuid = options?.storeUuid || getUserCurrentStore()?.uuid;
  const userUuid = options?.userUuid || getCookieUser()?.uuid;

  return useQuery({
    queryKey: ['user', userUuid],
    queryFn: () => getUser({ userUuid, storeUuid }),
    retry: 1,
  });
}
