import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectProductDomain = (state) => state.productDetails || initialState;

const selectProduct = createSelector(selectProductDomain, (subState) => subState.getProduct.product);
const selectProductState = createSelector(selectProductDomain, ({ getProduct: { error, fetching, fetched } }) => ({
  error,
  fetching,
  fetched,
}));

const selectProductEdit = createSelector(selectProductDomain, (subState) => subState.editProduct.product);
const selectProductEditState = createSelector(selectProductDomain, ({ editProduct: { error, fetching, fetched } }) => ({
  error,
  fetching,
  fetched,
}));

const selectUpdateProductPriceState = createSelector(
  selectProductDomain,
  ({ changeProductPrice: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

export {
  selectProductDomain,
  selectProduct,
  selectProductState,
  selectProductEdit,
  selectProductEditState,
  selectUpdateProductPriceState,
};
