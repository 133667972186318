export default function arrayToObjectUuid(array) {
  let valuesObject = {};

  if (array?.length > 0) {
    valuesObject = array?.reduce((obj, item) => {
      return {
        ...obj,
        [item?.uuid]: {
          ...item,
        },
      };
    }, {});

    return valuesObject;
  }

  return valuesObject;
}
