/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import camelCase from 'lodash/camelCase';

import { withTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';

import { typeErrorsPublishMenus, orderPublisMenusErrors } from 'utils/menuMaker';

import useStyles from './styles';

function DetailsPublishMenusErrors({ data, t }) {
  const [openItem, setOpenItem] = useState([]);
  const errors = data?.messages ? data?.messages : [];
  const validations = data?.validations ? orderPublisMenusErrors(data?.validations) : {};
  const classes = useStyles();

  const handleOpenDetails = (type) => {
    const openItemTmp = [...openItem];
    if (openItemTmp.includes(type)) {
      setOpenItem(openItemTmp?.filter((item) => item !== type));
    } else {
      openItemTmp.push(type);
      setOpenItem(openItemTmp);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <List className={classes.root}>
      {data?.messages && (
        <>
          <ListItem button onClick={() => handleOpenDetails(typeErrorsPublishMenus.GENERAL)}>
            <ListItemText
              primary={t(`menuMaker:publishMenu.fullMessage.error.types.${camelCase(typeErrorsPublishMenus.GENERAL)}`)}
            />
            {openItem.includes(typeErrorsPublishMenus.GENERAL) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openItem.includes(typeErrorsPublishMenus.GENERAL)} timeout="auto" unmountOnExit>
            <List className={classes.subList}>
              {errors.map((error, index) => (
                <ListItem key={`${index}-${error?.code}`} className={classes.detailsError}>
                  <ListItemText primary={error?.message} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      )}

      {data?.validations && (
        <>
          {validations?.MENU?.length > 0 && (
            <>
              <ListItem button onClick={() => handleOpenDetails(typeErrorsPublishMenus.MENU)}>
                <ListItemText
                  primary={t(`menuMaker:publishMenu.fullMessage.error.types.${camelCase(typeErrorsPublishMenus.MENU)}`)}
                />
                {openItem.includes(typeErrorsPublishMenus.MENU) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openItem.includes(typeErrorsPublishMenus.MENU)} timeout="auto" unmountOnExit>
                <List className={classes.subList} subheader={<li />}>
                  {validations?.MENU?.map((error, index) => (
                    <li key={`${index}-${error?.providerName}`} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader>
                          <Box component="span" fontWeight="fontWeightMedium">
                            {t(`menuMaker:chanels.${camelCase(error?.providerName)}`)}:
                          </Box>{' '}
                          {error?.name}
                        </ListSubheader>

                        {error?.errors.map((detail, indexD) => (
                          <ListItem key={`${indexD}-${detail?.message}`} className={classes.detailsError}>
                            <ListItemText primary={detail?.message} />
                          </ListItem>
                        ))}
                      </ul>
                    </li>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          {validations?.CATEGORY?.length > 0 && (
            <>
              <ListItem button onClick={() => handleOpenDetails(typeErrorsPublishMenus.CATEGORY)}>
                <ListItemText
                  primary={t(
                    `menuMaker:publishMenu.fullMessage.error.types.${camelCase(typeErrorsPublishMenus.CATEGORY)}`,
                  )}
                />
                {openItem.includes(typeErrorsPublishMenus.CATEGORY) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openItem.includes(typeErrorsPublishMenus.CATEGORY)} timeout="auto" unmountOnExit>
                <List className={classes.subList} subheader={<li />}>
                  {validations?.CATEGORY?.map((error, index) => (
                    <li key={`${index}-${error?.providerName}`} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader>
                          <Box component="span" fontWeight="fontWeightMedium">
                            {t(`menuMaker:chanels.${camelCase(error?.providerName)}`)}:
                          </Box>{' '}
                          {error?.name}
                        </ListSubheader>
                        {error?.errors.map((detail, indexD) => (
                          <ListItem key={`${indexD}-${detail?.message}`} className={classes.detailsError}>
                            <ListItemText primary={detail?.message} />
                          </ListItem>
                        ))}
                      </ul>
                    </li>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          {validations?.PRODUCT?.length > 0 && (
            <>
              <ListItem button onClick={() => handleOpenDetails(typeErrorsPublishMenus.PRODUCT)}>
                <ListItemText
                  primary={t(
                    `menuMaker:publishMenu.fullMessage.error.types.${camelCase(typeErrorsPublishMenus.PRODUCT)}`,
                  )}
                />
                {openItem.includes(typeErrorsPublishMenus.PRODUCT) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openItem.includes(typeErrorsPublishMenus.PRODUCT)} timeout="auto" unmountOnExit>
                <List className={classes.subList} subheader={<li />}>
                  {validations?.PRODUCT?.map((error, index) => (
                    <li key={`${index}-${error?.providerName}`} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader>
                          <Box component="span" fontWeight="fontWeightMedium">
                            {t(`menuMaker:chanels.${camelCase(error?.providerName)}`)}:
                          </Box>{' '}
                          {error?.name}
                        </ListSubheader>
                        {error?.errors.map((detail, indexD) => (
                          <ListItem key={`${indexD}-${detail?.message}`}>
                            <ListItemText primary={detail?.message} />
                          </ListItem>
                        ))}
                      </ul>
                    </li>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          {validations?.MODIFIER_GROUP?.length > 0 && (
            <>
              <ListItem button onClick={() => handleOpenDetails(typeErrorsPublishMenus.MODIFIER_GROUP)}>
                <ListItemText
                  primary={t(
                    `menuMaker:publishMenu.fullMessage.error.types.${camelCase(typeErrorsPublishMenus.MODIFIER_GROUP)}`,
                  )}
                />
                {openItem.includes(typeErrorsPublishMenus.MODIFIER_GROUP) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openItem.includes(typeErrorsPublishMenus.MODIFIER_GROUP)} timeout="auto" unmountOnExit>
                <List className={classes.subList} subheader={<li />}>
                  {validations?.MODIFIER_GROUP?.map((error, index) => (
                    <li key={`${index}-${error?.providerName}`} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader>
                          <Box component="span" fontWeight="fontWeightMedium">
                            {t(`menuMaker:chanels.${camelCase(error?.providerName)}`)}:
                          </Box>{' '}
                          {error?.name}
                        </ListSubheader>
                        {error?.errors.map((detail, indexD) => (
                          <ListItem key={`${indexD}-${detail?.message}`}>
                            <ListItemText primary={detail?.message} />
                          </ListItem>
                        ))}
                      </ul>
                    </li>
                  ))}
                </List>
              </Collapse>
            </>
          )}
        </>
      )}
    </List>
  );
}

DetailsPublishMenusErrors.propTypes = {
  data: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation('menuMaker')(DetailsPublishMenusErrors);
