import { useCallback } from 'react';

import { useSnackbar } from 'notistack';

const AUTO_HIDE_DURATION = 3000;

function useNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleEnqueueNotification = useCallback(
    async (message, options, config = {}) => {
      const defaultOptions = { preventDuplicate: true, autoHideDuration: AUTO_HIDE_DURATION, ...config };

      enqueueSnackbar({ message, options }, defaultOptions);
    },
    [enqueueSnackbar],
  );

  const closeAllSnackbar = () => {
    closeSnackbar();
  };

  return {
    setNotification: handleEnqueueNotification,
    closeAllSnackbar: useCallback(closeAllSnackbar, [closeSnackbar]),
  };
}

export default useNotification;
