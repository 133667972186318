import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: ({ openDrawer }) => (openDrawer ? 'repeat(3, 1fr)' : 'repeat(5, 1fr)'),
    gap: theme.spacing(3),
    alignItems: 'end',
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: () => 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: () => 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: () => '1fr',
    },
  },

  contentSelected: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  wrapperMultiSelect: {
    overflow: 'hidden',
  },

  wrapperActions: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    gridColumn: ({ openDrawer }) => (openDrawer ? '3' : 'auto'),

    [theme.breakpoints.down('md')]: {
      gridColumn: () => 3,
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: () => 2,
    },
    [theme.breakpoints.down('xs')]: {
      gridColumn: () => 1,
    },
  },

  middlePrimaryButton: {
    alignSelf: 'baseline',
  },

  searchProducts: {
    gridRow: ({ openDrawer }) => (openDrawer ? 2 : 'auto'),

    [theme.breakpoints.down('md')]: {
      gridRow: () => 2,
    },
    [theme.breakpoints.down('xs')]: {
      gridRow: () => 4,
    },
  },
}));

export default useStyles;
