import { getUserCurrentStore } from 'utils/auth';

import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getMovementReasonsRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/cash-movement-reasons/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function getMovementReasonDetailRequest({ reasonUuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/cash-movement-reasons/${reasonUuid}/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function createMovementReasonRequest({ movementType, name }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/cash-movement-reasons/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      movement_type: movementType,
      name,
    }),
    ...options,
  });
}

export function deleteMovementReasonRequest({ reasonUuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/cash-movement-reasons/${reasonUuid}/?store_uuid=${storeUuid}`, {
    method: 'DELETE',
    ...options,
  });
}

export function editMovementReasonRequest({ movementType, name, reasonUuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/cash-movement-reasons/${reasonUuid}/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: JSON.stringify({
      movement_type: movementType,
      name,
    }),
    ...options,
  });
}
