import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}
const Settings = forwardRef(function Settings(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FFF', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M19.0207 10.1876L19.166 10.75H19.7469H21.25V13.7494H19.5926H19.0484L18.8796 14.2667C18.7168 14.7655 18.5035 15.2383 18.2432 15.6809L17.9491 16.1811L18.3594 16.5915L19.4242 17.6563L17.3031 19.7773L16.1338 18.608L15.7485 18.2227L15.263 18.47C14.795 18.7084 14.3096 18.8923 13.8128 19.0207L13.2506 19.1661V19.7469V21.25H10.2506V19.5943V19.0509L9.73418 18.8816C9.23516 18.718 8.76155 18.5046 8.31906 18.2444L7.81885 17.9502L7.40853 18.3605L6.34371 19.4253L4.22237 17.304L5.39204 16.1343L5.77732 15.7491L5.53 15.2636C5.29163 14.7956 5.10775 14.3103 4.97931 13.813L4.83403 13.2506H4.25314H2.75V10.25H4.40514H4.94929L5.11813 9.73271C5.28052 9.23514 5.49273 8.7635 5.75171 8.32292L6.06934 7.78257L5.59397 7.37404L4.58045 6.50304L6.53556 4.22764L7.92383 5.42032L8.30294 5.74602L8.74948 5.5215C9.21331 5.28829 9.69426 5.10675 10.1872 4.97925L10.7494 4.83384V4.25314V2.75H13.7494V4.40629V4.95025L14.2665 5.1192C14.765 5.28209 15.2382 5.49533 15.6804 5.75587L16.1808 6.05074L16.5915 5.64004L17.6563 4.57523L19.7776 6.69657L18.608 7.86624L18.2227 8.25151L18.47 8.73701C18.7084 9.20494 18.8922 9.6903 19.0207 10.1876Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle cx="12" cy="12" r="3.25" stroke={color} strokeWidth="1.5" />
    </svg>
  );
});

export default Settings;
