import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { createRoleRequest, updateRoleRequest } from 'utils/api/baas/workforce/roles';

import { saveRole } from '../actions';

export function* handleSaveRoleRequest(action) {
  const role = action.payload;

  try {
    yield put(saveRole.request());
    let savedRole;
    if (role?.uuid) {
      // Update
      const roleUuid = role.uuid;
      delete role.uuid;
      const { data } = yield call(baasRequest, updateRoleRequest, roleUuid, role);
      savedRole = data;
    } else {
      // Create
      const { data } = yield call(baasRequest, createRoleRequest, role);
      savedRole = data;
    }

    yield put(saveRole.success(savedRole));
  } catch (err) {
    yield put(saveRole.failure(err));
  }
}
