import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_INVOICES_ORDERS_EDIT,
  GET_INVOICES_ORDERS_EDIT_BY_PAGINATION,
  GET_INVOICES_ORDERS_PRE_EDIT,
  UPDATE_INVOICES_ORDERS,
  RESET_EDITED_INVOICES_ORDERS,
  GENERATE_INVOICING,
  GET_INVOICES_ORDER_DETAILS_EDIT,
  UPDATE_ORDER_REMOVE_ITEM,
  UPDATE_ORDER_DISCOUNT,
  REVERT_CHANGES_ORDER_EDITED,
  SAVE_CHANGES_ORDER_EDITED,
} from './constants';

export const getInvoicesOrdersEdit = extendRoutine(createRoutine(GET_INVOICES_ORDERS_EDIT), 'RESET');
export const getInvoicesOrdersEditByPagination = extendRoutine(
  createRoutine(GET_INVOICES_ORDERS_EDIT_BY_PAGINATION),
  'RESET',
);
export const getInvoicesOrdersPreEdit = extendRoutine(createRoutine(GET_INVOICES_ORDERS_PRE_EDIT), 'RESET');
export const updateInvoicesOrders = extendRoutine(createRoutine(UPDATE_INVOICES_ORDERS), 'RESET');
export const resetEditedInvoicesOrders = extendRoutine(createRoutine(RESET_EDITED_INVOICES_ORDERS), 'RESET');
export const generateInvoicing = extendRoutine(createRoutine(GENERATE_INVOICING), 'RESET');

export const getInvoicesOrderDetailsEdit = extendRoutine(createRoutine(GET_INVOICES_ORDER_DETAILS_EDIT), 'RESET');
export const updateOrderRemoveItem = extendRoutine(createRoutine(UPDATE_ORDER_REMOVE_ITEM), 'RESET');
export const updateOrderDiscount = extendRoutine(createRoutine(UPDATE_ORDER_DISCOUNT), 'RESET');
export const revertChangesOrderEdited = extendRoutine(createRoutine(REVERT_CHANGES_ORDER_EDITED), 'RESET');
export const saveChangesOrderEdited = extendRoutine(createRoutine(SAVE_CHANGES_ORDER_EDITED), 'RESET');
