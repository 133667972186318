/* eslint-disable no-unused-vars */
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { getBrands } from './actions';
import saga from './saga';
import reducer from './reducer';

export function BrandsProvider({ children }) {
  useInjectSaga({ key: 'brandsProvider', saga });
  useInjectReducer({ key: 'brandsProvider', reducer });

  return React.Children.only(children);
}

const mapStateToProps = createStructuredSelector({});

export function mapDispatchToProps(dispatch) {
  return {
    loadBrands: () => dispatch(getBrands()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

BrandsProvider.propTypes = {
  children: PropTypes.element,
};

export { default as useBrands } from './useBrands';
export default compose(withConnect, memo)(BrandsProvider);
