import { forwardRef, Ref, SVGProps } from 'react';

const Ceal = forwardRef(function Ceal(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg ref={ref} fill="none" height="40" viewBox="0 0 32 40" width="32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_687_24784)">
        <path d="M0 5.8588L24.0864 4.9386L30.6742 38.4667L6.17624 40L0 5.8588Z" fill="black" />
        <path d="M0.592773 5.21985L24.6792 4.30078L31.267 37.8278L6.76787 39.361L0.592773 5.21985Z" fill="white" />
        <path
          d="M6.72112 39.4208L6.70861 39.3711L0.524414 5.16666L24.7257 4.24194L31.3351 37.8796L6.72112 39.4208ZM0.659718 5.27392L6.81549 39.3011L31.1987 37.7757L24.6324 4.35485L0.659718 5.27392Z"
          fill="black"
        />
        <path
          d="M19.3818 4.61803C19.1999 3.65266 18.6552 3.0486 17.7024 2.80472C16.6484 2.536 15.4614 3.45281 14.5438 3.01924C13.6001 2.57326 14.1925 1.91839 13.8184 1.16529C13.2954 0.112987 11.6899 -0.323968 10.6541 0.262025C9.5205 0.903344 9.8923 2.08324 10.1277 3.09828C10.1579 3.19814 10.1653 3.30346 10.1493 3.40652C10.0947 3.62669 9.84682 3.7317 9.62852 3.79267C8.65979 4.06478 7.1271 3.69331 6.49037 4.63383C6.18793 5.07869 6.32323 7.50057 6.97929 7.45654L19.3045 6.61989C19.508 6.60408 19.4102 4.76819 19.3818 4.61803ZM11.9969 2.7223C11.8652 2.73343 11.7331 2.70552 11.6173 2.64211C11.5015 2.57869 11.4073 2.48261 11.3465 2.36601C11.2857 2.24941 11.2611 2.11754 11.2758 1.98706C11.2905 1.85657 11.3438 1.73334 11.429 1.63295C11.5142 1.53255 11.6275 1.4595 11.7545 1.42302C11.8815 1.38655 12.0165 1.38829 12.1426 1.42802C12.2686 1.46776 12.3799 1.54371 12.4624 1.64626C12.545 1.74882 12.5951 1.87338 12.6063 2.0042C12.614 2.09112 12.6043 2.17868 12.5777 2.26187C12.5512 2.34505 12.5084 2.42221 12.4518 2.48893C12.3952 2.55565 12.3259 2.6106 12.2478 2.65065C12.1697 2.6907 12.0845 2.71505 11.9969 2.7223Z"
          fill="black"
        />
        <path d="M4.70996 11.5574L5.63662 16.1573L11.4001 15.8513L10.3711 11.353L4.70996 11.5574Z" fill="#F8B8B8" />
        <path d="M6.25391 19.0193L7.18057 23.6192L12.9441 23.3121L11.9151 18.8149L6.25391 19.0193Z" fill="#F8B8B8" />
        <path
          d="M8.40173 15.4008L6.26074 13.9465L6.4529 13.6665L8.22322 14.8701L9.69451 10.583L10.0174 10.6925L8.40173 15.4008Z"
          fill="#F04E4A"
        />
        <path
          d="M9.76501 22.6244L7.62402 21.1702L7.81618 20.8902L9.5865 22.0938L11.0589 17.8066L11.3818 17.9162L9.76501 22.6244Z"
          fill="#F04E4A"
        />
        <path d="M22.6105 12.2055L12.2139 12.8188L12.2341 13.157L22.6308 12.5436L22.6105 12.2055Z" fill="#A3A3AF" />
        <path d="M22.814 13.7406L12.3145 14.4551L12.3378 14.793L22.8373 14.0786L22.814 13.7406Z" fill="#A3A3AF" />
        <path d="M24.5657 19.9217L14.169 20.5351L14.1893 20.8732L24.5859 20.2598L24.5657 19.9217Z" fill="#A3A3AF" />
        <path d="M24.77 21.4584L14.2705 22.1729L14.2938 22.5108L24.7934 21.7963L24.77 21.4584Z" fill="#A3A3AF" />
        <path d="M21.7864 35.6113L9.8457 36.4321L9.86926 36.77L21.81 35.9492L21.7864 35.6113Z" fill="#A3A3AF" />
      </g>
      <defs>
        <clipPath id="clip0_687_24784">
          <rect fill="white" height="40" width="32" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default Ceal;
