import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}
const Inventories = forwardRef(function Inventories(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FFF', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <rect height="12.5" stroke={color} strokeLinejoin="round" strokeWidth="1.5" width="15" x="4.69995" y="8.69922" />
      <path
        d="M21.2 8.69922H2.69995L12.2 2.69922L21.2 8.69922Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <rect height="7.34" stroke={color} strokeWidth="1.5" width="7" x="8.69995" y="13.8555" />
      <path d="M10.2 17.5L14.2 17.5" stroke={color} strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  );
});

export default Inventories;
