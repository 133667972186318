import { forwardRef, Ref, SVGProps } from 'react';

const Store = forwardRef(function Store(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg ref={ref} fill="none" height="40" viewBox="0 0 51 40" width="51" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.3739 39.8863L33.4971 38.2437L24.7441 2.39542L5.66321 4.03415L14.3739 39.8863Z" fill="white" />
      <path
        d="M14.2992 39.9903L14.2791 39.9091L5.54175 3.94649L24.8188 2.29086L24.839 2.37205L33.6185 38.3314L33.5049 38.3411L14.2992 39.9903ZM5.78467 4.12121L14.4486 39.7798L33.375 38.156L24.6714 2.49741L5.78467 4.12121Z"
        fill="black"
      />
      <path d="M5.66314 4.03418L3.34045 22.5338L4.0835 38.3645L14.5 40L5.66314 4.03418Z" fill="#B0B0B0" />
      <path
        clipRule="evenodd"
        d="M3.54104 22.5416L4.27564 38.1922L14.234 39.7558L5.72689 5.13186L3.54104 22.5416ZM3.34045 22.5338L4.0835 38.3645L14.5 40L5.66314 4.03418L3.34045 22.5338Z"
        fill="black"
        fillRule="evenodd"
      />
      <path d="M5.66317 4.03418L0.107178 12.6221L3.36712 22.4805L5.66317 4.03418Z" fill="black" />
      <path
        d="M3.41063 22.9222L0 12.6085L0.0253313 12.5695L5.81255 3.62433L3.41063 22.9222ZM0.214341 12.6358L3.32424 22.0395L5.51442 4.44402L0.214341 12.6358Z"
        fill="black"
      />
      <path d="M24.1 3.55924L6.89343 5.03134L6.91004 5.22549L24.1166 3.75339L24.1 3.55924Z" fill="black" />
      <path
        d="M14.2341 3L18.3611 18.6268L17.557 17.357L17.053 18.7736L16.0956 17.5213L15.563 18.9412L14.8024 17.6668L14.2977 19.0828L13.4248 17.8214L12.8077 19.2503L11.9627 17.9857L11.5581 19.3906L8 3.5352L14.2341 3Z"
        fill="black"
      />
      <path
        d="M43.3279 25.3693C44.9421 25.4292 46.5752 25.1874 48.0158 24.5433L49.6591 21.1607L37.0227 16.3755L35.1389 23.8415C37.8787 23.7679 40.5912 25.268 43.3279 25.3693Z"
        fill="#F04E4A"
      />
      <path
        d="M33.2067 31.4996L32 36.2809L40.7101 39.585L43.9786 32.8578C43.5262 32.8901 43.0723 32.8978 42.6191 32.8807C39.4748 32.7644 36.3637 30.8035 33.2067 31.4996Z"
        fill="#F04E4A"
      />
      <path
        d="M43.3279 25.3693C40.5912 25.268 37.8787 23.7679 35.1389 23.8415L33.2067 31.4996C36.3637 30.8035 39.4748 32.7644 42.6177 32.8807C43.071 32.8978 43.5248 32.8901 43.9773 32.8578L48.0158 24.5433C46.5752 25.1874 44.9421 25.4292 43.3279 25.3693Z"
        fill="white"
      />
      <path
        d="M50.8767 20.6572C50.8741 20.6486 50.8696 20.6408 50.8635 20.6342C50.8574 20.6276 50.8498 20.6226 50.8415 20.6193L36.7125 15.2415C36.7038 15.2382 36.6944 15.2369 36.6851 15.2379C36.6758 15.2389 36.6669 15.2421 36.659 15.2472C36.6512 15.2523 36.6447 15.2592 36.64 15.2674C36.6354 15.2755 36.6328 15.2846 36.6324 15.2939L36.5883 16.3143C36.5878 16.3266 36.5913 16.3387 36.5981 16.349C36.6049 16.3592 36.6147 16.3671 36.6262 16.3715L50.1842 21.593C50.1901 21.5951 50.1963 21.5964 50.2027 21.5969C50.2125 21.5973 50.2223 21.5953 50.2311 21.591C50.2399 21.5867 50.2476 21.5802 50.2533 21.5722L50.8666 20.7088C50.8722 20.7016 50.876 20.6932 50.8778 20.6842C50.8796 20.6752 50.8792 20.666 50.8767 20.6572Z"
        fill="black"
      />
      <path d="M37.2166 15.4331L38.6184 14L49.7793 18.2284L49.8397 20.2378L37.2166 15.4331Z" fill="black" />
      <path d="M37.9363 15.4977L37.9208 15.5389L49.0831 19.7435L49.0986 19.7023L37.9363 15.4977Z" fill="white" />
      <path
        d="M26.6375 11.5679C29.8319 11.5679 32.4215 8.97837 32.4215 5.78397C32.4215 2.58957 29.8319 0 26.6375 0C23.4431 0 20.8535 2.58957 20.8535 5.78397C20.8535 8.97837 23.4431 11.5679 26.6375 11.5679Z"
        fill="#F04E4A"
      />
      <path
        d="M23.5626 5.21045V4.95843C23.5665 4.93505 23.5711 4.91167 23.5737 4.88828C23.6295 4.38231 23.8335 3.95103 24.2446 3.64186C24.4334 3.49464 24.6544 3.39433 24.8895 3.34916C25.1246 3.30399 25.367 3.31527 25.5969 3.38205C25.9555 3.47753 26.2295 3.70031 26.4491 3.9926C26.514 4.08093 26.5751 4.17576 26.6355 4.26475C26.7193 4.14718 26.7959 4.02507 26.8875 3.9153C27.172 3.57236 27.5292 3.35737 27.9813 3.33074C28.3319 3.30929 28.6799 3.4039 28.9714 3.59995C29.2629 3.79601 29.4817 4.08259 29.5941 4.41543C29.8168 5.06885 29.7207 5.6794 29.344 6.25163C29.1299 6.57147 28.8751 6.86208 28.586 7.11614C27.991 7.64614 27.3876 8.16641 26.7868 8.68927C26.681 8.7815 26.592 8.78085 26.4887 8.68927C26.0124 8.27791 25.535 7.86655 25.0565 7.45518C24.6873 7.14718 24.3508 6.80198 24.0524 6.42505C23.853 6.17629 23.7077 5.88872 23.6256 5.58067C23.5964 5.45596 23.5828 5.33256 23.5626 5.21045Z"
        fill="white"
      />
      <path
        d="M36.3765 36.1437C36.3093 36.324 36.1493 36.4365 35.9878 36.4172L28.8118 36.5688C25.899 36.2151 23.1331 35.3826 20.3234 34.5631L15.6913 32.1926C15.5309 32.1462 15.4277 31.9727 15.4423 31.7758L15.6241 29.3574C15.6434 29.0995 15.8528 28.9067 16.0664 28.9505L37.0058 33.2366C37.2241 33.2814 37.3403 33.5492 37.2486 33.7967L36.3765 36.1437Z"
        fill="#F04E4A"
      />
      <path
        d="M15.0682 32.0749L15.2245 34.82C15.2271 34.8661 15.2416 34.9109 15.2666 34.9497C15.2917 34.9886 15.3263 35.0204 15.3672 35.0419C16.6567 35.7192 27.0034 40.9811 35.1537 39.1322C35.1906 39.1238 35.2253 39.1076 35.2555 39.0848C35.2857 39.062 35.3106 39.033 35.3288 38.9998L36.6682 36.5799C36.691 36.5387 36.7025 36.4923 36.7017 36.4453C36.7009 36.3983 36.6877 36.3523 36.6634 36.3121C36.6392 36.2718 36.6048 36.2386 36.5636 36.2158C36.5225 36.193 36.4761 36.1815 36.4291 36.1823C34.4358 36.2214 26.2642 36.0818 15.4334 31.8123C15.3918 31.796 15.3467 31.7904 15.3023 31.7959C15.2579 31.8015 15.2157 31.8181 15.1793 31.8442C15.143 31.8703 15.1138 31.9051 15.0944 31.9454C15.075 31.9857 15.066 32.0302 15.0682 32.0749Z"
        fill="#F59397"
      />
      <path
        d="M18.0888 27.6825C17.4307 27.4605 16.7487 27.3167 16.057 27.2542C15.4792 27.2021 14.8858 27.2141 14.3528 27.3584C13.9574 27.4626 12.9894 27.8471 13.0858 28.2707C13.2124 28.8266 15.0708 29.0339 15.6214 29.1391C17.0104 29.4038 18.1883 29.7518 18.6072 31.0298C18.6072 31.0298 19.2168 32.2624 21.5179 31.8561C22.8496 31.6216 24.623 32.1259 25.5816 32.9908C26.6033 33.9119 27.9339 33.9806 29.265 33.6587C29.7755 33.5347 30.272 33.4597 30.8326 33.5237C32.0564 33.6623 32.7514 34.5584 33.8314 34.9538C34.8327 35.3185 35.7267 34.995 36.3321 34.3933C36.3321 34.3933 36.9391 33.8863 38.4645 34.4495C38.7735 34.5636 39.5758 34.4219 39.7998 34.2724C40.7996 33.6066 37.7581 32.0989 37.2074 31.7508"
        fill="#EFEFEF"
      />
      <path
        d="M16.0044 27.4756C16.0044 27.4756 16.6915 20.6247 28.0511 22.7868C28.0511 22.7868 38.7511 24.4044 37.6497 31.9415C37.6122 32.1979 35.2948 32.3099 35.1125 32.3213C32.4987 32.4849 29.8615 32.1744 27.2977 31.6696C25.5571 31.3262 23.8447 30.9267 22.1619 30.3593C21.1429 30.018 20.142 29.6251 19.1631 29.1819C18.8015 29.0152 15.9684 27.8195 16.0044 27.4756Z"
        fill="#F59397"
      />
      <path
        d="M24.0854 25.2938C24.0798 25.5405 24.1291 25.7853 24.2297 26.0106C24.2428 26.0329 24.2642 26.049 24.2892 26.0555C24.3142 26.0621 24.3407 26.0585 24.3631 26.0456C24.3851 26.0322 24.401 26.0109 24.4077 25.986C24.4143 25.9611 24.411 25.9347 24.3985 25.9122C24.3141 25.7173 24.2739 25.5061 24.2808 25.2938C24.2817 25.2803 24.2799 25.2669 24.2755 25.2542C24.271 25.2415 24.264 25.2298 24.2548 25.2199C24.2456 25.2101 24.2345 25.2022 24.2222 25.1968C24.2098 25.1915 24.1965 25.1887 24.1831 25.1887C24.1696 25.1887 24.1563 25.1915 24.1439 25.1968C24.1316 25.2022 24.1205 25.2101 24.1113 25.2199C24.1022 25.2298 24.0951 25.2415 24.0907 25.2542C24.0862 25.2669 24.0844 25.2803 24.0854 25.2938Z"
        fill="#F8B8B8"
      />
      <path
        d="M22.7413 27.6709C22.7599 27.891 22.7289 28.1125 22.6506 28.3191C22.6439 28.3441 22.6473 28.3707 22.66 28.3932C22.6728 28.4158 22.6939 28.4323 22.7189 28.4394C22.7438 28.4456 22.7701 28.4419 22.7924 28.4292C22.8147 28.4165 22.8313 28.3957 22.8387 28.3711C22.9234 28.148 22.9577 27.9089 22.9392 27.6709C22.9374 27.6463 22.9264 27.6232 22.9082 27.6063C22.8901 27.5895 22.8663 27.5801 22.8415 27.5801C22.8168 27.5801 22.793 27.5895 22.7749 27.6063C22.7567 27.6232 22.7457 27.6463 22.7439 27.6709H22.7413Z"
        fill="#F8B8B8"
      />
      <path
        d="M26.5033 28.2107C26.5214 28.4308 26.4903 28.6522 26.4121 28.8588C26.4053 28.8838 26.4087 28.9105 26.4215 28.933C26.4343 28.9555 26.4554 28.9721 26.4803 28.9792C26.5053 28.9855 26.5318 28.9819 26.5542 28.9692C26.5766 28.9565 26.5933 28.9356 26.6007 28.9109C26.684 28.6874 26.7174 28.4485 26.6986 28.2107C26.6978 28.185 26.6872 28.1606 26.669 28.1425C26.6508 28.1244 26.6264 28.1139 26.6007 28.1133C26.5749 28.1134 26.5502 28.1237 26.5319 28.142C26.5137 28.1602 26.5034 28.1849 26.5033 28.2107Z"
        fill="#F8B8B8"
      />
      <path
        d="M30.6951 28.3082C30.656 28.3468 30.6154 28.3827 30.5727 28.4176L30.5659 28.4233L30.5503 28.4348L30.519 28.4572C30.4951 28.4739 30.4711 28.49 30.4466 28.5057C30.3997 28.5343 30.3518 28.5609 30.3028 28.5849L30.2674 28.601L30.2455 28.6109C30.2403 28.6109 30.2325 28.6166 30.2424 28.6109C30.2153 28.6218 30.1903 28.6323 30.1606 28.6416C30.1367 28.6495 30.1167 28.6663 30.1049 28.6884C30.093 28.7106 30.0901 28.7365 30.0968 28.7608C30.1035 28.785 30.1193 28.8057 30.1408 28.8187C30.1624 28.8316 30.1881 28.8358 30.2127 28.8302C30.4456 28.7491 30.6577 28.6175 30.8337 28.4447C30.8516 28.4262 30.8615 28.4014 30.8615 28.3757C30.8615 28.3499 30.8516 28.3252 30.8337 28.3066C30.815 28.2889 30.7902 28.279 30.7644 28.279C30.7386 28.279 30.7138 28.2889 30.6951 28.3066V28.3082Z"
        fill="#F8B8B8"
      />
      <path
        d="M34.1274 30.0019C34.0883 30.0404 34.0471 30.0764 34.0049 30.1113C33.9919 30.1217 34.0049 30.1113 33.9976 30.1165L33.9825 30.1285L33.9513 30.1509C33.9273 30.1675 33.9033 30.1837 33.8788 30.1993C33.832 30.228 33.784 30.2546 33.7345 30.2785L33.6996 30.2947L33.6777 30.3046H33.6741C33.6475 30.3155 33.622 30.3259 33.5928 30.3353C33.5799 30.3382 33.5677 30.3437 33.5569 30.3515C33.5462 30.3593 33.5371 30.3691 33.5303 30.3805C33.5235 30.3919 33.519 30.4045 33.5173 30.4177C33.5155 30.4308 33.5164 30.4442 33.5199 30.4569C33.5234 30.4697 33.5295 30.4817 33.5378 30.492C33.5461 30.5024 33.5564 30.511 33.5681 30.5172C33.5798 30.5235 33.5926 30.5273 33.6058 30.5284C33.619 30.5296 33.6323 30.528 33.6449 30.5239C33.8777 30.4428 34.0896 30.3112 34.2654 30.1384C34.2835 30.12 34.2937 30.0952 34.2937 30.0693C34.2937 30.0435 34.2835 30.0187 34.2654 30.0003C34.2469 29.9825 34.2221 29.9725 34.1964 29.9725C34.1706 29.9725 34.1459 29.9825 34.1274 30.0003V30.0019Z"
        fill="#F8B8B8"
      />
      <path
        d="M33.3589 27.4069C33.1726 27.5549 33.0212 27.7422 32.9155 27.9555C32.908 27.9666 32.9028 27.9791 32.9003 27.9923C32.8978 28.0055 32.898 28.0191 32.901 28.0322C32.9039 28.0453 32.9096 28.0576 32.9175 28.0685C32.9255 28.0793 32.9355 28.0884 32.9472 28.0952C32.9588 28.1019 32.9716 28.1062 32.985 28.1078C32.9983 28.1094 33.0118 28.1082 33.0247 28.1044C33.0376 28.1005 33.0495 28.094 33.0598 28.0853C33.07 28.0767 33.0784 28.066 33.0843 28.0539C33.1087 28.0039 33.136 27.9554 33.1661 27.9086C33.1817 27.8836 33.1984 27.8596 33.2151 27.8356C33.2229 27.8247 33.2312 27.8143 33.2391 27.8039C33.2469 27.7934 33.2312 27.8132 33.2422 27.7992L33.2563 27.782C33.3278 27.6944 33.4086 27.6149 33.4975 27.5449C33.5156 27.5265 33.5257 27.5017 33.5257 27.4759C33.5257 27.4501 33.5156 27.4253 33.4975 27.4069C33.4784 27.3899 33.4537 27.3805 33.4282 27.3805C33.4026 27.3805 33.378 27.3899 33.3589 27.4069Z"
        fill="#F8B8B8"
      />
      <path
        d="M28.9857 25.8804C28.7994 26.0284 28.648 26.2157 28.5424 26.429C28.5348 26.4401 28.5296 26.4526 28.5271 26.4658C28.5246 26.479 28.5248 26.4926 28.5278 26.5057C28.5308 26.5188 28.5364 26.5311 28.5443 26.542C28.5523 26.5528 28.5624 26.5619 28.574 26.5687C28.5856 26.5754 28.5985 26.5798 28.6118 26.5813C28.6251 26.5829 28.6387 26.5817 28.6515 26.5779C28.6644 26.574 28.6763 26.5675 28.6866 26.5589C28.6968 26.5502 28.7052 26.5395 28.7112 26.5274C28.7357 26.4776 28.7628 26.4291 28.7924 26.3821C28.8086 26.3571 28.8247 26.3331 28.8419 26.3091L28.8654 26.2774L28.869 26.2727L28.8831 26.2555C28.9545 26.168 29.0352 26.0886 29.1238 26.0184C29.1329 26.0094 29.1401 25.9986 29.145 25.9868C29.1499 25.9749 29.1525 25.9622 29.1525 25.9494C29.1525 25.9366 29.1499 25.9239 29.145 25.912C29.1401 25.9002 29.1329 25.8894 29.1238 25.8804C29.1051 25.8628 29.0804 25.8529 29.0547 25.8529C29.0291 25.8529 29.0044 25.8628 28.9857 25.8804Z"
        fill="#F8B8B8"
      />
      <path d="M28.1894 18.4229L27.9708 22.7822L28.1269 22.79L28.3455 18.4307L28.1894 18.4229Z" fill="black" />
      <path
        d="M29.2381 20.7013C29.2949 20.0489 28.8584 19.4779 28.263 19.426C27.6676 19.3742 27.1389 19.861 27.0821 20.5134C27.0252 21.1658 27.4618 21.7367 28.0571 21.7886C28.6525 21.8405 29.1812 21.3537 29.2381 20.7013Z"
        fill="#F04E4A"
      />
      <path
        d="M43.0814 6.80959C43.0814 6.80959 39.8441 7.22529 39.3872 4C39.3872 4 39.5414 7.17733 36 8.34593C36 8.34593 39.1349 7.86628 40.0704 11.6453C40.0704 11.6453 39.109 7.19622 43.0814 6.80959Z"
        fill="#F59397"
      />
      <path
        clipRule="evenodd"
        d="M40.5724 39.5374L49.7481 20.8744L49.9275 20.9626L40.7519 39.6256L40.5724 39.5374Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
});

export default Store;
