import queryString from 'query-string';

import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getMenuPreviewRequest({ menuUuid, storeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function getMenuCategoryProductsRequest({ menuUuid, categoryUuid, storeUuid }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    menu_uuid: menuUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/categories/${categoryUuid}/products/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getMenuProductDetailsRequest({ menuUuid, productUuid, storeUuid }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    menu_uuid: menuUuid,
  });
  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/detail/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function exportMenuRequest({ menuUuid, storeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/export/?store_uuid=${storeUuid}`, {
    method: 'GET',
    config: {
      responseType: 'blob',
    },
    ...options,
  });
}
