import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_REPORTS,
  GET_REPORTS_BY_PAGINATION,
  EXPORT_REPORTS_TO_EXCEL,
  GET_REPORTS_SESSIONS,
  GET_REPORTS_SESSIONS_DETAILS,
  EXPORT_REPORTS_TO_EXCEL_DETAILS,
  GET_REPORTS_SESSIONS_DETAILS_PAGINATION,
  GET_REPORTS_PRODUCT_DETAILS,
  GET_PAGINATION_PRODUCT_DETAILS,
  GET_REPORTS_SESSIONS_PAGINATION,
  GET_REPORTS_SESSIONS_DETAILS_ALL,
  GET_REPORTS_BY_SORTING,
} from './constants';

export const getReportsSessions = extendRoutine(createRoutine(GET_REPORTS_SESSIONS), ['RESET', 'SET_PAGINATION']);
export const getReportsSessionsDetails = extendRoutine(createRoutine(GET_REPORTS_SESSIONS_DETAILS), [
  'RESET',
  'SET_PAGINATION',
]);
export const getReports = extendRoutine(createRoutine(GET_REPORTS), ['RESET', 'SET_PAGINATION']);
export const getReportsProductDetails = extendRoutine(createRoutine(GET_REPORTS_PRODUCT_DETAILS), ['RESET']);
export const getPaginationProductDetails = extendRoutine(createRoutine(GET_PAGINATION_PRODUCT_DETAILS), ['RESET']);
export const getReportsByPagination = extendRoutine(createRoutine(GET_REPORTS_BY_PAGINATION), ['RESET']);
export const exportReportsToExcel = extendRoutine(createRoutine(EXPORT_REPORTS_TO_EXCEL), ['RESET']);
export const getReportsBySorting = extendRoutine(createRoutine(GET_REPORTS_BY_SORTING), [
  'RESET',
  'SORTING',
  'RESET_SORTING',
]);

export const exportReportsToExcelDetails = extendRoutine(createRoutine(EXPORT_REPORTS_TO_EXCEL_DETAILS), ['RESET']);
export const getReportsSessionsDetailsPagination = extendRoutine(
  createRoutine(GET_REPORTS_SESSIONS_DETAILS_PAGINATION),
  ['RESET'],
);
export const getReportsSessionsPagination = extendRoutine(createRoutine(GET_REPORTS_SESSIONS_PAGINATION), ['RESET']);

export const getReportsSessionsDetailsAll = extendRoutine(createRoutine(GET_REPORTS_SESSIONS_DETAILS_ALL), ['RESET']);
