import { useMutation } from 'react-query';

import { AssignProductTypeFormProps } from '../types';
import { assignTypeOfProducts } from '../services/assignTypeOfProductService';

export function useAssignTypeOfProducts() {
  return useMutation((payload: AssignProductTypeFormProps) => assignTypeOfProducts(payload));
}

export default useAssignTypeOfProducts;
