import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const SuccessIllustration = forwardRef(function SuccessIllustration(props, ref) {
  const { size = 96, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 96 96"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M56.2665 58.7339C61.8244 62.1769 68.0085 65.2761 70.6482 72.2997C73.2878 79.3233 64.0474 95.9191 64.0474 95.9191L26.1472 93.75C26.1472 93.75 31.9837 86.2442 30.4551 80.5288C28.9266 74.8133 22.048 66.1371 20.2417 63.2447C18.4354 60.3524 18.0187 58.6997 19.6862 54.5685C21.3536 50.4364 29.2043 38.6618 31.5671 35.3565C33.9289 32.0512 41.0852 31.3629 48.2416 29.8479C55.3979 28.3329 56.9264 28.4705 58.455 28.6082C59.9836 28.7459 69.9007 33.849 71.2904 34.6749C72.6801 35.5008 72.9738 38.9296 70.25 40.6448C68.4075 41.8044 62.1114 43.1367 58.7151 42.8022C58.7151 42.8013 49.0067 51.4 56.2665 58.7339Z"
        fill="white"
      />
      <path
        d="M51.9595 42.4827C51.9595 42.4827 63.4229 48.9557 66.8277 50.746C70.2325 52.5363 72.2467 54.3959 70.0927 56.3238C67.9388 58.2518 64.1872 58.8032 60.5746 57.9765C56.9619 57.1497 49.1802 52.8116 49.1802 52.8116"
        fill="white"
      />
      <path
        d="M64.9954 58.2993C64.9954 58.2993 69.5365 61.351 70.5095 62.3154C71.4825 63.2798 70.996 66.7911 66.4103 66.2405C66.4103 66.2405 50.9866 60.6627 50.0835 56.7376C49.1803 52.8124 54.5993 52.8816 54.5993 52.8816L64.9954 58.2993Z"
        fill="white"
      />
      <path
        d="M43.0078 62.0717L9.17233 37.0058L8.75146 34.9243L41.6182 35.7661L89.5424 16.2079L89.9742 18.3444L43.0078 62.0717Z"
        fill="black"
      />
      <path
        d="M37.3961 32.2514L37.3052 31.9302C39.0357 31.448 41.2857 31.0492 43.4623 30.6638C44.4699 30.4853 45.5119 30.3009 46.4908 30.1099L46.5557 30.4377C45.5742 30.6288 44.5305 30.814 43.5213 30.9925C41.353 31.3763 39.1107 31.7734 37.3961 32.2514Z"
        fill="black"
      />
      <path
        d="M42.6147 59.9669L8.77832 34.9018L22.883 19.0635L41.225 33.6621L74.7129 1.71021L89.581 16.2404L42.6147 59.9669Z"
        fill="#B0EACA"
      />
      <path
        d="M30.4559 80.5288C31.8144 86.3978 26.1479 93.75 26.1479 93.75L64.0481 95.9191C64.0481 95.9191 73.2886 79.3233 70.6489 72.2997C68.0093 65.2761 61.9017 62.0509 56.2664 58.7339C52.0991 56.2812 53.3616 51.1088 50.4156 44.6017C47.7322 38.6743 44.1027 37.1768 39.8941 38.7619C38.1686 39.4118 38.2107 42.7663 38.884 48.5227C39.3175 52.2267 40.0086 53.0877 38.8268 56.2554C37.645 59.423 34.2411 60.2489 34.2411 60.2489C34.2411 60.2489 27.3466 67.0981 30.4559 80.5288Z"
        fill="white"
      />
      <path
        d="M64.1956 96L63.901 95.839C63.9237 95.7973 66.23 91.6394 68.1828 86.6797C70.7576 80.1425 71.5556 75.1904 70.4908 72.3581C68.1491 66.1287 62.9658 62.9919 57.9525 59.9578C57.3684 59.604 56.764 59.2386 56.1773 58.8757C53.4459 57.1855 53.036 55.009 52.4704 51.9965C52.0823 49.9309 51.6421 47.59 50.3476 44.7535C48.8543 41.4824 46.9958 39.4151 44.8217 38.6076C42.7746 37.8467 40.9531 38.4207 40.0491 38.8303C38.0222 39.7488 38.4759 43.0141 39.0003 46.7949C39.0735 47.3222 39.1493 47.8678 39.22 48.4184C39.3008 49.0507 39.3833 49.608 39.4557 50.0994C39.8151 52.5363 39.9792 53.6483 38.9852 56.3129C37.7866 59.5265 34.317 60.4024 34.2816 60.4108L34.2017 60.0871C34.2345 60.0787 37.534 59.242 38.6695 56.197C39.6333 53.6124 39.4734 52.5262 39.1224 50.1478C39.05 49.6547 38.9675 49.0958 38.8858 48.4601C38.8151 47.912 38.7402 47.3672 38.667 46.84C38.1241 42.9273 37.6544 39.5478 39.9094 38.5258C40.8605 38.0945 42.7788 37.4913 44.9403 38.2947C47.2012 39.1348 49.1237 41.2613 50.6548 44.6159C51.9662 47.489 52.4106 49.8508 52.802 51.9348C53.3525 54.863 53.7506 56.9795 56.3566 58.5921C56.9416 58.9542 57.5451 59.3196 58.1293 59.6725C63.1914 62.7358 68.4252 65.9035 70.8073 72.2405C71.9175 75.1929 71.1398 80.0925 68.4959 86.8032C66.5339 91.7837 64.2191 95.9583 64.1956 96Z"
        fill="black"
      />
      <path
        d="M63.4934 42.7638L63.4312 42.4359C66.0119 41.9563 69.2331 41.1028 70.1573 40.5046C72.3845 39.0631 72.478 36.8089 71.6219 35.3248L71.914 35.1596C72.4241 36.0439 72.5807 37.0734 72.3542 38.0586C72.1034 39.1515 71.4073 40.0942 70.3408 40.785C69.3468 41.4282 66.0708 42.2849 63.4934 42.7638Z"
        fill="black"
      />
      <path
        d="M26.2811 93.8518L26.0143 93.6482C26.0724 93.574 31.7818 86.1408 30.2928 80.5713C29.1287 76.2199 24.8797 70.1915 22.0675 66.2021C21.2392 65.0267 20.5229 64.0114 20.0987 63.3323C18.2158 60.3173 17.8698 58.6196 19.5297 54.5059C20.1214 53.0401 21.3958 50.9787 23.0094 48.3692C23.1836 48.0872 23.3612 47.7994 23.543 47.5057L23.8301 47.6801C23.6491 47.9737 23.4706 48.2615 23.2964 48.5435C21.6912 51.1397 20.4228 53.1903 19.842 54.6302C18.236 58.6096 18.5685 60.2498 20.3849 63.1571C20.8041 63.8287 21.5178 64.8407 22.3436 66.0119C25.1692 70.0205 29.4393 76.078 30.6177 80.4862C31.0604 82.1431 31.1446 85.0271 29.026 89.356C27.7659 91.9322 26.2963 93.8326 26.2811 93.8518Z"
        fill="black"
      />
      <path
        d="M64.1957 96L63.9011 95.839C63.9239 95.7973 66.2302 91.6394 68.183 86.6797C70.7578 80.1425 71.5557 75.1904 70.491 72.3581C68.1518 66.1362 62.9761 63.0011 57.9695 59.9695C57.3795 59.6124 56.7692 59.2428 56.1775 58.8757L56.3559 58.5929C56.9468 58.9592 57.5562 59.3279 58.1454 59.685C63.2 62.7467 68.4271 65.9118 70.8066 72.2421C71.9168 75.1946 71.1391 80.0941 68.4952 86.8049C66.534 91.7837 64.2193 95.9583 64.1957 96Z"
        fill="black"
      />
      <path
        d="M63.8437 58.522C62.7756 58.522 61.6612 58.3969 60.5366 58.1391C58.5855 57.6928 55.6841 56.3104 53.3752 55.155L53.5268 54.8571C55.8196 56.0034 58.6966 57.3758 60.6124 57.8137C64.273 58.6513 67.9497 58.0173 69.9799 56.1995C70.6061 55.6388 70.8755 55.0857 70.8031 54.5084C70.6693 53.4397 69.3427 52.2576 66.7485 50.8928C65.7048 50.3438 64.0853 49.4303 62.2108 48.3725C61.2319 47.8202 60.2193 47.2488 59.168 46.6614L59.333 46.3711C60.3843 46.9593 61.3977 47.5299 62.3766 48.083C64.2494 49.1392 65.8664 50.0518 66.9059 50.5983C69.6424 52.0374 70.9866 53.2671 71.1373 54.4675C71.2231 55.1508 70.9091 55.8174 70.2055 56.4472C68.6946 57.8004 66.4043 58.522 63.8437 58.522Z"
        fill="black"
      />
      <path
        d="M67.3395 66.4649C67.1871 66.4649 67.0314 66.4599 66.8715 66.4499L66.8925 66.117C67.0508 66.127 67.2023 66.132 67.3479 66.132C69.464 66.132 70.2543 65.1234 70.5414 64.4935C70.9168 63.6701 70.7585 62.8 70.3899 62.4338C69.4396 61.4919 64.9456 58.4686 64.9001 58.4378L65.0895 58.1616C65.2756 58.2868 69.655 61.2333 70.6281 62.1977C71.1508 62.7158 71.2451 63.7619 70.8486 64.6312C70.5851 65.2085 70.1289 65.679 69.5279 65.9935C68.9278 66.3064 68.193 66.4649 67.3395 66.4649Z"
        fill="black"
      />
      <path
        d="M52.3449 10.6217C52.2591 10.6217 52.185 10.5566 52.1774 10.4699L51.2339 0.181853C51.2254 0.0900852 51.2936 0.00916265 51.3862 0.000820117C51.4788 -0.00668816 51.5604 0.0600521 51.5689 0.15182L52.5124 10.4407C52.5208 10.5324 52.4527 10.6134 52.3601 10.6217C52.355 10.6217 52.35 10.6217 52.3449 10.6217Z"
        fill="black"
      />
      <path
        d="M30.8019 11.8356C30.7396 11.8356 30.6798 11.8014 30.6503 11.7421L26.0823 2.45607C26.0419 2.37348 26.0764 2.27337 26.1598 2.23333C26.2431 2.19328 26.3441 2.22749 26.3845 2.31008L30.9525 11.5961C30.9929 11.6787 30.9584 11.7788 30.8751 11.8189C30.8515 11.8297 30.8263 11.8356 30.8019 11.8356Z"
        fill="black"
      />
      <path
        d="M95.832 37.3779C95.827 37.3779 95.8211 37.3779 95.816 37.377L85.4392 36.3985C85.3466 36.3901 85.2784 36.3084 85.2877 36.2166C85.2961 36.1248 85.3786 36.0589 85.4712 36.0664L95.848 37.045C95.9406 37.0533 96.0088 37.1351 95.9995 37.2269C95.9911 37.3136 95.9179 37.3779 95.832 37.3779Z"
        fill="black"
      />
      <path
        d="M0.168575 46.7207C0.0869279 46.7207 0.0153816 46.6614 0.00275579 46.5797C-0.0115535 46.4887 0.0515756 46.4037 0.143323 46.3895L10.4435 44.8077C10.5352 44.7944 10.6211 44.8561 10.6354 44.947C10.6497 45.038 10.5866 45.1231 10.4948 45.1373L0.194668 46.719C0.185409 46.7198 0.176992 46.7207 0.168575 46.7207Z"
        fill="black"
      />
      <path
        d="M14.3025 24.3994C14.2832 24.3994 14.2638 24.396 14.2445 24.3893L4.45524 20.8413C4.3677 20.8096 4.32309 20.7136 4.35507 20.6277C4.38706 20.541 4.48386 20.4967 4.5714 20.5284L14.3606 24.0765C14.4482 24.1082 14.4928 24.2041 14.4608 24.2901C14.4355 24.3568 14.3716 24.3994 14.3025 24.3994Z"
        fill="black"
      />
      <path
        d="M87.1231 52.3802C87.1037 52.3802 87.0844 52.3769 87.065 52.3702L77.2758 48.8222C77.1883 48.7905 77.1436 48.6945 77.1756 48.6086C77.2076 48.5218 77.3044 48.4776 77.392 48.5093L87.1812 52.0574C87.2687 52.0891 87.3133 52.185 87.2813 52.271C87.2561 52.3385 87.1921 52.3802 87.1231 52.3802Z"
        fill="black"
      />
      <path
        d="M13.9539 65.6449C13.9539 65.6449 13.9025 69.1612 10.3665 69.8829C10.3665 69.8829 14.1399 70.8072 14.1399 74.256C14.1399 74.256 14.2729 70.4151 17.8485 69.8328C17.8493 69.8328 14.5481 69.0044 13.9539 65.6449Z"
        fill="#F8B8B7"
      />
      <path
        d="M81.978 37.5856C81.978 37.5856 81.9267 41.102 78.3906 41.8236C78.3906 41.8236 82.164 42.748 82.164 46.1968C82.164 46.1968 82.297 42.3559 85.8726 41.7736C85.8734 41.7736 82.5722 40.9451 81.978 37.5856Z"
        fill="#F8B8B7"
      />
      <path
        d="M51.845 19.4965C51.845 19.4965 51.8038 22.3154 48.9697 22.8936C48.9697 22.8936 51.9949 23.6352 51.9949 26.3991C51.9949 26.3991 52.1009 23.3207 54.9678 22.8535C54.9678 22.8535 52.3215 22.1895 51.845 19.4965Z"
        fill="#F8B8B7"
      />
    </svg>
  );
});

SuccessIllustration.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SuccessIllustration;
