import { getUserCurrentStore } from 'utils/auth';

import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getMultiTaxesRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/taxes/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function updateDefaultTaxRequest(taxUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/config/taxes/`, {
    method: 'PUT',
    body: JSON.stringify({
      default_tax_id: taxUuid,
    }),
    ...options,
  });
}
