import queryString from 'query-string';
import { getUserCurrentStore } from 'utils/auth';
import { pageSizeTypes } from 'utils/pagination';
import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getDiscountsRequest({ page }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store: storeUuid,
    page,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
  });
  return request(`${API_BASE_URL}/v1/discounts/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getDiscountDetailRequest({ storeUuid, discountUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/discounts/${discountUuid}/?store=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function createDiscountRequest(
  { storeUuid, title, discountType, amountType, description, amount, isRestricted, scope },
  options = {},
) {
  const query = queryString.stringify({
    store: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/discounts/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      title,
      discountType,
      amountType,
      description,
      amount,
      isRestricted,
      scope,
    }),
    ...options,
  });
}

export function deleteDiscountRequest({ storeUuid, discountUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/discounts/${discountUuid}/?store=${storeUuid}`, {
    method: 'DELETE',
    ...options,
  });
}

export function setIsEnabledDiscountRequest({ storeUuid, discountUuid, isEnabled }, options = {}) {
  return request(`${API_BASE_URL}/v1/discounts/${discountUuid}/set-is-enabled/?store=${storeUuid}`, {
    method: 'PUT',
    body: JSON.stringify({
      is_enabled: isEnabled,
    }),
    ...options,
  });
}
