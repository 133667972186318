import queryString from 'query-string';

import {
  ModifiersByProductResponse,
  ModifiersByProductDetailResponse,
  GetModifiersOptionsResponse,
  GetModifiersOptionsDetailResponse,
} from 'components/ReportModifiers/types';

import { request2 as request } from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { createHeaders } from 'utils/baasRequest';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

interface PropsModifiersReportFiltersRequest {
  storeUuid?: string;
  startDate: string;
  endDate: string;
  brands?: string[];
  providers?: string[];
  stores?: string[];
  page?: number;
  pageSize?: number;
}

interface PropsModifiersReportDetailRequest {
  storeUuid?: string;
  skuItem: string;
  startDate: string;
  endDate: string;
  brands?: string[];
  providers?: string[];
  stores?: string[];
}

interface PropsDownloadReportModifiers {
  startDate: string;
  endDate: string;
  report: string;
  brands?: string[];
  providers?: string[];
}

export function getModifiersByProduct(
  {
    storeUuid,
    startDate,
    endDate,
    brands = [],
    providers = [],
    stores = [],
    page = 1,
    pageSize = 10,
  }: PropsModifiersReportFiltersRequest,
  options = {},
): Promise<ModifiersByProductResponse> {
  const storeUuidLocal = storeUuid ?? getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuidLocal,
    start_date: startDate,
    end_date: endDate,
    page,
    page_size: pageSize,
  });

  const params = {
    brands,
    providers,
    stores: [storeUuidLocal, ...stores],
  };

  return request(`${API_BASE_URL}/v1/reports/modifiers-sales/products/?${query}`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(params),
    ...options,
  });
}

export function getModifiersByProductDetail(
  {
    storeUuid,
    skuItem,
    startDate,
    endDate,
    brands = [],
    providers = [],
    stores = [],
  }: PropsModifiersReportDetailRequest,
  options = {},
): Promise<ModifiersByProductDetailResponse> {
  const storeUuidLocal = storeUuid ?? getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuidLocal,
    start_date: startDate,
    end_date: endDate,
  });
  const params = {
    skuItem,
    brands,
    providers,
    stores: [storeUuidLocal, ...stores],
  };
  return request(`${API_BASE_URL}/v1/reports/modifiers-sales/products/detail/?${query}`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(params),
    ...options,
  });
}

export function getModifiersOptions({
  storeUuid,
  startDate,
  endDate,
  brands = [],
  providers = [],
  stores = [],
  page = 1,
  pageSize = 10,
}: PropsModifiersReportFiltersRequest): Promise<GetModifiersOptionsResponse> {
  const storeUuidLocal = storeUuid ?? getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuidLocal,
    start_date: startDate,
    end_date: endDate,
    page,
    page_size: pageSize,
  });

  const params = {
    brands,
    providers,
    stores: [storeUuidLocal, ...stores],
  };

  return request(`${API_BASE_URL}/v1/reports/modifiers-sales/?${query}`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: createHeaders(),
  });
}

export function getModifiersOptionsDetail({
  storeUuid,
  startDate,
  endDate,
  skuItem,
  brands = [],
  providers = [],
  stores = [],
}: PropsModifiersReportDetailRequest): Promise<GetModifiersOptionsDetailResponse> {
  const storeUuidLocal = storeUuid ?? getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuidLocal,
    start_date: startDate,
    end_date: endDate,
  });

  const params = {
    brands,
    providers,
    skuItem,
    stores: [storeUuidLocal, ...stores],
  };
  return request(`${API_BASE_URL}/v1/reports/modifiers-sales/detail/?${query}`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: createHeaders(),
  });
}

export function downloadReportModifiers({
  startDate,
  endDate,
  report,
  brands,
  providers,
}: PropsDownloadReportModifiers): Promise<Blob> {
  const storeUuid = getUserCurrentStore()?.uuid;

  const params = JSON.stringify({
    start_date: startDate,
    end_date: endDate,
    report,
    brands,
    providers,
  });

  return request(`${API_BASE_URL}/v1/reports/modifiers-sales/export/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: params,
    headers: createHeaders(),
    config: {
      responseType: 'blob',
    },
  });
}
