import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  getPortalConfigListRequest,
  savePortalConfigRequest,
  getPortalConfigDetailsRequest,
  updatePortalConfigRequest,
  setEnableAutoInvoicingRequest,
} from 'utils/api/baas/invoicing/portalConfig';

import {
  getInvoicingConfigListRequest,
  createInvoicingConfigRequest,
  getInvoicingConfigDetailsRequest,
  updateInvoicingConfigRequest,
  deleteInvoicingConfigRequest,
} from 'utils/api/baas/invoicing/invoicingConfig';
import { getBrandsNotConfiguredRequest } from 'utils/api/baas/invoicing/brands';

import {
  getPortalConfigList,
  savePortalConfig,
  portalConfigDetails,
  portalConfigUpdate,
  invoicingConfigList,
  invoicingConfigCreate,
  invoicingGetBrandsNotConfigured,
  getInvoicingConfigDetails,
  updateInvoicingConfig,
  deleteInvoicingConfig,
  enableInvoicingAuto,
} from './actions';

export function* handleGetPortalConfigListRequest() {
  try {
    yield put(getPortalConfigList.request());

    const { data } = yield call(baasRequest, getPortalConfigListRequest);

    yield put(getPortalConfigList.success({ data }));
  } catch (err) {
    yield put(getPortalConfigList.failure(err));
  }
}

export function* handleGetPortalConfigDetailsRequest(action) {
  const portalUuid = action.payload;

  try {
    yield put(portalConfigDetails.request());

    const { data } = yield call(baasRequest, getPortalConfigDetailsRequest, portalUuid);

    yield put(portalConfigDetails.success({ data }));
  } catch (err) {
    yield put(portalConfigDetails.failure(err));
  }
}

export function* handlePortalConfigSaveRequest(action) {
  const portalConfigValues = action.payload;

  try {
    yield put(savePortalConfig.request());

    const { data } = yield call(baasRequest, savePortalConfigRequest, { ...portalConfigValues });

    yield put(savePortalConfig.success({ data }));
  } catch (err) {
    yield put(savePortalConfig.failure(err));
  }
}

export function* handlePortalConfigUpdateRequest(action) {
  const portalConfigValues = action.payload;

  try {
    yield put(portalConfigUpdate.request());

    const { data } = yield call(baasRequest, updatePortalConfigRequest, { ...portalConfigValues });

    yield put(portalConfigUpdate.success({ data }));
  } catch (err) {
    yield put(portalConfigUpdate.failure(err));
  }
}

export function* handleGetInvoicingConfigListRequest() {
  try {
    yield put(invoicingConfigList.request());

    const { data } = yield call(baasRequest, getInvoicingConfigListRequest);

    yield put(invoicingConfigList.success({ data }));
  } catch (err) {
    yield put(invoicingConfigList.failure(err));
  }
}

export function* handleCreateInvoicingConfigRequest(action) {
  const invoicingNew = action.payload;

  try {
    yield put(invoicingConfigCreate.request());

    const { data } = yield call(baasRequest, createInvoicingConfigRequest, { ...invoicingNew });

    yield put(invoicingConfigCreate.success({ data }));
  } catch (err) {
    yield put(invoicingConfigCreate.failure(err));
  }
}

export function* handleGetBrandsNotConfiguredRequest() {
  try {
    yield put(invoicingGetBrandsNotConfigured.request());

    const { data } = yield call(baasRequest, getBrandsNotConfiguredRequest);

    yield put(invoicingGetBrandsNotConfigured.success({ data }));
  } catch (err) {
    yield put(invoicingGetBrandsNotConfigured.failure(err));
  }
}

export function* handleGetInvoicingConfigDetailsRequest(action) {
  const invoicingUuid = action.payload;

  try {
    yield put(getInvoicingConfigDetails.request());

    const { data } = yield call(baasRequest, getInvoicingConfigDetailsRequest, invoicingUuid);

    yield put(getInvoicingConfigDetails.success({ data }));
  } catch (err) {
    yield put(getInvoicingConfigDetails.failure(err));
  }
}

export function* handleUpdateInvoicingConfigRequest(action) {
  const { invoicingUpdate, invoicingUuidParam } = action.payload;

  try {
    yield put(updateInvoicingConfig.request());

    const { data } = yield call(baasRequest, updateInvoicingConfigRequest, invoicingUuidParam, invoicingUpdate);

    yield put(updateInvoicingConfig.success({ data }));
  } catch (err) {
    yield put(updateInvoicingConfig.failure(err));
  }
}

export function* handleInvoicingConfigDeleteRequest(action) {
  const invoicingUuidParam = action.payload;

  try {
    yield put(deleteInvoicingConfig.request());

    yield call(baasRequest, deleteInvoicingConfigRequest, invoicingUuidParam);

    yield put(deleteInvoicingConfig.success({ uuid: invoicingUuidParam }));
  } catch (err) {
    yield put(deleteInvoicingConfig.failure(err));
  }
}

export function* handleInvoicingEnableAutoRequest(action) {
  const portalConfigValues = action.payload;
  try {
    yield put(enableInvoicingAuto.request());

    const { data } = yield call(baasRequest, setEnableAutoInvoicingRequest, portalConfigValues);
    const { uuid, isEnabled } = data;

    yield put(enableInvoicingAuto.success({ uuid, isEnabled }));
  } catch (err) {
    yield put(enableInvoicingAuto.failure(err));
  }
}

export default function* invoicingProviderRootSga() {
  yield takeLatest(getPortalConfigList.TRIGGER, handleGetPortalConfigListRequest);
  yield takeLatest(portalConfigDetails.TRIGGER, handleGetPortalConfigDetailsRequest);
  yield takeLatest(savePortalConfig.TRIGGER, handlePortalConfigSaveRequest);
  yield takeLatest(portalConfigUpdate.TRIGGER, handlePortalConfigUpdateRequest);
  yield takeLatest(invoicingConfigList.TRIGGER, handleGetInvoicingConfigListRequest);
  yield takeLatest(invoicingConfigCreate.TRIGGER, handleCreateInvoicingConfigRequest);
  yield takeLatest(invoicingGetBrandsNotConfigured.TRIGGER, handleGetBrandsNotConfiguredRequest);
  yield takeLatest(getInvoicingConfigDetails.TRIGGER, handleGetInvoicingConfigDetailsRequest);
  yield takeLatest(updateInvoicingConfig.TRIGGER, handleUpdateInvoicingConfigRequest);
  yield takeLatest(deleteInvoicingConfig.TRIGGER, handleInvoicingConfigDeleteRequest);
  yield takeLatest(enableInvoicingAuto.TRIGGER, handleInvoicingEnableAutoRequest);
}
