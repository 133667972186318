import produce from 'immer';

import baseState from 'utils/baseState';

import {
  getKitchenAreas,
  getKitchenAreasPagination,
  getKitchenAreaDetails,
  createKitchenArea,
  updateKitchenArea,
  disableKitchenArea,
} from './actions';

export const initialState = {
  kitchenAreas: {
    ...baseState,
    kitchenAreas: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  kitchenAreaDetails: {
    ...baseState,
    kitchenArea: {},
  },

  createKitchenArea: {
    ...baseState,
    kitchenArea: {},
  },

  updateKitchenArea: {
    ...baseState,
  },

  disableKitchenArea: {
    ...baseState,
  },

  kitchenAreasPagination: {
    ...baseState,
    lastUpdatedRows: [],
  },
};

const invoicingProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getKitchenAreas.REQUEST:
        draft.kitchenAreas = {
          ...initialState.kitchenAreas,
          fetching: true,
        };
        break;

      case getKitchenAreas.SUCCESS:
        draft.kitchenAreas.fetching = false;
        draft.kitchenAreas.fetched = true;
        draft.kitchenAreas.kitchenAreas = payload.data;
        draft.kitchenAreas.pagination = {
          ...initialState.kitchenAreas.pagination,
          ...payload.pagination,
        };
        break;

      case getKitchenAreas.FAILURE:
        draft.kitchenAreas.error = payload;
        draft.kitchenAreas.fetching = false;
        draft.kitchenAreas.fetched = false;
        break;

      case getKitchenAreas.RESET:
        draft.kitchenAreas = {
          ...initialState.kitchenAreas,
        };
        break;

      case getKitchenAreaDetails.REQUEST:
        draft.kitchenAreaDetails = {
          ...initialState.kitchenAreaDetails,
          fetching: true,
        };
        break;

      case getKitchenAreaDetails.SUCCESS:
        draft.kitchenAreaDetails.fetching = false;
        draft.kitchenAreaDetails.fetched = true;
        draft.kitchenAreaDetails.kitchenArea = payload.data;
        break;

      case getKitchenAreaDetails.FAILURE:
        draft.kitchenAreaDetails.error = payload;
        draft.kitchenAreaDetails.fetching = false;
        draft.kitchenAreaDetails.fetched = false;
        break;

      case getKitchenAreaDetails.RESET:
        draft.kitchenAreaDetails = {
          ...initialState.kitchenAreaDetails,
        };
        break;

      case createKitchenArea.REQUEST:
        draft.createKitchenArea = {
          ...initialState.createKitchenArea,
          fetching: true,
        };
        break;

      case createKitchenArea.SUCCESS: {
        const kitchenList = [...draft.kitchenAreas.kitchenAreas];
        kitchenList.push(payload.data);
        draft.createKitchenArea.fetching = false;
        draft.createKitchenArea.fetched = true;
        draft.createKitchenArea.kitchenArea = payload.data;
        draft.kitchenAreas.kitchenAreas = kitchenList;
        break;
      }

      case createKitchenArea.FAILURE:
        draft.createKitchenArea.error = payload;
        draft.createKitchenArea.fetching = false;
        draft.createKitchenArea.fetched = false;
        break;

      case createKitchenArea.RESET:
        draft.createKitchenArea = {
          ...initialState.createKitchenArea,
        };
        break;

      case updateKitchenArea.REQUEST:
        draft.updateKitchenArea.fetching = true;
        draft.updateKitchenArea.fetched = false;
        draft.updateKitchenArea.error = false;
        break;

      case updateKitchenArea.SUCCESS: {
        const index = draft.kitchenAreas.kitchenAreas.findIndex((area) => {
          return area.uuid === payload.uuid;
        });

        draft.updateKitchenArea.fetching = false;
        draft.updateKitchenArea.fetched = true;
        draft.updateKitchenArea.reason = payload;
        draft.kitchenAreas.kitchenAreas[index] = payload;
        break;
      }

      case updateKitchenArea.FAILURE:
        draft.updateKitchenArea.error = payload;
        draft.updateKitchenArea.fetched = false;
        draft.updateKitchenArea.fetching = false;
        break;

      case updateKitchenArea.RESET:
        draft.updateKitchenArea = { ...initialState.updateKitchenArea };
        break;

      case disableKitchenArea.REQUEST:
        draft.disableKitchenArea.fetching = true;
        draft.disableKitchenArea.error = false;
        draft.disableKitchenArea.fetched = false;
        break;

      case disableKitchenArea.SUCCESS: {
        const index = draft.kitchenAreas.kitchenAreas.findIndex((area) => {
          return area.uuid === payload.uuid;
        });

        draft.disableKitchenArea.fetching = false;
        draft.disableKitchenArea.fetched = true;
        draft.kitchenAreas.kitchenAreas[index] = payload;

        break;
      }

      case disableKitchenArea.FAILURE:
        draft.disableKitchenArea.error = payload;
        draft.disableKitchenArea.fetching = false;
        draft.disableKitchenArea.fetched = false;
        break;

      case disableKitchenArea.RESET:
        draft.disableKitchenArea = { ...initialState.disableKitchenArea };
        break;

      case getKitchenAreasPagination.REQUEST:
        draft.kitchenAreasPagination = {
          ...initialState.kitchenAreasPagination,
          fetching: true,
        };
        break;

      case getKitchenAreasPagination.SUCCESS: {
        const currentKitchenAreaState = state.kitchenAreas.kitchenAreas;

        let newsRows = [...currentKitchenAreaState];
        newsRows = [...newsRows, ...payload.data];

        draft.kitchenAreasPagination.fetching = false;
        draft.kitchenAreasPagination.fetched = true;
        draft.kitchenAreasPagination.lastUpdatedRows = payload.data;

        draft.kitchenAreas.kitchenAreas = [...newsRows];
        draft.kitchenAreas.pagination = {
          ...initialState.kitchenAreasPagination.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getKitchenAreasPagination.FAILURE:
        draft.kitchenAreasPagination.error = payload;
        draft.kitchenAreasPagination.fetching = false;
        draft.kitchenAreasPagination.fetched = false;
        break;

      case getKitchenAreasPagination.RESET:
        draft.kitchenAreasPagination = {
          ...initialState.kitchenAreasPagination,
        };
        break;

      default:
        return draft;
    }
  });

export default invoicingProviderReducer;
