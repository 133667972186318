import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  getCategoryStore,
  editCategoryStore,
  getProductsSortCategoryRequest,
  updateProductsSortCategoryRequest,
} from 'utils/api/baas/categories';

import { getCategoryDetail, editCategory, getProductsSortCategory, updateProductsSortCategory } from './actions';

export function* handleInitCategoryRequest(action) {
  const categoryUuid = action.payload;

  try {
    yield put(getCategoryDetail.request());

    const { data } = yield call(baasRequest, getCategoryStore, categoryUuid);

    yield put(getCategoryDetail.success(data));
  } catch (err) {
    yield put(getCategoryDetail.failure(err));
  }
}

export function* handleEditCategoryRequest(action) {
  const { storeUuid, categoryUuid, category } = action.payload;

  try {
    yield put(editCategory.request());

    const { data } = yield call(baasRequest, editCategoryStore, {
      storeUuid,
      categoryUuid,
      category,
    });

    yield put(editCategory.success(data));
  } catch (err) {
    yield put(editCategory.failure(err));
  }
}

export function* handleGetProductsSortCategoryRequest(action) {
  const { storeUuid, categoryUuid } = action.payload;

  try {
    yield put(getProductsSortCategory.request());

    const { data } = yield call(baasRequest, getProductsSortCategoryRequest, {
      storeUuid,
      categoryUuid,
    });

    yield put(getProductsSortCategory.success(data));
  } catch (err) {
    yield put(getProductsSortCategory.failure(err));
  }
}

export function* handleUpdateProductsSortCategoryRequest(action) {
  const { storeUuid, categoryUuid, productsSort } = action.payload;

  try {
    yield put(updateProductsSortCategory.request());

    const { data } = yield call(baasRequest, updateProductsSortCategoryRequest, {
      storeUuid,
      categoryUuid,
      productsSort,
    });

    yield put(updateProductsSortCategory.success(data));
  } catch (err) {
    yield put(updateProductsSortCategory.failure({ error: err }));
  }
}

export default function* categoryDetailsSaga() {
  yield takeLatest(getCategoryDetail.TRIGGER, handleInitCategoryRequest);
  yield takeLatest(editCategory.TRIGGER, handleEditCategoryRequest);
  yield takeLatest(getProductsSortCategory.TRIGGER, handleGetProductsSortCategoryRequest);
  yield takeLatest(updateProductsSortCategory.TRIGGER, handleUpdateProductsSortCategoryRequest);
}
