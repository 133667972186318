import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';
import clsx from 'clsx';

import useStyles from './styles';

interface Props {
  size?: number;
  open?: boolean;
  lighter?: boolean;
  color?: string;
}

const Chevron = ({ size = 24, open = false, lighter = false, color = 'black' }: Props) => {
  const classes = useStyles();

  return (
    <ChevronDown
      className={clsx(classes.root, { [classes.rotate]: open })}
      color={color}
      lighter={lighter}
      size={size}
    />
  );
};

export default Chevron;
