import { useRef, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectPortalConfigList,
  selectSavePortalConfig,
  selectPortalConfigDetails,
  selectPortalConfigUpdate,
  selectInvoicingConfigList,
  selectInvoicingConfigCreate,
  selectInvoicingGetBrandsNotConfigured,
  selectInvoicingConfigDetails,
  selectUpdateInvoicingConfig,
  selectDeleteInvoicingConfig,
  selectEnableInvoicingAuto,
} from './selectors';
import {
  getPortalConfigList,
  savePortalConfig,
  portalConfigDetails,
  portalConfigUpdate,
  invoicingConfigList,
  invoicingConfigCreate,
  invoicingGetBrandsNotConfigured,
  getInvoicingConfigDetails,
  updateInvoicingConfig,
  deleteInvoicingConfig,
  enableInvoicingAuto,
} from './actions';

function useInvoicing() {
  const dispatch = useDispatch();

  const selectPortalConfigListRef = useRef();
  const selectSavePortalConfigRef = useRef();
  const selectPortalConfigDetailsRef = useRef();
  const selectPortalConfigUpdateRef = useRef();
  const selectInvoicingConfigListRef = useRef();
  const selectInvoicingConfigCreateRef = useRef();
  const selectInvoicingGetBrandsNotConfiguredRef = useRef();
  const selectInvoicingConfigDetailsRef = useRef();
  const selectUpdateInvoicingConfigRef = useRef();
  const selectDeleteInvoicingConfigRef = useRef();
  const selectEnableInvoicingAutoRef = useRef();
  selectPortalConfigListRef.current = useSelector(selectPortalConfigList);
  selectSavePortalConfigRef.current = useSelector(selectSavePortalConfig);
  selectPortalConfigDetailsRef.current = useSelector(selectPortalConfigDetails);
  selectPortalConfigUpdateRef.current = useSelector(selectPortalConfigUpdate);
  selectInvoicingConfigListRef.current = useSelector(selectInvoicingConfigList);
  selectInvoicingConfigCreateRef.current = useSelector(selectInvoicingConfigCreate);
  selectInvoicingGetBrandsNotConfiguredRef.current = useSelector(selectInvoicingGetBrandsNotConfigured);
  selectInvoicingConfigDetailsRef.current = useSelector(selectInvoicingConfigDetails);
  selectUpdateInvoicingConfigRef.current = useSelector(selectUpdateInvoicingConfig);
  selectDeleteInvoicingConfigRef.current = useSelector(selectDeleteInvoicingConfig);
  selectEnableInvoicingAutoRef.current = useSelector(selectEnableInvoicingAuto);

  const handleGetPortalConfigLis = useCallback(() => {
    dispatch(getPortalConfigList());
  }, [dispatch]);

  const handleSavePortalConfig = useCallback(
    (portalConfigValues) => {
      // brandUuid, email, phone, mainColor, logo
      dispatch(savePortalConfig({ ...portalConfigValues }));
    },
    [dispatch],
  );

  const handleResetSavePortalConfig = useCallback(() => {
    dispatch(savePortalConfig.reset());
  }, [dispatch]);

  const handleGetPortalConfigDetails = useCallback(
    (portalUuid) => {
      dispatch(portalConfigDetails(portalUuid));
    },
    [dispatch],
  );

  const handlePortalConfigUpdate = useCallback(
    (portalConfigValues) => {
      dispatch(portalConfigUpdate({ ...portalConfigValues }));
    },
    [dispatch],
  );

  const handleResetPortalConfigUpdate = useCallback(() => {
    dispatch(portalConfigUpdate.reset());
  }, [dispatch]);

  const handleLoadInvoicingConfigList = useCallback(() => {
    dispatch(invoicingConfigList());
  }, [dispatch]);

  const handleInvoicingConfigCreate = useCallback(
    (invoicingNew) => {
      dispatch(invoicingConfigCreate({ ...invoicingNew }));
    },
    [dispatch],
  );

  const handleResetInvoicingConfigCreate = useCallback(() => {
    dispatch(invoicingConfigCreate.reset());
  }, [dispatch]);

  const handleInvoicingGetBrandsNotConfigured = useCallback(() => {
    dispatch(invoicingGetBrandsNotConfigured());
  }, [dispatch]);

  const handleResetInvoicingBrandsNotConfigured = useCallback(() => {
    dispatch(invoicingGetBrandsNotConfigured.reset());
  }, [dispatch]);

  const handleGetInvoicingConfigDetails = useCallback(
    (invoicingUuidParam) => {
      dispatch(getInvoicingConfigDetails(invoicingUuidParam));
    },
    [dispatch],
  );

  const handleUpdateInvoicingConfig = useCallback(
    (invoicingUuidParam, invoicingUpdate) => {
      dispatch(updateInvoicingConfig({ invoicingUuidParam, invoicingUpdate }));
    },
    [dispatch],
  );

  const handleResetUpdateInvoicingConfig = useCallback(() => {
    dispatch(updateInvoicingConfig.reset());
  }, [dispatch]);

  const handleDeleteInvoicingConfig = useCallback(
    (invoicingUuidParam) => {
      dispatch(deleteInvoicingConfig(invoicingUuidParam));
    },
    [dispatch],
  );

  const handleResetAllStateInvoicingConfig = useCallback(() => {
    dispatch(updateInvoicingConfig.reset());
    dispatch(deleteInvoicingConfig.reset());
    dispatch(invoicingConfigCreate.reset());
  }, [dispatch]);

  const handleEnableInvoicingAuto = useCallback(
    (portalUuid, isEnabled) => {
      dispatch(enableInvoicingAuto({ isEnabled, portalUuid }));
    },
    [dispatch],
  );

  const handleResetEnableInvoicingAuto = useCallback(() => {
    dispatch(enableInvoicingAuto.reset());
  }, [dispatch]);

  const portalConfigListValues = selectPortalConfigListRef.current;
  const portalConfigDetailsValues = selectPortalConfigDetailsRef.current;
  const savePortalConfigValues = selectSavePortalConfigRef.current;
  const portalConfigUpdateValues = selectPortalConfigUpdateRef.current;
  const invoicingConfigListValues = selectInvoicingConfigListRef.current;
  const invoicingConfigCreateValues = selectInvoicingConfigCreateRef.current;
  const invoicingGetBrandsNotConfiguredValues = selectInvoicingGetBrandsNotConfiguredRef.current;
  const invoicingConfigDetailsValues = selectInvoicingConfigDetailsRef.current;
  const updateInvoicingConfigValues = selectUpdateInvoicingConfigRef.current;
  const deleteInvoicingConfigValues = selectDeleteInvoicingConfigRef.current;
  const enableInvoicingAutoValues = selectEnableInvoicingAutoRef.current;

  return {
    loadPortalConfigList: handleGetPortalConfigLis,
    portalConfigListState: {
      ...portalConfigListValues,
    },

    savePortalConfig: handleSavePortalConfig,
    savePortalConfigState: {
      ...savePortalConfigValues,
    },
    resetSavePortalConfig: handleResetSavePortalConfig,
    loadPortalConfigDetails: handleGetPortalConfigDetails,
    portalConfigDetailsState: {
      ...portalConfigDetailsValues,
    },
    updatePortalConfig: handlePortalConfigUpdate,
    resetPortalConfigUpdate: handleResetPortalConfigUpdate,
    updatePortalConfigState: {
      ...portalConfigUpdateValues,
    },
    loadInvoicingConfigList: handleLoadInvoicingConfigList,
    createInvoicingConfig: handleInvoicingConfigCreate,
    resetInvoicingConfigCreate: handleResetInvoicingConfigCreate,
    invoicingConfigListState: {
      ...invoicingConfigListValues,
    },
    createInvoicingConfigState: {
      ...invoicingConfigCreateValues,
    },
    loadInvoicingBrandsNotConfigured: handleInvoicingGetBrandsNotConfigured,
    resetInvoicingBrandsNotConfigured: handleResetInvoicingBrandsNotConfigured,
    getBrandsNotConfiguredState: {
      ...invoicingGetBrandsNotConfiguredValues,
    },
    loadInvoicingConfigDetails: handleGetInvoicingConfigDetails,
    invoicingConfigDetailsState: {
      ...invoicingConfigDetailsValues,
    },
    updateInvoicingConfig: handleUpdateInvoicingConfig,
    resetUpdateInvoicingConfig: handleResetUpdateInvoicingConfig,
    updateInvoicingConfigState: {
      ...updateInvoicingConfigValues,
    },
    deleteInvoicingConfig: handleDeleteInvoicingConfig,
    deleteInvoicingConfigState: {
      ...deleteInvoicingConfigValues,
    },
    resetAllStateInvoicingConfig: handleResetAllStateInvoicingConfig,
    enableInvoicingAutoState: {
      ...enableInvoicingAutoValues,
    },
    enableInvoicingAuto: handleEnableInvoicingAuto,
    resetEnableInvoicingAuto: handleResetEnableInvoicingAuto,
  };
}

export default useInvoicing;
