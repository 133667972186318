/* eslint-disable no-unused-vars */
import produce from 'immer';

import { DEFAULT_PAGE } from 'utils/modifierGroups';

import {
  getModifierGroups,
  getPaginationModifierGroup,
  createModifierGroup,
  duplicateModifierGroup,
  deleteModifierGroup,
} from './actions';

export const initialState = {
  getModifierGroups: {
    error: null,
    fetching: false,
    fetched: false,
    list: [],
    pagination: { page: DEFAULT_PAGE },
  },

  paginationModifierGroup: {
    fetching: false,
    fetched: false,
    error: null,
  },

  createModifierGroup: {
    error: null,
    fetching: false,
    fetched: false,
    modifierGroup: {},
  },

  deleteModifierGroup: {
    error: null,
    fetching: false,
    fetched: false,
  },
  duplicateModifierGroup: {
    error: null,
    fetching: false,
    fetched: false,
    newInstance: null,
  },
};

const modifierGroupsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      /*
        List
      */
      case getModifierGroups.REQUEST:
        draft.getModifierGroups.fetching = true;
        draft.getModifierGroups.fetched = false;
        draft.getModifierGroups.error = null;
        draft.getModifierGroups.pagination = { ...initialState.getModifierGroups.pagination };
        break;

      case getModifierGroups.SUCCESS:
        draft.getModifierGroups.fetching = false;
        draft.getModifierGroups.fetched = true;
        draft.getModifierGroups.error = null;
        draft.getModifierGroups.list = payload.modifierGroups;
        draft.getModifierGroups.pagination = {
          ...draft.getModifierGroups.pagination,
          ...payload.pagination,
        };
        break;

      case getModifierGroups.FAILURE:
        draft.getModifierGroups.error = payload;
        draft.getModifierGroups.fetching = false;
        draft.getModifierGroups.fetched = false;
        break;

      case getModifierGroups.RESET:
        draft.getModifierGroups = { ...initialState.getModifierGroups };
        break;

      case getPaginationModifierGroup.REQUEST:
        draft.paginationModifierGroup.fetching = true;
        draft.paginationModifierGroup.fetched = false;
        draft.paginationModifierGroup.error = null;
        break;

      case getPaginationModifierGroup.SUCCESS: {
        const newModifiers = [...draft.getModifierGroups.list, ...payload.data];

        draft.paginationModifierGroup.fetching = false;
        draft.paginationModifierGroup.fetched = true;
        draft.paginationModifierGroup.error = null;
        draft.getModifierGroups.list = newModifiers;
        draft.getModifierGroups.pagination = {
          ...draft.getModifierGroups.pagination,
          ...payload.pagination,
          page: payload.page,
        };
        break;
      }

      case getPaginationModifierGroup.FAILURE:
        draft.paginationModifierGroup.error = payload;
        draft.paginationModifierGroup.fetching = false;
        draft.paginationModifierGroup.fetched = false;
        break;

      // create
      case createModifierGroup.REQUEST:
        draft.createModifierGroup = { ...initialState.createModifierGroup, fetching: true };
        break;

      case createModifierGroup.SUCCESS: {
        draft.createModifierGroup.fetching = false;
        draft.createModifierGroup.fetched = true;
        draft.createModifierGroup.modifierGroup = payload;
        break;
      }

      case createModifierGroup.FAILURE:
        draft.createModifierGroup.error = payload.error;
        draft.createModifierGroup.fetching = false;
        draft.createModifierGroup.fetched = false;
        break;

      case createModifierGroup.RESET:
        draft.createModifierGroup = {
          ...initialState.createModifierGroup,
        };
        break;
      /*
        Delete
      */
      case deleteModifierGroup.REQUEST:
        draft.deleteModifierGroup.fetching = true;
        draft.deleteModifierGroup.fetched = false;
        draft.deleteModifierGroup.error = false;
        break;

      case deleteModifierGroup.SUCCESS:
        draft.deleteModifierGroup.fetching = false;
        draft.deleteModifierGroup.fetched = true;
        break;

      case deleteModifierGroup.FAILURE:
        draft.deleteModifierGroup.error = true;
        draft.deleteModifierGroup.fetching = false;
        draft.deleteModifierGroup.fetched = false;
        break;

      case deleteModifierGroup.RESET:
        draft.deleteModifierGroup = { ...initialState.deleteModifierGroup };
        break;

      /*
        Duplicate
      */
      case duplicateModifierGroup.REQUEST:
        draft.duplicateModifierGroup.fetching = true;
        draft.duplicateModifierGroup.fetched = false;
        draft.duplicateModifierGroup.error = false;
        break;

      case duplicateModifierGroup.SUCCESS:
        draft.duplicateModifierGroup.fetching = false;
        draft.duplicateModifierGroup.fetched = true;
        draft.duplicateModifierGroup.newInstance = payload;
        break;

      case duplicateModifierGroup.FAILURE:
        draft.duplicateModifierGroup.error = true;
        draft.duplicateModifierGroup.fetching = false;
        draft.duplicateModifierGroup.fetched = false;
        break;

      case duplicateModifierGroup.RESET:
        draft.duplicateModifierGroup = {
          ...initialState.duplicateModifierGroup,
        };
        break;

      default:
        return draft;
    }
  });

export default modifierGroupsReducer;
