import { TFunction } from 'react-i18next';
import { TableCellProps } from '@material-ui/core/TableCell';

export interface ColumnProps {
  field: string;
  columnName?: string | number;
  isActionField?: boolean;
  isLinkField?: boolean;
  cellProps?: TableCellProps;
  isNavLink?: boolean;
}

export const getColumns = (t: TFunction): ColumnProps[] => {
  return [
    {
      columnName: t('inventoriesSuppliers:inventoryTable.headCell.name'),
      field: 'name',
      isLinkField: true,
      cellProps: { align: 'left' },
    },
    {
      field: 'actions',
      isActionField: true,
      columnName: t('common:messages.actions'),
    },
  ];
};
