import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  alert?: boolean;
}
const Subscription = forwardRef(function Subscription(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FFF', size = 24, alert = false, ...others } = props;

  if (alert) {
    return (
      <svg
        ref={ref}
        fill="none"
        height={size}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        {...others}
      >
        <path
          d="M12 23C7.02944 23 3 18.9706 3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14C21 15.2485 20.7458 16.4376 20.2863 17.5185C19.8213 18.6121 19.1462 19.5948 18.3116 20.4159"
          stroke={color}
          strokeWidth="1.5"
        />
        <circle cx="19.5" cy="5.5" fill="#F04E4A" r="4.5" stroke="black" strokeWidth="2" />
        <path
          d="M14.2931 11.162C14.2931 11.162 13.3251 10.1719 12.0593 10.3369C11.1658 10.4194 10.0489 10.9145 10.0489 12.2346C10.0489 14.7099 14.3675 13.2248 14.442 16.0301C14.442 17.5153 13.1762 18.0928 12.0593 18.0103C10.868 17.8453 9.90002 16.9377 9.90002 16.9377"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path d="M12.3224 18.4004V19.5003" stroke={color} strokeMiterlimit="10" strokeWidth="1.5" />
        <path d="M12.3224 8.5V10.1499" stroke={color} strokeMiterlimit="10" strokeWidth="1.5" />
        <path d="M17.8346 16.9805L18.0046 20.8819L21.8114 20.0111" stroke={color} strokeWidth="1.5" />
      </svg>
    );
  }

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 13.2485 20.7458 14.4376 20.2863 15.5185C19.8213 16.6121 19.1462 17.5948 18.3116 18.4159"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M14.2929 9.16201C14.2929 9.16201 13.325 8.17189 12.0592 8.33691C11.1657 8.41942 10.0488 8.91448 10.0488 10.2346C10.0488 12.7099 14.3674 11.2248 14.4419 14.0301C14.4419 15.5153 13.1761 16.0928 12.0592 16.0103C10.8679 15.8453 9.8999 14.9377 9.8999 14.9377"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path d="M12.3223 16.4004V17.5003" stroke={color} strokeMiterlimit="10" strokeWidth="1.5" />
      <path d="M12.3223 6.5V8.14992" stroke={color} strokeMiterlimit="10" strokeWidth="1.5" />
      <path d="M17.8347 14.9805L18.0048 18.8819L21.8116 18.0111" stroke={color} strokeWidth="1.5" />
    </svg>
  );
});

export default Subscription;
