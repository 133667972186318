import { call, put } from 'redux-saga/effects';

import { duplicateProductRequest } from 'utils/api/baas/menuMaker';

import baasRequest from 'utils/baasRequest';

import { duplicateProduct } from '../actions';

export function* handleDuplicateProductRequest(action) {
  const { storeUuid, productUuid } = action.payload;
  try {
    yield put(duplicateProduct.request());

    const { data } = yield call(baasRequest, duplicateProductRequest, {
      storeUuid,
      productUuid,
    });

    yield put(duplicateProduct.success({ product: data.objectDetail }));
  } catch (err) {
    yield put(duplicateProduct.failure({ error: err, productUuid }));
  }
}
