import produce from 'immer';

import { DEFAULT_SIZE_PAGE, DEFAULT_PAGE, sortingOrderTypes, sortingPropertyTypes } from 'utils/employees';

import { getEmployees, createEmployee, deleteEmployee } from './actions';

export const initialState = {
  getEmployees: {
    error: null,
    fetching: false,
    fetched: false,
    employees: [],
    pagination: {
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_SIZE_PAGE,
      count: 0,
    },
  },

  sorting: {
    property: sortingPropertyTypes.LAST_NAME,
    filterByText: '',
    direction: sortingOrderTypes.ASC,
  },

  createEmployee: {
    error: null,
    fetching: false,
    fetched: false,
    employee: {},
  },

  deleteEmployee: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const employeesProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getEmployees.REQUEST:
        draft.getEmployees.fetching = true;
        draft.getEmployees.error = false;
        draft.getEmployees.fetched = false;
        draft.getEmployees.pagination = { ...initialState.getEmployees.pagination };
        draft.sorting = {
          ...initialState.sorting,
        };
        break;

      case getEmployees.SUCCESS:
        draft.getEmployees.fetching = false;
        draft.getEmployees.fetched = true;
        draft.getEmployees.employees = payload.data;
        draft.getEmployees.pagination = {
          ...draft.getEmployees.pagination,
          ...payload.pagination,
        };
        break;

      case getEmployees.FAILURE:
        draft.getEmployees.error = payload;
        draft.getEmployees.fetching = false;
        draft.getEmployees.fetched = false;
        break;

      case getEmployees.SORTING:
        draft.getEmployees.pagination = {
          ...draft.getEmployees.pagination,
          ...initialState.getEmployees.pagination,
        };

        draft.sorting = {
          ...draft.sorting,
          ...payload,
        };
        break;

      case getEmployees.PAGINATION:
        draft.getEmployees.pagination = {
          ...draft.getEmployees.pagination,
          ...payload,
        };
        break;

      case getEmployees.RESET_PAGINATION:
        draft.getEmployees.pagination = { ...initialState.getEmployees.pagination };
        break;

      case getEmployees.RESET_SORTING:
        draft.sorting = {
          ...initialState.sorting,
        };
        break;

      case getEmployees.RESET:
        draft.getEmployees = { ...initialState.getEmployees };
        break;

      case createEmployee.REQUEST:
        draft.createEmployee.fetching = true;
        draft.createEmployee.error = false;
        draft.createEmployee.fetched = false;
        break;

      case createEmployee.SUCCESS: {
        const employeesList = [...draft.getEmployees.employees];
        employeesList.unshift(payload);

        draft.createEmployee.fetching = false;
        draft.createEmployee.fetched = true;
        draft.createEmployee.employee = payload;
        draft.getEmployees.employees = employeesList;

        break;
      }

      case createEmployee.FAILURE:
        draft.createEmployee.error = payload;
        draft.createEmployee.fetching = false;
        draft.createEmployee.fetched = false;
        break;

      case createEmployee.RESET:
        draft.createEmployee = { ...initialState.createEmployee };
        break;

      case deleteEmployee.REQUEST:
        draft.deleteEmployee.fetching = true;
        draft.deleteEmployee.error = false;
        draft.deleteEmployee.fetched = false;
        break;

      case deleteEmployee.SUCCESS: {
        const employeesList = draft.getEmployees.employees.filter((employee) => employee.uuid !== payload);

        draft.deleteEmployee.fetching = false;
        draft.deleteEmployee.fetched = true;
        draft.getEmployees.employees = employeesList;

        break;
      }

      case deleteEmployee.FAILURE:
        draft.deleteEmployee.error = payload;
        draft.deleteEmployee.fetching = false;
        draft.deleteEmployee.fetched = false;
        break;

      case deleteEmployee.RESET:
        draft.deleteEmployee = { ...initialState.deleteEmployee };
        break;

      default:
        return draft;
    }
  });

export default employeesProviderReducer;
