import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectInvoiceCatalogProviderDomain = (state) => state.InvoiceCatalogProvider || initialState;

const selectInvoiceCancellations = createSelector(
  selectInvoiceCatalogProviderDomain,
  ({ CancellationReasons }) => CancellationReasons.list,
);

const selectInvoiceCancellationsState = createSelector(
  selectInvoiceCatalogProviderDomain,
  ({ CancellationReasons: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectInvoiceTaxRegime = createSelector(selectInvoiceCatalogProviderDomain, ({ TaxRegime }) => TaxRegime.data);

const selectInvoiceTaxRegimeState = createSelector(
  selectInvoiceCatalogProviderDomain,
  ({ TaxRegime: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

export {
  selectInvoiceCancellations,
  selectInvoiceCancellationsState,
  selectInvoiceTaxRegime,
  selectInvoiceTaxRegimeState,
};
