export function cleanFilter(filters = {}) {
  const newFilter = { ...filters };
  const keys = Object.keys(newFilter);
  if (keys.length > 0) {
    keys.forEach((key) => {
      if (!newFilter[key]) {
        delete newFilter[key];
      }
    });
    return newFilter;
  }
  return filters;
}
