import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getSubscriptionHome,
  deletePaymentMethod,
  savePaymentMethod,
  getSubscriptionPayments,
  getSubscriptionPaymentsByPagination,
  getSubscriptionInvoiceData,
  updateSubscriptionInvoiceData,
} from './actions';

import {
  selectSubscription,
  selectSubscriptionState,
  selectDeletePaymentMethodState,
  selectSavePaymentMethod,
  selectSavePaymentMethodState,
  selectSubscriptionPayments,
  selectSubscriptionPaymentsState,
  selectSubscriptionPaymentsPagination,
  selectSubscriptionPaymentsPaginationStatus,
  selectSubscriptionInvoiceData,
  selectSubscriptionInvoiceDataState,
  selectUpdateSubscriptionInvoiceData,
  selectUpdateSubscriptionInvoiceDataState,
} from './selectors';

export function withSubscriptionHoc(Component) {
  function withSubscription(props) {
    return <Component {...props} />;
  }

  withSubscription.displayName = `withSubscription(${getDisplayName(Component)})`;

  return withSubscription;
}

const mapStateToProps = createStructuredSelector({
  subscription: selectSubscription,
  subscriptionState: selectSubscriptionState,
  deletePaymentMethodState: selectDeletePaymentMethodState,
  paymentMethodObject: selectSavePaymentMethod,
  paymentMethodState: selectSavePaymentMethodState,
  subscriptionPayments: selectSubscriptionPayments,
  subscriptionPaymentsState: selectSubscriptionPaymentsState,
  subscriptionPaymentsPaginationValues: selectSubscriptionPaymentsPagination,
  subscriptionPaymentsPaginationStatus: selectSubscriptionPaymentsPaginationStatus,
  subscriptionInvoiceData: selectSubscriptionInvoiceData,
  subscriptionInvoiceDataState: selectSubscriptionInvoiceDataState,
  updateSubscriptionInvoiceData: selectUpdateSubscriptionInvoiceData,
  updateSubscriptionInvoiceDataState: selectUpdateSubscriptionInvoiceDataState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadSubscription: () => dispatch(getSubscriptionHome()),
    deletePaymentMethod: (paymentMethodUuid) => dispatch(deletePaymentMethod(paymentMethodUuid)),
    savePaymentMethod: (paymentMethod) => dispatch(savePaymentMethod(paymentMethod)),
    loadSubscriptionPayments: (filters) => dispatch(getSubscriptionPayments(filters)),
    loadSubscriptionPaymentsByPagination: ({ page }) => dispatch(getSubscriptionPaymentsByPagination({ page })),
    loadSubscriptionInvoiceData: () => dispatch(getSubscriptionInvoiceData()),
    updateSubscriptionInvoiceData: (invoiceData) => dispatch(updateSubscriptionInvoiceData(invoiceData)),
    resetSubscription: () => dispatch(getSubscriptionHome.reset()),
    resetDeletePaymentMethod: () => dispatch(deletePaymentMethod.reset()),
    resetSavePaymentMethod: () => dispatch(savePaymentMethod.reset()),
    resetSubscriptionPayments: () => dispatch(getSubscriptionPayments.reset()),
    resetSubscriptionPaymentsByPagination: () => dispatch(getSubscriptionPaymentsByPagination.reset()),
    resetSubscriptionInvoiceData: () => dispatch(getSubscriptionInvoiceData.reset()),
    resetUpdateSubscriptionInvoiceData: () => dispatch(updateSubscriptionInvoiceData.reset()),
  };
}

export const withSubscriptionState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withSubscriptionState, withSubscriptionHoc);
