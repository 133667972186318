import { Children } from 'react';
import PropTypes from 'prop-types';

import Roles from 'providers/workforce/Roles';
import RoleDetails from 'providers/workforce/RoleDetails';
import Permissions from 'providers/workforce/Permissions';
import Employees from 'providers/workforce/Employees';
import EmployeeDetails from 'providers/workforce/EmployeeDetails';
import Pin from 'providers/workforce/Pin';
import Email from 'providers/workforce/Email';

import ComposeProviders from 'components/ComposeProviders';

function WorkforceRootProvider({ children }) {
  return (
    <ComposeProviders
      providers={[<Roles />, <RoleDetails />, <Permissions />, <Employees />, <EmployeeDetails />, <Pin />, <Email />]}
    >
      {Children.only(children)}
    </ComposeProviders>
  );
}

WorkforceRootProvider.propTypes = {
  children: PropTypes.element,
};

export default WorkforceRootProvider;
