import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import useNotification from '@design-system/Notification/useNotification';

import { getStoreSettings, updateSettingsRequest } from 'utils/api/baas/settings/store';
import { UpdateSettingsProps } from 'utils/api/baas/settings/types';

export function useStoreSettings(enabled = true) {
  return useQuery('settings-store', () => getStoreSettings(), { enabled, retry: 1 });
}

export function useUpdateStoreSettings() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setNotification } = useNotification();

  return useMutation((values: UpdateSettingsProps) => updateSettingsRequest(values), {
    mutationKey: 'update-store-settings',
    onSuccess: ({ data }) => {
      queryClient.setQueryData('settings-store', data);
      setNotification(t('settings:messages.settingSavedSuccessfully'), { variant: 'success' });
    },
    onError: () => {
      setNotification(t('settings:messages.settingSavedError'), { variant: 'error' });
    },
  });
}
