/**
 *
 * ParrotSoftware
 * SettingsTablesConfigProvider reducer
 *
 */
import produce from 'immer';
import baseState from 'utils/baseState';

import { updateTableConfig, getStoreTableSettings } from './actions';

export const initialState = {
  getStoreTableSettings: {
    ...baseState,
    config: {},
  },

  updateTableConfig: {
    ...baseState,
  },
};

const settingsTablesConfigReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getStoreTableSettings.REQUEST:
        draft.getStoreTableSettings = {
          ...initialState.getStoreTableSettings,
          fetching: true,
        };
        break;

      case getStoreTableSettings.SUCCESS:
        draft.getStoreTableSettings.fetching = false;
        draft.getStoreTableSettings.fetched = true;
        draft.getStoreTableSettings.config = payload;
        break;

      case getStoreTableSettings.FAILURE:
        draft.getStoreTableSettings.error = payload;
        draft.getStoreTableSettings.fetching = false;
        draft.getStoreTableSettings.fetched = false;
        break;

      case getStoreTableSettings.RESET:
        draft.getStoreTableSettings = {
          ...initialState.getStoreTableSettings,
        };
        break;

      case updateTableConfig.REQUEST:
        draft.updateTableConfig = {
          ...initialState.updateTableConfig,
          fetching: true,
        };
        break;

      case updateTableConfig.SUCCESS:
        draft.updateTableConfig.fetching = false;
        draft.updateTableConfig.fetched = true;
        break;

      case updateTableConfig.FAILURE:
        draft.updateTableConfig.error = payload;
        draft.updateTableConfig.fetching = false;
        draft.updateTableConfig.fetched = false;
        break;

      case updateTableConfig.RESET:
        draft.updateTableConfig = {
          ...initialState.updateTableConfig,
        };
        break;

      default:
        return draft;
    }
  });

export default settingsTablesConfigReducer;
