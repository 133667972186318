import { useState, Children } from 'react';
import PropTypes from 'prop-types';

import DrawerStateContext from './DrawerStateContext';

function DrawerState({ children }) {
  const [keepOpen, setKeepOpen] = useState(undefined);
  const [drawerIsDirty, setDrawerIsDirty] = useState(false);

  return (
    <DrawerStateContext.Provider value={{ keepOpen, setKeepOpen, drawerIsDirty, setDrawerIsDirty }}>
      {Children.only(children)}
    </DrawerStateContext.Provider>
  );
}

DrawerState.propTypes = {
  children: PropTypes.node,
};

export { default as DrawerStateContext } from './DrawerStateContext';
export default DrawerState;
