import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';
import { reportFormatTypes } from 'utils/reports';

import {
  getReports,
  getReportsByPagination,
  exportReportsToExcel,
  getReportsSessions,
  getReportsSessionsDetails,
  exportReportsToExcelDetails,
  getReportsSessionsDetailsPagination,
  getReportsProductDetails,
  getReportsSessionsPagination,
  getReportsSessionsDetailsAll,
  getReportsBySorting,
} from './actions';

export function withReportsHoc(Component) {
  function withReports(props) {
    return <Component {...props} />;
  }

  withReports.displayName = `withReports(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withReports;
}

const mapStateToProps = createStructuredSelector({});

export function mapDispatchToProps(dispatch) {
  return {
    loadReportsSessions: ({ key, type, ...filters }) => dispatch(getReportsSessions({ key, type, ...filters })),
    loadReportsSessionsPagination: ({ key, type, page }) => dispatch(getReportsSessionsPagination({ key, type, page })),
    loadReportsSessionsDetails: ({ key, storeUuid, type, ...filters }) =>
      dispatch(getReportsSessionsDetails({ key, storeUuid, type, ...filters })),
    exportReportsToExcelDetails: ({ key, storeUuid, type, showModifiedTickets, ...filters }) =>
      dispatch(exportReportsToExcelDetails({ key, storeUuid, type, showModifiedTickets, ...filters })),
    loadReports: ({ key, type, reportFormat = reportFormatTypes.REPORT_FORMAT_TABLE, ...filters }) =>
      dispatch(getReports({ key, type, reportFormat, ...filters })),
    loadReportsProductDetails: ({ key, type, ...filters }) =>
      dispatch(getReportsProductDetails({ key, type, ...filters })),
    exportReportsToExcel: ({ key, type, nameFile, ...filters }) =>
      dispatch(exportReportsToExcel({ key, type, nameFile, ...filters })),
    loadReportsByPagination: ({ ...filters }) => dispatch(getReportsByPagination({ ...filters })),
    loadReportsSessionsDetailsPagination: ({ key, storeUuid, type, page }) =>
      dispatch(getReportsSessionsDetailsPagination({ key, storeUuid, type, page })),
    loadReportsSessionsDetailsAll: ({ key, storeUuid, type }) =>
      dispatch(getReportsSessionsDetailsAll({ key, storeUuid, type })),
    loadReportsBySorting: ({ reportFormat = reportFormatTypes.REPORT_FORMAT_TABLE, ...filters }) =>
      dispatch(getReportsBySorting({ reportFormat, ...filters })),
    resetReports: (key) => dispatch(getReports.reset(key)),
    resetReportsByPagination: (key) => dispatch(getReportsByPagination.reset(key)),
    resetReportsSessions: (key) => dispatch(getReportsSessions.reset(key)),
    resetReportsProductDetails: (key) => dispatch(getReportsProductDetails.reset(key)),
    resetReportsSessionsDetails: (key) => dispatch(getReportsSessionsDetails.reset(key)),
    resetExportReportsToExcelDetails: (key) => dispatch(exportReportsToExcelDetails.reset(key)),
    resetReportsSessionsDetailsAll: (key) => dispatch(getReportsSessionsDetailsAll.reset(key)),
    resetReportsBySorting: (key) => dispatch(getReportsBySorting.reset(key)),
  };
}

export const withReportsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withReportsState, withReportsHoc);
