import PropTypes from 'prop-types';

import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import IconButton from '@design-system/IconButton';
import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';

import useStyles from './styles';

function Accordion({
  actionComponent: actionComponentProp,
  children,
  expanded,
  disabledTitle,
  title,
  onClick,
  iconSize = 15,
  headerStyle,
}) {
  const classes = useStyles();
  return (
    <Box width="100%">
      <Box className={clsx(classes.accordionHeader, headerStyle)}>
        {actionComponentProp && <>{actionComponentProp}</>}
        <Box className={classes.accordionAreaAction} onClick={onClick}>
          <Box className={clsx(classes.labelSection, { [classes.labeDisabled]: disabledTitle })} component="span">
            {title}
          </Box>
          <IconButton>
            <ChevronDown className={clsx({ [classes.endIconRotate]: expanded })} color="#000" lighter size={iconSize} />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  );
}

Accordion.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  actionComponent: PropTypes.element,
  iconSize: PropTypes.number,
  headerStyle: PropTypes.string,
};

export default Accordion;
