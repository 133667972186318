import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const HelpEmail = forwardRef(function HelpEmail(props, ref) {
  const { color = '#fff', size = 13, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M22 17.619c0-.095 0-.095 0 0V6.476 6.19v-.095L21.9 6h-.099H2.298 2.2h-.1S2 6 2 6.095v11.429c0 .19.199.476.498.476H21.807S22 18 22 17.877v-.073-.185zM2.796 7.333L8.468 12l-5.672 4.667V7.333zm9.154 6.381L3.592 6.857h16.716l-8.358 6.857zm-2.786-1.143l2.488 2c.1.096.199.096.298.096.1 0 .2 0 .299-.096l2.487-2.095 5.572 4.572H3.592l5.572-4.477zM15.433 12l5.672-4.571v9.238L15.433 12z"
        fill={color}
      />
    </svg>
  );
});

HelpEmail.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default HelpEmail;
