import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getReportsFilters } from './actions';
import {
  selectReportsFiltersFetching,
  selectReportsFiltersFetched,
  selectReportsFiltersError,
  selectReportsFiltersBrands,
  selectReportsFiltersProviders,
  selectReportsFiltersOrderTypes,
  selectReportsFiltersCategories,
  selectReportsFiltersOrderStates,
  selectReportsFiltersPaymentTypes,
  selectReportsFiltersUsers,
} from './selectors';

export function withReportsFiltersHoc(Component) {
  function withReportsFilters(props) {
    return <Component {...props} />;
  }

  withReportsFilters.displayName = `withReportsFilters(${getDisplayName(Component)})`;

  return withReportsFilters;
}

const mapStateToProps = createStructuredSelector({
  reportsFiltersFetching: selectReportsFiltersFetching,
  reportsFiltersFetched: selectReportsFiltersFetched,
  reportsFiltersError: selectReportsFiltersError,
  reportsFiltersBrands: selectReportsFiltersBrands,
  reportsFiltersProviders: selectReportsFiltersProviders,
  reportsFiltersOrderTypes: selectReportsFiltersOrderTypes,
  reportsFiltersCategories: selectReportsFiltersCategories,
  reportsFiltersOrderStates: selectReportsFiltersOrderStates,
  reportsFiltersPaymentTypes: selectReportsFiltersPaymentTypes,
  reportsFiltersUsers: selectReportsFiltersUsers,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadReportsFilters: (filters = {}) => dispatch(getReportsFilters(filters)),
    resetReportsFilters: () => dispatch(getReportsFilters.reset()),
  };
}

export const withReportsFiltersState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withReportsFiltersState, withReportsFiltersHoc);
