import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  dialogTitleRoot: {
    padding: '48px 24px 24px 24px',
  },
}));

function DialogHead({ title, subtitle }) {
  const classes = useStyles();

  return (
    <DialogTitle classes={{ root: classes.dialogTitleRoot }} id="draggable-dialog-title">
      <Box alignItems="center" display="flex" flexDirection="column">
        <Box>
          <Box component="h4" fontSize={24} fontWeight="fontWeightMedium" m={0}>
            {title}
          </Box>
        </Box>

        <Box mt={2}>
          <Box
            color="text.secondary"
            component="p"
            fontSize={16}
            fontWeight="fontWeightRegular"
            m={0}
            textAlign="center"
          >
            {subtitle}
          </Box>
        </Box>
      </Box>
    </DialogTitle>
  );
}

DialogHead.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
};

export default DialogHead;
