import produce from 'immer';

import baseState from 'utils/baseState';

import { getSettingsStore, updateSettingsStore, getSettingsCustomizePrints } from './actions';

export const initialState = {
  getSettingsStore: {
    ...baseState,
    data: {},
  },
  updateSettingsStore: {
    ...baseState,
    updateData: {},
  },
  getSettingsCustomizePrints: {
    ...baseState,
    customizePrintsList: [],
  },
};

const settingsStoreProvider = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getSettingsStore.REQUEST:
        draft.getSettingsStore = {
          ...initialState.getSettingsStore,
          fetching: true,
        };
        break;

      case getSettingsStore.SUCCESS:
        draft.getSettingsStore.fetching = false;
        draft.getSettingsStore.fetched = true;
        draft.getSettingsStore.data = payload;
        break;

      case getSettingsStore.FAILURE:
        draft.getSettingsStore.error = payload;
        draft.getSettingsStore.fetching = false;
        draft.getSettingsStore.fetched = false;
        break;

      case getSettingsStore.RESET:
        draft.getSettingsStore = {
          ...initialState.getSettingsStore,
        };
        break;

      case updateSettingsStore.REQUEST:
        draft.updateSettingsStore = {
          ...initialState.updateSettingsStore,
          fetching: true,
        };
        break;

      case updateSettingsStore.SUCCESS:
        draft.updateSettingsStore.fetching = false;
        draft.updateSettingsStore.fetched = true;
        draft.updateSettingsStore.updateData = payload;
        break;

      case updateSettingsStore.FAILURE:
        draft.updateSettingsStore.error = payload;
        draft.updateSettingsStore.fetching = false;
        draft.updateSettingsStore.fetched = false;
        break;

      case updateSettingsStore.RESET:
        draft.updateSettingsStore = {
          ...initialState.updateSettingsStore,
        };
        break;

      case getSettingsCustomizePrints.REQUEST:
        draft.getSettingsCustomizePrints = {
          ...initialState.getSettingsCustomizePrints,
          fetching: true,
        };
        break;

      case getSettingsCustomizePrints.SUCCESS:
        draft.getSettingsCustomizePrints.fetching = false;
        draft.getSettingsCustomizePrints.fetched = true;
        draft.getSettingsCustomizePrints.customizePrintsList = payload;
        break;

      case getSettingsCustomizePrints.FAILURE:
        draft.getSettingsCustomizePrints.error = payload;
        draft.getSettingsCustomizePrints.fetching = false;
        draft.getSettingsCustomizePrints.fetched = false;
        break;

      case getSettingsCustomizePrints.RESET:
        draft.getSettingsCustomizePrints = {
          ...initialState.getSettingsCustomizePrints,
        };
        break;

      default:
        return draft;
    }
  });

export default settingsStoreProvider;
