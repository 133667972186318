import { forwardRef, Ref, SVGProps } from 'react';

const StoreDraft = forwardRef(function StoreDraft(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg ref={ref} fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1629_33401)">
        <path
          d="M12.9 18.8008C12.9 18.8008 10.6 19.1008 10.2 16.8008C10.2 16.8008 10.3 19.1008 7.69995 20.0008C7.69995 20.0008 9.99995 19.7008 10.7 22.4008C10.7 22.3008 9.99995 19.0008 12.9 18.8008Z"
          fill="#F04E4A"
        />
        <path d="M42.9835 36.406H2.72286L1.50952 11.3672L42.9835 12.3599V36.406Z" fill="#010101" />
        <path d="M40.667 13.5757L2.94336 12.583L3.93609 35.1951H41.8803V13.686L40.667 13.5757Z" fill="white" />
        <path d="M1.50952 36.4072H44.1968L42.9835 38.7236L2.94346 38.6133L1.50952 36.4072Z" fill="#010101" />
        <path d="M18.9377 36.4072L19.9305 37.1793H26.2177L26.8796 36.4072" fill="#B2B2B2" />
        <path d="M41.8806 35.749H4.04663V35.8593H41.8806V35.749Z" fill="white" />
        <path d="M3.54192 14.3321L3.53931 14.4424L40.7003 15.3242L40.7029 15.2139L3.54192 14.3321Z" fill="#010101" />
        <path
          d="M4.37778 13.7937C4.56054 13.7937 4.70869 13.6455 4.70869 13.4627C4.70869 13.28 4.56054 13.1318 4.37778 13.1318C4.19503 13.1318 4.04688 13.28 4.04688 13.4627C4.04688 13.6455 4.19503 13.7937 4.37778 13.7937Z"
          fill="#F05351"
        />
        <path
          d="M5.70103 13.7937C5.88378 13.7937 6.03194 13.6455 6.03194 13.4627C6.03194 13.28 5.88378 13.1318 5.70103 13.1318C5.51827 13.1318 5.37012 13.28 5.37012 13.4627C5.37012 13.6455 5.51827 13.7937 5.70103 13.7937Z"
          fill="#D8D9D8"
        />
        <path
          d="M7.02476 13.7937C7.20751 13.7937 7.35567 13.6455 7.35567 13.4627C7.35567 13.28 7.20751 13.1318 7.02476 13.1318C6.842 13.1318 6.69385 13.28 6.69385 13.4627C6.69385 13.6455 6.842 13.7937 7.02476 13.7937Z"
          fill="#B2B2B2"
        />
        <path d="M40.6675 14.3438H39.5645V14.4541H40.6675V14.3438Z" fill="#B2B2B2" />
        <path d="M16.2905 16.2313L16.5111 33.8798H5.0396L4.04688 15.9004L16.2905 16.2313Z" fill="#D7D7D6" />
        <path d="M13.643 17.876H5.70117V17.9863H13.643V17.876Z" fill="#B2B2B2" />
        <path d="M13.643 19.0918H5.70117V19.2021H13.643V19.0918Z" fill="#B2B2B2" />
        <path d="M25.9536 32.0382L25.9507 32.1484L38.0804 32.4643L38.0833 32.354L25.9536 32.0382Z" fill="#A3A3AF" />
        <path d="M28.3476 30.8595L28.3433 30.9697L37.932 31.3458L37.9363 31.2356L28.3476 30.8595Z" fill="#A3A3AF" />
        <path
          d="M39.3439 33.8798H5.0396L4.04688 15.9004L40.7778 16.6725L39.3439 33.8798ZM5.14991 33.7695H39.3439L40.7778 16.6725L4.15718 16.0107L5.14991 33.7695Z"
          fill="#A3A3AF"
        />
        <path
          d="M27.6904 20.7774L45.3424 8.4832C46.1772 7.90245 47.1538 8.11212 47.7345 8.94693L49.0933 10.8582C49.674 11.6931 49.4643 12.6696 48.6295 13.2504L30.9776 25.5446L30.747 25.6322L25.1476 27.283L23.5446 27.786C23.0834 27.9611 22.8097 27.3791 23.0625 27.0721L24.0847 25.7342L27.5474 21.0956L27.6904 20.7774Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
          strokeWidth="0.15"
        />
        <path
          d="M43.5632 9.62855L45.3536 8.36842C46.1884 7.78768 47.2748 8.00844 47.8555 8.84325L49.3129 10.8754C49.8937 11.7102 49.6729 12.7966 48.8381 13.3773L47.0476 14.6374L43.5632 9.62855Z"
          fill="#010101"
        />
        <path
          d="M27.702 20.671L31.0767 25.6687L25.1369 27.396L23.4242 27.8879C22.963 28.063 22.5795 27.4699 22.8434 27.0531L23.8768 25.6055L27.702 20.671Z"
          fill="#F8B5BA"
        />
        <path
          d="M25.0164 27.4892L23.5231 28.0033C23.0619 28.1784 22.4701 27.4533 22.734 27.0365L23.7563 25.6987L25.0164 27.4892Z"
          fill="black"
        />
        <path d="M28.7651 22.2128L42.5623 11.8568" stroke="black" strokeMiterlimit="10" strokeWidth="0.13" />
        <path
          d="M1.73754 41.2688C1.73754 41.2688 1.41898 44.1947 3.36178 44.9884C3.36178 44.9884 17.5971 47.3782 23.267 40.8267C23.267 40.8267 24.6272 38.2637 24.0257 36.302C24.0573 36.5466 18.454 45.9246 1.73754 41.2688Z"
          fill="#F59099"
        />
        <path
          d="M2.26749 40.7549C1.80985 41.0627 1.13917 41.6467 1.47853 42.3489C1.98993 43.2279 3.59337 43.3194 4.39508 43.3651C5.7539 43.488 7.14901 43.5069 8.54412 43.5257C11.7873 43.6043 15.1756 43.2663 18.3603 42.507C20.9153 41.8787 23.9327 40.5938 24.4233 37.8446C24.723 36.3137 22.8165 35.4159 21.5303 35.0846C18.7495 34.3494 15.6373 34.9004 13.046 35.6329C12.1354 35.8997 11.2249 36.1664 10.4184 36.4695"
          fill="#F2F2F3"
        />
        <path
          d="M1.81125 38.3795C1.81125 38.3795 -1.17924 40.2578 0.504823 42.1294C1.19296 42.8363 2.21242 42.2571 3.01414 42.3028C3.95631 42.2806 4.88906 42.956 5.61349 43.5588C6.06173 43.9485 6.37421 44.0574 6.96761 44.0305C7.80561 43.9721 8.37213 43.3518 9.14226 43.1529C9.9124 42.954 10.8183 43.036 11.5111 43.3942C12.0998 43.7161 12.7611 43.8296 13.4949 43.7349C14.0883 43.708 14.718 43.577 15.3161 43.2013C16.6165 42.4863 17.7179 41.0012 19.2803 41.5455C19.8374 41.6228 20.3267 41.5596 20.9564 41.4286C22.3562 41.0986 25.9999 37.3454 24.1707 35.8905"
          fill="#F05351"
        />
        <path
          d="M1.2356 39.6973C0.968817 38.7867 2.07497 36.9528 2.53732 36.2962C3.74764 34.4986 5.47406 33.2312 7.36782 32.4893C9.96383 31.408 12.5188 30.7797 15.3406 31.0619C17.0482 31.1896 18.7148 31.7703 20.3089 32.5592C21.3821 33.1667 22.6999 33.7427 23.5238 34.7306C24.4834 35.9993 24.1837 37.5302 22.847 38.3494C19.1637 39.8694 15.1726 40.9319 11.2587 41.4372C9.47856 41.5178 7.69838 41.5985 5.88663 41.4345C4.42364 41.2752 2.19053 41.3148 1.26718 39.9419C1.30347 39.8377 1.19931 39.8014 1.2356 39.6973Z"
          fill="#F8B5BA"
        />
        <path d="M6.27562 33.2708C6.27562 33.2708 9.69552 33.1774 11.9273 35.8238L6.27562 33.2708Z" fill="#F59099" />
        <path d="M11.4674 31.1107C11.4674 31.1107 15.5438 31.4796 15.9228 34.415L11.4674 31.1107Z" fill="#F59099" />
        <path
          d="M12.4121 26.0257C12.4121 26.0257 10.3942 26.3266 10.1104 24C10.1104 24 10.2056 26.292 8 27.135C8 27.135 9.95236 26.789 10.5361 29.5152C10.5361 29.5152 9.9362 26.3067 12.4121 26.0257Z"
          fill="#F04E4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1629_33401">
          <rect fill="white" height="50" width="50" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default StoreDraft;
