import { takeLatest, put, call, delay } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getVoidReasonsRequest,
  createVoidReasonRequest,
  deleteVoidReasonRequest,
} from 'utils/api/baas/restaurant/voidReasons';

import { getVoidReasons, getVoidReasonsByPagination, createVoidReason, deleteVoidReason } from './actions';

export function* handleGetVoidReasonsRequest(action) {
  const { storeUuid } = action.payload;

  try {
    yield put(getVoidReasons.request());

    const { data: voidReasons, pagination } = yield call(baasRequest, getVoidReasonsRequest, { storeUuid });

    yield put(getVoidReasons.success({ voidReasons, pagination }));
  } catch (err) {
    yield put(getVoidReasons.failure(err));
  } finally {
    yield put(getVoidReasons.fulfill());
  }
}

export function* handleGetVoidReasonsByPaginationRequest(action) {
  const { storeUuid, page } = action.payload;

  try {
    yield put(getVoidReasonsByPagination.request({ page }));

    const { data: voidReasons, pagination } = yield call(baasRequest, getVoidReasonsRequest, { storeUuid, page });

    yield put(getVoidReasonsByPagination.success({ voidReasons, pagination }));
  } catch (err) {
    yield put(getVoidReasonsByPagination.failure(err));
  } finally {
    yield put(getVoidReasonsByPagination.fulfill());
  }
}

export function* handleCreateVoidReasonRequest(action) {
  const { storeUuid, voidReason } = action.payload;

  try {
    yield put(createVoidReason.request());

    const { data } = yield call(baasRequest, createVoidReasonRequest, {
      storeUuid,
      voidReason,
    });

    yield put(createVoidReason.success(data));
  } catch (err) {
    yield put(createVoidReason.failure(err));
  } finally {
    yield put(createVoidReason.fulfill());
  }
}

export function* handleDeleteVoidReasonRequest(action) {
  const { storeUuid, voidReasonUuid } = action.payload;

  try {
    yield put(deleteVoidReason.request());

    yield call(baasRequest, deleteVoidReasonRequest, {
      storeUuid,

      voidReasonUuid,
    });

    yield put(deleteVoidReason.success());
  } catch (err) {
    yield put(deleteVoidReason.failure(err));
  } finally {
    yield delay(10);
    yield put(deleteVoidReason.fulfill(voidReasonUuid));
  }
}

export default function* restaurantVoidReasonsProviderSaga() {
  yield takeLatest(getVoidReasons.TRIGGER, handleGetVoidReasonsRequest);
  yield takeLatest(getVoidReasonsByPagination.TRIGGER, handleGetVoidReasonsByPaginationRequest);
  yield takeLatest(createVoidReason.TRIGGER, handleCreateVoidReasonRequest);
  yield takeLatest(deleteVoidReason.TRIGGER, handleDeleteVoidReasonRequest);
}
