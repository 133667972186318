import queryString from 'query-string';

import request from 'utils/request';

export const API_BASE_URL = `https://api.copomex.com`;

export const TOKEN_COPOMEX = `${process.env.REACT_APP_TOKEN_COPOMEX}`;

export function getStatesRequest(options = {}) {
  const query = queryString.stringify({
    token: TOKEN_COPOMEX,
  });

  return request(`${API_BASE_URL}/query/get_estados/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getMunicipalityListRequest(state, options = {}) {
  const query = queryString.stringify({
    token: TOKEN_COPOMEX,
  });

  return request(`${API_BASE_URL}/query/get_municipio_por_estado/${state}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getNeighborhoodListRequest(municipality, options = {}) {
  const query = queryString.stringify({
    token: TOKEN_COPOMEX,
  });

  return request(`${API_BASE_URL}/query/get_colonia_por_municipio/${municipality}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getInfoZipCodeRequest(zipCode, options = {}) {
  const query = queryString.stringify({
    token: TOKEN_COPOMEX,
    type: 'simplified',
  });

  return request(`${API_BASE_URL}/query/info_cp/${zipCode}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getInfoZipCodeGeocodingRequest({ zipCode, street, numberExterior }, options = {}) {
  const query = queryString.stringify({
    token: TOKEN_COPOMEX,
    calle: street,
    numero: numberExterior,
    type: 'simplified',
  });

  return request(`${API_BASE_URL}/query/info_cp_geocoding/${zipCode}/?${query}`, {
    method: 'GET',
    ...options,
  });
}
