import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import useStyles from './styles';

function SidebarLayoutItem({ disabled, label, startIcon, endIcon, complementIcon, onClick, openMenu, ...others }) {
  const classes = useStyles();
  const { setOpenDrawer } = useSidebarLayout();

  function handleClick() {
    setOpenDrawer(false);

    if (onClick) {
      onClick();
    }
  }

  return (
    <li className={classes.item} data-testid="navLinkItem">
      <NavLink
        activeClassName={classes.navLinkActive}
        className={clsx(classes.navLink, {
          [classes.navLinkEndIcon]: endIcon,
          [classes.navLinkStartIcon]: startIcon,
          [classes.disabled]: disabled,
          [classes.collapseMenu]: !openMenu,
        })}
        disabled={disabled}
        exact
        onClick={handleClick}
        {...others}
      >
        {startIcon && <span>{startIcon}</span>}

        {openMenu ? (
          <>
            <span
              className={clsx(classes.navLinkLabel, { [classes.navLinkLabelComplementIcon]: complementIcon })}
              data-testid="navLinkLabel"
            >
              {label} {complementIcon && complementIcon}
            </span>

            {endIcon && endIcon}
          </>
        ) : null}
      </NavLink>
    </li>
  );
}

SidebarLayoutItem.propTypes = {
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  label: PropTypes.string,
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
  complementIcon: PropTypes.node,
  openMenu: PropTypes.bool,
};

export default SidebarLayoutItem;
