/**
 *
 * ParrotSoftware
 * OnlineOrderingProvider selectors
 *
 */
import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectOnlineOrderingProviderDomain = (state) => state.onlineOrderingProvider || initialState;

const selectOnlineOrderingList = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ onlineOrdering }) => onlineOrdering.list,
);

const selectOnlineOrderingState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ onlineOrdering: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectOnlineOrderingPaginationState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ onlineOrderingPagination }) => onlineOrderingPagination,
);

const selectOnlineOrderingPaginationInfo = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ onlineOrdering }) => onlineOrdering.pagination,
);

const selectEnableOrderingState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ canOrdering: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectBrandSettings = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ brandSettings }) => brandSettings.data,
);

const selectBrandSettingsState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ brandSettings: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateBrandSettingsState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ updateBrandSettings: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectBrandContactInformation = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ brandContactInformation }) => brandContactInformation.contact,
);

const selectBrandContactInformationState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ brandContactInformation: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateContactInformationState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ updateContactInformation: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectOperationsSettings = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ operationsSettings }) => operationsSettings.data,
);

const selectOperationsSettingsState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ operationsSettings: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateOperationsSettingsState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ updateOperationsSettings: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectDeliveryZones = createSelector(selectOnlineOrderingProviderDomain, ({ deliveryZones }) => deliveryZones);

const selectDeliveryZonesState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ deliveryZones: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateDeliveryZonesState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ updateDeliveryZones: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectCreateDeliveryZonesState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ createDeliveryZones: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectDeleteDeliveryZonesState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ deleteDeliveryZones: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectValidateDirectionUrlState = createSelector(
  selectOnlineOrderingProviderDomain,
  (subState) => subState.validateDirectionUrl,
);

const selectGetResumesMenus = createSelector(
  selectOnlineOrderingProviderDomain,
  (subState) => subState.getResumesMenus.menusResume,
);

const selectGetResumesMenusState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ getResumesMenus: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectSaveOnboardingState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ saveOnboarding: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectSaveOnboardingBrand = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ saveOnboarding }) => saveOnboarding.brand,
);

const selectPublishBrandState = createSelector(
  selectOnlineOrderingProviderDomain,
  ({ publishBrand: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

export {
  selectOnlineOrderingProviderDomain,
  selectOnlineOrderingList,
  selectOnlineOrderingState,
  selectOnlineOrderingPaginationState,
  selectOnlineOrderingPaginationInfo,
  selectEnableOrderingState,
  selectBrandSettings,
  selectBrandSettingsState,
  selectUpdateBrandSettingsState,
  selectBrandContactInformation,
  selectBrandContactInformationState,
  selectUpdateContactInformationState,
  selectOperationsSettings,
  selectOperationsSettingsState,
  selectUpdateOperationsSettingsState,
  selectDeliveryZones,
  selectDeliveryZonesState,
  selectUpdateDeliveryZonesState,
  selectValidateDirectionUrlState,
  selectGetResumesMenus,
  selectGetResumesMenusState,
  selectSaveOnboardingState,
  selectCreateDeliveryZonesState,
  selectDeleteDeliveryZonesState,
  selectSaveOnboardingBrand,
  selectPublishBrandState,
};
