import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import { GET_REPORTS_CASH_REGISTER_SESSION_DETAIL_ALL } from './constants';

export const getReportsCashRegisterSessionDetailAll = extendRoutine(
  createRoutine(GET_REPORTS_CASH_REGISTER_SESSION_DETAIL_ALL),
  'RESET',
);
