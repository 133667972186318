/* eslint-disable react/no-array-index-key */
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import Table from '@experimental-components/Table';

import { DataResponseSummary } from 'containers/ReportSalesPerTime/types';

import useStyles from './styles';

interface Props {
  dataSummaries: DataResponseSummary;
  t: (value: string) => string;
}

const Summary = ({ dataSummaries, t }: Props) => {
  const classes = useStyles({});

  function renderSchedulesValues(
    values: string[] = [],
    extraSymbol: string,
    symbol: string,
    styleText: string,
    indexRow: number,
  ) {
    return values?.map((item, indexValue) => (
      <Table.Cell
        key={`value-schedule-${indexRow}-${indexValue}`}
        align="right"
        className={clsx(classes.summaryCell, { [classes.boldCell]: styleText === 'bold' })}
      >
        {`${extraSymbol} ${symbol}${item}`}
      </Table.Cell>
    ));
  }

  return (
    <Box mx={-4} width="100%">
      <Typography className={classes.titleSection}>{t('salesPerTimeReport.labels.summary')}</Typography>

      <Table>
        <Table.Head>
          <Table.Row className={classes.summaryRow}>
            <Table.HeadCell align="left" className={classes.summaryCell}>
              <></>
            </Table.HeadCell>
            {dataSummaries?.headers?.map((head, index) => (
              <Table.HeadCell key={`header-schedule-${index}`} align="right" className={classes.summaryCell}>
                {head.name}
              </Table.HeadCell>
            ))}
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {dataSummaries?.summaries?.map((summary, indexRow) => (
            <Table.RowStreaky key={`row-schedule-${indexRow}`} className={classes.summaryRow}>
              <Table.Cell
                align="left"
                className={clsx(classes.summaryCell, { [classes.boldCell]: summary.styleText === 'bold' })}
              >
                {summary.name}
              </Table.Cell>
              {renderSchedulesValues(summary.value, summary.extraSymbol, summary.symbol, summary.styleText, indexRow)}
            </Table.RowStreaky>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};

export default Summary;
