import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getServiceChargesRequest,
  getServiceChargeDetailRequest,
  createServiceChargeRequest,
  updateServiceChargeRequest,
  deleteServiceChargeRequest,
  setIsEnabledServiceChargeRequest,
} from 'utils/api/baas/settings/serviceCharges';

import {
  getServiceCharges,
  getServiceChargesByPagination,
  getServiceCharge,
  createServiceCharge,
  updateServiceCharge,
  deleteServiceCharge,
  setIsEnabledServiceCharge,
} from './actions';

export function* handleGetServiceChargesRequest(action) {
  const filters = action.payload;
  try {
    yield put(getServiceCharges.request());

    const { data: serviceCharges, pagination } = yield call(baasRequest, getServiceChargesRequest, filters);

    yield put(getServiceCharges.success({ serviceCharges, pagination }));
  } catch (err) {
    yield put(getServiceCharges.failure(err));
  }
}

export function* handleGetServiceChargesByPaginationRequest(action) {
  const { page } = action.payload;

  try {
    yield put(getServiceChargesByPagination.request({ page }));

    const { data: serviceCharges, pagination } = yield call(baasRequest, getServiceChargesRequest, { page });

    yield put(getServiceChargesByPagination.success({ serviceCharges, pagination }));
  } catch (err) {
    yield put(getServiceChargesByPagination.failure(err));
  } finally {
    yield put(getServiceChargesByPagination.fulfill());
  }
}

export function* handleGetServiceChargeDetailRequest(action) {
  const serviceChargeUuid = action.payload;
  try {
    yield put(getServiceCharge.request());

    const { data: serviceCharge } = yield call(baasRequest, getServiceChargeDetailRequest, serviceChargeUuid);

    yield put(getServiceCharge.success(serviceCharge));
  } catch (err) {
    yield put(getServiceCharge.failure(err));
  }
}

export function* handleCreateServiceChargeRequest(action) {
  const serviceCharge = action.payload;
  try {
    yield put(createServiceCharge.request());

    const { data } = yield call(baasRequest, createServiceChargeRequest, serviceCharge);

    yield put(createServiceCharge.success(data));
  } catch (err) {
    yield put(createServiceCharge.failure(err));
  }
}

export function* handleUpdateServiceChargeRequest(action) {
  const { serviceChargeUuid, serviceCharge } = action.payload;
  try {
    yield put(updateServiceCharge.request());

    const { data } = yield call(baasRequest, updateServiceChargeRequest, { serviceChargeUuid, serviceCharge });

    yield put(updateServiceCharge.success(data));
  } catch (err) {
    yield put(updateServiceCharge.failure(err));
  }
}

export function* handleDeleteServiceChargeRequest(action) {
  const serviceChargeUuid = action.payload;
  try {
    yield put(deleteServiceCharge.request());

    yield call(baasRequest, deleteServiceChargeRequest, serviceChargeUuid);

    yield put(deleteServiceCharge.success(serviceChargeUuid));
  } catch (err) {
    yield put(deleteServiceCharge.failure(err));
  }
}

export function* handleSetIsEnabledServiceChargeRequest(action) {
  const { serviceChargeUuid, isEnabled } = action.payload;

  try {
    yield put(setIsEnabledServiceCharge.request());

    const { data } = yield call(baasRequest, setIsEnabledServiceChargeRequest, {
      serviceChargeUuid,
      isEnabled,
    });

    yield put(setIsEnabledServiceCharge.success(data));
  } catch (err) {
    yield put(setIsEnabledServiceCharge.failure(err));
  }
}

export default function* settingsServiceChargesProviderSaga() {
  yield takeLatest(getServiceCharges.TRIGGER, handleGetServiceChargesRequest);
  yield takeLatest(getServiceChargesByPagination.TRIGGER, handleGetServiceChargesByPaginationRequest);
  yield takeLatest(getServiceCharge.TRIGGER, handleGetServiceChargeDetailRequest);
  yield takeLatest(createServiceCharge.TRIGGER, handleCreateServiceChargeRequest);
  yield takeLatest(updateServiceCharge.TRIGGER, handleUpdateServiceChargeRequest);
  yield takeLatest(deleteServiceCharge.TRIGGER, handleDeleteServiceChargeRequest);
  yield takeLatest(setIsEnabledServiceCharge.TRIGGER, handleSetIsEnabledServiceChargeRequest);
}
