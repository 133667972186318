import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const AddOutline = forwardRef(function AddOutline(props, ref) {
  const { color = '#FFFFFF', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M12 1.23669C12.3156 1.23669 12.5714 1.49253 12.5714 1.80812L12.5714 22.8193C12.5714 23.1349 12.3156 23.3907 12 23.3907C11.6844 23.3907 11.4286 23.1349 11.4286 22.8193L11.4286 1.80812C11.4286 1.49253 11.6844 1.23669 12 1.23669Z"
        fill={color}
        fillRule="evenodd"
      />

      <path
        clipRule="evenodd"
        d="M23.0768 12.3137C23.0768 12.6293 22.8209 12.8851 22.5054 12.8851L1.49418 12.8851C1.17859 12.8851 0.922753 12.6293 0.922751 12.3137C0.922753 11.9981 1.17859 11.7423 1.49418 11.7423L22.5054 11.7423C22.8209 11.7423 23.0768 11.9981 23.0768 12.3137Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

AddOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AddOutline;
