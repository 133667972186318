import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getRolesRequest, getPermissionsByRolesRequest } from 'utils/api/baas/workforce/roles';

import { getRoles, getPermissionsByRoles } from '../actions';

export function* handleGetRolesRequest(action) {
  const storeId = action.payload;

  try {
    yield put(getRoles.request());

    const { data } = yield call(baasRequest, getRolesRequest, storeId);

    yield put(getRoles.success(data));
  } catch (err) {
    yield put(getRoles.failure(err));
  }
}

export function* handleGetPermissionsByRolesRequest(action) {
  const jobUuids = action.payload;

  try {
    yield put(getPermissionsByRoles.request());

    const {
      data: { permissions },
    } = yield call(baasRequest, getPermissionsByRolesRequest, jobUuids);

    yield put(getPermissionsByRoles.success(permissions));
  } catch (err) {
    yield put(getPermissionsByRoles.failure(err));
  }
}
