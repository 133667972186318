import { sortBy } from 'lodash';

import {
  SchedulesType,
  ValidateSchedulesFormType,
  CleanErrorsType,
  SetErrorNameType,
  SetErrorHourType,
  HourListType,
} from 'components/ReportSalesPerTime/types';

import { regexTypes } from 'utils/formatField';

const scheduleNameField = {
  name: 'scheduleNameField',
  id: 'scheduleNameField',
  label: 'salesPerTimeReport.form.scheduleName',
  maxLength: 15,
  type: 'text',
  register: {
    required: 'common:messages.requireField',
    repeatedNames: 'salesPerTimeReport.form.errors.repeatedNames',
  },
};

const startHoursField = {
  name: 'startHoursField',
  id: 'startHoursField',
  label: 'labels.startHour',
  errorMsg: 'salesPerTimeReport.form.errors.overlapHours',
};

const endHoursField = {
  name: 'endHoursField',
  id: 'endHoursField',
  label: 'labels.endHour',
  errorMsg: 'salesPerTimeReport.form.errors.overlapHours',
};

export const formDefinition = {
  scheduleNameField,
  startHoursField,
  endHoursField,
};

export const scheduleColors = ['44AEC9', 'F04E4A', 'F6BE2C'];

export const defaultSchedule = [{ uuidField: '', scheduleNameField: '', startHoursField: '', endHoursField: '' }];

export function getInitSchedules(schedulesData?: SchedulesType[]) {
  let initSchedules = defaultSchedule;

  if (schedulesData?.length) {
    initSchedules = [];
    schedulesData.forEach((item) => {
      initSchedules.push({
        uuidField: item.uuid ?? '',
        scheduleNameField: item.name.trim(),
        startHoursField: item.startHour,
        endHoursField: item.endHour,
      });
    });
  }

  return initSchedules;
}

export function validateSchedulesForm({ errors, schedulesValues }: ValidateSchedulesFormType) {
  const isDataValid = schedulesValues?.scheduleFields?.every((schedule) => {
    return (
      regexTypes.REGEX_HOURS_MINUTES.test(schedule.startHoursField) &&
      regexTypes.REGEX_HOURS_MINUTES.test(schedule.endHoursField) &&
      schedule.scheduleNameField.length > 0
    );
  });

  return isDataValid && !errors?.scheduleFields?.length;
}

export function cleanErrorsOfName({ watchFields, errors, clearErrors }: CleanErrorsType) {
  watchFields.scheduleFields.forEach((_, index) => {
    if (errors?.scheduleFields?.length && errors?.scheduleFields[index]?.scheduleNameField?.type !== 'required') {
      clearErrors(`scheduleFields[${index}].scheduleNameField`);
    }
  });
}

export function setErrorsOfName({ scheduleNameList, errors, setError, t }: SetErrorNameType) {
  scheduleNameList.forEach((scheduleName, scheduleIndex) => {
    scheduleNameList.forEach((item, index) => {
      const hasError =
        errors?.scheduleFields?.length &&
        errors?.scheduleFields[index]?.scheduleNameField &&
        errors?.scheduleFields[index]?.scheduleNameField?.type !== 'required';

      if (
        scheduleName.trim().toLowerCase() === item.trim().toLowerCase() &&
        scheduleIndex !== index &&
        !hasError &&
        scheduleName.length > 0
      ) {
        setError(`scheduleFields[${index}].scheduleNameField`, {
          type: 'validate',
          message: t(formDefinition.scheduleNameField.register.repeatedNames),
        });
      }
    });
  });
}

export function isHourValid(hour: string) {
  return regexTypes.REGEX_HOURS_MINUTES.test(hour);
}

export function cleanErrorsOfHours({ watchFields, clearErrors }: CleanErrorsType) {
  watchFields.scheduleFields.forEach((_, index) => {
    clearErrors(`scheduleFields[${index}].${formDefinition.startHoursField.name}`);
    clearErrors(`scheduleFields[${index}].${formDefinition.endHoursField.name}`);
  });
}

function sortHoursList(scheduleList: HourListType[]) {
  const formattedList = scheduleList.reduce<[string, string, number][]>((list, item, index) => {
    if (isHourValid(item.startHoursField) && isHourValid(item.endHoursField)) {
      list.push([item.startHoursField, item.endHoursField, index]);
    }

    return list;
  }, []);

  return sortBy(formattedList, [0]);
}

export function setErrorsOfHours({ scheduleHourList, setError }: SetErrorHourType) {
  const hoursListSorted = sortHoursList(scheduleHourList);

  const findOverlapHour = hoursListSorted.reduce<number[]>((list, item, index) => {
    if (hoursListSorted.length !== index + 1) {
      if (item[0] === item[1]) {
        list.push(item[2]);
      }

      if (!(item[0] === item[1] || (item[0] < item[1] && item[1] <= hoursListSorted[index + 1][0]))) {
        list.push(item[2]);
        list.push(hoursListSorted[index + 1][2]);
      }
    } else if (item[0] >= item[1] && item[1] !== '00:00') {
      list.push(item[2]);
    }

    return list;
  }, []);

  findOverlapHour.forEach((item) => {
    setError(`scheduleFields[${item}].startHoursField`, { type: 'validate', message: 'true' });
    setError(`scheduleFields[${item}].endHoursField`, { type: 'validate', message: 'true' });
  });
}
