import { forwardRef, Ref, SVGProps } from 'react';

const Organization = forwardRef(function Organization(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg ref={ref} fill="none" height="40" viewBox="0 0 42 40" width="42" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_764_17796)">
        <path
          d="M27.7543 27.525C27.7543 27.525 26.7292 32.3943 26.7292 33.2254C26.7292 34.0565 26.7292 34.8872 28.9505 36.2724C31.1717 37.6576 32.3563 38.0007 32.449 39.9395H12.8287C11.0701 39.9395 10.3297 38.3702 10.3297 37.4466C10.3297 36.523 13.8637 26.1045 13.8637 26.1045L27.7543 27.525Z"
          fill="#D7D7D6"
        />
        <path d="M20.0508 37.9325L20.0491 38.072L28.5846 38.1745L28.5863 38.0351L20.0508 37.9325Z" fill="#272425" />
        <path
          d="M17.3156 39.9595C17.3156 39.9595 17.1641 38.7263 15.982 37.7153C13.6647 35.7341 12.8208 35.2354 13.5491 33.435C14.2304 31.7509 16.1451 27.5251 16.1451 27.5251L13.5356 26.5489C13.5356 26.5489 7.72633 37.3267 7.26502 38.2936C6.85776 39.1465 7.14246 40 8.56974 40C11.2598 40 17.3156 39.9595 17.3156 39.9595Z"
          fill="#B0B0B0"
        />
        <path
          d="M4.87271 30.4205L40.5686 30.6251C40.9058 30.6271 41.2311 30.5007 41.4781 30.2718L41.5765 30.1788C41.7563 30.0124 41.8864 29.7994 41.9522 29.5637C42.018 29.3279 42.017 29.0786 41.9492 28.8433L36.0253 8.34107C35.9509 8.0832 35.7997 7.85401 35.5916 7.68397C35.3835 7.51392 35.1285 7.41107 34.8604 7.38908L1.43336 4.67906C1.17047 4.65767 0.907155 4.71504 0.677129 4.84381C0.447104 4.97258 0.260831 5.16689 0.142154 5.40188C0.0177408 5.64747 -0.0265753 5.92573 0.0154095 6.19769L3.56985 29.3003C3.61804 29.611 3.77566 29.8945 4.01443 30.0998C4.2532 30.3051 4.55749 30.4188 4.87271 30.4205Z"
          fill="black"
        />
        <path
          d="M6.03431 29.3222L41.0829 29.6011C41.2127 29.602 41.3411 29.5742 41.4589 29.5196C41.5766 29.4649 41.6807 29.3849 41.7636 29.2853C41.8465 29.1857 41.9062 29.0689 41.9384 28.9434C41.9706 28.818 41.9744 28.6869 41.9496 28.5598L37.8067 7.35845C37.7549 7.09183 37.6149 6.85024 37.4092 6.67235C37.2035 6.49445 36.9439 6.39051 36.672 6.37717L1.93049 4.67492C1.76427 4.66676 1.59834 4.69604 1.44501 4.76059C1.29168 4.82514 1.15488 4.92329 1.04477 5.04778C0.934664 5.17227 0.854065 5.31989 0.808955 5.47969C0.763846 5.6395 0.755383 5.80738 0.784196 5.9709L4.70629 28.201C4.76148 28.5132 4.92452 28.7963 5.16711 29.0011C5.4097 29.2059 5.71652 29.3195 6.03431 29.3222Z"
          fill="black"
        />
        <path d="M2.86572 6.35388L36.3897 7.93899L40.5448 28.2158L6.36891 28.0127L2.86572 6.35388Z" fill="#F1F1F3" />
        <path
          d="M13.5888 9.78347H13.5599L5.6523 9.48272C5.24831 9.46738 4.86528 9.14757 4.79818 8.77012L4.71151 8.27971C4.69438 8.19622 4.69652 8.10994 4.71775 8.0274C4.73898 7.94485 4.77875 7.8682 4.83406 7.80325C4.89798 7.73427 4.97668 7.68056 5.0643 7.64611C5.15191 7.61167 5.24619 7.59737 5.3401 7.6043L13.2472 7.90459C13.6512 7.91993 14.0342 8.23927 14.1013 8.61672L14.188 9.10713C14.2052 9.19069 14.203 9.27705 14.1818 9.35968C14.1606 9.44231 14.1208 9.51903 14.0654 9.58406C14.0048 9.64927 13.9309 9.70081 13.8487 9.7352C13.7665 9.76959 13.6779 9.78605 13.5888 9.78347ZM13.5627 9.69051C13.6427 9.69705 13.7232 9.68539 13.7981 9.6564C13.873 9.62742 13.9404 9.58186 13.9951 9.52316C14.0413 9.46869 14.0745 9.40439 14.092 9.33519C14.1095 9.26599 14.1109 9.19371 14.0962 9.12386L14.0095 8.63346C13.9499 8.29691 13.6065 8.0115 13.2435 7.99802L5.33637 7.69773C5.25643 7.69131 5.17605 7.70298 5.10126 7.73187C5.02646 7.76077 4.95918 7.80614 4.90442 7.86461C4.85825 7.91913 4.82515 7.98342 4.80763 8.05261C4.79011 8.1218 4.78863 8.19406 4.8033 8.26391L4.88997 8.75478C4.94962 9.09133 5.29304 9.37627 5.65603 9.39022L13.5627 9.69051Z"
          fill="#B0B0B0"
        />
        <path
          d="M8.95646 11.2333L5.70537 11.1097C5.627 11.1056 5.55209 11.0762 5.49197 11.0259C5.43186 10.9755 5.38981 10.9071 5.3722 10.8308L5.33632 10.6295C5.31069 10.4821 5.4174 10.3678 5.57536 10.3738L8.82646 10.4975C8.90483 10.5015 8.97974 10.531 9.03985 10.5813C9.09997 10.6316 9.14201 10.7001 9.15963 10.7764L9.19504 10.9781C9.22113 11.125 9.11443 11.2394 8.95646 11.2333Z"
          fill="#B0B0B0"
        />
        <path
          d="M9.24162 12.7366L5.99053 12.613C5.91216 12.6089 5.83724 12.5795 5.77713 12.5292C5.71702 12.4788 5.67497 12.4103 5.65736 12.3341L5.62148 12.1323C5.59585 11.9854 5.70255 11.8711 5.86052 11.8771L9.11161 12.0003C9.18998 12.0044 9.26489 12.0338 9.32501 12.0841C9.38512 12.1344 9.42717 12.2029 9.44478 12.2792L9.4802 12.4809C9.50583 12.6288 9.39958 12.7431 9.24162 12.7366Z"
          fill="#B0B0B0"
        />
        <path
          d="M9.52677 14.2404L6.27568 14.1167C6.19727 14.1128 6.12228 14.0835 6.06213 14.0331C6.00199 13.9828 5.95999 13.9142 5.94251 13.8378L5.90663 13.6361C5.881 13.4892 5.98771 13.3749 6.14568 13.3809L9.39677 13.5046C9.47514 13.5086 9.55005 13.538 9.61016 13.5884C9.67028 13.6387 9.71232 13.7072 9.72994 13.7835L9.76535 13.9852C9.79145 14.1312 9.68427 14.2464 9.52677 14.2404Z"
          fill="#B0B0B0"
        />
        <path
          d="M38.7638 27.0858H38.7568L33.7938 26.9863C33.5305 26.9807 33.2812 26.6693 33.2406 26.2918L33.1871 25.8038C33.1619 25.5755 33.2164 25.3636 33.3334 25.2362C33.3663 25.1981 33.4071 25.1676 33.4531 25.147C33.4991 25.1264 33.549 25.116 33.5994 25.1167L38.5625 25.2167C38.8258 25.2218 39.0751 25.5332 39.1156 25.9111L39.1692 26.3992C39.1944 26.627 39.1399 26.8394 39.0229 26.9668C38.9907 27.0039 38.9509 27.0337 38.9063 27.0542C38.8616 27.0748 38.813 27.0855 38.7638 27.0858ZM33.5915 25.2097C33.5552 25.2102 33.5194 25.2184 33.4866 25.2338C33.4537 25.2492 33.4245 25.2714 33.4009 25.2989C33.3022 25.4068 33.2565 25.5918 33.2789 25.7935L33.3324 26.2816C33.3693 26.6144 33.5766 26.8887 33.7947 26.8934L38.7578 26.9928C38.7949 26.9931 38.8317 26.9854 38.8655 26.97C38.8993 26.9547 38.9293 26.9322 38.9535 26.904C39.0523 26.7962 39.0979 26.6112 39.0756 26.409L39.022 25.9209C38.9852 25.5881 38.7778 25.3138 38.5597 25.3096L33.5966 25.2097H33.5915Z"
          fill="#B0B0B0"
        />
        <path
          d="M38.1529 24.5259H38.1455L33.1829 24.426C32.9196 24.4209 32.6703 24.1094 32.6298 23.732L32.5757 23.242C32.5505 23.0138 32.6055 22.8013 32.722 22.674C32.755 22.6359 32.796 22.6055 32.842 22.5849C32.8881 22.5643 32.9381 22.5541 32.9886 22.555L37.9516 22.6545C38.2149 22.66 38.4642 22.9715 38.5043 23.3489L38.5583 23.837C38.5835 24.0652 38.529 24.2772 38.412 24.4046C38.3801 24.4421 38.3404 24.4724 38.2957 24.4933C38.251 24.5143 38.2023 24.5254 38.1529 24.5259ZM38.1473 24.4329C38.1846 24.4333 38.2215 24.4254 38.2554 24.41C38.2892 24.3946 38.3193 24.372 38.3435 24.3437C38.4423 24.2358 38.488 24.0508 38.4656 23.8491L38.412 23.361C38.3752 23.0282 38.1678 22.7539 37.9498 22.7493L32.9867 22.6498C32.9495 22.6492 32.9127 22.6569 32.8788 22.6722C32.845 22.6876 32.815 22.7102 32.791 22.7386C32.6922 22.8469 32.6461 23.0314 32.6684 23.2336L32.7225 23.7217C32.7593 24.0546 32.9667 24.3288 33.1847 24.333L38.1473 24.4329Z"
          fill="#B0B0B0"
        />
        <path
          d="M12.8752 15.7581C12.8421 15.7286 12.8117 15.6963 12.7844 15.6614C12.2355 14.9539 13.3165 14.114 13.9363 14.8596C14.1543 15.1217 14.2545 15.5457 14.0141 15.7892C13.9126 15.8821 13.7849 15.9414 13.6483 15.9589C13.3771 16.0012 13.0779 15.9357 12.8752 15.7581Z"
          fill="#F59397"
        />
        <path
          d="M20.1869 5.05377L31.311 7.3459L34.0761 9.57667L30.7039 18.5207L19.9287 16.5795L18.8043 13.9332L20.1869 5.05377Z"
          fill="white"
        />
        <path
          d="M20.1869 5.05377L22.725 7.31476L23.7441 10.5473L32.1186 12.0994L34.0761 9.57667L31.3111 7.3459L20.1869 5.05377Z"
          fill="#CF4F4C"
        />
        <path
          d="M33.5794 5.75473L34.1959 3.5049L33.5408 2.28935L31.8302 2.11875L31.1475 3.53744L30.6913 3.49096L31.0581 1.87796L30.2473 0.929686L28.5232 0.976171L27.9724 2.80392L27.4924 2.28795L26.1635 1.99557L26.2809 0.669376L24.3746 0.0557861L23.0023 0.856242L22.4506 0.464846L20.9544 1.00499L19.9758 7.55924L22.2009 8.56516L21.932 11.5536L32.0455 13.8523C32.0455 13.8523 34.4685 7.25152 34.5025 7.12601C34.5366 7.00051 33.5794 5.75473 33.5794 5.75473Z"
          fill="white"
        />
        <path
          d="M21.876 11.5936L22.1463 8.59676L19.9189 7.59038L20.9082 0.967333L22.4599 0.40813L23.0065 0.795342L24.3704 0L26.3359 0.633113L26.2184 1.95512L27.5185 2.24146L27.9509 2.7063L28.4887 0.927822L30.2719 0.881338L31.1153 1.86866L30.7561 3.44912L31.1186 3.48491L31.8013 2.06622L33.5747 2.24286L34.249 3.49886L33.6339 5.74404C33.9974 6.21957 34.5822 7.01212 34.55 7.13856C34.5179 7.26499 32.1908 13.5998 32.0916 13.8699L32.0762 13.9122L21.876 11.5936ZM20.0317 7.53042L22.2549 8.53494L21.9865 11.515L32.0128 13.7928C32.846 11.5243 34.3963 7.298 34.4517 7.11671C34.4377 7.01259 33.9787 6.36134 33.5389 5.78587L33.5235 5.76588L34.1409 3.51141L33.5081 2.33768L31.8609 2.17313L31.1782 3.59182L30.6284 3.5379L31.0012 1.8933L30.223 0.982208L28.5641 1.0259L27.9966 2.90386L27.463 2.33489L26.1061 2.03554L26.2245 0.705628L24.3806 0.111562L22.9995 0.91713L22.4431 0.522481L20.9986 1.04264L20.0317 7.53042Z"
          fill="black"
        />
        <path
          d="M32.1186 12.0994L23.7441 10.5473L22.725 7.31476L20.1869 5.05377L18.8043 13.9332L19.9287 16.5795L30.7039 18.5207L34.0761 9.57667L32.1186 12.0994Z"
          fill="#F8B8B8"
        />
        <path
          d="M22.5508 7.27381L23.644 1.5214L22.9674 0.89387L23.0373 0.819031L23.7553 1.48515L23.7497 1.51257L22.6519 7.29287L22.5508 7.27381Z"
          fill="black"
        />
        <path d="M22.3996 0.458183L21.5577 6.1181L21.6589 6.13308L22.5008 0.473157L22.3996 0.458183Z" fill="black" />
        <path d="M26.1125 1.99054L25.1384 10.7359L25.2401 10.7472L26.2141 2.00181L26.1125 1.99054Z" fill="black" />
        <path
          d="M23.5852 9.84068C23.6076 9.691 24.3163 0.148285 24.3238 0.052063L24.4263 0.0595004C24.3056 1.68412 23.7026 9.79326 23.6854 9.86764L23.5852 9.84068Z"
          fill="black"
        />
        <path d="M30.1992 0.920303L28.0164 11.3434L28.1167 11.3643L30.2995 0.941216L30.1992 0.920303Z" fill="black" />
        <path
          d="M27.1578 11.159L28.2263 3.16139L27.9346 2.83833L28.0106 2.76953L28.3344 3.12792L28.3312 3.15117L27.2594 11.1725L27.1578 11.159Z"
          fill="black"
        />
        <path d="M27.4366 2.2806L26.2556 10.915L26.3569 10.9288L27.538 2.2944L27.4366 2.2806Z" fill="black" />
        <path d="M33.493 2.27265L30.0864 11.686L30.1828 11.7208L33.5894 2.30737L33.493 2.27265Z" fill="black" />
        <path d="M31.1019 3.51738L29.5913 6.98969L29.6853 7.0304L31.1959 3.55809L31.1019 3.51738Z" fill="black" />
        <path d="M30.6402 3.47669L28.2964 11.3802L28.3947 11.4092L30.7385 3.5057L30.6402 3.47669Z" fill="black" />
        <path d="M33.556 5.74676L31.3713 11.8975L31.4197 11.9146L33.6043 5.76384L33.556 5.74676Z" fill="black" />
        <path
          d="M30.8457 15.3599C31.0873 14.2144 29.4396 12.8988 27.1656 12.4216C24.8915 11.9443 22.8521 12.4861 22.6105 13.6317C22.3689 14.7773 24.0166 16.0929 26.2907 16.5701C28.5648 17.0473 30.6042 16.5055 30.8457 15.3599Z"
          fill="#F04E4A"
        />
        <path d="M22.5729 7.18759L19.8806 16.5662L19.9792 16.5944L22.6714 7.21574L22.5729 7.18759Z" fill="black" />
        <path
          d="M30.32 30.8812C30.3484 30.8308 30.37 30.7769 30.3843 30.7209C30.6322 29.7558 29.0013 29.0474 28.3652 28.6876C27.1765 28.0136 25.9776 27.3545 24.828 26.614C23.6785 25.8735 22.6678 25.0056 21.6454 24.1108C21.0835 23.6186 20.3687 23.1904 20.0108 22.5122C20.0108 22.5122 20.1897 20.962 18.8249 20.7988L10.326 21.4259C9.88753 21.458 9.46909 22.033 9.33815 22.3909C8.70583 24.1252 10.2748 25.5197 11.6848 26.1752C13.2821 26.9189 14.9149 27.5925 16.5556 28.234C19.5271 29.3961 22.59 30.5005 25.7413 31.0607C26.5577 31.2057 27.3746 31.3744 28.2063 31.3939C28.758 31.407 29.9821 31.4823 30.32 30.8812Z"
          fill="#FADBDA"
        />
        <path d="M20.8723 23.606C20.8723 23.606 19.4529 25.6388 22.8396 25.1911L20.8723 23.606Z" fill="#EF4E4A" />
        <path
          d="M18.319 26.0479C18.4469 25.4485 17.8275 24.809 16.9354 24.6195C16.0434 24.4301 15.2165 24.7623 15.0886 25.3617C14.9606 25.9611 15.5801 26.6006 16.4721 26.79C17.3642 26.9795 18.191 26.6472 18.319 26.0479Z"
          fill="#EF4E4A"
        />
        <path
          d="M22.8364 27.6924C22.8685 26.7102 24.2506 26.9803 24.8447 27.1811C25.4174 27.3754 27.0991 28.181 26.7655 28.9791C26.5432 29.5118 25.9822 29.681 25.4426 29.5997C24.6136 29.4746 23.0372 28.8657 22.856 27.9179C22.841 27.8437 22.8345 27.7681 22.8364 27.6924Z"
          fill="#EF4E4A"
        />
        <path
          d="M30.4084 30.5502C30.4401 29.6722 28.9639 29.0265 28.3651 28.6876L27.8964 28.4227L30.4084 30.5502Z"
          fill="#FADBDA"
        />
        <path
          d="M27.4608 30.858C26.8983 30.6916 26.3191 30.4996 25.7912 30.243C25.1705 29.9413 24.6407 29.4746 23.9823 29.2473C23.5229 29.0857 23.0424 28.9918 22.5559 28.9684C22.2204 28.9935 21.816 29.0149 21.4898 28.908C21.1548 28.7987 20.946 28.5156 20.6874 28.2953C20.3803 28.0341 20.0047 27.8602 19.6231 27.7375C19.2005 27.6032 18.721 27.5009 18.2718 27.5111C17.2513 27.5344 16.5971 26.7386 15.9126 26.1073C15.1027 25.3603 14.0743 25.08 13.1293 24.5561C12.812 24.38 12.4015 24.1568 12.2472 23.8082C12.1797 23.6557 12.1769 23.4665 12.2756 23.3317C12.2928 23.3064 12.3158 23.2855 12.3427 23.2709C12.3682 23.2601 12.3953 23.2535 12.4229 23.2513C12.8581 23.1928 13.2556 23.2713 13.6889 23.2709C14.1587 23.2886 14.6291 23.2805 15.098 23.2467C16.037 23.183 16.968 23.0384 17.8976 22.8957C18.6059 22.7865 19.3128 22.6633 20.015 22.5197L20.0094 22.509C20.0094 22.509 20.1897 20.9643 18.8254 20.7988L10.326 21.4259C9.88753 21.458 9.46909 22.033 9.33815 22.3909C8.70583 24.1252 10.2748 25.5197 11.6848 26.1752C13.2821 26.9189 14.9149 27.5925 16.5556 28.234C19.5271 29.3961 22.59 30.5005 25.7413 31.0606C26.5577 31.2057 27.3746 31.3744 28.2063 31.3939C28.6439 31.4042 29.5031 31.4534 30.0092 31.1736C29.1746 31.3261 28.2073 31.0783 27.4608 30.858Z"
          fill="#F39594"
        />
        <path
          d="M39 15.8365C39 15.8365 37.1706 16.1093 36.9133 14C36.9133 14 36.9996 16.0779 35 16.8422C35 16.8422 36.77 16.5285 37.2992 19C37.2992 19 36.7553 16.0913 39 15.8365Z"
          fill="#F04E4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_764_17796">
          <rect fill="white" height="40" width="42" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default Organization;
