import { createSelector } from 'reselect';

import { selectBrands } from 'providers/root/BrandsProvider/selectors';

import { initialState } from './reducer';

const selectMenuMakerAdminProviderDomain = (state) => state.menus || initialState;

const selectMenus = createSelector(selectMenuMakerAdminProviderDomain, (subState) => subState.getMenus.list);

const selectAllMenus = createSelector(selectMenuMakerAdminProviderDomain, (subState) => subState.getMenus.list);

const selectAllMenusForBrand = createSelector(
  selectMenuMakerAdminProviderDomain,
  selectBrands,
  ({ getMenus }, brands) => {
    const menusBrandsList = JSON.parse(JSON.stringify(brands));

    menusBrandsList.forEach((brand, index) => {
      const menuListFilterBrand = [...getMenus.list].filter((menu) => menu?.brand?.uuid === brand?.uuid);
      const providersBrand = [];
      menuListFilterBrand?.forEach((menu) => {
        menu?.providers?.forEach((provider) => {
          if (!providersBrand.includes(provider?.providerName)) {
            providersBrand.push(provider?.providerName);
          }
        });
      });
      menusBrandsList[index].menus = menuListFilterBrand;
      menusBrandsList[index].providers = providersBrand;
    });

    return menusBrandsList;
  },
);

const selectPaginationMenu = createSelector(selectMenuMakerAdminProviderDomain, (subState) => subState.paginationMenu);

const selectMenusPagination = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.getMenus.pagination,
);

const selectMenusFetching = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.getMenus.fetching,
);

const selectMenusFetched = createSelector(selectMenuMakerAdminProviderDomain, (subState) => subState.getMenus.fetched);

const selectMenusError = createSelector(selectMenuMakerAdminProviderDomain, (subState) => subState.getMenus.error);

const selectCreateMenu = createSelector(selectMenuMakerAdminProviderDomain, (subState) => subState.createMenu.menu);

const selectCreateMenuFetching = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.createMenu.fetching,
);

const selectCreateMenuFetched = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.createMenu.fetched,
);

const selectCreateMenuError = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.createMenu.error,
);

const selectDeleteMenuFetching = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.deleteMenu.fetching,
);

const selectDeleteMenuFetched = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.deleteMenu.fetched,
);

const selectDeleteMenuError = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.deleteMenu.error,
);

const selectPublishMenuFetching = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.publishMenu.fetching,
);

const selectPublishMenuFetched = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.publishMenu.fetched,
);

const selectPublishMenuError = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.publishMenu.error,
);

const selectDuplicateMenuFetching = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.duplicateMenu.fetching,
);

const selectDuplicateMenuFetched = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.duplicateMenu.fetched,
);

const selectDuplicateMenuError = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.duplicateMenu.error,
);

const selectCopyMenuConfigFetching = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.copyMenuConfig.fetching,
);

const selectCopyMenuConfigFetched = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.copyMenuConfig.fetched,
);

const selectCopyMenuConfigError = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.copyMenuConfig.error,
);

const selectCopyMenuConfig = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.copyMenuConfig.menu,
);

const selectCopyMenuConfigStores = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.copyMenuConfig.stores,
);

const selectPublishMenusFetching = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.publishMenus.fetching,
);

const selectPublishMenusFetched = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.publishMenus.fetched,
);

const selectPublishMenusError = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.publishMenus.error,
);

const selectMenusDetailsResumeState = createSelector(
  selectMenuMakerAdminProviderDomain,
  ({ menusDetailsResume: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectMenusDetailsResume = createSelector(
  selectMenuMakerAdminProviderDomain,
  (subState) => subState.menusDetailsResume.menus,
);

export {
  selectAllMenus,
  selectAllMenusForBrand,
  selectCopyMenuConfig,
  selectCopyMenuConfigError,
  selectCopyMenuConfigFetched,
  selectCopyMenuConfigFetching,
  selectCopyMenuConfigStores,
  selectCreateMenu,
  selectCreateMenuError,
  selectCreateMenuFetched,
  selectCreateMenuFetching,
  selectDeleteMenuError,
  selectDeleteMenuFetched,
  selectDeleteMenuFetching,
  selectDuplicateMenuError,
  selectDuplicateMenuFetched,
  selectDuplicateMenuFetching,
  selectMenuMakerAdminProviderDomain,
  selectMenus,
  selectMenusError,
  selectMenusFetched,
  selectMenusFetching,
  selectMenusPagination,
  selectPaginationMenu,
  selectPublishMenuError,
  selectPublishMenuFetched,
  selectPublishMenuFetching,
  selectPublishMenusError,
  selectPublishMenusFetched,
  selectPublishMenusFetching,
  selectMenusDetailsResumeState,
  selectMenusDetailsResume,
};
