import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectListsOptionsProviderDomain = (state) => state.listsOptionsProvider || initialState;

const selectGetProductsOptions = createSelector(
  selectListsOptionsProviderDomain,
  (subState) => subState.getProductsOptions.products,
);

const selectGetProductsOptionsPagination = createSelector(
  selectListsOptionsProviderDomain,
  (subState) => subState.getProductsOptions.pagination,
);

const selectGetProductsOptionsPaginationState = createSelector(
  selectListsOptionsProviderDomain,
  (subState) => subState.getProductsOptions.loadPaginationStatus,
);

const selectGetProductsOptionsState = createSelector(
  selectListsOptionsProviderDomain,
  ({ getProductsOptions: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectGetProductsOptionsFilterState = createSelector(
  selectListsOptionsProviderDomain,
  (subState) => subState.getProductsOptions.loadFilterStatus,
);

const selectGetProductsAutocomplete = createSelector(
  selectListsOptionsProviderDomain,
  (subState) => subState.getProductsAutocomplete.products,
);

const selectGetProductsAutocompletePagination = createSelector(
  selectListsOptionsProviderDomain,
  (subState) => subState.getProductsAutocomplete.pagination,
);

const selectGetProductsAutocompletePaginationState = createSelector(
  selectListsOptionsProviderDomain,
  (subState) => subState.getProductsAutocomplete.loadPaginationStatus,
);

const selectGetProductsAutocompleteState = createSelector(
  selectListsOptionsProviderDomain,
  ({ getProductsAutocomplete: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectGetProductsAutocompleteFilterState = createSelector(
  selectListsOptionsProviderDomain,
  (subState) => subState.getProductsAutocomplete.loadFilterStatus,
);

export {
  selectListsOptionsProviderDomain,
  selectGetProductsOptions,
  selectGetProductsOptionsState,
  selectGetProductsOptionsPagination,
  selectGetProductsOptionsPaginationState,
  selectGetProductsOptionsFilterState,
  selectGetProductsAutocomplete,
  selectGetProductsAutocompletePagination,
  selectGetProductsAutocompletePaginationState,
  selectGetProductsAutocompleteState,
  selectGetProductsAutocompleteFilterState,
};
