import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getMenus,
  getPaginationMenu,
  createMenu,
  publishMenu,
  duplicateMenu,
  deleteMenu,
  copyMenuConfig,
  publishMenus,
} from './actions';
import {
  selectAllMenus,
  selectAllMenusForBrand,
  selectCopyMenuConfig,
  selectCopyMenuConfigError,
  selectCopyMenuConfigFetched,
  selectCopyMenuConfigFetching,
  selectCopyMenuConfigStores,
  selectCreateMenu,
  selectCreateMenuError,
  selectCreateMenuFetched,
  selectCreateMenuFetching,
  selectDeleteMenuError,
  selectDeleteMenuFetched,
  selectDeleteMenuFetching,
  selectDuplicateMenuError,
  selectDuplicateMenuFetched,
  selectDuplicateMenuFetching,
  selectMenus,
  selectMenusError,
  selectMenusFetched,
  selectMenusFetching,
  selectMenusPagination,
  selectPaginationMenu,
  selectPublishMenuError,
  selectPublishMenuFetched,
  selectPublishMenuFetching,
  selectPublishMenusError,
  selectPublishMenusFetched,
  selectPublishMenusFetching,
} from './selectors';

export function withMenusHoc(Component) {
  function withMenus(props) {
    return <Component {...props} />;
  }

  withMenus.displayName = `withMenus(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withMenus;
}

const mapStateToProps = createStructuredSelector({
  allMenus: selectAllMenus,
  allMenusForBrand: selectAllMenusForBrand,
  copyMenuConfigError: selectCopyMenuConfigError,
  copyMenuConfigFetched: selectCopyMenuConfigFetched,
  copyMenuConfigFetching: selectCopyMenuConfigFetching,
  copyMenuConfigObject: selectCopyMenuConfig,
  copyMenuConfigStores: selectCopyMenuConfigStores,
  createMenuError: selectCreateMenuError,
  createMenuFetched: selectCreateMenuFetched,
  createMenuFetching: selectCreateMenuFetching,
  createMenuObject: selectCreateMenu,
  deleteMenuError: selectDeleteMenuError,
  deleteMenuFetched: selectDeleteMenuFetched,
  deleteMenuFetching: selectDeleteMenuFetching,
  duplicateMenuError: selectDuplicateMenuError,
  duplicateMenuFetched: selectDuplicateMenuFetched,
  duplicateMenuFetching: selectDuplicateMenuFetching,
  menus: selectMenus,
  menusError: selectMenusError,
  menusFetched: selectMenusFetched,
  menusFetching: selectMenusFetching,
  menusPagination: selectMenusPagination,
  paginationMenuState: selectPaginationMenu,
  publishMenuError: selectPublishMenuError,
  publishMenuFetched: selectPublishMenuFetched,
  publishMenuFetching: selectPublishMenuFetching,
  publishMenusError: selectPublishMenusError,
  publishMenusFetched: selectPublishMenusFetched,
  publishMenusFetching: selectPublishMenusFetching,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadGetMenus: (values) => dispatch(getMenus(values)),
    createNewMenu: ({
      availability,
      name,
      sortingPosition,
      store,
      operationTimes,
      providers,
      brand,
      categories,
      sku,
    }) =>
      dispatch(
        createMenu({
          availability,
          name,
          sortingPosition,
          store,
          operationTimes,
          providers,
          brand,
          categories,
          sku,
        }),
      ),
    paginateMenu: (values) => dispatch(getPaginationMenu(values)),
    publishMenu: ({ menuUuid, storeUuid }) => dispatch(publishMenu({ menuUuid, storeUuid })),
    duplicateMenu: ({ menuUuid, storeUuid }) => dispatch(duplicateMenu({ menuUuid, storeUuid })),
    deleteMenu: (menuUuid) => dispatch(deleteMenu(menuUuid)),
    loadGetCopyMenuConfig: ({ menuUuid, storeUuid }) => dispatch(copyMenuConfig({ menuUuid, storeUuid })),
    publishMenus: ({ storeUuid, brandUuid, provider }) => dispatch(publishMenus({ storeUuid, brandUuid, provider })),
    updateMenus: (values) => dispatch(getMenus.update(values)),
    resetGetMenus: () => dispatch(getMenus.reset()),
    resetDeleteMenu: () => dispatch(deleteMenu.reset()),
    resetCreateMenu: () => dispatch(createMenu.reset()),
    resetPublishMenu: () => dispatch(publishMenu.reset()),
    resetDuplicateMenu: () => dispatch(duplicateMenu.reset()),
    resetCopyMenuConfig: () => dispatch(copyMenuConfig.reset()),
    resetPublishMenus: () => dispatch(publishMenus.reset()),
  };
}

export const withMenusState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withMenusState, withMenusHoc);
