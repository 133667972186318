import { useTranslation } from 'react-i18next';

import { ContentLayout } from 'Layout';

import { reportsTypes } from 'utils/reports';

function ReportsTabsNavigation() {
  const { t } = useTranslation('reports');

  return (
    <ContentLayout.Header sticky>
      <ContentLayout.TabsNavigation>
        <ContentLayout.TabLink
          label={t('reports:navigation.reportsResume')}
          to={`/reports/${reportsTypes.REPORTS_RESUME}`}
        />
        <ContentLayout.TabLink data-cy="reports-tab-button" label={t('reports:navigation.reports')} to="/reports" />
      </ContentLayout.TabsNavigation>
    </ContentLayout.Header>
  );
}

export default ReportsTabsNavigation;
