import DialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialogTitleRoot: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

interface Props extends DialogTitleProps {
  children: React.ReactNode;
}

function DialogLayoutTitle({ children, ...others }: Props) {
  const classes = useStyles();

  return (
    <DialogTitle classes={{ root: classes.dialogTitleRoot }} id="draggable-dialog-title" {...others}>
      {children}
    </DialogTitle>
  );
}

export default DialogLayoutTitle;
