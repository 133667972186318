import { useEffect, useState, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  getProductsOptions,
  getProductsOptionsByPagination,
  getProductsAutocomplete,
  getProductsAutocompleteByPagination,
} from './actions';
import {
  selectGetProductsOptions,
  selectGetProductsOptionsState,
  selectGetProductsOptionsPagination,
  selectGetProductsOptionsPaginationState,
  selectGetProductsAutocomplete,
  selectGetProductsAutocompletePagination,
  selectGetProductsAutocompletePaginationState,
  selectGetProductsAutocompleteState,
} from './selectors';

function useListsOptions() {
  const dispatch = useDispatch();

  const [productsOptionsList, setProductsOptionsList] = useState([]);
  const [productsAutocompleteList, setProductsAutocompleteList] = useState([]);

  const products = useSelector(selectGetProductsOptions);
  const productsOptionsStateTmp = useSelector(selectGetProductsOptionsState);
  const productsOptionsPaginationValuesTmp = useSelector(selectGetProductsOptionsPagination);
  const productsOptionsPaginationStateTmp = useSelector(selectGetProductsOptionsPaginationState);

  const productsAutocomplete = useSelector(selectGetProductsAutocomplete);
  const productsAutocompleteStateTmp = useSelector(selectGetProductsAutocompleteState);
  const productsAutocompletePaginationValuesTmp = useSelector(selectGetProductsAutocompletePagination);
  const productsAutocompletePaginationStateTmp = useSelector(selectGetProductsAutocompletePaginationState);

  useEffect(() => {
    setProductsOptionsList(products);
  }, [products]);

  useEffect(() => {
    setProductsAutocompleteList(productsAutocomplete);
  }, [productsAutocomplete]);

  const handleGetProductsOptions = useCallback(
    (filters = {}) => {
      dispatch(getProductsOptions(filters));
    },
    [dispatch],
  );

  const handleGetProductsOptionsReset = useCallback(() => {
    dispatch(getProductsOptions.reset());
  }, [dispatch]);

  const handleGetProductsOptionsPagination = useCallback(
    (filters = {}) => {
      dispatch(getProductsOptionsByPagination(filters));
    },
    [dispatch],
  );

  const handleGetProductsOptionsPaginationReset = useCallback(() => {
    dispatch(getProductsOptionsByPagination.reset());
  }, [dispatch]);

  const handleGetProductsAutocomplete = useCallback(
    (filters = {}) => {
      dispatch(getProductsAutocomplete(filters));
    },
    [dispatch],
  );

  const handleGetProductsAutocompleteReset = useCallback(() => {
    dispatch(getProductsAutocomplete.reset());
  }, [dispatch]);

  const handleGetProductsAutocompletePagination = useCallback(
    (filters = {}) => {
      dispatch(getProductsAutocompleteByPagination(filters));
    },
    [dispatch],
  );

  const handleGetProductsAutocompletePaginationReset = useCallback(() => {
    dispatch(getProductsAutocompleteByPagination.reset());
  }, [dispatch]);

  return {
    getListProductsOptions: handleGetProductsOptions,
    productsOptions: productsOptionsList,
    productsOptionsState: productsOptionsStateTmp,
    getListProductsOptionsReset: handleGetProductsOptionsReset,
    getListProductsOptionsPagination: handleGetProductsOptionsPagination,
    productsOptionsPaginationValues: productsOptionsPaginationValuesTmp,
    productsOptionsPaginationState: productsOptionsPaginationStateTmp,
    getListProductsOptionsPaginationReset: handleGetProductsOptionsPaginationReset,
    // autocomplete
    getListProductsAutocomplete: handleGetProductsAutocomplete,
    productsAutocomplete: productsAutocompleteList,
    productsAutocompleteState: productsAutocompleteStateTmp,
    getListProductsAutocompleteReset: handleGetProductsAutocompleteReset,
    getListProductsAutocompletePagination: handleGetProductsAutocompletePagination,
    productsAutocompletePaginationValues: productsAutocompletePaginationValuesTmp,
    productsAutocompletePaginationState: productsAutocompletePaginationStateTmp,
    getListProductsAutocompletePaginationReset: handleGetProductsAutocompletePaginationReset,
  };
}

export default useListsOptions;
