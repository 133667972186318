import { forwardRef, SVGProps, Ref } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  size: string | number;
}

const WarningCircle = forwardRef(function WarningCircle(props: Props, ref: Ref<SVGSVGElement>) {
  const { size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 72 72"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <g clipPath="url(#clip0_1519_32881)">
        <circle cx="36" cy="36" fill="#FCEBC0" r="36" />
        <path
          clipRule="evenodd"
          d="M37.3529 27.1765C37.3529 26.5267 36.8262 26 36.1765 26C35.5267 26 35 26.5267 35 27.1765V38.9412C35 39.5909 35.5267 40.1176 36.1765 40.1176C36.8262 40.1176 37.3529 39.5909 37.3529 38.9412V27.1765ZM37.3529 43.6471C37.3529 42.9973 36.8262 42.4706 36.1765 42.4706C35.5267 42.4706 35 42.9973 35 43.6471V44.8235C35 45.4733 35.5267 46 36.1765 46C36.8262 46 37.3529 45.4733 37.3529 44.8235V43.6471Z"
          fill="#F6BE2C"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_1519_32881">
          <rect fill="white" height="72" width="72" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default WarningCircle;
