import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ErrorIllustration = forwardRef(function ErrorIllustration(props, ref) {
  const { size = 88, ...others } = props;
  const currentHeight = size + 8;

  return (
    <svg
      ref={ref}
      fill="none"
      height={currentHeight}
      viewBox="0 0 88 96"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M18.1083 19.6262C18.1083 19.6262 16.361 19.5461 15.8845 21.7875C15.4079 24.0288 18.5054 28.0312 20.8087 32.5139C23.1119 36.9966 26.5207 45.6786 27.083 53.9668C27.6454 62.2549 41.5284 35.8815 41.5284 35.8815C41.5284 35.8815 23.6282 18.8641 18.1083 19.6262Z"
        fill="white"
      />
      <path
        d="M59.8454 60.2426C65.0897 63.5461 70.924 66.5199 73.4155 73.2592C75.907 79.9984 67.1872 95.9224 67.1872 95.9224L31.4257 93.8411C31.4257 93.8411 36.9329 86.6392 35.4906 81.1551C34.0483 75.671 27.5579 67.346 25.8535 64.5707C24.1491 61.7955 23.7559 60.2097 25.3293 56.2458C26.9026 52.281 34.3104 40.9822 36.5397 37.8107C38.7683 34.6392 43.1866 26.1341 48.4475 21.6274C53.2129 17.5449 55.9132 16.6644 57.8194 17.3848C59.1751 17.8972 73.1479 33.1543 74.2598 34.5151C75.2319 35.7046 75.1334 36.9166 74.3392 38.2774C75.8482 39.398 76.8974 40.1977 76.9601 42.2798C77.0912 46.6264 56.4049 54.8529 59.8454 60.2426Z"
        fill="white"
      />
      <path
        d="M55.7807 44.6492C55.7807 44.6492 66.5973 50.8601 69.8099 52.5779C73.0225 54.2958 74.9231 56.08 72.8907 57.9299C70.8583 59.7798 67.3184 60.309 63.9096 59.5157C60.5008 58.7224 53.1582 54.5599 53.1582 54.5599"
        fill="white"
      />
      <path
        d="M68.0816 59.8255C68.0816 59.8255 70.9654 61.0662 71.7184 62.1317C75.0541 66.8545 76.4837 73.2583 73.8628 77.2607C73.8628 77.2607 54.8626 62.0925 54.0104 58.3262C53.1582 54.5599 58.2714 54.6264 58.2714 54.6264L68.0816 59.8255Z"
        fill="white"
      />
      <path
        d="M42.0394 34.8321L41.9536 34.5239C43.5865 34.0613 45.7095 33.6786 47.7634 33.3088C48.714 33.1375 49.6973 32.9606 50.621 32.7773L50.6821 33.0919C49.7561 33.2752 48.7712 33.4529 47.819 33.6242C45.7722 33.9924 43.6572 34.3735 42.0394 34.8321Z"
        fill="black"
      />
      <path
        d="M31.0818 64.7997L14.9201 52.6908L14.4404 50.7704L84.6149 18.1421L85.0264 20.1921L31.0818 64.7997Z"
        fill="black"
      />
      <path
        d="M70.9954 39.6414C70.6809 39.6414 70.3696 39.6118 70.0654 39.5525C68.9424 39.3332 67.0751 38.4038 65.0713 37.067L65.2468 36.7997C67.2181 38.1149 69.0424 39.0266 70.1265 39.2379C71.1853 39.4445 72.3456 39.262 73.3924 38.7224C74.0802 38.3678 74.5687 37.7242 74.7307 36.9558C74.8959 36.1761 74.7069 35.374 74.2128 34.7561C73.4782 33.8371 72.4425 32.6684 71.0471 31.1835L71.2774 30.9634C72.6792 32.4547 73.7204 33.6298 74.4599 34.5559C75.0142 35.25 75.2263 36.1497 75.0412 37.0238C74.8586 37.8867 74.3097 38.6104 73.5362 39.009C72.7261 39.4261 71.8492 39.6414 70.9954 39.6414Z"
        fill="black"
      />
      <path
        d="M67.3265 96L67.0486 95.8455C67.07 95.8055 69.2462 91.8159 71.0888 87.057C73.5183 80.7845 74.2712 76.0328 73.2665 73.3152C71.0594 67.3444 66.1749 64.337 61.4516 61.4281C60.8949 61.0855 60.3199 60.7308 59.7607 60.3786L59.9291 60.1073C60.4867 60.4587 61.0617 60.8125 61.6176 61.1551C66.387 64.0929 71.3191 67.1299 73.5644 73.2039C74.6112 76.0368 73.8781 80.7381 71.3834 87.1771C69.5329 91.9544 67.3488 95.96 67.3265 96Z"
        fill="black"
      />
      <path
        d="M67.4513 65.4057L84.0037 53.8443L84.5462 51.9408L15.4859 16.9806L15.0078 19.0154L67.4513 65.4057Z"
        fill="black"
      />
      <path d="M67.9163 63.3868L84.5457 51.9408L30.5774 5.92276L15.4482 17.011L67.9163 63.3868Z" fill="#F04E4A" />
      <path d="M30.6831 62.7665L14.4404 50.7705L69.8964 6.58475L84.6515 18.1733L30.6831 62.7665Z" fill="#F04E4A" />
      <path
        d="M35.8989 81.2631C36.4898 86.9049 31.4258 93.841 31.4258 93.841L67.1873 95.9223C67.1873 95.9223 75.9063 79.9983 73.4156 73.2591C70.9249 66.5198 65.1628 63.426 59.8455 60.2425C55.9133 57.8891 57.1046 51.005 54.3249 44.7612C51.7929 39.0738 48.3682 37.6369 44.397 39.1579C42.7689 39.7814 42.8086 43.0002 43.444 48.5235C43.853 52.0776 44.5051 54.8248 43.39 57.8643C42.2757 60.9037 39.063 61.6962 39.063 61.6962C39.063 61.6962 34.5225 68.1144 35.8989 81.2631Z"
        fill="white"
      />
      <path
        d="M67.3265 96L67.0485 95.8455C67.07 95.8054 69.2462 91.8159 71.0888 87.057C73.5183 80.7844 74.2712 76.0328 73.2665 73.3152C71.057 67.338 66.1662 64.3282 61.4358 61.4168C60.8846 61.0774 60.3143 60.7268 59.7607 60.3786C57.2256 58.784 56.7919 55.9783 56.29 52.7292C55.9254 50.371 55.5124 47.6982 54.2592 44.9069C52.8502 41.7682 51.0966 39.7846 49.0451 39.0098C47.1135 38.2805 45.3948 38.8305 44.5418 39.2235C42.6293 40.1048 43.0574 43.2379 43.5522 46.8657C43.6213 47.3716 43.6928 47.8951 43.7595 48.4234C43.7849 48.6275 43.8111 48.8293 43.8366 49.0286C44.2646 52.3754 44.6022 55.0186 43.5387 57.9203C42.4244 60.9597 40.7637 62.5807 40.6938 62.6488L40.4746 62.4174C40.4913 62.4014 42.1536 60.7748 43.2409 57.8098C44.2781 54.9817 43.9446 52.373 43.522 49.0702C43.4966 48.8709 43.4704 48.6692 43.445 48.465C43.3783 47.9391 43.3076 47.4164 43.2385 46.9105C42.7262 43.1563 42.2831 39.9135 44.4108 38.9329C45.3083 38.5191 47.1183 37.9403 49.1579 38.7112C51.2911 39.5173 53.1052 41.5577 54.5498 44.7764C55.8206 47.6069 56.2368 50.3029 56.6045 52.6812C57.1152 55.9832 57.5179 58.5903 59.9299 60.108C60.4819 60.4554 61.0514 60.806 61.6026 61.1446C66.3782 64.084 71.3175 67.1234 73.5652 73.2039C74.6127 76.0368 73.8789 80.738 71.3842 87.1771C69.5329 91.9543 67.3488 95.9599 67.3265 96Z"
        fill="black"
      />
      <path
        d="M31.5523 93.9388L31.3006 93.7434C31.3141 93.7258 32.6841 91.9231 33.8595 89.4793C34.9381 87.2371 36.0699 83.98 35.3376 81.1959C34.2424 77.0318 30.2856 71.3428 27.667 67.5781C26.8815 66.4487 26.2033 65.4729 25.799 64.8149C24.6617 62.9626 24.0025 61.4337 24.2296 59.5357L24.5449 59.5741C24.3281 61.3792 24.9674 62.8529 26.069 64.6468C26.4685 65.2968 27.1444 66.2694 27.9275 67.3948C30.5588 71.1779 34.5354 76.8957 35.645 81.1143C36.4026 83.9944 35.2463 87.3324 34.1431 89.625C32.9525 92.0969 31.5658 93.9212 31.5523 93.9388Z"
        fill="black"
      />
      <path
        d="M24.709 42.652C23.1753 38.1549 21.9046 35.0522 20.5894 32.59C19.8515 31.2083 19.132 29.9556 18.4982 28.8501C16.82 25.9251 15.6072 23.8111 15.7263 22.2557C15.8073 21.1943 16.0599 20.4835 16.4761 20.1425C16.7485 19.9199 16.9907 19.9447 17.0177 19.9479L16.9796 20.2657C16.9741 20.2649 16.8343 20.2553 16.6627 20.4026C16.4308 20.6019 16.1346 21.0894 16.0432 22.2797C15.932 23.7358 17.1242 25.8139 18.7738 28.6892C19.4091 29.7963 20.1295 31.0514 20.8697 32.4379C22.1937 34.9162 23.4708 38.034 25.01 42.5479L24.709 42.652Z"
        fill="black"
      />
      <path
        d="M48.8698 21.5873L48.6602 21.3472C48.8762 21.1559 53.9894 16.6604 56.6874 17.1175L56.635 17.4336C54.0839 16.999 48.9215 21.5417 48.8698 21.5873Z"
        fill="black"
      />
      <path
        d="M75.8674 44.622L75.6474 44.3914C76.5425 43.5237 76.9396 42.6192 76.8292 41.7018C76.5996 39.8039 74.2758 38.4303 74.252 38.4167L74.4108 38.1397C74.4362 38.1541 75.0374 38.5063 75.6768 39.1131C76.5329 39.9248 77.0404 40.8061 77.1445 41.6626C77.2676 42.684 76.8387 43.6798 75.8674 44.622Z"
        fill="black"
      />
      <path
        d="M74.0713 77.1719L73.7988 77.0078C74.8655 75.2091 75.7844 72.0616 74.1054 67.2772C73.0952 64.3986 71.602 62.2686 71.5869 62.2469L71.8458 62.0612C71.8609 62.0828 73.3763 64.2433 74.4025 67.1627C75.7662 71.045 75.6518 74.5063 74.0713 77.1719Z"
        fill="black"
      />
      <path
        d="M8.28547 33.0182C7.40706 33.0182 6.46273 32.4451 5.90121 31.5389C5.48821 30.8729 5.30316 30.1029 5.33969 29.2688C2.77832 29.7851 0.266193 31.0386 0.230453 31.057C0.151825 31.0963 0.0565177 31.0642 0.0176007 30.9858C-0.0213162 30.9073 0.0104527 30.8105 0.0882867 30.7713C0.100994 30.7649 1.38843 30.1197 3.04756 29.5617C3.87912 29.2824 4.65269 29.0726 5.3659 28.9342C5.4906 27.8207 5.99334 26.6064 6.86063 25.3784C6.91146 25.3064 7.01074 25.2896 7.08222 25.3408C7.1537 25.392 7.17038 25.4921 7.11955 25.5641C6.66287 26.2109 5.88374 27.4853 5.69233 28.8749C6.75977 28.6948 7.68265 28.6812 8.45067 28.8365C10.3949 29.2287 10.5879 30.363 10.58 30.8329C10.5641 31.8439 9.67774 32.8133 8.60395 32.9934C8.49912 33.0094 8.39269 33.0182 8.28547 33.0182ZM5.65818 29.2079C5.61132 29.9324 5.73998 30.676 6.17045 31.3692C6.73515 32.2794 7.71442 32.8173 8.55233 32.6764C9.48157 32.5203 10.2488 31.6902 10.2623 30.8265C10.2758 30.0036 9.59276 29.392 8.38793 29.1495C7.57067 28.9846 6.6176 29.0382 5.65818 29.2079Z"
        fill="black"
      />
      <path
        d="M84.2669 67.3348C84.193 67.3348 84.1271 67.2827 84.1112 67.2075C84.093 67.121 84.1478 67.0362 84.2335 67.0178C85.6568 66.716 86.2604 65.8011 86.3724 64.9542C86.2565 65.0326 86.1326 65.1014 86.0023 65.1599C85.3352 65.4561 84.5933 65.4008 83.7975 64.9966C83.2487 64.7172 83.0112 64.0552 83.2328 63.422C83.5465 62.5255 84.4742 62.3846 85.1731 62.5359C85.9626 62.7064 86.5432 63.3916 86.679 64.2633C87.3771 63.4957 87.6829 62.4231 87.6869 62.4086C87.7107 62.3238 87.7981 62.2742 87.883 62.2982C87.9672 62.3222 88.0165 62.4102 87.9927 62.4959C87.9736 62.5631 87.6019 63.8655 86.7092 64.6876C86.7028 65.2687 86.4979 65.8347 86.131 66.2853C85.7069 66.8057 85.0731 67.1683 84.2987 67.3324C84.2884 67.334 84.278 67.3348 84.2669 67.3348ZM84.6656 62.7985C84.0286 62.7985 83.6657 63.1507 83.533 63.5285C83.3893 63.9392 83.483 64.4771 83.9413 64.71C84.6481 65.0694 85.2986 65.1223 85.8744 64.8661C86.0619 64.7829 86.2334 64.6716 86.3891 64.5419C86.3462 63.6926 85.8315 63.005 85.1064 62.8481C84.9476 62.8137 84.7998 62.7985 84.6656 62.7985Z"
        fill="black"
      />
      <path
        d="M11.5957 67.9752C11.5473 67.9752 11.4988 67.9528 11.4678 67.9103C11.4154 67.8391 11.4305 67.739 11.5004 67.6862L16.4246 64.0039C16.4953 63.9511 16.5945 63.9663 16.647 64.0375C16.6994 64.1088 16.6843 64.2089 16.6144 64.2617L11.6902 67.944C11.6616 67.9648 11.6291 67.9752 11.5957 67.9752Z"
        fill="#F04E4A"
      />
      <path
        d="M16.0434 68.2953C16.0021 68.2953 15.96 68.2785 15.929 68.2465L11.6402 63.7638C11.5791 63.6997 11.5814 63.5989 11.6442 63.5372C11.7069 63.4756 11.8078 63.478 11.8689 63.5412L16.1577 68.024C16.2189 68.088 16.2165 68.1889 16.1538 68.2505C16.1228 68.2801 16.0831 68.2953 16.0434 68.2953Z"
        fill="#F04E4A"
      />
      <path
        d="M48.2888 10.7408C48.2777 10.7408 48.2658 10.7392 48.2539 10.7368C48.1681 10.7176 48.1141 10.632 48.1331 10.5455C48.2928 9.8227 47.8829 9.15349 46.7631 8.30659C46.1889 7.87273 45.926 7.13708 46.0411 6.28858C46.0618 6.13408 46.0951 5.98279 46.138 5.8355C45.6226 5.4985 45.1024 4.9886 44.6576 4.25936C44.3963 3.8319 44.355 3.35321 44.5218 2.86012C44.1707 2.72964 43.8038 2.56874 43.4218 2.37823C42.3003 1.81789 41.4767 1.20873 41.4425 1.18311C41.3719 1.13028 41.3568 1.03022 41.4092 0.958978C41.4608 0.887735 41.5609 0.872526 41.6316 0.925358C41.6395 0.931762 42.4711 1.54573 43.5679 2.09406C43.942 2.28057 44.3026 2.43746 44.6473 2.56394C44.8283 2.20292 45.119 1.83871 45.513 1.48409C46.1158 0.942168 46.9005 0.480291 47.6661 0.215332C48.1442 0.0496323 49.0187 -0.1713 49.4976 0.208127C49.909 0.533923 50.0798 0.950173 49.9789 1.38163C49.7923 2.18211 48.7042 2.90014 47.2079 3.21233C46.5375 3.35161 45.7369 3.26916 44.818 2.96498C44.6496 3.47249 44.7847 3.85832 44.9276 4.09206C45.2286 4.58515 45.6917 5.13028 46.2492 5.52092C46.5232 4.86372 47.0133 4.3338 47.5938 4.10647C48.4762 3.76066 48.8829 4.06804 49.0465 4.28177C49.3467 4.67481 49.2832 5.328 48.8924 5.87072C48.643 6.21573 48.2126 6.39504 47.678 6.37423C47.3111 6.35982 46.8735 6.24695 46.4208 6.0036C46.393 6.11087 46.3707 6.22053 46.3556 6.3318C46.2564 7.06184 46.474 7.68781 46.9529 8.05043C48.1713 8.97098 48.6303 9.76186 48.4421 10.6144C48.4278 10.6904 48.3619 10.7408 48.2888 10.7408ZM46.5232 5.69542C46.8854 5.90354 47.2801 6.03963 47.6915 6.05564C48.1164 6.07245 48.4516 5.94037 48.6367 5.68421C48.9409 5.26236 49.0084 4.75566 48.7963 4.47869C48.6073 4.23214 48.2221 4.20573 47.7106 4.40665C47.1943 4.60837 46.7591 5.09426 46.5232 5.69542ZM44.9475 2.668C45.7989 2.94417 46.5447 3.02422 47.1451 2.89934C48.2729 2.664 49.4913 2.08125 49.6715 1.30879C49.7621 0.920555 49.5214 0.633182 49.303 0.460279C48.7423 0.0160121 46.9037 0.665202 45.7258 1.72424C45.3438 2.06764 45.0976 2.38303 44.9475 2.668Z"
        fill="#F04E4A"
      />
    </svg>
  );
});

ErrorIllustration.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ErrorIllustration;
