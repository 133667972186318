import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_ALL_INVOICES_UNINVOICED_ORDERS,
  GET_ALL_INVOICES_UNINVOICED_ORDERS_PAGINATION,
  GENERATE_INVOICING,
} from './constants';

export const getAllInvoicesUninvoicedOrders = extendRoutine(createRoutine(GET_ALL_INVOICES_UNINVOICED_ORDERS), [
  'RESET',
  'SORTING',
  'RESET_SORTING',
]);
export const getAllInvoicesUninvoicedOrdersPagination = extendRoutine(
  createRoutine(GET_ALL_INVOICES_UNINVOICED_ORDERS_PAGINATION),
  'RESET',
);
export const generateInvoicing = extendRoutine(createRoutine(GENERATE_INVOICING), 'RESET');
