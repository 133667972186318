/* eslint-disable no-unused-vars */
import queryString from 'query-string';

import request from 'utils/request';

export const API_BASE_URL = `https://maps.googleapis.com/maps/api`;

export function geocodingLookupLatitudeLongitudeRequest({ address }, options = {}) {
  const query = queryString.stringify({
    key: 'AIzaSyBmuLEB-EAmaQ2LeHXQCV7IKChAYWF77Z8',
    address,
  });

  return request(`https://maps.googleapis.com/maps/api/geocode/json?${query}`, {
    method: 'GET',
    ...options,
  });
}
