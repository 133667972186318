/**
 *
 * ParrotSoftware
 * PaymentsGatewayProvider selectors
 *
 */
import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectPaymentsGatewayProviderDomain = (state) => state.paymentsGatewayProvider || initialState;

const selectPaymentMethods = createSelector(
  selectPaymentsGatewayProviderDomain,
  ({ paymentsMethods }) => paymentsMethods.list,
);

const selectPaymentMethodsState = createSelector(
  selectPaymentsGatewayProviderDomain,
  ({ paymentsMethods: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectCreatePaymentMethod = createSelector(
  selectPaymentsGatewayProviderDomain,
  ({ createPaymentMethod }) => createPaymentMethod.data,
);

const selectCreatePaymentMethodState = createSelector(
  selectPaymentsGatewayProviderDomain,
  ({ createPaymentMethod: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectResumeMethod = createSelector(
  selectPaymentsGatewayProviderDomain,
  ({ resumePaymentMethod }) => resumePaymentMethod.data,
);

const selectResumeMethodState = createSelector(
  selectPaymentsGatewayProviderDomain,
  ({ resumePaymentMethod: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdatePaymentMethodStatus = createSelector(
  selectPaymentsGatewayProviderDomain,
  ({ updatePaymentMethodStatus }) => updatePaymentMethodStatus.data,
);

const selectUpdatePaymentMethodStatusState = createSelector(
  selectPaymentsGatewayProviderDomain,
  ({ updatePaymentMethodStatus: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

export {
  selectPaymentsGatewayProviderDomain,
  selectPaymentMethods,
  selectPaymentMethodsState,
  selectCreatePaymentMethod,
  selectCreatePaymentMethodState,
  selectResumeMethod,
  selectResumeMethodState,
  selectUpdatePaymentMethodStatus,
  selectUpdatePaymentMethodStatusState,
};
