import { useRef, useCallback } from 'react';

const booleansStringsTypes = {
  TRUE: 'true',
  FALSE: 'false',
  NULL: 'null',
};

function useEnvironment() {
  const environmentsRef = useRef(process.env);
  const isTestEnvRef = useRef();
  const environmentRef = useRef();
  const isEnvDevRef = useRef();
  const valueFeatureEnvironmentEnabledRef = useRef(environmentsRef.current.REACT_APP_FEATURE_ENVIRONMENT_ENABLED);

  isTestEnvRef.current = environmentsRef.current.REACT_APP_BANNER_ENV === booleansStringsTypes.TRUE;
  environmentRef.current = environmentsRef.current.NODE_ENV;
  isEnvDevRef.current = environmentRef.current === 'development';

  // eslint-disable-next-line no-unused-vars
  const enabled = useCallback((scope, description) => {
    if (valueFeatureEnvironmentEnabledRef.current === booleansStringsTypes.TRUE) {
      return true;
    }
    return false;
  }, []);

  return {
    featureEnvironment: {
      enabled,
    },
    isTestEnv: isTestEnvRef.current,
    environmentsState: environmentsRef.current,
    environment: environmentRef.current,
    isEnvDev: isEnvDevRef.current,
    conektaPublicKey: environmentsRef.current.REACT_APP_CONEKTA_PUBLIC_KEY,
    amplitudeKey: environmentsRef.current.REACT_APP_AMPLITUDE_KEY,
    spreedlyEnvKey: environmentsRef.current.REACT_APP_SPREEDLY_ENV_TOKEN,
  };
}

export default useEnvironment;
