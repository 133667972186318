/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import { SortableHandle } from 'react-sortable-hoc';

import camelCase from 'lodash/camelCase';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';

import IconButton from '@design-system/IconButton';
import Table from '@experimental-components/Table';
import IconMoreVertical from '@experimental-components/IconsComponents/MoreVerticalV2';
import DragHandleHorizontal from '@experimental-components/IconsComponents/DragHandleHorizontal';
import useNotification from '@design-system/Notification/useNotification';

import { useMenus } from 'providers/menuMaker/Menus';
import { useSortingPositionMenus } from 'providers/menuMaker/Menus/hooks';

import ActionsMenuMaker from 'components/ActionsMenuMaker';
import ChanelItem from 'components/ChanelItem';
import ConfirmDialog from 'components/ConfirmDialog';

import { getScheduleFormatted } from 'utils/menus/schedule';
import { drawerActions, drawerEntities } from 'utils/drawerMenuMaker';
import arrayMove from 'utils/array';

import HelperSkeleton from './HelperSkeleton';
import useStyles from './styles';

const DragHandle = SortableHandle(({ children }) => (
  <IconButton aria-label="drag" size="small">
    {children}
  </IconButton>
));

function MenuMakerTable({
  deleteMenuState,
  isSearchFilter,
  menus,
  onCopyMenu,
  onDeleteMenu,
  onDuplicate,
  scrollPaginationRef,
  loadingPagination,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { sortMenusPosition } = useMenus();
  const sortPosition = useSortingPositionMenus();
  const { setNotification } = useNotification();

  const [selectedMenu, setSelectedMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDialog, setAnchorDialog] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (deleteMenuState.fetched || deleteMenuState.error) {
      setAnchorDialog(null);
      setSelectedMenu(null);
      setOpenDialog(false);
    }
  }, [deleteMenuState]);

  const handleEditMenu = (menu) => {
    const query = { action: drawerActions.EDIT, entity: drawerEntities.MENU, uuid: menu?.uuid };

    history.replace({ search: stringify(query) });
  };

  const handleOpenActionsMenu = (menuUuid) => (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(menuUuid);
  };

  const handleCloseActionsMenu = () => {
    setAnchorEl(null);
    setSelectedMenu(null);
  };

  function handleDeleteMenu() {
    onDeleteMenu(selectedMenu?.uuid);
  }

  const handleConfirmDeleteMenu = () => {
    setAnchorDialog(anchorEl);
    setAnchorEl(null);
    setOpenDialog(true);
  };

  function handleCancelDeleteMenu() {
    setAnchorEl(null);
    setOpenDialog(false);
    setAnchorDialog(null);
    setSelectedMenu(null);
  }

  function handleDuplicateMenu() {
    setAnchorEl(null);
    onDuplicate(selectedMenu?.uuid);
  }

  function handleCopyMenu() {
    onCopyMenu(selectedMenu);
    setAnchorEl(null);
  }

  const handleMenuActionsEdit = () => {
    setAnchorEl(null);
    handleEditMenu(selectedMenu);
  };

  function handlePreviewMenu() {
    history.push(`/menus/preview/${selectedMenu?.uuid}`);
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const initMenus = JSON.parse(JSON.stringify(menus));

      const sortedMenus = arrayMove(menus, oldIndex, newIndex);
      sortMenusPosition(sortedMenus);

      const movedMenuUuid = sortedMenus[newIndex]?.uuid;

      sortPosition.mutate(
        { menuUuid: movedMenuUuid, newPosition: newIndex },
        {
          onError: () => {
            setNotification(t('menuMaker:messages.sortPositionMenuError'), { variant: 'error' });
            sortMenusPosition(initMenus);
          },
        },
      );
    }
  };

  function renderBody() {
    return menus?.map((menu, index) => (
      <Table.SortableRow key={menu?.uuid} className={classes.row} index={index}>
        <Table.Cell className={classes.td} isBorder={false} scope="row">
          <Box className={classes.rootRow}>
            <Box className={classes.headerRow}>
              <Box className={classes.contentLeftRow}>
                {!isSearchFilter && (
                  <DragHandle>
                    <DragHandleHorizontal />
                  </DragHandle>
                )}
                <Box>
                  <Link
                    className={classes.labelRow}
                    component="button"
                    data-testid={menu?.uuid}
                    onClick={() => handleEditMenu(menu)}
                    underline="none"
                    variant="body2"
                  >
                    {menu?.name}
                  </Link>

                  <Box className={classes.wrapperTimesRow}>
                    {menu?.operationTimes?.map((time) => (
                      <div key={time?.uuid}>
                        <Chip className={classes.chipTimesRow} label={getScheduleFormatted(time, t)} size="small" />
                        {time?.startHour} - {time?.endHour}
                      </div>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.contentRightRow}>
                <Box className={classes.providersRow}>
                  {menu?.providers?.map((provider) => (
                    <ChanelItem
                      key={camelCase(`${menu?.uuid}-${provider?.providerName || provider}`)}
                      size={24}
                      value={provider?.providerName || provider}
                    />
                  ))}
                </Box>
                <IconButton aria-label="actions" onClick={handleOpenActionsMenu(menu)}>
                  <IconMoreVertical />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Table.Cell>
      </Table.SortableRow>
    ));
  }

  return (
    <>
      <Box pb={1}>
        <Table aria-label="menu-maker-table" className={classes.table}>
          <Table.BodyDraggable onSortEnd={handleSortEnd} useDragHandle>
            {renderBody()}
          </Table.BodyDraggable>
        </Table>
        {loadingPagination && <HelperSkeleton dragActive={!isSearchFilter} />}

        <Box ref={scrollPaginationRef} mb={6} name="actionTableScroll" />
      </Box>

      <ActionsMenuMaker
        anchorEl={anchorEl}
        onClose={handleCloseActionsMenu}
        onCopy={handleCopyMenu}
        onDelete={handleConfirmDeleteMenu}
        onDuplicate={handleDuplicateMenu}
        onEdit={handleMenuActionsEdit}
        onPreview={handlePreviewMenu}
        open={Boolean(anchorEl)}
      />

      <ConfirmDialog
        anchorEl={anchorElDialog}
        id="dialogConfirmDeleteMenu"
        isDisabled={deleteMenuState.fetching}
        labelAccept={t('common:buttons.delete')}
        labelCancel={t('common:buttons.cancel')}
        loading={deleteMenuState.fetching}
        onAccept={handleDeleteMenu}
        onCancel={handleCancelDeleteMenu}
        open={openDialog}
        title={t('menuMaker:deleteMenu.popperDialog.title')}
      />
    </>
  );
}

MenuMakerTable.propTypes = {
  menus: PropTypes.array,
  loadingPagination: PropTypes.bool,
  isSearchFilter: PropTypes.bool,
  onCopyMenu: PropTypes.func,
  onDeleteMenu: PropTypes.func,
  onDuplicate: PropTypes.func,
  deleteMenuState: PropTypes.object,
  scrollPaginationRef: PropTypes.object,
};

export default MenuMakerTable;
