import { useEffect, Children } from 'react';
import PropTypes from 'prop-types';

import amplitude from 'amplitude-js';
import useEnvironment from 'hooks/useEnvironment';

export function AmplitudeProvider({ children }) {
  const instance = amplitude.getInstance();

  const { amplitudeKey } = useEnvironment();

  useEffect(() => {
    instance.init(amplitudeKey);
  }, [amplitudeKey, instance]);

  return Children.only(children);
}

AmplitudeProvider.propTypes = {
  children: PropTypes.element,
};

export default AmplitudeProvider;
