import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectServiceChargesProviderDomain = (state) => state.settingsServiceCharges || initialState;

const selectServiceCharges = createSelector(
  selectServiceChargesProviderDomain,
  (subState) => subState.getServiceCharges.serviceCharges,
);

const selectServiceChargesState = createSelector(selectServiceChargesProviderDomain, ({ getServiceCharges }) => {
  return {
    fetching: getServiceCharges.fetching,
    fetched: getServiceCharges.fetched,
    error: getServiceCharges.error,
  };
});

const selectServiceChargesPagination = createSelector(
  selectServiceChargesProviderDomain,
  (subState) => subState.getServiceCharges.pagination,
);

const selectServiceChargesPaginationState = createSelector(
  selectServiceChargesProviderDomain,
  (subState) => subState.loadPaginationStatus,
);

// details
const selectServiceChargeDetails = createSelector(
  selectServiceChargesProviderDomain,
  (subState) => subState.getServiceCharge.serviceCharge,
);

const selectServiceChargeDetailsState = createSelector(selectServiceChargesProviderDomain, ({ getServiceCharge }) => {
  return {
    fetching: getServiceCharge.fetching,
    fetched: getServiceCharge.fetched,
    error: getServiceCharge.error,
  };
});

// create
const selectCreateServiceCharge = createSelector(
  selectServiceChargesProviderDomain,
  (subState) => subState.createServiceCharge.serviceCharge,
);

const selectCreateServiceChargeState = createSelector(selectServiceChargesProviderDomain, ({ createServiceCharge }) => {
  return {
    fetching: createServiceCharge.fetching,
    fetched: createServiceCharge.fetched,
    error: createServiceCharge.error,
  };
});

// update
const selectUpateServiceCharge = createSelector(
  selectServiceChargesProviderDomain,
  (subState) => subState.updateServiceCharge.serviceCharge,
);

const selectUpateServiceChargeState = createSelector(selectServiceChargesProviderDomain, ({ updateServiceCharge }) => {
  return {
    fetching: updateServiceCharge.fetching,
    fetched: updateServiceCharge.fetched,
    error: updateServiceCharge.error,
  };
});

// delete
const selectDeleteServiceChargeState = createSelector(selectServiceChargesProviderDomain, ({ deleteServiceCharge }) => {
  return {
    fetching: deleteServiceCharge.fetching,
    fetched: deleteServiceCharge.fetched,
    error: deleteServiceCharge.error,
  };
});

// enable/disabled
const selectSetIsEnabledServiceChargeState = createSelector(
  selectServiceChargesProviderDomain,
  ({ setIsEnabledServiceCharge }) => {
    return {
      fetching: setIsEnabledServiceCharge.fetching,
      fetched: setIsEnabledServiceCharge.fetched,
      error: setIsEnabledServiceCharge.error,
    };
  },
);

export {
  selectServiceChargesProviderDomain,
  selectServiceCharges,
  selectServiceChargesState,
  selectServiceChargesPagination,
  selectServiceChargesPaginationState,
  selectServiceChargeDetails,
  selectServiceChargeDetailsState,
  selectCreateServiceCharge,
  selectCreateServiceChargeState,
  selectUpateServiceCharge,
  selectUpateServiceChargeState,
  selectDeleteServiceChargeState,
  selectSetIsEnabledServiceChargeState,
};
