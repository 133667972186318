import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectSubscriptionProviderDomain = (state) => state.subscriptionProvider || initialState;

const selectSubscription = createSelector(
  selectSubscriptionProviderDomain,
  (subState) => subState.getSubscriptionHome.subscription,
);

const selectSubscriptionState = createSelector(selectSubscriptionProviderDomain, ({ getSubscriptionHome }) => {
  return {
    fetching: getSubscriptionHome.fetching,
    fetched: getSubscriptionHome.fetched,
    error: getSubscriptionHome.error,
  };
});

const selectDeletePaymentMethodState = createSelector(selectSubscriptionProviderDomain, ({ deletePaymentMethod }) => {
  return {
    fetching: deletePaymentMethod.fetching,
    fetched: deletePaymentMethod.fetched,
    error: deletePaymentMethod.error,
  };
});

const selectSavePaymentMethod = createSelector(
  selectSubscriptionProviderDomain,
  (subState) => subState.savePaymentMethod.paymentMethod,
);

const selectSavePaymentMethodState = createSelector(selectSubscriptionProviderDomain, ({ savePaymentMethod }) => {
  return {
    fetching: savePaymentMethod.fetching,
    fetched: savePaymentMethod.fetched,
    error: savePaymentMethod.error,
  };
});

const selectSubscriptionPayments = createSelector(
  selectSubscriptionProviderDomain,
  (subState) => subState.getSubscriptionPayments.payments,
);

const selectSubscriptionPaymentsState = createSelector(
  selectSubscriptionProviderDomain,
  ({ getSubscriptionPayments }) => {
    return {
      fetching: getSubscriptionPayments.fetching,
      fetched: getSubscriptionPayments.fetched,
      error: getSubscriptionPayments.error,
    };
  },
);

const selectSubscriptionPaymentsPagination = createSelector(
  selectSubscriptionProviderDomain,
  (subState) => subState.getSubscriptionPayments.pagination,
);

const selectSubscriptionPaymentsPaginationStatus = createSelector(
  selectSubscriptionProviderDomain,
  (subState) => subState.loadPaginationStatus,
);

const selectSubscriptionInvoiceData = createSelector(
  selectSubscriptionProviderDomain,
  (subState) => subState.getSubscriptionInvoiceData.invoiceData,
);

const selectSubscriptionInvoiceDataState = createSelector(
  selectSubscriptionProviderDomain,
  ({ getSubscriptionInvoiceData }) => {
    return {
      fetching: getSubscriptionInvoiceData.fetching,
      fetched: getSubscriptionInvoiceData.fetched,
      error: getSubscriptionInvoiceData.error,
    };
  },
);

const selectUpdateSubscriptionInvoiceData = createSelector(
  selectSubscriptionProviderDomain,
  (subState) => subState.updateSubscriptionInvoiceData.invoiceData,
);

const selectUpdateSubscriptionInvoiceDataState = createSelector(
  selectSubscriptionProviderDomain,
  ({ updateSubscriptionInvoiceData }) => {
    return {
      fetching: updateSubscriptionInvoiceData.fetching,
      fetched: updateSubscriptionInvoiceData.fetched,
      error: updateSubscriptionInvoiceData.error,
    };
  },
);

export {
  selectSubscriptionProviderDomain,
  selectSubscription,
  selectSubscriptionState,
  selectDeletePaymentMethodState,
  selectSavePaymentMethod,
  selectSavePaymentMethodState,
  selectSubscriptionPayments,
  selectSubscriptionPaymentsState,
  selectSubscriptionPaymentsPagination,
  selectSubscriptionPaymentsPaginationStatus,
  selectSubscriptionInvoiceData,
  selectSubscriptionInvoiceDataState,
  selectUpdateSubscriptionInvoiceData,
  selectUpdateSubscriptionInvoiceDataState,
};
