import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_REPORTS_CANCELLATIONS_RESUME,
  GET_REPORTS_CANCELLATIONS_DETAILS,
  GET_REPORTS_CANCELLATIONS_DETAILS_BY_PAGINATION,
  GET_REPORTS_CANCELLATIONS_DETAILS_BY_SORTING,
  EXPORT_REPORTS_CANCELLATIONS,
} from './constants';

export const getReportsCancellationsResume = extendRoutine(createRoutine(GET_REPORTS_CANCELLATIONS_RESUME), 'RESET');
export const getReportsCancellationsDetails = extendRoutine(createRoutine(GET_REPORTS_CANCELLATIONS_DETAILS), 'RESET');
export const getReportsCancellationsDetailsByPagination = extendRoutine(
  createRoutine(GET_REPORTS_CANCELLATIONS_DETAILS_BY_PAGINATION),
  'RESET',
);
export const getReportsCancellationsDetailsBySorting = extendRoutine(
  createRoutine(GET_REPORTS_CANCELLATIONS_DETAILS_BY_SORTING),
  ['RESET', 'SORTING', 'RESET_SORTING'],
);
export const exportReportsCancellations = extendRoutine(createRoutine(EXPORT_REPORTS_CANCELLATIONS), 'RESET');
