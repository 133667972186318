/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1300,
  },
}));

export default useStyles;
