import queryString from 'query-string';

import { api } from 'config';
import { Response } from 'types';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

import { TopPaymentsRequest, DataResponseChart } from './types';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

// @ts-ignore
export function getReportsResumeSummariesRequest({ startDate, endDate, stores }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/general/?${query}`, {
    method: 'POST',
    body: JSON.stringify({ stores }),
    ...options,
  });
}

// @ts-ignore
export function getReportsResumeSalesChannelsRequest({ startDate, endDate, stores }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/provider-sales/?${query}`, {
    method: 'POST',
    body: JSON.stringify({ stores }),
    ...options,
  });
}

// @ts-ignore
export function getReportsResumeTopProductsRequest({ startDate, endDate, stores }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/top-products/?${query}`, {
    method: 'POST',
    body: JSON.stringify({ stores }),
    ...options,
  });
}

// @ts-ignore
export function getReportsResumeSalesBrandsRequest({ startDate, endDate, stores }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/brand-sales/?${query}`, {
    method: 'POST',
    body: JSON.stringify({ stores }),
    ...options,
  });
}

// @ts-ignore
export function getReportsResumeSalesCategoriesRequest({ startDate, endDate, stores }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/category-sales/?${query}`, {
    method: 'POST',
    body: JSON.stringify({ stores }),
    ...options,
  });
}

// @ts-ignore
export function getReportsResumeSalesPeriodRequest({ startDate, endDate, stores }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/period-sales/?${query}`, {
    method: 'POST',
    body: JSON.stringify({ stores }),
    ...options,
  });
}

// @ts-ignore
export function getReportsResumeSalesStoreRequest({ startDate, endDate, stores }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/store-sales/?${query}`, {
    method: 'POST',
    body: JSON.stringify({ stores }),
    ...options,
  });
}

export async function getReportsResumeTopPaymentRequest({ startDate, endDate, stores }: TopPaymentsRequest) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    start_date: startDate,
    end_date: endDate,
    store_uuid: storeUuid,
  });

  const response = await api.post<Response<DataResponseChart>>(`/v1/reports/top-payments/?${query}`, { stores });
  return response.data.data;
}
