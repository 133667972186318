import { useRef, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectMultiTaxes, selectMultiTaxesAtList, selectMultiTaxesState, selectUpdateDefaultState } from './selectors';
import { getMultiTaxes, updateDefaultTax } from './actions';

function useInvoiceCatalog() {
  const dispatch = useDispatch();

  const selectMultiTaxesRef = useRef();
  const selectMultiTaxesAtListRef = useRef();
  const selectMultiTaxesStateRef = useRef();
  const selectUpdateDefaultStateRef = useRef();

  selectMultiTaxesRef.current = useSelector(selectMultiTaxes);
  selectMultiTaxesStateRef.current = useSelector(selectMultiTaxesState);
  selectMultiTaxesAtListRef.current = useSelector(selectMultiTaxesAtList);
  selectUpdateDefaultStateRef.current = useSelector(selectUpdateDefaultState);

  const handleGetMultiTaxes = useCallback(() => {
    dispatch(getMultiTaxes());
  }, [dispatch]);

  const multiTaxes = selectMultiTaxesRef.current;
  const multiTaxesAtList = selectMultiTaxesAtListRef.current;
  const multiTaxesState = selectMultiTaxesStateRef.current;

  return {
    loadMultiTaxes: handleGetMultiTaxes,
    updateDefaultTax,
    multiTaxes,
    multiTaxesAtList,
    multiTaxesState,
  };
}

export default useInvoiceCatalog;
