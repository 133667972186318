import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_CATEGORIES,
  GET_PAGINATION_CATEGORY,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  DUPLICATE_CATEGORY,
} from './constants';

export const getCategories = extendRoutine(createRoutine(GET_CATEGORIES), 'RESET');
export const getPaginationCategory = createRoutine(GET_PAGINATION_CATEGORY);
export const createCategory = extendRoutine(createRoutine(CREATE_CATEGORY), 'RESET');
export const deleteCategory = extendRoutine(createRoutine(DELETE_CATEGORY), 'RESET');
export const duplicateCategory = extendRoutine(createRoutine(DUPLICATE_CATEGORY), 'RESET');
