import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}
const Reports = forwardRef(function Reports(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FFF', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <rect height="3.9" stroke={color} strokeWidth="1.5" width="4" x="2.75" y="17.3496" />
      <rect height="7" stroke={color} strokeWidth="1.5" width="4" x="9.94995" y="14.25" />
      <rect height="10" stroke={color} strokeWidth="1.5" width="4" x="17.25" y="11.25" />
      <path d="M4 13L20 4" stroke={color} strokeWidth="1.5" />
      <path d="M17 3L20.5 3.5L20 7" stroke={color} strokeWidth="1.5" />
    </svg>
  );
});

export default Reports;
