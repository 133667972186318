import PropTypes from 'prop-types';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import Box from '@material-ui/core/Box';

import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonNavigationBack from '@design-system/ButtonNavigationBack';

import useStyles from './styles';

function Step({ children, currentStep, disabled = true, labelAction, onAction, onGoBack, step, t }) {
  const { modeDraft } = useSidebarLayout();
  const isActive = currentStep === step;
  const classes = useStyles({ isActive, isModeDraft: modeDraft });

  if (currentStep < step) return null;

  return (
    <Box className={classes.drawerContainer} component="section" flexDirection="column">
      <Box className={classes.drawerHeader} component="header">
        <ButtonNavigationBack data-testid="btnGoBack" onClick={onGoBack}>
          {t('common:buttons.toReturn')}
        </ButtonNavigationBack>

        <ButtonPrimary data-testid="btnActionStepDrawer" disabled={disabled} onClick={onAction}>
          {labelAction}
        </ButtonPrimary>
      </Box>

      <Box className={classes.drawerContent}>{children}</Box>
    </Box>
  );
}

Step.propTypes = {
  children: PropTypes.node,
  currentStep: PropTypes.number,
  labelAction: PropTypes.string,
  onAction: PropTypes.func,
  onGoBack: PropTypes.func,
  step: PropTypes.number,
  t: PropTypes.func,
};

export default Step;
