// @ts-nocheck
import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import TextField from '@design-system/TextField';
import Check from '@experimental-components/IconsComponents/Check';
import Close from '@experimental-components/IconsComponents/Close';

import { useOverrideMenuProduct } from 'providers/menuMaker/MenuProducts/hooks';

import PriceFormatField from 'components/PriceFormatField';
import ModalConfirm from 'components/ModalConfirm';

import { removePriceSymbol, maxValueAllowed } from 'utils/currency';
import relativePercentageDiff from 'utils/products/priceVariation';

import { Button, useStyles } from './styles';

interface PropsInput {
  disabled: boolean;
  isResume?: boolean;
  menuUuid: string;
  menuName?: string;
  onSyncProductOverride?: (values: { price: string }) => void;
  placeholder?: string;
  productUuid: string;
  value: string;
  valueBase: string;
}

function InputOverridePrice(props: PropsInput) {
  const {
    disabled,
    isResume = true,
    menuUuid,
    menuName,
    productUuid,
    placeholder,
    value,
    valueBase,
    onSyncProductOverride,
  } = props;
  const overrideProductMutation = useOverrideMenuProduct('overridePrice');

  const classes = useStyles({ isResume });
  const { t } = useTranslation('menuMaker');
  const [focus, setFocus] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [overridePrice, setOverridePrice] = useState(value);
  const [previousPrice, setPreviousPrice] = useState(value ?? '');
  const [openPriceOverRange, setOpenPriceOverRange] = useState(false);

  useEffect(() => {
    setOverridePrice(value ?? '');
    setPreviousPrice(value ?? '');
  }, [value]);

  useEffect(() => {
    if (!focus && !isEditing) {
      setOverridePrice(previousPrice);
    }
  }, [focus]);

  useEffect(() => {
    if (overrideProductMutation.isSuccess) {
      if (onSyncProductOverride) onSyncProductOverride({ price: overridePrice });

      setPreviousPrice(overridePrice);
      setIsEditing(false);
      return;
    }

    if (overrideProductMutation.isError) {
      setIsEditing(true);
      return;
    }

    setIsEditing(false);
  }, [overrideProductMutation.isSuccess, overrideProductMutation.isError]);

  const handleConfirmProductPriceChange = () => {
    setOpenPriceOverRange(false);
    overrideProductMutation.mutate({ menuUuid, productUuid, price: removePriceSymbol(overridePrice) || null });
  };

  const handleCancelProductPriceChange = () => {
    setOpenPriceOverRange(false);
    setIsEditing(false);
  };

  const handleFocus = () => {
    overrideProductMutation.reset();
    setFocus(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setFocus(false);
    }, 300);
  };

  const handleChangePrice = ({ target }: { target: { value: string } }) => {
    setOverridePrice(target.value);
  };

  const handleSubmit = () => {
    setIsEditing(true);
    const previousValue =
      !isEmpty(previousPrice) || isNumber(previousPrice) ? removePriceSymbol(previousPrice) : valueBase;
    const updateValue = removePriceSymbol(overridePrice);
    if (previousValue !== updateValue) {
      const priceVariation = relativePercentageDiff(previousValue, updateValue);
      if (priceVariation > 30) {
        return setOpenPriceOverRange(true);
      }
      return overrideProductMutation.mutate({ menuUuid, productUuid, price: updateValue || null });
    }
  };

  const handlePressEnterKey = (codeKey: string) => {
    if (codeKey === 'Enter' || codeKey === 'NumpadEnter') {
      handleSubmit();
    }
  };

  const renderIcon = () => {
    if (overrideProductMutation.isError) {
      return <Close color="#DF130E" size={16} />;
    }

    return <Check color={overrideProductMutation.isSuccess ? '#27AE60' : 'white'} size={16} />;
  };

  return (
    <>
      <Box
        className={clsx(classes.root, {
          [classes.disalbed]: overrideProductMutation.isLoading,
          [classes.success]: overrideProductMutation.isSuccess,
          [classes.error]: overrideProductMutation.isError,
          [classes.hasOverride]:
            overridePrice !== null &&
            overridePrice !== undefined &&
            overridePrice !== '' &&
            !overrideProductMutation.isSuccess &&
            !overrideProductMutation.isError &&
            !overrideProductMutation.isLoading,
        })}
      >
        <PriceFormatField
          classes={{ input: classes.input }}
          customInput={TextField}
          data-testid={`inputOverridePrice-${menuUuid}-${productUuid}`}
          disabled={overrideProductMutation.isLoading || disabled}
          hideLabel
          hideLabelHelperText
          isAllowed={maxValueAllowed(9999999.99)}
          onBlur={handleBlur}
          onChange={handleChangePrice}
          onFocus={handleFocus}
          onKeyDown={({ code }: { code: string }) => handlePressEnterKey(code)}
          placeholder={placeholder}
          useNegative={false}
          value={overridePrice}
        />

        <Button
          data-testid={`btnOverridePrice-${menuUuid}-${productUuid}`}
          disabled={overrideProductMutation.isLoading || disabled}
          error={overrideProductMutation.isError}
          focus={focus}
          hasOverride={
            overridePrice !== null &&
            overridePrice !== undefined &&
            overridePrice !== '' &&
            !overrideProductMutation.isSuccess &&
            !overrideProductMutation.isError &&
            !overrideProductMutation.isLoading
          }
          isResume={isResume}
          onClick={handleSubmit}
          success={overrideProductMutation.isSuccess}
        >
          {overrideProductMutation.isLoading ? <CircularProgress color="inherit" size={16} /> : renderIcon()}
        </Button>
      </Box>
      {openPriceOverRange && (
        <ModalConfirm
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          message={t('menuMaker:dialogConfirmPriceVariation.helperText')}
          onAccept={handleConfirmProductPriceChange}
          onCancel={handleCancelProductPriceChange}
          open={openPriceOverRange}
          title={t('menuMaker:dialogConfirmPriceVariation.title', { currentMenuName: menuName })}
        />
      )}
    </>
  );
}

export default InputOverridePrice;
