import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectInvoicesGeneratedProviderDomain = (state) => state.invoicesGeneratedProvider || initialState;

const selectGetAllInvoicesGenerated = createSelector(
  selectInvoicesGeneratedProviderDomain,
  (subState) => subState.getInvoicesGenerated,
);

const selectPaginationInvoicesGeneratedState = createSelector(
  selectInvoicesGeneratedProviderDomain,
  (subState) => subState.paginationInvoicesGeneratedState,
);

const selectCancelInvoicingState = createSelector(
  selectInvoicesGeneratedProviderDomain,
  (subState) => subState.cancelInvoicing,
);

const selectDownloadInvoicesState = createSelector(
  selectInvoicesGeneratedProviderDomain,
  (subState) => subState.downloadInvoices,
);

const selectInvoicesGenerated = createSelector(
  selectInvoicesGeneratedProviderDomain,
  ({ invoicesGenerated }) => invoicesGenerated,
);

const selectInvoicesGeneratedPaginationInfo = createSelector(
  selectInvoicesGeneratedProviderDomain,
  ({ invoicesGenerated }) => invoicesGenerated.pagination,
);

export {
  selectInvoicesGeneratedProviderDomain,
  selectGetAllInvoicesGenerated,
  selectPaginationInvoicesGeneratedState,
  selectInvoicesGeneratedPaginationInfo,
  selectCancelInvoicingState,
  selectDownloadInvoicesState,
  selectInvoicesGenerated,
};
