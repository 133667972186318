import queryString from 'query-string';

import request from 'utils/request';
import { paginationConfigDefaults } from 'utils/reports';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getCashRegisterSessionsRequest(storeUuid, { startDate, endDate, page, status }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    state: status,
    page,
  });
  return request(`${API_BASE_URL}/v1/reports/cash-register-sessions/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getCashRegisterSessionsDetailsRequest(reportUuid, { storeUuid, page }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    page,
    page_size: paginationConfigDefaults.pageSize,
    include_details: true,
  });
  return request(`${API_BASE_URL}/v1/reports/cash-register-sessions/${reportUuid}?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function exportCashRegisterSessionsReportToExcelRequest(
  reportUuid,
  { storeUuid, showModifiedTickets },
  options = {},
) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    requires_calculated_with_edited_orders: showModifiedTickets,
  });

  return request(`${API_BASE_URL}/v1/reports/cash-register-sessions/${reportUuid}/export/?${query}`, {
    method: 'GET',

    config: {
      responseType: 'blob',
    },
    ...options,
  });
}

export function getCashRegisterSessionsDetailsAllRequest(reportUuid, { storeUuid }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/reports/cash-register-sessions/${reportUuid}/report-x/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getCashRegisterSessionsDetailedAllRequest({ cashRegisterSessionUuid, context }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    context,
  });
  return request(`${API_BASE_URL}/v1/reports/cashier-session/${cashRegisterSessionUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}
