import Box from '@material-ui/core/Box';

interface Props {
  isActive?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLElement, Element>) => void;
  onClick?: () => void;
  children?: React.ReactNode;
}

function MenuItem({ onClick, onBlur, isActive, children }: Props) {
  return (
    <Box
      bgcolor={isActive ? '#F7F7F9' : ''}
      component="li"
      onBlur={(e) => onBlur && onBlur(e)}
      onClick={onClick}
      onFocus={(e) => e.stopPropagation()}
      pb="8px"
      pl={2}
      pr={2}
      pt="8px"
      sx={{
        // @ts-ignore
        // TODO: update Material UI to latest version to support selector types
        '& span': {
          fontSize: 14,
        },
        '&:hover': {
          backgroundColor: '#F7F7F9',
          cursor: 'pointer',
        },
      }}
    >
      {children}
    </Box>
  );
}

export default MenuItem;
