import axios from 'axios';
import { getAuthToken } from 'utils/auth';

import { env } from './env';

const authTokens = getAuthToken();
const authorization = authTokens?.accessToken ? { authorization: `Bearer ${authTokens.accessToken}` } : undefined;
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Language': 'es-MX',
  ...authorization,
};

export const inventoriesApi = axios.create({
  baseURL: env.BASE_URL_API_INVENTORIES,
  headers,
});
