/**
 *
 * ParrotSoftware
 * PaymentsGatewayProvider reducer
 *
 */
import produce from 'immer';
import baseState from 'utils/baseState';

import { getPaymentsMethods, createPaymentMethod, resumePaymentMethod, updatePaymentMethodStatus } from './actions';

export const initialState = {
  paymentsMethods: {
    ...baseState,
    list: [],
  },

  createPaymentMethod: {
    ...baseState,
    data: {},
  },

  resumePaymentMethod: {
    ...baseState,
    data: {},
  },

  updatePaymentMethodStatus: {
    ...baseState,
    data: {},
  },
};

const paymentsGatewayReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getPaymentsMethods.REQUEST: {
        draft.paymentsMethods = {
          ...initialState.paymentsMethods,
          fetching: true,
        };
        break;
      }

      case getPaymentsMethods.SUCCESS: {
        draft.paymentsMethods.fetching = false;
        draft.paymentsMethods.fetched = true;
        draft.paymentsMethods.list = payload;
        break;
      }

      case getPaymentsMethods.FAILURE: {
        draft.paymentsMethods.error = payload;
        draft.paymentsMethods.fetched = false;
        draft.paymentsMethods.fetching = false;
        break;
      }

      case getPaymentsMethods.RESET: {
        draft.paymentsMethods = { ...initialState.paymentsMethods };
        break;
      }

      case createPaymentMethod.REQUEST: {
        draft.createPaymentMethod = {
          ...initialState.createPaymentMethod,
          fetching: true,
        };
        break;
      }

      case createPaymentMethod.SUCCESS: {
        draft.createPaymentMethod.fetching = false;
        draft.createPaymentMethod.fetched = true;
        draft.createPaymentMethod.data = payload;
        break;
      }

      case createPaymentMethod.FAILURE: {
        draft.createPaymentMethod.error = payload;
        draft.createPaymentMethod.fetched = false;
        draft.createPaymentMethod.fetching = false;
        break;
      }

      case createPaymentMethod.RESET: {
        draft.createPaymentMethod = { ...initialState.createPaymentMethod };
        break;
      }

      case resumePaymentMethod.REQUEST: {
        draft.resumePaymentMethod = {
          ...initialState.resumePaymentMethod,
          fetching: true,
        };
        break;
      }

      case resumePaymentMethod.SUCCESS: {
        draft.resumePaymentMethod.fetching = false;
        draft.resumePaymentMethod.fetched = true;
        draft.resumePaymentMethod.data = payload;
        break;
      }

      case resumePaymentMethod.FAILURE: {
        draft.resumePaymentMethod.error = payload;
        draft.resumePaymentMethod.fetched = false;
        draft.resumePaymentMethod.fetching = false;
        break;
      }

      case resumePaymentMethod.RESET: {
        draft.resumePaymentMethod = { ...initialState.resumePaymentMethod };
        break;
      }

      case updatePaymentMethodStatus.REQUEST: {
        draft.updatePaymentMethodStatus = {
          ...initialState.updatePaymentMethodStatus,
          fetching: true,
        };
        break;
      }

      case updatePaymentMethodStatus.SUCCESS: {
        draft.updatePaymentMethodStatus.fetching = false;
        draft.updatePaymentMethodStatus.fetched = true;
        draft.updatePaymentMethodStatus.data = payload;
        break;
      }

      case updatePaymentMethodStatus.FAILURE: {
        draft.updatePaymentMethodStatus.error = payload;
        draft.updatePaymentMethodStatus.fetched = false;
        draft.updatePaymentMethodStatus.fetching = false;
        break;
      }

      case updatePaymentMethodStatus.RESET: {
        draft.updatePaymentMethodStatus = { ...initialState.updatePaymentMethodStatus };
        break;
      }

      default:
        return draft;
    }
  });

export default paymentsGatewayReducer;
