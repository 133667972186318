// @ts-nocheck
import { useMutation, useQuery } from 'react-query';

import { createHeaders } from 'utils/baasRequest';
import {
  changeMenuProductPriceRequest,
  getOverrideProductRequest,
  restoreOverrideProductRequest,
} from 'utils/api/baas/menuMaker/products';

interface PropsOverrideMenuProduct {
  menuUuid: string;
  productUuid: string;
  price?: string | null;
  availability?: string;
  hasOverridePrice?: boolean;
}

interface PropsRestore {
  menuUuid: string;
  productUuid: string;
}

export function useOverrideMenuProduct(mutationKey: string) {
  const headers = createHeaders();
  delete headers['Content-Type'];

  return useMutation(
    (values: PropsOverrideMenuProduct) =>
      changeMenuProductPriceRequest({ ...values, hasOverridePrice: values?.hasOverridePrice ?? true }, { headers }),
    {
      mutationKey,
    },
  );
}

export function useGetOverrideProductDetail(productUuid: string, menuUuid: string) {
  return useQuery(
    ['getOverrideProduct', productUuid, menuUuid],
    () => getOverrideProductRequest({ productUuid, menuUuid }, { headers: createHeaders() }),
    { enabled: false },
  );
}

export function useRestoreOverrideProduct(mutationKey: string) {
  return useMutation((values: PropsRestore) => restoreOverrideProductRequest(values, { headers: createHeaders() }), {
    mutationKey,
  });
}
