import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import Box from '@material-ui/core/Box';

import ExportFileBar from 'components/ExportFileBar';

import { dateToString } from 'utils/reports/users/transformers';
import { getReportFileNameByRangeDate as getReportFileName } from 'utils/getReportFileName';

import { useMovementsCash } from './hooks';
import useDownloadMovementsCash from './useDownload';
import { FiltersProps } from './types';
import LayoutContainer from './components/LayoutContainer';
import Filter from './components/Filter';
import { movementsCashFilterAtom, hasBeenSetQueryParamsAtom } from './recoil/atoms';
import List from './components/List';

function ReportsMovementsCash() {
  const { t } = useTranslation('reports');
  const history = useHistory();
  const {
    movementsCashState,
    movementsCash,
    refetchMovementsCash,
    hasNextPage,
    fetchNextPage,
    resetMovementsCash,
    removeMovementsCashQuery,
  } = useMovementsCash();

  const { downloadExcel, downloadExcelState } = useDownloadMovementsCash();

  const [filtersValue, setFiltersValue] = useRecoilState<FiltersProps>(movementsCashFilterAtom);
  const hasBeenSetQueryParams = useRecoilValue(hasBeenSetQueryParamsAtom);
  const resetHasBeenSetQueryParams = useResetRecoilState(hasBeenSetQueryParamsAtom);

  function handleGoBack() {
    history.push({
      pathname: '/reports',
    });
  }

  function handleOnChangeFilter(values: FiltersProps) {
    setFiltersValue(values);
  }

  useEffect(() => {
    if (hasBeenSetQueryParams) {
      removeMovementsCashQuery();
      refetchMovementsCash();
    }
  }, [filtersValue, hasBeenSetQueryParams]);

  useEffect(() => {
    return () => {
      resetMovementsCash();
      resetHasBeenSetQueryParams();
    };
  }, []);

  return (
    <LayoutContainer onGoBack={handleGoBack} title={t('navigation.movementsCash')}>
      <Box mx={-4}>
        <ExportFileBar
          isLoading={downloadExcelState.isFetching}
          isLoadingByModal={false}
          mutateAsync={downloadExcel.mutateAsync}
          name="movements-cash-report"
          nameFile={getReportFileName(
            t('reports:movementsCash.fileName'),
            dateToString(filtersValue?.dates?.[0]),
            dateToString(filtersValue?.dates?.[1]),
          )}
          showDownloadButton={movementsCash?.rows?.length > 0}
        >
          {hasBeenSetQueryParams && <Filter defaultFilters={filtersValue} onChange={handleOnChangeFilter} />}
          {!hasBeenSetQueryParams && <Box />}
        </ExportFileBar>
      </Box>

      <Box mx={-4}>
        <List
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          headers={movementsCash?.headers || []}
          isFetched={movementsCashState.isFetched}
          isLoading={movementsCashState.isFetching && !movementsCashState.isFetchingNextPage}
          isLoadingNextPage={movementsCashState.isFetchingNextPage}
          rows={movementsCash?.rows || []}
        />
      </Box>
    </LayoutContainer>
  );
}

export default ReportsMovementsCash;
