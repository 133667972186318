import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  lighter?: boolean;
  size?: string | number;
}
const ChevronDown = forwardRef(function ChevronDown(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#fff', size = 16, lighter } = props;

  if (lighter) {
    return (
      <svg
        ref={ref}
        fill="none"
        height={size}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          clipRule="evenodd"
          d="M2 7.33333L3.35987 6.05846L12 15.2746L20.6401 6.05846L22 7.33333L12 18L2 7.33333Z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M14.7071 4.29289C14.3166 3.90237 13.6834 3.90237 13.2929 4.29289L8 9.58579L2.70711 4.29289C2.31658 3.90237 1.68342 3.90237 1.29289 4.29289C0.902368 4.68342 0.902368 5.31658 1.29289 5.70711L7.29289 11.7071C7.68342 12.0976 8.31658 12.0976 8.70711 11.7071L14.7071 5.70711C15.0976 5.31658 15.0976 4.68342 14.7071 4.29289Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default ChevronDown;
