import React from 'react';
import PropTypes from 'prop-types';

function SidebarLayoutContent({ children }) {
  return <>{children}</>;
}

SidebarLayoutContent.propTypes = {
  children: PropTypes.node,
};

export default SidebarLayoutContent;
