import produce from 'immer';

import baseState from 'utils/baseState';

import {
  getReportsResumeALL,
  getReportsResumeSummaries,
  getReportsResumeSalesChannels,
  getReportsResumeTopProducts,
  getReportsResumeSalesBrands,
  getReportsResumeSalesCategories,
  getReportsResumeSalesPeriod,
  getReportsResumeSalesStores,
} from './actions';

export const initialState = {
  getReportsResumeSummaries: {
    ...baseState,
    summaries: {},
  },
  getReportsResumeSalesChannels: {
    ...baseState,
    salesChannels: {},
  },
  getReportsResumeTopProducts: {
    ...baseState,
    topProducts: {},
  },
  getReportsResumeSalesBrands: {
    ...baseState,
    salesBrands: {},
  },
  getReportsResumeSalesCategories: {
    ...baseState,
    salesCategories: {},
  },
  getReportsResumeSalesPeriod: {
    ...baseState,
    salesPeriod: {},
  },
  getReportsResumeSalesStores: {
    ...baseState,
    salesStores: {},
  },
};

const reportsResumeReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getReportsResumeALL.RESET:
        return initialState;

      case getReportsResumeSummaries.REQUEST:
        draft.getReportsResumeSummaries = {
          ...initialState.getReportsResumeSummaries,
          fetching: true,
        };
        break;

      case getReportsResumeSummaries.SUCCESS:
        draft.getReportsResumeSummaries.fetching = false;
        draft.getReportsResumeSummaries.fetched = true;
        draft.getReportsResumeSummaries.summaries = payload;
        break;

      case getReportsResumeSummaries.FAILURE:
        draft.getReportsResumeSummaries.error = payload;
        draft.getReportsResumeSummaries.fetching = false;
        draft.getReportsResumeSummaries.fetched = false;
        break;

      case getReportsResumeSummaries.RESET:
        draft.getReportsResumeSummaries = {
          ...initialState.getReportsResumeSummaries,
        };
        break;

      case getReportsResumeSalesChannels.REQUEST:
        draft.getReportsResumeSalesChannels = {
          ...initialState.getReportsResumeSalesChannels,
          fetching: true,
        };
        break;

      case getReportsResumeSalesChannels.SUCCESS:
        draft.getReportsResumeSalesChannels.fetching = false;
        draft.getReportsResumeSalesChannels.fetched = true;
        draft.getReportsResumeSalesChannels.salesChannels = payload;
        break;

      case getReportsResumeSalesChannels.FAILURE:
        draft.getReportsResumeSalesChannels.error = payload;
        draft.getReportsResumeSalesChannels.fetching = false;
        draft.getReportsResumeSalesChannels.fetched = false;
        break;

      case getReportsResumeSalesChannels.RESET:
        draft.getReportsResumeSalesChannels = {
          ...initialState.getReportsResumeSalesChannels,
        };
        break;

      case getReportsResumeTopProducts.REQUEST:
        draft.getReportsResumeTopProducts = {
          ...initialState.getReportsResumeTopProducts,
          fetching: true,
        };
        break;

      case getReportsResumeTopProducts.SUCCESS:
        draft.getReportsResumeTopProducts.fetching = false;
        draft.getReportsResumeTopProducts.fetched = true;
        draft.getReportsResumeTopProducts.topProducts = payload;
        break;

      case getReportsResumeTopProducts.FAILURE:
        draft.getReportsResumeTopProducts.error = payload;
        draft.getReportsResumeTopProducts.fetching = false;
        draft.getReportsResumeTopProducts.fetched = false;
        break;

      case getReportsResumeTopProducts.RESET:
        draft.getReportsResumeTopProducts = {
          ...initialState.getReportsResumeTopProducts,
        };
        break;

      case getReportsResumeSalesBrands.REQUEST:
        draft.getReportsResumeSalesBrands = {
          ...initialState.getReportsResumeSalesBrands,
          fetching: true,
        };
        break;

      case getReportsResumeSalesBrands.SUCCESS:
        draft.getReportsResumeSalesBrands.fetching = false;
        draft.getReportsResumeSalesBrands.fetched = true;
        draft.getReportsResumeSalesBrands.salesBrands = payload;
        break;

      case getReportsResumeSalesBrands.FAILURE:
        draft.getReportsResumeSalesBrands.error = payload;
        draft.getReportsResumeSalesBrands.fetching = false;
        draft.getReportsResumeSalesBrands.fetched = false;
        break;

      case getReportsResumeSalesBrands.RESET:
        draft.getReportsResumeSalesBrands = {
          ...initialState.getReportsResumeSalesBrands,
        };
        break;

      case getReportsResumeSalesCategories.REQUEST:
        draft.getReportsResumeSalesCategories = {
          ...initialState.getReportsResumeSalesCategories,
          fetching: true,
        };
        break;

      case getReportsResumeSalesCategories.SUCCESS:
        draft.getReportsResumeSalesCategories.fetching = false;
        draft.getReportsResumeSalesCategories.fetched = true;
        draft.getReportsResumeSalesCategories.salesCategories = payload;
        break;

      case getReportsResumeSalesCategories.FAILURE:
        draft.getReportsResumeSalesCategories.error = payload;
        draft.getReportsResumeSalesCategories.fetching = false;
        draft.getReportsResumeSalesCategories.fetched = false;
        break;

      case getReportsResumeSalesCategories.RESET:
        draft.getReportsResumeSalesCategories = {
          ...initialState.getReportsResumeSalesCategories,
        };
        break;

      case getReportsResumeSalesPeriod.REQUEST:
        draft.getReportsResumeSalesPeriod = {
          ...initialState.getReportsResumeSalesPeriod,
          fetching: true,
        };
        break;

      case getReportsResumeSalesPeriod.SUCCESS:
        draft.getReportsResumeSalesPeriod.fetching = false;
        draft.getReportsResumeSalesPeriod.fetched = true;
        draft.getReportsResumeSalesPeriod.salesPeriod = payload;
        break;

      case getReportsResumeSalesPeriod.FAILURE:
        draft.getReportsResumeSalesPeriod.error = payload;
        draft.getReportsResumeSalesPeriod.fetching = false;
        draft.getReportsResumeSalesPeriod.fetched = false;
        break;

      case getReportsResumeSalesPeriod.RESET:
        draft.getReportsResumeSalesPeriod = {
          ...initialState.getReportsResumeSalesPeriod,
        };
        break;

      case getReportsResumeSalesStores.REQUEST:
        draft.getReportsResumeSalesStores = {
          ...initialState.getReportsResumeSalesStores,
          fetching: true,
        };
        break;

      case getReportsResumeSalesStores.SUCCESS:
        draft.getReportsResumeSalesStores.fetching = false;
        draft.getReportsResumeSalesStores.fetched = true;
        draft.getReportsResumeSalesStores.salesStores = payload;
        break;

      case getReportsResumeSalesStores.FAILURE:
        draft.getReportsResumeSalesStores.error = payload;
        draft.getReportsResumeSalesStores.fetching = false;
        draft.getReportsResumeSalesStores.fetched = false;
        break;

      case getReportsResumeSalesStores.RESET:
        draft.getReportsResumeSalesStores = {
          ...initialState.getReportsResumeSalesStores,
        };
        break;

      default:
        return draft;
    }
  });

export default reportsResumeReducer;
