import { styled, makeStyles } from '@material-ui/core/styles';

export const NavigationStyled = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.palette.darkGrey[67],
  paddingBlock: '1rem',
  paddingRight: '2rem',
  paddingLeft: '1.25rem',
  alignItems: 'center',
  width: '100%',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    alignItems: 'center',
    gap: theme.spacing(3),
    paddingInline: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 48%)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  tab: {
    maxWidth: theme.spacing(34),
  },
  customRow: {
    fontWeight: 400,
  },
  rolColumn: {
    textAlign: 'center',
    minWidth: 300,
  },
  rolCell: {
    minWidth: 300,
    fontWeight: 400,
    textAlign: 'center',
  },
  buttonSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    gridColumn: '5',
    '& > button': {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: '1/4',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1/3',
    },
    [theme.breakpoints.down('xs')]: {
      gridColumn: '1',
    },
  },
  optionalGridButton: {
    gridColumn: '1/5 !important',
  },
  divider: {
    width: '100%',
    marginTop: '-24px',
  },
}));

export default useStyles;
