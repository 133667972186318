import produce from 'immer';

import baseState from 'utils/baseState';

import {
  getListReportsOrders,
  exportReportsOrders,
  getListReportsOrdersScrollPagination,
  getDetailsReportsOrders,
} from './actions';

export const initialState = {
  getListReportsOrders: {
    ...baseState,
    summaries: [],
    headers: [],
    orders: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  paginationReportsOrders: {
    ...baseState,
    lastUpdatedRows: [],
  },

  exportReportsOrders: {
    ...baseState,
    document: null,
  },

  detailsReportsOrders: {
    ...baseState,
    order: {},
  },
};

const invoicingProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getListReportsOrders.REQUEST:
        draft.getListReportsOrders = {
          ...initialState.getListReportsOrders,
          fetching: true,
        };
        break;

      case getListReportsOrders.SUCCESS:
        draft.getListReportsOrders.fetching = false;
        draft.getListReportsOrders.fetched = true;
        draft.getListReportsOrders.summaries = payload.summaries;
        draft.getListReportsOrders.headers = payload.headers;
        draft.getListReportsOrders.orders = payload.rows;
        draft.getListReportsOrders.pagination = {
          ...initialState.getListReportsOrders.pagination,
          ...payload.pagination,
        };
        break;

      case getListReportsOrders.FAILURE:
        draft.getListReportsOrders.error = payload;
        draft.getListReportsOrders.fetching = false;
        draft.getListReportsOrders.fetched = false;
        break;

      case getListReportsOrders.RESET:
        draft.getListReportsOrders = {
          ...initialState.getListReportsOrders,
        };
        break;

      case getDetailsReportsOrders.REQUEST:
        draft.detailsReportsOrders = {
          ...initialState.detailsReportsOrders,
          fetching: true,
        };
        break;

      case getDetailsReportsOrders.SUCCESS:
        draft.detailsReportsOrders.fetching = false;
        draft.detailsReportsOrders.fetched = true;
        draft.detailsReportsOrders.order = payload;

        break;

      case getDetailsReportsOrders.FAILURE:
        draft.detailsReportsOrders.error = payload;
        draft.detailsReportsOrders.fetching = false;
        draft.detailsReportsOrders.fetched = false;
        break;

      case getDetailsReportsOrders.RESET:
        draft.detailsReportsOrders = {
          ...initialState.detailsReportsOrders,
        };
        break;

      case getListReportsOrdersScrollPagination.REQUEST:
        draft.paginationReportsOrders = {
          ...initialState.paginationReportsOrders,
          fetching: true,
        };
        break;

      case getListReportsOrdersScrollPagination.SUCCESS: {
        const currentOrdersState = state.getListReportsOrders.orders;

        let newsRows = [...currentOrdersState];
        newsRows = [...newsRows, ...payload.rows];

        draft.paginationReportsOrders.fetching = false;
        draft.paginationReportsOrders.fetched = true;
        draft.paginationReportsOrders.lastUpdatedRows = payload.data;

        draft.getListReportsOrders.orders = [...newsRows];
        draft.getListReportsOrders.pagination = {
          ...initialState.getListReportsOrders.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getListReportsOrdersScrollPagination.FAILURE:
        draft.paginationReportsOrders.error = payload;
        draft.paginationReportsOrders.fetching = false;
        draft.paginationReportsOrders.fetched = false;
        break;

      case getListReportsOrdersScrollPagination.RESET:
        draft.paginationReportsOrders = {
          ...initialState.paginationReportsOrders,
        };
        break;

      case exportReportsOrders.REQUEST:
        draft.exportReportsOrders = {
          ...initialState.exportReportsOrders,
          fetching: true,
        };
        break;

      case exportReportsOrders.SUCCESS:
        draft.exportReportsOrders.fetching = false;
        draft.exportReportsOrders.fetched = true;
        draft.exportReportsOrders.document = payload;

        break;

      case exportReportsOrders.FAILURE:
        draft.exportReportsOrders.error = payload;
        draft.exportReportsOrders.fetching = false;
        draft.exportReportsOrders.fetched = false;
        break;

      case exportReportsOrders.RESET:
        draft.exportReportsOrders = {
          ...initialState.exportReportsOrders,
        };
        break;

      default:
        return draft;
    }
  });

export default invoicingProviderReducer;
