import { useEffect, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import ProductsTable from 'components/ProductsTable';

import DialogConfirmDelete from './components/DialogConfirmDelete';
import { useGetProductoAssociations, useDeleteProduct } from './hooks/useDeleteProduct';
import { REMOVE_ACTIONS } from './removeActionTypes';

function ProductsTableWrapper({
  categoriesByUuid,
  isLoading,
  isSearchFilter,
  loadingEnabledProduct,
  loadingUpdateProductImage,
  onClearNotification,
  onUpdateProductImage,
  products,
  toggleProductStock,
  onPaginate,
  paginateValues,
  paginateState,
  openDrawer,
  onDuplicateProduct,
  isCheckAll,
  setIsCheckAll,
  setIsIndeterminate,
  productSelectedRow,
  setProductSelectedRow,
  excludedProductsOrdersLocal,
  setExcludedProductsOrdersLocal,
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const scrollPaginationRef = useRef(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const {
    productAssociationsState,
    resetLoadProductAssociaions,
    loadProductAssociations,
    productAssociations,
  } = useGetProductoAssociations();

  const { deleteProductState, deleteProduct, resetDeleteProduct } = useDeleteProduct();

  function handleDeleteProduct(selected) {
    const productFound = products.find((p) => p.uuid === selected);
    deleteProduct({ uuid: productFound.uuid, recipeAction: REMOVE_ACTIONS.NO_ACTION });
  }

  function handleOnConfirmDelete(action) {
    deleteProduct({ uuid: selectedProduct.uuid, recipeAction: action });
  }

  function handleOnLoadProductAssociations(productSelected) {
    setSelectedProduct(productSelected);
    loadProductAssociations(productSelected.sku, productSelected.soldAlone);
  }

  function handleOnCancel() {
    setShowConfirm(false);
    resetLoadProductAssociaions();
    resetDeleteProduct();
    setSelectedProduct(null);
  }

  useEffect(() => {
    if (productAssociationsState.isFetched && productAssociations.hasAssociation) {
      setShowConfirm(true);
    }
  }, [productAssociations.hasAssociation, productAssociationsState.isFetched]);

  useEffect(() => {
    if (
      showConfirm &&
      productAssociations.hasAssociation &&
      (deleteProductState.isError || deleteProductState.isSuccess)
    ) {
      handleOnCancel();
    }
  }, [showConfirm, productAssociations.hasAssociation, deleteProductState.isError, deleteProductState.isSuccess]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && paginateValues?.next) {
          onPaginate();
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return function cleanup() {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPaginate, paginateValues?.next, scrollPaginationRef]);

  return (
    <>
      <Box>
        <ProductsTable
          categoriesByUuid={categoriesByUuid}
          deleteProduct={handleDeleteProduct}
          deleteProductError={deleteProductState.isError}
          deleteProductFetched={deleteProductState.isSuccess}
          deleteProductLoading={deleteProductState.isFetching || productAssociationsState.fetching}
          excludedProductsOrdersLocal={excludedProductsOrdersLocal}
          hasAssociation={productAssociations.hasAssociation}
          isCheckAll={isCheckAll}
          isLoading={isLoading}
          isLoadingScrollPagination={paginateState.fetching}
          isSearchFilter={isSearchFilter}
          loadingEnabledProduct={loadingEnabledProduct}
          loadingUpdateProductImage={loadingUpdateProductImage}
          loadProductAssociations={loadProductAssociations}
          onClearNotification={onClearNotification}
          onDuplicateProduct={onDuplicateProduct}
          onLoadProductAssociations={handleOnLoadProductAssociations}
          onUpdateProductImage={onUpdateProductImage}
          openDrawer={openDrawer}
          productAssociationsState={productAssociationsState}
          products={products}
          productSelectedRow={productSelectedRow}
          resetDeleteProduct={resetDeleteProduct}
          resetLoadProductAssociaions={resetLoadProductAssociaions}
          setExcludedProductsOrdersLocal={setExcludedProductsOrdersLocal}
          setIsCheckAll={setIsCheckAll}
          setIsIndeterminate={setIsIndeterminate}
          setProductSelectedRow={setProductSelectedRow}
          toggleProductStock={toggleProductStock}
        />

        {!isLoading && <Box ref={scrollPaginationRef} mb={5} name="actionTableScroll" />}
      </Box>
      <DialogConfirmDelete
        associations={productAssociations?.associations || []}
        isLoading={deleteProductState.isFetching}
        onCancel={handleOnCancel}
        onConfirmDelete={handleOnConfirmDelete}
        open={showConfirm}
      />
    </>
  );
}

ProductsTableWrapper.propTypes = {
  categoriesByUuid: PropTypes.object,
  isLoading: PropTypes.bool,
  isSearchFilter: PropTypes.bool,
  loadingEnabledProduct: PropTypes.bool,
  loadingUpdateProductImage: PropTypes.bool,
  onClearNotification: PropTypes.func,
  onUpdateProductImage: PropTypes.func,
  products: PropTypes.array,
  toggleProductStock: PropTypes.func,
  onPaginate: PropTypes.func,
  paginateValues: PropTypes.object,
  paginateState: PropTypes.object,
  openDrawer: PropTypes.bool,
  onDuplicateProduct: PropTypes.func,
};

export default memo(ProductsTableWrapper);
