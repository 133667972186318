import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { deleteModifierGroupRequest } from 'utils/api/baas/menuMaker/modifierGroups';

import { deleteModifierGroup } from '../actions';

export function* handleDeleteModifierGroupRequest(action) {
  const { storeUuid, modifierGroupUuid } = action.payload;
  try {
    yield put(deleteModifierGroup.request());
    const data = yield call(baasRequest, deleteModifierGroupRequest, {
      storeUuid,
      modifierGroupUuid,
    });
    yield put(deleteModifierGroup.success(data));
  } catch (err) {
    yield put(deleteModifierGroup.failure(err));
  }
}
