import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import { GET_VOID_REASONS, GET_VOID_REASONS_BY_PAGINATION, CREATE_VOID_REASON, DELETE_VOID_REASON } from './constants';

export const getVoidReasons = extendRoutine(createRoutine(GET_VOID_REASONS), ['SORTING', 'RESET_SORTING', 'RESET']);

export const getVoidReasonsByPagination = extendRoutine(createRoutine(GET_VOID_REASONS_BY_PAGINATION), ['RESET']);

export const createVoidReason = extendRoutine(createRoutine(CREATE_VOID_REASON), 'RESET');

export const deleteVoidReason = extendRoutine(createRoutine(DELETE_VOID_REASON), 'RESET');
