import { getQueryParams } from 'utils/history';

const CREATE = 'create';
const EDIT = 'edit';

export const drawerActionList = [CREATE, EDIT];
export const drawerActions = { CREATE, EDIT };

const MENU = 'menu';
const CATEGORY = 'category';
const PRODUCT = 'product';
const PRODUCT_OVERRIDE = 'product-override';
const MODIFIER_GROUP = 'modifier-group';

export const drawerEntityList = [MENU, CATEGORY, PRODUCT, PRODUCT_OVERRIDE, MODIFIER_GROUP];
export const drawerEntities = {
  MENU,
  CATEGORY,
  PRODUCT,
  PRODUCT_OVERRIDE,
  MODIFIER_GROUP,
};

export const getQueryParamsMenuMaker = (location) => {
  const query = getQueryParams(location.search);
  const action = query.get('action');
  const entity = query.get('entity');
  const uuid = query.get('uuid');
  const menuOfCategory = query.get('menuOfCategory');
  const menuOfProduct = query.get('menuOfProduct');
  const menuOfModifier = query.get('menuOfModifier');
  const categoryOfProduct = query.get('categoryOfProduct');
  const focusInCategories = query.get('focusInCategories');
  const focusInProducts = query.get('focusInProducts');
  const isSoldByWeight = query.get('isSoldByWeight');

  return {
    action,
    entity,
    uuid,
    menuOfCategory,
    menuOfProduct,
    menuOfModifier,
    focusInCategories,
    categoryOfProduct,
    focusInProducts,
    isSoldByWeight: isSoldByWeight === 'true',
  };
};
