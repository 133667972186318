import { memo, useEffect } from 'react';
import { compose } from 'redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import FeatureProvider from 'FeatureProvider';
import { useQueryParams } from 'hooks';
import useRemoteConfigFlags from 'hooks/useRemoteConfigFlags';

import { useActiveLicense } from 'containers/Subscription/hooks';
import { useUser } from 'containers/Users';

import { getListPermissions, getActivesModules, setAuthToken, setUserCurrentStore, setCookieUser } from 'utils/auth';

interface Params {
  token: string;
  storeUuid: string;
  userUuid: string;
}

interface Props {
  children: React.ReactNode;
}

export function AuthProvider({ children }: Props) {
  const { queryParams } = useQueryParams<Params>();
  const { data: license, isLoading: isLoadingLicense } = useActiveLicense();
  const { data: user, isLoading: isLoadingUser } = useUser();
  const { stateFlags, flags } = useRemoteConfigFlags();

  const { token, storeUuid, userUuid } = queryParams;

  useEffect(() => {
    if (token && storeUuid && userUuid) {
      setAuthToken({
        accessToken: token,
        refreshToken: '',
      });
      setUserCurrentStore({
        isDraft: false,
        uuid: storeUuid,
        name: '',
        secret: '',
        legacyId: '',
        jobs: [],
        organizationUuid: '',
      });
      setCookieUser({
        uuid: userUuid || '',
        email: '',
        firstName: '',
        lastName: '',
      });
      window.location.replace('/');
    }
  }, [token, storeUuid, userUuid]);

  if (isLoadingLicense || isLoadingUser || stateFlags.loading || !stateFlags.loaded) {
    return (
      <Backdrop open>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }

  const features =
    user && license ? [...getListPermissions(user.permissions), ...getActivesModules(license.modules)] : [];

  return <FeatureProvider features={{ permissions: features, flags }}>{children}</FeatureProvider>;
}

export { default as useAuth } from './useAuth';
export { default as withRequiredLicense } from './withRequiredLicense';
export default compose(memo)(AuthProvider);
