import { atom } from 'recoil';

import { rangesTypesValues, rangesTypes } from 'utils/dateRanges';

import { FiltersProps } from '../types';

export const initialState: FiltersProps = {
  startTime: '00:00',
  endTime: '23:59',
  dates: rangesTypesValues[rangesTypes.TODAY],
  storeUuid: undefined,
};

export const movementsCashFilterAtom = atom({
  key: 'ATOMS/MOVEMENTS_CASH_FILTERS',
  default: initialState as FiltersProps,
});

export const hasBeenSetQueryParamsAtom = atom({
  key: 'ATOMS/MOVEMENTS_CASH_FILTERS_HAS_BEEN_SET_QUERY_PARAMS',
  default: false,
});
