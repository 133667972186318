import DialogContentMui from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialogContentRoot: {
    paddingTop: 24,
    paddingBottom: 45,
    paddingRight: 40,
    paddingLeft: 40,

    [theme.breakpoints.down('xs')]: {
      padding: 16,
    },
  },
}));

function DialogContent({ ...others }) {
  const classes = useStyles();

  return <DialogContentMui classes={{ root: classes.dialogContentRoot }} {...others} />;
}

DialogContent.propTypes = {
  ...DialogContentMui.propTypes,
};

export default DialogContent;
