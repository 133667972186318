import { makeStyles } from '@material-ui/core/styles';

interface PropsStyles {
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  rowCheckbox: {
    alignItems: 'center',
    display: 'flex',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    width: '100%',
    '&:hover': {
      background: theme.palette.darkGrey[5],
      cursor: (props: PropsStyles) => (props.disabled ? 'default' : 'pointer'),
    },
  },
  textDisabledItem: {
    color: theme.palette.darkGrey[15],
  },
}));

export default useStyles;
