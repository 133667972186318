import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectEmailProviderDomain = (state) => state.emailProvider || initialState;

const selectValidateEmailFetching = createSelector(
  selectEmailProviderDomain,
  (subState) => subState.validateEmail.fetching,
);
const selectValidateEmailFetched = createSelector(
  selectEmailProviderDomain,
  (subState) => subState.validateEmail.fetched,
);
const selectValidateEmailError = createSelector(selectEmailProviderDomain, (subState) => subState.validateEmail.error);

export { selectEmailProviderDomain, selectValidateEmailFetching, selectValidateEmailFetched, selectValidateEmailError };
