export const GET_REPORTS_DISCOUNTS_RESUME = 'providers/reports/Discounts/GET_REPORTS_DISCOUNTS_RESUME';
export const GET_REPORTS_DISCOUNTS_RESUME_BY_PAGINATION =
  'providers/reports/Discounts/GET_REPORTS_DISCOUNTS_RESUME_BY_PAGINATION';
export const GET_REPORTS_DISCOUNTS_RESUME_BY_SORTING =
  'providers/reports/Discounts/GET_REPORTS_DISCOUNTS_RESUME_BY_SORTING';
export const GET_REPORTS_DISCOUNTS_DETAILS = 'providers/reports/Discounts/GET_REPORTS_DISCOUNTS_DETAILS';
export const GET_REPORTS_DISCOUNTS_DETAILS_BY_PAGINATION =
  'providers/reports/Discounts/GET_REPORTS_DISCOUNTS_DETAILS_BY_PAGINATION';
export const GET_REPORTS_DISCOUNTS_DETAILS_BY_SORTING =
  'providers/reports/Discounts/GET_REPORTS_DISCOUNTS_DETAILS_BY_SORTING';
export const EXPORT_REPORTS_DISCOUNTS = 'providers/reports/Discounts/EXPORT_REPORTS_DISCOUNTS';
