import React from 'react';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';

function PriceFormatField(props) {
  const { inputRef, useNegative, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowLeadingZeros
      allowNegative={useNegative}
      decimalScale={2}
      fixedDecimalScale
      getInputRef={inputRef}
      prefix="$"
    />
  );
}

PriceFormatField.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node]),
  useNegative: PropTypes.bool,
};

export default PriceFormatField;
