import { makeStyles } from '@material-ui/core/styles';
import { PropsLayout } from 'hooks/navigation/interfaces';

const useStyles = makeStyles((theme) => ({
  topNavigation: {
    position: 'fixed',
    top: 0,
    zIndex: 1250,
    [theme.breakpoints.up('sm')]: {
      width: ({ openMdSidebar }: PropsLayout) => `calc(100% - ${openMdSidebar ? 260 : 64}px)`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      top: '56px',
    },
  },
  containerWorkspace: {
    marginTop: ({ isModeDraft }: PropsLayout) => theme.typography.pxToRem(isModeDraft ? 112 : 56),
    width: ({ openMdSidebar }: PropsLayout) => `calc(100vw - ${openMdSidebar ? 260 : 64}px)`,
    height: ({ isModeDraft }: PropsLayout) => `calc(100vh - ${isModeDraft ? 112 : 56}px)`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginTop: ({ isModeDraft }: PropsLayout) => theme.typography.pxToRem(isModeDraft ? 168 : 112),
      width: () => '100%',
      height: ({ isModeDraft }: PropsLayout) => `calc(100vh - ${isModeDraft ? 168 : 112}px)`,
    },
  },
}));

export default useStyles;
