import produce from 'immer';

import { getEmployeeDetail, editEmployee, updatePassport } from './actions';

export const initialState = {
  getEmployeeDetail: {
    error: null,
    fetching: false,
    fetched: false,
    employee: {},
  },
  editEmployee: {
    error: null,
    fetching: false,
    fetched: false,
    employee: {},
  },

  updatePassport: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const employeesDetailsProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getEmployeeDetail.REQUEST:
        draft.getEmployeeDetail.fetching = true;
        draft.getEmployeeDetail.fetched = false;
        draft.getEmployeeDetail.error = false;
        break;

      case getEmployeeDetail.SUCCESS:
        draft.getEmployeeDetail.fetching = false;
        draft.getEmployeeDetail.fetched = true;
        draft.getEmployeeDetail.error = false;
        draft.getEmployeeDetail.employee = payload;
        break;

      case getEmployeeDetail.FAILURE:
        draft.getEmployeeDetail.error = true;
        draft.getEmployeeDetail.fetching = false;
        draft.getEmployeeDetail.fetched = false;
        break;

      case getEmployeeDetail.RESET:
        draft.getEmployeeDetail = { ...initialState.getEmployeeDetail };
        break;

      // edit employee
      case editEmployee.REQUEST:
        draft.editEmployee.fetching = true;
        draft.editEmployee.fetched = false;
        draft.editEmployee.error = false;
        break;

      case editEmployee.SUCCESS: {
        draft.editEmployee.fetching = false;
        draft.editEmployee.fetched = true;
        draft.editEmployee.employee = payload;
        break;
      }

      case editEmployee.FAILURE:
        draft.editEmployee.error = payload;
        draft.editEmployee.fetched = false;
        draft.editEmployee.fetching = false;
        break;

      case editEmployee.RESET:
        draft.editEmployee = { ...initialState.editEmployee };
        break;

      case updatePassport.REQUEST:
        draft.updatePassport.fetching = true;
        draft.updatePassport.fetched = false;
        draft.updatePassport.error = false;
        break;

      case updatePassport.SUCCESS: {
        draft.updatePassport.fetching = false;
        draft.updatePassport.fetched = true;
        break;
      }

      case updatePassport.FAILURE:
        draft.updatePassport.error = true;
        draft.updatePassport.fetched = false;
        draft.updatePassport.fetching = false;
        break;

      case updatePassport.RESET:
        draft.updatePassport = { ...initialState.updatePassport };
        break;

      default:
        return draft;
    }
  });

export default employeesDetailsProviderReducer;
