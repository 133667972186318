/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';

import IconButton from '@design-system/IconButton';
import Tooltip from '@design-system/Tooltip';
import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';
import ButtonPlus from '@experimental-components/ButtonPlus';
import CustomDropdown from '@experimental-components/CustomDropdown';

import { drawerActions, drawerEntities, getQueryParamsMenuMaker } from 'utils/drawerMenuMaker';
import { typesAddingProducts } from 'utils/products';

import RowProduct from './RowProduct';
import HelperSkeleton from './HelperSkeleton';
import useStyles from './styles';

function RowCategory({ openDefault = false, category, menu, hasProductOverrides, onLoadDetails, openDrawer }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');
  const [openRow, setOpenRow] = useState(openDefault);
  const history = useHistory();
  const location = useLocation();

  const { entity } = getQueryParamsMenuMaker(location);

  const hideOverrideColumn = (() => {
    const entityCheck = entity === drawerEntities.PRODUCT_OVERRIDE;
    return (openDrawer && entityCheck) ?? false;
  })();

  const handleLoadDetails = () => {
    setOpenRow((state) => !state);
    if (!category?.isLoaded) {
      onLoadDetails(menu?.uuid, category?.uuid);
    }
  };

  const handleEditCategory = (categoryUuid) => {
    const query = { action: drawerActions.EDIT, entity: drawerEntities.CATEGORY, uuid: categoryUuid };

    history.replace({ search: stringify(query) });
  };

  const handleOpenCreateProduct = (value) => {
    let query;

    if (value === typesAddingProducts.NEW) {
      query = {
        action: drawerActions.CREATE,
        entity: drawerEntities.PRODUCT,
        categoryOfProduct: category?.uuid,
        menuOfProduct: menu?.uuid,
      };
      history.replace({ search: stringify(query) });
    }

    if (value === typesAddingProducts.EXISTING) {
      query = {
        action: drawerActions.EDIT,
        entity: drawerEntities.CATEGORY,
        uuid: category?.uuid,
        focusInProducts: true,
      };
      history.replace({ search: stringify(query) });
    }
  };

  function renderContentCategory() {
    if (category?.isLoading) {
      return <HelperSkeleton type="contentCategory" />;
    }

    return category?.products?.map((product) => (
      <RowProduct
        key={`${menu?.uuid}-${product?.uuid}`}
        hasProductOverrides={hasProductOverrides}
        menu={menu}
        openDrawer={openDrawer}
        product={product}
        t={t}
      />
    ));
  }

  return (
    <Box>
      <Box className={clsx(classes.headerRow, classes.rowBorderBottom)}>
        <Link
          className={classes.labelCategoryRow}
          component="button"
          data-testid={category?.uuid}
          onClick={() => handleEditCategory(category?.uuid)}
          underline="none"
          variant="body2"
        >
          {category?.name}
        </Link>

        <Box className={classes.contentLeftRow}>
          <Box className={clsx(classes.labelHeaderColumns, classes.columnPrice)}>
            {t('menuMaker:contentResume.labels.price')}
          </Box>

          {hasProductOverrides && !hideOverrideColumn && (
            <Box className={clsx(classes.labelHeaderColumns, classes.columnPriceInMenu)}>
              {t('menuMaker:contentResume.labels.priceInMenu')}
              <Tooltip title={<Trans i18nKey="menuMaker:contentResume.labels.priceInMenuTooltip" />}>
                <Tooltip.Help size={34} />
              </Tooltip>
            </Box>
          )}

          {hasProductOverrides && !openDrawer && (
            <Box className={clsx(classes.labelHeaderColumns, classes.columnProduct)}>
              {t('menuMaker:contentResume.labels.includeInMenu')}
            </Box>
          )}

          <IconButton aria-label="collapse-category-row" onClick={() => handleLoadDetails()}>
            <ChevronDown
              className={clsx(classes.iconInitialState, { [classes.iconRotate]: openRow })}
              color="#000"
              lighter
              size={16}
            />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={openRow}>
        {renderContentCategory()}
        <Box px={4} py={3}>
          <CustomDropdown
            onChange={handleOpenCreateProduct}
            renderButton={(openOptions) => (
              <ButtonPlus data-testid={`btnAddProductOfCategory_${category?.uuid}`} onClick={openOptions}>
                {t('menuMaker:buttons.addProduct')}
              </ButtonPlus>
            )}
          >
            <CustomDropdown.Option value={typesAddingProducts.EXISTING}>
              {t('menuMaker:buttons.addExistingProduct')}
            </CustomDropdown.Option>

            <CustomDropdown.Option value={typesAddingProducts.NEW}>
              {t('menuMaker:buttons.addNewProduct')}
            </CustomDropdown.Option>
          </CustomDropdown>
        </Box>
      </Collapse>
    </Box>
  );
}
RowCategory.propTypes = {
  category: PropTypes.object,
  hasProductOverrides: PropTypes.bool,
  onLoadDetails: PropTypes.func,
  menu: PropTypes.object,
  openDefault: PropTypes.bool,
  openDrawer: PropTypes.bool,
};

export default RowCategory;
