import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

export function Pin({ children }) {
  useInjectSaga({ key: 'pin', saga });
  useInjectReducer({ key: 'pin', reducer });

  return React.Children.only(children);
}

Pin.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withPin } from './withPin';
export default compose(withConnect, memo)(Pin);
