import queryString from 'query-string';

import { api } from 'config';
import { getUserCurrentStore } from 'utils/auth';

import { cleanObject } from 'utils/objects';

import { ProductTypeFormProps, Response, ResponseCrud } from './types';

export async function getTypesOfProducts({ name }: { name?: string }) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    store_uuid: storeUuid,
    name,
  };

  const query = queryString.stringify(cleanObject(params));

  const response = await api.get<Response>(`/v1/menu-maker/product-types/?${query}`);
  return response.data;
}

export async function createTypeOfProducts({ name }: { name: string }) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    store: storeUuid,
    name,
  };

  const response = await api.post<ResponseCrud>('/v1/menu-maker/product-types/', {
    ...params,
  });
  return response.data;
}

export async function deleteTypeOfProducts({ uuid }: { uuid: string }) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    store_uuid: storeUuid,
  };
  const query = queryString.stringify(cleanObject(params));

  const response = await api.delete<ResponseCrud>(`/v1/menu-maker/product-types/${uuid}/?${query}`);
  return response.data;
}

export async function updateTypeOfProducts({ name, uuid }: ProductTypeFormProps) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    store_uuid: storeUuid,
    name,
  };
  const query = queryString.stringify(cleanObject(params));

  const response = await api.put<ResponseCrud>(`/v1/menu-maker/product-types/${uuid}/?${query}`);

  return response.data;
}
