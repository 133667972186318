/**
 *
 * ParrotSoftware
 * SettingsTablesConfigProvider actions
 *
 */
import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/extendRoutines';

import { UPDATE_TABLE_CONFIG, GET_STORE_TABLE_SETTINGS } from './constants';

export const updateTableConfig = extendRoutine(createRoutine(UPDATE_TABLE_CONFIG), 'RESET');
export const getStoreTableSettings = extendRoutine(createRoutine(GET_STORE_TABLE_SETTINGS), 'RESET');
