import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_MOVEMENT_REASONS,
  GET_MOVEMENT_REASONS_DETAIL,
  CREATE_MOVEMENT_REASONS,
  DELETE_MOVEMENT_REASONS,
  EDIT_MOVEMENT_REASONS,
} from './constants';

export const getMovementReasons = extendRoutine(createRoutine(GET_MOVEMENT_REASONS), [
  'SORTING',
  'RESET_SORTING',
  'PAGINATION',
  'RESET_PAGINATION',
  'RESET',
]);

export const getMovementReasonDetail = extendRoutine(createRoutine(GET_MOVEMENT_REASONS_DETAIL), 'RESET');
export const createMovementReason = extendRoutine(createRoutine(CREATE_MOVEMENT_REASONS), 'RESET');
export const deleteMovementReason = extendRoutine(createRoutine(DELETE_MOVEMENT_REASONS), 'RESET');
export const editMovementReason = extendRoutine(createRoutine(EDIT_MOVEMENT_REASONS), 'RESET');
