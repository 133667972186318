import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

export const CATEGORIES_PATH = '/menus/categories';
export const DEFAULT_SIZE_PAGE = 10;
export const DEFAULT_PAGE = 1;

const PROPERTY_NAME = 'name';

const DESC = 'desc';
const ASC = 'asc';

const ACTION_TYPE_DELETE = 'DELETE';
const ACTION_TYPE_DUPLICATE = 'DUPLICATE';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  NAME: PROPERTY_NAME,
};

export const actionTypes = {
  DELETE: ACTION_TYPE_DELETE,
  DUPLICATE: ACTION_TYPE_DUPLICATE,
};

const FILTER_PROPERTIES = [PROPERTY_NAME];

export function getFilteredCategoriesByText(categories, sorting) {
  return categories.filter(
    (category) =>
      FILTER_PROPERTIES.filter((property) =>
        category[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}

export function filterCategoriesByName(categoriesByUuid, searchValue) {
  const filteredCategoriesByUuid = {};
  Object.keys(categoriesByUuid).forEach((uuid) => {
    const category = categoriesByUuid[uuid];
    if (!searchValue || category.name.toLowerCase().includes(searchValue.toLowerCase())) {
      filteredCategoriesByUuid[category.uuid] = category;
    }
  });
  return filteredCategoriesByUuid;
}

export function getMenusOfFields(menusObject = {}) {
  const menusArray = [];
  Object.values(menusObject).forEach((menuUuid) => {
    if (menuUuid) menusArray.push({ uuid: menuUuid });
  });

  return menusArray;
}

export const defaultCategoryObject = {
  availability: 'AVAILABLE',
  legacy_id: null,
  name: null,
  sort_position: 0,
  store: null,
  menus: [],
};

export function setProductsSortBy(products) {
  return sortBy(products, ['sortingPosition', 'name']);
}

export function isMenuFieldWasSelected(menusField = {}) {
  const menusArray = Object.entries(menusField);
  return menusArray.some((menu) => typeof menu[1] !== 'boolean');
}

export function joinBrand(menuList, allMenus) {
  return menuList.map((menu) => {
    return {
      ...menu,
      brand: allMenus.find((m) => m.uuid === menu.uuid)?.brand || {},
    };
  });
}

export function getUuidProducts(products) {
  if (products) return products?.map((product) => product?.uuid);

  return [];
}

export const resetCategoryForm = (categoryFormTmp) => {
  return {
    ...categoryFormTmp,
    products: categoryFormTmp.products.map(({ uuid, name, sortingPosition, sku }) => ({
      uuid,
      name,
      sortingPosition,
      sku,
    })),
  };
};

export const categoryFormIsDirty = (initCategory, categoryForm) => {
  const initCategoryTmp = { ...initCategory };
  const categoryFormTmp = resetCategoryForm({ ...categoryForm });
  delete initCategoryTmp.menusField;
  delete categoryFormTmp.menusField;

  const isPartialDirty = !isEqual(initCategoryTmp, categoryFormTmp);

  const initMenusUuid = initCategory.menusField ? Object.keys(initCategory.menusField) : [];
  const currentMenusUuid = categoryForm.menusField && Object.values(categoryForm.menusField).filter((item) => !!item);

  const menusFieldIsDirty = !isEqual(initMenusUuid?.sort(), currentMenusUuid?.sort());

  return isPartialDirty || menusFieldIsDirty;
};

export const categoryFormIsValid = (formState, menusField) => {
  const menusFieldIsValid = menusField && Object.values(menusField)?.some((value) => !!value);

  return formState?.isValid && menusFieldIsValid;
};

export const validateProductsField = (productsField = [], currentProductsSelected) => {
  return currentProductsSelected && !isEqual(productsField, currentProductsSelected);
};

export const defaultCategory = {
  nameCategoryField: '',
  menusField: null,
  kitchenAreasFields: [],
  products: [],
  colorField: 'FFFFFF',
  skuFieldMultiStore: '',
};

export function getCategoryMenus(categoryMenus) {
  return categoryMenus?.reduce((menusField, menu) => ({ ...menusField, [menu?.uuid]: menu?.uuid }), {});
}

export const typesAddingCategories = { EXISTING: 'existing', NEW: 'new' };
