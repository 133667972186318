import IconOrganization from '@experimental-components/IconsComponents/TopNavBar/Organization';
import Store from '@experimental-components/IconsComponents/TopNavBar/Store';
import Ceal from '@experimental-components/IconsComponents/TopNavBar/Ceal';
import StoreDraft from '@experimental-components/IconsComponents/TopNavBar/StoreDraft';

import { NavigationType } from './types';

interface Props {
  type: NavigationType;
}

function Icon({ type }: Props) {
  if (type === NavigationType.ORGANIZATION) return <IconOrganization />;
  if (type === NavigationType.STORE) return <Store />;
  if (type === NavigationType.CEAL) return <Ceal />;
  if (type === NavigationType.STORE_DRAFT) return <StoreDraft />;
  return <></>;
}

export default Icon;
