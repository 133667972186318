import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ContentLayoutContext from './ContentLayoutContext';

function ContentLayoutProvider({ children, defaultValue = 0 }) {
  const [value, setValue] = useState(defaultValue);

  const contextDefault = {
    value,
    setValue,
  };

  return (
    <ContentLayoutContext.Provider value={contextDefault}>
      {React.Children.only(children)}
    </ContentLayoutContext.Provider>
  );
}

ContentLayoutProvider.propTypes = {
  children: PropTypes.element,
  defaultValue: PropTypes.number,
};

export { default as ContentLayoutContext } from './ContentLayoutContext';
export default ContentLayoutProvider;
