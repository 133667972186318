import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

export type DateOptions =
  | 'TODAY'
  | 'SINCE_YESTERDAY'
  | 'YESTERDAY'
  | 'LAST_WEEK'
  | 'PAST_WEEK'
  | 'MONTH'
  | 'LAST_MONTH'
  | 'YEAR';

export type DateRange<Type> = {
  [date in DateOptions]: Type;
};

export const rangesValues: DateRange<Dayjs[]> = {
  TODAY: [dayjs(), dayjs()],
  SINCE_YESTERDAY: [dayjs().subtract(1, 'days'), dayjs()],
  YESTERDAY: [dayjs().subtract(1, 'days'), dayjs()],
  LAST_WEEK: [dayjs().weekday(0), dayjs()],
  PAST_WEEK: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
  MONTH: [dayjs().startOf('month'), dayjs()],
  LAST_MONTH: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  YEAR: [dayjs().startOf('year'), dayjs()],
};

export const rangesLabels: DateRange<string> = {
  TODAY: `common:forms.rangesDateOptions.today`,
  SINCE_YESTERDAY: `common:forms.rangesDateOptions.sinceYesterday`,
  YESTERDAY: `common:forms.rangesDateOptions.yesterDay`,
  LAST_WEEK: `common:forms.rangesDateOptions.lastWeek`,
  PAST_WEEK: `common:forms.rangesDateOptions.pastWeek`,
  MONTH: `common:forms.rangesDateOptions.month`,
  LAST_MONTH: `common:forms.rangesDateOptions.lastMonth`,
  YEAR: `common:forms.rangesDateOptions.year`,
};

export const getRangesOptions = (fn: (dateRange: string) => string) => {
  return {
    [fn(rangesLabels.TODAY)]: rangesValues.TODAY,
    [fn(rangesLabels.YESTERDAY)]: rangesValues.YESTERDAY,
    [fn(rangesLabels.LAST_WEEK)]: rangesValues.LAST_WEEK,
    [fn(rangesLabels.PAST_WEEK)]: rangesValues.PAST_WEEK,
    [fn(rangesLabels.MONTH)]: rangesValues.MONTH,
    [fn(rangesLabels.LAST_MONTH)]: rangesValues.LAST_MONTH,
    [fn(rangesLabels.YEAR)]: rangesValues.YEAR,
  };
};

export const getCurrentWeek = () => {
  return [dayjs().startOf('week'), dayjs().endOf('week')];
};
