import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  lighter?: boolean;
  size?: string | number;
}

const Close = forwardRef(function Close(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#000000', size = 24, lighter } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {lighter ? (
        <path
          clipRule="evenodd"
          d="M10 11.0317L18.9683 20L20 18.9683L11.0317 10L20 1.03174L18.9683 0L10 8.96826L1.03174 0L0 1.03174L8.96826 10L0 18.9683L1.03174 20L10 11.0317Z"
          fill={color}
          fillRule="evenodd"
        />
      ) : (
        <path
          clipRule="evenodd"
          d="M23.5596 2.56649C24.1468 1.97937 24.1468 1.02746 23.5596 0.440341C22.9725 -0.14678 22.0206 -0.14678 21.4335 0.440341L12 9.87385L2.56649 0.440341C1.97937 -0.14678 1.02746 -0.14678 0.44034 0.440341C-0.14678 1.02746 -0.14678 1.97937 0.44034 2.56649L9.87384 12L0.44034 21.4335C-0.14678 22.0206 -0.14678 22.9725 0.44034 23.5597C1.02746 24.1468 1.97937 24.1468 2.56649 23.5597L12 14.1262L21.4335 23.5597C22.0206 24.1468 22.9725 24.1468 23.5596 23.5597C24.1468 22.9725 24.1468 22.0206 23.5596 21.4335L14.1261 12L23.5596 2.56649Z"
          fill={color}
          fillRule="evenodd"
        />
      )}
    </svg>
  );
});

export default Close;
