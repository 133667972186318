import { makeStyles, styled } from '@material-ui/core/styles';

interface PropsButton {
  disabled?: boolean;
  error?: boolean;
  focus?: boolean;
  hasOverride?: boolean;
  isResume?: boolean;
  success?: boolean;
  valid?: boolean;
}

interface propsStyles {
  isResume?: boolean;
}

export const Button = styled('button')({
  transition: '300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  color: 'white',
  borderRadius: '0 4px 4px 0',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  paddingInline: ({ isResume }: PropsButton) => (isResume ? '11.5px' : '19.5px'),
  appearance: 'none',
  flex: 1,
  margin: 0,
  borderLeft: '0 !important',
  background: ({ focus, disabled, success, error, hasOverride }: PropsButton) => {
    if (disabled) {
      return '#CECDD1';
    }

    if (success) {
      return '#DFF3E7';
    }

    if (error) {
      return '#FADCDB';
    }

    if (focus || hasOverride) {
      return '#47465f';
    }

    return '#A3A3AF';
  },
  border: ({ focus, disabled, success, error, hasOverride }: PropsButton) => {
    if (disabled) {
      return '1px solid #CECDD1';
    }

    if (success) {
      return '1px solid #27AE60';
    }

    if (error) {
      return '1px solid #DF130E';
    }

    if (focus || hasOverride) {
      return '1px solid #47465f';
    }

    return '1px solid #A3A3AF';
  },
  '&:disabled': {
    border: ({ hasOverride }) => `1px solid ${hasOverride ? '#47465f' : '#A3A3AF'}`,
  },
});

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '&:hover > button': {
      borderLeft: 0,
      border: '1px solid #47465f',
      background: '#47465f',
    },
    '&:hover input': {
      border: '1px solid #47465f',
      borderRight: 0,
    },
  },

  disalbed: {
    '&:hover > button': {
      border: '1px solid #CECDD1',
      borderLeft: 0,
      background: '#CECDD1',
    },
    '&:hover input': {
      border: '1px solid #CECDD1',
      borderRight: 0,
    },
    '& input': {
      border: '1px solid #CECDD1',
      borderRight: 0,
    },
  },

  success: {
    '&:hover > button': {
      border: '1px solid #27AE60',
      background: '#DFF3E7',
    },
    '&:hover input': {
      border: '1px solid #27AE60',
      borderRight: 0,
    },
    '& input': {
      border: '1px solid #27AE60',
      borderRight: 0,
    },
  },

  error: {
    '&:hover > button': {
      border: '1px solid #DF130E',
      background: '#FADCDB',
    },
    '&:hover input': {
      border: '1px solid #DF130E',
      borderRight: 0,
    },
    '& input': {
      border: '1px solid #DF130E',
      borderRight: 0,
    },
  },

  hasOverride: {
    '&:hover > button': {
      border: '1px solid #47465f',
      background: '#47465f',
    },
    '&:hover input': {
      border: '1px solid #47465f',
      borderRight: 0,
    },
    '& input': {
      border: '1px solid #47465f',
      borderRight: 0,
    },
  },

  input: {
    height: ({ isResume }: propsStyles) => (isResume ? '40px !important' : '56px'),
    padding: '0.75rem',
    borderRadius: '4px 0 0 4px',
    borderRight: 0,
    fontSize: '14px',
    '&:active': {
      borderRight: 0,
      border: '1px solid #47465f',
    },
    '&:focus': {
      borderRight: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      border: '1px solid #47465f',
    },
    '&:hover': {
      borderRight: 0,
      border: '1px solid #47465f',
    },
  },
}));
