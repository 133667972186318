/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableCellMui from '@material-ui/core/TableCell';
import { SortableHandle } from 'react-sortable-hoc';

import IconButton from '@design-system/IconButton';
import DragHandleVertical from '@experimental-components/IconsComponents/DragHandleVertical';

import ExpandableList from './ExpandableList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    borderBottom: 0,
    borderTop: ({ isBorder }) => (isBorder ? `1px solid ${theme.palette.darkGrey[5]}` : 0),
    color: ({ isColorError }) => (isColorError ? theme.palette.error.main : theme.palette.common.black),
  },

  wrapperBigCell: {
    display: 'flex',
    flexDirection: 'Column',
    gap: '10px',
    padding: '1px',
    width: '100%',
    overflowY: ({ scrollInCell }) => (scrollInCell ? 'auto' : 'initial'),
    maxHeight: ({ scrollInCell, maxHeightCell }) => (scrollInCell && maxHeightCell ? maxHeightCell : 'initial'),
  },

  cellOption: {
    lineHeight: '1',
  },
}));

const DragHandle = SortableHandle(() => (
  <IconButton aria-label="drag" size="small">
    <DragHandleVertical />
  </IconButton>
));

const CellOption = ({ children, ...others }) => {
  const classes = useStyles();
  return children ? (
    <span className={classes.cellOption} {...others}>
      {children}
    </span>
  ) : null;
};

function TableBigCell({
  isBorder = true,
  isColorError,
  isDraggable,
  children,
  scrollInCell = false,
  maxHeightCell = '200px',
  ...others
}) {
  const classes = useStyles({ isBorder, isColorError, scrollInCell, maxHeightCell });

  return (
    <TableCellMui classes={{ root: classes.root }} {...others}>
      <div className={classes.wrapperBigCell}>
        {isDraggable && <DragHandle />}
        {children}
      </div>
    </TableCellMui>
  );
}

TableBigCell.Option = CellOption;
TableBigCell.ExpandableList = ExpandableList;

TableBigCell.propTypes = {
  ...TableCellMui.propTypes,
  isBorder: PropTypes.bool,
};

CellOption.propTypes = {
  children: PropTypes.node,
};

export default TableBigCell;
