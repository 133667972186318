import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function createTableRequest({ serviceArea, name, capacity, tableType, description }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/area-tables/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      serviceArea,
      name,
      capacity,
      tableType,
      description,
    }),
    ...options,
  });
}

export function createTableBulkRequest({ serviceArea, prefix, totalTables, seatsPerTable }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/area-tables/bulk-create/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      serviceArea,
      prefix,
      totalTables,
      seatsPerTable,
    }),
    ...options,
  });
}

export function getTablesRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/area-tables/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getTableDetailsRequest(tableUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/area-tables/${tableUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateTableRequest(tableUuid, { serviceArea, name, capacity, tableType, description }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/area-tables/${tableUuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      serviceArea,
      name,
      capacity,
      tableType,
      description,
    }),
    ...options,
  });
}

export function deleteTableRequest(tableUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/area-tables/${tableUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function validateNameRequest({ name, serviceAreaUuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    name,
    service_area: serviceAreaUuid,
  });

  return request(`${API_BASE_URL}/v1/area-tables/check/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateTablePositionRequest({ tableUuid, beforePosition, newPosition, nextPosition }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/area-tables/${tableUuid}/sorting-position/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      beforePosition,
      newPosition,
      nextPosition,
    }),
    ...options,
  });
}
