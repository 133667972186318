import queryString from 'query-string';
import request from 'utils/request';
import { paginationConfigDefaults } from 'utils/reports';

const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getClientDataRequest({ storeUuid, brand, searchTerm, page, orderBy }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    brand,
    search_term: searchTerm || undefined,
    page,
    page_size: paginationConfigDefaults.pageSize,
    order_by: orderBy ?? undefined,
  });

  return request(`${API_BASE_URL}/v1/reports/online-ordering/all-eaters/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getClientDetailsRequest({ storeUuid, eaterUuid, page = 1 }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    page,
    page_size: paginationConfigDefaults.pageSize,
  });

  return request(`${API_BASE_URL}/v1/reports/online-ordering/eater/${eaterUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function exportClientsReportRequest({ storeUuid, brand, searchTerm, orderBy }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    brand,
    search_term: searchTerm || undefined,
    order_by: orderBy ?? undefined,
  });

  return request(`${API_BASE_URL}/v1/reports/online-ordering/all-eaters/export/?${query}`, {
    method: 'GET',
    config: {
      responseType: 'blob',
    },
    ...options,
  });
}
