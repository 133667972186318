import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    '& :hover': {
      cursor: 'pointer',
    },
  },

  photoContainer: {
    width: '100%',
    height: '100%',
    '& img': {
      maxWidth: '100%',
      height: '100%',
      borderRadius: theme.typography.pxToRem(4),
    },
  },
}));

export default useStyles;
