import { useTranslation } from 'react-i18next';

import { useMemo } from 'react';

import CatalogHeader from 'components/Inventories/CatalogHeader';
import CatalogTable from 'components/Inventories/CatalogTable';

import { getColumns } from 'utils/inventories/suppliers/data';

import { ViewPanelLayout } from 'Layout';

import ButtonPlus from '@experimental-components/ButtonPlus';

import { Box } from '@material-ui/core';

import { ProductType } from '../types';
import useStyles from '../styles';

interface onDeleteProps {
  uuid: string;
}
interface Props {
  isFetching: boolean;
  isSearching: boolean;
  onSearch: (newSearch: string) => void;
  onGoBack: () => void;
  onGoCreate: () => void;
  onGoUpdate: (value: string) => void;
  onDelete: (values: onDeleteProps) => void;
  productTypes: Array<ProductType>;
  isDeleting: boolean;
  isDeleted: boolean;
}

function ProductTypesList({
  isFetching,
  isSearching,
  onSearch,
  onGoBack,
  onGoCreate,
  onGoUpdate,
  productTypes,
  onDelete,
  isDeleting,
  isDeleted,
}: Props) {
  const { t } = useTranslation('settingsTypeOfProduct');
  const classes = useStyles();

  const tableRows = useMemo(() => {
    const handler = onGoUpdate;
    return productTypes.map((productType) => {
      return {
        uuid: productType.uuid,
        name: productType.name,
        handler,
        handlerNavigation: () => {},
        cells: { name: productType.name },
        isInternal: productType.isInternal,
      };
    });
  }, [productTypes]);

  return (
    <ViewPanelLayout
      actionComponent={
        <ButtonPlus disabled={isFetching} isDisabled={isFetching} onClick={onGoCreate}>
          {t('settingsTypeOfProduct:form.addTypeButton')}
        </ButtonPlus>
      }
      labelGoBack={t('common:buttons.toReturn')}
      onGoBack={onGoBack}
      pb={4}
      pl={0}
      pr={0}
    >
      <Box mb={5} px={4} width={582}>
        <Box className={classes.title}>{t('settingsTypeOfProduct:form.typeOfProductsTitleSection')}</Box>
        <Box className={classes.description}>{t('settingsTypeOfProduct:form.typeOfProductsDescriptionSection')}</Box>
      </Box>
      <CatalogHeader
        onChangeSearch={onSearch}
        searchPlaceHolder={t('settingsTypeOfProduct:form.searchTypePlaceholder')}
      />
      <CatalogTable
        columns={getColumns(t)}
        confirmDeleteMessage={t('inventoriesSuppliers:messages.dialogConfirmDeletedMsg')}
        fetchNextPage={() => {}}
        hasNextPage={false}
        isDeleted={isDeleted}
        isDeleting={isDeleting}
        isLoading={isFetching}
        isSortingSearch={isSearching}
        onDelete={onDelete}
        rows={tableRows}
        titleConfirmDelete={t('inventoriesSuppliers:messages.dialogConfirmDeletedTitle')}
      />
    </ViewPanelLayout>
  );
}

export default ProductTypesList;
