import { useState, createContext, ReactNode, Dispatch, SetStateAction } from 'react';

import { Filters } from './types';

interface FilterContextType {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
}
export const FiltersContext = createContext<FilterContextType | null>(null);

interface Props {
  children: ReactNode;
}

export function FiltersProvider({ children }: Props) {
  const initialFilters: Filters = { startDate: '', endDate: '' };

  const [filters, setFilters] = useState<Filters>(initialFilters);

  return <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>;
}
