import React from 'react';

import useStyles from './styles';

function SidebarLayoutSpacer() {
  const classes = useStyles();

  return <div className={classes.spacer} />;
}

export default SidebarLayoutSpacer;
