import { useContext } from 'react';

import { ViewPanelContext } from 'providers/root/ViewPanelProvider';

function useViewPanel() {
  const { open, setOpen } = useContext(ViewPanelContext);

  return { open, setOpen };
}

export default useViewPanel;
