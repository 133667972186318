/* eslint-disable no-nested-ternary */
import { useCallback, useRef } from 'react';

import amplitude from 'amplitude-js';

import { config as configInitial } from './events';
import isUndefined from './isUndefined';
import isString from './isString';
import isObject from './isObject';
import get from './get';

function useAmplitude() {
  const instance = amplitude.getInstance();
  const configInitialRef = useRef(configInitial);

  const getValues = (fieldNames) => {
    const values = configInitialRef.current;

    return isUndefined(fieldNames)
      ? values
      : isString(fieldNames)
      ? get(values, fieldNames)
      : fieldNames.map((name) => get(values, name));
  };

  const getData = (fieldNames) => {
    const values = configInitialRef.current;
    const rexKey = /[,[\].]+?/;

    if (isString(fieldNames)) {
      const key = fieldNames?.split(rexKey)[0]?.replace('@@', '');
      return {
        [key]: get(values, fieldNames),
      };
    }

    if (isObject(fieldNames)) {
      const dataObject = fieldNames.reduce((a, b) => {
        const key = b?.split(rexKey)[0]?.replace('@@', '');
        return { ...a, [key]: get(values, b) };
      }, {});

      return dataObject;
    }

    return {};
  };

  function handleUserProperty(properties) {
    instance.setUserProperties(properties);
  }

  function handleLogEvent(event, data, extras) {
    instance.logEvent(getValues(event), { ...getData(data), ...extras });
  }

  return {
    setUserProperties: useCallback(handleUserProperty, [instance]),
    logEvent: useCallback(handleLogEvent, [instance]),
    trackEvents: configInitialRef.current,
  };
}

export default useAmplitude;
