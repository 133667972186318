const IVA = 'IVA';
const IEPS = 'IEPS';
const noApply = 'noApply';

export const groupLabel = {
  [IVA]: 'settingsTaxes:labels.iva',
  [IEPS]: 'settingsTaxes:labels.special',
};

export const taxesTypes = {
  IVA,
  IEPS,
  noApply,
};
