/* eslint-disable import/no-named-default */
import Box from '@material-ui/core/Box';
import { default as ListUI } from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';

function SkuSkeleton() {
  return null;
}

function List() {
  return (
    <ListUI>
      {Array.from(Array(3).keys()).map((item) => (
        <ListItem key={`item-${item}`}>
          <Skeleton height={16} variant="rect" width="100%" />
        </ListItem>
      ))}
    </ListUI>
  );
}

function Transfer() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.inputSearch}>
        <Skeleton height={50} variant="rect" width="100%" />
      </Box>

      <Box className={classes.contentLists}>
        <Box className={classes.wrapperBox}>
          <Box className={classes.labelList}>
            <Skeleton height={16} variant="text" width={150} />
          </Box>

          <Skeleton className={classes.boxList} variant="rect"></Skeleton>
        </Box>

        <Box width="100%">
          <Box className={classes.labelList}>
            <Skeleton height={16} variant="text" width={150} />
          </Box>

          <Skeleton className={classes.boxItem} height={50} variant="rect"></Skeleton>
        </Box>
      </Box>
    </Box>
  );
}

SkuSkeleton.List = List;
SkuSkeleton.Transfer = Transfer;

export default SkuSkeleton;
