import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}
const ChevronBase = forwardRef(function ChevronBase(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#000', size = 14, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 14 14"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <g id="Chevron 14">
        <path
          clipRule="evenodd"
          d="M0.95285 4.2463L2.04716 3.22039L7 8.50342L11.9529 3.22039L13.0472 4.2463L7 10.6966L0.95285 4.2463Z"
          fill={color}
          fillRule="evenodd"
          id="Vector 46 (Stroke)"
        />
      </g>
    </svg>
  );
});

export default ChevronBase;
