export const menuProps = {
  PaperProps: {
    style: {
      marginTop: 4,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

const NAME = 'name';
const CREATED_AT = 'created_at';
const PRICE = 'price';

export const optionsOrderBy = [
  {
    label: 'menuMaker:headerForm.filterProducts.nameAsc',
    value: NAME,
    id: 'nameAsc',
  },
  {
    label: 'menuMaker:headerForm.filterProducts.nameDesc',
    value: `-${NAME}`,
    id: 'nameDesc',
  },
  {
    label: 'menuMaker:headerForm.filterProducts.createdAtDesc',
    value: `-${CREATED_AT}`,
    id: 'createdAtDesc',
  },
  {
    label: 'menuMaker:headerForm.filterProducts.createdAtAsc',
    value: CREATED_AT,
    id: 'createdAtAsc',
  },
  {
    label: 'menuMaker:headerForm.filterProducts.priceAsc',
    value: PRICE,
    id: 'priceAsc',
  },
  {
    label: 'menuMaker:headerForm.filterProducts.priceDesc',
    value: `-${PRICE}`,
    id: 'priceDesc',
  },
];
