import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import { GET_MENU_PRODUCTS, CHANGE_MENU_PRODUCT_PRICE } from './constants';

export const getMenusProducts = extendRoutine(createRoutine(GET_MENU_PRODUCTS), [
  'SORTING',
  'RESET_ALL_SORTING',
  'PAGINATION',
  'RESET_PAGINATION',
  'RESET',
  'PAGINATION_SOLD_ALONE',
  'RESET_ALL_PAGINATION',
]);
export const changeMenuProductPrice = extendRoutine(createRoutine(CHANGE_MENU_PRODUCT_PRICE), 'RESET');
