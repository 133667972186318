import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectSettingsStoreProviderDomain = (state) => state.settingsStore || initialState;

const selectSettingStoreData = createSelector(
  selectSettingsStoreProviderDomain,
  (subState) => subState.getSettingsStore.data,
);

const selectSettingStoreState = createSelector(
  selectSettingsStoreProviderDomain,
  ({ getSettingsStore: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateSettingStore = createSelector(
  selectSettingsStoreProviderDomain,
  (subState) => subState.updateSettingsStore.updateData,
);

const selectUpdateSettingStoreState = createSelector(
  selectSettingsStoreProviderDomain,
  ({ updateSettingsStore: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectSettingStoreCustomizePrintsData = createSelector(
  selectSettingsStoreProviderDomain,
  (subState) => subState.getSettingsCustomizePrints.customizePrintsList,
);

const selectSettingStoreCustomizePrintsState = createSelector(
  selectSettingsStoreProviderDomain,
  ({ getSettingsCustomizePrints: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

export {
  selectSettingsStoreProviderDomain,
  selectSettingStoreData,
  selectSettingStoreState,
  selectUpdateSettingStore,
  selectUpdateSettingStoreState,
  selectSettingStoreCustomizePrintsData,
  selectSettingStoreCustomizePrintsState,
};
