import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getSettingsStoreRequest,
  updateSettingsStoreRequest,
  getSettingsStoreCustomizePrintsRequest,
} from 'utils/api/baas/settings/store';

import { getSettingsStore, updateSettingsStore, getSettingsCustomizePrints } from './actions';

export function* handleGetSettingsStoreRequest() {
  try {
    yield put(getSettingsStore.request());

    const { data } = yield call(baasRequest, getSettingsStoreRequest);

    yield put(getSettingsStore.success(data));
  } catch (err) {
    yield put(getSettingsStore.failure(err));
  }
}

export function* handleUpdateSettingsStoreRequest(action) {
  const updateSettingsStoreData = action.payload;

  try {
    yield put(updateSettingsStore.request());

    const { data } = yield call(baasRequest, updateSettingsStoreRequest, updateSettingsStoreData);

    yield put(updateSettingsStore.success(data));
  } catch (err) {
    yield put(updateSettingsStore.failure(err));
  }
}

export function* handleGetSettingsStoreCustomizePrintsRequest(action) {
  const filters = action.payload;
  try {
    yield put(getSettingsCustomizePrints.request());

    const { data } = yield call(baasRequest, getSettingsStoreCustomizePrintsRequest, filters);

    yield put(getSettingsCustomizePrints.success(data));
  } catch (err) {
    yield put(getSettingsCustomizePrints.failure(err));
  }
}

export default function* reportsPaymentsSaga() {
  yield takeLatest(getSettingsStore.TRIGGER, handleGetSettingsStoreRequest);
  yield takeLatest(updateSettingsStore.TRIGGER, handleUpdateSettingsStoreRequest);
  yield takeLatest(getSettingsCustomizePrints.TRIGGER, handleGetSettingsStoreCustomizePrintsRequest);
}
