import { Box, Typography } from '@material-ui/core';

import CalendarIllustration from '@experimental-components/IconsComponents/CalendarIllustration';
import ButtonPrimary from '@design-system/ButtonPrimary';

import useStyles from './styles';

interface Props {
  t: (value: string) => string;
  onOpenDrawer: () => void;
}

const InitSetup = ({ t, onOpenDrawer }: Props) => {
  const classes = useStyles();

  const handleOpenDrawer = () => {
    onOpenDrawer();
  };

  return (
    <Box className={classes.rootInitSetup}>
      <Box className={classes.containerInitSetup}>
        <CalendarIllustration />

        <Box className={classes.messageInitSetup}>
          <Typography className={classes.title}>{t('salesPerTimeReport.labels.setYourSchedules')}</Typography>
          <Typography className={classes.descriptionInitSetup}>{t('salesPerTimeReport.labels.initMessage')}</Typography>
        </Box>

        <ButtonPrimary onClick={handleOpenDrawer}>{t('salesPerTimeReport.buttons.start')}</ButtonPrimary>
      </Box>
    </Box>
  );
};

export default InitSetup;
