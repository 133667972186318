import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import Layout, { ViewPanelLayout } from 'Layout';

import ButtonPrimary from '@design-system/ButtonPrimary';

import TitleSection from 'components/TitleSection';

import { settingsPathRoot } from 'utils/settings/paths';

import Form from './components/Form';
import FormSkeleton from './components/FormSkeleton';
import { useSettingTimeClock } from './hooks';

interface FormProps {
  isEnabledAuthPin: string | boolean;
}

export function SettingsTimeClock() {
  const history = useHistory();
  const { t } = useTranslation('settingsTips');
  const { settings, settingsState, updateSettingState, updateSettings, resetSettings } = useSettingTimeClock();
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      isEnabledAuthPin: settings.isEnabledAuthPin ? 'true' : 'false',
    },
  });

  function handleOnGoBack() {
    history.push({
      pathname: settingsPathRoot,
    });
  }

  function handleSubmit(value: FormProps) {
    updateSettings({
      ...value,
      isEnabledAuthPin: value.isEnabledAuthPin === 'true',
    });
  }

  useEffect(() => {
    const value = settings.isEnabledAuthPin ? 'true' : 'false';
    formMethods.reset({ isEnabledAuthPin: value });
  }, [settingsState.fetched, settingsState.fetching]);

  useEffect(() => {
    if (!updateSettingState.error && updateSettingState.fetched) {
      history.push({
        pathname: settingsPathRoot,
      });
    }
  }, [updateSettingState.fetched, updateSettingState.error]);

  useEffect(() => {
    return () => {
      resetSettings();
    };
  }, []);

  return (
    <Layout>
      <ViewPanelLayout
        actionComponent={
          <ButtonPrimary
            disabled={updateSettingState.fetching || !formMethods.formState.isDirty}
            loading={updateSettingState.fetching}
            onClick={formMethods.handleSubmit(handleSubmit)}
            type="submit"
          >
            {t('common:buttons.save')}
          </ButtonPrimary>
        }
        labelGoBack={t('common:buttons.toReturn')}
        onGoBack={handleOnGoBack}
        pb={4}
        pl={0}
        pr={0}
      >
        <TitleSection description="" title="Reloj checador" />

        {settingsState.fetching && <FormSkeleton />}

        {!settingsState.fetching && (
          <FormProvider {...formMethods}>
            <Form />
          </FormProvider>
        )}
      </ViewPanelLayout>
    </Layout>
  );
}

export default SettingsTimeClock;
