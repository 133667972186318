import { put, call, take, cancel, fork, takeLatest } from 'redux-saga/effects';
import baasRequest from 'utils/baasRequest';
import { invoicingGeneratedRequest, invoicingGeneratedPaginationRequest } from 'utils/invoicing/invoicing';

import { cancelInvoicing, downloadInvoices, getReportsGenerated, getReportsGeneratedPagination } from './actions';

import { handleCancelInvoicingGeneratedRequest } from './sagas/cancelInvoicingGenerated';
import { handleDownloadInvoicesRequest } from './sagas/downloadInvoices';

export function* handleGetReportsRequest(action) {
  const { type, filters } = action.payload;

  try {
    yield put(getReportsGenerated.request());

    const { data, pagination } = yield call(baasRequest, invoicingGeneratedRequest, type, { ...filters });

    yield put(getReportsGenerated.success({ data, pagination }));
  } catch (err) {
    yield put(getReportsGenerated.failure(err));
  }
}

export function* handleGetReportsPaginationRequest(action) {
  const { type, filters } = action.payload;

  try {
    yield put(getReportsGeneratedPagination.request());

    const { data, pagination } = yield call(baasRequest, invoicingGeneratedPaginationRequest, type, { ...filters });
    const paginationCurrent = { ...pagination, page: filters?.page };
    yield put(getReportsGeneratedPagination.success({ data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getReportsGeneratedPagination.failure(err));
  }
}

export function* takeReports() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsGenerated.TRIGGER);
    const { type } = action.payload;

    if (tasks[type]) {
      yield cancel(tasks[type]);
    }

    tasks[type] = yield fork(handleGetReportsRequest, action);
  }
}

export function* takeReportsPagination() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsGeneratedPagination.TRIGGER);
    const { type } = action.payload;

    if (tasks[type]) {
      yield cancel(tasks[type]);
    }

    tasks[type] = yield fork(handleGetReportsPaginationRequest, action);
  }
}

export function* takeReportsDownload() {
  const tasks = {};

  while (true) {
    const action = yield take(downloadInvoices.TRIGGER);
    const { type } = action.payload;

    if (tasks[type]) {
      yield cancel(tasks[type]);
    }

    tasks[type] = yield fork(handleDownloadInvoicesRequest, action);
  }
}

export default function* invoicesGeneratedProviderRootSga() {
  yield takeLatest(cancelInvoicing.TRIGGER, handleCancelInvoicingGeneratedRequest);
  yield fork(takeReports);
  yield fork(takeReportsPagination);
  yield fork(takeReportsDownload);
}
