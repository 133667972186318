import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popupIndicatorOpen: {
    transform: 'rotate(180deg)',
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    height: 160,
  },
  menuColumn: {
    display: 'inherit',
    flexDirection: 'column',
    width: '50%',
    alignItems: 'center',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.3)',
    },
    '& > div': {
      padding: 4,
      textAlign: 'center',
      width: '100%',
      '& > button': {
        '&:hover': {
          background: theme.palette.darkGrey[2],
        },
        padding: 12,
        width: '100%',
      },
    },
  },
  active: {
    background: theme.palette.darkGrey[2],
  },
  inputSelect: {
    '& input, div': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
