import queryString from 'query-string';

import { getUserCurrentStore } from 'utils/auth';
import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getKitchenTicketDetailsRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/kitchen-ticket-config/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateKitchenTicketRequest(
  {
    topMargin,
    bottomMargin,
    articleMargin,
    orderNumberFontType,
    orderTypeFontType,
    articleCounterFontType,
    modifierCounterFontType,
    notesFontType,
    orderBrandFontType,
    displayModifierGroupsTitle,
    displayMultipleKitchenArea,
  },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/kitchen-ticket-config/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      topMargin,
      bottomMargin,
      articleMargin,
      orderNumberFontType,
      orderTypeFontType,
      articleCounterFontType,
      modifierCounterFontType,
      notesFontType,
      orderBrandFontType,
      displayModifierGroupsTitle,
      displayMultipleKitchenArea,
    }),
    ...options,
  });
}
