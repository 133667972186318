import PropTypes from 'prop-types';

import DidiFood from '@experimental-components/IconsComponents/DidiFood';
import UberEats from '@experimental-components/IconsComponents/UberEats';
import ParrotConnect from '@experimental-components/IconsComponents/ParrotConnect';
import Rappi from '@experimental-components/IconsComponents/Rappi';
import MenuOrdering from '@experimental-components/IconsComponents/MenuOrdering';

import { providersTypes } from 'utils/reportsFilters';

const iconComponents = {
  [providersTypes.PARROT]: ParrotConnect,
  [providersTypes.UBER_EATS]: UberEats,
  [providersTypes.DIDI_FOOD]: DidiFood,
  [providersTypes.RAPPI]: Rappi,
  [providersTypes.ONLINE]: MenuOrdering,
};

function ChanelItem({ value, size = 32, idRef }) {
  const IconComponent = iconComponents[value] ?? ParrotConnect;

  return <IconComponent idRef={idRef} size={size} />;
}

ChanelItem.propTypes = {
  value: PropTypes.string,
  size: PropTypes.number,
  idRef: PropTypes.string,
};

export default ChanelItem;
