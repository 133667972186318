/* eslint-disable no-unused-vars */
import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;
export const API_MOCK_SERVER = `${process.env.REACT_APP_BASE_MOCK_SERVER_URL_API}/api`; // TEMP REMOVE
export const API_LOCAL = 'http://localhost:3000/api';

export function getOnlineOrderingRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateEnableOrderingRequest({ uuid, isEnabled }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuid}/toggle-enable/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      brand: uuid,
      canOrdering: isEnabled,
    }),
    ...options,
  });
}

export function getBrandSettingsRequest(uuidBrand, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuidBrand}/configs/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateBrandSettingsRequest({ uuidBrand, name, slug, description, logo, header }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuidBrand}/configs/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      slug,
      description,
      logo,
      header,
      name,
    }),
    ...options,
  });
}

export function getBrandContactInformationRequest(uuidBrand, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuidBrand}/contacts/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateContactInformationRequest(
  {
    uuid,
    email,
    phone,
    website,
    street,
    externalNumber,
    internalNumber,
    zipCode,
    state,
    city,
    neighborhood,
    latitude,
    longitude,
  },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuid}/contacts/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      uuid,
      email,
      phone,
      website,
      street,
      externalNumber,
      internalNumber,
      zipCode,
      state,
      city,
      neighborhood,
      latitude,
      longitude,
    }),
    ...options,
  });
}

export function getOperationsSettingsRequest(uuidBrand, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuidBrand}/deliveries/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateOperationsSettingsRequest(
  { uuidBrand, canDelivery, canPickup, deliveryCharge, deliveryTime, pickupCharge, pickupTime },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuidBrand}/deliveries/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      canDelivery,
      canPickup,
      deliveryCharge,
      deliveryTime,
      pickupCharge,
      pickupTime,
    }),
    ...options,
  });
}

export function getDeliveryZonesRequest(uuidBrand, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuidBrand}/zones/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateDeliveryZonesRequest({ uuidBrand, uuid, name, distanceAllowed, deliveryCharge }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuidBrand}/zones/${uuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      name,
      distanceAllowed,
      deliveryCharge,
    }),
    ...options,
  });
}

export function createDeliveryZonesRequest({ uuidBrand, name, distanceAllowed, deliveryCharge }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuidBrand}/zones/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      name,
      distanceAllowed,
      deliveryCharge,
    }),
    ...options,
  });
}

export function deleteDeliveryZonesRequest({ uuidBrand, uuid }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/online-ordering/brands/${uuidBrand}/zones/${uuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function validateDirectionUrlRequest(urlSlug, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    slug: urlSlug,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/check-slug/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getResumesMenusRequest(menusUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    menu_uuid: menusUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/menus/summaries/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function saveOnboardingRequest(brand, onboardingConfig, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${brand}/save-onboarding/?${query}`, {
    method: 'POST',
    body: JSON.stringify(onboardingConfig),
    ...options,
  });
}

export function publishBrandRequest(isPublished, brandUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/online-ordering/brands/${brandUuid}/publish/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({ isPublished }),
    ...options,
  });
}
