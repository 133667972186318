import { useState, useEffect } from 'react';
import { ContentLayout } from 'Layout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useIsFetching } from 'react-query';

import {
  useDownloadReportModifiers,
  useGetModifiersByProduct,
  useGetModifiersOptionInfinity,
} from 'containers/ReportModifiers/hooks';

import ContentTabsReports from 'components/ContentTabsReports';
import ExportToExcelButton from 'components/ExportToExcelButton';

import { getReportFileName } from 'utils/getReportFileName';

type ParamType = { type: string };

const Navigation = () => {
  const [excelButtonState, setExcelButtonState] = useState({
    reportType: 'PRODUCT_MODIFIER_SALES',
    disabledButton: false,
  });

  const { t } = useTranslation('reports');
  const downloadMutation = useDownloadReportModifiers();
  const { data: productModifiersData } = useGetModifiersByProduct();
  const { data: optionModifiersData } = useGetModifiersOptionInfinity();
  const { type } = useParams<ParamType>();
  const isFetchingProducts = useIsFetching({ queryKey: ['modifiersByProduct'] });
  const isFetchingOptions = useIsFetching({ queryKey: ['modifiersOptionsInfinity'] });
  const isFetching = isFetchingProducts || isFetchingOptions;

  useEffect(() => {
    if (!isFetching) {
      const newState = (() => {
        switch (type) {
          case 'modifiersByProduct':
            return {
              reportType: 'PRODUCT_MODIFIER_SALES',
              disabledButton: !productModifiersData?.pages[0]?.data?.length,
            };
          case 'modifiersByOption':
            return { reportType: 'MODIFIER_SALES', disabledButton: !optionModifiersData?.pages[0]?.data?.length };
          default:
            return {
              reportType: 'PRODUCT_MODIFIER_SALES',
              disabledButton: !productModifiersData?.pages[0]?.data?.length,
            };
        }
      })();

      setExcelButtonState(newState);
    }
  }, [isFetching]);

  return (
    <ContentTabsReports pt={3}>
      <ContentTabsReports.Options>
        <ContentLayout.TabsNavigation>
          <ContentLayout.TabLink
            label={t('modifiersReport.modifiersByProduct')}
            to="/reports/modifiers/modifiersByProduct"
            value={0}
          />
          <ContentLayout.TabLink
            label={t('modifiersReport.modifiersByOption')}
            to="/reports/modifiers/modifiersByOption"
            value={1}
          />
        </ContentLayout.TabsNavigation>
      </ContentTabsReports.Options>
      <ContentTabsReports.ButtonExport>
        <ExportToExcelButton
          disabled={excelButtonState.disabledButton || downloadMutation.isLoading || Boolean(isFetching)}
          fileName={getReportFileName({ name: 'Grupos modificadores' })}
          isLoading={downloadMutation.isLoading}
          isVisible
          mutateAsync={() => downloadMutation.mutateAsync(excelButtonState.reportType)}
          name="modifiers-report"
        />
      </ContentTabsReports.ButtonExport>
    </ContentTabsReports>
  );
};

export default Navigation;
