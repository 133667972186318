import { getUserCurrentStore } from 'utils/auth';
import { request2 as request } from 'utils/request';
import { createHeaders } from 'utils/baasRequest';

import { SchedulesResponse, SchedulesType } from 'components/ReportSalesPerTime/types';

const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getSalesSchedules(): Promise<SchedulesResponse> {
  const storeUuid = getUserCurrentStore()?.uuid;

  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/hours`, {
    method: 'GET',
    headers: createHeaders(),
  });
}

export function saveSalesSchedules(data: SchedulesType[]) {
  const storeUuid = getUserCurrentStore()?.uuid;

  data.forEach((item) => {
    if (!item.uuid) {
      delete item?.uuid;
    }
  });

  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/hours`, {
    method: 'PUT',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
}
