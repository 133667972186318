import produce from 'immer';

import { getMenuDetails, editMenu } from './actions';

export const initialState = {
  menuDetails: {
    error: null,
    fetching: false,
    fetched: false,
    menu: null,
  },

  editMenu: {
    error: null,
    fetching: false,
    fetched: false,
    menu: null,
  },
};

const menuDetailsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getMenuDetails.REQUEST:
        draft.menuDetails.fetching = true;
        draft.menuDetails.error = false;
        draft.menuDetails.fetched = false;
        break;

      case getMenuDetails.SUCCESS:
        draft.menuDetails.fetching = false;
        draft.menuDetails.fetched = true;
        draft.menuDetails.menu = payload;
        break;

      case getMenuDetails.FAILURE:
        draft.menuDetails.error = payload;
        draft.menuDetails.fetching = false;
        draft.menuDetails.fetched = false;
        break;

      case getMenuDetails.RESET:
        draft.menuDetails = { ...initialState.menuDetails };
        break;

      case editMenu.REQUEST:
        draft.editMenu.fetching = true;
        draft.editMenu.error = false;
        draft.editMenu.fetched = false;
        break;

      case editMenu.SUCCESS:
        draft.editMenu.fetching = false;
        draft.editMenu.fetched = true;
        draft.editMenu.menu = payload;
        break;

      case editMenu.FAILURE:
        draft.editMenu.error = payload;
        draft.editMenu.fetching = false;
        draft.editMenu.fetched = false;
        break;

      case editMenu.RESET:
        draft.editMenu = { ...initialState.editMenu };
        break;

      default:
        return draft;
    }
  });

export default menuDetailsReducer;
