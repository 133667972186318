import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useInfiniteQuery } from 'react-query';

import { dateToString } from 'utils/reports/users/transformers';

import { getMovementsCash } from './services';

import { movementsCashFilterAtom } from './recoil/atoms';
import { RowsProps } from './types';

const QUERY_KEY = 'reportMovementsCash';

interface DataPageProps {
  headers: unknown[];
  rows: unknown[];
}

interface RowPage {
  data: DataPageProps;
}

function extractRow(pages: RowPage[]) {
  if (pages?.length === 0) return [];
  const rows = pages.map((page) => page.data.rows);
  return rows.reduce((prev, current) => {
    return [...prev, ...current];
  });
}

export function useMovementsCash() {
  const filtersValue = useRecoilValue(movementsCashFilterAtom);
  const resetFilters = useResetRecoilState(movementsCashFilterAtom);

  const {
    refetch,
    data,
    isError,
    isFetched,
    isFetching,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    remove,
  } = useInfiniteQuery(
    QUERY_KEY,
    ({ pageParam = 1 }) =>
      getMovementsCash({
        startDate: dateToString(filtersValue.dates?.[0]),
        endDate: dateToString(filtersValue.dates?.[1]),
        startTime: filtersValue.startTime,
        endTime: filtersValue.endTime,
        page: pageParam,
        pageSize: 50,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.pagination?.next) {
          return null;
        }

        return pages.length + 1;
      },
      enabled: false,
    },
  );

  function reset() {
    resetFilters();
    remove();
  }

  return {
    movementsCash: {
      headers: data?.pages[0]?.data.headers,
      rows: extractRow(data?.pages || []) as RowsProps[],
    },
    movementsCashState: {
      isError,
      isFetching,
      isFetchingNextPage,
      isFetched,
      isLoading,
    },
    fetchNextPage,
    hasNextPage,
    refetchMovementsCash: refetch,
    resetMovementsCash: reset,
    removeMovementsCashQuery: remove,
  };
}
