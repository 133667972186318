import { taxesTypes } from 'utils/settingsTaxes/taxesTypes';

export const getDefaultTaxes = (list = []) => {
  if (list.length === 0) return [];
  return list.filter((item) => item.isDefault);
};

const setDefault = (items, prop = 'value', setInValue = 16) => {
  return items.map((item) => {
    if (item[prop] === setInValue) {
      return { ...item, isDefault: true };
    }
    return item;
  });
};

const setNewDefault = (items = [], uuidToDefault) =>
  items.map((item) => {
    if (uuidToDefault === item.uuid) {
      return { ...item, isDefault: true };
    }
    return { ...item, isDefault: false };
  });

export const setDefaultTaxes = (groupTaxes = []) => {
  if (groupTaxes.length === 0) return [];

  return groupTaxes.map((taxGroup) => {
    if (taxGroup.name === taxesTypes.IVA) {
      const defaultTaxes = taxGroup.items.filter((item) => item.isDefault);
      if (defaultTaxes.length === 0) {
        return {
          ...taxGroup,
          items: setDefault(taxGroup.items),
        };
      }
      return taxGroup;
    }
    const defaultTaxesSpecial = taxGroup.items.filter((item) => item.isDefault);
    if (defaultTaxesSpecial.length === 0) {
      return {
        ...taxGroup,
        items: setDefault(taxGroup.items, 'type', taxesTypes.noApply),
      };
    }
    return taxGroup;
  });
};

export const getGroupName = (taxes = []) => {
  if (taxes.length === 0) return [];
  return taxes.map((item) => item.name);
};

export const updateDefault = (uuidTax, groupTaxes = []) => {
  if (!uuidTax || groupTaxes.length === 0) return [];
  return groupTaxes.map((groupTax) => {
    if (!groupTax?.items || groupTax?.items?.length === 0) return groupTax;

    const isOnGroup = groupTax.items.some((tax) => tax.uuid === uuidTax);
    if (isOnGroup) {
      return {
        ...groupTax,
        items: setNewDefault(groupTax.items, uuidTax),
      };
    }
    return groupTax;
  });
};

export const setSymbolValue = (value, type) => {
  if (type === 'PERCENTAGE') return `${value} %`;
  return `$ ${value}`;
};

export const checkIfIeps = (taxUuid, taxesGroup = []) => {
  if (taxesGroup.length === 0) return false;
  if (taxUuid === taxesTypes.noApply) return false;
  const taxesList = taxesGroup.find((tax) => tax.name === taxesTypes.IEPS)?.items;
  if (!taxUuid && taxesList.length === 0) return false;
  const ieps = getTaxValue(taxUuid, taxesList, 'uuid');
  if (!ieps) return false;
  return true;
};

export const getTaxValue = (value, taxesList, prop = 'value') => {
  return taxesList.find((tax) => tax[prop] === value);
};
