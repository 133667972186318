export const DEFAULT_SIZE_PAGE = 5;
export const DEFAULT_PAGE = 1;

const PROPERTY_NAME = 'name';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  TITLE: PROPERTY_NAME,
};

const FILTER_PROPERTIES = [PROPERTY_NAME];

export function getFilteredPaymentsByText(payments, sorting) {
  return payments?.filter(
    (payment) =>
      FILTER_PROPERTIES.filter((property) =>
        payment[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}

const PAYMENT_TYPE_CASH = 'CASH';
const PAYMENT_TYPE_CREDIT_CARD = 'CREDIT_CARD';
const PAYMENT_TYPE_DEBIT_CARD = 'DEBIT_CARD';
const PAYMENT_TYPE_THIRD_PARTY = 'THIRD_PARTY';
const PAYMENT_TYPE_FOREIGN_CURRENCY = 'FOREIGN_CURRENCY';

export const PAYMENT_TYPES = {
  PAYMENT_TYPE_CASH,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_TYPE_DEBIT_CARD,
  PAYMENT_TYPE_THIRD_PARTY,
  PAYMENT_TYPE_FOREIGN_CURRENCY,
};

export const paymentTypes = [
  { label: 'restaurant:payments.formCreatePayment.paymentsTypesLabels.cash', value: PAYMENT_TYPE_CASH },
  { label: 'restaurant:payments.formCreatePayment.paymentsTypesLabels.creditCard', value: PAYMENT_TYPE_CREDIT_CARD },
  { label: 'restaurant:payments.formCreatePayment.paymentsTypesLabels.debitCard', value: PAYMENT_TYPE_DEBIT_CARD },
  { label: 'restaurant:payments.formCreatePayment.paymentsTypesLabels.thirdParty', value: PAYMENT_TYPE_THIRD_PARTY },
  {
    label: 'restaurant:payments.formCreatePayment.paymentsTypesLabels.foreingCurrency',
    value: PAYMENT_TYPE_FOREIGN_CURRENCY,
  },
];

const CURRENCY_TYPE_DOLLAR = 'USD';
const CURRENCY_TYPE_EURO = 'EUR';
const CURRENCY_TYPE_CANADIAN_DOLLAR = 'CAD';

export const CURRENCY_TYPES = {
  CURRENCY_TYPE_DOLLAR,
  CURRENCY_TYPE_EURO,
  CURRENCY_TYPE_CANADIAN_DOLLAR,
};

export const currencyTypes = [
  { label: 'restaurant:payments.formCreatePayment.currencyTypesLabels.dollar', value: CURRENCY_TYPE_DOLLAR },
  { label: 'restaurant:payments.formCreatePayment.currencyTypesLabels.euro', value: CURRENCY_TYPE_EURO },
  {
    label: 'restaurant:payments.formCreatePayment.currencyTypesLabels.canadianDollar',
    value: CURRENCY_TYPE_CANADIAN_DOLLAR,
  },
];
