import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { cancelInvoicing, downloadInvoices, getReportsGenerated, getReportsGeneratedPagination } from './actions';
import {
  selectInvoicesGenerated,
  selectPaginationInvoicesGeneratedState,
  selectInvoicesGeneratedPaginationInfo,
  selectCancelInvoicingState,
  selectDownloadInvoicesState,
} from './selectors';

export function withInvoicesGeneratedHoc(Component) {
  function withInvoicesGenerated(props) {
    return <Component {...props} />;
  }

  withInvoicesGenerated.displayName = `withInvoicesGenerated(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withInvoicesGenerated;
}

const mapStateToProps = createStructuredSelector({
  loadInvoicesGeneratedState: selectInvoicesGenerated,
  invoicesGeneratedPaginationInfo: selectInvoicesGeneratedPaginationInfo,
  paginationInvoicesGeneratedState: selectPaginationInvoicesGeneratedState,
  cancelInvoicingState: selectCancelInvoicingState,
  downloadInvoicesState: selectDownloadInvoicesState,
});

export function mapDispatchToProps(dispatch) {
  return {
    cancelInvoicing: (values = {}) => dispatch(cancelInvoicing(values)),
    downloadInvoices: (type, filters, invoicesUuids) => dispatch(downloadInvoices({ type, filters, invoicesUuids })),
    resetDownloadInvoices: () => dispatch(downloadInvoices.reset()),
    resetCancelInvoicing: () => dispatch(cancelInvoicing.reset()),
    loadInvoicesGenerated: (type, filters = {}) => dispatch(getReportsGenerated({ type, filters })),
    loadInvoicesGeneratedPagination: (type, filters = {}) => dispatch(getReportsGeneratedPagination({ type, filters })),
    resetGetInvoicesGenerated: () => dispatch(getReportsGenerated.reset()),
  };
}

export const withInvoicesGeneratedState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withInvoicesGeneratedState, withInvoicesGeneratedHoc);
