import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import { getReportsFiltersRequest } from 'utils/api/baas/reportsFilters';

import { getReportsFilters } from './actions';

export function* handleGetReportsFiltersRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsFilters.request());

    const { data } = yield call(baasRequest, getReportsFiltersRequest, filters);

    yield put(getReportsFilters.success(data));
  } catch (error) {
    yield put(getReportsFilters.failure(error));
  } finally {
    yield put(getReportsFilters.fulfill());
  }
}

export default function* reportsFiltersProviderSaga() {
  yield takeLatest(getReportsFilters.TRIGGER, handleGetReportsFiltersRequest);
}
