export const removeAccents = (str = '') => {
  return str
    .replace(/[áàãâä]/gi, 'a')
    .replace(/[éè¨ê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[úùüû]/gi, 'u')
    .replace(/[ç]/gi, 'c')
    .replace(/[ñ]/gi, 'n')
    .replace(/[^a-zA-Z0-9]/g, ' ');
};

export const toUpperAndHyphen = (str = '') => str.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);

export const toNumber = (str) => {
  if (!str) return 0;
  if (typeof str === 'number') return str;
  if (typeof str === 'string') {
    const transformed = Number(str);
    if (Number.isNaN(transformed)) return 0;
    return transformed;
  }
  return 0;
};
