import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

function Container({ children, ...others }) {
  return (
    <Box component="main" flexGrow={1} role="main" {...others}>
      {children}
    </Box>
  );
}

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
