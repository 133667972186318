import { useSelector, useDispatch } from 'react-redux';

import { selectMenusDetailsResumeState, selectMenusDetailsResume } from './selectors';
import * as actions from './actions';

function useMenus() {
  const dispatch = useDispatch();
  const menusDetails = useSelector(selectMenusDetailsResume);
  const menusDetailsState = useSelector(selectMenusDetailsResumeState);

  return {
    getMenusDetails: (values) => dispatch(actions.menusDetailsResume(values)),
    resetGetMenusDetails: () => dispatch(actions.menusDetailsResume.reset()),
    sortMenusPosition: (menus) => dispatch(actions.getMenus.sortPosition(menus)),
    syncProductOverride: (values) => dispatch(actions.syncProductOverride.success(values)),
    invalidateMenus: () => dispatch(actions.menusDetailsResume.invalidateMenus()),
    menusDetails,
    menusDetailsState,
  };
}

export default useMenus;
