import orderBy from 'lodash/orderBy';

import { getGroupName, setDefaultTaxes, getDefaultTaxes } from './utils';

export const taxesGroupedBy = (list = [], groupByProp = 'class') => {
  if (list.length === 0) return {};

  const obj = {};
  list.forEach((tax) => {
    const prop = tax[groupByProp];
    if (!obj[prop]) {
      obj[prop] = [];
    }
    obj[prop].push({ ...tax });
  });
  return obj;
};

export const getOnlyTaxName = (str = '') => {
  const arr = str.split(' ') || [];
  if (arr.length > 1) return arr[0];
  return str;
};

export const convertTaxesToList = (list = []) => {
  if (list.length === 0) return [];
  const sortList = orderBy(list, ['sortPosition']);
  let newList = [];
  sortList.forEach((tax) => {
    if (tax?.items?.length > 0) {
      const sortItems = orderBy(tax.items, ['sortPosition']).map((taxItem) => {
        return { ...taxItem, class: tax.name };
      });
      newList = [...newList, ...sortItems];
    }
  });
  return newList;
};

export const convertToFieldFormat = (taxes = [], prefix = 'taxGroupField') => {
  if (taxes.length === 0) return {};

  const oFields = {};
  const groups = getGroupName(taxes);

  groups.forEach((group) => {
    oFields[`${prefix}${group}`] = '';
  });

  const defaultTaxes = getDefaultTaxes(convertTaxesToList(setDefaultTaxes(taxes)));
  if (defaultTaxes.length === 0) return {};

  defaultTaxes.forEach((defaultTax) => {
    oFields[`${prefix}${defaultTax.class}`] = defaultTax.uuid;
  });

  return oFields;
};

export const mergeAssignTaxesToField = (
  taxesField = {},
  taxesAssign = [],
  multiTaxes = [],
  prefix = 'taxGroupField',
) => {
  if (taxesAssign.length === 0) return taxesField;
  const newTaxesField = { ...taxesField };
  const listTaxes = convertTaxesToList(multiTaxes);
  taxesAssign.forEach((taxUuid) => {
    const taxClass = listTaxes.find((tax) => tax.uuid === taxUuid)?.class;
    if (taxClass && taxesField[`${prefix}${taxClass}`]) {
      newTaxesField[`${prefix}${taxClass}`] = taxUuid;
    }
  });

  return newTaxesField;
};

export const covertToSaveArray = (object, taxes = [], prefix = 'taxGroupField') => {
  const taxesList = convertTaxesToList(taxes).filter((tax) => tax.uuid !== 'noApply');
  const keysTaxesFields = Object.keys(object).filter((key) => key.includes(prefix) && object[key]);

  const taxesToSave = [];

  keysTaxesFields.forEach((keyTaxField) => {
    const oTax = taxesList.find((tax) => tax.uuid === object[keyTaxField]);
    if (oTax) {
      taxesToSave.push(oTax.uuid);
    }
  });

  return taxesToSave;
};

export const withNoApplyOption = (taxesGroups) => {
  if (taxesGroups.length === 0) return [];
  const noApply = {
    uuid: 'noApply',
    name: 'No aplica',
    value: 0,
    type: 'noApply',
    isDefault: false,
    sortPosition: 0,
  };
  return taxesGroups.map((taxGroup) => {
    if (taxGroup.name !== 'IVA' && Array.isArray(taxGroup.items)) {
      const newItems = [...taxGroup.items];
      newItems.push({
        ...noApply,
        sortPosition: taxGroup.items.length,
      });

      return { ...taxGroup, items: newItems };
    }
    return taxGroup;
  });
};
