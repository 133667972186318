import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getModifierGroupsRequest } from 'utils/api/baas/menuMaker/modifierGroups';

import { getModifierGroups, getPaginationModifierGroup } from '../actions';

export function* handleGetModifierGroupsRequest({ payload }) {
  try {
    yield put(getModifierGroups.request());

    const { data: modifierGroups, pagination } = yield call(baasRequest, getModifierGroupsRequest, payload);

    yield put(getModifierGroups.success({ modifierGroups, pagination, page: payload?.page }));
  } catch (err) {
    yield put(getModifierGroups.failure(err));
  }
}

export function* getPaginationModifierGroupSaga({ payload }) {
  try {
    yield put(getPaginationModifierGroup.request());

    const { data, pagination } = yield call(baasRequest, getModifierGroupsRequest, payload);

    yield put(getPaginationModifierGroup.success({ data, pagination, page: payload?.page }));
  } catch (error) {
    yield put(getPaginationModifierGroup.failure(error));
  }
}
