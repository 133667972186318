import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getBrandsRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/brands/`, {
    method: 'GET',
    ...options,
  });
}

export function updateBrandsRequest(brands, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/brands/providers-config/`, {
    method: 'PUT',
    body: JSON.stringify({
      brands,
    }),
    ...options,
  });
}
