import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getReportsCancellationsResume,
  getReportsCancellationsDetails,
  getReportsCancellationsDetailsByPagination,
  getReportsCancellationsDetailsBySorting,
  exportReportsCancellations,
} from './actions';

import {
  selectReportsCancellationsResume,
  selectReportsCancellationsResumeState,
  selectReportsCancellationsDetails,
  selectReportsCancellationsDetailsState,
  selectReportsCancellationsDetailsPagination,
  selectReportsCancellationsDetailsPaginationState,
  selectReportsCancellationsDetailsSortingState,
  selectExportReportsCancellationsState,
  selectExportReportsCancellationsDocument,
} from './selectors';

export function withReportsCancellationsHoc(Component) {
  function withReportsCancellations(props) {
    return <Component {...props} />;
  }

  withReportsCancellations.displayName = `withReportsCancellations(${getDisplayName(Component)})`;
  return withReportsCancellations;
}

const mapStateToProps = createStructuredSelector({
  cancellationsResume: selectReportsCancellationsResume,
  cancellationsResumeState: selectReportsCancellationsResumeState,
  cancellationsDetails: selectReportsCancellationsDetails,
  cancellationsDetailsState: selectReportsCancellationsDetailsState,
  cancellationsDetailsPaginationValues: selectReportsCancellationsDetailsPagination,
  cancellationsDetailsPaginationState: selectReportsCancellationsDetailsPaginationState,
  cancellationsDetailsSortingState: selectReportsCancellationsDetailsSortingState,
  cancellationsExportDocument: selectExportReportsCancellationsDocument,
  cancellationsExportState: selectExportReportsCancellationsState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadReportsCancellationsResume: (filters = {}) => dispatch(getReportsCancellationsResume(filters)),
    loadReportsCancellationsDetails: (filters = {}) => dispatch(getReportsCancellationsDetails(filters)),
    loadReportsCancellationsDetailsPagination: (filters = {}) =>
      dispatch(getReportsCancellationsDetailsByPagination(filters)),
    loadReportsCancellationsDetailsSorting: (filters = {}) =>
      dispatch(getReportsCancellationsDetailsBySorting(filters)),
    exportReportsCancellations: (filters = {}) => dispatch(exportReportsCancellations(filters)),
    resetReportsCancellationsResume: () => dispatch(getReportsCancellationsResume.reset()),
    resetReportsCancellationsDetails: () => dispatch(getReportsCancellationsDetails.reset()),
    resetReportsCancellationsDetailsPagination: () => dispatch(getReportsCancellationsDetailsByPagination.reset()),
    resetReportsCancellationsDetailsSorting: () => dispatch(getReportsCancellationsDetailsBySorting.reset()),
    resetExportReportsCancellations: () => dispatch(exportReportsCancellations.reset()),
  };
}

export const withReportsCancellationsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withReportsCancellationsState, withReportsCancellationsHoc);
