/**
 *
 * ParrotSoftware
 * SettingsServiceAreasProvider selectors
 *
 */
import { createSelector } from 'reselect';
import orderBy from 'lodash/sortBy';

import { initialState } from './reducer';

const selectServiceAreasProviderDomain = (state) => state.settingsServiceAreas || initialState;

const selectCreateServiceAreaState = createSelector(
  selectServiceAreasProviderDomain,
  ({ createServiceArea: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectServiceAreas = createSelector(selectServiceAreasProviderDomain, ({ getServicesAreas }) => {
  return orderBy(getServicesAreas.areas, ['sortPosition']);
});

const selectServiceAreasState = createSelector(
  selectServiceAreasProviderDomain,
  ({ getServicesAreas: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectServiceAreaDetails = createSelector(
  selectServiceAreasProviderDomain,
  (subState) => subState.getServiceAreaDetails.area,
);

const selectServiceAreaDetailsState = createSelector(
  selectServiceAreasProviderDomain,
  ({ getServiceAreaDetails: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectUpdateServiceAreaState = createSelector(
  selectServiceAreasProviderDomain,
  ({ updateServiceArea: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectDeleteServiceAreaState = createSelector(
  selectServiceAreasProviderDomain,
  ({ deleteServiceArea: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectServiceAreaChangeDefaultState = createSelector(
  selectServiceAreasProviderDomain,
  ({ serviceAreaChangeDefault: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectValidateNameAreaState = createSelector(
  selectServiceAreasProviderDomain,
  ({ validateNameArea: { isNameRegistered } }) => ({
    isNameRegistered,
  }),
);

export {
  selectServiceAreasProviderDomain,
  selectCreateServiceAreaState,
  selectServiceAreas,
  selectServiceAreasState,
  selectServiceAreaDetails,
  selectServiceAreaDetailsState,
  selectUpdateServiceAreaState,
  selectDeleteServiceAreaState,
  selectServiceAreaChangeDefaultState,
  selectValidateNameAreaState,
};
