import { createSelector } from 'reselect';

import { getFilteredDiscountsByText } from 'utils/discounts';

import { initialState } from './reducer';

const selectDiscountsProviderDomain = (state) => state.settingsDiscounts || initialState;

const selectDiscounts = createSelector(selectDiscountsProviderDomain, ({ getDiscounts, sorting }) => {
  let discountsList = [...getDiscounts.discounts];

  const { filterByText } = sorting;

  if (filterByText && filterByText.length >= 3) {
    discountsList = getFilteredDiscountsByText(getDiscounts.discounts, sorting);
  }

  return discountsList;
});

const selectDiscountsPagination = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.getDiscounts.pagination,
);

const selectDiscountsSorting = createSelector(selectDiscountsProviderDomain, (subState) => subState.sorting);

const selectDiscountsFetching = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.getDiscounts.fetching,
);

const selectDiscountsFetched = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.getDiscounts.fetched,
);

const selectDiscountsError = createSelector(selectDiscountsProviderDomain, (subState) => subState.getDiscounts.error);

const selectCreateDiscount = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.createDiscount.discount,
);

const selectCreateDiscountFetching = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.createDiscount.fetching,
);

const selectCreateDiscountFetched = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.createDiscount.fetched,
);

const selectCreateDiscountError = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.createDiscount.error,
);

const selectDeleteDiscountFetching = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.deleteDiscount.fetching,
);

const selectDeleteDiscountFetched = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.deleteDiscount.fetched,
);

const selectDeleteDiscountError = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.deleteDiscount.error,
);

const selectSetIsEnabledDiscountFetching = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.setIsEnabledDiscount.fetching,
);

const selectSetIsEnabledDiscountFetched = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.setIsEnabledDiscount.fetched,
);

const selectSetIsEnabledDiscountError = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.setIsEnabledDiscount.error,
);

const selectDiscountDetail = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.getDiscountDetail.discount,
);

const selectDiscountDetailFetching = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.getDiscountDetail.fetching,
);

const selectDiscountDetailFetched = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.getDiscountDetail.fetched,
);

const selectDiscountDetailError = createSelector(
  selectDiscountsProviderDomain,
  (subState) => subState.getDiscountDetail.error,
);

const selectDiscountsPaginationState = createSelector(
  selectDiscountsProviderDomain,
  ({ getDiscountsPagination }) => getDiscountsPagination,
);

const selectDiscountsPaginationInfo = createSelector(
  selectDiscountsProviderDomain,
  ({ getDiscounts }) => getDiscounts.pagination,
);

export {
  selectDiscountsProviderDomain,
  selectDiscounts,
  selectDiscountsPagination,
  selectDiscountsSorting,
  selectDiscountsFetching,
  selectDiscountsFetched,
  selectDiscountsError,
  selectCreateDiscount,
  selectCreateDiscountFetching,
  selectCreateDiscountFetched,
  selectCreateDiscountError,
  selectDeleteDiscountFetching,
  selectDeleteDiscountFetched,
  selectDeleteDiscountError,
  selectSetIsEnabledDiscountFetching,
  selectSetIsEnabledDiscountFetched,
  selectSetIsEnabledDiscountError,
  selectDiscountDetail,
  selectDiscountDetailFetching,
  selectDiscountDetailFetched,
  selectDiscountDetailError,
  selectDiscountsPaginationState,
  selectDiscountsPaginationInfo,
};
