import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getInvoicingConfigListRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/invoicing/configs/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function deleteInvoicingConfigRequest(invoicingUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/invoicing/configs/${invoicingUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function getInvoicingConfigDetailsRequest(invoicingUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/invoicing/configs/${invoicingUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function createInvoicingConfigRequest(
  {
    fiscalRegime,
    branchName,
    taxId,
    email,
    fiscalName,
    associatedBrands,
    address,
    stampCertFile,
    stampKeyFile,
    stampPassword,
    name,
    lastName,
    surname,
  },
  options = {},
) {
  const headers = { ...options.headers };
  delete headers['Content-Type'];

  options = {
    ...options,
    headers: {
      ...headers,
    },
  };

  const storeUuid = getUserCurrentStore()?.uuid;
  const formData = new FormData();

  const configValues = {
    fiscal_regime: fiscalRegime,
    branch_name: branchName,
    tax_id: taxId,
    fiscal_name: fiscalName,
    email,
    associated_brands: associatedBrands,
    stamp_cert_file: stampCertFile,
    stamp_key_file: stampKeyFile,
    stamp_password: stampPassword,
    street: address?.street,
    ext_num: address?.extNum,
    int_num: address?.intNum,
    country: address?.country,
    state: address?.state,
    municipality: address?.municipality,
    neighborhood: address?.neighborhood,
    zip_code: address?.zipCode,
    person_first_name: name,
    person_last_name: lastName,
    person_surname: surname,
  };

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  Object.keys(configValues).forEach((key) => {
    const value = configValues[key];

    if (key === 'associated_brands' && !value) {
      formData.append('associated_brands', []);
    }

    if (value) {
      formData.append(key, value);
    }
  });

  return request(`${API_BASE_URL}/v1/invoicing/configs/?${query}`, {
    method: 'POST',
    body: formData,
    ...options,
  });
}

export function updateInvoicingConfigRequest(
  invoicingUuid,
  {
    fiscalRegime,
    branchName,
    taxId,
    fiscalName,
    associatedBrands,
    email,
    address,
    stampCertFile,
    stampKeyFile,
    stampPassword,
    name,
    lastName,
    surname,
  },
  options = {},
) {
  const headers = { ...options.headers };
  delete headers['Content-Type'];

  options = {
    ...options,
    headers: {
      ...headers,
    },
  };

  const storeUuid = getUserCurrentStore()?.uuid;
  const formData = new FormData();

  const configValues = {
    fiscal_regime: fiscalRegime,
    email,
    branch_name: branchName,
    tax_id: taxId,
    fiscal_name: fiscalName,
    associated_brands: associatedBrands,
    stamp_cert_file: stampCertFile,
    stamp_key_file: stampKeyFile,
    stamp_password: stampPassword,
    street: address?.street,
    ext_num: address?.extNum,
    int_num: address?.intNum,
    country: address?.country,
    state: address?.state,
    municipality: address?.municipality,
    neighborhood: address?.neighborhood,
    zip_code: address?.zipCode,
    person_first_name: name,
    person_last_name: lastName,
    person_surname: surname,
  };

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  Object.keys(configValues).forEach((key) => {
    const value = configValues[key];

    if (key === 'associated_brands' && !value) {
      formData.append('associated_brands', []);
    }

    if (value) {
      formData.append(key, value);
    }
  });

  return request(`${API_BASE_URL}/v1/invoicing/configs/${invoicingUuid}/?${query}`, {
    method: 'PUT',
    body: formData,
    ...options,
  });
}
