import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getModifierGroups,
  getPaginationModifierGroup,
  createModifierGroup,
  duplicateModifierGroup,
  deleteModifierGroup,
} from './actions';
import {
  // Get Modifier Groups
  selectModifierGroups,
  selectModifierGroupsFetching,
  selectModifierGroupsError,
  selectModifierGroupsFetched,
  selectModifierGroupsPagination,
  selectPaginationModifierGroupState,
  // create modifier group
  selectCreateModifierGroup,
  selectCreateModifierGroupError,
  selectCreateModifierGroupFetching,
  selectCreateModifierGroupFetched,
  // Delete Modifier Group
  selectDeleteModifierGroupError,
  selectDeleteModifierGroupFetching,
  selectDeleteModifierGroupFetched,
  // Duplicate Modifier Group
  selectDuplicateModifierGroupFetching,
  selectDuplicateModifierGroupError,
  selectDuplicateModifierGroupFetched,
  selectDuplicateModifierGroupNewInstance,
} from './selectors';

export function withModifierGroupsHoc(Component) {
  function withModifierGroups(props) {
    return <Component {...props} />;
  }

  withModifierGroups.displayName = `withModifierGroups(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withModifierGroups;
}

const mapStateToProps = createStructuredSelector({
  // Get Modifier Groups
  modifierGroupsList: selectModifierGroups,
  modifierGroupsFetching: selectModifierGroupsFetching,
  modifierGroupsError: selectModifierGroupsError,
  modifierGroupsFetched: selectModifierGroupsFetched,
  modifierGroupPagination: selectModifierGroupsPagination,
  paginationModifierGroupState: selectPaginationModifierGroupState,
  // create modifier group
  createModifierGroupObject: selectCreateModifierGroup,
  createModifierGroupError: selectCreateModifierGroupError,
  createModifierGroupFetching: selectCreateModifierGroupFetching,
  createModifierGroupFetched: selectCreateModifierGroupFetched,
  // Delete Modifier Group
  deleteModifierGroupError: selectDeleteModifierGroupError,
  deleteModifierGroupFetching: selectDeleteModifierGroupFetching,
  deleteModifierGroupFetched: selectDeleteModifierGroupFetched,
  // Duplicate Modifier Group
  duplicateModifierGroupFetching: selectDuplicateModifierGroupFetching,
  duplicateModifierGroupError: selectDuplicateModifierGroupError,
  duplicateModifierGroupFetched: selectDuplicateModifierGroupFetched,
  duplicateModifierGroupNewInstance: selectDuplicateModifierGroupNewInstance,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadModifierGroups: (values) => dispatch(getModifierGroups(values)),
    paginateModifierGroups: (values) => dispatch(getPaginationModifierGroup(values)),
    resetModifierGroups: () => dispatch(getModifierGroups.reset()),
    deleteModifierGroup: ({ storeUuid, modifierGroupUuid }) =>
      dispatch(deleteModifierGroup({ storeUuid, modifierGroupUuid })),
    resetDeleteModifierGroup: () => dispatch(deleteModifierGroup.reset()),
    duplicateModifierGroup: ({ storeUuid, modifierGroupUuid }) =>
      dispatch(duplicateModifierGroup({ storeUuid, modifierGroupUuid })),
    resetDuplicateModifierGroup: () => dispatch(duplicateModifierGroup.reset()),
    createModifierGroup: (storeUuid, modifierGroup) => dispatch(createModifierGroup({ storeUuid, modifierGroup })),
    resetCreateModifierGroup: () => dispatch(createModifierGroup.reset()),
  };
}

export const withModifierGroupsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withModifierGroupsState, withModifierGroupsHoc);
