type Entity = 'category' | 'product';

export interface Props {
  entity: Entity;
  onChange: (value: string) => void;
  value: string;
}

export const colors = ['FFFFFF', 'C3BFBB', 'F1DFC3', 'C0CFE0', 'E2EBCE', 'ECCDE1', 'D5C4EB'];

export const descriptionMap = {
  category: 'categories:formCategory.colorInfo',
  product: 'menuMaker:productForm.colorInfo',
};
