import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { duplicateModifierGroupRequest } from 'utils/api/baas/menuMaker/modifierGroups';

import { duplicateModifierGroup } from '../actions';

export function* handleDuplicateModifierGroupRequest(action) {
  const { storeUuid, modifierGroupUuid } = action.payload;
  try {
    yield put(duplicateModifierGroup.request());
    const data = yield call(baasRequest, duplicateModifierGroupRequest, {
      storeUuid,
      modifierGroupUuid,
    });
    yield put(duplicateModifierGroup.success(data));
  } catch (err) {
    yield put(duplicateModifierGroup.failure(err));
  }
}
