import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '40%',
    maxHeight: 'calc(100vh - 450px)',
    overflow: 'auto',
    marginTop: theme.spacing(2),
  },
  subList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  detailsError: {
    fontSize: theme.typography.pxToRem(14),
  },
  textPrimaryHeaher: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  iconInitialState: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  iconRotate: {
    transform: 'rotate(180deg)',
  },
}));

export default useStyles;
