import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MenuOutline = forwardRef(function MenuOutline(props, ref) {
  const { color = '#000000', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M20.812 4.097a.643.643 0 10-.91-.909L12 11.091 4.097 3.188a.643.643 0 00-.909.91L11.091 12l-7.903 7.903a.643.643 0 10.91.909L12 12.909l7.903 7.903a.643.643 0 10.909-.91L12.909 12l7.903-7.903z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

MenuOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MenuOutline;
