import queryString from 'query-string';

import request from 'utils/request';
import { paginationConfigDefaults } from 'utils/voidReasons';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getVoidReasonsRequest({ storeUuid, page }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    page,
    page_size: paginationConfigDefaults.pageSize,
  });
  return request(`${API_BASE_URL}/v1/payments/void-reasons/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function createVoidReasonRequest({ storeUuid, voidReason }, options = {}) {
  return request(`${API_BASE_URL}/v1/payments/void-reasons/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify(voidReason),
    ...options,
  });
}

export function deleteVoidReasonRequest({ storeUuid, voidReasonUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/payments/void-reasons/${voidReasonUuid}/?store_uuid=${storeUuid}`, {
    method: 'DELETE',
    ...options,
  });
}
