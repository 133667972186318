import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  height?: string | number;
  width?: string | number;
}

const LogoConnectSmall = forwardRef(function LogoConnectSmall(props: Props, ref: Ref<SVGSVGElement>) {
  const { height = 285, width = 161, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={height}
      viewBox="0 0 161 285"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M129.231 268.358C148.97 253.118 160.276 230.1 160.276 205.182C160.276 161.18 124.482 125.409 80.5032 125.409C80.3902 125.409 80.2771 125.409 80.1641 125.409C69.6046 125.341 59.746 121.791 51.6059 115.098C41.0012 106.393 34.9187 93.5496 34.9187 79.8471C34.9187 54.7033 55.3594 34.2626 80.5032 34.2626C105.647 34.2626 126.088 54.7033 126.088 79.8471C126.088 85.8165 124.957 91.6276 122.719 97.0996C122.334 98.0266 121.927 98.9537 121.498 99.8356L152.226 114.872C152.995 113.289 153.741 111.661 154.397 110.033C158.332 100.446 160.321 90.271 160.321 79.8471C160.321 35.8454 124.528 0.0742188 80.5484 0.0742188C36.5467 0.0742188 0.775536 35.868 0.775536 79.8471C0.775536 103.815 11.4029 126.291 29.9442 141.531C30.3738 141.87 30.826 142.187 31.2557 142.526C12.7143 157.155 0.75293 179.789 0.75293 205.205C0.75293 229.173 11.3803 251.648 29.9216 266.888C44.1441 278.556 62.1202 285 80.5258 285C98.321 284.955 115.166 279.212 129.231 268.358ZM51.6059 240.433C41.0012 231.728 34.9187 218.884 34.9187 205.182C34.9187 180.129 55.2463 159.733 80.2545 159.62C80.3223 159.62 80.4128 159.62 80.4806 159.62V159.597C105.624 159.597 126.065 180.038 126.065 205.182C126.065 219.404 119.598 232.564 108.315 241.292C100.288 247.488 90.6783 250.766 80.4806 250.766C69.8307 250.744 59.8364 247.194 51.6059 240.433Z"
        fill="#F04E4A"
      />
      <path
        d="M95.8111 79.802C95.8111 88.2587 88.9599 95.1099 80.5032 95.1099C72.0466 95.1099 65.1953 88.2587 65.1953 79.802C65.1953 71.3454 72.0466 64.4941 80.5032 64.4941C88.9599 64.5168 95.8111 71.368 95.8111 79.802Z"
        fill="#F04E4A"
      />
    </svg>
  );
});

export default LogoConnectSmall;
