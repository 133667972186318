import queryString from 'query-string';

import { api } from 'config';
import { Response } from 'types';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

import { StoreSettings, UpdateSettingsProps } from './types';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getSettingsStoreRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/stores/settings/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export async function getStoreSettings() {
  const storeUuid = getUserCurrentStore()?.uuid;

  const response = await api.get<Response<StoreSettings>>('/v1/stores/settings', {
    params: {
      store_uuid: storeUuid,
    },
  });

  return response.data.data;
}

// @ts-ignore
export function updateSettingsStoreRequest(updateSettingsStoreData, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/stores/settings/?${query}`, {
    method: 'PUT',
    body: JSON.stringify(updateSettingsStoreData),
    ...options,
  });
}

export async function updateSettingsRequest({ settingsData }: UpdateSettingsProps) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const response = await api.put(`/v1/stores/settings/?store_uuid=${storeUuid}`, settingsData);
  return response.data;
}

// @ts-ignore
export function getSettingsStoreCustomizePrintsRequest({ context, brandUuid, evalBrandTicket }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    context,
    brand: brandUuid,
    eval_brand_ticket: evalBrandTicket,
  });

  return request(`${API_BASE_URL}/v1/stores/settings/tickets/?${query}`, {
    method: 'GET',
    ...options,
  });
}
