import React from 'react';
import PropTypes from 'prop-types';

import Tabs from '@design-system/Tabs';

import useContentLayout from 'hooks/useContentLayout';

function TabsNavigation({ ...others }) {
  const { value } = useContentLayout();

  return <Tabs value={value} variant="scrollable" {...others} />;
}

TabsNavigation.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default TabsNavigation;
