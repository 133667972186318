import produce from 'immer';

import { validateEmail } from './actions';

export const initialState = {
  validateEmail: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const employeesProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case validateEmail.REQUEST:
        draft.validateEmail.fetching = true;
        draft.validateEmail.error = false;
        draft.validateEmail.fetched = false;
        break;

      case validateEmail.SUCCESS:
        draft.validateEmail.fetching = false;
        draft.validateEmail.fetched = true;

        break;

      case validateEmail.FAILURE:
        draft.validateEmail.error = payload;
        draft.validateEmail.fetching = false;
        draft.validateEmail.fetched = false;
        break;

      case validateEmail.RESET:
        draft.validateEmail = { ...initialState.validateEmail };
        break;

      default:
        return draft;
    }
  });

export default employeesProviderReducer;
