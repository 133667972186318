import { forwardRef, Ref, SVGProps } from 'react';

const OrganizationDiagram = forwardRef(function OrganizationDiagram(
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) {
  return (
    <svg ref={ref} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M12 3.53846C11.401 3.53846 10.9155 4.02062 10.9155 4.61538C10.9155 5.21015 11.401 5.69231 12 5.69231C12.5989 5.69231 13.0845 5.21015 13.0845 4.61538C13.0845 4.02062 12.5989 3.53846 12 3.53846ZM9.36618 4.61538C9.36618 3.17095 10.5454 2 12 2C13.4546 2 14.6338 3.17095 14.6338 4.61538C14.6338 6.05982 13.4546 7.23077 12 7.23077C10.5454 7.23077 9.36618 6.05982 9.36618 4.61538ZM12.7746 10.3077V8.30769H11.2253V10.3077H4.66664C3.66838 10.3077 2.85913 11.1113 2.85913 12.1026V15.6923H4.40843V12.1026C4.40843 11.9609 4.52403 11.8462 4.66664 11.8462H11.2253V15.6923H12.7746V11.8462H19.3333C19.4759 11.8462 19.5915 11.9609 19.5915 12.1026V15.6923H21.1408V12.1026C21.1408 11.1113 20.3316 10.3077 19.3333 10.3077H12.7746ZM10.9155 19.3846C10.9155 18.7898 11.401 18.3077 12 18.3077C12.5989 18.3077 13.0845 18.7898 13.0845 19.3846C13.0845 19.9794 12.5989 20.4615 12 20.4615C11.401 20.4615 10.9155 19.9794 10.9155 19.3846ZM12 16.7692C10.5454 16.7692 9.36618 17.9402 9.36618 19.3846C9.36618 20.8291 10.5454 22 12 22C13.4546 22 14.6338 20.8291 14.6338 19.3846C14.6338 17.9402 13.4546 16.7692 12 16.7692ZM20.3662 18.3077C19.7673 18.3077 19.2817 18.7898 19.2817 19.3846C19.2817 19.9794 19.7673 20.4615 20.3662 20.4615C20.9652 20.4615 21.4507 19.9794 21.4507 19.3846C21.4507 18.7898 20.9652 18.3077 20.3662 18.3077ZM17.7324 19.3846C17.7324 17.9402 18.9116 16.7692 20.3662 16.7692C21.8208 16.7692 23 17.9402 23 19.3846C23 20.8291 21.8208 22 20.3662 22C18.9116 22 17.7324 20.8291 17.7324 19.3846ZM2.54929 19.3846C2.54929 18.7898 3.03484 18.3077 3.6338 18.3077C4.23276 18.3077 4.71831 18.7898 4.71831 19.3846C4.71831 19.9794 4.23276 20.4615 3.6338 20.4615C3.03484 20.4615 2.54929 19.9794 2.54929 19.3846ZM3.6338 16.7692C2.17919 16.7692 1 17.9402 1 19.3846C1 20.8291 2.17919 22 3.6338 22C5.08841 22 6.2676 20.8291 6.2676 19.3846C6.2676 17.9402 5.08841 16.7692 3.6338 16.7692Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
});

export default OrganizationDiagram;
