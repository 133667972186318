export const CalendarIcon = () => (
  <svg
    className="react-daterange-picker-v2__calendar-button__icon react-daterange-picker-v2__button__icon"
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Calendar">
      <g id="Group 314">
        <g id="Group 287">
          <path
            clipRule="evenodd"
            d="M2 3.20001V12H12V3.20001H2ZM1.5 2.20001C1.22386 2.20001 1 2.42387 1 2.70001V12.5C1 12.7762 1.22386 13 1.5 13H12.5C12.7761 13 13 12.7762 13 12.5V2.70001C13 2.42387 12.7761 2.20001 12.5 2.20001H1.5Z"
            fill="#000000"
            fillRule="evenodd"
            id="Rectangle 85 (Stroke)"
          />
          <path
            clipRule="evenodd"
            d="M12.7 6.10001H1.60001V5.10001H12.7V6.10001Z"
            fill="#000000"
            fillRule="evenodd"
            id="Vector 49 (Stroke)"
          />
        </g>
        <path
          clipRule="evenodd"
          d="M3.89999 1V2.2H2.89999V1H3.89999Z"
          fill="#000000"
          fillRule="evenodd"
          id="Vector 50 (Stroke)"
        />
        <path
          clipRule="evenodd"
          d="M11.1 1V2.2H10.1V1H11.1Z"
          fill="#000000"
          fillRule="evenodd"
          id="Vector 51 (Stroke)"
        />
      </g>
    </g>
  </svg>
);
