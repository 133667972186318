import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_LIST_REPORTS_ORDERS,
  EXPORT_REPORTS_ORDERS,
  GET_LIST_REPORTS_ORDERS_SCROLL_PAGINATION,
  GET_DETAILS_REPORTS_ORDERS,
} from './constants';

export const getListReportsOrders = extendRoutine(createRoutine(GET_LIST_REPORTS_ORDERS), 'RESET');
export const getDetailsReportsOrders = extendRoutine(createRoutine(GET_DETAILS_REPORTS_ORDERS), 'RESET');
export const getListReportsOrdersScrollPagination = extendRoutine(
  createRoutine(GET_LIST_REPORTS_ORDERS_SCROLL_PAGINATION),
  'RESET',
);
export const exportReportsOrders = extendRoutine(createRoutine(EXPORT_REPORTS_ORDERS), 'RESET');
