/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Link from '@material-ui/core/Link';

import ButtonOutlined from '@design-system/ButtonOutlined';
import ButtonPrimary from '@design-system/ButtonPrimary';
import Table from '@experimental-components/Table';

import useUserStore from 'hooks/useUserStore';

import useStyles from './styles';

function DialogPublishMenu({
  brand,
  provider,
  open,
  onClose,
  onPublishMenus,
  isLoadingValidation,
  keepItemsAvailabilityPages,
  paginateKeepItemsState,
  t,
}) {
  const [showMoreLink, setShowMoreLink] = useState(false);
  const [keepItemsPages, setKeepItemsPages] = useState([]);

  const classes = useStyles();
  const { storeName } = useUserStore();
  const scrollPaginationRef = useRef(null);
  const enablePagination = keepItemsAvailabilityPages?.length && keepItemsAvailabilityPages[0].data.length > 6;

  useEffect(() => {
    setKeepItemsPages(keepItemsAvailabilityPages);

    if (!keepItemsPages?.length && keepItemsAvailabilityPages?.length) {
      setShowMoreLink(keepItemsAvailabilityPages[0].data.length > 6);
    }
  }, [keepItemsAvailabilityPages]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        if (
          isIntersecting &&
          paginateKeepItemsState?.hasNextPage &&
          enablePagination &&
          keepItemsPages?.length &&
          !showMoreLink
        ) {
          if (!paginateKeepItemsState?.isFetchingNextPage) {
            paginateKeepItemsState?.fetchNextPage();
          }
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };
  }, [
    scrollPaginationRef,
    paginateKeepItemsState?.hasNextPage,
    paginateKeepItemsState?.isFetchingNextPage,
    keepItemsPages,
    showMoreLink,
  ]);

  const renderBodyTableKeepItems = () => {
    if (!keepItemsPages?.length) {
      return renderPlaceholderKeepItems();
    }

    if (showMoreLink) {
      return keepItemsPages[0].data.slice(0, 6).map((item) => (
        <Box key={item?.uuid} className={classes.rowkeepItems}>
          <Box>{item?.name}</Box>
        </Box>
      ));
    }

    return keepItemsPages.map((page) => {
      if (!page) return null;

      return page?.data?.map((item) => (
        <Box key={item?.uuid} className={classes.rowkeepItems}>
          <Box>{item?.name}</Box>
        </Box>
      ));
    });
  };

  const renderPlaceholderKeepItems = () => {
    return [...Array(4).keys()].map((item) => (
      <Box key={item} className={classes.rowkeepItems}>
        <Skeleton height={14} variant="text" width={150} />
      </Box>
    ));
  };

  const handleOpenList = () => {
    setShowMoreLink(false);
  };

  return (
    <Dialog maxWidth="lg" open={open}>
      <Box alignItems="center" display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Box fontSize={20} fontWeight="fontWeightMedium" mt={4} textAlign="center">
          {t(
            `menuMaker:publishMenu.dialog.${
              keepItemsAvailabilityPages?.[0]?.data?.length ? 'titleValidation' : 'title'
            }`,
          )}
        </Box>

        <Box mt={0.5} textAlign="center">
          <Trans
            i18nKey={`menuMaker:publishMenu.dialog.${
              keepItemsAvailabilityPages?.[0]?.data?.length ? 'descriptionValidation' : 'description'
            }`}
          />
        </Box>

        {!keepItemsAvailabilityPages?.[0]?.data || !keepItemsAvailabilityPages?.[0]?.data.length ? (
          <Box className={classes.wrapperkeepItems}>
            <Table className={classes.table}>
              <Table.Head>
                <Table.Row>
                  <Table.HeadCell align="center">{t('menuMaker:publishMenu.dialog.store')}</Table.HeadCell>
                  <Table.HeadCell align="center">{t('menuMaker:publishMenu.dialog.brand')}</Table.HeadCell>
                  <Table.HeadCell align="center">{t('menuMaker:publishMenu.dialog.chanel')}</Table.HeadCell>
                  <Table.HeadCell align="center">{t('menuMaker:publishMenu.dialog.menus')}</Table.HeadCell>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <Table.Row>
                  <Table.Cell align="center" isBorder={false}>
                    {storeName}
                  </Table.Cell>

                  <Table.Cell align="center" isBorder={false}>
                    {brand?.name}
                  </Table.Cell>

                  <Table.Cell align="center" isBorder={false}>
                    <Box alignItems="center" display="flex">
                      {provider?.icon}
                      &nbsp;
                      {t(provider?.label)}
                    </Box>
                  </Table.Cell>

                  <Table.BigCell align="center" isBorder={false} maxHeightCell={200} scrollInCell>
                    {brand?.menus?.length ? (
                      <Table.BigCell.ExpandableList
                        defaultItems={6}
                        list={brand?.menus
                          ?.filter((menu) => menu.providers.some((item) => item?.providerName === provider?.value))
                          .map((menu) => (
                            <Table.BigCell.Option key={menu.uuid}>{menu?.name}</Table.BigCell.Option>
                          ))}
                      />
                    ) : (
                      t('common:notAvailableDefault')
                    )}
                  </Table.BigCell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Box>
        ) : (
          <Box className={clsx(classes.wrapperkeepItems, classes.heightOverOfStock)}>
            <Box className={clsx(classes.rowkeepItems, classes.rowSticky)}>
              <Box className={classes.headKeepItems}>{t('menuMaker:publishMenu.dialog.article')}</Box>
            </Box>
            {renderBodyTableKeepItems()}

            {showMoreLink && (
              <Box textAlign="center" width="100%">
                <Link
                  className={classes.linkMore}
                  color="secondary"
                  onClick={handleOpenList}
                  underline="hover"
                  variant="button"
                >
                  {t('common:buttons.seeMore')}
                </Link>
              </Box>
            )}

            {paginateKeepItemsState?.isFetchingNextPage && renderPlaceholderKeepItems()}

            <Box ref={scrollPaginationRef} pb={1} />
          </Box>
        )}
      </Box>

      <DialogActions align="center" className={classes.actions}>
        <ButtonOutlined
          className={classes.actionButton}
          disabled={isLoadingValidation}
          disableElevation
          onClick={onClose}
        >
          {t('common:buttons.cancel')}
        </ButtonOutlined>

        <ButtonPrimary
          className={classes.actionButton}
          disabled={isLoadingValidation}
          disableElevation
          loading={isLoadingValidation}
          onClick={onPublishMenus}
        >
          {t(`common:buttons.${keepItemsAvailabilityPages?.[0]?.data?.length ? 'accept' : 'publish'}`)}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
}

DialogPublishMenu.propTypes = {
  brand: PropTypes.object,
  provider: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onPublishMenus: PropTypes.func,
  isLoadingValidation: PropTypes.bool,
  keepItemsAvailabilityPages: PropTypes.array,
  paginateKeepItemsState: PropTypes.object,
  t: PropTypes.func,
};

export { default as ModalPublishWithErrors } from './ModalPublishWithErrors';

export default withTranslation('menuMaker')(DialogPublishMenu);
