import { useState, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';

import NestedTable from '@experimental-components/NestedTable';
import Tooltip from '@design-system/Tooltip';

import { useGetModifiersByProductDetail } from 'containers/ReportModifiers/hooks';

import PlaceholderTable from 'components/ReportModifiers/PlaceholderTable';
import { renderSafeValue } from 'utils/formatField';

import { ModifiersByProductType } from './types';
import ModifiersByOption from './ModifiersByOption';
import useStyles from './styles';

interface Props {
  row: ModifiersByProductType;
  t: (value: string) => string;
}

const ModifierProductRow = ({ row, t }: Props) => {
  const classes = useStyles();
  const [openRowProduct, setOpenRowProduct] = useState(false);
  const [openRowModifiers, setOpenRowModifiers] = useState(false);

  const { data, dataStatus, loadModifiersDetail, removeModifiersDetail } = useGetModifiersByProductDetail(row.sku);

  useEffect(() => {
    if (openRowProduct && !data?.data) {
      loadModifiersDetail();
    }
  }, [openRowProduct]);

  useEffect(() => {
    return () => {
      removeModifiersDetail();
    };
  }, []);

  return (
    <>
      <NestedTable.Row bold="700" divider onClick={() => setOpenRowProduct(!openRowProduct)}>
        <NestedTable.Cell align="left" openChevron={openRowProduct} showChevron>
          {renderSafeValue({ value: row?.name, t })}
        </NestedTable.Cell>
        <NestedTable.Cell />
        <NestedTable.Cell />
        <NestedTable.Cell />
        <NestedTable.Cell>
          {renderSafeValue({ value: row?.totalProducts, renderValue: `$${row.totalProducts}`, t })}
          <Tooltip sizeIcon={16} title={t('modifiersReport.tooltips.byProducts.totalSaleRow')}>
            <Tooltip.Help size={16} />
          </Tooltip>
        </NestedTable.Cell>
      </NestedTable.Row>

      <Collapse in={openRowProduct}>
        {dataStatus.fetching && !dataStatus.fetched && <PlaceholderTable.ProductDetail />}

        {dataStatus.fetched && !dataStatus.fetching && (
          <>
            <NestedTable.Row bgColor="white" bold="500" divider>
              <NestedTable.Cell
                align="left"
                className={clsx({ [classes.lineThroughStyle]: data?.data.textStyle === 'line-through' })}
                tab={1}
              >
                {renderSafeValue({ value: data?.data.name, t })}
              </NestedTable.Cell>
              <NestedTable.Cell>{renderSafeValue({ value: data?.data.sku, t })}</NestedTable.Cell>
              <NestedTable.Cell>{renderSafeValue({ value: data?.data.quantity, t })}</NestedTable.Cell>
              <NestedTable.Cell>
                {renderSafeValue({ value: data?.data.averagePrice, renderValue: `$${data?.data.averagePrice}`, t })}
              </NestedTable.Cell>
              <NestedTable.Cell>
                {renderSafeValue({ value: data?.data.totalProducts, renderValue: `$${data?.data.totalProducts}`, t })}
              </NestedTable.Cell>
            </NestedTable.Row>

            <NestedTable.Row bgColor="white" bold="500" divider onClick={() => setOpenRowModifiers(!openRowModifiers)}>
              <NestedTable.Cell
                align="left"
                openChevron={openRowModifiers}
                showChevron={!!data?.data.modifierGroups.modifiers.length}
                tab={1}
              >
                {renderSafeValue({ value: data?.data.modifierGroups.name, t })}
              </NestedTable.Cell>
              <NestedTable.Cell />
              <NestedTable.Cell />
              <NestedTable.Cell />
              <NestedTable.Cell>
                {renderSafeValue({
                  value: data?.data.modifierGroups.totalProducts,
                  renderValue: `$${data?.data.modifierGroups.totalProducts}`,
                  t,
                })}
              </NestedTable.Cell>
            </NestedTable.Row>

            <Collapse in={openRowModifiers}>
              {data?.data.modifierGroups.modifiers?.map((modifier) => (
                <ModifiersByOption key={modifier?.sku} modifier={modifier} t={t} />
              ))}
            </Collapse>
          </>
        )}
      </Collapse>
    </>
  );
};

export default ModifierProductRow;
