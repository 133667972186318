const OPEN_MENU = 'parr-open-menu';

export function setCollapsePrincipalMenuKey(open: boolean) {
  localStorage.setItem(OPEN_MENU, open ? 'true' : 'false');
}

export function getCollapsePrincipalMenuKey() {
  const openMenu = localStorage.getItem(OPEN_MENU);

  if (!openMenu) {
    return true;
  }

  return openMenu === 'true';
}

export function removeCollapsePrincipalMenuKey() {
  localStorage.removeItem(OPEN_MENU);
}
