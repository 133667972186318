import { takeLatest, takeEvery } from 'redux-saga/effects';

import {
  getProducts,
  createProduct,
  toggleProductAvailability,
  duplicateProduct,
  deleteProduct,
  updateProductImage,
  getProductsScrollPagination,
} from '../actions';

import { handleLoadProductsRequest, getProductsScrollPaginationSaga } from './getProductsSaga';
import { handleCreateProductRequest } from './createProductSaga';
import { handleToggleProductAvailabilityRequest } from './toggleProductAvailabilitySaga';
import { handleDuplicateProductRequest } from './duplicateProductSaga';
import { handleDeleteProductRequest } from './deleteProductSaga';
import { handleUpdateProductImageRequest } from './updateProductImage';

export default function* productsSaga() {
  yield takeLatest(getProducts.TRIGGER, handleLoadProductsRequest);
  yield takeLatest(getProductsScrollPagination.TRIGGER, getProductsScrollPaginationSaga);
  yield takeLatest(createProduct.TRIGGER, handleCreateProductRequest);
  yield takeEvery(toggleProductAvailability.TRIGGER, handleToggleProductAvailabilityRequest);
  yield takeLatest(duplicateProduct.TRIGGER, handleDuplicateProductRequest);
  yield takeLatest(deleteProduct.TRIGGER, handleDeleteProductRequest);
  yield takeLatest(updateProductImage.TRIGGER, handleUpdateProductImageRequest);
}
