import { useState } from 'react';
import NestedTable from '@experimental-components/NestedTable';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

import { renderSafeValue } from 'utils/formatField';

import { ModifierType } from './types';
import useStyles from './styles';

interface Props {
  modifier: ModifierType;
  t: (value: string) => string;
}

const ModifiersByOption = ({ modifier, t }: Props) => {
  const classes = useStyles();
  const [openRow, setOpenRow] = useState(false);

  return (
    <>
      <NestedTable.Row bgColor="white" bold="500" color="gray" divider={!openRow} onClick={() => setOpenRow(!openRow)}>
        <NestedTable.Cell
          align="left"
          className={clsx({ [classes.lineThroughStyle]: modifier.textStyle === 'line-through' })}
          openChevron={openRow}
          showChevron
          tab={2}
        >
          {renderSafeValue({ value: modifier.name, t })}
        </NestedTable.Cell>
        <NestedTable.Cell>{renderSafeValue({ value: modifier.sku, t })}</NestedTable.Cell>
        <NestedTable.Cell>{renderSafeValue({ value: modifier.quantity, t })}</NestedTable.Cell>
        <NestedTable.Cell>
          {renderSafeValue({ value: modifier.averagePrice, renderValue: `$${modifier.averagePrice}`, t })}
        </NestedTable.Cell>
        <NestedTable.Cell>
          {renderSafeValue({ value: modifier.totalProducts, renderValue: `$${modifier.totalProducts}`, t })}
        </NestedTable.Cell>
      </NestedTable.Row>

      <Collapse in={openRow}>
        {modifier.options.map((option) => (
          <NestedTable.Row key={option.sku} bgColor="white" color="gray">
            <NestedTable.Cell
              align="left"
              className={clsx({ [classes.lineThroughStyle]: option.textStyle === 'line-through' })}
              tab={3}
            >
              {renderSafeValue({ value: option.name, t })}
            </NestedTable.Cell>
            <NestedTable.Cell>{renderSafeValue({ value: option.sku, t })}</NestedTable.Cell>
            <NestedTable.Cell>{renderSafeValue({ value: option.quantity, t })}</NestedTable.Cell>
            <NestedTable.Cell>
              {renderSafeValue({ value: option.averagePrice, renderValue: `$${option.averagePrice}`, t })}
            </NestedTable.Cell>
            <NestedTable.Cell>
              {renderSafeValue({ value: option.totalProducts, renderValue: `$${option.totalProducts}`, t })}
            </NestedTable.Cell>
          </NestedTable.Row>
        ))}
        <Box borderBottom="1px solid #CECDD1" />
      </Collapse>
    </>
  );
};

export default ModifiersByOption;
