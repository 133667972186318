/**
 *
 * ParrotSoftware
 * OnlineOrderingProvider constants
 *
 */
export const GET_ONLINE_ORDERING = 'providers/onlineOrdering/OnlineOrderingProvider/GET_ONLINE_ORDERING';
export const GET_ONLINE_ORDERING_PAGINATION =
  'providers/onlineOrdering/OnlineOrderingProvider/GET_ONLINE_ORDERING_PAGINATION';
export const UPDATE_CAN_ORDERING = 'providers/onlineOrdering/OnlineOrderingProvider/UPDATE_CAN_ORDERING';
export const GET_BRAND_SETTINGS = 'providers/onlineOrdering/OnlineOrderingProvider/GET_BRAND_SETTINGS';
export const UPDATE_BRAND_SETTINGS = 'providers/onlineOrdering/OnlineOrderingProvider/UPDATE_BRAND_SETTINGS';
export const GET_BRAND_CONTACT_INFORMATION =
  'providers/onlineOrdering/OnlineOrderingProvider/GET_BRAND_CONTACT_INFORMATION';
export const UPDATE_CONTACT_INFORMATION = 'providers/onlineOrdering/OnlineOrderingProvider/UPDATE_CONTACT_INFORMATION';
export const GET_OPERATIONS_SETTINGS = 'providers/onlineOrdering/OnlineOrderingProvider/GET_OPERATIONS_SETTINGS';
export const UPDATE_OPERATIONS_SETTINGS = 'providers/onlineOrdering/OnlineOrderingProvider/UPDATE_OPERATIONS_SETTINGS';
export const GET_DELIVERY_ZONES = 'providers/onlineOrdering/OnlineOrderingProvider/GET_DELIVERY_ZONES';
export const UPDATE_DELIVERY_ZONES = 'providers/onlineOrdering/OnlineOrderingProvider/UPDATE_DELIVERY_ZONES';
export const VALIDATE_DIRECTION_URL = 'providers/onlineOrdering/OnlineOrderingProvider/VALIDATE_DIRECTION_URL';
export const GET_RESUMES_MENUS = 'providers/onlineOrdering/OnlineOrderingProvider/GET_RESUMES_MENUS';
export const SAVE_ONBOARDING = 'providers/onlineOrdering/OnlineOrderingProvider/SAVE_ONBOARDING';
export const CREATE_DELIVERY_ZONES = 'providers/onlineOrdering/OnlineOrderingProvider/CREATE_DELIVERY_ZONES';
export const DELETE_DELIVERY_ZONES = 'providers/onlineOrdering/OnlineOrderingProvider/DELETE_DELIVERY_ZONES';
export const PUBLISH_BRAND = 'providers/onlineOrdering/OnlineOrderingProvider/PUBLISH_BRAND';
