import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getListReportsOrders,
  exportReportsOrders,
  getListReportsOrdersScrollPagination,
  getDetailsReportsOrders,
} from './actions';
import {
  selectReportsOrdersSummaries,
  selectReportsOrdersHeaders,
  selectReportsOrdersOrders,
  selectReportsOrdersState,
  selectExportReportsOrdersDocument,
  selectExportReportsOrdersState,
  selectReportsOrdersPaginationInfo,
  selectReportsOrdersPaginationScrollState,
  selectDetailsReportsOrder,
  selectDetailsReportsOrdersState,
} from './selectors';

export function withReportsOrdersHoc(Component) {
  function withReportsOrders(props) {
    return <Component {...props} />;
  }

  withReportsOrders.displayName = `withReportsOrders(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withReportsOrders;
}

const mapStateToProps = createStructuredSelector({
  reportsOrdersSummaries: selectReportsOrdersSummaries,
  reportsOrdersHeaders: selectReportsOrdersHeaders,
  reportsOrdersOrders: selectReportsOrdersOrders,
  reportsOrdersState: selectReportsOrdersState,
  exportReportsOrdersDocument: selectExportReportsOrdersDocument,
  exportReportsOrdersState: selectExportReportsOrdersState,
  reportsOrdersPaginationInfo: selectReportsOrdersPaginationInfo,
  reportsOrdersPaginationScrollState: selectReportsOrdersPaginationScrollState,
  detailsReportsOrder: selectDetailsReportsOrder,
  detailsReportsOrdersState: selectDetailsReportsOrdersState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadListReportsOrders: (filters = {}) => dispatch(getListReportsOrders(filters)),
    loadListReportsOrdersScrollPagination: (filters = {}) => dispatch(getListReportsOrdersScrollPagination(filters)),
    loadDetailsReportsOrders: (orderUuid, isEdited = false) =>
      dispatch(getDetailsReportsOrders({ orderUuid, isEdited })),
    exportReportsOrders: (filters = {}) => dispatch(exportReportsOrders(filters)),
    resetListReportsOrders: () => dispatch(getListReportsOrders.reset()),
    resetExportReportsOrders: () => dispatch(exportReportsOrders.reset()),
    resetReportsOrdersScrollPagination: () => dispatch(getListReportsOrdersScrollPagination.reset()),
    resetDetailsReportsOrders: () => dispatch(getDetailsReportsOrders.reset()),
  };
}

export const withReportsOrdersState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withReportsOrdersState, withReportsOrdersHoc);
