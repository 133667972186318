/* eslint-disable react/no-array-index-key */
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

import Table from '@experimental-components/Table';
import Dialog from '@experimental-components/Dialog';
import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonOutlined from '@design-system/ButtonOutlined';

import { useGetSkuList } from 'providers/menuMaker/Sku/hooks';

import { skuLabelsDefinition, excludeItemValidateSku } from 'utils/menuMaker';

import { getErrorCode, errorsCodeTypes } from 'utils/parrotErrorCode';

import SkeletonList from './SkeletonList';
import useStyles from './styles';

interface PropsModal {
  actionState: { fetching: boolean; fetched: boolean };
  onAccept: () => void;
  onClose: () => void;
  sku: string;
  t: (value: string) => string;
  type: string;
  itemUuid: string | null;
}

function ModalValidateUsedSku({ actionState, type, t, sku, onClose, onAccept, itemUuid }: PropsModal) {
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();
  const scrollPaginationRef = useRef(null);
  const skuLabels = skuLabelsDefinition[type];
  const { skuList, loadSkuList, skuListState, paginateSkuList, resetSkuList } = useGetSkuList(type, sku, true);
  const loading = skuListState.fetching && !skuListState.isFetchingNextPage;

  useEffect(() => {
    loadSkuList();

    return () => {
      resetSkuList();
    };
  }, []);

  useEffect(() => {
    if (
      actionState.fetched ||
      (actionState.error && getErrorCode(actionState?.error, errorsCodeTypes.PRODUCT_BARCODE_DUPLICATED))
    ) {
      onClose();
    }
  }, [actionState.fetched, actionState.error]);

  useEffect(() => {
    if (skuListState.fetched) {
      const validateItemsSku = excludeItemValidateSku(skuList[0]?.data, itemUuid);
      if (validateItemsSku?.length) {
        setOpenDialog(true);
      } else {
        onAccept();
        onClose();
      }
    }
  }, [skuListState.fetched, skuList]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && skuListState.hasNextPage) {
          paginateSkuList();
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };
  }, [scrollPaginationRef, skuListState.hasNextPage, skuList]);

  const renderBody = () => {
    if (loading) {
      return <SkeletonList />;
    }

    return skuList?.map((group, i) => (
      <React.Fragment key={i}>
        {excludeItemValidateSku(group?.data, itemUuid)?.map((store) => (
          <Table.Row key={store?.uuid} className={classes.cell}>
            <Table.Cell align="center" isBorder={false}>
              <Typography variant="subtitle2">{store?.name}</Typography>
            </Table.Cell>

            <Table.BigCell align="center" isBorder={false}>
              {store?.items?.length ? (
                <Table.BigCell.ExpandableList
                  defaultItems={3}
                  list={store.items.map((item) => (
                    <Table.BigCell.Option key={item.uuid} className={clsx({ [classes.itemRemoved]: item?.removed })}>
                      {item?.name}
                    </Table.BigCell.Option>
                  ))}
                />
              ) : (
                t('common:notAvailableDefault')
              )}
            </Table.BigCell>
          </Table.Row>
        ))}
      </React.Fragment>
    ));
  };

  return (
    <Dialog isOpen={openDialog}>
      <Dialog.Head subtitle={t(skuLabels.existingSkuInfo)} title={t(skuLabels.existingSku)} />

      <Dialog.Content className={classes.wrapperContent}>
        <>
          <Table>
            <Table.Head>
              <Table.Row className={classes.headCell}>
                <Table.HeadCell align="center">{t('menuMaker:skuLabels.store')}</Table.HeadCell>

                <Table.HeadCell align="center">{t(skuLabels.optionsLabel)}</Table.HeadCell>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {renderBody()}
              {skuListState.isFetchingNextPage && <SkeletonList />}
            </Table.Body>
          </Table>

          <Box ref={scrollPaginationRef} mb={1} />
        </>
      </Dialog.Content>

      <Dialog.Actions>
        <ButtonOutlined className={classes.actionButton} disabled={loading || actionState.fetching} onClick={onClose}>
          {t('common:buttons.cancel')}
        </ButtonOutlined>

        <ButtonPrimary
          className={classes.actionButton}
          disabled={loading || actionState.fetching}
          loading={actionState.fetching}
          onClick={onAccept}
        >
          {t('common:buttons.accept')}
        </ButtonPrimary>
      </Dialog.Actions>
    </Dialog>
  );
}

export default ModalValidateUsedSku;
