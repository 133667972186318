import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'providers/root/AuthProvider';
import { datadogLogs } from '@datadog/browser-logs';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import SignInLayout from 'components/SignInLayout';

import { ReactComponent as ParrotLogo } from 'images/svg/brand/parrot_connect.svg';

import { removeStoreDratfKey, removeStoreBackupKey } from 'utils/auth';
import useFiltersReports from 'hooks/useFiltersReports';

import useStyles from './styles';

export function LoggedOutPage({ t }) {
  const classes = useStyles();
  const history = useHistory();

  const { clearLicenseStateChannel } = useAuth();
  const { removeFilters } = useFiltersReports();

  useEffect(() => {
    datadogLogs.clearGlobalContext();
    datadogLogs.clearUser();
    removeStoreDratfKey();
    removeStoreBackupKey();
    clearLicenseStateChannel();
    removeFilters();
  }, []);

  function handleSignIn() {
    history.push('/signin');
  }

  return (
    <SignInLayout>
      <div className={classes.header}>
        <ParrotLogo className={classes.parrotS} />
      </div>

      <Box mb={2} mt={4}>
        <Typography gutterBottom variant="h6">
          {t('signIn:loggedOut.heading')}
        </Typography>
      </Box>

      <Box mb={2} width="80%">
        <Typography align="center" color="textSecondary" variant="subtitle1">
          {t('signIn:loggedOut.message')}
        </Typography>
      </Box>

      <Button color="primary" onClick={handleSignIn} size="large" variant="contained">
        {t('signIn:buttons.signIn')}
      </Button>
    </SignInLayout>
  );
}

LoggedOutPage.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('signIn')(LoggedOutPage);
