import { useState, useMemo, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFeatures } from 'FeatureProvider';
import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import Box from '@material-ui/core/Box';

import TopNavigationBar from '@experimental-components/TopNavigationBar';
import { NavigationType } from '@experimental-components/TopNavigationBar/types';

import { useUser } from 'containers/Users';

import { StoreProps } from 'hooks/navigation/interfaces';
import useUserStore from 'hooks/useUserStore';
import useOrganization from 'hooks/Organization/useOrganization';
import useNavigation from 'hooks/navigation/useNavigation';
import useLicenseState from 'hooks/useLicenseState';
import useMenuMakerDrawerState from 'hooks/useMenuMakerDrawerState';
import history from 'utils/history';
import { getValidateItemsAvailabilityFlags } from 'utils/featureFlags/menus';

import Button from './ButtonNav';
import BannerErrorPublishMenus from './BannerErrorPublishMenus';

import { getTitle, getLabelButton, relocatePage } from './utils';
import useStyles from './styles';

function TopNavigationBarStores() {
  const classes = useStyles();
  const { t } = useTranslation(['menuMaker']);
  const location = useLocation();
  const { logEvent } = useAmplitude();

  const { remove: resetUser, refetch: loadGetUser } = useUser();

  const {
    storeState: { stores = [], storeDraft, selected: storeSelected },
    setStore,
    storeBackup,
    setStoreBackup,
  } = useUserStore();
  const { organization } = useOrganization();
  const { modeDraft, setModeDraft, isPublishMenuError } = useSidebarLayout();
  const { licenseStatus, paymentInvalid, licenseInvalid } = useLicenseState();
  const { drawerIsDirty } = useMenuMakerDrawerState();

  const { flags } = useFeatures();
  const { enableValidateItemsAvailability } = getValidateItemsAvailabilityFlags(flags);

  const [wasClicked, setWasClicked] = useState<boolean>(false);
  const [elMenu, setMenu] = useState<HTMLElement | null>(null);
  const {
    currentNavigation: typeNavigation,
    setNavigation,
    reloadStoreOnClose,
    setReloadStoreOnClose,
    changeLocationState,
    setChangeLocationState,
    goToMenuDraft,
    setGoToMenuDraft,
  } = useNavigation();
  const [stateChangeStoreBackup, setStateChangeStoreBackup] = useState<string>('');

  useEffect(() => {
    if (storeSelected?.isDraft) {
      setNavigation(NavigationType.STORE_DRAFT);
      setModeDraft(true);
    }
  }, []);

  useEffect(() => {
    if (!storeSelected?.isDraft && !goToMenuDraft && changeLocationState && reloadStoreOnClose) {
      setReloadStoreOnClose(false);
      setChangeLocationState(false);
      handleReloadInformationStore(stateChangeStoreBackup);
    }

    if (!storeSelected?.isDraft && goToMenuDraft && changeLocationState && reloadStoreOnClose) {
      setReloadStoreOnClose(false);
      setChangeLocationState(false);
      handleLoadNavigationStoreDraft();
    }

    if (storeSelected?.isDraft && changeLocationState && reloadStoreOnClose) {
      setReloadStoreOnClose(false);
      setChangeLocationState(false);
      handleLoadNavigationStore();
    }
  }, [changeLocationState, reloadStoreOnClose]);

  const title = useMemo(() => {
    let level = '';
    if (typeNavigation === NavigationType.ORGANIZATION) {
      level = organization.name;
    }

    if (typeNavigation === NavigationType.STORE_DRAFT) {
      level = t('common:topNavigarionBar.menuStoreDraft');
    }
    return getTitle(typeNavigation, level, storeSelected?.name);
  }, [storeSelected, typeNavigation]);

  const showChevron = useMemo(() => {
    return typeNavigation === NavigationType.STORE && stores.length > 1;
  }, [typeNavigation, stores]);

  const showButtonOrganization = useMemo(() => {
    const isPathMenu = location.pathname.startsWith('/menus');
    return storeDraft && isPathMenu;
  }, [storeDraft, location]);

  const showGoToStoresLicenseInvalid = useMemo(() => {
    const isPathSubscription = location.pathname.startsWith('/subscription');
    return storeSelected?.isDraft && isPathSubscription && (paymentInvalid || licenseInvalid);
  }, [storeSelected, location]);

  function handleChevronClick(element: HTMLElement) {
    setMenu(element);
    setWasClicked(!wasClicked);
  }

  function onCloseList() {
    setWasClicked(false);
  }

  function handleNavigationButtonOrganization() {
    if (location.pathname.startsWith('/menus')) {
      handleGoToStoreDraft();
    }
  }

  function handleOnSelectedStore(values: string) {
    if (storeSelected?.uuid !== values) {
      relocatePage(location, history.replace);
      onCloseList();
      if (drawerIsDirty) {
        setChangeLocationState(true);
        return setStateChangeStoreBackup(values);
      }

      handleReloadInformationStore(values);
    } else {
      onCloseList();
    }
  }

  const handleReloadInformationStore = (values: string) => {
    const changeStore = stores.find((store: StoreProps) => store.uuid === values);
    setStoreBackup(changeStore);
    setStore(changeStore);
    logEvent('@event.$store.viewStore', '', {
      store: changeStore?.uuid,
      isDraft: changeStore?.isDraft,
      license: licenseStatus,
    });

    loadGetUser();
    resetUser();
  };

  function handleGoToStoreDraft() {
    history.replace({ search: '' });

    if (typeNavigation === NavigationType.STORE_DRAFT) {
      if (drawerIsDirty) {
        return setChangeLocationState(true);
      }
      return handleLoadNavigationStore();
    }

    if (typeNavigation === NavigationType.STORE) {
      if (drawerIsDirty) {
        setGoToMenuDraft(true);
        return setChangeLocationState(true);
      }
      handleLoadNavigationStoreDraft();
    }
  }

  function handleLoadNavigationStore() {
    const changeStore = storeBackup ?? stores[0];
    setModeDraft(false);
    setStore(changeStore);
    setNavigation(NavigationType.STORE);
    logEvent('@event.$store.viewStore', '', {
      store: changeStore?.uuid,
      isDraft: changeStore?.isDraft,
      license: licenseStatus,
    });
    resetUser();
  }

  function handleLoadNavigationStoreDraft() {
    setStoreBackup(storeSelected);
    setModeDraft(true);
    setNavigation(NavigationType.STORE_DRAFT);
    setStore(storeDraft);
    logEvent('@event.$store.viewStore', '', {
      store: storeDraft?.uuid,
      isDraft: storeDraft?.isDraft,
      license: licenseStatus,
    });
    resetUser();
  }

  return (
    <>
      <TopNavigationBar
        chevronDown={wasClicked}
        onClickChevron={handleChevronClick}
        showChevron={showChevron}
        title={title}
        type={typeNavigation}
      >
        <Box className={classes.buttonSection}>
          {(showButtonOrganization || showGoToStoresLicenseInvalid) && (
            <Button onClick={handleNavigationButtonOrganization}>{t(getLabelButton(typeNavigation))}</Button>
          )}
        </Box>
      </TopNavigationBar>

      {modeDraft && <Box className={classes.messageModeDraft}>{t('menuMaker:messages.modeDraft')}</Box>}

      {enableValidateItemsAvailability && isPublishMenuError && !modeDraft && <BannerErrorPublishMenus t={t} />}

      <TopNavigationBar.List anchorEl={elMenu} onClose={onCloseList} open={wasClicked}>
        {stores.map((store: StoreProps) => (
          <TopNavigationBar.ListItem
            key={store.uuid}
            onClick={handleOnSelectedStore}
            text={store.name}
            value={store.uuid}
          />
        ))}
      </TopNavigationBar.List>
    </>
  );
}

export default TopNavigationBarStores;
