import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { getAuth, signOut as firebaseSignOut } from 'firebase/auth';

import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import SignInLayout from 'components/SignInLayout';

import { ReactComponent as IconParrotPOS } from 'images/svg/brand/parrot_connect_large.svg';

import {
  removeAuthToken,
  removeUserStoresStoreKey,
  removeUserCurrentStore,
  removeStoreDratfKey,
  removeStoreBackupKey,
  removeOrganizationUser,
  removeCookieUser,
} from 'utils/auth';
import { removeBrandSelectedLocalStorage } from 'utils/menuMaker';

import useStyles from './styles';

function SignOut() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { logEvent } = useAmplitude();
  const auth = getAuth();

  useEffect(() => {
    datadogLogs.clearGlobalContext();
    datadogLogs.clearUser();
    logEvent('@event.$login.logout');
    removeAuthToken();
    removeUserStoresStoreKey();
    removeUserCurrentStore();
    removeStoreDratfKey();
    removeStoreBackupKey();
    removeBrandSelectedLocalStorage();
    removeOrganizationUser();
    removeCookieUser();
    firebaseSignOut(auth);
    history.push('/signin');
  });

  return (
    <SignInLayout>
      <div className={classes.header}>
        <IconParrotPOS className={classes.parrotS} />
      </div>

      <Box mb={2} mt={4}>
        <Typography gutterBottom variant="h6">
          {t('common:auth.signingOut')}
        </Typography>
      </Box>

      <Box mb={2} textAlign="center" width="80%">
        <CircularProgress />
      </Box>
    </SignInLayout>
  );
}

export default SignOut;
