import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  backgroundColor?: string;
  colorInherit?: string;
  size?: string | number;
}
const Information = forwardRef(function Information(props: Props, ref: Ref<SVGSVGElement>) {
  const { backgroundColor = '#44AEC9', size = 22, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 22 22"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <circle cx="11" cy="11" fill={backgroundColor} r="11" />
      <path
        clipRule="evenodd"
        d="M11.5 16.5L11.5 8.96403L10.3 8.96402L10.3 16.5L11.5 16.5ZM11.5 7.45684L11.5 5.94965L10.3 5.94965L10.3 7.45684L11.5 7.45684Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
});

export default Information;
