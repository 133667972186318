import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import { GET_MENU_PREVIEW, GET_MENU_CATEGORY_PRODUCTS, GET_MENU_PRODUCT_DETAILS, EXPORT_MENU } from './constants';

export const getMenuPreview = extendRoutine(createRoutine(GET_MENU_PREVIEW), 'RESET');
export const getMenuCategoryProducts = extendRoutine(createRoutine(GET_MENU_CATEGORY_PRODUCTS), 'RESET');
export const getMenuProductDetails = extendRoutine(createRoutine(GET_MENU_PRODUCT_DETAILS), 'RESET');
export const exportMenu = extendRoutine(createRoutine(EXPORT_MENU), 'RESET');
