import React from 'react';

import Box, { BoxProps } from '@material-ui/core/Box';

import ContentHours from './ContentHours';
import useStyles from './styles';

interface Props extends BoxProps {
  children: React.ReactNode;
}

function ContentFiltersReports({ children, ...others }: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.root} {...others}>
      {children}
    </Box>
  );
}

ContentFiltersReports.Hours = ContentHours;

export default ContentFiltersReports;
