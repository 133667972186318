import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toggleButtonGroup: {
    '& > button': {
      width: theme.typography.pxToRem(88),
      height: theme.typography.pxToRem(54),
    },
    '& > :not(:first-child)': {
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      marginLeft: theme.spacing(3),
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },

    '& > :not(:last-child)': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  toggleButton: {
    padding: 20,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.darkGrey.main,
  },
}));

export default useStyles;
