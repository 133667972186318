import { Switch } from 'react-router-dom';

import { useFeature } from 'FeatureProvider';

import Products from 'containers/Products';
import MenuMaker from 'containers/MenuMaker';
import Categories from 'containers/Categories';
import ModifierGroups from 'containers/ModifierGroups';
import MenuMakerResume from 'containers/MenuMakerResume';
import PublishMenuMaker from 'containers/MenuMakerPublish';
import MenuMakerPreview from 'containers/MenuMakerPreview';

import useUserStore from 'hooks/useUserStore';

import AppRoute from '../PrivateRoute';

export function MenuMakerRoutes() {
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();
  const superUserAccess = useFeature('adminStore');
  const menuMakerModuleAccess = useFeature('backofficeModuleMenu');
  const permissionMenuMakerModule = superUserAccess || menuMakerModuleAccess;

  return (
    <Switch>
      <AppRoute accessRoute={permissionMenuMakerModule} component={MenuMaker} exact path="/menus" />
      <AppRoute accessRoute={permissionMenuMakerModule} component={Categories} exact path="/menus/categories" />
      <AppRoute accessRoute={permissionMenuMakerModule} component={Products} exact path="/menus/products" />
      <AppRoute
        accessRoute={permissionMenuMakerModule}
        component={ModifierGroups}
        exact
        path="/menus/modifier-groups"
      />
      <AppRoute
        accessRoute={permissionMenuMakerModule && !storeSelected?.isDraft}
        component={PublishMenuMaker}
        exact
        path="/menus/publish"
      />

      <AppRoute
        accessRoute={permissionMenuMakerModule}
        component={MenuMakerPreview}
        exact
        path="/menus/preview/:menuUuid"
      />

      <AppRoute accessRoute={permissionMenuMakerModule} component={MenuMakerResume} exact path="/menus/resume" />
    </Switch>
  );
}
