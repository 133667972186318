import produce from 'immer';

import { sortingDirection } from 'utils/reports/tables';

import { getAllInvoicesUninvoicedOrders, getAllInvoicesUninvoicedOrdersPagination, generateInvoicing } from './actions';

const baseState = {
  error: null,
  fetching: false,
  fetched: false,
};

export const initialState = {
  getAllInvoicesUninvoicedOrders: {
    ...baseState,
    invoices: {},
    pagination: {
      page: 1,
      page_size: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  sorting: {
    columnSelected: 0,
    directionSelected: 0,
    direction: sortingDirection.ASC,
  },

  paginationScrollInvoicesState: {
    ...baseState,
    lastUpdatedRows: [],
  },

  generateInvoicing: {
    ...baseState,
    data: null,
  },
};

const InvoicesGlobalProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getAllInvoicesUninvoicedOrders.REQUEST:
        draft.getAllInvoicesUninvoicedOrders = {
          ...initialState.getAllInvoicesUninvoicedOrders,
          fetching: true,
        };
        break;

      case getAllInvoicesUninvoicedOrders.SUCCESS:
        draft.getAllInvoicesUninvoicedOrders.fetching = false;
        draft.getAllInvoicesUninvoicedOrders.fetched = true;
        draft.getAllInvoicesUninvoicedOrders.invoices = payload.data;
        draft.getAllInvoicesUninvoicedOrders.pagination = {
          ...initialState.getAllInvoicesUninvoicedOrders.pagination,
          ...payload.pagination,
        };

        break;

      case getAllInvoicesUninvoicedOrders.FAILURE:
        draft.getAllInvoicesUninvoicedOrders.error = payload;
        draft.getAllInvoicesUninvoicedOrders.fetching = false;
        draft.getAllInvoicesUninvoicedOrders.fetched = false;
        break;

      case getAllInvoicesUninvoicedOrders.RESET:
        draft.getAllInvoicesUninvoicedOrders = {
          ...initialState.getAllInvoicesUninvoicedOrders,
        };
        break;

      case getAllInvoicesUninvoicedOrdersPagination.REQUEST:
        draft.paginationScrollInvoicesState = {
          ...initialState.paginationScrollInvoicesState,
          fetching: true,
        };
        break;

      case getAllInvoicesUninvoicedOrdersPagination.SUCCESS: {
        const currentInvoicesState = state.getAllInvoicesUninvoicedOrders.invoices?.rows;
        const newRowsPayload = payload?.data?.rows;

        let newsRows = [...currentInvoicesState];
        newsRows = [...newsRows, ...newRowsPayload];

        draft.paginationScrollInvoicesState.fetching = false;
        draft.paginationScrollInvoicesState.fetched = true;
        draft.paginationScrollInvoicesState.error = null;
        draft.paginationScrollInvoicesState.lastUpdatedRows = newRowsPayload;

        draft.getAllInvoicesUninvoicedOrders.invoices.rows = [...newsRows];
        draft.getAllInvoicesUninvoicedOrders.pagination = {
          ...initialState.getAllInvoicesUninvoicedOrders.pagination,
          ...payload?.pagination,
          page: payload?.page,
        };

        break;
      }

      case getAllInvoicesUninvoicedOrdersPagination.FAILURE:
        draft.paginationScrollInvoicesState.error = payload;
        draft.paginationScrollInvoicesState.fetching = false;
        draft.paginationScrollInvoicesState.fetched = false;
        break;

      case getAllInvoicesUninvoicedOrders.SORTING: {
        draft.sorting = {
          ...initialState.sorting,
          ...payload,
        };

        break;
      }

      case getAllInvoicesUninvoicedOrdersPagination.RESET:
        draft.paginationScrollInvoicesState = {
          ...initialState.paginationScrollInvoicesState,
        };
        break;

      case generateInvoicing.REQUEST:
        draft.generateInvoicing = {
          ...initialState.generateInvoicing,
          fetching: true,
        };
        break;

      case generateInvoicing.SUCCESS: {
        const currentInvoicesState = state.getAllInvoicesUninvoicedOrders.invoices?.rows;
        const newsRows = currentInvoicesState?.filter((item) => !payload?.subtractOrders?.includes(item?.uuid));

        draft.getAllInvoicesUninvoicedOrders.invoices.rows = [...newsRows];
        draft.generateInvoicing.fetching = false;
        draft.generateInvoicing.fetched = true;
        draft.generateInvoicing.data = payload?.data;

        break;
      }

      case generateInvoicing.FAILURE:
        draft.generateInvoicing.error = payload;
        draft.generateInvoicing.fetching = false;
        draft.generateInvoicing.fetched = false;
        break;

      case generateInvoicing.RESET:
        draft.generateInvoicing = {
          ...initialState.generateInvoicing,
        };
        break;

      default:
        return draft;
    }
  });

export default InvoicesGlobalProviderReducer;
