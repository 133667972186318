import produce from 'immer';

import baseState from 'utils/baseState';
import { sortingDirection } from 'utils/reports/tables';

import {
  getReportsDiscountsResume,
  getReportsDiscountsResumeByPagination,
  getReportsDiscountsResumeBySorting,
  getReportsDiscountsDetails,
  getReportsDiscountsDetailsByPagination,
  getReportsDiscountsDetailsBySorting,
  exportReportsDiscounts,
} from './actions';

export const initialState = {
  getReportsDiscountsResume: {
    ...baseState,
    resume: {},
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },
  loadPaginationResumeStatus: {
    ...baseState,
    lastUpdatedRows: {},
  },
  sortingResumeStatus: {
    ...baseState,
    columnSelected: 0,
    columnDirection: sortingDirection.ASC,
    lastSortingRows: {},
  },

  getReportsDiscountsDetails: {
    ...baseState,
    discounts: {},
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },
  loadPaginationDetailsStatus: {
    ...baseState,
    lastUpdatedRows: {},
  },
  sortingDetailsStatus: {
    ...baseState,
    columnSelected: 0,
    columnDirection: sortingDirection.ASC,
    lastSortingRows: {},
  },

  exportReportsDiscounts: {
    ...baseState,
    document: null,
  },
};

const reportsDiscountsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getReportsDiscountsResume.REQUEST:
        draft.getReportsDiscountsResume = {
          ...initialState.getReportsDiscountsResume,
          fetching: true,
        };
        break;

      case getReportsDiscountsResume.SUCCESS:
        draft.getReportsDiscountsResume.fetching = false;
        draft.getReportsDiscountsResume.fetched = true;
        draft.getReportsDiscountsResume.resume = payload?.resume;
        draft.getReportsDiscountsResume.pagination = {
          ...initialState.getReportsDiscountsResume.pagination,
          ...payload.pagination,
        };
        break;

      case getReportsDiscountsResume.FAILURE:
        draft.getReportsDiscountsResume.error = payload;
        draft.getReportsDiscountsResume.fetching = false;
        draft.getReportsDiscountsResume.fetched = false;
        break;

      case getReportsDiscountsResume.RESET:
        draft.getReportsDiscountsResume = {
          ...initialState.getReportsDiscountsResume,
        };
        break;

      // pagination resume
      case getReportsDiscountsResumeByPagination.REQUEST:
        draft.loadPaginationResumeStatus = {
          ...initialState.loadPaginationResumeStatus,
          fetching: true,
        };
        break;

      case getReportsDiscountsResumeByPagination.SUCCESS: {
        const currentReportsDiscountsResumePaginationState = draft.getReportsDiscountsResume;
        let newsRows = [...currentReportsDiscountsResumePaginationState.resume?.rows];
        newsRows = [...newsRows, ...payload?.resume?.rows];

        draft.getReportsDiscountsResume = {
          ...currentReportsDiscountsResumePaginationState,
          resume: { ...draft.getReportsDiscountsResume.resume, rows: [...newsRows] },
          pagination: { ...currentReportsDiscountsResumePaginationState?.pagination, ...payload?.pagination },
        };

        draft.loadPaginationResumeStatus = {
          ...initialState.loadPaginationResumeStatus,
          fetching: false,
          fetched: true,
          lastUpdatedRows: [...payload?.resume?.rows],
        };
        break;
      }

      case getReportsDiscountsResumeByPagination.FAILURE:
        draft.loadPaginationResumeStatus = {
          ...initialState.loadPaginationResumeStatus,
          error: payload,
          fetching: false,
        };
        break;

      case getReportsDiscountsResumeByPagination.RESET:
        draft.loadPaginationResumeStatus = {
          ...initialState.loadPaginationResumeStatus,
        };
        break;

      // sorting resume
      case getReportsDiscountsResumeBySorting.REQUEST: {
        const currentReportsDiscountsResumeSortingState = draft.getReportsDiscountsResume;
        draft.getReportsDiscountsResume = {
          ...currentReportsDiscountsResumeSortingState,
          pagination: {
            ...initialState.getReportsDiscountsResume.pagination,
          },
        };

        draft.sortingResumeStatus = {
          ...draft.sortingResumeStatus,
          fetching: true,
          fetched: false,
          error: null,
        };

        break;
      }

      case getReportsDiscountsResumeBySorting.SUCCESS: {
        const currentReportsDiscountsResumeSortingState = draft.getReportsDiscountsResume;
        draft.getReportsDiscountsResume = {
          ...currentReportsDiscountsResumeSortingState,
          resume: payload?.resume,
          pagination: { ...currentReportsDiscountsResumeSortingState.pagination, ...payload?.pagination },
        };

        draft.sortingResumeStatus = {
          ...draft.sortingResumeStatus,
          fetching: false,
          fetched: true,
          lastSortingRows: payload?.resume?.rows,
        };
        break;
      }

      case getReportsDiscountsResumeBySorting.SORTING:
        draft.sortingResumeStatus = {
          ...draft.sortingResumeStatus,
          fetched: false,
          columnSelected: payload.selected,
          columnDirection: payload.direction,
        };
        break;

      case getReportsDiscountsResumeBySorting.RESET_SORTING:
        draft.sortingResumeStatus = {
          ...initialState.sortingResumeStatus,
        };
        break;

      case getReportsDiscountsResumeBySorting.FAILURE:
        draft.sortingResumeStatus = {
          ...draft.sortingResumeStatus,
          error: payload,
          fetching: false,
          fetched: false,
        };

        break;

      case getReportsDiscountsResumeBySorting.RESET:
        draft.sortingResumeStatus = {
          ...initialState.sortingResumeStatus,
        };
        break;

      // report detailed
      case getReportsDiscountsDetails.REQUEST:
        draft.getReportsDiscountsDetails = {
          ...initialState.getReportsDiscountsDetails,
          fetching: true,
        };
        break;

      case getReportsDiscountsDetails.SUCCESS:
        draft.getReportsDiscountsDetails.fetching = false;
        draft.getReportsDiscountsDetails.fetched = true;
        draft.getReportsDiscountsDetails.discounts = payload?.discounts;
        draft.getReportsDiscountsDetails.pagination = {
          ...initialState.getReportsDiscountsDetails.pagination,
          ...payload.pagination,
        };
        break;

      case getReportsDiscountsDetails.FAILURE:
        draft.getReportsDiscountsDetails.error = payload;
        draft.getReportsDiscountsDetails.fetching = false;
        draft.getReportsDiscountsDetails.fetched = false;
        break;

      case getReportsDiscountsDetails.RESET:
        draft.getReportsDiscountsDetails = {
          ...initialState.getReportsDiscountsDetails,
        };
        break;

      // pagination detailed
      case getReportsDiscountsDetailsByPagination.REQUEST:
        draft.loadPaginationDetailsStatus = {
          ...initialState.loadPaginationDetailsStatus,
          fetching: true,
        };
        break;

      case getReportsDiscountsDetailsByPagination.SUCCESS: {
        const currentReportsDiscountsDetailsPaginationState = draft.getReportsDiscountsDetails;
        let newsRows = [...currentReportsDiscountsDetailsPaginationState.discounts?.rows];
        newsRows = [...newsRows, ...payload?.discounts?.rows];

        draft.getReportsDiscountsDetails = {
          ...currentReportsDiscountsDetailsPaginationState,
          discounts: { ...draft.getReportsDiscountsDetails.discounts, rows: [...newsRows] },
          pagination: { ...currentReportsDiscountsDetailsPaginationState?.pagination, ...payload.pagination },
        };

        draft.loadPaginationDetailsStatus = {
          ...initialState.loadPaginationDetailsStatus,
          fetching: false,
          fetched: true,
          lastUpdatedRows: [...payload?.discounts?.rows],
        };
        break;
      }

      case getReportsDiscountsDetailsByPagination.FAILURE:
        draft.loadPaginationDetailsStatus = {
          ...initialState.loadPaginationDetailsStatus,
          error: payload,
          fetching: false,
        };
        break;

      case getReportsDiscountsDetailsByPagination.RESET:
        draft.loadPaginationDetailsStatus = {
          ...initialState.loadPaginationDetailsStatus,
        };
        break;

      // sorting detailed
      case getReportsDiscountsDetailsBySorting.REQUEST: {
        const currentReportsDiscountsDetailsSortingState = draft.getReportsDiscountsDetails;
        draft.getReportsDiscountsDetails = {
          ...currentReportsDiscountsDetailsSortingState,
          pagination: {
            ...initialState.getReportsDiscountsDetails.pagination,
          },
        };

        draft.sortingDetailsStatus = {
          ...draft.sortingDetailsStatus,
          fetching: true,
          fetched: false,
          error: null,
        };

        break;
      }

      case getReportsDiscountsDetailsBySorting.SUCCESS: {
        const currentReportsDiscountsDetailsSortingState = draft.getReportsDiscountsDetails;
        draft.getReportsDiscountsDetails = {
          ...currentReportsDiscountsDetailsSortingState,
          discounts: payload?.discounts,
          pagination: { ...currentReportsDiscountsDetailsSortingState.pagination, ...payload?.pagination },
        };

        draft.sortingDetailsStatus = {
          ...draft.sortingDetailsStatus,
          fetching: false,
          fetched: true,
          lastSortingRows: payload?.discounts?.rows,
        };
        break;
      }

      case getReportsDiscountsDetailsBySorting.SORTING:
        draft.sortingDetailsStatus = {
          ...draft.sortingDetailsStatus,
          fetched: false,
          columnSelected: payload.selected,
          columnDirection: payload.direction,
        };
        break;

      case getReportsDiscountsDetailsBySorting.RESET_SORTING:
        draft.sortingDetailsStatus = {
          ...initialState.sortingDetailsStatus,
        };
        break;

      case getReportsDiscountsDetailsBySorting.FAILURE:
        draft.sortingDetailsStatus = {
          ...draft.sortingDetailsStatus,
          error: payload,
          fetching: false,
          fetched: false,
        };

        break;

      case getReportsDiscountsDetailsBySorting.RESET:
        draft.sortingDetailsStatus = {
          ...initialState.sortingDetailsStatus,
        };
        break;

      // export file excel
      case exportReportsDiscounts.REQUEST:
        draft.exportReportsDiscounts = {
          ...initialState.exportReportsDiscounts,
          fetching: true,
        };
        break;

      case exportReportsDiscounts.SUCCESS:
        draft.exportReportsDiscounts.fetching = false;
        draft.exportReportsDiscounts.fetched = true;
        draft.exportReportsDiscounts.document = payload;

        break;

      case exportReportsDiscounts.FAILURE:
        draft.exportReportsDiscounts.error = payload;
        draft.exportReportsDiscounts.fetching = false;
        draft.exportReportsDiscounts.fetched = false;
        break;

      case exportReportsDiscounts.RESET:
        draft.exportReportsDiscounts = {
          ...initialState.exportReportsDiscounts,
        };
        break;

      default:
        return draft;
    }
  });

export default reportsDiscountsReducer;
