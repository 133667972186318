import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

export function DiningOptions({ children }) {
  useInjectSaga({ key: 'settingsDiningOptions', saga });
  useInjectReducer({ key: 'settingsDiningOptions', reducer });

  return React.Children.only(children);
}

DiningOptions.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withDiningOptions } from './withDiningOptions';
export default compose(withConnect, memo)(DiningOptions);
