import produce from 'immer';

import { cancelInvoicing, downloadInvoices, getReportsGenerated, getReportsGeneratedPagination } from './actions';

const baseState = {
  error: null,
  fetching: false,
  fetched: false,
};

export const initialState = {
  invoicesGenerated: {
    ...baseState,
    invoices: [],
    pagination: {
      page: 1,
      page_size: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  paginationInvoicesGeneratedState: {
    ...baseState,
    lastUpdatedRows: [],
  },

  cancelInvoicing: {
    ...baseState,
  },

  downloadInvoices: {
    ...baseState,
    document: null,
  },
};

const invoicesGeneratedProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getReportsGenerated.REQUEST:
        draft.invoicesGenerated = {
          ...initialState.invoicesGenerated,
          fetching: true,
        };
        break;

      case getReportsGenerated.SUCCESS:
        draft.invoicesGenerated.fetching = false;
        draft.invoicesGenerated.fetched = true;
        draft.invoicesGenerated.invoices = payload.data;
        draft.invoicesGenerated.pagination = {
          ...initialState.invoicesGenerated.pagination,
          ...payload.pagination,
        };
        break;

      case getReportsGenerated.FAILURE:
        draft.invoicesGenerated.error = payload;
        draft.invoicesGenerated.fetching = false;
        draft.invoicesGenerated.fetched = false;
        break;

      case getReportsGenerated.RESET:
        draft.invoicesGenerated = {
          ...initialState.invoicesGenerated,
        };
        break;

      case getReportsGeneratedPagination.REQUEST:
        draft.paginationInvoicesGeneratedState = {
          ...initialState.paginationInvoicesGeneratedState,
          fetching: true,
        };
        break;

      case getReportsGeneratedPagination.SUCCESS: {
        const currentInvoicesState = state.invoicesGenerated.invoices;

        let newsRows = [...currentInvoicesState];
        newsRows = [...newsRows, ...payload.data];

        draft.paginationInvoicesGeneratedState.fetching = false;
        draft.paginationInvoicesGeneratedState.fetched = true;
        draft.paginationInvoicesGeneratedState.lastUpdatedRows = payload.data;

        draft.invoicesGenerated.invoices = [...newsRows];
        draft.invoicesGenerated.pagination = {
          ...initialState.paginationInvoicesGeneratedState.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getReportsGeneratedPagination.FAILURE:
        draft.paginationInvoicesGeneratedState.error = payload;
        draft.paginationInvoicesGeneratedState.fetching = false;
        draft.paginationInvoicesGeneratedState.fetched = false;
        break;

      case getReportsGeneratedPagination.RESET:
        draft.paginationInvoicesGeneratedState = {
          ...initialState.paginationInvoicesGeneratedState,
        };
        break;

      case cancelInvoicing.REQUEST:
        draft.cancelInvoicing = {
          ...initialState.cancelInvoicing,
          fetching: true,
        };
        break;

      case cancelInvoicing.SUCCESS: {
        const currentInvoices = draft.invoicesGenerated.invoices;
        const indexInvoice = currentInvoices.findIndex((inv) => {
          return inv.uuid === payload.invoicingUuid;
        });
        currentInvoices[indexInvoice] = {
          ...currentInvoices[indexInvoice],
          ...payload.data,
        };

        draft.cancelInvoicing.fetching = false;
        draft.cancelInvoicing.fetched = true;
        draft.invoicesGenerated.invoices = currentInvoices;

        break;
      }

      case cancelInvoicing.FAILURE:
        draft.cancelInvoicing.error = payload;
        draft.cancelInvoicing.fetching = false;
        draft.cancelInvoicing.fetched = false;
        break;

      case cancelInvoicing.RESET:
        draft.cancelInvoicing = {
          ...initialState.cancelInvoicing,
        };
        break;

      case downloadInvoices.REQUEST:
        draft.downloadInvoices = {
          ...initialState.downloadInvoices,
          fetching: true,
        };
        break;

      case downloadInvoices.SUCCESS:
        draft.downloadInvoices.fetching = false;
        draft.downloadInvoices.fetched = true;
        draft.downloadInvoices.document = payload;

        break;

      case downloadInvoices.FAILURE:
        draft.downloadInvoices.error = payload;
        draft.downloadInvoices.fetching = false;
        draft.downloadInvoices.fetched = false;
        break;

      case downloadInvoices.RESET:
        draft.downloadInvoices = {
          ...initialState.downloadInvoices,
        };
        break;

      default:
        return draft;
    }
  });

export default invoicesGeneratedProviderReducer;
