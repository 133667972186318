import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectModifierGroupProviderDomain = (state) => state.modifierGroupDetails || initialState;

// Get Modifier Groups
const selectModifierGroupError = createSelector(
  selectModifierGroupProviderDomain,
  (modifierGroupState) => modifierGroupState.getModifierGroup.error,
);

const selectModifierGroupFetching = createSelector(
  selectModifierGroupProviderDomain,
  (modifierGroupState) => modifierGroupState.getModifierGroup.fetching,
);

const selectModifierGroupFetched = createSelector(
  selectModifierGroupProviderDomain,
  (modifierGroupState) => modifierGroupState.getModifierGroup.fetched,
);
const selectModifierGroupInstance = createSelector(
  selectModifierGroupProviderDomain,
  (modifierGroupState) => modifierGroupState.getModifierGroup.modifierGroup,
);

// edit Modifier Group
const selectEditModifierGroupError = createSelector(
  selectModifierGroupProviderDomain,
  (modifierGroupState) => modifierGroupState.editModifierGroup.error,
);

const selectEditModifierGroupFetching = createSelector(
  selectModifierGroupProviderDomain,
  (modifierGroupState) => modifierGroupState.editModifierGroup.fetching,
);

const selectEditModifierGroupFetched = createSelector(
  selectModifierGroupProviderDomain,
  (modifierGroupState) => modifierGroupState.editModifierGroup.fetched,
);

const selectEditModifierGroupInstance = createSelector(
  selectModifierGroupProviderDomain,
  (modifierGroupState) => modifierGroupState.editModifierGroup.modifierGroup,
);

export {
  selectModifierGroupProviderDomain,
  // Get Modifier Groups
  selectModifierGroupError,
  selectModifierGroupFetching,
  selectModifierGroupFetched,
  selectModifierGroupInstance,
  // edit Modifier Group
  selectEditModifierGroupError,
  selectEditModifierGroupFetching,
  selectEditModifierGroupFetched,
  selectEditModifierGroupInstance,
};
