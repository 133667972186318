import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}
const Invoice = forwardRef(function Invoice(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FFF', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M17.25 9C17.9444 9 18.6667 9 19.3333 9C21 9 21 8.11111 21 7.66667V4.33333C21 4 20.5833 3 18.9167 3C17.25 3 16.1389 3 16 3"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M12.5012 8.37028C12.5012 8.37028 11.5094 7.35579 10.2125 7.52487C9.29696 7.60941 8.15258 8.11666 8.15258 9.46931C8.15258 12.0055 12.5775 10.4838 12.6538 13.3582C12.6538 14.8799 11.3568 15.4717 10.2125 15.3872C8.99179 15.2181 8 14.2882 8 14.2882"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path d="M10.3269 15.7871V16.9141" stroke={color} strokeMiterlimit="10" strokeWidth="1.5" />
      <path d="M10.3269 5.64453V7.33507" stroke={color} strokeMiterlimit="10" strokeWidth="1.5" />
      <path
        d="M3 20.6604V5.03774C3 3.40755 4.7193 3 5.57895 3H17V5.03774V20.6604L13.3158 18.9623L10 21L6.68421 18.9623L3 20.6604Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
});

export default Invoice;
