import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { cancelInvoicingGeneratedRequest } from 'utils/api/baas/invoicing/invoicesGenerated';

import { cancelInvoicing } from '../actions';

export function* handleCancelInvoicingGeneratedRequest(action) {
  const values = action.payload;

  try {
    yield put(cancelInvoicing.request());

    const { data } = yield call(baasRequest, cancelInvoicingGeneratedRequest, values);

    yield put(cancelInvoicing.success({ data, invoicingUuid: values?.invoicingUuid }));
  } catch (err) {
    yield put(cancelInvoicing.failure(err));
  }
}
