export const GET_PORTAL_CONFIG_LIST = 'providers/invoicing/InvoicingProvider/GET_PORTAL_CONFIG_LIST';
export const SAVE_PORTAL_CONFIG = 'providers/invoicing/InvoicingProvider/SAVE_PORTAL_CONFIG';
export const PORTAL_CONFIG_DETAILS = 'providers/invoicing/InvoicingProvider/PORTAL_CONFIG_DETAILS';
export const PORTAL_CONFIG_UPDATE = 'providers/invoicing/InvoicingProvider/PORTAL_CONFIG_UPDATE';
export const INVOICING_CONFIG_LIST = 'providers/invoicing/InvoicingProvider/INVOICING_CONFIG_LIST';
export const INVOICING_CONFIG_DETAILS = 'providers/invoicing/InvoicingProvider/INVOICING_CONFIG_DETAILS';
export const INVOICING_CONFIG_CREATE = 'providers/invoicing/InvoicingProvider/INVOICING_CONFIG_CREATE';
export const INVOICING_CONFIG_UPDATE = 'providers/invoicing/InvoicingProvider/INVOICING_CONFIG_UPDATE';
export const INVOICING_CONFIG_DELETE = 'providers/invoicing/InvoicingProvider/INVOICING_CONFIG_DELETE';
export const INVOICING_GET_BRANDS_NOT_CONFIGURED =
  'providers/invoicing/InvoicingProvider/INVOICING_GET_BRANDS_NOT_CONFIGURED';
export const INVOICING_ENABLE_AUTO = 'providers/invoicing/InvoicingProvider/INVOICING_ENABLE_AUTO';
