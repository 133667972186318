import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function createServicesAreasRequest(name, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/service-areas/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    }),
    ...options,
  });
}

export function getServicesAreasRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/service-areas/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getServiceAreaDetailsRequest(areaUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/service-areas/${areaUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateServiceAreaRequest({ name, areaUuid, isDefault }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/service-areas/${areaUuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      name,
      isDefault,
    }),
    ...options,
  });
}

export function deleteServicesAreaRequest(areaUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/service-areas/${areaUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function validateNameAreaRequest({ name }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    name,
  });

  return request(`${API_BASE_URL}/v1/service-areas/check/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateAreaPositionRequest({ serviceArea, beforePosition, newPosition, nextPosition }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/service-areas/${serviceArea}/sorting-position/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      beforePosition,
      newPosition,
      nextPosition,
    }),
    ...options,
  });
}
