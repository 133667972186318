import { useState, createContext, ReactNode, Dispatch, SetStateAction } from 'react';
import dayjs from 'dayjs';
import { formatTypesDates } from 'utils/times';

import { Filters } from './types';

interface FilterContextType {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
}
export const FiltersContext = createContext<FilterContextType | null>(null);

interface Props {
  children: ReactNode;
}

export function FiltersProvider({ children }: Props) {
  const starHours = '00:00:00';
  const endHours = '23:59:59';

  const initialFilters: Filters = {
    startDate: `${dayjs().format(formatTypesDates.SEND_FORM)} ${starHours}`,
    endDate: `${dayjs().format(formatTypesDates.SEND_FORM)} ${endHours}`,
    brands: [],
    providers: [],
  };

  const [filters, setFilters] = useState<Filters>(initialFilters);

  return <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>;
}
