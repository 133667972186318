import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectInvoicingProviderDomain = (state) => state.invoicingProvider || initialState;

/**
 * @return {Object} The state of request.getPortalConfigList
 */
const selectPortalConfigList = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.getPortalConfigList,
);

const selectPortalConfigDetails = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.portalConfigDetails,
);

/**
 * @return {Object} The state of request.savePortalConfig
 */
const selectSavePortalConfig = createSelector(selectInvoicingProviderDomain, (subState) => subState.savePortalConfig);
const selectPortalConfigUpdate = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.portalConfigUpdate,
);

const selectInvoicingConfigList = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.invoicingConfigList,
);

const selectInvoicingConfigCreate = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.invoicingConfigCreate,
);

const selectInvoicingGetBrandsNotConfigured = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.invoicingGetBrandsNotConfigured,
);

const selectInvoicingConfigDetails = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.getInvoicingConfigDetails,
);

const selectUpdateInvoicingConfig = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.updateInvoicingConfig,
);

const selectDeleteInvoicingConfig = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.deleteInvoicingConfig,
);

const selectEnableInvoicingAuto = createSelector(
  selectInvoicingProviderDomain,
  (subState) => subState.enableInvoicingAuto,
);

export {
  selectInvoicingProviderDomain,
  selectPortalConfigList,
  selectPortalConfigDetails,
  selectSavePortalConfig,
  selectPortalConfigUpdate,
  selectInvoicingConfigList,
  selectInvoicingConfigCreate,
  selectInvoicingGetBrandsNotConfigured,
  selectInvoicingConfigDetails,
  selectUpdateInvoicingConfig,
  selectDeleteInvoicingConfig,
  selectEnableInvoicingAuto,
};
