import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import productSaga from './saga';
import productReducer from './reducer';

export function ProductDetails({ children }) {
  useInjectSaga({ key: 'productDetails', saga: productSaga });
  useInjectReducer({ key: 'productDetails', reducer: productReducer });

  return React.Children.only(children);
}

ProductDetails.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withProductDetails } from './withProductDetails';
export default compose(withConnect, memo)(ProductDetails);
