import { takeLatest } from 'redux-saga/effects';

import { getRole, saveRole } from '../actions';

import { handleGetRoleRequest } from './getRoleSaga';
import { handleSaveRoleRequest } from './saveRoleSaga';

export default function* rolesSaga() {
  yield takeLatest(getRole.TRIGGER, handleGetRoleRequest);
  yield takeLatest(saveRole.TRIGGER, handleSaveRoleRequest);
}
