import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { pageSizeTypes } from 'utils/pagination';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getServiceChargesRequest({ page }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    page,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
  });
  return request(`${API_BASE_URL}/v1/orders/service-charges/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getServiceChargeDetailRequest(serviceChargeUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/orders/service-charges/${serviceChargeUuid}/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function createServiceChargeRequest(serviceCharge, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/orders/service-charges/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify({ ...serviceCharge, store: storeUuid }),
    ...options,
  });
}

export function deleteServiceChargeRequest(serviceChargeUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/orders/service-charges/${serviceChargeUuid}/?store_uuid=${storeUuid}`, {
    method: 'DELETE',
    ...options,
  });
}

export function updateServiceChargeRequest({ serviceChargeUuid, serviceCharge }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(`${API_BASE_URL}/v1/orders/service-charges/${serviceChargeUuid}/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: JSON.stringify({ ...serviceCharge, store: storeUuid }),
    ...options,
  });
}

export function setIsEnabledServiceChargeRequest({ serviceChargeUuid, isEnabled }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  return request(
    `${API_BASE_URL}/v1/orders/service-charges/${serviceChargeUuid}/set-is-enabled/?store_uuid=${storeUuid}`,
    {
      method: 'PUT',
      body: JSON.stringify({
        isEnabled,
      }),
      ...options,
    },
  );
}
