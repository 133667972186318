import React from 'react';
import Box from '@material-ui/core/Box';

import Container from './Container';
import Head from './Head';
import HeadCell from './HeadCell';
import Row from './Row';
import Cell from './Cell';

interface Props {
  children: React.ReactNode;
}

const NestedTable = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

NestedTable.Head = Head;
NestedTable.HeadCell = HeadCell;
NestedTable.Body = Box;
NestedTable.Row = Row;
NestedTable.Cell = Cell;

export default NestedTable;
