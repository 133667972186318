import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ButtonPrimary from '@design-system/ButtonPrimary';
import IconError from '@experimental-components/IconsComponents/ErrorIllustration';

import useStyles from './styles';

function PageError({ id, labelAction, title, maxWidth = 600, message, onAction, ...others }) {
  const classes = useStyles();

  return (
    <Container className={classes.root} data-testid={id} id={id} maxWidth="sm" {...others}>
      <IconError />

      <Box maxWidth={maxWidth} mb={5} mt={3} width="100%">
        {title && (
          <Typography align="center" variant="h6">
            {title}
          </Typography>
        )}

        <Typography align="center" style={{ whiteSpace: 'pre-line' }} variant="body1">
          {message}
        </Typography>
      </Box>

      {onAction && (
        <ButtonPrimary data-testid={`btn-${id}`} onClick={onAction}>
          {labelAction}
        </ButtonPrimary>
      )}
    </Container>
  );
}

PageError.propTypes = {
  id: PropTypes.string,
  labelAction: PropTypes.string,
  title: PropTypes.string,
  maxWidth: PropTypes.number,
  message: PropTypes.string,
  onAction: PropTypes.func,
  variant: PropTypes.string,
};

export default PageError;
