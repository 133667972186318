import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { updateKitchenTicket, getKitchenTicket } from './actions';
import {
  selectKitchenTicketDetails,
  selectKitchenTicketDetailsState,
  selectKitchenTicketUpdateState,
} from './selectors';

export function withKitchenTicketHoc(Component) {
  function withKitchenTicket(props) {
    return <Component {...props} />;
  }

  withKitchenTicket.displayName = `withKitchenTicket(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withKitchenTicket;
}

const mapStateToProps = createStructuredSelector({
  kitchenTicket: selectKitchenTicketDetails,
  kitchenTicketState: selectKitchenTicketDetailsState,
  kitchenTicketUpdateState: selectKitchenTicketUpdateState,
});

export function mapDispatchToProps(dispatch) {
  return {
    updateOrder: (values) => dispatch(updateKitchenTicket(values)),
    loadOrder: () => dispatch(getKitchenTicket()),
    resetUpdateOrder: () => dispatch(updateKitchenTicket.reset()),
    resetLoadOrder: () => dispatch(getKitchenTicket.reset()),
  };
}

export const withKitchenTicketState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withKitchenTicketState, withKitchenTicketHoc);
