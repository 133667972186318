import produce from 'immer';

import { getBrands, selectedBrand } from './actions';

export const initialState = {
  getBrands: {
    error: null,
    fetching: false,
    fetched: false,
    brands: [],
  },

  selectedBrand: {
    brand: {},
  },
};

const brandsProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getBrands.REQUEST:
        draft.getBrands.fetching = true;
        draft.getBrands.fetched = false;
        draft.getBrands.brands = [];
        draft.getBrands.error = null;
        break;

      case getBrands.SUCCESS: {
        draft.getBrands.fetching = false;
        draft.getBrands.fetched = true;
        draft.getBrands.brands = payload;
        break;
      }

      case getBrands.FAILURE:
        draft.getBrands.error = payload;
        draft.getBrands.fetching = false;
        draft.getBrands.fetched = false;
        break;

      case getBrands.RESET:
        draft.getBrands = { ...initialState.getBrands };
        break;

      case selectedBrand.SELECTED:
        draft.selectedBrand.brand = payload;
        break;

      case selectedBrand.RESET: {
        const [initialBrand] = draft.getBrands.brands;

        draft.selectedBrand.brand = initialBrand || {};
        break;
      }

      default:
        return draft;
    }
  });

export default brandsProviderReducer;
