import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getMultiTaxesRequest, updateDefaultTaxRequest } from 'utils/api/baas/settings/multiTaxes';

import { getMultiTaxes, updateDefaultTax } from './actions';

export function* handleGetMultiTaxesRequest() {
  try {
    yield put(getMultiTaxes.request());

    const { data } = yield call(baasRequest, getMultiTaxesRequest);

    yield put(getMultiTaxes.success(data));
  } catch (err) {
    yield put(getMultiTaxes.failure(err));
  } finally {
    yield put(getMultiTaxes.fulfill());
  }
}

export function* handleUpdateDefaultRequest({ payload }) {
  try {
    yield put(updateDefaultTax.request());

    const { data } = yield call(baasRequest, updateDefaultTaxRequest, payload);

    yield put(updateDefaultTax.success(data));
  } catch (err) {
    yield put(updateDefaultTax.failure(err));
  }
}

export default function* InvoiceCancellationsProviderSaga() {
  yield takeLatest(getMultiTaxes.TRIGGER, handleGetMultiTaxesRequest);
  yield takeLatest(updateDefaultTax.TRIGGER, handleUpdateDefaultRequest);
}
