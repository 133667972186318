import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import queryString from 'query-string';
import { pageSizeTypes } from 'utils/pagination';
import { cleanObject } from 'utils/objects';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getPaymentsRequest({ page }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    page,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
  });
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/payment-types/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getPaymentDetailRequest({ storeUuid, paymentUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/payment-types/${paymentUuid}/`, {
    method: 'GET',
    ...options,
  });
}

export function createPaymentRequest({ storeUuid, payment }, options = {}) {
  const keys = cleanObject(payment);

  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/payment-types/`, {
    method: 'POST',
    body: JSON.stringify(keys),
    ...options,
  });
}

export function deletePaymentRequest({ storeUuid, paymentUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/payment-types/${paymentUuid}/`, {
    method: 'DELETE',
    ...options,
  });
}

export function paymentAvailabilityRequest({ storeUuid, paymentUuid, availability }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/payment-types/${paymentUuid}/set-is-enabled/`, {
    method: 'PUT',
    body: JSON.stringify({
      is_enabled: availability,
    }),
    ...options,
  });
}

export function editPaymentRequest({ storeUuid, paymentUuid, payment }, options = {}) {
  const keys = cleanObject(payment);

  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/payment-types/${paymentUuid}/`, {
    method: 'PUT',
    body: JSON.stringify(keys),
    ...options,
  });
}

export function updatePaymentPositionRequest({ paymentUuid, beforePosition, newPosition, nextPosition }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/payments/payment-types/${paymentUuid}/sorting-position/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      beforePosition,
      newPosition,
      nextPosition,
    }),
    ...options,
  });
}
