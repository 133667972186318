import React, { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

function SpreedlyProvider({ children }: Props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://core.spreedly.com/iframe/iframe-v1.min.js';
    script.id = 'spreedlySDK';
    script.type = 'text/javascript';
    script.async = true;
    if (!document.getElementById('spreedlySDK')) document.body.appendChild(script);
  }, []);

  return React.Children.only(children);
}

export default SpreedlyProvider;
