import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Select from '@design-system/Select';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import { TypeOfSaleFilterValues } from 'components/ProductForm/types';

import useStyles from './styles';
import { menuProps } from './utils';

function TypeSoldProducts({ onSelectTypeSoldProduct }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');

  function handleRenderNameValue(value) {
    let renderSelected = '';

    if (value === TypeOfSaleFilterValues.ALL) renderSelected = t('menuMaker:headerForm.filterProducts.allArticles');

    if (value === TypeOfSaleFilterValues.WITH_INDEPENDENT_SALE)
      renderSelected = t('menuMaker:headerForm.filterProducts.soldAlone');

    if (value === TypeOfSaleFilterValues.WITHOUT_INDEPENDENT_SALE)
      renderSelected = t('menuMaker:headerForm.filterProducts.notSoldAlone');

    if (value === TypeOfSaleFilterValues.SALE_BY_WEIGHT)
      renderSelected = t('menuMaker:headerForm.filterProducts.soldByGrammage');

    return <span className={classes.contentSelected}>{renderSelected}</span>;
  }

  return (
    <Select
      data-testid="filterByTypeSoldProducts"
      defaultValue={TypeOfSaleFilterValues.ALL}
      displayEmpty
      fullWidth
      hideLabelHelperText
      id="filterByTypeSoldProducts"
      label={t('menuMaker:headerForm.typeSoldProducts')}
      MenuProps={{ ...menuProps }}
      name="typeOfSale"
      onChange={(e) => onSelectTypeSoldProduct(e.target.value)}
      renderValue={(selected) => handleRenderNameValue(selected)}
    >
      <MenuItem key="all" data-testid="allTypeSoldFilter" value={TypeOfSaleFilterValues.ALL}>
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.allArticles')} />
      </MenuItem>
      <MenuItem key="soldAlone" data-testid="soldAloneFilter" value={TypeOfSaleFilterValues.WITH_INDEPENDENT_SALE}>
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.soldAlone')} />
      </MenuItem>
      <MenuItem
        key="notSoldAlone"
        data-testid="notSoldAloneFilter"
        value={TypeOfSaleFilterValues.WITHOUT_INDEPENDENT_SALE}
      >
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.notSoldAlone')} />
      </MenuItem>
      <MenuItem key="notSoldAlone" data-testid="notSoldAloneFilter" value={TypeOfSaleFilterValues.SALE_BY_WEIGHT}>
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.soldByGrammage')} />
      </MenuItem>
    </Select>
  );
}
TypeSoldProducts.propTypes = {
  onSelectTypeSoldProduct: PropTypes.func,
};

export default TypeSoldProducts;
