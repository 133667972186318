/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUserCurrentStore } from 'utils/auth';

export const getModifiersReportFlags = (flags: { [key: string]: any }) => {
  const currentStore = getUserCurrentStore()?.uuid;

  const showModifierReport =
    !flags.activeReportModifiersByStore ||
    (flags.activeReportModifiersByStore && flags.reportModifiersByStore?.storesUuidList?.includes(currentStore));

  return { showModifierReport };
};
