import axios, { AxiosRequestConfig } from 'axios';
import { getAuthToken } from 'utils/auth';

import { env } from './env';

export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Language': 'es-MX',
};

export const api = axios.create({
  baseURL: env.BASE_URL_API,
  headers,
});

export const payApi = axios.create({
  baseURL: env.BASE_URL_API_PAY,
  headers,
});

export const inventoriesApi = axios.create({
  baseURL: env.BASE_URL_API_INVENTORIES,
  headers,
});

// adding the token in this way is better because for each request we get the token from the cookie
// using interceptors
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function addAccessTokenInHeaders(config: AxiosRequestConfig<any>) {
  const tokens = getAuthToken();
  if (tokens?.accessToken && config.headers) {
    config.headers.Authorization = `Bearer ${tokens.accessToken}`;
  }
  return config;
}

api.interceptors.request.use(addAccessTokenInHeaders);
payApi.interceptors.request.use(addAccessTokenInHeaders);
inventoriesApi.interceptors.request.use(addAccessTokenInHeaders);

api.interceptors.response.use((response) => {
  if (response.status === 401) {
    return window.location.replace('/signout');
  }

  return response;
});
