import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { History } from 'history';

import useNotification from '@design-system/Notification/useNotification';

import { createTypeOfProducts, deleteTypeOfProducts, getTypesOfProducts, updateTypeOfProducts } from '../services';
import { ProductTypeFormProps } from '../types';

function useTypesOfProducts(options: { enabled?: boolean }) {
  const { t } = useTranslation('settingsTypeOfProduct');
  const { setNotification } = useNotification();
  const [search, setSearch] = useState<string>('');

  const {
    refetch: loadTypesOfProducts,
    data: dataTypesOfProducts,
    isFetched: isFetchedTypesOfProducts,
    isFetching: isFetchingTypesOfProducts,
    remove,
  } = useQuery(
    'types-of-products',
    () =>
      getTypesOfProducts({
        name: search,
      }),
    {
      onError: () => {
        setNotification(t('settingsTypeOfProduct:errorCodes.TypeOfProductFetchError'), {
          variant: 'error',
        });
      },
      enabled: options.enabled,
    },
  );

  const mutationDelete = useMutation(
    (uuid: string) => {
      return deleteTypeOfProducts({ uuid });
    },
    {
      mutationKey: 'type-of-product',
      onError: () => {
        setNotification(t('settingsTypeOfProduct:messages.typeOfProductDeleteError'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        setNotification(t('settingsTypeOfProduct:messages.typeOfProductDeleteSuccess'), {
          variant: 'success',
        });
      },
    },
  );
  const mutationCreate = useMutation(
    (params: ProductTypeFormProps) => {
      return createTypeOfProducts(params);
    },
    {
      mutationKey: 'type-of-product',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error?.response?.data?.errors[0]?.code === 'menu.ProductTypeNameExisting') {
          return setNotification(t('settingsTypeOfProduct:messages.typeOfProductCreateDuplicatedNameError'), {
            variant: 'error',
          });
        }

        return setNotification(t('settingsTypeOfProduct:messages.typeOfProductCreateError'), {
          variant: 'error',
        });
      },
      onSuccess: ({ data }) => {
        remove();
        setNotification(t('settingsTypeOfProduct:messages.typeOfProductCreateSuccess', { name: data.name }), {
          variant: 'success',
        });
      },
    },
  );
  const mutationUpdate = useMutation(
    (params: ProductTypeFormProps) => {
      return updateTypeOfProducts(params);
    },
    {
      mutationKey: 'type-of-product',
      onError: () => {
        setNotification(t('settingsTypeOfProduct:messages.typeOfProductUpdateError'), {
          variant: 'error',
        });
      },
      onSuccess: ({ data }) => {
        setNotification(t('settingsTypeOfProduct:messages.typeOfProductUpdateSuccess', { name: data.name }), {
          variant: 'success',
        });
      },
    },
  );
  async function deleteTypeOfProduct(uuid: string) {
    mutationDelete.mutate(uuid, {
      onSuccess: () => {
        remove();
      },
    });
  }
  async function createTypeOfProduct(params: ProductTypeFormProps, history: History, suppliersPathRoot: string) {
    mutationCreate.mutate(params, {
      onSuccess: () => {
        history.replace({
          pathname: suppliersPathRoot,
        });
      },
    });
  }
  async function updateTypeOfProduct(params: ProductTypeFormProps, history: History, suppliersPathRoot: string) {
    mutationUpdate.mutate(params, {
      onSuccess: () => {
        remove();
        history.replace({
          pathname: suppliersPathRoot,
        });
      },
    });
  }

  useEffect(() => {
    loadTypesOfProducts();
  }, [search]);

  return {
    typesOfProducts: dataTypesOfProducts?.data || [],
    isFetchedTypesOfProducts,
    typesOfProductsState: {
      isFetching: isFetchingTypesOfProducts,
      isFetched: isFetchedTypesOfProducts,
    },
    createState: {
      isFetching: mutationCreate.isLoading,
      isSuccess: mutationCreate.isSuccess,
      isError: mutationCreate.isError,
    },
    updateState: {
      isFetching: mutationUpdate.isLoading,
      isSuccess: mutationUpdate.isSuccess,
      isError: mutationUpdate.isError,
    },
    deleteState: {
      isFetching: mutationDelete.isLoading,
      isSuccess: mutationDelete.isSuccess,
      isError: mutationDelete.isError,
    },
    deleteTypeOfProduct,
    createTypeOfProduct,
    updateTypeOfProduct,
    setSearch,
  };
}

export default useTypesOfProducts;
