import { useRef, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectInvoiceCancellations,
  selectInvoiceCancellationsState,
  selectInvoiceTaxRegime,
  selectInvoiceTaxRegimeState,
} from './selectors';
import { getInvoiceCancellations, getInvoiceTaxRegime } from './actions';

function useInvoiceCatalog() {
  const dispatch = useDispatch();

  const selectInvoiceCancellationsRef = useRef();
  const selectInvoiceCancellationsStateRef = useRef();
  const selectInvoiceTaxRegimeRef = useRef();
  const selectInvoiceTaxRegimeStateRef = useRef();

  selectInvoiceCancellationsRef.current = useSelector(selectInvoiceCancellations);
  selectInvoiceCancellationsStateRef.current = useSelector(selectInvoiceCancellationsState);

  selectInvoiceTaxRegimeRef.current = useSelector(selectInvoiceTaxRegime);
  selectInvoiceTaxRegimeStateRef.current = useSelector(selectInvoiceTaxRegimeState);

  const handleGetCancellationCatalog = useCallback(() => {
    if (selectInvoiceCancellationsRef.current?.length === 0) {
      dispatch(getInvoiceCancellations());
    }
  }, [dispatch]);

  const handleGetTaxRegimeCatalog = useCallback(() => {
    const canLoad =
      selectInvoiceTaxRegimeRef.current?.taxRegimeCompany?.length === 0 &&
      selectInvoiceTaxRegimeRef.current?.taxRegimePersons?.length === 0;

    if (canLoad) dispatch(getInvoiceTaxRegime());
  }, [dispatch]);

  const invoiceCancellations = selectInvoiceCancellationsRef.current;
  const invoiceCancellationsState = selectInvoiceCancellationsStateRef.current;
  const invoiceTaxRegime = selectInvoiceTaxRegimeRef.current;
  const invoiceTaxRegimeState = selectInvoiceTaxRegimeStateRef.current;

  return {
    loadInvoiceCancellations: handleGetCancellationCatalog,
    loadInvoiceTaxRegime: handleGetTaxRegimeCatalog,
    invoiceCancellations,
    invoiceCancellationsState,
    invoiceTaxRegime,
    invoiceTaxRegimeState,
  };
}

export default useInvoiceCatalog;
