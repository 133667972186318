import dayjs from 'dayjs';

import { api } from 'config';

import { Response } from 'types';

import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';

import { ActiveLicense } from './types';

export async function getActiveLicense(options: { storeUuid?: string }) {
  if (!options.storeUuid) return null;

  const result = await api.get<Response<ActiveLicense>>('/v1/stores/info/', {
    params: {
      store_uuid: options.storeUuid,
    },
  });

  const responseData = result.data.data.license;
  const responseMenuPublication = result.data.data.menuPublication;

  const licenseExtesionAllowed =
    (responseData?.status === subscriptionStatusTypes.UNAVAILABLE ||
      responseData?.status === subscriptionStatusTypes.PAYMENT_REQUIRED) &&
    responseData?.extensionConnect > 0 &&
    dayjs(responseData?.webCutoffDate).add(responseData.extensionConnect, 'day') > dayjs(responseData?.serverDate);

  const licenseStatus = licenseExtesionAllowed ? subscriptionStatusTypes.AVAILABLE : responseData?.status;

  const response = {
    ...responseData,
    status: licenseStatus,
    licenseExtesionAllowed,
    menuPublicationState: responseMenuPublication.state,
  };

  return response;
}
