import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getInvoicesOrdersEditRequest,
  updateInvoicesOrderEditRequest,
  resetEditedInvoicesOrdersRequest,
  generateInvoicingRequest,
  getInvoicesOrderDetailsEditRequest,
  removeItemsOrderEditRequest,
  applyDiscountOrderEditRequest,
  resetChangesOrderEditRequest,
  saveChangesOrderEditRequest,
} from 'utils/api/baas/invoicing/ticketsEditor';

import {
  getInvoicesOrdersEdit,
  getInvoicesOrdersEditByPagination,
  getInvoicesOrdersPreEdit,
  updateInvoicesOrders,
  resetEditedInvoicesOrders,
  generateInvoicing,
  getInvoicesOrderDetailsEdit,
  updateOrderRemoveItem,
  updateOrderDiscount,
  revertChangesOrderEdited,
  saveChangesOrderEdited,
} from './actions';

export function* handleGetInvoicesOrdersEditRequest(action) {
  const filters = action.payload;

  try {
    yield put(getInvoicesOrdersEdit.request());

    const { data, pagination } = yield call(baasRequest, getInvoicesOrdersEditRequest, { ...filters });

    yield put(getInvoicesOrdersEdit.success({ data, pagination }));
  } catch (err) {
    yield put(getInvoicesOrdersEdit.failure(err));
  }
}

export function* handleGetInvoicesOrdersEditByPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getInvoicesOrdersEditByPagination.request());

    const { data, pagination } = yield call(baasRequest, getInvoicesOrdersEditRequest, { ...filters });

    yield put(getInvoicesOrdersEditByPagination.success({ data, pagination, page: filters?.page }));
  } catch (err) {
    yield put(getInvoicesOrdersEditByPagination.failure(err));
  }
}

export function* handleGetInvoicesOrdersPreEditRequest(action) {
  const filters = action.payload;

  try {
    yield put(getInvoicesOrdersPreEdit.request());

    const { data } = yield call(baasRequest, updateInvoicesOrderEditRequest, { ...filters });

    yield put(getInvoicesOrdersPreEdit.success(data));
  } catch (err) {
    yield put(getInvoicesOrdersPreEdit.failure(err));
  }
}

export function* handleUpdateInvoicesOrdersRequest(action) {
  const filters = action.payload;

  try {
    yield put(updateInvoicesOrders.request());

    const { data } = yield call(baasRequest, updateInvoicesOrderEditRequest, { ...filters });

    yield put(updateInvoicesOrders.success(data));
  } catch (err) {
    yield put(updateInvoicesOrders.failure(err));
  }
}

export function* handleResetEditedInvoicesOrdersRequest(action) {
  const filters = action.payload;

  try {
    yield put(resetEditedInvoicesOrders.request());

    const { data } = yield call(baasRequest, resetEditedInvoicesOrdersRequest, { ...filters });

    yield put(resetEditedInvoicesOrders.success(data));
  } catch (err) {
    yield put(resetEditedInvoicesOrders.failure(err));
  }
}

export function* handleGenerateInvoicingRequest(action) {
  const filters = action.payload;

  try {
    yield put(generateInvoicing.request());

    const { data } = yield call(baasRequest, generateInvoicingRequest, { ...filters });

    yield put(generateInvoicing.success({ data }));
  } catch (err) {
    yield put(generateInvoicing.failure(err));
  }
}

export function* handleGetInvoicesOrderDetailsEditRequest(action) {
  const filters = action.payload;

  try {
    yield put(getInvoicesOrderDetailsEdit.request());

    const { data: order } = yield call(baasRequest, getInvoicesOrderDetailsEditRequest, { ...filters });

    yield put(getInvoicesOrderDetailsEdit.success({ order }));
  } catch (err) {
    yield put(getInvoicesOrderDetailsEdit.failure(err));
  }
}

export function* handleUpdateOrderRemoveItemRequest(action) {
  const filters = action.payload;

  try {
    yield put(updateOrderRemoveItem.request());

    const { data: orderUpdated } = yield call(baasRequest, removeItemsOrderEditRequest, { ...filters });

    yield put(updateOrderRemoveItem.success({ orderUpdated }));
  } catch (err) {
    yield put(updateOrderRemoveItem.failure(err));
  }
}

export function* handleUpdateOrderDiscountRequest(action) {
  const filters = action.payload;

  try {
    yield put(updateOrderDiscount.request());

    const { data: orderUpdated } = yield call(baasRequest, applyDiscountOrderEditRequest, { ...filters });

    yield put(updateOrderDiscount.success({ orderUpdated }));
  } catch (err) {
    yield put(updateOrderDiscount.failure(err));
  }
}

export function* handleResetChangesOrderEditedRequest(action) {
  const filters = action.payload;

  try {
    yield put(revertChangesOrderEdited.request());

    yield call(baasRequest, resetChangesOrderEditRequest, { ...filters });

    yield put(revertChangesOrderEdited.success());
  } catch (err) {
    yield put(revertChangesOrderEdited.failure(err));
  }
}

export function* handleSaveChangesOrderEditedRequest(action) {
  const filters = action.payload;

  try {
    yield put(saveChangesOrderEdited.request());

    yield call(baasRequest, saveChangesOrderEditRequest, { ...filters });

    yield put(saveChangesOrderEdited.success());
  } catch (err) {
    yield put(saveChangesOrderEdited.failure(err));
  }
}

export default function* invoicesTicketsEditorProviderSaga() {
  yield takeLatest(getInvoicesOrdersEdit.TRIGGER, handleGetInvoicesOrdersEditRequest);
  yield takeLatest(getInvoicesOrdersEditByPagination.TRIGGER, handleGetInvoicesOrdersEditByPaginationRequest);
  yield takeLatest(getInvoicesOrdersPreEdit.TRIGGER, handleGetInvoicesOrdersPreEditRequest);
  yield takeLatest(updateInvoicesOrders.TRIGGER, handleUpdateInvoicesOrdersRequest);
  yield takeLatest(resetEditedInvoicesOrders.TRIGGER, handleResetEditedInvoicesOrdersRequest);
  yield takeLatest(generateInvoicing.TRIGGER, handleGenerateInvoicingRequest);
  yield takeLatest(getInvoicesOrderDetailsEdit.TRIGGER, handleGetInvoicesOrderDetailsEditRequest);
  yield takeLatest(updateOrderRemoveItem.TRIGGER, handleUpdateOrderRemoveItemRequest);
  yield takeLatest(updateOrderDiscount.TRIGGER, handleUpdateOrderDiscountRequest);
  yield takeLatest(revertChangesOrderEdited.TRIGGER, handleResetChangesOrderEditedRequest);
  yield takeLatest(saveChangesOrderEdited.TRIGGER, handleSaveChangesOrderEditedRequest);
}
