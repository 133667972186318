/**
 *
 * ParrotSoftware
 * GoogleMapsProvider saga
 *
 */
import { takeLatest, put, call } from 'redux-saga/effects';

import { geocodingLookupLatitudeLongitudeRequest } from 'utils/api/baas/google/geocoding';

import { geocodingLookupLatitudeLongitude } from './actions';

export function* geocodingLookupLatitudeLongitudeSaga(action) {
  const { address } = action.payload;

  try {
    yield put(geocodingLookupLatitudeLongitude.request());

    const { results } = yield call(geocodingLookupLatitudeLongitudeRequest, { address });
    const [dataInit] = yield results;

    yield put(geocodingLookupLatitudeLongitude.success(dataInit?.geometry));
  } catch (err) {
    yield put(geocodingLookupLatitudeLongitude.failure(err));
  }
}

export default function* googleMapsRootSga() {
  yield takeLatest(geocodingLookupLatitudeLongitude.TRIGGER, geocodingLookupLatitudeLongitudeSaga);
}
