import { Tooltip, TooltipProps, makeStyles } from '@material-ui/core';

import Help from '@experimental-components/IconsComponents/Help';

interface CustomProps {
  boxShadow?: string;
  fontSize?: number | string;
  maxWidth?: number | string;
}

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: ({ fontSize }: CustomProps) => fontSize || theme.typography.pxToRem(16),
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    boxShadow: ({ boxShadow }: CustomProps) => boxShadow,
    maxWidth: ({ maxWidth }: CustomProps) => maxWidth || 'unset',
    lineHeight: '1.7',
  },
}));

interface Props extends TooltipProps {
  sizeIcon?: number;
  boxShadow?: string;
  fontSize?: number | string;
  maxWidth?: number | string;
}

function TooltipBase({
  sizeIcon = 20,
  children,
  boxShadow = '0px 8px 40px #f2f2f4',
  fontSize,
  maxWidth,
  ...props
}: Props) {
  const classes = useStyles({ boxShadow, fontSize, maxWidth });
  return (
    <>
      {children && (
        <Tooltip classes={{ tooltip: classes.customTooltip }} {...props}>
          {children}
        </Tooltip>
      )}{' '}
      {!children && (
        <Tooltip classes={{ tooltip: classes.customTooltip }} {...props}>
          <Help size={sizeIcon} />
        </Tooltip>
      )}
    </>
  );
}

TooltipBase.Help = Help;

export default TooltipBase;
