import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { updateProductImageRequest } from 'utils/api/baas/menuMaker';

import { updateProductImage } from '../actions';

export function* handleUpdateProductImageRequest(action) {
  const { storeUuid, productUuid, image } = action.payload;
  try {
    yield put(updateProductImage.request());

    const { data } = yield call(baasRequest, updateProductImageRequest, {
      storeUuid,
      productUuid,
      image,
    });

    yield put(updateProductImage.success(data));
  } catch (err) {
    yield put(updateProductImage.failure(err));
  }
}
