import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  createMovementReasonRequest,
  deleteMovementReasonRequest,
  editMovementReasonRequest,
  getMovementReasonDetailRequest,
  getMovementReasonsRequest,
} from 'utils/api/baas/settings/movementReasons';

import {
  createMovementReason,
  deleteMovementReason,
  getMovementReasons,
  getMovementReasonDetail,
  editMovementReason,
} from './actions';

export function* handleGetMovementReasonsRequest() {
  try {
    yield put(getMovementReasons.request());
    const { data: reasons, pagination } = yield call(baasRequest, getMovementReasonsRequest);
    yield put(getMovementReasons.success({ reasons, pagination }));
  } catch (err) {
    yield put(getMovementReasons.failure(err));
  } finally {
    yield put(getMovementReasons.fulfill());
  }
}

export function* handleGetMovementReasonDetailRequest(action) {
  const { reasonUuid } = action.payload;
  try {
    yield put(getMovementReasonDetail.request());

    const { data } = yield call(baasRequest, getMovementReasonDetailRequest, { reasonUuid });

    yield put(getMovementReasonDetail.success(data));
  } catch (err) {
    yield put(getMovementReasonDetail.failure(err));
  } finally {
    yield put(getMovementReasonDetail.fulfill());
  }
}

export function* handleCreateMovementReasonRequest(action) {
  const { movementType, name } = action.payload;

  try {
    yield put(createMovementReason.request());

    const { data } = yield call(baasRequest, createMovementReasonRequest, {
      movementType,
      name,
    });

    yield put(createMovementReason.success(data));
  } catch (err) {
    yield put(createMovementReason.failure(err));
  } finally {
    yield put(createMovementReason.fulfill());
  }
}

export function* handleDeleteMovementReasonRequest(action) {
  const { reasonUuid } = action.payload;

  try {
    yield put(deleteMovementReason.request());

    yield call(baasRequest, deleteMovementReasonRequest, {
      reasonUuid,
    });

    yield put(deleteMovementReason.success(reasonUuid));
  } catch (err) {
    yield put(deleteMovementReason.failure(err));
  } finally {
    yield put(deleteMovementReason.fulfill());
  }
}

export function* handleEditMovementReasonRequest(action) {
  const { movementType, name, reasonUuid } = action.payload;

  try {
    yield put(editMovementReason.request());

    const { data } = yield call(baasRequest, editMovementReasonRequest, {
      movementType,
      name,
      reasonUuid,
    });

    yield put(editMovementReason.success(data));
  } catch (err) {
    yield put(editMovementReason.failure(err));
  }
}

export default function* settingsPayInProviderSaga() {
  yield takeLatest(createMovementReason.TRIGGER, handleCreateMovementReasonRequest);
  yield takeLatest(deleteMovementReason.TRIGGER, handleDeleteMovementReasonRequest);
  yield takeLatest(editMovementReason.TRIGGER, handleEditMovementReasonRequest);
  yield takeLatest(getMovementReasons.TRIGGER, handleGetMovementReasonsRequest);
  yield takeLatest(getMovementReasonDetail.TRIGGER, handleGetMovementReasonDetailRequest);
}
